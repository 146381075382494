import React, { useState } from "react";
import { Box, Button, Drawer, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import SemicircularChart from "../../../common/AnalyticsComponents/SemicircularChart";
import {
  priorityValueColor,
  priorityValueLable,
  taskAnalyticsContentEnum,
} from "../../../utils/constant";
import { getLast12Months } from "../../../utils/helper";
import Resource from "./Drawer/Resource";
const TaskAnalytics = ({
  taskTabValue,
  selectedTaskGroup,
  selectedTaskmember,
}) => {
  const taskAnalyticsData = useSelector(
    (state) => state?.arkchatgroup?.taskAnalyticsData
  );
  const [isResourceUtilisationOpen, setResourceUtilisationOpen] =
    useState(false);
  return (
    <Box>
      <Box mt={"0.2rem"} mb={"1.5rem"}>
        <Box mb={"1rem"}>
          <Button
            style={{
              background: "#DB0011",
              color: "white",
              borderRadius: "3px",
              textTransform: "none",
              minWidth: "9.5rem",
            }}
            variant="contained"
            onClick={() => setResourceUtilisationOpen(true)}
          >
            Time sheet
          </Button>
          <Drawer
            sx={{ zIndex: 1202 }}
            open={isResourceUtilisationOpen}
            anchor="right"
          >
            <Resource
              taskTabValue={taskTabValue}
              setResourceUtilisationOpen={setResourceUtilisationOpen}
              selectedTaskGroup={selectedTaskGroup}
              selectedTaskmember={selectedTaskmember}
            />
          </Drawer>
        </Box>

        {/* <Box
          sx={{
            overflowX: "auto",
            gap: "0.5rem",
            display: "flex",
            "&::-webkit-scrollbar": {
              height: "2px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#F9F8F8",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "grey",
            },
          }}
        >
          {taskAnalyticsData?.groupData?.map((groupAnalyticsData) => (
            <Box display={"inline-flex"} gap={"0.5rem"} mt={"0.1rem"}>
              <Typography fontSize={"1rem"}>
                {groupAnalyticsData?.name}
              </Typography>
              <Box
                display={"flex"}
                border={"1px solid"}
                bgcolor={"rgba(0, 127, 143, 1)"}
                color={"#fff"}
                padding={"0.1rem 0.5rem"}
                gap={"0.4rem"}
              >
                <Typography>
                  {groupAnalyticsData?.analysis_time?.timeValue}
                </Typography>
                <Typography>
                  {groupAnalyticsData?.analysis_time?.timeType}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box> */}
      </Box>

      <Box display={"flex"} gap={"0.5%"} mt={"0.1rem"}>
        {taskAnalyticsData?.analyticsData?.map((data) => (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            sx={{
              borderRadius: "4px",
              border: "1px solid rgba(232, 232, 232, 1)",
              padding: "0.4rem",
            }}
            width={"40%"}
            key={data?.status + "TaskAnalyticsData"}
          >
            <Box
              mb={"0.6rem"}
              sx={{
                borderRadius: "4px",
                border: "1px solid rgba(232, 232, 232, 1)",
                padding: "0.5rem",
              }}
            >
              <Box textAlign={"center"} mt={"0.4rem"}>
                <Typography variant="h5" fontWeight={500} fontSize={"0.87rem"}>
                  {taskAnalyticsContentEnum[data?.status]?.title}
                </Typography>
              </Box>
              <SemicircularChart
                trackBackground={
                  taskAnalyticsContentEnum[data?.status]?.backgroundColor
                }
                colorsData={taskAnalyticsContentEnum[data?.status]?.color}
                seriesData={
                  data?.total !== 0
                    ? Math.floor((data?.count / data?.total) * 100)
                    : 0
                }
              />
              <Box textAlign={"center"}>
                {/* <Typography variant="h5" fontWeight={500} fontSize={"0.87rem"}>
                {taskAnalyticsContentEnum[data?.status]?.totalTitle} :{" "}
                {data?.total}
              </Typography> */}
                <Typography variant="h5" fontWeight={500} fontSize={"0.87rem"}>
                  {taskAnalyticsContentEnum[data?.status]?.countTitle} :{" "}
                  {data?.count}
                </Typography>
              </Box>
            </Box>

            <Box
              mb={"0.6rem"}
              sx={{
                borderRadius: "4px",
                border: "1px solid rgba(232, 232, 232, 1)",
                padding: "0.5rem",
              }}
            >
              <Box textAlign={"center"} mt={"0.4rem"}>
                <Typography variant="h5" fontWeight={500} fontSize={"0.87rem"}>
                  Priority
                </Typography>
              </Box>
              <Box>
                <Typography fontSize={"0.75rem"}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "13px",
                      height: "13px",
                      verticalAlign: "middle",
                      margin: "-1px 5px 0px 5px",
                      backgroundColor:
                        priorityValueColor[data?.priority[0]?.status],
                    }}
                  ></span>
                  {priorityValueLable[data?.priority[0]?.status]}{" "}
                  {data?.priority[0]?.count}
                </Typography>
                <Typography fontSize={"0.75rem"}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "13px",
                      height: "13px",
                      verticalAlign: "middle",
                      margin: "-1px 5px 0px 5px",
                      backgroundColor:
                        priorityValueColor[data?.priority[1]?.status],
                    }}
                  ></span>
                  {priorityValueLable[data?.priority[1]?.status]}{" "}
                  {data?.priority[1]?.count}
                </Typography>
                <Typography fontSize={"0.75rem"}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "13px",
                      height: "13px",
                      verticalAlign: "middle",
                      margin: "-1px 5px 0px 5px",
                      backgroundColor:
                        priorityValueColor[data?.priority[2]?.status],
                    }}
                  ></span>
                  {priorityValueLable[data?.priority[2]?.status]}{" "}
                  {data?.priority[2]?.count}
                </Typography>
              </Box>
              {/* <BarChart
              priorityValue={data?.priority}
              highPriorityColor={priorityValueColor[data?.priority[0]?.status]}
              mediumPriorityColor={
                priorityValueColor[data?.priority[1]?.status]
              }
              lowPriorityColor={priorityValueColor[data?.priority[2]?.status]}
              totalValue={data?.total}
            /> */}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TaskAnalytics;
