import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import deleteN from "../../../assets/images/delete.svg";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
    marginTop: "0.6rem",
  },
  calIcon: {
    backgroundColor: "#F3F3F3",
    padding: "7px",
    borderRadius: "3px",
    width: "30px",
    height: "30px",
  },
};

const NotificationDetails = ({ data, handleClearSingleNotification }) => {
  return (
      <>
        <Stack
          width={"95%"}
          direction="row"
          alignItems="flex-start"
          spacing={2}
          margin={"auto"}
          mb={"0.8rem"}
        >
          <Box width={"95%"}>
            <Typography
              variant="h6"
              fontSize={"0.87rem"}
              fontWeight={400}
              title={data?.message}
            >
              {data?.message}
            </Typography>
          </Box>

          <Box
            onClick={() => handleClearSingleNotification(data?.id)}
            p={"0.25rem 0rem 0rem 0rem"}
            sx={{ cursor: "pointer" }}
          >
            <img src={deleteN} sx={classes.calIcon} alt="Calender" />
          </Box>
        </Stack>
      </>
  );
};

export default NotificationDetails;
