import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import google_image from "../../../assets/images/chats/google_image.svg";
import searchIcon from "../../../assets/images/chats/gallery_active.svg";
import {
  ChatSearchButton,
  CustomInput,
  SearchBox,
  SmallIcon,
} from "../../../common/chatComponents/ScreenSearchSection";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#EAEAEA",
    color: "#000000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "26px",
    width: "26px",
    padding: "4px",
    borderRadius: "2px",
    cursor: "pointer",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const GoogleDrawer = ({
  setGoogleDrawerOpen,
  queryValue,
  setQueryValue,
  handleSearch,
  googleImageList,
  handleSelectGoogleImg,
  selectedImages,
  selectedGoogleImageList,
  setGoogleImageList,
  setSelectedGoogleImageList,
  setPreviousQuery,
}) => {
  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={classes.header}
          >
            <Stack
              onClick={() => {
                setGoogleDrawerOpen(false);
                setQueryValue("");
                setPreviousQuery("");
                setGoogleImageList([]);
              }}
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Typography sx={classes.iconDiv}>
                <CloseIcon sx={classes.iconn} />
              </Typography>
              <Typography fontSize={"1rem"} variant="h6" fontWeight={400}>
                Google
              </Typography>
            </Stack>
          </Stack>

          <Stack width={"32%"} margin={"auto"} mt={1} mb={2}>
            <img src={google_image} alt="google" />
          </Stack>

          <Stack width={"90%"} margin={"auto"}>
            <SearchBox>
              <CustomInput
                value={queryValue}
                onChange={(e) => setQueryValue(e.target.value)}
                placeholder="Search"
              />
              <ChatSearchButton
                onClick={() => {
                  handleSearch();
                  setGoogleImageList([]);
                  // setSelectedGoogleImageList([]);
                }}
                size="small"
                variant="contained"
              >
                <SmallIcon src={searchIcon} />
              </ChatSearchButton>
            </SearchBox>
          </Stack>
          <>
            <Box
              width={"90%"}
              margin={"auto"}
              display={"flex"}
              flexWrap={"wrap"}
              mt={"0.7rem"}
              maxHeight={"51vh"}
              overflow={"auto"}
              height={"51vh"}
              sx={{
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "3px",
                },
              }}
            >
              {googleImageList?.map((item, index) => (
                <Box
                  boxShadow={"rgba(0, 0, 0, 0.16) 0px 1px 4px"}
                  width={"32.12%"}
                  height={"7.4rem"}
                  maxHeight={"7.4rem"}
                  mb={"0.4rem"}
                  borderRadius={"4px"}
                  overflow={"hidden"}
                  position={"relative"}
                  mr={(index + 1) % 3 === 0 ? "0rem" : "0.4rem"}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={item?.location}
                    alt="pic"
                  />
                  <Box
                    sx={{ cursor: "pointer" }}
                    zIndex={100}
                    position={"absolute"}
                    top={"0.2rem"}
                    right={"0rem"}
                  >
                    <FormControlLabel
                      disabled={
                        selectedImages?.length === 5 &&
                        !selectedGoogleImageList.some(
                          (itemInList) => itemInList.imageId === item.imageId
                        )
                      }
                      control={
                        <Checkbox
                          sx={{
                            padding: "0rem",
                            "& .MuiSvgIcon-root": {
                              backgroundColor: "white",
                              padding: "0rem",
                              color: "#183243",
                            },
                          }}
                          size="medium"
                          onChange={(event) =>
                            handleSelectGoogleImg(event, item)
                          }
                        />
                      }
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </>
          {googleImageList?.length > 0 && (
            <>
              <Box textAlign={"center"} mt={"1.5rem"}>
                <Button onClick={handleSearch}>Show More</Button>
              </Box>
              <Box textAlign={"center"} mt={"1.5rem"} position={"relative"}>
                <Button
                  style={{
                    background: "#DB0011",
                    color: "white",
                    borderRadius: "2px",
                  }}
                  variant="contained"
                  onClick={() => {
                    setGoogleDrawerOpen(false);
                    setQueryValue("");
                    setPreviousQuery("");
                    setGoogleImageList([]);
                  }}
                >
                  Done
                </Button>
                <Box
                  position={"absolute"}
                  right={"1.5rem"}
                  bottom="20%"
                  transform="translateY(-50%)"
                >
                  <Typography fontSize={"0.85rem"}>
                    {selectedImages?.length}/5
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default GoogleDrawer;
