import React from "react";
import { Drawer } from "@mui/material";
import InviteGroupMember from "./InviteGroupMember";

const AddMemberGroupDrawer = ({
  isaddmemberOpen,
  handleCloseAddPeopleDrawer,
}) => {
  return (
    <React.Fragment>
      <Drawer sx={{zIndex:1202}} open={isaddmemberOpen} anchor={"right"}>
        <InviteGroupMember
          handleCloseAddPeopleDrawer={handleCloseAddPeopleDrawer}
        />
      </Drawer>
    </React.Fragment>
  );
};

export default AddMemberGroupDrawer;
