import { Box, Drawer } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HorizontalStepper from "../commonComponent/HorizontalStepper";
import FormComponent from "../commonComponent/FormComponent";
import CreatePostComponent from "../commonComponent/CreatePostComponent";
import axios from "axios";
import GoogleDrawer from "../Drawer/GoogleDrawer";
import { generateUniqueId } from "../../../utils/copytoClipboard";
import { useDispatch, useSelector } from "react-redux";
import profileimg from "../../../assets/images/auth/profileimg.svg";
import profileBanner from "../../../assets/images/Network.svg";
import CountryDrawer from "../Drawer/CountryDrawer";
import {
  editSubscriberProfile,
  getProfile,
  updateFirmPic,
} from "../../../appStore/actions/profile/action";
import TreeViewWithCheckboxes from "../Drawer/TreeViewWithCheckboxes";
import {
  handleCreatePost,
  handleDeletePost,
  handleEditCreatePost,
} from "../../../appStore/actions/ArkchatGroup/action";
import PostCard from "../commonComponent/PostCard/PostCard";
import { serviceTypeName } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";
import { handleSavePreviewEditPostData } from "../../../appStore/actions/AppState/action";
import useAppSelectors from "../../../customHooks/useAppSelectors";
import { showToastMsg } from "../../../appStore/actions/global/action";

const PostServices = ({ activeStep, setActiveStep, setLoading, isLoading }) => {
  const { currentUserData , postServiceTypeValue} = useAppSelectors();

  const serviceCategoryData = useSelector(
    (state) => state?.serviceCategory?.serviceCategoryData
  );
  const countryData = useSelector((state) => state?.country);
  const postPreviewData = useSelector(
    (state) => state?.arkchatgroup?.postPreviewData
  );
  const postEditorPreviewData = useSelector(
    (state) => state?.appStateData?.postEditPreviewData
  );
  const [queryValue, setQueryValue] = useState("");
  const [previousQuery, setPreviousQuery] = useState("");
  const [isGoogleDrawerOpen, setGoogleDrawerOpen] = useState(false);
  const [isCountryDrawer, setCountryDrawer] = useState(false);
  const [selectedFileImageList, setSelectedFileImageList] = useState([]);
  const [selectedGoogleImageList, setSelectedGoogleImageList] = useState(
    postEditorPreviewData?.selectedImageList || []
  );
  const [googleImageList, setGoogleImageList] = useState([]); //this is for all google image getting by search
  const [googleSearchIndex, setGoogleSearchIndex] = useState(0);
  const [selectedImageList, setSelectedImageList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    postEditorPreviewData?.selectedCategory || null
  );
  const [profileData, setProfileData] = useState({});
  const [coverImage, setCoverImage] = useState({
    src: "",
    isCoverSelected: false,
    files: "",
  });
  const [profileImage, setProfileImage] = useState({
    src: "",
    isProfileSelected: false,
    files: "",
  });
  const fileImageRef = useRef();
  const coverPicRef = useRef(null);
  const profilePicRef = useRef(null);
  const submitUpdateProfileBtnRef = useRef(null);
  const createPostSubmitBtnRef = useRef(null);
  const [requiredInput, setRequiredInput] = useState("both"); //for linkdin url || website
  const [isLinkedinPopUpOpen, setLinkedinPopUpOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState(
    postEditorPreviewData?.selectedCountries ||
      (countryData?.countrylist?.data || []).map((data) => ({
        code: data.iso2,
        name: data.name,
      }))
  );
  const [isMultiSelectCountry, setMultiSelectCountry] = useState(false);
  const [createPostData, setCreatePostData] = useState(
    postEditorPreviewData?.createPostData || {
      category: "",
      core_team: {},
      subscriber_id: "",
      tags: [],
      topic_id: 0,
      type: "1",
      countries: [],
      post_data: { title: "", description: "" },
      tagsInput: "",
    }
  );
  const [isServiceCategoryModalOpen, setServiceCategoryModalOpen] =
    useState(false);
  const [isAllCountry, setAllCountry] = useState(
    countryData?.countrylist?.data?.length === selectedCountries?.length
      ? true
      : false
  );
  const [coreTeam, setCoreTeam] = useState(
    Object.keys(currentUserData?.activeSubscriber?.coreTeam || {}).length ===
        0
      ? {
          id: currentUserData?.id,
          pp: currentUserData?.profileImgUrl,
          name: `${currentUserData?.firstName} ${currentUserData?.lastName}`,
          target_subscriber_id: currentUserData?.activeSubscriber?.id,
        }
      : currentUserData?.activeSubscriber?.coreTeam
  );
  const navigate = useNavigate();
  const handleSelecteCoreTeam = (
    e,
    data,
    setProfileCoreMember,
    profileCoreMember
  ) => {
    const memberData = {
      id: data?.userId,
      pp: data?.userInfo?.profileImgUrl,
      name: `${data?.userInfo?.firstName} ${data?.userInfo?.lastName}`,
      target_subscriber_id: data?.userInfo?.activeSubscriber?.id,
    };

    if (e.target.checked) {
      let updateselectedCoreMember = { ...memberData };
      setCoreTeam(updateselectedCoreMember);
      setProfileCoreMember({ ...memberData });
    } else if (!e.target.checked) {
      setProfileCoreMember({});
      setCoreTeam({});
    }
  };

  const dispatch = useDispatch();
  // Handle GoogleSearch
  const handleSearch = async () => {
    if (queryValue) {
      const response = await axios.get(
        "https://www.googleapis.com/customsearch/v1",
        {
          params: {
            key: process.env.REACT_APP_FIREBASE_API_KEY,
            q: queryValue,
            cx: "831a5dbab64aa4a0a",
            searchType: "image",
            num: 9,
            start: googleSearchIndex,
          },
        }
      );

      const { items } = response?.data;
      setGoogleSearchIndex(
        response?.data?.items &&
          response?.data?.queries?.nextPage &&
          response?.data?.queries?.nextPage[0]?.startIndex
      );

      const filteredGoogleLink =
        response?.data?.items &&
        items?.map((item, index) => {
          return {
            location: item?.link,
            name: item?.title,
            imageId: generateUniqueId(item?.title),
          };
        });
      if (
        filteredGoogleLink?.length > 0 &&
        (queryValue === previousQuery || previousQuery === "")
      ) {
        setGoogleImageList([...googleImageList, ...filteredGoogleLink]);
      } else {
        setGoogleImageList([...filteredGoogleLink]);
      }

      setPreviousQuery(queryValue);
    }
  };
  const handleSelectGoogleImg = (event, item) => {
    if (event.target.checked) {
      setSelectedGoogleImageList([...selectedGoogleImageList, item]);
    } else {
      const filteredData = selectedGoogleImageList?.filter(
        (listData) => listData?.location !== item?.location
      );
      setSelectedGoogleImageList(filteredData);
    }
  };
  // Handling Image Selection From Device
  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const maxFiles = 5;
    if (
      selectedFileImageList?.length + files?.length > maxFiles ||
      selectedImageList?.length + files?.length > maxFiles
    ) {
      e.preventDefault();
      dispatch(showToastMsg("You can only select up to 5 attachment."));
      return;
    }

    const imageListWithIds = Array.from(files).map((file) => ({
      imageId: generateUniqueId(file?.name),
      file: file,
    }));

    setSelectedFileImageList([...selectedFileImageList, ...imageListWithIds]);
  };
  // Removing Image From Slected List
  const cancleSelectedImage = (imageData) => {
    const updatedFileImageList = selectedFileImageList?.filter(
      (item) => item?.imageId !== imageData?.imageId
    );
    const updatedGoogleImageList = selectedGoogleImageList?.filter(
      (item) => item?.imageId !== imageData?.imageId
    );
    setSelectedFileImageList(updatedFileImageList);
    setSelectedGoogleImageList(updatedGoogleImageList);
    setSelectedImageList({
      ...updatedGoogleImageList,
      ...updatedFileImageList,
    });
  };
  useEffect(() => {
    const allFilesData = [...selectedFileImageList, ...selectedGoogleImageList];
    setSelectedImageList(allFilesData);
  }, [selectedFileImageList, selectedGoogleImageList]);

  useEffect(() => {
    setProfileData({
      city: currentUserData?.activeSubscriber?.city || "",
      email: currentUserData?.activeSubscriber?.email || "",
      country: currentUserData?.activeSubscriber?.country || "",
      country_code: currentUserData?.activeSubscriber?.countryCode || "",
      subscriberFirmName:
        currentUserData?.activeSubscriber?.subscriberFirmName || "",
      website: currentUserData?.activeSubscriber?.website || "",
      profile_brief: currentUserData?.activeSubscriber?.profileBrief || "",
      linkedin: currentUserData?.activeSubscriber?.linkedin || "",
    });
    setCoverImage({
      ...coverImage,
      src: currentUserData?.activeSubscriber?.coverImgUrl
        ? currentUserData?.activeSubscriber?.coverImgUrl
        : profileBanner,
    });
    setProfileImage({
      ...profileImage,
      src: currentUserData?.activeSubscriber?.profileImgUrl
        ? currentUserData?.activeSubscriber?.profileImgUrl
        : profileimg,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // for linkdin url || website
    if (name === "linkedin") {
      setRequiredInput("linkedin");
    } else if (name === "website") {
      setRequiredInput("website");
    }
    if (name === "profile_brief") {
      // Limiting the input character
      if (value.length <= 160) {
        setProfileData({
          ...profileData,
          [name]: value,
        });
      }
    }
    if (name !== "profile_brief") {
      setProfileData({
        ...profileData,
        [name]: value,
      });
    }
  };

  const handleCountryInputChange = (country, isMultiSelectCountry) => {
    if (isMultiSelectCountry) {
      const isSelected = selectedCountries.some((c) => c.code === country.code);
      if (isSelected) {
        // If already selected, remove it from the selected list
        setAllCountry(false);
        setSelectedCountries(
          selectedCountries?.filter((c) => c.code !== country.code)
        );
      } else {
        // If not selected, add it to the selected list
        setSelectedCountries([...selectedCountries, country]);
      }
    } else {
      setProfileData({
        ...profileData,
        country: country?.name,
        country_code: country?.iso2,
      });
      setCountryDrawer(false);
    }
  };

  const handleClickAllCountry = () => {
    if (isAllCountry) {
      setSelectedCountries([]);
      setAllCountry(false);
    } else {
      setAllCountry(true);
      setSelectedCountries(
        countryData?.countrylist?.data.map((data) => ({
          code: data.iso2,
          name: data.name,
        }))
      );
    }
  };
  const handleProfileImgInputChange = (event) => {
    const file = event.target.files[0];
    if (event.target.name === "cover") {
      setCoverImage({
        ...coverImage,
        src: URL.createObjectURL(file),
        isCoverSelected: true,
        files: file,
      });
    } else {
      setProfileImage({
        ...profileImage,
        src: URL.createObjectURL(file),
        isProfileSelected: true,
        files: file,
      });
    }
  };

  const handleCameraButtonClick = (type) => {
    if (type === "cover") {
      coverPicRef.current.click();
    } else {
      profilePicRef.current.click();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reqBody = {
      subscriber_firm_name: profileData?.subscriberFirmName,
      website: profileData?.website,
      city: profileData?.city,
      country: profileData?.country,
      country_code: profileData?.country_code,
      linkedin: profileData?.linkedin,
      profile_brief: profileData?.profile_brief,
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(reqBody));
    dispatch(
      editSubscriberProfile(formData, currentUserData?.activeSubscriber?.id)
    );
    // call profile api also
    if (coverImage?.isCoverSelected) {
      const formData = new FormData();
      formData.append("files", coverImage?.files);
      formData.append("data", JSON.stringify({ is_cover_image: true }));
      dispatch(updateFirmPic(formData, currentUserData?.activeSubscriber?.id));
    }

    if (profileImage?.isProfileSelected) {
      const formData = new FormData();
      formData.append("files", profileImage?.files);
      formData.append("data", JSON.stringify({ is_cover_image: false }));
      // eslint-disable-next-line no-unused-vars
      const updatedImgres = await dispatch(
        updateFirmPic(formData, currentUserData?.activeSubscriber?.id)
      );
    }
    handleNext();
  };

  // stepper Functions
  const handleStepClick = (step) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };
  const handlePrev = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const handleClick = () => {
    if (activeStep === 0) {
      submitUpdateProfileBtnRef.current.click();
    } else if (activeStep === 1) {
      createPostSubmitBtnRef.current.click();
    }
  };

  const handleCreatePostSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (selectedImageList?.length > 0) {
      const isEditPost = JSON.parse(localStorage.getItem("isEditPost"));
      const reqBody = {
        category: selectedCategory?.id,
        core_team: {},
        subscriber_id: currentUserData?.activeSubscriber?.id,
        tags: createPostData?.tags,
        topic_id: 0,
        type: String(+postServiceTypeValue),
        countries: selectedCountries?.map((item) => item?.name),
        post_data: createPostData?.post_data,
        attachments:
          selectedGoogleImageList?.length > 0 ? selectedGoogleImageList : [],
      };
      const formData = new FormData();
      formData.append("data", JSON.stringify(reqBody));
      if (selectedFileImageList?.length > 0) {
        selectedFileImageList?.forEach((file) =>
          formData.append("files", file?.file)
        );
      }
      if (isEditPost) {
        dispatch(
          handleEditCreatePost(formData, +localStorage.getItem("post_id"))
        ).then((res) => {
          setLoading(false);
          if (res?.status) {
            const localPreviewData = {
              selectedCategory: selectedCategory,
              createPostData: createPostData,
              selectedCountries: selectedCountries,
              selectedImageList: res?.data?.data?.post?.attachments,
              post_id: res?.data?.data?.post?.id,
            };
            dispatch(handleSavePreviewEditPostData(localPreviewData));
            localStorage.setItem(
              "post_id",
              JSON.stringify(res?.data?.data?.post?.id)
            );
            handleNext();
          }
        });
      } else {
        dispatch(handleCreatePost(formData)).then((res) => {
          setLoading(false);
          if (res?.status) {
            const localPreviewData = {
              selectedCategory: selectedCategory,
              createPostData: createPostData,
              selectedCountries: selectedCountries,
              selectedImageList: res?.data?.data?.post?.attachments,
              post_id: res?.data?.data?.post?.id,
            };
            dispatch(handleSavePreviewEditPostData(localPreviewData));
            localStorage.setItem(
              "post_id",
              JSON.stringify(res?.data?.data?.post?.id)
            );
            handleNext();
          }
        });
      }
    } else {
      setLoading(false);
      dispatch(showToastMsg("Add minimum 1 Attachment"));
    }
  };
  const handlePublish = () => {
    setLoading(true);
    const reqBody = {
      category: selectedCategory?.id,
      subscriber_id: currentUserData?.activeSubscriber?.id,
      tags: createPostData?.tags,
      topic_id: 0,
      type: String(+postServiceTypeValue),
      countries: selectedCountries?.map((item) => item?.name),
      post_data: createPostData?.post_data,
      attachments:
        postEditorPreviewData?.selectedImageList?.length > 0
          ? postEditorPreviewData?.selectedImageList
          : [],
      core_team: coreTeam,
      is_draft: false,
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(reqBody));
    if (Object.keys(coreTeam).length > 0) {
      dispatch(
        handleEditCreatePost(formData, +localStorage.getItem("post_id"))
      ).then((res) => {
        setLoading(false);
        if (res?.status) {
          setSelectedCategory(null);
          setCreatePostData({
            category: "",
            core_team: {},
            subscriber_id: "",
            tags: [],
            topic_id: 0,
            type: "1",
            countries: [],
            post_data: { title: "", description: "" },
            tagsInput: "",
          });
          setSelectedGoogleImageList([]);
          setSelectedCountries(
            (countryData?.countrylist?.data || []).map((data) => ({
              code: data.iso2,
              name: data.name,
            }))
          );

          if (currentUserData?.activeSubscriber?.accountServiceType === 0) {
            const editSubscriberBody = {
              account_service_type: serviceTypeName?.Service,
            };
            const editSubscriberFormData = new FormData();
            editSubscriberFormData.append(
              "data",
              JSON.stringify(editSubscriberBody)
            );
            dispatch(
              editSubscriberProfile(
                editSubscriberFormData,
                currentUserData?.activeSubscriber?.id
              )
            ).then((res) => dispatch(getProfile()));
          } else {
            dispatch(getProfile());
          }
          localStorage.removeItem("post_id");
          dispatch(handleSavePreviewEditPostData({}));
          navigate("/explore", { replace: true });
        }
      });
    } else {
      setLoading(false);
      dispatch(showToastMsg("Select Member "));
    }
  };
  const handleDeletePostById = () => {
    dispatch(handleDeletePost(+localStorage.getItem("post_id"))).then((res) => {
      if (res?.status) {
        setSelectedCategory(null);
        setCreatePostData({
          category: "",
          core_team: {},
          subscriber_id: "",
          tags: [],
          topic_id: 0,
          type: "1",
          countries: [],
          post_data: { title: "", description: "" },
          tagsInput: "",
        });
        setSelectedGoogleImageList([]);
        setSelectedCountries(
          (countryData?.countrylist?.data || []).map((data) => ({
            code: data.iso2,
            name: data.name,
          }))
        );
        localStorage.removeItem("post_id");
        dispatch(handleSavePreviewEditPostData({}));

        handlePrev();
      }
    });
  };

  const handleCreatePostInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "description") {
      if (value?.length <= 2000) {
        setCreatePostData({
          ...createPostData,
          post_data: { ...createPostData?.post_data, [name]: value },
        });
      }
    }
    if (name === "title") {
      setCreatePostData({
        ...createPostData,
        post_data: { ...createPostData?.post_data, [name]: value },
      });
    }
    if (name === "tagsInput") {
      setCreatePostData({
        ...createPostData,
        [name]: value,
      });
    }
  };
  const handleTagKeyDown = (e) => {
    if (e.key === "Enter" && createPostData?.tagsInput.trim() !== "") {
      e.preventDefault();
      if (!createPostData?.tags.includes(createPostData?.tagsInput)) {
        setCreatePostData({
          ...createPostData,
          tags: [...createPostData?.tags, `#${createPostData?.tagsInput}`],
          tagsInput: "",
        });
      }
    }
  };
  const handleRemoveTag = (tagData) => {
    const updatedTags = createPostData?.tags?.filter(
      (item) => item !== tagData
    );
    setCreatePostData({
      ...createPostData,
      tags: [...updatedTags],
    });
  };
  const handleNodeSelect = (event, categoryData) => {
    setSelectedCategory(categoryData);
    setServiceCategoryModalOpen(false);
  };
  const stepContent = [
    <FormComponent
      type={1}
      accountTypeValue={currentUserData?.activeSubscriber?.subscriberPriority}
      currentUserData={currentUserData}
      profileData={profileData}
      profileImage={profileImage}
      handleInputChange={handleInputChange}
      coverImage={coverImage}
      handleProfileImgInputChange={handleProfileImgInputChange}
      handleCameraButtonClick={handleCameraButtonClick}
      profilePicRef={profilePicRef}
      coverPicRef={coverPicRef}
      handleSubmit={handleSubmit}
      countryDrawer={setCountryDrawer}
      submitUpdateProfileBtnRef={submitUpdateProfileBtnRef}
      requiredInput={requiredInput}
      setLinkedinPopUpOpen={setLinkedinPopUpOpen}
      isLinkedinPopUpOpen={isLinkedinPopUpOpen}
      setCoverImage={setCoverImage}
      setProfileImage={setProfileImage}
      profileBanner={profileBanner}
      profileimg={profileimg}
    />,
    <CreatePostComponent
      images={selectedImageList}
      handleFileInputChange={handleFileInputChange}
      setGoogleDrawerOpen={setGoogleDrawerOpen}
      cancleSelectedImage={cancleSelectedImage}
      fileImageRef={fileImageRef}
      setMultiSelectCountry={setMultiSelectCountry}
      createPostData={createPostData}
      handleCreatePostInputChange={handleCreatePostInputChange}
      handleTagKeyDown={handleTagKeyDown}
      handleRemoveTag={handleRemoveTag}
      countryValue={
        countryData?.countrylist?.data?.length === selectedCountries?.length
          ? "Global"
          : "Selected Country"
      }
      setCategoryModalOpen={setServiceCategoryModalOpen}
      selectedCategory={selectedCategory}
      createPostSubmitBtnRef={createPostSubmitBtnRef}
      handleCreatePostSubmit={handleCreatePostSubmit}
      titleCaption={"Describe your service in 2-3 words"}
      visiblityCaption={
        "Your service will be visible to users in selected countries."
      }
      descriptionCaption={"Write something about your service"}
      descriptionHeading={"Description"}
    />,
    <PostCard
      type={
        currentUserData?.activeSubscriber?.subscriberPriority === 2
          ? "Professional Service"
          : "Freelance service"
      }
      postPreviewData={postPreviewData}
      handleSelecteCoreTeam={handleSelecteCoreTeam}
      handlePublish={handlePublish}
      setActiveStep={setActiveStep}
      isLoading={isLoading}
      handleDeletePostById={handleDeletePostById}
    />,
  ];
  return (
    <Box>
      <HorizontalStepper
        stepContent={stepContent}
        setQueryValue={setQueryValue}
        setGoogleDrawerOpen={setGoogleDrawerOpen}
        handleStepClick={handleStepClick}
        handleNext={handleNext}
        activeStep={activeStep}
        handleClick={handleClick}
        setActiveStep={setActiveStep}
        isLoading={isLoading}
      />

      <Drawer sx={{ zIndex: 1202 }} open={isGoogleDrawerOpen} anchor={"right"}>
        <GoogleDrawer
          setQueryValue={setQueryValue}
          queryValue={queryValue}
          handleSearch={handleSearch}
          setGoogleDrawerOpen={setGoogleDrawerOpen}
          googleImageList={googleImageList}
          handleSelectGoogleImg={handleSelectGoogleImg}
          selectedImages={selectedImageList}
          selectedGoogleImageList={selectedGoogleImageList}
          setGoogleImageList={setGoogleImageList}
          setSelectedGoogleImageList={setSelectedGoogleImageList}
          setPreviousQuery={setPreviousQuery}
        />
      </Drawer>
      <Drawer sx={{ zIndex: 1202 }} open={isCountryDrawer} anchor={"right"}>
        <CountryDrawer
          handleCountryInputChange={handleCountryInputChange}
          setCountryDrawer={setCountryDrawer}
        />
      </Drawer>
      <Drawer
        sx={{ zIndex: 1202 }}
        open={isMultiSelectCountry}
        anchor={"right"}
      >
        <CountryDrawer
          handleCountryInputChange={handleCountryInputChange}
          setCountryDrawer={setMultiSelectCountry}
          isMultiSelectCountry={isMultiSelectCountry}
          selectedCountries={selectedCountries}
          isAllCountry={isAllCountry}
          handleClickAllCountry={handleClickAllCountry}
        />
      </Drawer>
      <Drawer
        sx={{ zIndex: 1202 }}
        open={isServiceCategoryModalOpen}
        anchor={"right"}
      >
        <TreeViewWithCheckboxes
          data={serviceCategoryData}
          onNodeSelect={handleNodeSelect}
          selectedCategory={selectedCategory}
          setServiceCategoryModalOpen={setServiceCategoryModalOpen}
        />
      </Drawer>
    </Box>
  );
};

export default PostServices;
