import React, { useState } from "react";
import { FormControl } from "@mui/base";
import {
  FormHelperText,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useTranslation } from "react-i18next";
import { AuthActionButton } from "../../../common/StyledComponents/AuthComponents";
import { useDispatch } from "react-redux";
import { encrypter } from "../../../utils/encryptpassword";
import { resetOldPassword } from "../../../appStore/actions/auth/resetpassword/resetpassword";


const classes = {
  blockInputBox: {
    width: "100%",
    marginTop: "1.5rem",
  },
  captions: {
    fontSize: "0.87rem !important",
    display: "block",
  },
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "1rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const CreateNewPassword = ({
  setResetPasswordDrawerOpen,
  isResetPassword = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");

  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [passwordError, setPasswordError] = useState(false);
  const dispatch = useDispatch();
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleReEnterPassword = () => {
    setShowRePassword(!showRePassword);
  };
  const handleToggleOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  //  validation password
  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&&()#`./<>{}[\]:;"])[A-Za-z\d@$!%*?&&()#`./<>{}[\]:;"]{8,}$/;
    return passwordRegex.test(password);
  };
  const handleEncrypt = (text) => {
    const encrypted = encrypter.encrypt(text);
    return encrypted;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let result = validatePassword(newPassword);
    setPasswordError(!result);
    if (newPassword === reEnterPassword && result) {
      const encryptedText = handleEncrypt(newPassword);
      const oldPass = handleEncrypt(oldPassword);
      if (encryptedText) {
        let formBody = {
          confirm_password: encryptedText,
          new_password: encryptedText,
          old_password: oldPass,
        };
        dispatch(resetOldPassword(formBody)).then((res) => {
          setResetPasswordDrawerOpen(false);
        });
      }
    }
  };
  return (
    <Box sx={classes.drawerBody}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => setResetPasswordDrawerOpen(false)}
          />
        </Typography>
        <Typography color={"black"}>{appContent?.resetPassword}</Typography>
      </Stack>

      <Box width={"90%"} margin={"auto"}>
        <Box>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <TextField
                type={showOldPassword ? "text" : "password"}
                label={appContent?.oldPassword}
                variant="standard"
                name="oldPassword"
                onChange={(e) => {
                  setOldPassword(e.target.value.trim());
                }}
                required
                sx={classes.blockInputBox}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleToggleOldPassword}>
                        {showOldPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <RemoveRedEyeIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            <FormControl>
              <TextField
                type={showPassword ? "text" : "password"}
                label={appContent?.newpassword}
                variant="standard"
                name="password"
                error={passwordError ? true : false}
                onChange={(e) => {
                  setNewPassword(e.target.value.trim());
                  setPasswordError(!validatePassword(e.target.value.trim()));
                }}
                required
                sx={classes.blockInputBox}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword}>
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <RemoveRedEyeIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText error={passwordError ? true : false}>
                {" "}
                {appContent?.passwordRequirements}
              </FormHelperText>
            </FormControl>

            <FormControl>
              <TextField
                type={showRePassword ? "text" : "password"}
                label={appContent?.reEnterPassword}
                variant="standard"
                name="reEnterPassword"
                error={
                  newPassword?.length > 0 &&
                  reEnterPassword?.length > 0 &&
                  newPassword !== reEnterPassword
                    ? true
                    : false
                }
                onChange={(e) => {
                  setReEnterPassword(e.target.value.trim());
                }}
                required
                sx={classes.blockInputBox}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleToggleReEnterPassword}>
                        {showRePassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <RemoveRedEyeIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText>{appContent?.required}</FormHelperText>
            </FormControl>
            <Box
              sx={{ position: "absolute", bottom: "2rem" }}
              width={"98%"}
              textAlign={"center"}
            >
              <AuthActionButton
                type="submit"
                variant="contained"
                disabled={
                  newPassword.length > 0 &&
                  oldPassword?.length > 0 &&
                  newPassword === reEnterPassword
                    ? false
                    : true
                }
              >
                {appContent?.resetPassword}
              </AuthActionButton>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateNewPassword;
