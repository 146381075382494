import { GET_COUNTRY, GET_COUNTRY_ERROR } from "../../actions/masterData";


const initState = {
    data: [],
    error: null,
    status: null,
    currentlogedInuser: null,
};

export const countryReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_COUNTRY:
            return {
                ...state,
                countrylist: {
                    data: action?.payload,
                    status: action?.payload?.data?.status,
                    error: null,
                },
            };
        case GET_COUNTRY_ERROR:
            return {
                ...state,
                countrylist: {
                    data: action?.payload,
                    status: action?.payload?.state,
                    error: null,
                },
            };
        default:
            return state;
    }
};
