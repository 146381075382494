import React, { useState } from "react";
import {
  FormHelperText,
  Grid,
  Link,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IntroVideo from "../../common/IntroVideo";
import { AuthActionButton } from "../../common/StyledComponents/AuthComponents";
import { showToastMsg } from "../../appStore/actions/global/action";
import { handleOtpVerifyDeleteAccountFromOpenRoute } from "../../appStore/actions/AppState/action";
import ConfirmationPopUp from "./ConfirmationPopUp";



const classes = {
  captions: {
    fontSize: "0.87rem !important",
    display: "block",
  },
  verifyText: {
    color: "red !important",
  },
  otpBoxWrapper: {
    display: "grid",
    alignItems: "center",
    "@media only screen and (max-width:899px)": {
      marginBottom: "25px",
    },
    "@media only screen and (min-width:1199px)": {
      paddingRight: "20px",
    },
  },
  otpBox: {
    padding: "1.5rem 2rem 2rem 2rem",
    border: "1px solid #C4C4C4",
    height: "calc(100vh - 32px)",
    maxHeight: "700px",
    minHeight: "590px",
    width: "100%",
    maxWidth: "690px",
    margin: "auto",
    position: "relative",
    "@media only screen and (max-width:900px)": {
      margin: "auto !important",
      padding: "1.5rem 2rem 2rem 2rem !important",
    },
    "@media only screen and (max-width:1024px)": {
      height: "100%",
      padding: "1rem",
      marginLeft: "-5px",
    },
    "@media only screen and (max-width:1300px)": {
      minHeight: "530px",
    },
  },
  otpBtn: {
    position: "absolute",
    bottom: "2rem",
    left: "50%",
    marginLeft: "-2.72rem",
    "@media only screen and (max-width:899px)": {
      position: "relative",
      marginBottom: "-1.8rem",
      bottom: "0 !important",
    },
    "@media only screen and (max-width:1300px)": {
      bottom: "1.5rem",
    },
  },
  pointer: {
    cursor: "pointer",
  },
  mobscroll: {
    overflowX: "hidden",
    "@media only screen and (max-width:899px)": {
      overflowX: "hidden",
    },
  },
};
const DeleteAccountVerifyOtp = () => {
  const [otp, setOtp] = useState("");
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const openRouteDeleteAccountDetail = useSelector(
    (state) => state?.localAppReducer?.openRouteDeleteAccountDetail
  );
//   const { time: remainingTime, resetTimer } = useTimer(60);
//   const isRemember = getCookie("isRemember");

//   useEffect(() => {
//     if (remainingTime === 0) {
//       setCanResend(true);
//     }
//   }, [remainingTime]);
  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleverify=()=>{
   setPopupOpen(true);
  }
  const handleConfirm = () => {
    if(otp){
      dispatch(
        handleOtpVerifyDeleteAccountFromOpenRoute({
          email: openRouteDeleteAccountDetail?.email,
          user_id: openRouteDeleteAccountDetail?.id,
          opt:otp
        })
      ).then((res) => {
        if(res?.status){
          dispatch(showToastMsg(res?.message));
        }else{
          dispatch(showToastMsg(res?.message||"Something went wrong !"));
        }
        setPopupOpen(false);
      });
    }
  };



  return (
    <Box m={2}>
      <ConfirmationPopUp
        open={isPopupOpen}
        closeModal={setPopupOpen}
        title={appContent?.deleteAccountConfirmationTitle}
        handleDone={handleConfirm}
      />
      <Grid container justifyContent="space-between" sx={classes.mobscroll}>
        <Grid item xs={12} md={5}>
          <IntroVideo />
        </Grid>
        <Grid item xs={12} md={6} sx={classes.otpBoxWrapper}>
          <Box sx={classes.otpBox}>
            <Typography fontSize={"1.7rem"} fontWeight={600} variant="h5">
              {appContent?.twoFactorHeading}
            </Typography>
            <Typography mt={"0.6rem"} sx={classes.captions} variant="caption">
              {appContent?.twoFactorEmailSentDescription}
            </Typography>
            <Typography mt={"0.2rem"} sx={classes.captions}>
              {openRouteDeleteAccountDetail?.email}
            </Typography>

            <Box mt={"2.5rem"}>
              <Box mb={"0.6rem"}>
                <Typography sx={classes.captions}>
                  {appContent?.verificationCode}
                </Typography>
              </Box>
              <Box width={"21.5rem"}>
                <MuiOtpInput
                  TextFieldsProps={{
                    type: "text",
                    inputMode: "numeric",
                    size: "small",
                  }}
                  length={6}
                  value={otp}
                  onChange={handleChange}
                />
              </Box>
              {showError ? (
                <FormHelperText sx={classes.verifyText}>
                  {appContent?.twoFactorEnterValidCode}
                </FormHelperText>
              ) : (
                <></>
              )}
              {/* <Box mt={"1rem"}>
                <Typography color={"#183242"} variant="caption">
                  {appContent?.twoFactorDidntReceiveCode}{" "}
                  <Link
                    onClick={reSendEmail}
                    style={{
                      textDecoration: "none",
                      color: canResend ? "#59A0FF" : "#183242",
                      cursor: canResend ? "pointer" : "not-allowed",
                    }}
                  >
                    {" "}
                    {appContent?.resendEmail}
                  </Link>
                </Typography>
              </Box> */}
             
              <Box mt={"1.4rem"}>
                <Typography
                  fontWeight={400}
                  fontSize={"0.87rem"}
                  color={"#183242"}
                  variant="caption"
                >
                  {appContent?.checkEmailFolders}
                </Typography>
              </Box>

              <Box sx={classes.otpBtn}>
                <AuthActionButton
                  type="button"
                  variant="contained"
                  disabled={otp.length >= 6 ? false : true}
                  onClick={()=>!isLoading?handleverify():""}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    appContent?.verify
                  )}
                </AuthActionButton>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeleteAccountVerifyOtp;
