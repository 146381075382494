import { Box} from "@mui/material";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";

const Calender = ({ handleDateChange, dueDate }) => {
  const currentDate = dayjs();
  const minSelectableDate = currentDate;
  return (
    <>

        <Box maxWidth={"100%"}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              sx={{ maxWidth: "100%"}}
              onChange={handleDateChange}
              value={dueDate}
              minDate={minSelectableDate}
            />
          </LocalizationProvider>
        </Box>
    </>
  );
};

export default Calender;
