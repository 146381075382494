import {
  requestDeleteGroupFromDb,
  requestUpdateGroupDb,
} from "../../../appStore/actions/ArkchatGroup/action";
import { saveActivityIndicator } from "../../../appStore/actions/global/action";
import { PubnubEventType } from "../../../utils/constant";
import { checkDoesHaveSid } from "../../../utils/helper";
// NOTE : activeGroup = selectedGroup both are one it's just naming.*****
export const updateGroupSignal = async (
  sidList,
  loggedInUserActiveSubsId,
  loggedInUserId,
  signalData,
  dispatch,
  requestallmemberswithgroups,
  selectedGroup,
  setisTopicOpen,
  setGroupname,
  saveSelectedAppGroupData,
  handleSwitchAccountRefresh,
  switchAccountRefresh
) => {
  const haveSidInList = checkDoesHaveSid(sidList, signalData?.sid);
  if (
    (loggedInUserActiveSubsId === signalData?.content?.sid &&
      signalData?.content?.uuid?.includes(loggedInUserId)) ||
    (loggedInUserActiveSubsId !== signalData?.content?.sid &&
      signalData?.content?.uuid?.includes(loggedInUserId) &&
      !haveSidInList)
  ) {
    if (signalData?.content?.type !== PubnubEventType?.GROUP_DELETED) {
      if (signalData?.content?.type === PubnubEventType?.GROUP_EDITED) {
        dispatch(requestUpdateGroupDb(signalData?.data));
        if (
          selectedGroup?.id === signalData?.content?.gid &&
          selectedGroup?.isGroup
        ) {
          setisTopicOpen(true);
          setGroupname(signalData?.data?.name);
          dispatch(saveSelectedAppGroupData(signalData?.data));
        }
      } else {
        //for new group case handle
        dispatch(
          requestallmemberswithgroups({
            isSignalData: true,
            createdData: signalData?.data,
          })
        );
      }
    } else if (signalData?.content?.type === PubnubEventType?.GROUP_DELETED) {
      dispatch(requestDeleteGroupFromDb(signalData?.content?.pnId)).then(
        (res) => {
          if (
            selectedGroup?.id === signalData?.content?.gid &&
            selectedGroup?.isGroup
          ) {
            dispatch(handleSwitchAccountRefresh(!switchAccountRefresh));
          }
        }
      );
    }
  } else {
    dispatch(saveActivityIndicator(loggedInUserId, signalData?.content?.sid));
  }
  return;
};

export const memberAddedToGrpSignal = (
  loggedInUserActiveSubsId,
  loggedInUserId,
  signalData,
  dispatch,
  requestallmemberswithgroups,
  requestgroupMemberDetails,
  requestUpdateFromSignalGroupRecipient,
  selectedGroup,
  handleSwitchAccountRefresh,
  switchAccountRefresh,
  sidList
) => {
  const isCurrentUserInvolved =
    signalData?.uuid.includes(loggedInUserId) ||
    signalData?.uid.includes(loggedInUserId);
  const isCurrentGroupSelected =
    selectedGroup?.id === signalData?.gid && selectedGroup?.isGroup;
  const isSignalForCurrentUser = loggedInUserActiveSubsId === signalData?.sid;
  const haveSidInList = checkDoesHaveSid(sidList, signalData?.sid);
  const isRemoveOrExitEvent =
    signalData?.type === PubnubEventType?.USER_REMOVED_FROM_GROUP ||
    signalData?.type === PubnubEventType?.USER_EXITED_THE_GROUP;
  const isGroupListUpdateEvent =
    signalData?.type === PubnubEventType?.USER_GROUP_LIST_UPDATED;

  const refreshGroupMemberDetails = () => {
    const memberreqBody = {
      group_id: selectedGroup?.id,
      target_subscriber_id: loggedInUserActiveSubsId,
    };
    dispatch(requestUpdateFromSignalGroupRecipient(signalData?.recipients));
    dispatch(requestgroupMemberDetails(memberreqBody));
  };

  if (
    (isSignalForCurrentUser && isCurrentUserInvolved) ||
    (!isSignalForCurrentUser && !haveSidInList && isCurrentUserInvolved)
  ) {
    if (!isRemoveOrExitEvent && !isGroupListUpdateEvent) {
      dispatch(requestallmemberswithgroups({ forceFetch: true }));
      if (isCurrentGroupSelected) refreshGroupMemberDetails();
    } else if (isRemoveOrExitEvent) {
      if (signalData?.uid.includes(loggedInUserId)) {
        if (isCurrentGroupSelected) {
          dispatch(handleSwitchAccountRefresh(!switchAccountRefresh));
        } else {
          dispatch(requestallmemberswithgroups({ forceFetch: true }));
        }
      } else if (isCurrentGroupSelected) {
        refreshGroupMemberDetails();
      }
    } else if (isGroupListUpdateEvent && isCurrentGroupSelected) {
      refreshGroupMemberDetails();
    }
  } else {
    dispatch(saveActivityIndicator(loggedInUserId, signalData?.sid));
  }
};

export const updateGroupTopicSignal = (
  loggedInUserActiveSubsId,
  loggedInUserId,
  signalData,
  dispatch,
  getAlltopic,
  selectedGroup,
  sidList
) => {
  const haveSidInList = checkDoesHaveSid(sidList, signalData?.sid);

  if (
    ((loggedInUserActiveSubsId === signalData?.sid &&
      signalData?.uuid.includes(loggedInUserId)) ||
      (!haveSidInList && signalData?.uuid.includes(loggedInUserId))) &&
    selectedGroup?.id === signalData?.gid &&
    selectedGroup?.isGroup
  ) {
    //
    dispatch(getAlltopic({ group_id: signalData?.gid }));
  } else if (
    loggedInUserActiveSubsId !== signalData?.sid &&
    signalData?.uuid?.includes(loggedInUserId)
  ) {
    dispatch(saveActivityIndicator(loggedInUserId, signalData?.sid));
  }
  return;
};

export const handleContactPermissionUpdate = (
  loggedInUserActiveSubsId,
  loggedInUserId,
  signalData,
  dispatch,
  requestGetAllAcountLevelMember,
  getProfile,
  requestallmemberswithgroups,
  selectedGroup,
  requestgroupMemberDetails,
  groupRecipientDetails,
  handleSwitchAccountRefresh,
  switchAccountRefresh
) => {
  // if we also get permission type to which permission is update maybe we can optmise it.(idea for next phase)
  if (
    loggedInUserActiveSubsId === signalData?.sid &&
    signalData?.uuid?.includes(loggedInUserId)
  ) {
    const userExistsInActiveGroup = groupRecipientDetails?.some(
      (obj) => obj.userId === signalData?.uid[0]
    );
    dispatch(requestGetAllAcountLevelMember(true));
    if (!signalData?.uid.includes(loggedInUserId) || userExistsInActiveGroup) {
      const memberreqBody = {
        group_id: selectedGroup?.id,
        target_subscriber_id: loggedInUserActiveSubsId,
      };
      dispatch(requestgroupMemberDetails(memberreqBody));
    }
    if (signalData?.uid.includes(loggedInUserId)) {
      dispatch(getProfile(true));
      dispatch(requestallmemberswithgroups({ forceFetch: true }));
      if (!userExistsInActiveGroup) {
        dispatch(handleSwitchAccountRefresh(!switchAccountRefresh));
      }
    }
  } else if (
    loggedInUserActiveSubsId !== signalData?.sid &&
    signalData?.uuid?.includes(loggedInUserId)
  ) {
    dispatch(saveActivityIndicator(loggedInUserId, signalData?.sid));
  }
};


export const handleContactAdded = (
  loggedInUserActiveSubsId,
  loggedInUserId,
  signalData,
  dispatch,
  requestGetAllAcountLevelMember,
  requestallmemberswithgroups,
  requestgroupMemberDetails,
  requestGetGroupRecipient,
  selectedGroup
) => {
  if (loggedInUserActiveSubsId === signalData?.sid) {
    dispatch(requestGetAllAcountLevelMember());
    dispatch(requestallmemberswithgroups({ forceFetch: true }));
    const memberreqBody = {
      group_id: selectedGroup?.id,
      target_subscriber_id: loggedInUserActiveSubsId,
    };
    if (selectedGroup?.isGroup) {
      dispatch(requestGetGroupRecipient(selectedGroup?.id));
      dispatch(requestgroupMemberDetails(memberreqBody));
    }
  } else if (
    loggedInUserActiveSubsId !== signalData?.sid &&
    signalData?.uuid?.includes(loggedInUserId)
  ) {
    dispatch(saveActivityIndicator(loggedInUserId, signalData?.sid));
  }
};

export const handleGetInvitationList = (
  loggedInUserActiveSubsId,
  loggedInUserId,
  signalData,
  dispatch,
  getAllPendingInvitation
) => {
  if (loggedInUserActiveSubsId === signalData?.sid) {
    dispatch(getAllPendingInvitation());
  } else if (
    loggedInUserActiveSubsId !== signalData?.sid &&
    signalData?.uuid?.includes(loggedInUserId)
  ) {
    dispatch(saveActivityIndicator(loggedInUserId, signalData?.sid));
  }
};

const handleRedirection = (
  response,
  isAtchat,
  navigate,
  dispatch,
  handleSwitchAccountRefresh,
  switchAccountRefresh
) => {
  if (isAtchat) {
    if (response?.accountOwner) {
      if (response?.isFirstGroupCreated && response?.isFirstInviteSent) {
        dispatch(handleSwitchAccountRefresh(!switchAccountRefresh));
      } else if (
        response?.isFirstGroupCreated &&
        !response?.isFirstInviteSent
      ) {
        navigate("/invite");
      } else {
        navigate("/createfirstgroup");
      }
    } else {
      dispatch(handleSwitchAccountRefresh(!switchAccountRefresh));
    }
  } else {
    if (response?.accountOwner) {
      if (response?.isFirstGroupCreated && response?.isFirstInviteSent) {
        navigate("/chat");
      } else if (
        response?.isFirstGroupCreated &&
        !response?.isFirstInviteSent
      ) {
        navigate("/invite");
      } else {
        navigate("/createfirstgroup");
      }
    } else {
      navigate("/chat");
    }
  }
};

export const handleContactRemovedSignal = (
  loggedInUserActiveSubsId,
  loggedInUserId,
  signalData,
  dispatch,
  switchAccountId,
  switchAccount,
  getProfile,
  requestallmemberswithgroups,
  requestGetAllAcountLevelMember,
  requestgroupMemberDetails,
  requestGetGroupRecipient,
  activeGroup,
  navigate,
  userData,
  handleSwitchAccountRefresh,
  switchAccountRefresh,
  groupRecipientDetails
) => {
  const endpoint = window.location.pathname;
  const removedUserExistsInActiveGroup = groupRecipientDetails?.some(
    (obj) => obj.userId === signalData?.ruid
  );
  if (
    loggedInUserActiveSubsId === signalData?.sid &&
    signalData?.uuid.includes(loggedInUserId) &&
    loggedInUserId !== signalData?.ruid
  ) {
    dispatch(requestGetAllAcountLevelMember());
    dispatch(requestallmemberswithgroups({ forceFetch: true }));
    const memberreqBody = {
      group_id: activeGroup?.id,
      target_subscriber_id: loggedInUserActiveSubsId,
    };
    if (activeGroup?.isGroup) {
      dispatch(requestgroupMemberDetails(memberreqBody));
      removedUserExistsInActiveGroup &&
        dispatch(requestGetGroupRecipient(activeGroup?.id));
    }
  } else if (
    loggedInUserActiveSubsId === signalData?.sid &&
    loggedInUserId === signalData?.ruid
  ) {
    const data = {
      switch_subscriber_id: switchAccountId, //substo[0]
    };
    dispatch(switchAccount(data)).then((res) => {
      if (res?.status) {
        dispatch(getProfile()).then((Profileres) => {
          if (Object.keys(Profileres).length > 0) {
            if (endpoint !== "/chat") {
              handleRedirection(
                Profileres,
                false,
                navigate,
                dispatch,
                handleSwitchAccountRefresh,
                switchAccountRefresh
              );
            } else {
              handleRedirection(
                Profileres,
                true,
                navigate,
                dispatch,
                handleSwitchAccountRefresh,
                switchAccountRefresh
              );
            }
          }
        });
      }
    });
  } else {
  }
};

export const handleChatLanguageUpdatedSignal = (
  loggedInUserActiveSubsId,
  loggedInUserId,
  signalData,
  dispatch,
  requestgroupMemberDetails,
  requestGetGroupRecipient,
  selectedGroup,
  requestGetAllAcountLevelMember,
  sidList
) => {
  const haveSidInList = checkDoesHaveSid(sidList, signalData?.sid);
  if (
    (loggedInUserActiveSubsId === signalData?.sid &&
      signalData?.uuid.includes(loggedInUserId)) ||
    (!haveSidInList && signalData?.uuid.includes(loggedInUserId))
  ) {
    dispatch(requestGetAllAcountLevelMember(true));
    if (selectedGroup?.isGroup) {
      const memberreqBody = {
        group_id: selectedGroup?.id,
        target_subscriber_id: loggedInUserActiveSubsId,
      };
      dispatch(requestGetGroupRecipient(selectedGroup?.id));
      dispatch(requestgroupMemberDetails(memberreqBody));
    }
  }
};
