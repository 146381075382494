import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
} from "redux-persist";
import storage from "redux-persist-indexeddb-storage";
import { globalReducer } from "./reducers/global/reducer";
import { profileReducer } from "./reducers/profile";
import { arkchatgroupReducer } from "./reducers/Arkchatreducer/reducer";
import persistStore from "redux-persist/es/persistStore";
import { topicsReducer } from "./reducers/topics/topicsReducer";
import { subscriberReducer } from "./reducers/subscriber/subscriberReducer";
import { countryReducer } from "./reducers/masterData/countryReducer";
import { industryVirticalReducer } from "./reducers/masterData/industryverticalReducer";
import { languageReducer } from "./reducers/masterData/languageReducer";
import { notificationReducer } from "./reducers/notifications/allnotificationRedicer";
import { serviceCategoryReducer } from "./reducers/masterData/serviceCategoryReducer";
import { AUTH_401_REDIRECT } from "./reducers/auth";
import { eraseCookie } from "../config/cookie";
import { REMOVE_SET_CURRENT_USER_LOGEDIN } from "./actions/auth/login";
import { appReducer } from "./reducers/Appreducer";
import { faqReducer } from "./reducers/masterData/faqReducer";
import { subscriptionReducer } from "./reducers/Subscription";
import { localAppReducer } from "./reducers/AppLocalStateReducer";

// Combine your reducers
const combineReducer = combineReducers({
  appStateData: appReducer,
  localAppReducer:localAppReducer,
  profile: profileReducer,
  global: globalReducer,
  arkchatgroup: arkchatgroupReducer,
  topic: topicsReducer,
  subscriber: subscriberReducer,
  country: countryReducer,
  faq:faqReducer,
  indusrty: industryVirticalReducer,
  languages: languageReducer,
  notification: notificationReducer,
  serviceCategory: serviceCategoryReducer,
  subscription:subscriptionReducer
});

const rootReducer = (state, action) => {
  if (action.type === REMOVE_SET_CURRENT_USER_LOGEDIN) {
    state = undefined;
  }
  return combineReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage: storage("arkchatDb"),
  blacklist: ["localAppReducer","subscription"]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const handle401Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action?.payload && action?.payload?.status === 401) {
      eraseCookie("token");
      dispatch({ type: AUTH_401_REDIRECT });
    }
    return next(action);
  };

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(handle401Middleware),
});

export { store };
export const persistor = persistStore(store);
