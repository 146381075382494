import React from "react";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { Box, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { serviceTypeValue } from "../../../utils/constant";
import useAppSelectors from "../../../customHooks/useAppSelectors";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#EAEAEA",
    color: "#000000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "26px",
    width: "26px",
    padding: "4px",
    borderRadius: "2px",
    cursor: "pointer",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const TreeViewWithCheckboxes = ({
  data,
  onNodeSelect,
  selectedCategory,
  setServiceCategoryModalOpen,
}) => {
  const { postServiceTypeValue } = useAppSelectors();

  const renderTree = (nodes) =>
    nodes.map((node, level) => (
      <TreeItem
        sx={{
          marginBottom: "1rem",
        }}
        key={node?.id + "Treeview"}
        nodeId={node?.id.toString()}
        label={
          <Typography fontSize="0.87rem">
            {(node?.subCategory?.length === 0 || !node?.subCategory) && (
              <Checkbox
                checked={selectedCategory?.id === node?.id}
                onChange={(event) => onNodeSelect(event, node)}
                sx={{ "& .MuiSvgIcon-root": { color: "#183243",padding:"0rem" } }}
              />
            )}
            {node?.name}
          </Typography>
        }
        children={
          node?.subCategory?.length > 0
            ? renderTree(node?.subCategory)
            : undefined
        }
      />
    ));

  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={classes.header}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              onClick={() => setServiceCategoryModalOpen(false)}
            >
              <Typography sx={classes.iconDiv}>
                <CloseIcon sx={classes.iconn} />
              </Typography>
              <Typography fontSize={"1rem"} variant="h6" fontWeight={400}>
                {serviceTypeValue[+postServiceTypeValue]} Category
              </Typography>
            </Stack>
          </Stack>
          <Box
            width={"90%"}
            margin={"auto"}
            mt={"1rem"}
            height={"72vh"}
            maxHeight={"72vh"}
            overflow={"auto"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "3px",
              },
            }}
          >
            <TreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              sx={{
                flexGrow: 1,
                width: "90%",
              }}
            >
              {renderTree(data)}
            </TreeView>
          </Box>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default TreeViewWithCheckboxes;
