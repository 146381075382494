import {
  GET_ART_SERVICE_CATEGORY,
  GET_PHOTO_SERVICE_CATEGORY,
  GET_PRODUCT_SERVICE_CATEGORY,
  GET_SERVICE_CATEGORY,
  GET_SERVICE_CATEGORY_ERROR,
  GET_VIDEO_SERVICE_CATEGORY,
} from "../../actions/masterData";

const initState = {
  serviceCategoryData: [],
  error: null,
  status: null,
};

export const serviceCategoryReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_SERVICE_CATEGORY:
      return {
        ...state,
        serviceCategoryData: action?.payload,
        status: action?.payload?.data?.status,
        error: false,
      };
    case GET_ART_SERVICE_CATEGORY:
      return {
        ...state,
        artServiceCategoryData: action?.payload,
        status: action?.payload?.data?.status,
        error: false,
      };
    case GET_PRODUCT_SERVICE_CATEGORY:
      return {
        ...state,
        productServiceCategoryData: action?.payload,
        status: action?.payload?.data?.status,
        error: false,
      };
    case GET_PHOTO_SERVICE_CATEGORY:
      return {
        ...state,
        photoServiceCategoryData: action?.payload,
        status: action?.payload?.data?.status,
        error: false,
      };
    case GET_VIDEO_SERVICE_CATEGORY:
      return {
        ...state,
        videoServiceCategoryData: action?.payload,
        status: action?.payload?.data?.status,
        error: false,
      };
    case GET_SERVICE_CATEGORY_ERROR:
      return {
        ...state,
        serviceCategoryData: [],
        status: action?.payload?.data?.status,
        error: true,
      };
    default:
      return state;
  }
};
