import React, { useEffect, useState } from "react";
import { FormControl } from "@mui/base";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import googleLogo from "../../../assets/images/auth/googleLogo.svg";
import {
  appleprovider,
  auth,
  fetchToken,
  provider,
} from "../../../config/firebase.config";
import { signInWithPopup } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  handleSocialSignIn,
  userLogin,
} from "../../../appStore/actions/auth/login/loginaction";
import {
  AuthActionButton,
  GoogleButton,
  AppleButton,
  GoogleImg,
} from "../../../common/StyledComponents/AuthComponents";
import { encrypter } from "../../../utils/encryptpassword";
import showIcon from "../../../assets/images/auth/visibility-on.svg";
import hideIcon from "../../../assets/images/auth/visibility-off.svg";
import appleIcon from "../../../assets/images/auth/apple.svg";
import { getProfile } from "../../../appStore/actions/profile/action";
import { requestallmemberswithgroups } from "../../../appStore/actions/ArkchatGroup/action";
import LoadingSpinner from "../../../common/LoadingSpinner/LoadingSpinner";
import useLoginCondition from "../../../customHooks/useLoginCondition";
import { setCookie } from "../../../config/cookie";
import {
  getCountryList,
  getIndustryList,
} from "../../../appStore/actions/masterData/masterDataAction";
import validator from "validator";
import IntroVideo from "../../../common/IntroVideo";
import { showToastMsg } from "../../../appStore/actions/global/action";

const classes = {
  blockInputBox: {
    width: "100%",
  },
  signInBox: {
    height: "calc(100vh - 4rem)",
    maxHeight: "40.7rem",
    minHeight: "28rem",
    width: "100%",
    maxWidth: "43rem",
    position: "relative",
    "@media only screen and (max-width:899px)": {
      height: "100%",
    },
    "@media only screen and (max-width: 1204px)": {
      minHeight: "38rem",
    },
  },
  signinBtn: {
    position: "absolute",
    bottom: "2rem",
    left: "50%",
    marginLeft: "-2.72rem",
    "@media only screen and (max-width:899px)": {
      position: "relative",
      marginBottom: "-1.8rem",
      bottom: "0 !important",
    },
    "@media only screen and (max-width:1300px)": {
      bottom: "1.5rem",
    },
  },
  googleLogo: {
    marginRight: "0.5rem",
    width: "1.2rem", // Adjust the size of the Google logo as needed
    height: "1.2rem",
  },
  captions: {
    fontSize: "0.87rem !important",
    display: "block",
  },
  forgotPassword: {
    color: "grey !important",
    textDecoration: "none !important",
  },
  loginBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "60%",
  },
  mobscroll: {
    overflowX: "hidden",
    "@media only screen and (max-width:899px)": {
      overflowX: "hidden",
    },
  },
};
const SignIn = () => {
  const { loading } = useLoginCondition();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isRemember, setIsrememberme] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isRequiredEmpty, setIsRequiredEmpty] = useState({
    email: false,
    password: false,
  });
  

  useEffect(() => {
    dispatch(getIndustryList());
    dispatch(getCountryList());
  }, []);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRedirection = (socialSignInResponse) => {
    if (socialSignInResponse?.data?.accountOwner) {
      if (
        socialSignInResponse?.data?.isFirstGroupCreated &&
        socialSignInResponse?.data?.isFirstInviteSent
      ) {
        navigate("/chat");
      } else if (
        socialSignInResponse?.data?.isFirstGroupCreated &&
        !socialSignInResponse?.data?.isFirstInviteSent
      ) {
        navigate("/invite");
      } else {
        navigate("/createfirstgroup");
      }
    } else {
      navigate("/chat");
    }
  };

  const handleGoogle = () => {
    signInWithPopup(auth, provider)
      .then(async (res) => {
        const user = res?.user;
        const userdetails = user?.providerData[0];
        const namedetails = userdetails?.displayName.split(" ");
        const reqbody = {
          first_name: namedetails[0],
          last_name: namedetails[1],
          email: userdetails?.email,
          social_id: user?.providerData[0]?.uid,
          type: 1,
          device: "Android",
        };

        const socialSignInResponse = await dispatch(
          handleSocialSignIn(reqbody)
        );

        if (socialSignInResponse?.status) {
          fetchToken(socialSignInResponse?.data?.id);
          dispatch(getProfile());
          const allres = await dispatch(requestallmemberswithgroups({}));
          //
          const invitationData =
            JSON.parse(localStorage.getItem("invitationData")) || false;
          //

          if (
            socialSignInResponse?.data?.isProfileJourneyCompleted &&
            invitationData
          ) {
            navigate("/invitations");
          } else if (
            socialSignInResponse?.data?.isProfileJourneyCompleted &&
            !invitationData
          ) {
            handleRedirection(socialSignInResponse);
          } else if (
            !socialSignInResponse?.data?.isProfileJourneyCompleted &&
            invitationData &&
            +invitationData?.inviteType === 1
          ) {
            navigate("/individualprofile");
          } else {
            navigate("/profile");
          }
        } else {
          dispatch(showToastMsg(socialSignInResponse?.message));
        }
      })
      .catch((err) => {});
  };
  const handleEncrypt = (text) => {
    const encrypted = encrypter.encrypt(text);
    return encrypted;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isLoading) {
      setLoading(true);
      if (password && email) {
        const encryptedPassword = handleEncrypt(password);
        if (encryptedPassword) {
          let formBody = {
            password: encryptedPassword,
            email: email,
          };
          //
          dispatch(userLogin(formBody)).then((res) => {
            if (res?.status) {
              if (isRemember) {
                setCookie("isRemember", true, 30);
              }
              setLoading(false);
              navigate("/otp-verify");
            } else {
              setLoading(false);
              dispatch(showToastMsg(res?.message));
            }
          });
        } else {
          setLoading(false);
        }
      } else {
        const isRequiredValue = {
          ...isRequiredEmpty,
          email: !email ? true : false,
          password: !password ? true : false,
        };
        const isValidEmail = validator.isEmail(email);
        setIsValid(isValidEmail);
        setIsRequiredEmpty(isRequiredValue);
        setLoading(false);
      }
    }
  };

  const handleloginwithApple = () => {
    signInWithPopup(auth, appleprovider)
      .then(async (response) => {
        const userdetails = response?.user?.providerData?.[0];
        const namedetails = userdetails?.displayName;
        const reqbody = {
          first_name: namedetails ? namedetails?.split(" ")?.[0] : null,
          last_name: namedetails ? namedetails?.split(" ")?.[1] : null,
          email: userdetails?.email,
          social_id: userdetails?.uid,
          type: 2,
          device: "Android",
        };
        const res = await dispatch(handleSocialSignIn(reqbody));
        const invitationData =
          JSON.parse(localStorage.getItem("invitationData")) || false;
        if (res?.status) {
          fetchToken(res?.data?.id);
          dispatch(getProfile());
          if (res?.data?.isProfileJourneyCompleted && invitationData) {
            navigate("/invitations");
          } else if (res?.data?.isProfileJourneyCompleted && !invitationData) {
            handleRedirection(res);
          } else if (
            !res?.data?.isProfileJourneyCompleted &&
            invitationData &&
            +invitationData?.inviteType === 1
          ) {
            navigate("/individualprofile");
          } else {
            navigate("/profile");
          }
        } else {
          dispatch(showToastMsg(res?.message));
        }
      })
      .catch(() => {
        //
      });
  };

  const handleRememberMe = (event) => {
    setIsrememberme(event.target.checked);
  };

  return (
    <>
      {loading ? (
        <>
          <LoadingSpinner minHeight={"100vh"} />
        </>
      ) : (
        <Box m={2}>
          <Grid container justifyContent="space-between" sx={classes.mobscroll}>
            <Grid item xs={12} md={5}>
              <IntroVideo />
            </Grid>
            <Grid ml={"auto"} item xs={12} md={6} padding={"0.4rem 1rem"}>
              <Box
                boxShadow={"0px 4px 14px rgba(0, 0, 0, 0.09)"}
                padding={"1rem"}
                sx={classes.signInBox}
              >
                <Typography fontSize={"1.7rem"} fontWeight={600} variant="h5">
                  {appContent?.signIn}
                </Typography>
                <Typography
                  mt={"0.4em"}
                  sx={classes.captions}
                  variant="caption"
                >
                  {appContent?.newUser}{" "}
                  <Link
                    style={{ textDecoration: "none", color: "#59A0FF" }}
                    to="/"
                  >
                    {appContent?.newAccount}
                  </Link>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "4px",
                  }}
                  mt={"1rem"}
                  mb={"1rem"}
                >
                  <GoogleButton variant="outlined" onClick={handleGoogle}>
                    <GoogleImg src={googleLogo} alt="Google Logo" />
                    {appContent?.googleLogin}
                  </GoogleButton>
                  <AppleButton
                    sx={{
                      bgcolor: "#000000",
                      color: "#FFFFFF",
                      border: "1px solid #FFFFFF",
                      display: "flex",
                      alignItems: "center",
                      padding: "0.36rem 0.76rem",
                      borderRadius: "0.49rem",
                      textTransform: "none",
                      ":hover": {
                        bgcolor: "#000000",
                        color: "#FFFFFF",
                        border: "1px solid #FFFFFF",
                      },
                    }}
                    size="small"
                    variant="outlined"
                    onClick={handleloginwithApple}
                  >
                    <GoogleImg src={appleIcon} alt="Apple Logo" />
                    {appContent?.appleLogin}
                  </AppleButton>
                </Box>
                <Box sx={classes.divider}>
                  <div className="devider_or">
                    <div className="rule_line"></div>
                    {appContent?.or}
                    <div className="rule_line"></div>
                  </div>
                </Box>
                <form onSubmit={handleSubmit} noValidate>
                  <FormControl>
                    <TextField
                      type="email"
                      label={appContent?.email}
                      variant="standard"
                      name="email"
                      required
                      error={!isValid || isRequiredEmpty?.email ? true : false}
                      value={email.trim()}
                      onChange={(e) => {
                        setEmail(e.target.value.trim());
                        setIsValid(validator.isEmail(e.target.value.trim()));
                      }}
                      sx={classes.blockInputBox}
                    />
                    <FormHelperText
                      error={!isValid || isRequiredEmpty?.email ? true : false}
                    >
                      {appContent?.required}
                    </FormHelperText>
                  </FormControl>
                  <FormControl>
                    <TextField
                      type={showPassword ? "text" : "password"}
                      label={appContent?.password}
                      variant="standard"
                      name="password"
                      value={password?.trim()}
                      error={!isRequiredEmpty?.password ? false : true}
                      onChange={(e) => setPassword(e.target.value?.trim())}
                      required
                      sx={classes.blockInputBox}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleTogglePassword}>
                              {showPassword ? (
                                <img src={hideIcon} alt="show" />
                              ) : (
                                <img src={showIcon} alt="hide" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText
                      error={isRequiredEmpty?.password ? true : false}
                    >
                      {appContent?.required}
                    </FormHelperText>
                  </FormControl>
                  <Box marginTop={"1em"}>
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "rgb(165 162 162)",
                        fontFamily: "Poppins",
                        fontSize: "0.87rem",
                      }}
                      to="/forgetpassword"
                      sx={classes.forgotPassword}
                    >
                      {appContent?.forgotPassword}?
                    </Link>
                  </Box>
                  <Box marginTop={"1em"}>
                    <FormControlLabel
                      sx={{
                        ".MuiFormControlLabel-label": {
                          fontSize: "0.85rem",
                        },
                      }}
                      value={appContent?.keepsigned}
                      control={
                        <Checkbox
                          size="small"
                          checked={isRemember}
                          onClick={handleRememberMe}
                          style={{ color: "#183243" }}
                        />
                      }
                      label={appContent?.keepsigned}
                      labelPlacement="end"
                    />
                  </Box>

                  <Box sx={classes.signinBtn}>
                    <AuthActionButton type="submit" variant="contained">
                      {isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        appContent?.signIn
                      )}
                    </AuthActionButton>
                  </Box>
                </form>
                <Box mt={"1rem"} textAlign={"left"}>
                  <Typography>
                    You are signing into an Arkchat Beta account.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default SignIn;
