import React from "react";

const NotFound = () => {
  return (
    <div style={{height:"90vh",width:"100%"}}>
      <div style={{textAlign:"center"}}>
      <h1>404</h1>
      <div>Ooops!!! The page you are looking for is not found</div>
      </div>
      
    </div>
  );
};

export default NotFound;
