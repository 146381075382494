import React from "react";

const Attherate = ({ color = "" }) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.07 6.67L17.02 6.71L10.06 13.69H0L7.02 6.67L0.36 0H10.4L17.02 6.62L17.07 6.67Z"
        fill={color ? color : "rgb(219, 0, 17)"}
      />
    </svg>

    // <svg
    //   width="100%"
    //   height="100%"
    //   viewBox="0 0 32 39"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g clipPath="url(#clip0_3918_280)">
    //     <path
    //       d="M14.9535 38.2194C6.70764 38.2194 0 31.5118 0 23.266C0 15.0201 6.70764 8.3125 14.9535 8.3125C16.6085 8.3125 18.2489 8.58509 19.8114 9.12053C20.0207 9.19355 20.1911 9.34444 20.2884 9.53915C20.3858 9.73872 20.4004 9.9675 20.3274 10.1719C20.2543 10.3813 20.1034 10.5516 19.9039 10.649C19.7043 10.7463 19.4755 10.7609 19.2711 10.6879C17.8789 10.2109 16.4235 9.9675 14.9535 9.97237C7.62276 9.97237 1.66474 15.9353 1.66474 23.2611C1.66474 30.5869 7.62762 36.5498 14.9535 36.5498C22.2793 36.5498 28.2422 30.5869 28.2422 23.2611C28.2422 22.3606 28.1497 21.4649 27.9744 20.5839C27.955 20.4768 27.9501 20.3648 27.9744 20.2577C27.9939 20.1507 28.0377 20.0484 28.0961 19.9559C28.1546 19.8635 28.2324 19.7856 28.3249 19.7272C28.4174 19.6639 28.5148 19.6249 28.6218 19.6006C28.7289 19.5763 28.8409 19.5763 28.948 19.6006C29.0551 19.6201 29.1573 19.6639 29.2498 19.7223C29.3423 19.7807 29.4201 19.8586 29.4786 19.9511C29.5418 20.0436 29.5808 20.1409 29.6051 20.248C29.8047 21.2361 29.9069 22.2486 29.9069 23.2562C29.9069 31.502 23.1993 38.2097 14.9535 38.2097V38.2194Z"
    //       fill={color ? color : "#183243"}
    //     />
    //     <path
    //       d="M30.9196 7.77978C30.574 7.42931 30.1651 7.15672 29.7173 6.96688C29.2646 6.77704 28.7827 6.67969 28.2911 6.67969C27.7995 6.67969 27.3176 6.77704 26.8649 6.96688C26.4122 7.15672 26.0033 7.43417 25.6626 7.77978L23.2969 10.1795L28.5199 15.4025L30.8856 13.0369C31.236 12.6961 31.5135 12.2872 31.7082 11.8394C31.898 11.3916 32.0003 10.9048 32.0051 10.4181C32.01 9.93128 31.9126 9.44452 31.7277 8.99183C31.5427 8.53913 31.2701 8.13025 30.9245 7.77978H30.9196Z"
    //       fill={color ? color : "#DA1F26"}
    //     />
    //     <path
    //       d="M21.5422 11.9297L11.2082 22.2637C11.1157 22.3611 11.0378 22.4682 10.9746 22.585L7.55259 29.4922C7.45523 29.6772 7.40169 29.8865 7.40656 30.1007C7.40656 30.31 7.46983 30.5193 7.57692 30.6994C7.68401 30.8795 7.83491 31.0304 8.01988 31.1375C8.20485 31.2446 8.40929 31.2981 8.6186 31.303C8.80844 31.303 8.99341 31.264 9.15891 31.1813L16.0661 27.7593C16.1829 27.696 16.29 27.6182 16.3874 27.5257L26.7214 17.1916L21.5374 11.9346L21.5422 11.9297Z"
    //       fill={color ? color : "#DA1F26"}
    //     />
    //     <path
    //       d="M8.79297 6.6211C8.79297 3.51553 11.3339 0.974609 14.4395 0.974609C17.545 0.974609 20.0859 3.51553 20.0859 6.6211V9.96031"
    //       stroke="#183243"
    //       strokeWidth="1.94706"
    //       strokeMiterlimit="10"
    //       strokeLinecap="round"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_3918_280">
    //       <rect width="32" height="38.216" fill="white" />
    //     </clipPath>
    //   </defs>
    // </svg>
  );
};

export default Attherate;
