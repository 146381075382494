import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Stack, FormControlLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  ApprovalStatus,
  MessageType,
  TaskStatus,
} from "../../../utils/constant";
import PopupModal from "../../../common/PopupModal";
import UpdateTaskSwitch from "../../../common/UpdateTaskSwitch";
import DrawerInput from "./DrawerInput";
import { useTranslation } from "react-i18next";
import AlertPopUp from "../../../common/AlertPopUp";
import dayjs from "dayjs";
import StartTask from "./StartTask";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  radiogp: {
    display: "block",
  },
};

const selectTaskValue = [
  { title: `Cancel or close the task`, value: `cancelTask` },
  { title: `Reopen done task`, value: `reopen` },
  { title: `Reassign to someone else`, value: `reassignTask` },
  {
    title: `Change task date | Time | Priority |
Repeat`,
    value: `changeValues`,
  },
  { title: `Change task to approval request`, value: `changeType` },
];
const selectApprovalValue = [
  { title: `Cancel or close approval request`, value: `cancelApproval` },
  { title: `Change approval date | Time | Priority `, value: `changeValues` },
  {
    title: `Request approval from someone else`,
    value: `reassignTask`,
  },
  { title: `Change approval request to task`, value: `changeType` },
];

const TaskUpdate = ({
  setTaskUpdateOpen,
  setSelectRecipientsDrawerOpen,
  setStepper,
  taskData,
  setTaskData,
  setMessageType,
  msgType,
  handleClickYouTube,
  setFileAttachment,
  handleClickGoogle,
  fileAttachment,
  attachment,
  setAttachment,
  handleUpdateTaskApproval,
  updateTaskApprovalMsg,
  setUpdateTaskApprovalMsg,
  checkedType,
  setCheckedType,
  setReplyParentMsg,
  selectedRecipients,
  setDueDate,
  setDueTime,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const userData = useSelector((state) => state?.profile?.profileData?.data);
  const [isChangeMsgTypeOpen, setChangeTypeOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [actualTimeValue, setActualTimeValue] = useState("");
  const [startTaskOpen, setStartTaskOpen] = useState(false);

  const handleMenuAction = (event) => {
    if (event?.target?.checked) {
      if (event?.target?.name === "reassignTask") {
        setSelectRecipientsDrawerOpen(true);
        setCheckedType(event?.target?.name);
      } else if (event?.target?.name === "reopen") {
        const updatedTaskBody = {
          ...taskData,
          task_status: TaskStatus?.Pending,
        };
        setCheckedType(event?.target?.name);
        setTaskData(updatedTaskBody);
      } else if (event?.target?.name === "changeValues") {
        setCheckedType(event?.target?.name);
        setSelectRecipientsDrawerOpen(true);
        setStepper("datetime");
      } else if (event?.target?.name === "cancelTask") {
        const updatedTaskBody = {
          ...taskData,
          task_status: TaskStatus?.Cancelled,
        };
        setCheckedType(event?.target?.name);
        setTaskData(updatedTaskBody);
      } else if (event?.target?.name === "cancelApproval") {
        const updatedTaskBody = {
          ...taskData,
          approval_status: ApprovalStatus?.Cancelled,
        };
        setCheckedType(event?.target?.name);
        setTaskData(updatedTaskBody);
      } else if (event?.target?.name === "changeType") {
        setMessageType(taskData?.type);
        setCheckedType(event?.target?.name);
        setChangeTypeOpen(true);
      } else if (event?.target?.name === "done") {
        const updatedTaskBody = {
          ...taskData,
          task_status: TaskStatus?.Done,
        };
        setStartTaskOpen(true);
        setTaskData(updatedTaskBody);
      } else if (event?.target?.name === "accept") {
        const updatedTaskBody = {
          ...taskData,
          approval_status: ApprovalStatus?.Approved,
        };
        setCheckedType(event?.target?.name);
        setTaskData(updatedTaskBody);
      } else if (event?.target?.name === "reject") {
        const updatedTaskBody = {
          ...taskData,
          approval_status: ApprovalStatus?.Rejected,
        };
        setCheckedType(event?.target?.name);
        setTaskData(updatedTaskBody);
      }
    } else {
      setCheckedType("");
    }
  };
  const handleDoneMsgTypeChange = () => {
    const updatedTaskBody = {
      ...taskData,
      type:
        msgType === MessageType?.Approval
          ? MessageType?.Approval
          : MessageType?.Task,
      approval_status:
        msgType === MessageType?.Approval ? ApprovalStatus?.Pending : 0,
      task_status: msgType === MessageType?.Task ? TaskStatus?.Pending : 0,
    };
    setTaskData(updatedTaskBody);
    setChangeTypeOpen(false);
  };
  const handleCloseUpdateDrawer = () => {
    // if (!checkedType) {
    //   setDueDate(dayjs());
    //   setDueTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
    //   setMessageType(MessageType?.Message);
    //   setStepper("user");
    //   setTaskUpdateOpen(false);
    //   setCheckedType(null);
    // } else {
    //   setShowAlert(true);
    // }
    setReplyParentMsg("");
    setDueDate(dayjs());
    setDueTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
    setMessageType(MessageType?.Message);
    setStepper("user");
    setTaskUpdateOpen(false);
    setCheckedType(null);
    handleConfirmation();
  };
  const handleConfirmation = () => {
    setDueDate(dayjs());
    setDueTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
    setMessageType(MessageType?.Message);
    setStepper("user");
    setTaskUpdateOpen(false);
    setCheckedType(null);
  };
  const handleActualTime = () => {
    //task actual time
    const updatedTaskBody = {
      ...taskData,
      actual_time: {
        timeType: taskData?.estimated_time?.timeType,
        timeValue: +actualTimeValue,
      },
      end_time: Math.floor(Date.now() / 10000),
    };
    setCheckedType("done");
    setTaskData(updatedTaskBody);
    setStartTaskOpen(false);
  };
  return (
    <Box sx={classes?.drawerBody}>
      {startTaskOpen && (
        <StartTask
          isActualTime={true}
          open={startTaskOpen}
          taskData={taskData}
          setStartTaskOpen={setStartTaskOpen}
          actualTimeValue={actualTimeValue}
          setActualTimeValue={setActualTimeValue}
          handleActualTime={handleActualTime}
        />
      )}
      {/* <AlertPopUp
        title={"Alert."}
        bodyContent={appContent?.addMember}
        open={showAlert}
        closeModal={setShowAlert}
        isTaskUpdatePopup={true}
        handleDone={handleConfirmation}
      /> */}
      {isChangeMsgTypeOpen && (
        <PopupModal
          title={"Change approval request to task"}
          setMessageType={setMessageType}
          msgType={msgType}
          isChangeMsgType={true}
          setPopupModalOpen={setChangeTypeOpen}
          handleDoneMsgTypeChange={handleDoneMsgTypeChange}
        />
      )}
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon sx={classes.iconn} onClick={handleCloseUpdateDrawer} />
        </Typography>
        <Typography color={"black"}>
          {MessageType?.Task === taskData?.type
            ? "Update Task"
            : "Update Approval"}
        </Typography>
      </Stack>
      <Box mt={"0.8rem"} padding={"0.2rem 0.4rem"}>
        <Typography fontWeight={600} maxWidth={"90%"}>
          {taskData?.from?.id === userData?.id
            ? "Choose option, write a message and click send"
            : `Click the "Completed" toggle, write your message, and send.`}
        </Typography>
        {/* <Typography>Write a message and click send</Typography> */}

        <Box padding={"0.4rem"}>
          {taskData?.type === MessageType?.Task && (
            <>
              {taskData?.assignee?.id === userData?.id && (
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mt={"0.4rem"}
                >
                  <Box>
                    <Typography fontSize={"0.85rem"}>Completed</Typography>
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <UpdateTaskSwitch
                          name={"done"}
                          switchLabel=""
                          checked={checkedType === "done"}
                          handleChange={(event) => handleMenuAction(event)}
                        />
                      }
                    />
                  </Box>
                </Box>
              )}
              {taskData?.from?.id === userData?.id && (
                <>
                  {selectTaskValue?.map((item) => (
                    <Box
                      width={"100%"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      mt={"0.4rem"}
                      key={item?.title}
                    >
                      <Box>
                        <Typography fontSize={"0.85rem"}>
                          {item?.title}
                        </Typography>
                      </Box>
                      <Box>
                        <FormControlLabel
                          control={
                            <UpdateTaskSwitch
                              disabled={
                                (item?.value === "reopen" &&
                                  taskData?.task_status === TaskStatus?.Done) ||
                                item?.value !== "reopen"
                                  ? false
                                  : true
                              }
                              name={item?.value}
                              switchLabel=""
                              checked={checkedType === item?.value}
                              handleChange={(event) => handleMenuAction(event)}
                            />
                          }
                        />
                      </Box>
                    </Box>
                  ))}
                </>
              )}
            </>
          )}
          {taskData?.type === MessageType?.Approval && (
            <>
              {taskData?.assignee?.id === userData?.id && (
                <>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={"0.4rem"}
                  >
                    <Box>
                      <Typography fontSize={"0.85rem"}>Approve</Typography>
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <UpdateTaskSwitch
                            name={"accept"}
                            switchLabel=""
                            checked={checkedType === "accept"}
                            handleChange={(event) => handleMenuAction(event)}
                          />
                        }
                      />
                    </Box>
                  </Box>

                  <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={"0.4rem"}
                  >
                    <Box>
                      <Typography fontSize={"0.85rem"}>Reject</Typography>
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <UpdateTaskSwitch
                            name={"reject"}
                            switchLabel=""
                            checked={checkedType === "reject"}
                            handleChange={(event) => handleMenuAction(event)}
                          />
                        }
                      />
                    </Box>
                  </Box>
                </>
              )}
              {taskData?.from?.id === userData?.id && (
                <>
                  {selectApprovalValue?.map((item) => (
                    <Box
                      width={"100%"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      mt={"0.4rem"}
                      key={item?.title}
                    >
                      <Box>
                        <Typography fontSize={"0.85rem"}>
                          {item?.title}
                        </Typography>
                      </Box>
                      <Box>
                        <FormControlLabel
                          control={
                            <UpdateTaskSwitch
                              name={item?.value}
                              switchLabel=""
                              checked={checkedType === item?.value}
                              handleChange={(event) => handleMenuAction(event)}
                            />
                          }
                        />
                      </Box>
                    </Box>
                  ))}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box position={"absolute"} bottom={"0"} width={"100%"}>
        <DrawerInput
          handleUpdateTaskApproval={handleUpdateTaskApproval}
          handleClickYouTube={handleClickYouTube}
          setFileAttachment={setFileAttachment}
          handleClickGoogle={handleClickGoogle}
          fileAttachment={fileAttachment}
          actualTimeValue={actualTimeValue}
          attachment={attachment}
          setAttachment={setAttachment}
          taskData={taskData}
          checkedType={checkedType}
          setUpdateTaskApprovalMsg={setUpdateTaskApprovalMsg}
          updateTaskApprovalMsg={updateTaskApprovalMsg}
          selectedRecipients={selectedRecipients}
          setReplyParentMsg={setReplyParentMsg}
        />
      </Box>
    </Box>
  );
};

export default TaskUpdate;
