import React from "react";
import {
  Box,
  Button,
  FormHelperText,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import closeLogo from "../../../assets/images/chats/closeLogo.svg";
import { CloseButton, CloseIcon } from "../../Profille/CustomDrawer";
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};
const CrudPopup = ({
  closePopup,
  open,
  title,
  addbtnLable = "Save",
  editBtnLable = "Delete",
  isEdit = false,
  inputLable1 = "",
  inputLable2 = "",
  isBrand = false,
  handleChange,
  handleSave,
  value,
  isConfirmation = false,
  handleDelete,
  handleDeleteConfirm,
  setAddData
}) => {
  const handleClose = () => {
    closePopup(false);
    setAddData("")
  };
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box display={"flex"} alignItems={"center"} gap={"0.4rem"}>
            <Box>
              <CloseButton
                onClick={handleClose}
                size="small"
                variant="contained"
              >
                <CloseIcon src={closeLogo} alt="close" />
              </CloseButton>
            </Box>
            <Box>
              <Typography fontSize={"1rem"} variant="h6" fontWeight={700}>
                {title}
              </Typography>
            </Box>
          </Box>

          <Box width={"80%"} margin={"auto"} mt={"0.7rem"}>
            {!isConfirmation && (
              <form onSubmit={handleSave}>
                <Box>
                  <TextField
                    size="Large"
                    name={"name"}
                    type="text"
                    label={inputLable1}
                    variant="standard"
                    required
                    fullWidth
                    onChange={handleChange}
                    value={value?.name || ""}
                  />
                  <FormHelperText>Required</FormHelperText>
                </Box>
                {isBrand && (
                  <Box mt={"0.6rem"}>
                    <TextField
                      size="Large"
                      name={"website"}
                      type="text"
                      label={inputLable2}
                      variant="standard"
                      fullWidth
                      onChange={handleChange}
                      value={value?.website || ""}
                    />
                    <FormHelperText>Optional</FormHelperText>
                  </Box>
                )}

                <Stack
                  direction="row"
                  justifyContent="center"
                  marginTop="30px"
                  alignItems="center"
                  spacing={3}
                  padding="12px 19px 12px 12px"
                >
                  {isEdit && (
                    <Button
                      onClick={handleDelete}
                      style={{
                        background: "white",
                        color: "black",
                        borderRadius: "0px",
                        border: "1px solid black",
                      }}
                      variant="contained"
                    >
                      {editBtnLable}
                    </Button>
                  )}

                  <Button
                    type="submit"
                    style={{
                      background: "#DB0011",
                      color: "white",
                      borderRadius: "0px",
                      cursor: "pointer",
                      border: "1px solid #DB0011",
                    }}
                    variant="contained"
                  >
                    {addbtnLable}
                  </Button>
                </Stack>
              </form>
            )}

            {isConfirmation && (
              <>
                <Typography>
                  Are you sure you want to delete this Brand? All posts related
                  to this Brand will also be deleted.
                </Typography>

                <Stack
                  direction="row"
                  justifyContent="center"
                  marginTop="30px"
                  alignItems="center"
                  spacing={3}
                  padding="12px 19px 12px 12px"
                >
                  <Button
                    onClick={handleDeleteConfirm}
                    sx={{
                      background: "#DB0011",
                      color: "white",
                      borderRadius: "0px",
                      cursor: "pointer",
                      border: "1px solid #DB0011",
                      ":hover": {
                        background: "#DB0011",
                        color: "white",
                        borderRadius: "0px",
                        cursor: "pointer",
                        border: "1px solid #DB0011",
                      },
                    }}
                    variant="contained"
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={handleClose}
                    sx={{
                      background: "white",
                      color: "black",
                      borderRadius: "0px",
                      border: "1px solid black",
                      ":hover": {
                        background: "white",
                        color: "black",
                        borderRadius: "0px",
                        border: "1px solid black",
                      },
                    }}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Stack>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CrudPopup;
