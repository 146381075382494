import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import reply_icon from "../../../assets/images/chats/reply.svg";
import delete_icon from "../../../assets/images/delete.svg";

const classes = {
  hoverStyle: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E1E1E1",
    },
  },
};

const DmMoreMenu = ({
  messageData,
  menuRef,
  setMoreMenu,
  setDeleteGroupMsgOpen,
  setDeleteGroupMsgOpenIndicatorId,
  setReplyParentMsg,
}) => {
  const handlereply = (msgData) => {
    setReplyParentMsg(msgData);
    setMoreMenu(null);
  };

  return (
    <Box
      position="absolute"
      top={"0"}
      right={"0.8rem"}
      width={"15rem"}
      zIndex={100}
      ref={menuRef}
    >
      <Paper
        sx={{
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          padding: "0.7rem 0rem",
        }}
      >
        <Box>
          <Box p={"0.4rem 1rem"} sx={classes?.hoverStyle}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              onClick={() => handlereply(messageData)}
            >
              <Box>
                <Typography>Reply</Typography>
              </Box>
              <Box>
                <img src={reply_icon} alt="reply_icon" />
              </Box>
            </Box>
          </Box>

          <Box
            onClick={() => {
              setDeleteGroupMsgOpen(true);
              setDeleteGroupMsgOpenIndicatorId(messageData?.timetoken);
            }}
            mt={"0.7rem"}
            p={"0.4rem 1rem"}
            sx={classes?.hoverStyle}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography>Delete</Typography>
              </Box>
              <Box>
                <img src={delete_icon} alt="delete_icon" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default DmMoreMenu;
