import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createStyledIndicatorButton } from "../../common/StyledComponents/SideBarComponents";
import {
  getAllPendingInvitation,
  handleAcceptIgnoreInvite,
} from "../../appStore/actions/subscriber/action";
import { InviteType, accountType } from "../../utils/constant";
import { setCookie } from "../../config/cookie";
import { getProfile } from "../../appStore/actions/profile/action";
import { requestallmemberswithgroups } from "../../appStore/actions/ArkchatGroup/action";
import ProfileDetailModal from "../../common/ProfileDetailModal";
import { logWrapper } from "../../utils/helper";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  ignorebtn: {
    color: "#183243",
    padding: "0.2rem 1rem",
    borderRadius: "0.2rem",
    bgcolor: "white",
    border: "1px solid #DB0011",
    boxShadow: "none",
    textTransform: "none",
    ":hover": {
      color: "#183243",
      padding: "0.2rem 1rem",
      borderRadius: "0.2rem",
      bgcolor: "white",
    },
  },
  acceptbtn: {
    color: "white",
    padding: "0.2rem 1rem",
    borderRadius: "0.2rem",
    bgcolor: "#DB0011",
    border: "1px solid #DB0011",
    boxShadow: "none",
    textTransform: "none",
    ":hover": {
      color: "white",
      padding: "0.2rem 1rem",
      borderRadius: "0.2rem",
      bgcolor: "#DB0011",
    },
  },
  radioStyle: {
    color: "#092C4C",
    "&.Mui-checked": {
      color: "#092C4C",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
    },
  },
};

const InvitationDrawer = ({ setInvitationDrawerOpen }) => {
  const [isProfileViewOpen, setProfileViewModal] = useState(false);
  const [profileViewData, setProfileViewData] = useState({});
  const NameBadge = createStyledIndicatorButton("#E3E1E1");
  const allPendingInvitationData = useSelector(
    (state) => state?.subscriber?.allPendingInvitationData
  );
  const [selectedAccount, setSelectedAccount] = useState(
    allPendingInvitationData?.accountOwned?.length > 0 &&
      allPendingInvitationData?.accountOwned[0]?.id
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAction = (status, id, data, type) => {
    // accountType?.Individual because for internal invite user can only join with there individual accoutn.
    const subsCriber_id = data?.find(
      (item) => item?.subscriberPriority === accountType?.Individual
    );
    // 1=accept;
    // 2=ignore;
    if (type === InviteType?.internal) {
      const reqBody = {
        status,
        target_subscriber_id: subsCriber_id?.id,
      };
      dispatch(handleAcceptIgnoreInvite(reqBody, id)).then((res) => {
        if (res?.code === 200 && status === 1) {
          localStorage.removeItem("invitationData");
          localStorage.removeItem("newProfile_subscriber_id");
          setCookie("subsCriber_id", res?.data?.id, 7);
          dispatch(getProfile(true));
          dispatch(requestallmemberswithgroups({ forceFetch: true })).then(
            (res) => {
              navigate("/chat");
            }
          );
        } else {
          setInvitationDrawerOpen(false);
          dispatch(getAllPendingInvitation());
        }
      });
    } else {
      const reqBody = {
        status,
        target_subscriber_id: selectedAccount,
      };
      dispatch(handleAcceptIgnoreInvite(reqBody, id)).then((res) => {
        if (res?.code === 200 && status === 1) {
          localStorage.removeItem("invitationData");
          localStorage.removeItem("newProfile_subscriber_id");
          setCookie("subsCriber_id", res?.data?.id, 7);
          dispatch(getProfile(true));
          dispatch(requestallmemberswithgroups({ forceFetch: true })).then(
            (res) => {
              navigate("/chat");
            }
          );
        } else {
          setInvitationDrawerOpen(false);
          dispatch(getAllPendingInvitation());
        }
      });
    }
  };

  const handleSelectAccount = (e, data) => {
    if (e.target.checked) {
      setSelectedAccount(data?.id);
    }
  };
  const handleShowProfileData = (data) => {
    logWrapper(data);
  };
  return (
    <>
      <ProfileDetailModal
        isProfileViewOpen={isProfileViewOpen}
        setProfileViewModal={setProfileViewModal}
        profileViewData={profileViewData}
        setProfileViewData={setProfileViewData}
      />
      <Box sx={classes?.drawerBody}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={classes.header}
        >
          <Typography sx={classes.iconDiv}>
            <CloseIcon
              sx={classes.iconn}
              onClick={() => setInvitationDrawerOpen(false)}
            />
          </Typography>
          <Typography color={"black"}>Invitations</Typography>
        </Stack>

        <Box width={"95%"} margin={"auto"}>
          <Box>
            <Typography maxWidth={"25rem"}>
              Following invitations to join contact list are pending. Please
              accept/ignore invitations by clicking on them.
            </Typography>
          </Box>
          {allPendingInvitationData?.invitations?.length > 0 &&
            allPendingInvitationData?.invitations?.map((data) => (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                boxShadow={"0px 1px 4px rgba(0, 0, 0, 0.25)"}
                padding={"0.45rem"}
                mt={"0.5rem"}
                key={data?.id + "inviteData"}
              >
                {data?.type === 1 ? (
                  <>
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                      <Box onClick={() => handleShowProfileData(data)}>
                        <NameBadge
                          sx={{ color: "#183243" }}
                          size="small"
                          variant="contained"
                        >
                          {data && data?.invitedBy?.subscriberFirmName[0]}
                        </NameBadge>
                      </Box>
                      <Box>
                        <Typography>
                          {data && data?.invitedBy?.subscriberFirmName}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display={"flex"} alignItems={"center"} gap={1}>
                      <Button
                        onClick={() =>
                          handleAction(
                            2,
                            data?.id,
                            allPendingInvitationData?.accountOwned,
                            data?.type
                          )
                        }
                        sx={classes?.ignorebtn}
                        size="small"
                        variant="contained"
                      >
                        Ignore
                      </Button>
                      <Button
                        onClick={() =>
                          handleAction(
                            1,
                            data?.id,
                            allPendingInvitationData?.accountOwned,
                            data?.type
                          )
                        }
                        sx={classes?.acceptbtn}
                        size="small"
                        variant="contained"
                      >
                        Accept
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box>
                      <Box display={"flex"} alignItems={"center"} gap={1}>
                        <Box onClick={() => handleShowProfileData(data)}>
                          <NameBadge
                            sx={{ color: "#183243" }}
                            size="small"
                            variant="contained"
                          >
                            {data && data?.invitedBy?.subscriberFirmName[0]}
                          </NameBadge>
                        </Box>
                        <Box>
                          <Typography>
                            {data && data?.invitedBy?.subscriberFirmName}
                          </Typography>
                        </Box>
                      </Box>

                      <Box ml={"0.2rem"}>
                        <>
                          <RadioGroup row>
                            {allPendingInvitationData?.accountOwned?.map(
                              (accountData) => (
                                <FormControlLabel
                                  key={accountData?.id + "externalInvite"}
                                  value={accountData?.id}
                                  control={
                                    <Radio
                                      onChange={(e) =>
                                        handleSelectAccount(e, accountData)
                                      }
                                      sx={classes.radioStyle}
                                      checked={
                                        accountData?.id === selectedAccount
                                      }
                                    />
                                  }
                                  label={accountData?.subscriberFirmName}
                                  labelPlacement="end"
                                />
                              )
                            )}
                          </RadioGroup>
                        </>
                      </Box>
                    </Box>

                    <Box>
                      <Box mb={"0.4rem"}>
                        <Button
                          onClick={() =>
                            handleAction(
                              2,
                              data?.id,
                              allPendingInvitationData?.accountOwned,
                              data?.type
                            )
                          }
                          sx={classes?.ignorebtn}
                          size="small"
                          variant="contained"
                        >
                          Ignore
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          onClick={() =>
                            handleAction(
                              1,
                              data?.id,
                              allPendingInvitationData?.accountOwned,
                              data?.type
                            )
                          }
                          sx={classes?.acceptbtn}
                          size="small"
                          variant="contained"
                        >
                          Accept
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            ))}
        </Box>
      </Box>
    </>
  );
};

export default InvitationDrawer;
