import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import searchIcon from "../../../assets/images/chats/gallery_active.svg";
import youTube_logo from "../../../assets/images/chats/YouTube_Logo_2017 1.svg";
import {
  ChatSearchButton,
  CustomInput,
  SearchBox,
  SmallIcon,
} from "../../../common/chatComponents/ScreenSearchSection";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth:"31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    padding: "4px",
    borderRadius: "2px",
    cursor: "pointer",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const YouTubeAttachmentDrawer = ({
  handleCloseYoutubeDrawer,
  handleSendYouTubeAttachment,
  fileAttachment,
}) => {
  const youtubeRef = useRef(null);
  const [inputvalue, setInputValue] = useState("");
  const [youtubevideoList, setYoutubeVideoList] = useState([]);
  const [selectedvideoList, setselectedvideoList] = useState([]);
  const [isCheckBoxDisabled, setIsCheckBoxDisabled] = useState(false);
  useEffect(() => {
    youtubeRef.current.focus();
  }, []);
  useEffect(() => {
    const combinedLength = fileAttachment?.length + selectedvideoList?.length;

    // Setting the disabled state of the checkbox not more than 5 overall attachment
    setIsCheckBoxDisabled(combinedLength === 5);
  }, [fileAttachment, selectedvideoList]);
  const handleClose = () => {
    setInputValue("");
    setselectedvideoList([]);
    setYoutubeVideoList([]);
    handleCloseYoutubeDrawer();
  };

  const handleAttachedYouTube = () => {
    const selectedLink = youtubevideoList
      ?.map((item) => {
        if (selectedvideoList?.includes(item?.videoid)) {
          return {
            ...item,
            videoLink: `https://www.youtube.com/watch?v=${item?.videoid}`,
          };
        }
      })
      ?.filter(Boolean);

    handleSendYouTubeAttachment(selectedLink);
  };

  const handleSearch = async () => {
    const response = await axios.get(
      "https://www.googleapis.com/youtube/v3/search",
      {
        params: {
          key: process.env.REACT_APP_FIREBASE_API_KEY,
          q: inputvalue,
          part: "snippet",
          type: "video",
          maxResults: 10,
        },
      }
    );

    const { items } = response?.data;
    //
    const filteredVideo = items?.map((item, index) => {
      return {
        videoid: item?.id?.videoId,
        etag: item?.etag,
        thumbnail: item?.snippet?.thumbnails?.default?.url,
        title: item?.snippet?.title,
        channelId: item?.snippet?.channelId,
      };
    });

    setYoutubeVideoList(filteredVideo);
  };

  const handleInputValue = (event) => {
    setInputValue(event.target.value);
  };

  const handleSelectVideo = (event, item) => {
    if (event.target.checked) {
      setselectedvideoList([...selectedvideoList, item?.videoid]);
    } else {
      const filteredData = selectedvideoList?.filter(
        // eslint-disable-next-line eqeqeq
        (nesteditem) => nesteditem != item?.videoid
      );
      setselectedvideoList(filteredData);
    }
  };

  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={classes.header}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography sx={classes.iconDiv}>
                <CloseIcon sx={classes.iconn} onClick={handleClose} />
              </Typography>
              <Typography color={"#000000"}>Youtube</Typography>
            </Stack>
          </Stack>

          <Stack width={"35%"} margin={"auto"} mt={2} mb={3}>
            <img src={youTube_logo} alt="google" />
          </Stack>

          <Stack width={"90%"} margin={"auto"} mb={2}>
            <SearchBox>
              <CustomInput
                ref={youtubeRef}
                value={inputvalue}
                onChange={handleInputValue}
                placeholder="Search"
              />
              <ChatSearchButton
                onClick={handleSearch}
                size="small"
                variant="contained"
              >
                <SmallIcon src={searchIcon} />
              </ChatSearchButton>
            </SearchBox>
          </Stack>
          {youtubevideoList?.length > 0 && (
            <Box
              mt={"0.6rem"}
              maxHeight={"24rem"}
              overflow={"auto"}
              sx={{
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "3px",
                },
              }}
            >
              {youtubevideoList?.map((item) => (
                <Stack
                  key={item?.videoid}
                  width={"91%"}
                  maxWidth={"91%"}
                  margin={"auto"}
                  mb={5}
                >
                  <Box>
                    <Box>
                      <iframe
                        title={item?.title}
                        style={{ width: "100%" }}
                        allowFullScreen="allow"
                        src={`https://www.youtube.com/embed/${item?.videoid}`}
                      />
                    </Box>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box width={"82%"}>
                        <Typography
                          sx={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item?.title}
                        </Typography>
                      </Box>
                      <Box>
                        <FormControlLabel
                          disabled={
                            isCheckBoxDisabled &&
                            !selectedvideoList?.includes(item?.videoid)
                          }
                          control={
                            <Checkbox
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 30 ,color: "#183243"} }}
                            />
                          }
                          name={item?.videoid}
                          checked={selectedvideoList?.includes(item?.videoid)}
                          onChange={(event) => handleSelectVideo(event, item)}
                        />
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              ))}
            </Box>
          )}
        </Stack>

        <Stack
          alignItems="center"
          spacing={1}
          padding="12px 0px"
          margin={"0 auto"}
          width={"360px"}
          position={"relative"}
        >
          <Button
            onClick={handleAttachedYouTube}
            style={{
              background: "#DB0011",
              color: "white",
              borderRadius: "2px",
            }}
            variant="contained"
          >
            Done
          </Button>
          <Box position={"absolute"} right={"1.5rem"}>
            <Typography fontSize={"0.85rem"}>
              {fileAttachment?.length + selectedvideoList?.length}/5
            </Typography>
          </Box>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default YouTubeAttachmentDrawer;
