import { Alert, Slide, Snackbar, SnackbarContent } from "@mui/material";
import React from "react";
// function TransitionUp(props) {
//   return <Slide {...props} direction="up" />;
// }
const Toastbar = ({
  openSnackbar,
  snackbarMessage,
  severity,
  setOpenSnackbar,
}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  
  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Toastbar;
