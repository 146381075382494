import React from "react";
import { Drawer } from "@mui/material";
import GrouMemberList from "./GroupMemberDeatils";
const GroupMemberDrawer = ({ isGroupmembermenu, handleCloseMemberDrawer }) => {
  return (
    <React.Fragment>
      <Drawer sx={{zIndex:1202}} open={isGroupmembermenu} anchor={"right"}>
        <GrouMemberList handleCloseMemberDrawer={handleCloseMemberDrawer} />
      </Drawer>
    </React.Fragment>
  );
};

export default GroupMemberDrawer;
