import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import SemicircularChart from "../../../common/AnalyticsComponents/SemicircularChart";
import {
  approvalAnalyticsContentEnum,
  priorityValueColor,
} from "../../../utils/constant";
import BarChart from "../../../common/AnalyticsComponents/BarChart";
import { useTranslation } from "react-i18next";

const ApprovalAnalytics = () => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const approvalAnalyticsData = useSelector(
    (state) => state?.arkchatgroup?.approvalAnalyticsData
  );
  return (
    <Box display={"flex"} gap={"0.5%"} mt={"0.1rem"}>
      {approvalAnalyticsData?.map((data) => (
        <Box
          sx={{
            borderRadius: "4px",
            border: "1px solid rgba(232, 232, 232, 1)",
            padding: "0.4rem",
          }}
          width={"24.5%"}
          key={data?.id+"approvalAnalyticsData"}
        >
          <Box
            mb={"0.6rem"}
            sx={{
              borderRadius: "4px",
              border: "1px solid rgba(232, 232, 232, 1)",
              padding: "0.5rem",
            }}
          >
            <Box textAlign={"center"} mt={"0.4rem"}>
              <Typography variant="h5" fontWeight={500} fontSize={"0.87rem"}>
                {approvalAnalyticsContentEnum[data?.status]?.title}
              </Typography>
            </Box>
            <SemicircularChart
              trackBackground={
                approvalAnalyticsContentEnum[data?.status]?.backgroundColor
              }
              colorsData={approvalAnalyticsContentEnum[data?.status]?.color}
              seriesData={
                data?.total !== 0
                  ? Math.floor((data?.count / data?.total) * 100)
                  : 0
              }
            />
            <Box textAlign={"center"}>
              <Typography variant="h5" fontWeight={500} fontSize={"0.87rem"}>
                {approvalAnalyticsContentEnum[data?.status]?.totalTitle} :{" "}
                {data?.total}
              </Typography>
              <Typography variant="h5" fontWeight={500} fontSize={"0.87rem"}>
                {approvalAnalyticsContentEnum[data?.status]?.countTitle} :{" "}
                {data?.count}
              </Typography>
            </Box>
          </Box>

          <Box
            mb={"0.6rem"}
            sx={{
              borderRadius: "4px",
              border: "1px solid rgba(232, 232, 232, 1)",
              padding: "0.5rem",
            }}
          >
            <Box textAlign={"center"} mt={"0.4rem"}>
              <Typography variant="h5" fontWeight={500} fontSize={"0.87rem"}>
                {appContent?.priority}
              </Typography>
            </Box>
            <BarChart
              priorityValue={data?.priority}
              highPriorityColor={priorityValueColor[data?.priority[0]?.status]}
              mediumPriorityColor={
                priorityValueColor[data?.priority[1]?.status]
              }
              lowPriorityColor={priorityValueColor[data?.priority[2]?.status]}
              totalValue={data?.total}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ApprovalAnalytics;
