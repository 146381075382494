export const getChatGroupMenu = (appConstant) => [
  {
    name: appConstant?.chatGroupMenu?.groupMembers,
    value: "members",
  },
  {
    name: appConstant?.chatGroupMenu?.mediaFiles,
    value: "media",
  },
  {
    name: appConstant?.chatGroupMenu?.deactivatePrivateChats,
    value: "privatechat",
  },
  {
    name: appConstant?.chatGroupMenu?.editGroup,
    value: "edit",
  },
  {
    name: appConstant?.chatGroupMenu?.delete,
    value: "delete",
  },
  {
    name:  appConstant?.chatGroupMenu?.exit,
    value: "exit",
  },
  {
    name: appConstant?.chatGroupMenu?.chatLanguage,
    value: "language",
  },
  {
    name: appConstant?.chatGroupMenu?.dateTimeFormat,
    value: "dateformat",
  },
];

export const GroupLevelArkchatPermission = {
  USER: 1,
  MANAGER: 2,
  OWNER: 3,
  REMOVE: 4,
};

export const AccountLevelArkchatPermission = {
  USER: 1,
  ADMIN: 2,
  MANAGER: 3,
};


//group level
// user(1),
// manager(2),
// owner(3),
// removed(4);

// account level
// user(1),
// admin(2),
// manager(3);
export const InviteType = {
  internal: 1,
  external: 2,
  internalOwnerInviteType: 0,
};

export const MessageType = {
  Message: 0,
  Task: 1,
  Approval: 2,
  Direct_Mesage: 3,
  Post: 4,
};

// Pending: 1,
// Cancelled: 2,
// Done: 3,
// Delayed: 4,
// Started: 6,

// estimated_time = {}, start_time = 0, actual_time = {}, end_time = 0 linux time stamp
// https://apptest.arkchat.com/v2/chats/task/4 patch
export const TaskStatus = {
  Pending: 1,
  Cancelled: 2,
  Done: 3,
  Delayed: 4,
  Started: 6,
};


//   1: "Pending",
//   2: "Cancelled",
//   3: "Completed", // Done
//   4: "Delayed",
//   6: "Started",
export const getTaskStatusLabels = (appConstant) => ({
  1: appConstant?.taskStatusLabels.pending,
  2: appConstant?.taskStatusLabels.cancelled,
  3: appConstant?.taskStatusLabels.completed,
  4: appConstant?.taskStatusLabels.delayed,
  6: appConstant?.taskStatusLabels.started,
});

export const taskStatusColor = {
  1: "#F5E120",
  2: "#4F63AF",
  3: "#007F8F",
  4: "#FF4450",
  6: "#3CA9EE",
};

export const ApprovalStatus = {
  Pending: 1,
  Cancelled: 2,
  Approved: 3,
  Delayed: 4,
  Rejected: 5,
};

export const ApprovalStatusLabels = {
  1: "Pending",
  2: "Cancelled",
  3: "Approved",
  4: "Delayed",
  5: "Rejected",
};

export const approvalStatusColor = {
  1: "#F5E120",
  2: "#4F63AF",
  3: "#007F8F",
  4: "#FF4450",
  5: "#4F63AF",
};

export const priorityValueColor = {
  1: "rgba(147, 184, 248, 1)",
  2: "rgba(227, 72, 231, 1)",
  3: "rgba(219, 0, 17, 1)",
};

export const priorityValueLable = {
  1: "Low",
  2: "Medium",
  3: "High",
};

export const priorityValue = {
  Low: 1,
  Medium: 2,
  High: 3,
};

export const tasksidebarmenu = [
  {
    label: "Done Tasks",
    value: "3",
    type: "Status",
  },
  {
    label: "Pending Tasks",
    value: "1",
    type: "Status",
  },
  {
    label: "Delayed Tasks",
    value: "4",
    type: "Status",
  },
  {
    label: "Priority - High",
    value: "3",
    type: "Priority",
  },
  {
    label: "Priority - Medium",
    value: "2",
    type: "Priority",
  },
  {
    label: "Priority - Low",
    value: "1",
    type: "Priority",
  },
  {
    label: "Repeat Tasks",
    value: true,
    type: "Repeat",
  },
];

export const approvalsidebarmenu = [
  {
    label: "Approved",
    value: "3",
    type: "Status",
  },
  {
    label: "Rejected",
    value: "2",
    type: "Status",
  },
  {
    label: "Pending",
    value: "1",
    type: "Status",
  },
  {
    label: "Priority - High",
    value: "3",
    type: "Priority",
  },
  {
    label: "Priority - Medium",
    value: "2",
    type: "Priority",
  },
  {
    label: "Priority - Low",
    value: "1",
    type: "Priority",
  },
  {
    label: "Repeat Approvals",
    value: true,
    type: "Repeat",
  },
];

export const taskAnalyticsContentEnum = {
  pending: {
    title: "Total Pending Tasks",
    countTitle: "Total Pending Tasks",
    color: "rgba(245, 225, 32, 1)",
    backgroundColor: "rgba(118, 118, 118, 0.12)",
  },
  delayed_pending: {
    title: "Delayed Pending Tasks",
    countTitle: "Total Delayed Tasks",
    color: "rgba(255, 68, 80, 1)",
    backgroundColor: "rgba(250, 240, 143, 1)",
  },
  not_delayed_pending: {
    title: "Not Delayed Pending Tasks",
    countTitle: "Total Pending tasks not delayed",
    color: "rgba(245, 225, 32, 1)",
    backgroundColor: "rgba(250, 240, 143, 1)",
  },
  done: {
    title: "Completed Tasks",
    countTitle: "Total Completed Tasks",
    color: "rgba(0, 127, 143, 1)",
    backgroundColor: "rgba(118, 118, 118, 0.12)",
  },
  total: {
    title: "Total Task",
    countTitle: "Total Task",
    color: "rgba(169, 168, 160, 1)",
    backgroundColor: "rgba(118, 118, 118, 0.12)",
  },
  not_started_pending: {
    title: "Pending Tasks Not Started",
    countTitle: "Pending Tasks Not Started",
    color: "rgba(0, 127, 143, 1)",
    backgroundColor: "rgba(118, 118, 118, 0.12)",
  },
  started_pending: {
    title: "Pending tasks started",
    countTitle: "Pending tasks started",
    color: "rgba(0, 127, 143, 1)",
    backgroundColor: "rgba(118, 118, 118, 0.12)",
  },
};

export const approvalAnalyticsContentEnum = {
  pending: {
    title: "Total Pending Approvals",
    totalTitle: "Total Approvals",
    countTitle: "Total Pending Approvals",
    color: "rgba(245, 225, 32, 1)",
    backgroundColor: "rgba(118, 118, 118, 0.12)",
  },
  delayed_pending: {
    title: "Delayed Pending Approvals",
    totalTitle: "Total Approvals",
    countTitle: "Total Delayed Approvals",
    color: "rgba(255, 68, 80, 1)",
    backgroundColor: "rgba(250, 240, 143, 1)",
  },
  not_delayed_pending: {
    title: "Rejected",
    totalTitle: "Total Approvals",
    countTitle: "Rejected",
    color: "rgba(245, 225, 32, 1)",
    backgroundColor: "rgba(250, 240, 143, 1)",
  },
  done: {
    title: "Approved",
    totalTitle: "Total Approvals",
    countTitle: "Approved",
    color: "rgba(0, 127, 143, 1)",
    backgroundColor: "rgba(118, 118, 118, 0.12)",
  },
};

export const daysOfWeek = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thrusday: 4,
  friday: 5,
  saturday: 6,
};

export const months = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

export const monthMapping = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December"
};



export const groupMsgDeleteType = {
  me: "deleteGrpMsgForMe",
  all: "deleteGrpMsgForAll",
};

export const serviceTypeValue = {
  1: "Service",
  2: "Product",
  3: "Art",
  4: "Photo",
  5: "Videos",
};

export const serviceTypeName = {
  Service: 1,
  Product: 2,
  Art: 3,
  Photo: 4,
  Videos: 5,
};

export const accountType = {
  Individual: 1,
  Organisation: 2,
};

export const sellerTypeEnum = {
  Empty: 0,
  Agent: 1,
  Distributor: 2,
  Dealer: 3,
};
export const sellerTypeValue = {
  0: "Empty",
  1: "Agent",
  2: "Distributor",
  3: "Dealer",
};
//p=oriority,s=status

export const exploreServiceTypeNameRelation = {
  5: 1,
  4: 2,
  1: 3,
  2: 4,
  3: 5,
};

export const PubnubEventType = {
  PROFILE_UPDATE: "profile-updated",
  GROUP_CREATED: "new-group-created",
  GROUP_DELETED: "group-deleted",
  GROUP_EDITED: "group-setting-updated",
  USER_ADDED_TO_GROUP: "user-added-to-group",
  USER_GROUP_LIST_UPDATED: "user-group-list-updated",
  USER_REMOVED_FROM_GROUP: "user-removed-from-group",
  USER_EXITED_THE_GROUP: "user-exited-the-group",
  TOPIC_CREATED: "topic-created",
  TOPIC_DELETED: "topic-deleted",
  TOPIC_UPDATED: "topic-updated",
  CONTACT_PERMISSION_UPDATED: "contact-updated",
  CONTACT_ADDED: "contact-added-to-account",
  CONTACT_REMOVED: "contact-removed",
  ACCOUNT_SWITCHED: "account-switched",
  CHAT_LANGUAGE_UPDATED: "chat-language-updated",
  MARK_AS_READ: "mark-as-read",
  ACCOUNT_INVITATION: "account-invitation",
  CHAT_MOVED_TO_ANOTHER_TOPIC: "moved-to-topic",
  CHAT_STARRED: "message-starred",
  CHAT_MSG_DELETE: "message-deleted",
  PUBNUB_TOKEN_UPDATE: "token-updated",
};

export const characterLimit = {
  firstName: 25,
  lastName: 25,
  city: 15,
  organisationName: 50,
};

export const cancelReason = {
  low_quality: "Didn’t find Arkchat’s value proposition useful.",
  too_expensive: "Too expensive.",
  too_complex: "Too difficult to use.",
  missing_features: "Technical glitches.",
  switched_service: "I have decided to use another platform.",
};
