import * as actionTypes from "../../actions/global/type";

const initState = {
  isLoading: false,
  tostmsgData: "",
  indicatorData: [],
  isToastOpen: false,
  isShowInternetToast: false,
};

export const globalReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes?.REQUEST_SHOW_LOADER:
      return {
        ...state,
        isLoading: action?.payload,
      };
    case actionTypes?.REQUEST_HIDE_LOADER:
      return {
        ...state,
        isLoading: action?.payload,
      };
    case actionTypes?.SHOW_TOAST_MESSAGE:
      return {
        ...state,
        tostmsgData: action?.payload,
      };
    case actionTypes?.SHOW_INTERNET_TOAST:
      return {                              
        ...state,                           
        isShowInternetToast: action?.payload,
      };                                      
    case actionTypes?.HANDLE_TOAST_OPEN_CLOSE:
      return {
        ...state,
        isToastOpen: action?.payload,
      };
    case actionTypes?.SAVE_ACTIVITY_INDICATOR:
      return {
        ...state,
        indicatorData: action?.payload,
      };
    default:
      return state;
  }
};
