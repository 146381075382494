import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { Box, Stack, Typography, Button } from "@mui/material";

const FilterCategory = ({
  data,
  handleFilterApply,
  clearFilter,
  setChecked,
  checked,
}) => {
  const handleCheckboxChange = (event, nodeId, nodedata) => {
    const isChecked = event.target.checked;
    let newChecked = isChecked
      ? [...checked, nodeId]
      : checked?.filter((id) => id !== nodeId);
    if (isChecked) {
      const childrenAndGrandchildren = getAllChildrenAndGrandchildren(
        [nodedata],
        nodeId
      );
      newChecked.push(...childrenAndGrandchildren);
    } else {
      const childrenAndGrandchildren = getAllChildrenAndGrandchildren(
        [nodedata],
        nodeId
      );
      newChecked = newChecked?.filter(
        (id) => !childrenAndGrandchildren.includes(id)
      );
    }

    setChecked(newChecked);
  };

  const getAllChildrenAndGrandchildren = (nodes, parentId) => {
    const childrenAndGrandchildren = [];
    nodes?.forEach((node) => {
      if (node?.id === parentId) {
        if (node?.subCategory) {
          childrenAndGrandchildren.push(
            ...node?.subCategory?.map((child) => child?.id)
          );
          for (const child of node?.subCategory) {
            childrenAndGrandchildren.push(
              ...getAllChildrenAndGrandchildren(node?.subCategory, child?.id)
            );
          }
        }
      }
    });
    return childrenAndGrandchildren;
  };

  const clearAppliedFilter = () => {
    clearFilter();
    setChecked([]);
  };
  const renderTree = (nodes) =>
    nodes?.map((node, level) => (
      <TreeItem
        sx={{
          marginBottom: "1rem",
          textAlign: "start",
          ".css-137wtfu-MuiTreeItem-content ": {
            padding: "0rem",
          },
          ".css-137wtfu-MuiTreeItem-content .MuiTreeItem-iconContainer": {
            width: "0rem",
            margin: "0",
          },
        }}
        key={node?.id + "filterCategory"}
        nodeId={node?.id.toString()}
        label={
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              sx={{
                fontSize: "14px",
              }}
            >
              {node?.name}
            </Typography>
            <Checkbox
              sx={{
                "& .MuiSvgIcon-root": {
                  color: "#183243",
                },
              }}
              checked={checked.includes(node.id)}
              onChange={(event) => handleCheckboxChange(event, node.id, node)}
            />
          </Box>
        }
        children={
          node?.subCategory?.length > 0
            ? renderTree(node?.subCategory)
            : undefined
        }
      />
    ));

  return (
    <Box>
      <>
        <Box
          mt={"1rem"}
          height={"60vh"}
          overflow={"auto"}
          sx={{
            "&::-webkit-scrollbar": {
              width: "3px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "3px",
            },
          }}
        >
          <TreeView
            aria-label="file system navigator"
            sx={{
              flexGrow: 1,
              maxWidth: "100%",
            }}
          >
            {renderTree(data)}
          </TreeView>
        </Box>
      </>
      <Box mt={"1rem"}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Button
            onClick={clearAppliedFilter}
            sx={{
              textTransform: "none",
              background: "#DB0011",
              color: "white",
              borderRadius: "3px",
            }}
            variant="contained"
          >
            Clear
          </Button>

          <Button
            onClick={() =>
              checked?.length > 0 && handleFilterApply(checked?.join(","))
            }
            sx={{
              textTransform: "none",
              background: "#DB0011",
              color: "white",
              borderRadius: "3px",
            }}
            variant="contained"
          >
            Apply
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default FilterCategory;
