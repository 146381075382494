import React from "react";
import { useSelector } from "react-redux";
import FilterCategory from "./FilterCategory";
const ProductFilter = ({ handleFilter, clearFilter, setChecked, checked }) => {
  const productServiceCategoryData = useSelector(
    (state) => state?.serviceCategory?.productServiceCategoryData
  );
  return (
    <>
      <FilterCategory
        data={productServiceCategoryData}
        handleFilterApply={handleFilter}
        clearFilter={clearFilter}
        checked={checked}
        setChecked={setChecked}
      />
    </>
  );
};

export default ProductFilter;
