import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { AuthActionButton } from "../../common/StyledComponents/AuthComponents";
import { useNavigate } from "react-router-dom";
import invited_icon from "../../assets/images/Profile/invited.svg";
import referred_icon from "../../assets/images/Profile/refer.svg";
import influencer_icon from "../../assets/images/Profile/Influencer.svg";
import linkedin_icon from "../../assets/images/Profile/linkedin.svg";
import facebook_icon from "../../assets/images/Profile/facebook.svg";
import instagram_icon from "../../assets/images/Profile/instagram.svg";
import twitter_icon from "../../assets/images/Profile/Twitter.svg";
import mail_icon from "../../assets/images/Profile/email.svg";
import googleprofile_icon from "../../assets/images/Profile/google.svg";
import youtubeprofile_icon from "../../assets/images/Profile/Youtube.svg";
import ticktok_icon from "../../assets/images/Profile/tiktok.svg";
import pinterest_icon from "../../assets/images/Profile/pinterest.svg";
import check_background from "../../assets/images/Profile/check_background.svg";
import check from "../../assets/images/Profile/check.svg";
import PopupModal from "../../common/PopupModal";

const classes = {
  signInBox: {
    padding: "1rem 1rem 2rem 2rem",
    border: "1px solid #C4C4C4",
    height: "calc(100vh - 32px)",
    maxHeight: "700px",
    minHeight: "590px",
    width: "100%",
    maxWidth: "690px",
    margin: "auto",
    position: "relative",
    "@media only screen and (max-width:900px)": {
      margin: "auto !important",
      padding: "1.5rem 2rem 2rem 2rem !important",
    },
    "@media only screen and (max-width:1024px)": {
      height: "100%",
      padding: "1rem",
      marginLeft: "-20px",
    },
    "@media only screen and (max-width:1300px)": {
      minHeight: "530px",
    },
  },
  content: {
    paddingLeft: "29px",
    fontSize: "0.75rem",
    color: "#000000",
  },
  nexBtn: {
    position: "absolute",
    bottom: "2rem",
    left: "50%",
    marginLeft: "-2.72rem",
    "@media only screen and (max-width:899px)": {
      position: "relative",
      marginBottom: "-1.8rem",
      bottom: "0 !important",
    },
    "@media only screen and (max-width:1300px)": {
      bottom: "1.5rem",
    },
  },
};

const came_fromFirstRowData = [
  {
    icon: invited_icon,
    title: "Invited by Arkchat user",
    value: "invited_by_arkchat_user",
  },
  {
    icon: referred_icon,
    title: "Referred by a friend",
    value: "referred_by_a_friend",
  },
  {
    icon: influencer_icon,
    title: "Influencer",
    value: "influencer",
  },
];

const came_fromData = [
  {
    icon: linkedin_icon,
    title: "Linkedin",
    value: "linkedin",
  },
  {
    icon: facebook_icon,
    title: "Facebook",
    value: "facebook",
  },
  {
    icon: instagram_icon,
    title: "Instagram",
    value: "instagram",
  },
  {
    icon: twitter_icon,
    title: "Twitter",
    value: "twitter",
  },
  {
    icon: mail_icon,
    title: "Email",
    value: "email",
  },
  {
    icon: googleprofile_icon,
    title: "Google",
    value: "google",
  },
  {
    icon: youtubeprofile_icon,
    title: "YouTube",
    value: "youTube",
  },
  {
    icon: ticktok_icon,
    title: "Tiktok",
    value: "tiktok",
  },
  {
    icon: pinterest_icon,
    title: "Pinterest",
    value: "pinterest",
  },
];

const UpdateProfile = () => {
  const [isIndividualChecked, setIndividualChecked] = useState(false);
  const [isOrganizationChecked, setOrganizationChecked] = useState(false);
  const [selectInvitedFrom, setSelecteInvitedFrom] = useState(null);
  const [isPopupModalOpen, setPopupModalOpen] = useState(false);
  const navigate = useNavigate();
  const handleIndividualChange = (event) => {
    if (event.target.checked) {
      setIndividualChecked(true);
      setOrganizationChecked(false);
    }
  };

  const handleOrganizationChange = (event) => {
    if (event.target.checked) {
      setIndividualChecked(false);
      setOrganizationChecked(true);
    }
  };
  const handleClick = () => {
    if (isIndividualChecked || isOrganizationChecked) {
      if (selectInvitedFrom) {
        if (isIndividualChecked) {
          navigate("/individualprofile");
        }
        if (isOrganizationChecked) {
          navigate("/organisationprofile");
        }
      } else {
        setPopupModalOpen(true);
      }
    }
  };
  const handleSelectInvitedFrom = (value) => {
    setSelecteInvitedFrom(value);
    localStorage.setItem("referred_by", JSON.stringify(value));
  };
  return (
    <Box sx={classes.signInBox}>
      {isPopupModalOpen && (
        <PopupModal
        title="Alert"
          content="Before moving to the next section, let us know how you heard about us by clicking one of the icons."
          setPopupModalOpen={setPopupModalOpen}
        />
      )}
      <Typography
        fontSize={"1.7rem"}
        fontWeight={600}
        variant="h5"
        mb={"0.4rem"}
      >
        Get Started
      </Typography>
      <Box>
        <Typography fontSize={"1rem"} fontWeight={600} variant="h5">
          For Whom are you signing up?
          <FormHelperText>Please select one of the options.</FormHelperText>
        </Typography>
      </Box>
      <FormGroup>
        <Box mb={"0.5rem"}>
          <FormControlLabel
            required
            sx={{
              ".MuiFormControlLabel-label": {
                fontSize: "0.87rem",
              },
            }}
            control={
              <Checkbox
                style={{ color: "#183243" }}
                checked={isIndividualChecked}
                onChange={handleIndividualChange}
              />
            }
            label="I have signed up as an individual"
            labelPlacement="end"
          />
          <FormHelperText sx={classes.content}>
            Select this option if you work for an organisation or are a
            freelancer.
          </FormHelperText>
        </Box>

        <Box>
          <FormControlLabel
            required
            sx={{
              ".MuiFormControlLabel-label": {
                fontSize: "0.87rem",
              },
            }}
            control={
              <Checkbox
                checked={isOrganizationChecked}
                onChange={handleOrganizationChange}
                sx={{ "& .MuiSvgIcon-root": { color: "#183243" } }}
              />
            }
            label="I have signed up on behalf of organisation"
            labelPlacement="end"
          />
          <FormHelperText sx={classes.content}>
            Select this option if you own an organisation or are an executive
            having the authority to create an account on behalf of your
            organisation.
          </FormHelperText>
        </Box>

        <Box width={"98%"} margin={"auto"} mt={"0.9rem"}>
          <Typography fontSize={"1rem"} fontWeight={600} variant="subtitle2">
            How did you hear about us?
            <FormHelperText>Please click on one of the icons</FormHelperText>
          </Typography>
        </Box>
        <Box display={"flex"} flexWrap={"wrap"} gap={"0.5rem"} mt={"0.9rem"}>
          {came_fromFirstRowData?.map((item, i) => (
            <Box width={"15%"} key={i + "came_fromFirstRowData"}>
              <Box
                textAlign={"center"}
                sx={{ cursor: "pointer" }}
                position={"relative"}
                onClick={() => handleSelectInvitedFrom(item?.value)}
              >
                <img src={item?.icon} alt="" />
                {item?.value === selectInvitedFrom && (
                  <Box>
                    <img
                      style={{
                        position: "absolute",
                        top: "-4px",
                        right: "12px",
                      }}
                      src={check_background}
                      alt=""
                    />
                    <img
                      style={{
                        position: "absolute",
                        top: "1px",
                        right: "15px",
                      }}
                      src={check}
                      alt=""
                    />
                  </Box>
                )}
              </Box>
              <Box width={"80%"} textAlign={"center"} margin={"auto"}>
                <Typography variant="subtitle2" fontSize={"0.65rem"}>
                  {item?.title}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Box display={"flex"} flexWrap={"wrap"} gap={"0.3rem"}>
          {came_fromData?.map((item, i) => (
            <Box width={"10%"} key={i + "came_fromData"}>
              <Box
                textAlign={"center"}
                sx={{ cursor: "pointer" }}
                position={"relative"}
                onClick={() => handleSelectInvitedFrom(item?.value)}
              >
                <img src={item?.icon} alt="" />
                {item?.value === selectInvitedFrom && (
                  <Box>
                    <img
                      style={{ position: "absolute", top: "-4px", right: "0" }}
                      src={check_background}
                      alt=""
                    />
                    <img
                      style={{
                        position: "absolute",
                        top: "2px",
                        right: "3.5px",
                      }}
                      src={check}
                      alt=""
                    />
                  </Box>
                )}
              </Box>
              <Box width={"80%"} textAlign={"center"} margin={"auto"}>
                <Typography variant="subtitle2" fontSize={"0.65rem"}>
                  {item?.title}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Box sx={classes.nexBtn}>
          <AuthActionButton
            onClick={handleClick}
            type="submit"
            variant="contained"
            disabled={
              isOrganizationChecked || isIndividualChecked ? false : true
            }
          >
            Next
          </AuthActionButton>
        </Box>
      </FormGroup>
    </Box>
  );
};

export default UpdateProfile;
