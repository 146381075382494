import { getCookie } from "./cookie";

export const BASE_API_URL = process.env.REACT_APP_BASE_PATH;

export const defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const token = getCookie("token");
