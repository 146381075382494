import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import UpdateProfile from "./UpdateProfile";
import IntroVideo from "../../common/IntroVideo";

const classes = {
  Wrapper: {
    display: "flex",
    alignItems: "center",

    "@media only screen and (min-width: 1901px)": {
      width: "80%",
      margin: "auto",
    },
  },
};

const Profile = () => {
  return (
    <>
      <Box m={2}>
        <Grid container justifyContent="space-between" sx={classes?.Wrapper}>
          <Grid item xs={12} md={5}>
          <IntroVideo />
          </Grid>
          <Grid item xs={12} md={6}>
            <UpdateProfile />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Profile;
