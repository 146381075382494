import axios from "axios";
import { REFRESH_TOKEN_API_URL, SIGNUP_API_URL, SIGNUP_DONE } from ".";
import { SET_TOKEN_DONE } from "../../../reducers/auth";
import { EMAIL_STORE, REMOVE_SET_CURRENT_USER_LOGEDIN } from "../login";
import { REQUEST_FAILED } from "../../global/type";
import { getCookie, setCookie } from "../../../../config/cookie";

export const userSignup = (formData) => {
  let API_URL = SIGNUP_API_URL;
  return (dispatch) => {
    return axios
      .post(API_URL, formData)
      .then((res) => {
        if (res?.status == 200) {
          dispatch({
            type: SIGNUP_DONE,
            payload: res?.data,
          });
          dispatch({
            type: EMAIL_STORE,
            payload: formData?.email,
          });
          dispatch({
            type: SET_TOKEN_DONE,
            payload: res?.data.data?.token,
          });
          return res?.data;
        } else {
          dispatch({
            type: SIGNUP_DONE,
            payload: res,
          });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};

export const getRefreshToken = () => {
  let API_URL = REFRESH_TOKEN_API_URL;
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .get(API_URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setCookie("token", res?.data?.data?.token, 30);
          return res?.data;
        } else {
          dispatch({
            type: REMOVE_SET_CURRENT_USER_LOGEDIN,
          });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};
