import React, { useState } from "react";
import { Box, Stack, Tabs, Tab, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import PricingPlans from "./Pricing/PricingPlans";
import { useTranslation } from "react-i18next";
import ManageSubscriptionHome from "./ManageSubscription/ManageSubscriptionHome";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "1rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  Tabs_01: {
    minHeight: "38px",
    width: "100%",
    "& .MuiTab-root.Mui-selected": {
      background: "#183243",
      color: "#fff",
    },
  },

  tabs: {
    marginRight: "0.4rem",
    color: "#183243",
    textTransform: "none",
    minWidth: "80px",
    minHeight: "1.2rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "1.2rem",
    lineHeight: "normal",
    background: "#fff",
    borderRadius: "3px",
    border: "1px solid #000000",

    img: {
      margin: "0 !important",
    },
    "&.Mui-selected": {},
    "@media (max-width: 64em)": {},
  },
};
const TabPanel = ({ children, value, index }) => (
  <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>
);
const Subscription = ({
  setPricingDrawerOpen,
  setQuantityDarwerOpen,
  setBillingFormDrawerOpen,
  setManageActiveMemberDrawerOpen
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [activeButton, setActiveButton] = useState(0);
  const handleViewChange = (event, newValue) => {
    setActiveButton(newValue);
  };

  return (
    <Box sx={classes.drawerBody}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => setPricingDrawerOpen(false)}
          />
        </Typography>
        <Typography color={"black"}>{appContent?.pricing}</Typography>
      </Stack>

      <Box width={"90%"} margin={"auto"}>
        <Tabs
          value={activeButton}
          onChange={handleViewChange}
          sx={classes.Tabs_01}
          TabIndicatorProps={{
            style: { backgroundColor: "transparent" },
          }}
        >
          <Tab disableRipple sx={classes.tabs} label={appContent?.pricing} />
          <Tab
            disableRipple
            sx={classes.tabs}
            label={appContent?.manage_Subscription}
          />
        </Tabs>
      </Box>

      <Box width={"90%"} margin={"auto"}>
        <TabPanel value={activeButton} index={0}>
          <PricingPlans
            setBillingFormDrawerOpen={setBillingFormDrawerOpen}
            setQuantityDarwerOpen={setQuantityDarwerOpen}
            setManageActiveMemberDrawerOpen={setManageActiveMemberDrawerOpen}
          />
        </TabPanel>
        <TabPanel value={activeButton} index={1}>
          <ManageSubscriptionHome/>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Subscription;
