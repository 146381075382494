import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import chat_language_illustration from "../../assets/images/chats/chat_language_illustration.svg";
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getInviteUrl } from "../../appStore/actions/subscriber/action";
import { useNavigate } from "react-router-dom";
import { handleCopyClick } from "../../utils/copytoClipboard";
import { profileUpdate } from "../../appStore/actions/profile/action";
import { showToastMsg } from "../../appStore/actions/global/action";
const classes = {
  inputField: {
    borderBottom: "1px solid grey",
    width: "100%",
    textAlign: "center",
    "& input": {
      textAlign: "center",
    },
  },
  image: {
    width: "100%",
  },
  headerBox: {
    display: "flex",
    width: "100%",
    padding: "0.2rem 0rem",
    justifyContent: "center",
    backgroundColor: "#F3F3F3 !important",
  },
};

export const InviteButton = styled(Button)({
  backgroundColor: "#DB0011",
  borderColor: "#DB0011",
  color: "#fff",
  padding: "0.31rem 3.4rem",
  borderRadius: "0",
  display: "block",
  margin: "auto",
  textTransform: "none",
  ":hover": {
    backgroundColor: "#DB0011",
    borderColor: "#DB0011",
    color: "#fff",
  },
});

const InviteComponent = () => {
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const inviteData = useSelector((state) => state?.subscriber);
  const firstGroupData = useSelector(
    (state) => state?.arkchatgroup?.firstGroup
  );
  const navigate = useNavigate();
  const handleChecked = () => {
    setIsChecked(!isChecked);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    async function apiCall() {
      dispatch(getInviteUrl(1, firstGroupData?.id));
    }
    apiCall();
  }, [dispatch]);
  //
  const handleToast = (type) => {
    if (type === "error") {
      dispatch(showToastMsg(appContent?.wrongError))
    } else {
      dispatch(showToastMsg(appContent?.copiedClipboard))
    }
  };
  const handleBtnClick = () => {
    dispatch(profileUpdate({ is_first_invite_sent: true }, false));
    navigate("/chat");
  };
  return (
    <>
      <Box sx={{ height: "89vh" }} position={"relative"}>
        <Box mb={4} sx={classes?.headerBox}>
          <Typography fontSize={"1rem"}>Get Started</Typography>
        </Box>

        <Box width={"21%"} margin={"auto"}>
          <img
            style={{ width: "290px", height: "180px" }}
            src={chat_language_illustration}
            alt="createGroup"
          />
        </Box>
        <Box mt={2}>
          <Typography align="center" variant="subtitle1" gutterBottom>
            {appContent?.invitePeople}
          </Typography>

          <Box
            display={"flex"}
            width={"25%"}
            margin={"auto"}
            justifyContent={"center"}
            gap={"4%"}
            mt={"1rem"}
          >
            <InviteButton
              sx={{
                padding: "0.4rem 1rem",
                bgcolor: "white",
                color: "#000000",
                border: "1px solid #DB0011",
                borderRadius: "0.12rem",
                borderColor: "#000000",
              }}
              onClick={() =>
                handleCopyClick(
                  inviteData?.inviteUrlData?.data?.inviteMessage,
                  handleToast
                )
              }
            >
              Copy invite
            </InviteButton>
           
          </Box>
          <Box mt={"0.8rem"} textAlign="center">
            <Typography fontSize={"0.75rem"} color={"#183242"} variant="body2">
              Share invite through email / WhatsApp etc.
            </Typography>
          </Box>

          <Box mt={"1.5rem"} textAlign="center">
            <Typography color={"#183242"} variant="body2">
              {appContent?.notifyYou} {appContent?.someOneJoinYourTeam}
            </Typography>
          </Box>
          <Box>
            <Box textAlign={"center"} width={"90%"} margin={"auto"}>
              <FormControlLabel
                required
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "1rem",
                  },
                }}
                control={
                  <Checkbox
                    style={{ color: "#183243" }}
                    checked={isChecked}
                    onChange={handleChecked}
                  />
                }
                label={appContent?.inviteConfirmation}
                labelPlacement="end"
              />
            </Box>
            <Box width={"100%"} position={"absolute"} bottom={"1rem"}>
              <InviteButton
                sx={{
                  padding: "0.5rem 2rem",
                  borderRadius: "0.12rem",
                }}
                variant="outlined"
                disabled={isChecked ? false : true}
                onClick={handleBtnClick}
              >
                <Typography color={isChecked ? "#ffff" : "#e1e1e1ad"}>
                  {" "}
                  {appContent?.next}
                </Typography>
              </InviteButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default InviteComponent;
