import { BASE_API_URL } from "../../../config/url";

export const GET_COUNTRY = "GET_COUNTRY_DONE";
export const GET_LANGUAGE = "GET_LANGUAGE_DONE";
export const GET_INDUSTRY_VIRTICAL = "GET_INDUSTRY_VIRTICAL_DONE";

export const GET_SERVICE_CATEGORY="GET_SERVICE_CATEGORY";
export const GET_ART_SERVICE_CATEGORY="GET_ART_SERVICE_CATEGORY";
export const GET_PRODUCT_SERVICE_CATEGORY="GET_PRODUCT_SERVICE_CATEGORY";
export const GET_PHOTO_SERVICE_CATEGORY="GET_PHOTO_SERVICE_CATEGORY";
export const GET_VIDEO_SERVICE_CATEGORY="GET_VIDEO_SERVICE_CATEGORY";

export const GET_COUNTRY_ERROR = "GET_COUNTRY_DONE_ERROR";
export const GET_LANGUAGE_ERROR = "GET_LANGUAGE_DONE_ERROR";
export const GET_INDUSTRY_VIRTICAL_ERROR = "GET_INDUSTRY_VIRTICAL_DONE_ERROR";
export const GET_SERVICE_CATEGORY_ERROR="GET_SERVICE_CATEGORY_ERROR";
export const GET_FAQ_LIST_DATA="GET_FAQ_LIST_DATA";

export const COUNTRY_API_URL = `${BASE_API_URL}/masterdata/countries`;
export const LANGUAGE_API_URL = `${BASE_API_URL}/masterdata/languages`;
export const INDUSTRY_VIRTICAL_API_URL = `${BASE_API_URL}/masterdata/industry-verticals`;

export const INDUSTRY_LIST_URL = `${BASE_API_URL}/masterdata/industry-verticals`;
export const SERVICE_CATEGORY_URL=`${BASE_API_URL}/masterdata/categories`

export const FAQ_LIST_URL=`${BASE_API_URL}/masterdata/faqs`;
