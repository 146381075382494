import React from "react";
import { Chip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useDispatch } from "react-redux";
import {
  handleAppSelectedTopic,
  handleAppSelectedTopicToSaveMsg,
  handleScrollToBottom,
  handleStarMsgScreen,
} from "../../../appStore/actions/AppState/action";
import { handleFetchMessages } from "../../../appStore/actions/ArkchatGroup/action";
import useAppSelectors from "../../../customHooks/useAppSelectors";

const GroupInfoHeader = ({ setMediaFilesOpen, isTopicOpen }) => {
  const dispatch = useDispatch();
  const { selectedGroup, selectedTopic, isThreadScreen, showStarMessage } =
    useAppSelectors();
  const handleDelete = () => {
    dispatch(handleAppSelectedTopic(null));
    dispatch(handleAppSelectedTopicToSaveMsg(null));
    dispatch(handleFetchMessages(selectedGroup?.pnId, null));
  };
  const handleRemoveStarScreen = () => {
    dispatch(handleStarMsgScreen(!showStarMessage));
    dispatch(handleFetchMessages(selectedGroup?.pnId, selectedTopic?.id));
    dispatch(handleScrollToBottom(true));
  };
  return (
    <Stack
      width={"100%"}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        padding: "0rem 0.25rem",
        backgroundColor: "#E1E1E1",
        height: "1.8rem",
      }}
    >
      {!isThreadScreen ? (
        <Stack
          justifyContent={"center"}
          spacing={"0.25rem"}
          width={"80%"}
          direction={"row"}
          alignItems={"center"}
        >
          <Box maxWidth="50%">
            <Typography
              sx={{
                fontSize: "0.81rem",
                fontWeight: 600,
                // whiteSpace: "nowrap",
                // overflow: "hidden",
                // textOverflow: "ellipsis",
              }}
              title={`${selectedGroup?.name}${
                selectedGroup?.isBuyerSellerGroup ? " - Buyer/Seller Group" : ""
              }`}
            >
              {`${selectedGroup?.name}${
                selectedGroup?.isBuyerSellerGroup ? " - Buyer/Seller Group" : ""
              }`}
            </Typography>
          </Box>

          <Box maxWidth="40%">
            {selectedGroup?.isGroup && !showStarMessage && selectedTopic && (
              <Chip
                sx={{
                  height: "unset",
                  "& .MuiChip-label": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
                label={`${selectedTopic.name}`}
                variant="outlined"
                onDelete={handleDelete}
                title={selectedTopic.name}
              />
            )}
            {selectedGroup?.isGroup && showStarMessage && (
              <Chip
                sx={{
                  height: "unset",
                  "& .MuiChip-label": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
                label={"Starred Chats"}
                variant="outlined"
                onDelete={handleRemoveStarScreen}
              />
            )}
          </Box>
        </Stack>
      ) : (
        <Stack
          sx={{
            width: "60.7%",
            justifyContent: "flex-start",
          }}
        >
          <Box>
            <Typography
              variant="subtitle1"
              fontWeight={200}
              ml={"0.4rem"}
              fontSize={"0.8rem"}
            >
              Message Thread
            </Typography>
          </Box>
        </Stack>
      )}
      <Box display={"flex"} alignItems={"center"} gap={"0.4rem"}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={0.5}
          sx={{ cursor: "pointer" }}
          onClick={() => setMediaFilesOpen(true)}
          mr={isThreadScreen ? "0.4rem" : "0rem"}
        >
          <Box>
            <svg
              width="14"
              height="14"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5562 3.32087L10.543 0.470187C10.2225 0.166969 9.80297 0 9.36178 0H3.625C2.67728 0 1.90625 0.771031 1.90625 1.71875V14.2812C1.90625 15.229 2.67728 16 3.625 16H12.375C13.3227 16 14.0938 15.229 14.0938 14.2812V4.56944C14.0938 4.09916 13.8978 3.64406 13.5562 3.32087ZM12.6457 3.75H10.3125C10.2263 3.75 10.1562 3.67991 10.1562 3.59375V1.39488L12.6457 3.75ZM12.375 15.0625H3.625C3.19422 15.0625 2.84375 14.712 2.84375 14.2812V1.71875C2.84375 1.28797 3.19422 0.9375 3.625 0.9375H9.21875V3.59375C9.21875 4.19684 9.70941 4.6875 10.3125 4.6875H13.1562V14.2812C13.1562 14.712 12.8058 15.0625 12.375 15.0625Z"
                fill={"#000000"}
              />
            </svg>
          </Box>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            maxWidth="100%"
            fontSize={"0.85rem"}
            title={
              selectedGroup?.isGroup
                ? isThreadScreen
                  ? "Thread Files"
                  : selectedGroup !== null && selectedTopic !== null
                  ? "Topic Files"
                  : selectedGroup !== null && !isThreadScreen
                  ? "Group Files"
                  : "Files"
                : "Files"
            }
          >
            {selectedGroup?.isGroup
              ? isThreadScreen
                ? "Thread Files"
                : selectedGroup !== null && selectedTopic !== null
                ? "Topic Files"
                : selectedGroup !== null && !isThreadScreen
                ? "Group Files"
                : "Files"
              : "Files"}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

export default GroupInfoHeader;
