import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import NavigationBtnBox from "./NavigationBtnBox";
import { classes } from "../PostCreateIndex";

const HorizontalStepper = ({
  stepContent,
  handleNext,
  handleStepClick,
  activeStep,
  handleClick,
  setActiveStep,
  isLoading
}) => {
  return (
    <Box
      position={"relative"}
      sx={{ width: "100%" }}
      // border={"1px solid"}
    >
      <Box width={"90%"} margin={"auto"} mb={"0.5rem"}>
        <Stepper activeStep={activeStep}>
          {stepContent?.map((content, index) => (
            <Step sx={classes?.active} key={index + "stepContent"}>
              <StepLabel>
                {index === 0 && "Update Profile"}
                {index === 1 && "Create Post"}
                {index === 2 && "Preview & post"}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box
        overflow={"scroll"}
        height={"calc(100vh - 8rem)"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "1px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#F9F8F8",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "grey",
          },
        }}
      >
        <Box>{stepContent[activeStep]}</Box>

        <NavigationBtnBox
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          handleClick={handleClick}
          handleNext={handleNext}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default HorizontalStepper;
