import React from "react";
import { useSelector } from "react-redux";
import PostLandingpage from "./PostLandingpage";
import { Box } from "@mui/material";
import HelperScreen from "../../common/ToolTip/HelperScreen";
import { useTranslation } from "react-i18next";

const Post = () => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const userData = useSelector((state) => state?.profile?.profileData);
  return (
    <Box width={"100%"} margin={"auto"}>
      {!userData?.data?.tooltipPostScreenWeb && (
        <HelperScreen
          updateKey={"tooltip_post_screen_web"}
          content={[appContent?.postContent1, appContent?.postContent2]}
        />
      )}
      <PostLandingpage />
    </Box>
  );
};

export default Post;
