import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import {
  IndicatorIcon,
  createStyledIndicatorButton,
} from "../../../../common/StyledComponents/SideBarComponents";
import GroupLogo from "../../../../assets/images/Grouplogo.svg";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#E1E1E1",
    color: "black",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },

  internalExternalButton: {
    background: "white",
    border: "1px solid #183243",
    color: "#183243",
    borderRadius: "0px",
    "&:hover": {
      background: "white",
    },
  },

  activeButton: {
    "&:hover": {
      background: "#183243",
    },
    background: "#183243",
    borderRadius: "0px",
    color: "white",
  },

  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  radiogp: {
    display: "block",
  },
  groupMember: {
    backgroundColor: "#F3F3F3",
    padding: "20px 10px",
  },
  selectedItem: {
    // backgroundColor: "#183243",
    backgroundColor: "#E1E1E1",
    color: "#000",
  },
  nonselectedItem: {
    color: "#000",
  },

  // add group member
  addgmember: {
    transform: "translate(0, 70%)",
    textAlign: "center",
  },
  // add group member
};
const TaskGroupList = ({
  handlecloseGroupList,
  handlegroupItemclick,
  selectedTaskGroup,
}) => {
  const groupList = useSelector((state) => state?.arkchatgroup?.groupList);

  const SmallActionButton = createStyledIndicatorButton("#E3E1E1");

  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={classes.header}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography sx={classes.iconDiv}>
                <CloseIcon sx={classes.iconn} onClick={handlecloseGroupList} />
              </Typography>
              <Typography variant="subtitle2">Group</Typography>
            </Stack>
          </Stack>
          <Stack
            mt={"0.5rem"}
            overflow={"scroll"}
            overflowY={"hidden"}
            height={"calc(100vh - 7rem)"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "2px",
                padding: "0px 2px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#F9F8F8",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "grey",
              },
            }}
            padding={" 10px 25px"}
          >
            {groupList
              ?.filter((item) => item?.isGroup)
              ?.map((item) => {
                return (
                  <div
                    key={item?.id + "taskGrpList"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  >
                    <FormControlLabel
                      sx={{ marginBottom: "10px" }}
                      checked={
                        selectedTaskGroup?.length > 0 &&
                        selectedTaskGroup[0]?.id === item?.id
                      }
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 30,
                              color: "#183243",
                            },
                          }}
                          onChange={(e) => handlegroupItemclick(e, item)}
                          icon={
                            <SmallActionButton
                              size="small"
                              variant="contained"
                              sx={{ mr: "4px" }}
                            >
                              {item?.isGroup && (
                                <IndicatorIcon src={GroupLogo} />
                              )}
                            </SmallActionButton>
                          }
                        />
                      }
                      label={`${item?.name}`}
                    />
                  </div>
                );
              })}
          </Stack>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default TaskGroupList;
