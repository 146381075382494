import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import closeLogo from "../assets/images/chats/closeLogo.svg";
import { CloseButton, CloseIcon } from "../page/Profille/CustomDrawer";
import profile_background_logo from "../assets/images/Network.svg";
import profile_logo from "../assets/images/auth/profileimg.svg";
import linkedin from "../assets/images/postcard/Linkedin.svg"
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.30)",
  borderRadius: "4px",
};

const PictureBox = ({ profileImgUrl, coverImgUrl, initialName = "" }) => {
  return (
    <Box maxHeight={"8rem"} height="8rem" width={"100%"} position={"relative"}>
      <Box height={"100%"} overflow={"hidden"}>
        <img
          src={coverImgUrl ? coverImgUrl : profile_background_logo}
          alt="coverImg"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>

      <Box
        bgcolor={"#EAEAEA"}
        position={"absolute"}
        left="50%"
        marginLeft={"-50px"}
        transform="translateX(-40%)"
        bottom="-20%"
        borderRadius={"0.2rem"}
        overflow={"hidden"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          position={"relative"}
          height={"100px"}
          width={"100px"}
          minHeight={"100%"}
          minWidth={"100%"}
        >
          {profileImgUrl ? (
            <img
              src={profileImgUrl ? profileImgUrl : profile_logo}
              alt="profileImg"
              style={{ width: "100%", height: "100%", objectFit: "fill" }}
            />
          ) : (
            <Typography fontSize={"1.8rem"}>
              {initialName && initialName[0]}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const ProfileViewModal = ({
  isProfileViewOpen,
  setProfileViewModal,
  profileViewData
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const handleClose = () => {
    setProfileViewModal(false);
  };
  // name:data?.postData?.postBy,
  //     companyName:data?.subscriberInfo?.subscriberFirmName,
  //     companyIndustryType:data?.subscriberInfo?.subscriberAccountIndustry,
  //     // city:data?.subscriberInfo?.city,
  //     // country:data?.subscriberInfo?.country,
  //     city:data?.userInfo?.city,
  //     country:data?.userInfo?.country,
  //     profilePic:data?.userInfo?.profileImgUrl,
  //     coverImage:data?.userInfo?.coverImgUrl,
  //     linkedin:data?.userInfo?.linkedin

  
  return (
    <div>
      <Modal open={isProfileViewOpen} onClose={handleClose}>
        <Box sx={style}>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"0.4rem"}
            bgcolor={"rgba(234, 234, 234, 1)"}
            padding={"0.5rem"}
          >
            <Box>
              <CloseButton
                onClick={handleClose}
                size="small"
                variant="contained"
              >
                <CloseIcon src={closeLogo} alt="close" />
              </CloseButton>
            </Box>

            <Box>
              <Typography
                color={"#00000"}
                fontSize={"1rem"}
                variant="h6"
                fontWeight={600}
              >
                {appContent?.profile}
              </Typography>
            </Box>
          </Box>

          <Box position={"relative"}>
            <PictureBox
              profileImgUrl={profileViewData?.profileImgUrl}
              coverImgUrl={profileViewData?.coverImgUrl}
              initialName={profileViewData?.userName}
            />
            {profileViewData?.linkedin && (
              <Box
                sx={{ cursor: "pointer" }}
                position={"absolute"}
                right={"8rem"}
                bottom={"-1.6rem"}
              >
                <Link
                  href={profileViewData?.linkedin}
                  target="_blank"
                  rel="noopener"
                >
                  <img src={linkedin} title="Linkedin" alt="linkedin" />
                </Link>
              </Box>
            )}
          </Box>

          <Box
            mt={"2rem"}
            textAlign={"center"}
            padding={"0.5rem 0rem"}
            mb={"1rem"}
          >
            <Box>
              <Typography fontWeight={600}>
                {profileViewData?.userName}
              </Typography>
            </Box>
            <Box>
              <Typography>{profileViewData?.companyName}</Typography>
            </Box>
            <Box>
              {profileViewData?.website !== "" && (
                <Link
                  href={profileViewData?.website}
                  target="_blank"
                  rel="noopener"
                >
                  <Typography
                    fontSize={"0.88rem"}
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    {profileViewData?.website}
                  </Typography>
                </Link>
              )}
            </Box>
            <Box>
              <Typography>
                {profileViewData?.indusrtyName} <br />
                {profileViewData?.cityName}, {profileViewData?.countryName}
              </Typography>
            </Box>

            {profileViewData?.profileBrief && (
              <Box width={"85%"} mt={"1rem"} margin={"auto"}>
                <Typography sx={{ wordBreak: "break-word" }} width={"100%"}>
                  {profileViewData?.profileBrief}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default ProfileViewModal;
