import axios from "axios";
import {
  ALL_PENDING_INVITATRION,
  CREATE_SUBSCRIBER_API,
  CREATE_SUBSCRIBER_DONE,
  CREATE_SUBSCRIBER_ERROR,
  GET_INVITE_URL_DONE,
  GET_INVITE_URL_ERROR,
  INVITATION_RETRIVED_DATA,
  JOIN_USER_DONE,
  JOIN_USER_ERROR,
} from ".";
import { REQUEST_FAILED } from "../global/type";
import { eraseCookie, getCookie, setCookie } from "../../../config/cookie";
import { showToastMsg } from "../global/action";

export const createSubscriber = (formData) => {
  let API_URL = `${CREATE_SUBSCRIBER_API}/create`;
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .post(API_URL, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: CREATE_SUBSCRIBER_DONE,
            payload: res?.data?.data,
          });
          return res?.data;
        } else {
          dispatch({
            type: CREATE_SUBSCRIBER_ERROR,
            payload: res?.data?.data,
          });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};

export const getInviteUrl = (type, groupId) => {
  let API_URL = `${CREATE_SUBSCRIBER_API}/add/invite?inviteType=${type}`;
  if (groupId) {
    API_URL += `&groupId=${groupId}`;
  }
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .get(API_URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_INVITE_URL_DONE,
            payload: res?.data?.data,
          });
          return res?.data;
        } else {
          dispatch({
            type: GET_INVITE_URL_ERROR,
            payload: res?.data?.data,
          });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
        //
      });
  };
};


export const createRetriveInvite = (formData) => {
  // https://apptest.arkchat.com/v2/onboarding/subscriber/invitations
  // https://apptest.arkchat.com/v2/onboarding/subscriber/invitations/{invitation_id}
  // https://apptest.arkchat.com/v2/onboarding/subscriber/invitations
  let API_URL = `${CREATE_SUBSCRIBER_API}/invitations`;
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .post(API_URL, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: INVITATION_RETRIVED_DATA,
          payload: res?.data?.data,
        });
        return res?.data;
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
        //
      });
  };
};

export const handleAcceptIgnoreInvite = (reqBody, invitation_id) => {
  // https://apptest.arkchat.com/v2/onboarding/subscriber/invitations
  // https://apptest.arkchat.com/v2/onboarding/subscriber/invitations/{invitation_id}
  // https://apptest.arkchat.com/v2/onboarding/subscriber/invitations
  let API_URL = `${CREATE_SUBSCRIBER_API}/invitations/${invitation_id}`;
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .put(API_URL, reqBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
        //
      });
  };
};

export const getAllPendingInvitation = () => {
  let API_URL = `${CREATE_SUBSCRIBER_API}/invitations`;
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .get(API_URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: ALL_PENDING_INVITATRION,
            payload: res?.data?.data,
          });
          return res?.data;
        } else {
          dispatch({
            type: GET_INVITE_URL_ERROR,
            payload: res?.data?.data,
          });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
        //
      });
  };
};

export const switchAccount = (switch_subscriber_id) => {
  let API_URL = `${CREATE_SUBSCRIBER_API}/user/switch`;
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .post(API_URL, switch_subscriber_id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        eraseCookie("subsCriber_id");
        setCookie(
          "subsCriber_id",
          switch_subscriber_id?.switch_subscriber_id,
          30
        );
        if (res.status === 200) {
          // dispatch({
          //   type: JOIN_USER_DONE,
          //   payload: res,
          // });
          return res?.data;
        } else {
          // dispatch({
          //   type: JOIN_USER_ERROR,
          //   payload: res,
          // });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};

export const removeExternalAccount = (subscriber_id) => {
  let API_URL = `${CREATE_SUBSCRIBER_API}/remove-account/${subscriber_id}`;
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .get(API_URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return res?.data;
        } else {
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};

export const removeExitUserAccount = (remove_user_id) => {
  let API_URL = `${CREATE_SUBSCRIBER_API}/user/remove`;
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .post(API_URL, remove_user_id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // dispatch({
          //   type: JOIN_USER_DONE,
          //   payload: res,
          // });
          return res?.data;
        } else {
          // dispatch({
          //   type: JOIN_USER_ERROR,
          //   payload: res,
          // });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};
// subscriber/user/update

export const updateAccountLevelPermission = (formData) => {
  let API_URL = `${CREATE_SUBSCRIBER_API}/user/update`;
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .post(API_URL, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(showToastMsg(res?.data?.message));
          return res?.data;
        } else {
          dispatch(showToastMsg(res?.data?.message));
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};
