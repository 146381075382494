import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";

const PaymentEnvironment = ({ children }) => {
  const paymentIntentData = useSelector(
    (state) => state?.subscription?.paymentIntentData
  );
  const stripePromise = loadStripe(
    process.env.NODE_ENV === "development"
      ? "pk_test_51Iyt1aIWA448nFWKdP01vg6idkCFTbeVygY8A74m1bs0tj8KLKPnDgXtLwGqFQfGYWHAU9ipgIMYAJ7jMtcgjkvz00ck9EHFeD"
      : "pk_live_51Iyt1aIWA448nFWKmt0rayRnYNu0H5iXqwnMbXOQj29v7GUgO3TjC9WvYIstPMUDmgGiMe1zRud5fB1mChyaSCXW00lI8GTZhK"
  );
  const options = {
    // passing the client secret obtained in step 3
    clientSecret: paymentIntentData?.secret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

export default PaymentEnvironment;
