import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  FormLabel,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import PictureComponent from "../post/commonComponent/PictureComponent";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import AlertPopUp from "../../common/AlertPopUp";
const classes = {
  defaultEdit: {
    padding: "0.4em 1.5em",
    borderRadius: "0.15rem",
    textTransform: "none",
    backgroundColor: "#DB0011",
    color: "#fff",
    ":hover": {
      backgroundColor: "#DB0011",
      color: "#fff",
    },
  },
  editable: {
    padding: "0.4em 1.5em",
    borderRadius: "0.15rem",
    textTransform: "none",
    backgroundColor: "#183243",
    color: "#fff",
    ":hover": {
      backgroundColor: "#183243",
      color: "#fff",
    },
  },
};
const ProfileUpdateForm = ({
  type,
  isEditable,
  handleInputChange,
  profileData,
  handleCameraButtonClick,
  handleRemovePic,
  coverPicRef,
  profilePicRef,
  handleProfileImgInputChange,
  coverImage,
  profileImage,
  countryDrawer,
  handleSave,
  linkedinFieldRef,
  setEditable,
}) => {
  const [isLinkedinPopUpOpen, setLinkedinPopUpOpen] = useState(false);
  const inputRef = useRef();
  // useEffect(() => {
  //   if (!isEditable) {
  //     inputRef.current.focus();
  //   }
  // }, [isEditable]);
  return (
    <Box>
      <AlertPopUp
        title={"LinkedIn profile url"}
        open={isLinkedinPopUpOpen}
        closeModal={setLinkedinPopUpOpen}
        isLinkedinPopUp={true}
      />
      <Box margin={"auto"} mb={"2rem"}>
        <PictureComponent
          handleRemovePic={handleRemovePic}
          profileImage={profileImage}
          coverImage={coverImage}
          profilePicRef={profilePicRef}
          coverPicRef={coverPicRef}
          handleProfileImgInputChange={handleProfileImgInputChange}
          handleCameraButtonClick={handleCameraButtonClick}
        />
      </Box>
      <Box>
        <form onSubmit={handleSave} style={{ width: "100%" }}>
          {type === "Firm" && (
            <Box mb={"0.5rem"}>
              <Box>
                <TextField
                  size="Large"
                  type="text"
                  label={"Organisation Name"}
                  onChange={handleInputChange}
                  variant="standard"
                  value={profileData?.subscriber_firm_name || ""}
                  onFocus={() => setEditable(false)}
                  name="subscriber_firm_name"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  inputRef={inputRef}
                />
                <FormHelperText>Required</FormHelperText>
              </Box>
            </Box>
          )}

          {type === "Individual" && (
            <Box
              mb={"0.5rem"}
              sx={{
                display: "flex",
                gap: "10%",
                justifyContent: "center",
              }}
            >
              <Box width={"45%"}>
                <TextField
                  size="Large"
                  name="first_name"
                  type="text"
                  label={"First Name"}
                  variant="standard"
                  onFocus={() => setEditable(false)}
                  required
                  fullWidth
                  value={profileData?.first_name || ""}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: false,
                  }}
                  inputRef={inputRef}
                />
                <FormHelperText>Required</FormHelperText>
              </Box>

              <Box width={"45%"}>
                <TextField
                  size="Large"
                  type="text"
                  label={"Last Name"}
                  variant="standard"
                  name="last_name"
                  fullWidth
                  onFocus={() => setEditable(false)}
                  value={profileData?.last_name || ""}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: false,
                  }}
                />
                <FormHelperText>Required.</FormHelperText>
              </Box>
            </Box>
          )}

          <Box
            mb={"0.5rem"}
            sx={{
              display: "flex",
              gap: "10%",
              justifyContent: "center",
            }}
          >
            <Box width={"45%"}>
              <TextField
                size="Large"
                name={"city"}
                type="text"
                label={"City"}
                variant="standard"
                required
                onFocus={() => setEditable(false)}
                fullWidth
                value={profileData?.city || ""}
                onChange={handleInputChange}
                InputProps={{
                  readOnly: false,
                }}
              />
              <FormHelperText>Required</FormHelperText>
            </Box>

            <Box width={"45%"}>
              <TextField
                size="small"
                type="text"
                label={"Country"}
                variant="standard"
                name="country"
                onClick={() => !isEditable && countryDrawer(true)}
                // onFocus={() => !isEditable && countryDrawer(true)}
                onFocus={() => setEditable(false)}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowForwardIosOutlined />
                    </InputAdornment>
                  ),
                }}
                required
                fullWidth
                value={profileData?.country || ""}
              />
              <FormHelperText>Required</FormHelperText>
            </Box>
          </Box>

          {type === "Individual" && (
            <Box margin={"auto"} mb={"0.5rem"}>
              {/*Linkdin cond */}
              <Box width={"100%"}>
                <TextField
                  ref={linkedinFieldRef}
                  size="Large"
                  type="url"
                  label={"LinkedIn Profile url..."}
                  value={profileData?.linkedin || ""}
                  onChange={handleInputChange}
                  onFocus={() => setEditable(false)}
                  InputProps={{
                    readOnly: false,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Link
                          onClick={() => setLinkedinPopUpOpen(true)}
                          sx={{ cursor: "pointer" }}
                        >
                          Get linkedIn url
                        </Link>
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  name="linkedin"
                  required
                  fullWidth
                />
                <FormHelperText>Required</FormHelperText>
              </Box>
            </Box>
          )}

          {type === "Firm" && (
            <Box mb={"0.5rem"}>
              {/*Linkdin cond */}
              <Box>
                <TextField
                  size="Large"
                  type="url"
                  label={"LinkedIn Profile url"}
                  onChange={handleInputChange}
                  onFocus={() => setEditable(false)}
                  value={profileData?.linkedin || ""}
                  InputProps={{
                    readOnly: false,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Link
                          onClick={() => setLinkedinPopUpOpen(true)}
                          sx={{ cursor: "pointer" }}
                        >
                          Get linkedIn url
                        </Link>
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  name="linkedin"
                  required
                  fullWidth
                />
                <FormHelperText>
                  Optional, between website or LinkedIn profile at least one is
                  required.
                </FormHelperText>
              </Box>
              <Box>
                <TextField
                  size="Large"
                  type="text"
                  label={"Website"}
                  variant="standard"
                  onFocus={() => setEditable(false)}
                  name="website"
                  //   required={
                  //     requiredInput === "website" || requiredInput === "both"
                  //   }
                  fullWidth
                  value={profileData?.website || ""}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: false,
                  }}
                />
                <FormHelperText>
                  Optional, between website or LinkedIn profile at least one is
                  required.
                </FormHelperText>
              </Box>
            </Box>
          )}
          {/*Profile brif */}
          <Box mb={"0.5rem"}>
            <Box width={"100%"}>
              <Box>
                <FormLabel>Profile Brief</FormLabel>
              </Box>
              <TextField
                onChange={handleInputChange}
                onFocus={() => setEditable(false)}
                variant="outlined"
                fullWidth
                name="profile_brief"
                value={profileData?.profile_brief}
              />
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  {" "}
                  <FormHelperText>
                    Required, Maximum 160 characters
                  </FormHelperText>
                </Box>
                <Box>
                  {" "}
                  <FormHelperText>
                    {profileData?.profile_brief?.length}/{160}
                  </FormHelperText>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box display={"flex"} justifyContent={"center"}>
            <Box textAlign={"center"}>
              <Button
                sx={{
                  pointerEvents: isEditable ? "none" : "auto",
                  backgroundColor: "#DB0011",
                  color: isEditable ? "grey" : "#fff",
                  padding: "0.4em 1.5em",
                  borderRadius: "0.15rem",
                  textTransform: "none",
                  ":hover": {
                    backgroundColor: "#DB0011",
                    color: isEditable ? "grey" : "#fff",
                  },
                }}
                type="submit"
              >
                Save
              </Button>
            </Box>
            {/* <Box ml={"0.5rem"} onClick={() => setEditable(!isEditable)}>
              <Button
                sx={!isEditable ? classes?.editable : classes?.defaultEdit}
              >
                Edit
              </Button>
            </Box> */}
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ProfileUpdateForm;
