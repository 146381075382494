import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import { styled } from "@mui/system";
import {
  FilterIcon,
  StyledSideBarHeader,
  StyledSideBarInviteButton,
  createStyledIndicatorButton,
} from "../../../common/StyledComponents/SideBarComponents";
import Filtericon from "../../../assets/images/task/Filter_icon.svg";
import ExploreVideoFilter from "./ExploreVideoFilter";
import ExplorePhotoFilter from "./ExplorePhotoFilter";
import ExploreArtFilterTab from "./ExploreArtFilterTab";
import ExploreServiceFilterTab from "./ExploreServiceFilterTab";
import ExploreProductFilterTab from "./ExploreProductFilterTab";
const StyledSubHeadingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  // backgroundColor: "#F3F3F3",
  marginBottom: "0.5rem",
});
const classes = {
  chatBox1: {
    minWidth: "280px",
    backgroundColor: "#efefef",
    height: "calc(100vh - 64px)",
    width: "calc(100% - 71.7em)",
    maxWidth: "500px",
    "@media only screen and (max-width:900px)": {
      flex: "1",
    },
  },
  chatBox2: {
    backgroundColor: "#efefef",
    height: "calc(100vh - 64px)",
    minWidth: "250px",
    maxWidth: "350px",

    "@media only screen and (max-width:900px)": {
      flex: "1",
    },
  },
  Tabs: {
    minHeight: "38px",
    width: "100%",
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
      gap: "1rem",
    },
    "& .css-jpln7h-MuiTabs-scroller": {
      overflow: "auto !important",
      "&::-webkit-scrollbar": {
        height: "0px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
      },
    },
    "@media (max-width: 63em)": {
      "& .MuiTabs-flexContainer": {},
    },
  },
  tabs: {
    color: "black",
    padding: "0px 0px",
    textTransform: "none",
    minWidth: "unset",
    minHeight: "2.2rem",
    "&.Mui-selected": {
      color: "black",
    },
    "@media (max-width: 64em)": {
      // marginRight: "2.5rem",
    },
  },
  headingText: {
    color: "black",
    fontSize: "14px",
    fontWeight: "400",
    "@media (max-width: 63em)": {
      fontSize: "0.8rem",
    },
  },

  selectedItem: {
    backgroundColor: "#E1E1E1",
    color: "#000",
  },
  nonselectedItem: {
    color: "#000",
  },
  taskmargin: {
    display: "flex",
    display: "-webkit-box",
    display: "-ms-flexbox",
    display: "-webkit-flex",
    "-webkit-flex-direction": "row",
    "-ms-flex-direction": "row",
    flexDirection: "row",
    "-webkit-box-align": "center",
    "-ms-flex-align": "center",
    alignItems: "center",

    "@media only screen and (max-width:63em)": {
      marginLeft: "0 0px",
    },
  },
  checklabel: {
    marginRight: "0",
  },

  sidebar: {
    padding: "0px 20px 9px 30px",
    height: "calc(100vh - 110px)",
    "@media (max-width: 85.5em)": {
      padding: "4px 8px 4px 8px",
    },
  },
  ckeckboxH: {
    height: "calc(100vh - 15.5rem)",
    overflow: "auto",
    "@media only screen and (min-width: 1600px)": {
      height: "calc(100vh - 18rem)",
    },
  },
  topindication: {
    padding: "0.5rem 0.5rem",
    marginTop: "3px",
    backgroundColor: "#F3F3F3",
    borderRadius: "0.1rem",
    fontSize: "13px !important",
  },
};
const Sidebar = ({
  clearFilter,
  handleFilter,
  filterSwitchLoading,
  setSideBarOpen,
  isSideBarOpen,
  checked,
  setChecked,
}) => {
  const SmallActionButton = createStyledIndicatorButton("#DB0011");
  const [taskApprovalViewValue, setTaskApprovalViewValue] = useState(0);

  const navigate = useNavigate();
  const handleViewChange = (event, newValue) => {
    setChecked([]);
    setTaskApprovalViewValue(newValue);
  };
  const TabPanel = ({ children, value, index }) => (
    <div hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </div>
  );

  return (
    <>
      <Box sx={{ height: "100%" }}>
        <StyledSideBarHeader position={"relative"}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Box display="flex" alignItems="center" gap={"0.5rem"}>
              <SmallActionButton
                onClick={() => setSideBarOpen(!isSideBarOpen)}
                size="small"
                variant="contained"
              >
                <FilterIcon src={Filtericon} />
              </SmallActionButton>
              <Box>
                <Typography sx={classes.headingText} variant="h6">
                  Filter
                </Typography>
              </Box>
            </Box>

            <Box onClick={() => navigate("/post/manage-mypost")}>
              <StyledSideBarInviteButton sx={{ fontWeight: 600 }}>
                My Posts
              </StyledSideBarInviteButton>
            </Box>
          </Box>
        </StyledSideBarHeader>
        <>
          <StyledSubHeadingBox>
            <Box width={"100%"} sx={classes.sidebar}>
              <Tabs
                value={taskApprovalViewValue}
                onChange={handleViewChange}
                TabIndicatorProps={{
                  style: { backgroundColor: "#183243" },
                }}
                sx={classes.Tabs}
              >
                <Tab
                  disableRipple
                  sx={classes.tabs}
                  // label={subHeaderLabels?.chats}
                  label="Videos"
                />
                <Tab
                  disableRipple
                  sx={classes.tabs}
                  // label={subHeaderLabels?.tasks}
                  label="Photos"
                />
                <Tab
                  disableRipple
                  sx={classes.tabs}
                  // label={subHeaderLabels?.tasks}
                  label="Services"
                />
                <Tab
                  disableRipple
                  sx={classes.tabs}
                  // label={subHeaderLabels?.tasks}
                  label="Products"
                />
                <Tab
                  disableRipple
                  sx={classes.tabs}
                  // label={subHeaderLabels?.sellers}
                  label="Art"
                />
              </Tabs>
              <TabPanel value={taskApprovalViewValue} index={0}>
                <ExploreVideoFilter
                  handleFilter={handleFilter}
                  clearFilter={clearFilter}
                  checked={checked}
                  setChecked={setChecked}
                />
              </TabPanel>
              <TabPanel value={taskApprovalViewValue} index={1}>
                <ExplorePhotoFilter
                  handleFilter={handleFilter}
                  clearFilter={clearFilter}
                  checked={checked}
                  setChecked={setChecked}
                />
              </TabPanel>
              <TabPanel value={taskApprovalViewValue} index={2}>
                <ExploreServiceFilterTab
                  handleFilter={handleFilter}
                  clearFilter={clearFilter}
                  checked={checked}
                  setChecked={setChecked}
                />
              </TabPanel>
              <TabPanel value={taskApprovalViewValue} index={3}>
                <ExploreProductFilterTab
                  handleFilter={handleFilter}
                  clearFilter={clearFilter}
                  checked={checked}
                  setChecked={setChecked}
                />
              </TabPanel>
              <TabPanel value={taskApprovalViewValue} index={4}>
                <ExploreArtFilterTab
                  handleFilter={handleFilter}
                  clearFilter={clearFilter}
                  checked={checked}
                  setChecked={setChecked}
                />
              </TabPanel>
            </Box>
          </StyledSubHeadingBox>
        </>
      </Box>
    </>
  );
};

export default Sidebar;
