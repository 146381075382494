import * as appActionTypes from "../../actions/AppState/type";

const initState = {
  pubnub: null,
  selectedGroupData: null,
  selectedTopicData: null,
  selectedTopicToSaveMsg: null,
  showStarMessage: false,
  isAiScreenActive: false,
  isChangeTopicOpen: false,
  isScrollToBottom: true,
  isSwitchAccountRefresh: false,
  isThreadScrollToBottom: null,
  toggleSetPubTokenState: false,
  serviceType:0,
  postEditPreviewData: {},

};

export const appReducer = (state = initState, action) => {
  switch (action.type) {
    case appActionTypes.TOGGLE_UPDATE_PUBNUB_TOKEN:
      return {
        ...state,
        toggleSetPubTokenState: action?.payload,
      };
    case appActionTypes.SET_SCROLL_TO_BOTTOM:
      return {
        ...state,
        isScrollToBottom: action?.payload,
      };
    case appActionTypes.POST_PREVIEW_OR_EDIT_DATA:
      return {
        ...state,
        postEditPreviewData: action?.payload,
      };
    case appActionTypes.SET_THREAD_SCROLL_TO_BOTTOM:
      return {
        ...state,
        isThreadScrollToBottom: action?.payload,
      };
    case appActionTypes.SET_SELECTED_GROUP_DATA:
      return {
        ...state,
        selectedGroupData: action?.payload,
      };
    case appActionTypes.SET_SELECTED_TOPIC_DATA:
      return {
        ...state,
        selectedTopicData: action?.payload,
      };
    case appActionTypes.TOPIC_TO_SAVE_MESSAGE:
      return {
        ...state,
        selectedTopicToSaveMsg: action?.payload,
      };
    case appActionTypes.CHANGE_TOPIC_INITIATED:
      return {
        ...state,
        isChangeTopicOpen: action?.payload,
      };
    case appActionTypes.SET_SHOW_STARMESSAGE_SCREEN:
      return {
        ...state,
        showStarMessage: action?.payload,
      };
    case appActionTypes.SET_SHOW_AI_SCREEN:
      return {
        ...state,
        isAiScreenActive: action?.payload,
      };
      case appActionTypes.SET_SERVICETYPE_POST:
        return {
          ...state,
          serviceType: action?.payload,
        };
    case appActionTypes.SET_SHOW_AI_HISTOPRY_SCREEN:
      return {};
    case appActionTypes.SET_SHOW_STARHISTOPRY_SCREEN:
      return {};

    case appActionTypes.SWITCH_ACCOUNT_SHOULD_REFRESH:
      return {
        ...state,
        isSwitchAccountRefresh: action?.payload,
      };

    default:
      return state;
  }
};
