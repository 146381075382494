import React, { useEffect, useRef, useState } from "react";
import NoTaskListComponent from "./NoTaskListComponent";
import { useSelector } from "react-redux";
import TaskMessage from "./TaskMessage";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../common/LoadingSpinner/LoadingSpinner";

const TaskListComponent = ({
  handleClickTaskUpdate,
  taskTabValue,
  handleViewThreadClick,
  handleApplyTaskFilter,
  filterChecked,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const storeTasklist = useSelector((state) => state?.arkchatgroup?.taskList);
  const isLoading = useSelector((state) => state?.localAppReducer?.isLoading);
  const [isScrollingToBottom, setIsScrollingToBottom] = useState(false);
  const [startTaskOpen, setStartTaskOpen] = useState(null);
  const taskContainerRef = useRef(null);
  let content = appContent?.defaultEmptyTaskMsg;
  if (filterChecked?.length) {
    content = appContent?.emptyTaskFilter;
  } else if (taskTabValue === 4) {
    content = appContent?.emptyTaskArchive;
  }
  const handleStartTask = (item) => {
    if (startTaskOpen?.timetoken === item?.timetoken) {
      setStartTaskOpen(null);
    } else {
      setStartTaskOpen(item);
    }
  };
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = taskContainerRef.current;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 50;
    setIsScrollingToBottom(isAtBottom);
  };
  useEffect(() => {
    if (isScrollingToBottom) {
      const page = Math.floor(storeTasklist?.length / 4) + 1;
      handleApplyTaskFilter(
        filterChecked,
        page,
        storeTasklist[storeTasklist?.length - 1]
      );
    }
  }, [isScrollingToBottom]);

  if (storeTasklist?.length > 0) {
    return (
      <Box
        ref={taskContainerRef}
        onScroll={handleScroll}
        overflow={"auto"}
        height={"calc(100vh - 11.26rem)"}
      >
        {isLoading && <LoadingSpinner />}
        {!isLoading &&
          storeTasklist?.map((item, indx) => {
            return (
              <TaskMessage
                handleViewThreadClick={handleViewThreadClick}
                handleClickTaskUpdate={handleClickTaskUpdate}
                taskTabValue={taskTabValue}
                taskItem={item}
                startTaskOpen={startTaskOpen}
                setStartTaskOpen={setStartTaskOpen}
                handleStartTask={handleStartTask}
                indx={indx}
                key={indx + "tasklistcomp"}
                tabValue={1}
              />
            );
          })}
      </Box>
    );
  } else {
    return (
      <>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <NoTaskListComponent content={content} />
        )}
      </>
    );
  }
};

export default TaskListComponent;
