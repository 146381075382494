import React from "react";

const ApprovalSvg = ({ circleColor = "", tickColor }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.73257 19.636C5.53345 19.636 2.11719 16.2198 2.11719 12.0207C2.11719 7.8215 5.53345 4.40528 9.73257 4.40528C10.5745 4.40435 11.4108 4.54332 12.2072 4.81651C12.3133 4.85298 12.4006 4.93011 12.4499 5.03094C12.4991 5.13176 12.5063 5.24803 12.4698 5.35415C12.4334 5.46028 12.3562 5.54757 12.2554 5.59683C12.1546 5.64608 12.0383 5.65327 11.9322 5.6168C11.2242 5.374 10.4807 5.25053 9.73219 5.25143C5.9996 5.25143 2.96296 8.28811 2.96296 12.0207C2.96296 15.7532 5.9996 18.7899 9.73219 18.7899C13.4648 18.7899 16.5014 15.7532 16.5014 12.0207C16.5015 11.5627 16.4555 11.1058 16.3641 10.657C16.353 10.6026 16.3527 10.5465 16.3632 10.4919C16.3738 10.4374 16.395 10.3854 16.4256 10.3391C16.4563 10.2927 16.4957 10.2528 16.5418 10.2217C16.5878 10.1906 16.6395 10.1688 16.694 10.1577C16.7484 10.1466 16.8045 10.1463 16.8591 10.1568C16.9137 10.1674 16.9656 10.1886 17.012 10.2192C17.0583 10.2499 17.0982 10.2893 17.1293 10.3354C17.1605 10.3814 17.1822 10.4332 17.1933 10.4876C17.2961 10.9921 17.3479 11.5058 17.3478 12.0207C17.348 16.2198 13.9317 19.636 9.73257 19.636Z"
        fill={circleColor ? circleColor : "#183243"}
      />
      <path
        d="M9.59391 16C9.5469 16 9.5007 15.9878 9.45991 15.9647C9.41912 15.9415 9.38514 15.9082 9.36134 15.868C8.46529 14.3546 6.07644 11.1321 6.05244 11.0997C6.01407 11.048 5.99575 10.9843 6.00083 10.9203C6.00592 10.8563 6.03407 10.7962 6.08014 10.7511L6.8147 10.0315C6.85957 9.98751 6.91855 9.96054 6.98139 9.95524C7.04424 9.94994 7.10696 9.96665 7.15867 10.0025L9.53779 11.6493C11.1298 9.62549 12.6086 8.22723 13.5814 7.40216C14.6767 6.47343 15.374 6.05532 15.4032 6.0381C15.4451 6.01317 15.493 6 15.5419 6H16.7303C16.785 6 16.8384 6.01647 16.8834 6.04723C16.9284 6.07799 16.9629 6.12158 16.9824 6.17224C17.0018 6.22289 17.0053 6.27821 16.9923 6.33086C16.9793 6.38351 16.9505 6.431 16.9096 6.46704C13.5349 9.44706 9.8649 15.8011 9.82823 15.865C9.80481 15.9057 9.771 15.9396 9.73017 15.9633C9.68935 15.987 9.64296 15.9997 9.59566 16L9.59391 16Z"
        fill={tickColor ? tickColor : "black"}
      />
    </svg>
  );
};

export default ApprovalSvg;
