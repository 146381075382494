import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Stack, Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAppSelectedTopic,
  handleScrollToBottom,
  handleShowAIScreen,
  handleStarMsgScreen,
} from "../../../appStore/actions/AppState/action";
import {
  fetchOldMessageFromApi,
  handleDeleteGroupMessage,
  handleFetchMessages,
  handleFetchOldMessage,
  handleGetStarMessages,
  handleStarMessage,
} from "../../../appStore/actions/ArkchatGroup/action";
import { groupMsgDeleteType } from "../../../utils/constant";
import closeIcon from "../../../assets/images/chats/closeLogo.svg";
import TempMessageView from "./TempMessageView";
import MessageThread from "./MessageThread";
import {
  ActionButton,
  CustomIcon,
  SmallIcon,
} from "../../../common/chatComponents/ScreenSearchSection";
import aIIcon from "../../../assets/images/chats/aIIcon.svg";
import vectorIcon from "../../../assets/images/chats/VectorIcon.svg";
import { findUser } from "../../../utils/helper";
import ProfileDetailModal from "../../../common/ProfileDetailModal";
import GroupInfoHeader from "../Tabs/GroupInfoHeader";

const MessageThreadList = ({
  messageList,
  setMediaFilesOpen,
  setReplyParentMsg,
  handlegetThreadMessages,
  handleBacktoMessage,
  setMoveTopicTriggered,
  setMoveTopicMsgId,
  setisTopicOpen,
  handleReadStatusDrawer,
  handleSendTempMsg,
  handleClickTaskUpdate,
  progress,
}) => {
  const userData = useSelector((state) => state?.profile);
  const tempMsgList = useSelector(
    (state) => state?.arkchatgroup?.tempMessageList
  );
  const isThreadScreen = useSelector(
    (state) => state?.localAppReducer?.isThreadScreen
  );
  const selectedTopic = useSelector(
    (state) => state?.appStateData?.selectedTopicData
  );
  const selectedGroup = useSelector(
    (state) => state?.appStateData?.selectedGroupData
  );
  const groupmemberdetails = useSelector(
    (state) => state?.arkchatgroup?.groupmember
  );
  const showStarMessage = useSelector(
    (state) => state?.appStateData?.showStarMessage
  );
  const isScrollToBottom = useSelector(
    (state) => state?.appStateData?.isScrollToBottom
  );
  const isThreadScrollToBottom = useSelector(
    (state) => state?.appStateData?.isThreadScrollToBottom
  );
  const starMsgData = useSelector((state) => state?.arkchatgroup?.starMsgData);
  const threadMsgList = useSelector(
    (state) => state?.arkchatgroup?.threadMsgList
  );
  const threadContainerRef = useRef(null);
  const menuRef = useRef(null);
  const threeDotRef = useRef(null);
  const [isMoreMenuOpen, setMoreMenu] = useState(false);
  const [isDeleteGroupMsgOpen, setDeleteGroupMsgOpen] = useState(false);
  const [isDeleteGroupMsgOpenIndicatorId, setDeleteGroupMsgOpenIndicatorId] =
    useState(null);
  const [deleteGrpMsgType, setDeleteGrpMsgType] = useState("");
  const [isScrollingToTop, setIsScrollingToTop] = useState(false);
  const [localStarMsgData, setLocalStarMsgData] = useState([]);
  const [highlightedMessageId, setHighlightedMessageId] = useState(null);
  const [profileViewData, setProfileViewData] = useState("");
  const [isProfileViewOpen, setProfileViewModal] = useState(false);
  const [startTaskOpen, setStartTaskOpen] = useState(null);
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(handleAppSelectedTopic(null));
    dispatch(handleFetchMessages(selectedGroup?.pnId, null));
  };

  ////////
  const scrollToBottom = () => {
    if (threadContainerRef?.current && isThreadScreen&&
      isThreadScrollToBottom) {
      threadContainerRef.current.scrollTop =
        threadContainerRef.current.scrollHeight;
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMoreMenu(null);
    }
  };
  const toggleItemMenu = (item) => {
    if (isMoreMenuOpen?.timetoken === item?.timetoken) {
      setMoreMenu(null);
    } else {
      setMoreMenu(item);
    }
  };

  const handleDeleteGrpMsg = (msgData) => {
    if (deleteGrpMsgType === groupMsgDeleteType?.me) {
      dispatch(handleDeleteGroupMessage(msgData, false)).then((res) => {});
      setDeleteGrpMsgType("");
      setDeleteGroupMsgOpen(false);
      setDeleteGroupMsgOpenIndicatorId(null);
    } else if (deleteGrpMsgType === groupMsgDeleteType?.all) {
      dispatch(handleDeleteGroupMessage(msgData, true)).then((res) => {});
      setDeleteGrpMsgType("");
      setDeleteGroupMsgOpen(false);
      setDeleteGroupMsgOpenIndicatorId(null);
    }
  };
  const handleStarUnstarMsg = (timetoken, isalreadyStared) => {
    const reqBoby = {
      pn_id: selectedGroup?.pnId,
      timetoken: timetoken,
    };
    dispatch(
      handleStarMessage(
        reqBoby,
        selectedGroup?.pnId,
        selectedTopic,
        messageList,
        isThreadScreen
      )
    ).then((res) => {
      if (res?.status) {
        if (localStarMsgData?.includes(reqBoby?.timetoken)) {
          const updatedData = localStarMsgData?.filter(
            (item) => item !== reqBoby?.timetoken
          );
          setLocalStarMsgData(updatedData);
        } else if (
          !isalreadyStared?.includes(userData?.profileData?.data?.id)
        ) {
          setLocalStarMsgData([...localStarMsgData, reqBoby?.timetoken]);
        }

        setMoreMenu(null);
        dispatch(handleGetStarMessages(selectedGroup?.pnId));
      }
    });
  };
  const handleAiBtnCLick = () => {
    if (selectedGroup) {
      dispatch(handleShowAIScreen(true));
    }
  };

  const handleStartTask = (item) => {
    if (startTaskOpen?.timetoken === item?.timetoken) {
      setStartTaskOpen(null);
    } else {
      setStartTaskOpen(item);
    }
  };

  const handleRemoveStarScreen = () => {
    dispatch(handleStarMsgScreen(!showStarMessage));
    dispatch(handleFetchMessages(selectedGroup?.pnId, selectedTopic?.id));
    dispatch(handleScrollToBottom(true));
  };

  const scrollToRepliedMessage = (messageId) => {
    setHighlightedMessageId(messageId);
    setTimeout(() => {
      setHighlightedMessageId(null);
    }, 2000);

    const repliedMessageElement = document.getElementById(
      `replied-message-${messageId}`
    );
    if (repliedMessageElement) {
      repliedMessageElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), delay);
    };
  };
  ////
  const handleScroll = debounce(() => {
    const activeChannel = selectedGroup?.pnId;

    if (threadContainerRef?.current) {
      const { scrollTop } = threadContainerRef.current;
      let shouldConsiderScrollTop = false;

      if (messageList?.length > 0) {
        shouldConsiderScrollTop = messageList[0]?.pnId === activeChannel;
      }

      const isNearTop =
        scrollTop <= 201 && shouldConsiderScrollTop && messageList?.length > 20;

      // Only update state if necessary
      if (isScrollingToTop !== isNearTop) {
        setIsScrollingToTop(isNearTop);
      }
    }
  }, 100);
  /////
  useEffect(() => {
    if (isScrollingToTop) {
      dispatch(handleScrollToBottom(false));

      const container = threadContainerRef.current;
      if (container) {
        // Save the current scroll position relative to the bottom
        const currentScrollPosition =
          container.scrollHeight - container.scrollTop;

        if (showStarMessage) {
          const page = Math.floor(starMsgData?.messages?.length / 50) + 1;
          dispatch(handleGetStarMessages(selectedGroup?.pnId, page)).then(
            () => {
              setTimeout(() => {
                container.scrollTop =
                  container.scrollHeight - currentScrollPosition;
              }, 0);
            }
          );
        }
      }
    }
  }, [isScrollingToTop]);

  /////
  useEffect(() => {
    if (isScrollToBottom || isThreadScrollToBottom) {
      scrollToBottom();
    }
  }, [messageList,tempMsgList]);

  useEffect(() => {
    if (selectedGroup?.isGroup) {
      dispatch(handleGetStarMessages(selectedGroup?.pnId));
    }
  }, [dispatch, selectedGroup]);
  const handleShowUserProfile = (userToFindData) => {
    const data = findUser(groupmemberdetails, userToFindData);
    const viewData = {
      companyName: data?.userData?.subscriberFirmName,
      userName: `${data?.userInfo?.firstName} ${data?.userInfo?.lastName}`,
      indusrtyName: data?.userData?.subscriberAccountIndustry,
      cityName: data?.userData?.city,
      countryName: data?.userData?.country,
      profileBrief: data?.userInfo?.profileBrief,
      profileImgUrl: data?.userInfo?.profileImgUrl,
      coverImgUrl: data?.userInfo?.coverImgUrl,
      isGroup: false,
      website: data?.userInfo?.website,
      linkedin: data?.userInfo?.linkedin,
    };
    setProfileViewData(viewData);
    setProfileViewModal(true);
  };
  return (
    <>
      <Box display={"flex"} marginTop="8px">
        <ProfileDetailModal
          setProfileViewModal={setProfileViewModal}
          isProfileViewOpen={isProfileViewOpen}
          profileViewData={profileViewData}
          setProfileViewData={setProfileViewData}
        />
        {isThreadScreen && (
          <Box mr={"0.4rem"}>
            <ActionButton
              onClick={handleBacktoMessage}
              size="small"
              variant="contained"
            >
              <CustomIcon sx={{ width: "70%" }} src={closeIcon} />
            </ActionButton>
          </Box>
        )}
        <GroupInfoHeader
          setisTopicOpen={setisTopicOpen}
          setMediaFilesOpen={setMediaFilesOpen}
        />
        {isThreadScreen && (
          <Box display={"flex"} gap={"0.4rem"} ml={"0.4rem"}>
            <Box>
              <ActionButton
                onClick={handleAiBtnCLick}
                size="small"
                variant="contained"
              >
                <CustomIcon src={aIIcon} />
              </ActionButton>
            </Box>
            <Box>
              <ActionButton
                sx={{ backgroundColor: showStarMessage ? "grey" : "" }}
                onClick={() =>
                  selectedGroup &&
                  dispatch(handleStarMsgScreen(!showStarMessage))
                }
                size="small"
                variant="contained"
              >
                <SmallIcon src={vectorIcon} />
              </ActionButton>
            </Box>
          </Box>
        )}
      </Box>

      <Box borderBottom={"1px solid"}>
        {threadMsgList?.length > 0 && (
          <MessageThread
            item={threadMsgList[0]}
            key={threadMsgList[0]?.temp_timetoken + "ThreadmessageViewList"}
            isMoreMenuOpen={isMoreMenuOpen}
            startTaskOpen={startTaskOpen}
            setStartTaskOpen={setStartTaskOpen}
            handleStartTask={handleStartTask}
            threeDotRef={threeDotRef}
            userData={userData}
            isDeleteGroupMsgOpenIndicatorId={isDeleteGroupMsgOpenIndicatorId}
            handleClickTaskUpdate={handleClickTaskUpdate}
            isDeleteGroupMsgOpen={isDeleteGroupMsgOpen}
            handleReadStatusDrawer={handleReadStatusDrawer}
            setisTopicOpen={setisTopicOpen}
            setMoveTopicMsgId={setMoveTopicMsgId}
            setMoveTopicTriggered={setMoveTopicTriggered}
            setDeleteGroupMsgOpenIndicatorId={setDeleteGroupMsgOpenIndicatorId}
            setDeleteGroupMsgOpen={setDeleteGroupMsgOpen}
            handlegetThreadMessages={handlegetThreadMessages}
            setMoreMenu={setMoreMenu}
            menuRef={menuRef}
            isThreadScreen={isThreadScreen}
            selectedGroup={selectedGroup}
            toggleItemMenu={toggleItemMenu}
            handleStarUnstarMsg={handleStarUnstarMsg}
            handleDeleteGrpMsg={handleDeleteGrpMsg}
            setDeleteGrpMsgType={setDeleteGrpMsgType}
            localStarMsgData={localStarMsgData}
            handleShowUserProfile={handleShowUserProfile}
            bgcolor={
              highlightedMessageId === threadMsgList[0]?.temp_timetoken
                ? "#F9F8F8"
                : "transparent"
            }
            scrollToRepliedMessage={scrollToRepliedMessage}
            hideBottonLine={true}
          />
        )}
      </Box>

      <Box
        maxWidth={"100%"}
        sx={{
          position: "relative",
          height: "100%",
          overflow: "auto",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "3px",
          },
          "@media (max-width: 1194px)": {
            height: "69%",
          },
        }}

        ref={threadContainerRef}
        onScroll={handleScroll}
      >
        {threadMsgList?.length > 0 &&
          threadMsgList?.map(
            (item, i) =>
              i !== 0 && (
                <MessageThread
                  item={item}
                  key={item?.temp_timetoken + "ThreadmessageViewList"}
                  isMoreMenuOpen={isMoreMenuOpen}
                  startTaskOpen={startTaskOpen}
                  setStartTaskOpen={setStartTaskOpen}
                  handleStartTask={handleStartTask}
                  threeDotRef={threeDotRef}
                  userData={userData}
                  isDeleteGroupMsgOpenIndicatorId={
                    isDeleteGroupMsgOpenIndicatorId
                  }
                  handleClickTaskUpdate={handleClickTaskUpdate}
                  isDeleteGroupMsgOpen={isDeleteGroupMsgOpen}
                  handleReadStatusDrawer={handleReadStatusDrawer}
                  setisTopicOpen={setisTopicOpen}
                  setMoveTopicMsgId={setMoveTopicMsgId}
                  setMoveTopicTriggered={setMoveTopicTriggered}
                  setDeleteGroupMsgOpenIndicatorId={
                    setDeleteGroupMsgOpenIndicatorId
                  }
                  setDeleteGroupMsgOpen={setDeleteGroupMsgOpen}
                  handlegetThreadMessages={handlegetThreadMessages}
                  setMoreMenu={setMoreMenu}
                  menuRef={menuRef}
                  isThreadScreen={isThreadScreen}
                  selectedGroup={selectedGroup}
                  toggleItemMenu={toggleItemMenu}
                  handleStarUnstarMsg={handleStarUnstarMsg}
                  handleDeleteGrpMsg={handleDeleteGrpMsg}
                  setDeleteGrpMsgType={setDeleteGrpMsgType}
                  localStarMsgData={localStarMsgData}
                  handleShowUserProfile={handleShowUserProfile}
                  bgcolor={
                    highlightedMessageId === item?.temp_timetoken
                      ? "#F9F8F8"
                      : "transparent"
                  }
                  scrollToRepliedMessage={scrollToRepliedMessage}
                />
              )
          )}

        {tempMsgList?.length > 0 &&
          selectedGroup?.isGroup &&
          tempMsgList?.map((item, i) => (
            <TempMessageView
              selectedGroup={selectedGroup}
              item={item}
              key={item?.temp_timetoken + "tempMsg"}
              threeDotRef={threeDotRef}
              userData={userData}
              handleSendTempMsg={handleSendTempMsg}
              progress={progress}
            />
          ))}
      </Box>
    </>
  );
};

export default MessageThreadList;
