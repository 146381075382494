import React from "react";
import { Box, Toolbar } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const StyledToolBar = styled(Toolbar)({
  display: "flex",
  alignItems: "center",
  padding: "0 !important",
  gap: "0.499rem",
});

export const StyledHeaderLogo = styled("img")({
  width:"40%",
});

export const StyledNavigationBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "6%",
  paddingLeft: "0.8rem",
  width: "74%",
});

export const StyledNavLink = styled(NavLink)({
  textDecoration: "none",
  color: "#000000",
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "0.87rem",
  "&.active": {
    color: "#000",
    fontWeight: "600",
  },
});
export const StyledProfileBox = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const HeaderComponents = () => {
  return <div></div>;
};

export default HeaderComponents;
