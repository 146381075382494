import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { REMOVE_SET_CURRENT_USER_LOGEDIN } from "./appStore/actions/auth/login";
import { getCookie } from "./config/cookie";

const PrivateRoute = ({ children }) => {
  const token = getCookie("token");
  const shouldRedirect = useSelector((state) => state?.profile?.shouldRedirect);
  let location = useLocation();
  const dispatch = useDispatch();
  if (!token || shouldRedirect) {
    if (shouldRedirect) {
      dispatch({ type: REMOVE_SET_CURRENT_USER_LOGEDIN });
    }
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};

export default PrivateRoute;
