import axios from "axios";
import {
  PROFILE_API_URL,
  PROFILE_DONE,
  PROFILE_DONE_ERROR,
  UPDATE_PROFILE_DONE,
  UPDATE_PROFILE_DONE_ERROR,
} from ".";
import { REQUEST_FAILED } from "../global/type";
import { getCookie } from "../../../config/cookie";
import { getProfileData, storeProfileData } from "../../../config/db";
import { showToastMsg } from "../global/action";

const updateProfileDb = async () => {
  try {
    let API_URL = `${PROFILE_API_URL}/user/profile`;
    const token = getCookie("token");
    const subscriber_id = getCookie("subsCriber_id");
    const response = await axios.get(API_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      // Update the profile data in the database
      await storeProfileData(subscriber_id, response.data.data);
      return response?.data?.data;
    } else {
      return false;
    }
  } catch (error) {
    // Handle background API call errors if needed
    return false;
  }
};

export const getProfile = (forceFetch = false) => {
  let API_URL = `${PROFILE_API_URL}/user/profile`;
  const token = getCookie("token");
  return async (dispatch) => {
    try {
      // Check if profile data is already present in the database
      const subscriber_id = getCookie("subsCriber_id") || false;
      let profileData;
      if (subscriber_id) {
        profileData = await getProfileData(subscriber_id);
      }
      if (profileData?.length > 0 && !forceFetch && subscriber_id) {
        // If data is present, dispatch it
        dispatch({
          type: PROFILE_DONE,
          payload: profileData[0]?.data,
        });

        updateProfileDb().then((updatedData) => {
          // Dispatch the updated data to refresh the UI
          if (updatedData) {
            dispatch({
              type: PROFILE_DONE,
              payload: updatedData,
            });
          } else {
            dispatch({
              type: PROFILE_DONE,
              payload: profileData[0]?.data,
            });
          }
        });

        return profileData[0]?.data;
      } else {
        // If data is not present, make an API call
        const response = await axios.get(API_URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          // Dispatch the profile data
          await storeProfileData(subscriber_id, response.data.data);
          dispatch({
            type: PROFILE_DONE,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: PROFILE_DONE_ERROR,
            payload: response,
          });
        }
      }
    } catch (error) {
      dispatch({ type: REQUEST_FAILED, payload: error?.response });
    }
  };
};

//   let API_URL = `${PROFILE_API_URL}/user/profile`;
//   const token = getCookie("token");

//   return async (dispatch) => {
//     try {
//       // Check if profile data is already present in the database
//       const profileData = await getProfileData("21");

//       if (profileData.length > 0) {
//         // If data is present, dispatch it
//         dispatch({
//           type: "UPDATE_PROFILE_DONE",
//           payload: profileData,
//         });
//       } else {
//         // If data is not present, make an API call
//         const response = await axios.put(API_URL, formData, {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         if (response.status === 200) {
//           // Dispatch the updated profile data
//           dispatch({
//             type: "UPDATE_PROFILE_DONE",
//             payload: response.data,
//           });

//           // Store the updated profile data in the database
//           await storeProfileData("90", response.data);
//         } else {
//           dispatch({
//             type: "UPDATE_PROFILE_DONE_ERROR",
//             payload: response,
//           });
//         }
//       }
//     } catch (error) {
//       dispatch({ type: "REQUEST_FAILED", payload: error?.response });
//     }
//   };
// };

export const profileUpdate = (formData, showToast = true) => {
  let API_URL = `${PROFILE_API_URL}/user/profile`;
  const subscriber_id = getCookie("subsCriber_id") || false;
  const token = getCookie("token");
  return async (dispatch) => {
    return axios
      .put(API_URL, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_PROFILE_DONE,
            payload: res?.data?.data,
          });
          if (subscriber_id) {
            await storeProfileData(subscriber_id, res?.data?.data);
          }
          if (!("time_zone" in formData) && showToast) {
            dispatch(showToastMsg(res?.data?.message));
          }
          return res?.data;
        } else {
          dispatch({
            type: UPDATE_PROFILE_DONE_ERROR,
            payload: res?.data?.data,
          });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
        //
      });
  };
};

export const updateProfilePic = (formData) => {
  let API_URL = `${PROFILE_API_URL}/user/image`;
  const subscriber_id = getCookie("subsCriber_id") || false;
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .post(API_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        if (res.status === 200) {
          if (subscriber_id) {
            await storeProfileData(subscriber_id, res?.data?.data);
          }
          dispatch(showToastMsg(res?.data?.message));
          return res?.data;
        } else {
          dispatch(showToastMsg(res?.data?.message));
          // dispatch({
          //   type: UPDATE_PROFILE_DONE_ERROR,
          //   payload: res,
          // });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
        //
      });
  };
};

export const updateFirmPic = (formData, subscriber_id) => {
  let API_URL = `${PROFILE_API_URL}/subscriber/image/${subscriber_id}`;
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .post(API_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // dispatch({
          //   type: UPDATE_PROFILE_DONE,
          //   payload: res,
          // });
          return res?.data;
        } else {
          // dispatch({
          //   type: UPDATE_PROFILE_DONE_ERROR,
          //   payload: res,
          // });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
        //
      });
  };
};

export const editSubscriberProfile = (reqBody, subscriber_id, showToast) => {
  return (dispatch) => {
    let API_URL = `${PROFILE_API_URL}/subscriber/edit/${subscriber_id}`;
    const token = getCookie("token");

    return axios
      .post(API_URL, reqBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(getProfile());
        if (showToast) {
          dispatch(showToastMsg(res?.data?.message));
        }
        return res?.data;
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};

export const handleSaveDeviceToken = (formData) => {
  let API_URL = `https://dev-api01.arkchat.com/v1/notifications/updateDevice`;
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .post(API_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return res?.data;
        } else {
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
        //
      });
  };
};

//https://dev-api01.arkchat.com/v1/onboarding/subscriber/edit/317

export const deleteAccount = (reqBody) => {
  return (dispatch) => {
    let API_URL = `${PROFILE_API_URL}/user/delete-account`;
    const token = getCookie("token");

    return axios
      .post(API_URL, reqBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};
