import React, { useEffect, useState } from "react";
import ContactHeader from "../../components/contact/ContactHeader";
import { Box, Stack } from "@mui/material";
import InternalContact from "../../components/contact/InternalContact";
import ExternalContact from "../../components/contact/ExternalContact";
import { useDispatch } from "react-redux";
import { getInviteUrl } from "../../appStore/actions/subscriber/action";
import { useTranslation } from "react-i18next";
import HelperScreen from "../../common/ToolTip/HelperScreen";
import { handleCopyClick } from "../../utils/copytoClipboard";
import { showToastMsg } from "../../appStore/actions/global/action";
import { requestGetAllAcountLevelMember } from "../../appStore/actions/ArkchatGroup/action";
import useAppSelectors from "../../customHooks/useAppSelectors";
import {
  AccountLevelArkchatPermission,
  InviteType,
} from "../../utils/constant";

const Contact = () => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const { accountLevelPeople, currentUserData } = useAppSelectors();
  const [showPromt, setShowPromt] = useState(true);
  const [internalInviteLink, setInternalInviteLink] = useState("");
  const [externalInviteLink, setExternalInviteLink] = useState("");
  const dispatch = useDispatch();
  const handleGetInviteLink = async (type) => {
    const res = await dispatch(getInviteUrl(type));
    const inviteMessage = res?.data?.inviteMessage;

    if (type === InviteType.internal) {
      setInternalInviteLink(inviteMessage);
    } else if (type === InviteType.external) {
      setExternalInviteLink(inviteMessage);
    }
  };

  const handleCopyInvite = (type) => {
    if (internalInviteLink || externalInviteLink) {
      if (type === InviteType?.external) {
        handleCopyClick(externalInviteLink, handleCopyClickCallback);
      } else if (type === InviteType?.internal) {
        handleCopyClick(internalInviteLink, handleCopyClickCallback);
      }
    } else {
      dispatch(showToastMsg(appContent?.wrongError));
    }
  };

  const handleCopyClickCallback = (type) => {
    if (type === "error") {
      dispatch(showToastMsg(appContent?.wrongError));
    } else {
      dispatch(showToastMsg(appContent?.copiedClipboard));
    }
  };
  const fetchInviteLinks = async () => {
    if (
      currentUserData?.activePermission !== AccountLevelArkchatPermission?.USER
    ) {
      await handleGetInviteLink(InviteType.internal);
    }
    await handleGetInviteLink(InviteType.external);
  };

  useEffect(() => {
    dispatch(requestGetAllAcountLevelMember());
    fetchInviteLinks();
  }, []);
  return (
    <div>
      {!currentUserData?.tooltipContactsScreeenWeb && (
        <HelperScreen
          isContact={true}
          updateKey={"tooltip_contacts_screeen_web"}
          heading1={appContent?.internalContact}
          heading2={appContent?.externalContact}
          content={[
            appContent?.contactContent1,
            appContent?.contactContent2,
            appContent?.contactContent3,
          ]}
          content2={[
            appContent?.contactContent4,
            appContent?.contactContent5,
            appContent?.contactContent6,
          ]}
        />
      )}
      <ContactHeader showPromt={showPromt} setShowPromt={setShowPromt} />
      <Box>
        <Stack direction="row">
          <Box width={"50%"} borderRight={"1px solid black"}>
            <InternalContact
              currentUserData={currentUserData}
              accountLevelPeople={accountLevelPeople}
              handleCopyInvite={handleCopyInvite}
              showPromt={showPromt}
            />
          </Box>
          <Box width={"50%"}>
            <ExternalContact
              currentUserData={currentUserData}
              accountLevelPeople={accountLevelPeople}
              handleCopyInvite={handleCopyInvite}
              showPromt={showPromt}
            />
          </Box>
        </Stack>
      </Box>
    </div>
  );
};

export default Contact;
