import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import aIbadgeIcon from "../../../assets/images/chats/aIbadgeIcon.svg";
import {
  IndicatorIcon,
  createStyledIndicatorButton,
} from "../../../common/StyledComponents/SideBarComponents";
import {
  handleDeleteAiHistory,
  handleGetAiHistory,
  handleUseHistoryQuery,
} from "../../../appStore/actions/ArkchatGroup/action";
import { useTranslation } from "react-i18next";
const AIAnswerBox = ({
  answerData,
  queried_by,
  date,
  history_id,
  screenType,
  historyData,
  setAiQueryHistory,
  setShareAiText,
  setNewMessages,
  setSelectedAnswer,
  selecteAnswer,
  setEditModalOpen,
  handleOpenEditModal
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const BadgeButton = createStyledIndicatorButton("#F3F3F3");
  const dispatch = useDispatch();
  const handleDeleteHistory = (history_id) => {
    dispatch(handleDeleteAiHistory(history_id)).then((res) => {
      if (res?.status) {
        dispatch(handleGetAiHistory());
      }
    });
  };
  const handleUseIt = () => {
    dispatch(handleUseHistoryQuery(historyData));
    setAiQueryHistory(false);
  };

  const handleShare = () => {
    if (selecteAnswer === null || selecteAnswer !== history_id) {
      setSelectedAnswer(history_id);
      setNewMessages(answerData);
      setShareAiText(true);
    } else if (selecteAnswer === history_id) {
      setSelectedAnswer(null);
      setNewMessages("");
      setShareAiText(false);
    }
  };
  return (
    <Box padding="8px 0px">
      <Box display={"flex"} maxWidth={"90%"}>
        <Box mr={"0.5rem"}>
          <BadgeButton size="small" variant="contained">
            <IndicatorIcon src={aIbadgeIcon} />
          </BadgeButton>
        </Box>

        <Box width={"100%"}>
          <Typography variant="subtitle2" fontWeight={400} color={"#183242"}>
            {answerData?.split(/\n/).map((line, indx) => (
              <span
                key={line + indx}
                style={{ marginBottom: "0.5rem", display: "block" }}
              >
                {line}
              </span>
            ))}
          </Typography>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"baseline"}
            width={"100%"}
          >
            {screenType === "aiHistory" && (
              <Box mt={"1.5rem"}>
                <Typography>
                  {queried_by} | {date}
                </Typography>
              </Box>
            )}

            {screenType === "aiSession" && (
              <Box>
                <Button
                  onClick={() => handleOpenEditModal(historyData)}
                  variant="outlined"
                  sx={{
                    mr: "0.5rem",
                    border: "1.23px solid #183242",
                    color: "#183242",
                    minHeight: "unset",
                    height: "1.7rem",
                    textTransform: "none",
                    "&:hover": {
                      border: "1.23px solid #183242",
                      color: "#183242",
                    },
                  }}
                >
                  {appContent?.edit}
                </Button>
                <Button
                  onClick={handleShare}
                  variant="outlined"
                  sx={{
                    border:
                      selecteAnswer === history_id
                        ? "1.23px solid #183243"
                        : "1.23px solid #183242",
                    background:
                      selecteAnswer === history_id ? "#183243" : "#fff",
                    color: selecteAnswer === history_id ? "#fff" : "#183242",
                    minHeight: "unset",
                    height: "1.7rem",
                    textTransform: "none",
                    "&:hover": {
                      border:
                        selecteAnswer === history_id
                          ? "1.23px solid #183243"
                          : "1.23px solid #183242",
                      background:
                        selecteAnswer === history_id ? "#183243" : "#fff",
                      color: selecteAnswer === history_id ? "#fff" : "#183242",
                    },
                  }}
                >
                  {appContent?.share}
                </Button>
              </Box>
            )}

            {screenType === "aiHistory" && (
              <Box>
                <Button
                  onClick={() => handleDeleteHistory(history_id)}
                  variant="outlined"
                  sx={{
                    mr: "0.5rem",
                    border: "1.23px solid #183242",
                    color: "#183242",
                    minHeight: "unset",
                    height: "1.7rem",
                    textTransform: "none",
                    "&:hover": {
                      border: "1.23px solid #183242",
                      color: "#183242",
                    },
                  }}
                >
                  {appContent?.delete}
                </Button>
                <Button
                  onClick={handleUseIt}
                  variant="outlined"
                  sx={{
                    border: "1.23px solid #183242",
                    color: "#183242",
                    minHeight: "unset",
                    height: "1.7rem",
                    textTransform: "none",
                    "&:hover": {
                      border: "1.23px solid #183242",
                      color: "#183242",
                    },
                  }}
                >
                  {appContent?.useit}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AIAnswerBox;
