import { Box } from "@mui/material";
import React from "react";
import smallCloseIcon from "../../../assets/images/post/closeIcon.svg";
const SelectVideos = ({ videos, cancleSelectedVideo }) => {
  return (
    <>
      
        <Box display={"flex"} flexWrap={"wrap"}>
          <Box
            width={"100%"}
            height={"11.4rem"}
            maxHeight={"11.4rem"}
            // ml={index === 0 || index === 3 ? "none" : "0.4rem"}
            mb={"0.4rem"}
            borderRadius={"4px"}
            bgcolor={"#F3F3F3"}
            overflow={"hidden"}
            position={"relative"}
          >
            {videos?.length > 0 && (
              <Box>
                <iframe
                  title={videos[0]?.title}
                  style={{ width: "100%", height: "100%" }}
                  allowFullScreen="allow"
                  src={`https://www.youtube.com/embed/${videos[0]?.videoid}`}
                />
              </Box>
            )}
            {videos?.length > 0 && (
              <Box
                sx={{ cursor: "pointer" }}
                zIndex={100}
                position={"absolute"}
                top={"0.2rem"}
                right={"0.2rem"}
                onClick={() => cancleSelectedVideo(videos[0])}
              >
                <img src={smallCloseIcon} alt="close" />
              </Box>
            )}
          </Box>
        </Box>
    </>
  );
};

export default SelectVideos;
