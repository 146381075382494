import { Box } from "@mui/material";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import arkChatLogo from "../assets/images/arkChatLogo.svg";

const IntroVideo = ({height="calc(100vh - 32px)"}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <>
      {/*  */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        position={"relative"}
        height={height}
      >
        <Box width={"30%"}>
          <img src={arkChatLogo} alt="arkChat" />
        </Box>

        <Box width={"100%"} position={"absolute"} top={"25%"}>
          <Box
            onClick={() => (isPlaying ? setIsPlaying(false) : "")}
            height={"21.8rem"}
            width={"100%"}
            position={"relative"}
            sx={{ cursor: isPlaying ? "pointer" : "" }}
          >
            <ReactPlayer
              width="100%"
              height="95%"
              url={"https://youtu.be/iUklpCZAdNI?si=65f5vRPH06a5dQzf"}
              controls={false}
              volume={null}
              muted={false}
              onEnded={() => setIsPlaying(false)}
              playing={isPlaying}
              pip={false}
              config={{
                file: {
                  attributes: {
                    controlsList:
                      "nodownload disablepictureinpicture noplaybackrate",
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IntroVideo;
