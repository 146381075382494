import { Box, Button, Stack, styled, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import searchIcon from "../../assets/images/chats/gallery_active.svg";
import aIIcon from "../../assets/images/chats/aIIcon.svg";
import vectorIcon from "../../assets/images/chats/VectorIcon.svg";
import folderIcon from "../../assets/images/chats/FolderIcon.svg";
import historyIcon from "../../assets/images/chats/historyIcon.svg";
import white_back_arrow from "../../assets/images/chats/white_back_arrow.svg";
import { clearCurrentAiSessionData } from "../../appStore/actions/ArkchatGroup/action";
import addmember_icon from "../../assets/images/chats/addmember_icon.svg";
import {
  handleScrollToBottom,
  handleShowAIScreen,
  handleStarMsgScreen,
} from "../../appStore/actions/AppState/action";
import { useTranslation } from "react-i18next";
import LableToolTip from "../LableToolTip";
import useAppSelectors from "../../customHooks/useAppSelectors";
import GroupInfoHeader from "../../page/chat/Tabs/GroupInfoHeader";
export const SearchBox = styled("div")({
  display: "flex",
  alignItems: "center",
  padding: "0.13rem 0.3rem",
  border: "1px solid rgb(24 50 66 / 50%)",
  justifyContent: "space-between",
  backgroundColor: "#fff",
  borderRadius: "3px",
});

export const CustomInput = styled("input")({
  // width: "90%",
  width: "calc(100% - 30px)",
  border: "none",
  outline: "none",
  "&:focus": {
    border: "none",
  },
});

export const SearchButton = styled(Button)({
  backgroundColor: "#DB0011",
  color: "#fff",
  borderRadius: "50%",
  width: "1.5rem",
  height: "1.5rem",
  minWidth: "unset",
  minHeight: "unset",
  padding: 0,
  "&:hover": {
    backgroundColor: "#DB0011",
    color: "#fff",
  },
});
export const ChatSearchButton = styled(Button)({
  // backgroundColor: "#DB0011",
  backgroundColor: "transparent",
  color: "#fff",
  borderRadius: "50%",
  width: "23px",
  height: "23px",
  margin: "1px",
  minWidth: "unset",
  minHeight: "unset",
  boxShadow: "none",

  "&:hover": {
    // backgroundColor: "#DB0011",
    backgroundColor: "transparent",
    boxShadow: "none",
    color: "#fff",
  },
});

export const SmallIcon = styled("img")({
  width: "1rem",
  height: "1rem",
});
export const CustomIcon = styled("img")({
  width: "1.4rem",
  height: "1.4rem",
});

export const ActionButton = styled(Button)({
  backgroundColor: "#DB0011",
  padding: "4px",
  color: "#fff",
  width: "30px",
  height: "30px",
  minWidth: "unset",
  minHeight: "unset",
  borderRadius: "0.15rem",
  "&:hover": {
    backgroundColor: "#DB0011",
    color: "#fff",
  },
});
const ScreenSearchSection = ({
  setisTopicOpen,
  setMediaFilesOpen,
  handleAddgroupMember,
  isTopicOpen,
  setAiQueryHistory,
  setTempAiQuery,
  setShareAiText,
  setShowStaredAiHistory,
  showStaredAiHistory,
  isAiQueryHistory,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const { selectedGroup, isAiScreenActive, isThreadScreen, showStarMessage } =
    useAppSelectors();

  const dispatch = useDispatch();
  const handleTopicClick = () => {
    if (selectedGroup && selectedGroup?.isGroup && !isTopicOpen) {
      setisTopicOpen(true);
    }
  };
  const handleAiBtnCLick = () => {
    if (selectedGroup) {
      setisTopicOpen(false);
      dispatch(handleShowAIScreen(true));
    }
  };
  const handleAiBackBtnClick = () => {
    dispatch(clearCurrentAiSessionData());
    dispatch(handleShowAIScreen(false));
    setAiQueryHistory(false);
    setTempAiQuery(false);
    setShareAiText(false);
  };
  const handleClearCurrentAiSession = () => {
    dispatch(clearCurrentAiSessionData());
  };
  return (
    <>
      {(!isThreadScreen || isAiScreenActive) && (
        <Stack
          spacing={"0.28rem"}
          mt={"0.37rem"}
          alignItems="center"
          direction="row"
        >
          <Stack alignItems="center" direction="row" spacing={"0.35rem"}>
            {isAiScreenActive ? (
              <>
                <Box>
                  <Button
                    onClick={() => selectedGroup && handleAiBackBtnClick()}
                    size="small"
                    variant="contained"
                    sx={{
                      alignContent: "center",
                      textTransform: "none",
                      borderRadius: "0.15rem",
                      backgroundColor: "#DB0011",
                      padding: "4px",
                      color: "#fff",
                      height: "30px",
                      minHeight: "unset",
                      "&:hover": {
                        backgroundColor: "#DB0011",
                        color: "#fff",
                      },
                    }}
                  >
                    <img
                      style={{ marginRight: "0.3rem" }}
                      src={white_back_arrow}
                      alt=""
                    />
                    {appContent?.back}
                  </Button>
                </Box>
                <Box>
                  <ActionButton
                    onClick={() => setAiQueryHistory(true)}
                    size="small"
                    variant="contained"
                  >
                    <SmallIcon src={historyIcon} />
                  </ActionButton>
                </Box>
              </>
            ) : (
              <>
                <LableToolTip title={appContent?.topicBtnLable}>
                  <Box>
                    <ActionButton
                      onClick={handleTopicClick}
                      size="small"
                      variant="contained"
                    >
                      <SmallIcon src={folderIcon} />
                    </ActionButton>
                  </Box>
                </LableToolTip>

                <LableToolTip title={appContent?.addGroupMemberBtnLable}>
                  <Box>
                    <ActionButton
                      size="small"
                      variant="contained"
                      onClick={handleAddgroupMember}
                      sx={{ bgcolor: "#DB0011" }}
                    >
                      <img
                        style={{ width: "95%" }}
                        src={addmember_icon}
                        alt="add member"
                      />
                    </ActionButton>
                  </Box>
                </LableToolTip>
              </>
            )}
          </Stack>

          <Stack flex={3}>
            <GroupInfoHeader
              isTopicOpen={isTopicOpen}
              setMediaFilesOpen={setMediaFilesOpen}
            />
            {/* <SearchBox>
                <CustomInput type="text" placeholder="Search" />
                <Box>
                  <ChatSearchButton size="small" variant="contained">
                    <SmallIcon src={searchIcon} />
                  </ChatSearchButton>
                </Box>
              </SearchBox> */}
          </Stack>

          <Stack alignItems="center" direction="row" spacing={"0.35rem"}>
            {!isAiScreenActive && (
              <LableToolTip title={appContent?.aiBtnLable}>
                <Box>
                  <ActionButton
                    onClick={handleAiBtnCLick}
                    size="small"
                    variant="contained"
                  >
                    <CustomIcon src={aIIcon} />
                  </ActionButton>
                </Box>
              </LableToolTip>
            )}

            {isAiScreenActive ? (
              <>
                <LableToolTip title={appContent?.starBtnLable}>
                  <Box>
                    <ActionButton
                      sx={{
                        backgroundColor: showStaredAiHistory ? "grey" : "",
                      }}
                      onClick={() =>
                        selectedGroup &&
                        isAiQueryHistory &&
                        setShowStaredAiHistory(true)
                      }
                      size="small"
                      variant="contained"
                    >
                      <SmallIcon src={vectorIcon} />
                    </ActionButton>
                  </Box>
                </LableToolTip>
                <Box>
                  <Button
                    onClick={() =>
                      selectedGroup && handleClearCurrentAiSession()
                    }
                    size="small"
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      borderRadius: "0.15rem",
                      backgroundColor: "#DB0011",
                      padding: "4px",
                      color: "#fff",
                      height: "30px",
                      minHeight: "unset",
                      "&:hover": {
                        backgroundColor: "#DB0011",
                        color: "#fff",
                      },
                    }}
                  >
                    <Typography variant="h5" fontSize={"0.75rem"}>
                      {appContent?.clear}
                    </Typography>
                  </Button>
                </Box>
              </>
            ) : (
              <LableToolTip title={appContent?.starBtnLable}>
                <Box>
                  <ActionButton
                    sx={{ backgroundColor: showStarMessage ? "grey" : "" }}
                    onClick={() => {
                      selectedGroup &&
                        dispatch(handleStarMsgScreen(!showStarMessage));
                      dispatch(handleScrollToBottom(true));
                    }}
                    size="small"
                    variant="contained"
                  >
                    <SmallIcon src={vectorIcon} />
                  </ActionButton>
                </Box>
              </LableToolTip>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default ScreenSearchSection;
