import axios from "axios";
import { OTP_VARIFY_URL, VERIFY_OTP, VERIFY_OTP_DONE } from ".";
import { SET_TOKEN_DONE, VERIFY_EMAIL_OTP_DONE } from "../../../reducers/auth";
import { REQUEST_FAILED } from "../../global/type";
import { setCookie } from "../../../../config/cookie";

export const otpVarification = (formData) => {
  let API_URL = `${OTP_VARIFY_URL}/verifyemail`;
  return async (dispatch) => {
    return axios
      .post(API_URL, formData)
      .then((res) => {
        if (res?.status == 200) {
          if(res?.data?.data?.activeSubscriber?.id){
            setCookie("subsCriber_id",res?.data?.data?.activeSubscriber?.id,30)
          }
          dispatch({
            type: VERIFY_OTP_DONE,
            payload: res?.data,
          });
          dispatch({
            type: SET_TOKEN_DONE,
            payload: res?.data.data?.token,
          });
          return res?.data;
        } else {
          dispatch({
            type: VERIFY_OTP,
            payload: res,
          });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};

export const otpEmailVarification = (formData) => {
  let API_URL = `${OTP_VARIFY_URL}/verifyemail`;
  return (dispatch) => {
    return axios
      .post(API_URL, formData)
      .then((res) => {
        if (res?.data?.status) {
          dispatch({
            type: VERIFY_EMAIL_OTP_DONE,
            payload: res?.data,
          });
          return res?.data;
        } else {
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
        // console.error(err);
      });
  };
};

export const reSendOtp = (formData) => {
  let API_URL = `${OTP_VARIFY_URL}/sendotp`;
  return (dispatch) => {
    return axios
      .post(API_URL, formData)
      .then((res) => {
        if (res?.status == 200) {
          // dispatch({
          //   type: VERIFY_EMAIL_OTP_DONE,
          //   payload: res?.data,
          // });
          return res?.data;
        } else {
          // dispatch({
          //   type: VERIFY_OTP,
          //   payload: res,
          // });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};
