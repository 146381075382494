import {
  handleChatLanguageUpdatedSignal,
  handleContactAdded,
  handleContactPermissionUpdate,
  handleContactRemovedSignal,
  handleGetInvitationList,
  memberAddedToGrpSignal,
  updateGroupSignal,
  updateGroupTopicSignal,
} from ".";
import { PubnubEventType } from "../../../utils/constant";


export function handlePubnubSignal({
  loggedInUserActiveSubsId,
  loggedInUserId,
  sidList,
  messageEvent,
  dispatch,
  requestallmemberswithgroups,
  setisTopicOpen,
  handleCloseAddPeopleDrawer,
  getAlltopic,
  requestgroupMemberDetails,
  requestGetGroupRecipient,
  requestUpdateFromSignalGroupRecipient,
  groupList,
  groupRecipientDetails,
  switchAccount,
  getProfile,
  switchAccountId,
  requestGetAllAcountLevelMember,
  setGroupname,
  navigate,
  userData,
  saveSelectedAppGroupData,
  handleAppSelectedTopic,
  selectedGroup,
  handleFetchConversation,
  handleUpdateExistingMessage,
  messageList,
  selectedTopic,
  isThreadScreen,
  getAllPendingInvitation,
  handleSwitchAccountRefresh,
  switchAccountRefresh,
  handleSyncStarMsgData,
  handleDeleteMsgFromDb,
  toggleSetPubTokenState,
  handleTogglePunbunTokenUpdateState,
}) {
  switch (messageEvent?.message?.content?.type) {
    case PubnubEventType?.PROFILE_UPDATE: {
      const memberreqBody = {
        group_id: selectedGroup?.id,
        target_subscriber_id: loggedInUserActiveSubsId,
      };
      dispatch(requestallmemberswithgroups({ forceFetch: true }));
      dispatch(requestgroupMemberDetails(memberreqBody));
      dispatch(requestGetGroupRecipient(selectedGroup?.id));
      dispatch(requestGetAllAcountLevelMember());
      return;
    }
    case PubnubEventType?.MARK_AS_READ: {
      if (
        // loggedInUserActiveSubsId === messageEvent?.message?.content?.sid &&
        messageEvent?.message?.content?.uuid?.includes(loggedInUserId) &&
        messageEvent?.message?.content?.type === PubnubEventType?.MARK_AS_READ
      ) {
        const reqBody = {
          pn_id: messageEvent?.message?.content?.pnid,
          timetoken: messageEvent?.message?.content?.timetoken,
        };
        let shouldUpdateMessageList = false;
        if (messageEvent?.message?.content?.pnid === selectedGroup?.pnId) {
          shouldUpdateMessageList = true;
        }
        dispatch(handleFetchConversation(reqBody)).then((res) => {
          dispatch(
            handleUpdateExistingMessage(
              messageEvent?.message?.content?.pnid,
              res,
              shouldUpdateMessageList,
              messageList,
              selectedTopic,
              isThreadScreen
            )
          );
        });
      }

      return;
    }

    case PubnubEventType?.CHAT_MOVED_TO_ANOTHER_TOPIC: {
      if (
        loggedInUserActiveSubsId === messageEvent?.message?.content?.sid &&
        messageEvent?.message?.content?.uuid?.includes(loggedInUserId)
      ) {
        const reqBody = {
          pn_id: messageEvent?.message?.content?.pnid,
          timetoken: messageEvent?.message?.content?.timetoken,
        };
        let shouldUpdateMessageList = false;
        if (messageEvent?.message?.content?.pnid === selectedGroup?.pnId) {
          shouldUpdateMessageList = true;
        }
        dispatch(handleFetchConversation(reqBody)).then((res) => {
          dispatch(
            handleUpdateExistingMessage(
              messageEvent?.message?.content?.pnid,
              res,
              shouldUpdateMessageList,
              messageList,
              selectedTopic,
            )
          );
        });
      }
      return;
    }

    case PubnubEventType?.CHAT_STARRED: {
      if (
        loggedInUserActiveSubsId === messageEvent?.message?.content?.sid &&
        messageEvent?.message?.content?.uuid?.includes(loggedInUserId)
      ) {
        let shouldUpdateMessageList = false;
        if (messageEvent?.message?.content?.pnid === selectedGroup?.pnId) {
          shouldUpdateMessageList = true;
        }
        dispatch(
          handleSyncStarMsgData(
            messageEvent?.message?.content?.pnid,
            messageEvent?.message?.content?.message?.temp_timetoken,
            selectedTopic?.id,
            messageList?.length,
            messageEvent?.message?.content?.message,
            shouldUpdateMessageList
          )
        );
      }
      return;
    }
    case PubnubEventType?.CHAT_MSG_DELETE: {
      if (messageEvent?.message?.content?.uuid?.includes(loggedInUserId)) {
        if (loggedInUserActiveSubsId === messageEvent?.message?.content?.sid) {
          let shouldUpdateMessageList =
            messageEvent?.message?.content?.pnid === selectedGroup?.pnId
              ? true
              : false;
          dispatch(
            handleDeleteMsgFromDb(
              messageEvent?.message?.content?.pnid,
              messageEvent?.message?.content?.temp_timetoken,
              messageEvent?.message?.content?.sid,
              selectedGroup?.pnId,
              selectedTopic?.id,
              messageList?.length,
              shouldUpdateMessageList,
              isThreadScreen
            )
          );
        } else {
          dispatch(
            handleDeleteMsgFromDb(
              messageEvent?.message?.content?.pnid,
              messageEvent?.message?.content?.temp_timetoken,
              messageEvent?.message?.content?.sid,
              selectedGroup?.pnId,
              selectedTopic?.id,
              messageList?.length,
              false
            )
          );
          // delete on basis of sid to db
        }
      }

      return;
    }

    case PubnubEventType?.ACCOUNT_INVITATION: {
      handleGetInvitationList(
        loggedInUserActiveSubsId,
        loggedInUserId,
        messageEvent?.message?.content,
        dispatch,
        getAllPendingInvitation
      );
      return;
    }

    case PubnubEventType?.ACCOUNT_SWITCHED: {
      const endpoint = window.location.pathname;
      if (messageEvent?.message?.content?.uuid?.includes(loggedInUserId)) {
        dispatch(getProfile()).then((Profileres) => {
          if (Object.keys(Profileres).length > 0) {
            dispatch(requestallmemberswithgroups({ forceFetch: true })).then(
              (allgrpres) => {
                if (endpoint !== "/chat") {
                  navigate("/chat");
                }
                dispatch(handleSwitchAccountRefresh(!switchAccountRefresh));
              }
            );
          }
        });
      }
      return;
    }
    case PubnubEventType?.GROUP_CREATED: {
      updateGroupSignal(
        sidList,
        loggedInUserActiveSubsId,
        loggedInUserId,
        messageEvent?.message,
        dispatch,
        requestallmemberswithgroups,
      );
      return;
    }
    case PubnubEventType?.GROUP_DELETED:
    case PubnubEventType?.GROUP_EDITED: {
      updateGroupSignal(
        sidList,
        loggedInUserActiveSubsId,
        loggedInUserId,
        messageEvent?.message,
        dispatch,
        requestallmemberswithgroups,
        selectedGroup,
        setisTopicOpen,
        setGroupname,
        saveSelectedAppGroupData,
        handleSwitchAccountRefresh,
        switchAccountRefresh
      );
      return;
    }
    case PubnubEventType?.USER_GROUP_LIST_UPDATED:
    case PubnubEventType?.USER_ADDED_TO_GROUP:
    case PubnubEventType?.USER_REMOVED_FROM_GROUP:
    case PubnubEventType?.USER_EXITED_THE_GROUP: {
      memberAddedToGrpSignal(
        loggedInUserActiveSubsId,
        loggedInUserId,
        messageEvent?.message?.content,
        dispatch,
        requestallmemberswithgroups,
        requestgroupMemberDetails,
        requestUpdateFromSignalGroupRecipient,
        selectedGroup,
        handleSwitchAccountRefresh,
        switchAccountRefresh,
        sidList
      );
      return;
    }
    case PubnubEventType?.TOPIC_CREATED:
    case PubnubEventType?.TOPIC_UPDATED:
    case PubnubEventType?.TOPIC_DELETED: {
      updateGroupTopicSignal(
        loggedInUserActiveSubsId,
        loggedInUserId,
        messageEvent?.message?.content,
        dispatch,
        getAlltopic,
        selectedGroup,
        sidList
      );
      return;
    }

    case PubnubEventType?.CONTACT_REMOVED: {
      handleContactRemovedSignal(
        loggedInUserActiveSubsId,
        loggedInUserId,
        messageEvent?.message?.content,
        dispatch,
        switchAccountId,
        switchAccount,
        getProfile,
        requestallmemberswithgroups,
        requestGetAllAcountLevelMember,
        requestgroupMemberDetails,
        requestGetGroupRecipient,
        selectedGroup,
        navigate,
        userData,
        handleSwitchAccountRefresh,
        switchAccountRefresh,
        groupRecipientDetails
      );
      return;
    }
    case PubnubEventType?.CONTACT_PERMISSION_UPDATED: {
      handleContactPermissionUpdate(
        loggedInUserActiveSubsId,
        loggedInUserId,
        messageEvent?.message?.content,
        dispatch,
        requestGetAllAcountLevelMember,
        getProfile,
        requestallmemberswithgroups,
        selectedGroup,
        requestgroupMemberDetails,
        groupRecipientDetails,
        handleSwitchAccountRefresh,
        switchAccountRefresh
      );
      return;
    }
    case PubnubEventType?.CONTACT_ADDED: {
      handleContactAdded(
        loggedInUserActiveSubsId,
        loggedInUserId,
        messageEvent?.message?.content,
        dispatch,
        requestGetAllAcountLevelMember,
        requestallmemberswithgroups,
        requestgroupMemberDetails,
        requestGetGroupRecipient,
        selectedGroup
      );
      return;
    }
    case PubnubEventType?.CHAT_LANGUAGE_UPDATED: {
      handleChatLanguageUpdatedSignal(
        loggedInUserActiveSubsId,
        loggedInUserId,
        messageEvent?.message?.content,
        dispatch,
        requestgroupMemberDetails,
        requestGetGroupRecipient,
        selectedGroup,
        requestGetAllAcountLevelMember,
        sidList
      );
      return;
    }
    default:
      return null;
  }
}
