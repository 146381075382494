import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getCookie } from "./cookie";
import { logWrapper } from "../utils/helper";

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMIAN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSANGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MESURMNET_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const appleprovider = new OAuthProvider("apple.com");

let messaging;

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      logWrapper("Service worker registered:", registration);
      messaging = getMessaging(app);
    })
    .catch((error) => {
      logWrapper("Service worker registration failed:", error);
    });
} else {
  logWrapper("Service workers are not supported in this browser.");
}

// Function to fetch FCM token
export const fetchToken = async (user_id) => {
  if (!messaging) {
    return;
  }

  try {
    // Request notification permission from the user
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.warn("Notification permission not granted.");
      return;
    }

    // Get the FCM token
    const currentToken = await getToken(messaging, {
      vapidKey: "BMAy-MIa5WcYPxxCk9ZGb8yZd4ltujbqoE1jjoPqd6LNdixTL9zxL79jEzZaxc-_fIEM8KorAd50r6B9rr5AdQU",
    });
    logWrapper("FCM Token:", currentToken);

    if (currentToken) {
      sendTokenToBackend(currentToken, user_id);
    } else {
      console.warn("No FCM token available.");
    }
  } catch (err) {
    console.error("Error fetching FCM token:", err);
  }
};

// Function to send FCM token to the backend
const sendTokenToBackend = (token, user_id) => {
  const API_URL = `${process.env.REACT_APP_BASE_PATH}/notifications/update-device`;
  const reqBody = {
    token: token,
    app_version: "0.1.0",
    device_version: "15.6.0",
    user_id: user_id,
  };
  logWrapper("Sending token to backend:", reqBody);

  const authToken = getCookie("token");

  axios
    .post(API_URL, reqBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((res) => {
    })
    .catch((error) => {
      console.error("Error sending token to backend:", error);
    });
};

export const checkNotificationPermission = async (user_id) => {
  if (Notification.permission === "granted") {
    await fetchToken(user_id);
  } else {
    console.warn("Notification permission is not granted.");
  }
};

// Listener for incoming messages
export const onMessageListener = (fun) =>
  new Promise((resolve) => {
    if (!messaging) {
      resolve(null);
      return;
    }

    onMessage(messaging, (payload) => {
      fun(payload);
      resolve(payload);
    });
  });

export { auth, provider, appleprovider, messaging };
