import { Box } from "@mui/material";
import React from "react";
import smallCloseIcon from "../../../assets/images/post/closeIcon.svg";

const SelectImage = ({ images, cancleSelectedImage }) => {
  return (
    <Box display={"flex"} flexWrap={"wrap"}>
      {[...Array(5)].map((_, index) => (
        <Box
          key={index+"SelectImage"}
          width={"32.4%"}
          height={"7.4rem"}
          maxHeight={"7.4rem"}
          ml={index === 0 || index === 3 ? "none" : "0.4rem"}
          mb={"0.4rem"}
          borderRadius={"4px"}
          bgcolor={images[index] ? "transparent" : "#F3F3F3"}
          overflow={"hidden"}
          position={"relative"}
        >
          {images[index] && (
            <>
              <img
                style={{ width: "100%", height: "100%" }}
                src={
                  images[index]?.file
                    ? URL.createObjectURL(images[index].file)
                    : images[index]?.location
                }
                alt="pic"
              />
              <Box
                sx={{ cursor: "pointer" }}
                zIndex={100}
                position={"absolute"}
                top={"0.2rem"}
                right={"0.2rem"}
                onClick={() => cancleSelectedImage(images[index])}
              >
                <img src={smallCloseIcon} alt="close" />
              </Box>
            </>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default SelectImage;
