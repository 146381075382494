import React from "react";
import { useDispatch } from "react-redux";
import { Box, Paper, Typography } from "@mui/material";
import reply_icon from "../../../assets/images/chats/reply.svg";
import move_topic_icon from "../../../assets/images/chats/move_topic.svg";
import delete_icon from "../../../assets/images/delete.svg";
import { handleChangeTopicOpen } from "../../../appStore/actions/AppState/action";
import { MessageType } from "../../../utils/constant";

const classes = {
  hoverStyle: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E1E1E1",
    },
  },
};
const MoreMenu = ({
  messageData,
  menuRef,
  setMoreMenu,
  setDeleteGroupMsgOpen,
  setDeleteGroupMsgOpenIndicatorId,
  setMoveTopicTriggered,
  setMoveTopicMsgId,
  handleReplyMessage,
  setisTopicOpen,
  isThreadScreen,
  handleReadStatusDrawer,
}) => {
  const dispatch = useDispatch();
  const handlereply = (msgData) => {
    handleReplyMessage(msgData);
    setMoreMenu(null);
  };

  return (
    <Box
      position="absolute"
      top={"0"}
      right={"0.8rem"}
      width={"15rem"}
      zIndex={100}
      ref={menuRef}
    >
      <Paper
        sx={{
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          padding: "0.7rem 0rem",
        }}
      >
        <Box>
          <Box p={"0.4rem 1rem"} sx={classes?.hoverStyle}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              onClick={() => handlereply(messageData)}
            >
              <Box>
                <Typography>Reply</Typography>
              </Box>
              <Box>
                <img src={reply_icon} alt="reply_icon" />
              </Box>
            </Box>
          </Box>

          {!isThreadScreen && (
            <Box
              onClick={() => {
                setisTopicOpen(true);
                setMoveTopicTriggered(true);
                dispatch(handleChangeTopicOpen(false));
                setMoveTopicMsgId(messageData);
                setMoreMenu(null);
              }}
              mt={"0.7rem"}
              p={"0.4rem 1rem"}
              sx={classes?.hoverStyle}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography>Move to another topic</Typography>
                </Box>
                <Box>
                  <img src={move_topic_icon} alt="icon" />
                </Box>
              </Box>
            </Box>
          )}
          {messageData?.type === MessageType?.Message && (
            <Box
              onClick={() => {
                setDeleteGroupMsgOpen(true);
                setDeleteGroupMsgOpenIndicatorId(messageData?.timetoken);
              }}
              mt={"0.7rem"}
              p={"0.4rem 1rem"}
              sx={classes?.hoverStyle}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography>Delete</Typography>
                </Box>
                <Box>
                  <img src={delete_icon} alt="delete_icon" />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default MoreMenu;
