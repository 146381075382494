import React from "react";
import { Typography, Box } from "@mui/material";
import image1 from "../../../assets/images/auth/authCarouselSlide1.svg";
import image2 from "../../../assets/images/auth/authCarouselSlide2.svg";
import arkChatLogo from "../../../assets/images/arkChatLogo.svg";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { carouselStyle } from "../../../Style/styles";

const CarouselComponent = ({ showLogo = true }) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const slides = [
    {
      image: image1,
      heading: appContent?.authCarouselFirstTitle,
      description: appContent?.authCarouselFirstDescription,
    },
    {
      image: image2,
      heading: appContent?.authCarouselSecondTitle,
      description: appContent?.authCarouselSecondDescription,
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        position={"relative"}
        sx={{ ...carouselStyle.main }}
      >
        {showLogo && (
          <Box width={"30%"}>
            <img src={arkChatLogo} alt="arkChat" />
          </Box>
        )}
        <Box
          maxWidth={"100%"}
          position={"absolute"}
          top={"11%"}
          transform= "translateY(-11%)"
        >
          <Slider {...settings}>
            {slides?.map((item, i) => (
              <Box key={item?.image}>
                <Box textAlign={"center"}>
                  <img
                    style={{
                      display: "block",
                      margin: "auto",
                    }}
                    src={item?.image}
                    alt="logo"
                  />
                </Box>
                <Box textAlign={"center"} mt={"1rem"}>
                  <Typography
                    variant="h5"
                    fontSize={"0.85rem"}
                    fontWeight={600}
                  >
                    {item?.heading}
                  </Typography>
                </Box>
                <Box
                  textAlign={"center"}
                  width={"80%"}
                  margin={"auto"}
                  mt={"1rem"}
                  mb={i === 1 ? "2rem" : "0rem"}
                >
                  <Typography
                    variant="h5"
                    fontSize={"0.85rem"}
                    fontWeight={400}
                  >
                    {item?.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </>
  );
};

export default CarouselComponent;
