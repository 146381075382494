export const SET_SELECTED_GROUP_DATA = "SET_SELECTED_GROUP_DATA";
export const SET_SELECTED_TOPIC_DATA = "SET_SELECTED_TOPIC_DATA";
export const SET_PUBNUB = "SET_PUBNUB";

export const SET_SHOW_STARMESSAGE_SCREEN = "SET_SHOW_STARMESSAGE_SCREEN"; 

export const SET_HOMETAB_VALUE = "SET_HOMETAB_VALUE"; 
export const SET_SHOW_AI_SCREEN = "SET_SHOW_AI_SCREEN";
export const SET_SHOW_AI_HISTOPRY_SCREEN = "SET_SHOW_AI_HISTOPRY_SCREEN";

export const SET_SHOW_STARHISTOPRY_SCREEN = "SET_SHOW_STARHISTOPRY_SCREEN";
export const SET_SCROLL_TO_BOTTOM = "SET_SCROLL_TO_BOTTOM";
export const SET_THREAD_SCROLL_TO_BOTTOM = "SET_THREAD_SCROLL_TO_BOTTOM";


export const CHANGE_TOPIC_INITIATED = "CHANGE_TOPIC_INITIATED";
export const TOPIC_TO_SAVE_MESSAGE = "TOPIC_TO_SAVE_MESSAGE";
export const SWITCH_ACCOUNT_SHOULD_REFRESH = "SWITCH_ACCOUNT_SHOULD_REFRESH";
export const SET_SERVICETYPE_POST="SET_SERVICETYPE_POST";

/////// Local State TYPE (which is not persisted) ////////
export const POST_PREVIEW_OR_EDIT_DATA = "POST_PREVIEW_OR_EDIT_DATA";
/////// Local State TYPE (which is not persisted) ////////

export const OPEN_CLOSE_THREAD_VIEW = "OPEN_CLOSE_THREAD_VIEW";
export const TOGGLE_UPDATE_PUBNUB_TOKEN = "TOGGLE_UPDATE_PUBNUB_TOKEN";
export const SHOULD_CALL_PUBNUB_GET_TOKEN = "SHOULD_CALL_PUBNUB_GET_TOKEN";
export const IS_LOADING = "IS_LOADING";
export const ACCOUNT_SWITCH_LOADING = "ACCOUNT_SWITCH_LOADING";

export const DELETE_ACCOUNT_API_RESPONSE_DATA="DELETE_ACCOUNT_API_RESPONSE_DATA";// for open route delete
