import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Calender from "./Calender";
import Time from "./Time";
import { MessageType, priorityValue } from "../../../utils/constant";
import DrawerInput from "../MessageComponent/DrawerInput";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },

  typeBox: {
    fontSize: "12px",
    color: "#183243",
    background: "#E1E1E1",
    borderRadius: "3px",
    padding: "0",
    borderColor: "#E1E1E1",
    textTransform: "none",
    ":hover": {
      color: "#183243",
      background: "#E1E1E1",
      borderRadius: "3px",
      padding: "0",

      borderColor: "#E1E1E1",
    },
  },
};
const UserDateTimeDrawer = ({
  handleNextStep,
  taskAssignedUser,
  handleClostRecipientsDrawer,
  setType,
  handleTimeChange,
  dueTime,
  handleDateChange,
  setPriority,
  priority,
  setMessageType,
  msgType,
  setRepeat,
  dueDate,
  setStepper,
  setTaskUpdateOpen,
  setSelectRecipientsDrawerOpen,
  taskData,
  setTaskData,
  handleClickYouTube,
  setFileAttachment,
  handleClickGoogle,
  fileAttachment = [],
  attachment = [],
  setAttachment,
  handleUpdateTaskApproval,
  updateTaskApprovalMsg,
  setUpdateTaskApprovalMsg,
  checkedType,
  isTaskUpdateOpen,
  selectedRecipients,
}) => {
  const handleRepeat = () => {
    handleNextStep("repeat");
  };
  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={classes.header}
          >
            <Typography sx={classes.iconDiv}>
              <CloseIcon
                sx={classes.iconn}
                onClick={() =>
                  taskAssignedUser?.userId
                    ? setStepper("user")
                    : handleClostRecipientsDrawer()
                }
              />
            </Typography>
            <Typography color={"black"}>
              {taskAssignedUser?.userId ? (
                <>
                  {" "}
                  {taskAssignedUser?.firstName} {taskAssignedUser?.lastName} :
                  To Do
                </>
              ) : (
                <>Update Task</>
              )}
            </Typography>
          </Stack>
          <Box>
            <Box borderBottom={"1px solid #e1e1e1"} mb={"0.45rem"}>
              <Calender handleDateChange={handleDateChange} dueDate={dueDate} />
            </Box>

            <Box width={"70%"} margin={"auto"}>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"5px"}
                mb={"0.5rem"}
              >
                <Box>
                  <Button sx={classes?.typeBox}>Time</Button>
                </Box>
                <Box>
                  <Typography variant="subtitle1">:</Typography>
                </Box>
                <Box>
                  <Time
                    defaultValue={dueTime}
                    handleTimeChange={handleTimeChange}
                  />
                </Box>
              </Box>

              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"5px"}
                mb={"0.5rem"}
              >
                <Box>
                  <Button sx={classes?.typeBox}>Priority</Button>
                </Box>
                <Box>
                  <Typography variant="subtitle1">:</Typography>
                </Box>

                <Box display={"flex"}>
                  <Button
                    onClick={() => setPriority(priorityValue?.Low)}
                    variant="outlined"
                    sx={{
                      fontSize: "12px",
                      backgroundColor:
                        priority === priorityValue?.Low ? "#183243" : "#fff",
                      borderRadius: "3px",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                      padding: "0",
                      textTransform: "none",
                      color:
                        priority === priorityValue?.Low ? "#fff" : "#183243",
                      borderColor: "#183243",
                      ":hover": {
                        backgroundColor:
                          priority === priorityValue?.Low ? "#183243" : "#fff",
                        color:
                          priority === priorityValue?.Low ? "#fff" : "#183243",
                        borderRadius: "3px",
                        padding: "0",

                        borderColor: "#183243",
                      },
                    }}
                  >
                    Low
                  </Button>
                  <Button
                    onClick={() => setPriority(priorityValue?.Medium)}
                    variant="outlined"
                    sx={{
                      fontSize: "12px",
                      borderRadius: "3px",
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                      padding: "0",
                      textTransform: "none",
                      backgroundColor:
                        priority === priorityValue?.Medium ? "#183243" : "#fff",
                      color:
                        priority === priorityValue?.Medium ? "#fff" : "#183243",
                      borderColor: "#183243",
                      ":hover": {
                        backgroundColor:
                          priority === priorityValue?.Medium
                            ? "#183243"
                            : "#fff",
                        color:
                          priority === priorityValue?.Medium
                            ? "#fff"
                            : "#183243",
                        borderRadius: "3px",
                        padding: "0",

                        borderColor: "#183243",
                      },
                    }}
                  >
                    Medium
                  </Button>

                  <Button
                    onClick={() => setPriority(priorityValue?.High)}
                    variant="outlined"
                    sx={{
                      fontSize: "12px",
                      borderRadius: "3px",
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      borderLeft: "0px",
                      padding: "0",
                      textTransform: "none",
                      backgroundColor:
                        priority === priorityValue?.High ? "#183243" : "#fff",
                      color:
                        priority === priorityValue?.High ? "#fff" : "#183243",
                      borderColor: "#183243",
                      ":hover": {
                        backgroundColor:
                          priority === priorityValue?.High ? "#183243" : "#fff",
                        color:
                          priority === priorityValue?.High ? "#fff" : "#183243",
                        borderRadius: "3px",
                        padding: "0",

                        borderColor: "#183243",
                      },
                    }}
                  >
                    High
                  </Button>
                </Box>
              </Box>

              <Box display={"flex"} alignItems={"center"} gap={"5px"}>
                <Box>
                  <Button sx={classes?.typeBox}>To Do</Button>
                </Box>

                <Box>
                  <Typography variant="subtitle1">:</Typography>
                </Box>

                <Box display={"flex"}>
                  <Button
                    onClick={() => setMessageType(MessageType?.Task)}
                    variant="outlined"
                    sx={{
                      fontSize: "12px",
                      borderRadius: "3px",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                      padding: "0",

                      textTransform: "none",
                      backgroundColor:
                        msgType === MessageType?.Task ? "#183243" : "#fff",
                      color: msgType === MessageType?.Task ? "#fff" : "#183243",
                      borderColor: "#183243",
                      ":hover": {
                        backgroundColor:
                          msgType === MessageType?.Task ? "#183243" : "#fff",
                        color:
                          msgType === MessageType?.Task ? "#fff" : "#183243",
                        borderRadius: "3px",
                        padding: "0",

                        borderColor: "#183243",
                      },
                    }}
                  >
                    Task
                  </Button>
                  <Button
                    onClick={() => setMessageType(MessageType?.Approval)}
                    variant="outlined"
                    sx={{
                      fontSize: "12px",
                      borderRadius: "3px",
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      padding: "0",
                      textTransform: "none",
                      backgroundColor:
                        msgType === MessageType?.Approval ? "#183243" : "#fff",
                      color:
                        msgType === MessageType?.Approval ? "#fff" : "#183243",
                      borderColor: "#183243",
                      ":hover": {
                        backgroundColor:
                          msgType === MessageType?.Approval
                            ? "#183243"
                            : "#fff",
                        color:
                          msgType === MessageType?.Approval
                            ? "#fff"
                            : "#183243",
                        borderRadius: "3px",
                        padding: "0",

                        borderColor: "#183243",
                      },
                    }}
                  >
                    Approval
                  </Button>
                  <Button
                    onClick={handleRepeat}
                    variant="outlined"
                    sx={{
                      fontSize: "12px",
                      ml: "0.4rem",
                      backgroundColor: "#fff",
                      borderRadius: "3px",
                      padding: "0",

                      textTransform: "none",
                      color: "#183243",
                      borderColor: "#183243",
                      ":hover": {
                        color: "#183243",
                        borderRadius: "3px",
                        padding: "0",

                        borderColor: "#183243",
                      },
                    }}
                  >
                    Repeat
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Stack>

        {isTaskUpdateOpen && (
          <Box position={"absolute"} bottom={"0"} width={"100%"}>
            <DrawerInput
              handleUpdateTaskApproval={handleUpdateTaskApproval}
              handleClickYouTube={handleClickYouTube}
              setFileAttachment={setFileAttachment}
              handleClickGoogle={handleClickGoogle}
              fileAttachment={fileAttachment}
              attachment={attachment}
              taskData={taskData}
              checkedType={checkedType}
              setUpdateTaskApprovalMsg={setUpdateTaskApprovalMsg}
              updateTaskApprovalMsg={updateTaskApprovalMsg}
              selectedRecipients={selectedRecipients}
              setAttachment={setAttachment}
            />
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default UserDateTimeDrawer;
