import React from "react";
import { Box } from "@mui/material";
import replyIcon from "../../../assets/images/chats/reply.svg";
import { getFileIconUrl } from "../../../utils/findextension";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";

const SelectedAttachment = ({
  data,
  handleRemoveAttachment,
  replyParentMsg = "",
}) => {
  return (
    <Box
      position={"absolute"}
      bgcolor={"#FBFBFB"}
      display={"flex"}
      boxSizing={"border-box"}
      width={"100%"}
    >
      {data?.map((item, i) => (
        <Box
          display={"flex"}
          maxWidth={"25%"}
          padding={"0.5rem"}
          key={"fileAttachment" + i}
        >
          <Box width={"100%"}>
            <Chip
              sx={{ ".MuiChip-deleteIcon": { color: "#DB0011" } }}
              label={`${item?.name ? item?.name : item?.file?.name}`}
              variant="outlined"
              avatar={
                <Avatar
                  alt="Natacha"
                  src={
                    replyParentMsg
                      ? replyIcon
                      : getFileIconUrl(
                          item?.name ? item?.location : item?.file?.name
                        )
                  }
                  title={`${item?.name ? item?.name : item?.file?.name}`}
                />
              }
              onDelete={() => handleRemoveAttachment(item?.id)}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SelectedAttachment;
