import React from "react";
import ReactApexChart from "react-apexcharts";

const SemicircularChart = ({ seriesData, colorsData, trackBackground }) => {
  const options1 = {
    chart: {
      height: 210,
      type: "radialBar",
    },
    series: [seriesData],
    colors: [colorsData],
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        track: {
          background: trackBackground,
          startAngle: -135,
          endAngle: 135,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            fontSize: "1rem",
            show: true,
          },
        },
      },
    },

    stroke: {
      lineCap: "butt",
    },
    labels: ["Progress"],
  };

  return (
    <div>
      <ReactApexChart
        options={options1}
        series={options1.series}
        type="radialBar"
        height={240}
      />
    </div>
  );
};

export default SemicircularChart;
