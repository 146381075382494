import React from "react";
import { useSelector } from "react-redux";
import FilterCategory from "./FilterCategory";

const ArtFilter = ({ handleFilter, clearFilter, setChecked, checked }) => {
  const artServiceCategoryData = useSelector(
    (state) => state?.serviceCategory?.artServiceCategoryData
  );

  return (
    <>
      <FilterCategory
        data={artServiceCategoryData}
        handleFilterApply={handleFilter}
        clearFilter={clearFilter}
        checked={checked}
        setChecked={setChecked}
      />
    </>
  );
};

export default ArtFilter;
