import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Stack, Drawer, Link, Collapse } from "@mui/material";
import { classes } from "./MessageList";
import three_dots from "../../../assets/images/chats/three_dots.svg";
import starred_icon from "../../../assets/images/chats/starred_icon.svg";
import replaym from "../../../assets/images/chats/reply.svg";
import moment from "moment";
import MoreMenu from "./MoreMenu";
import InputDialogComponent from "../../../components/InputDialog";
import { getFileIconUrl } from "../../../utils/findextension";
import {
  AccountLevelArkchatPermission,
  ApprovalStatus,
  MessageType,
  TaskStatus,
  getTaskStatusLabels,
  priorityValueColor,
  priorityValueLable,
  taskStatusColor,
} from "../../../utils/constant";
import TaskSvg from "../../../common/SvgImageView/TaskSvg";
import ApprovalSvg from "../../../common/SvgImageView/ApprovalSvg";
import RecipientList from "./RecipientList";
import { handleThreadScrollToBottom } from "../../../appStore/actions/AppState/action";
import StartTask from "./StartTask";
import { useTranslation } from "react-i18next";
const StarMessageView = ({
  item,
  isMoreMenuOpen,
  threeDotRef,
  userData,
  isDeleteGroupMsgOpenIndicatorId,
  isDeleteGroupMsgOpen,
  handleReadStatusDrawer,
  setisTopicOpen,
  setMoveTopicMsgId,
  setMoveTopicTriggered,
  setDeleteGroupMsgOpenIndicatorId,
  setDeleteGroupMsgOpen,
  handlegetThreadMessages,
  setMoreMenu,
  menuRef,
  isThreadScreen,
  selectedGroup,
  toggleItemMenu,
  startTaskOpen,
  setStartTaskOpen,
  handleStartTask,
  handleStarUnstarMsg,
  handleDeleteGrpMsg,
  setDeleteGrpMsgType,
  handleClickTaskUpdate,
}) => {
  const { t } = useTranslation();
  const appConstant = t("AppConstant", { returnObjects: true });
  const taskStatusLabels = getTaskStatusLabels(appConstant);
  const groupTopicData = useSelector((state) => state?.topic?.groupTopicData);
  const [isRecipientListOpen, setRecipientListOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [recipientList, setRecipientList] = useState([]);
  const dispatch = useDispatch();
  const handleSeeRecipientList = (recipientData) => {
    setRecipientList(recipientData);
    setRecipientListOpen(true);
  };
  return (
    <Box width={"100%"} key={item?.timetoken}>
      <Drawer
        sx={{
          zIndex: 12011,
        }}
        anchor={"right"}
        open={isRecipientListOpen}
      >
        <RecipientList
          recipientList={recipientList}
          readby={item?.read_by}
          setRecipientListOpen={setRecipientListOpen}
        />
      </Drawer>

      <Box width={"100%"}>
        {(item?.type === 0 || item?.type === 1 || item?.type === 2) && (
          <>
            <Stack
              direction="row"
              spacing={2}
              width={"100%"}
              padding={"0.8rem 0rem"}
              justifyContent={"space-between"}
              // bgcolor={bgcolor}
              id={`replied-message-${item?.temp_timetoken}`}
            >
              <div style={{ width: "100%" }}>
                <Stack
                  // border="1px solid red"
                  width={"100%"}
                  direction="row"
                  spacing={1}
                >
                  {/* ------------profile section--------------- */}
                  {item?.from?.pp ? (
                    <Box sx={classes.chatUserprofile}>
                      <img src={item?.from?.pp} alt="profile" height="100%" />
                    </Box>
                  ) : (
                    <Box
                      bgcolor={"#E3E1E1"}
                      width={"30px"}
                      height={"30px"}
                      display={"flex"}
                      flex={"none"}
                      alignItems={"center"}
                      flex-wrap={"wrap"}
                      justifyContent={"center"}
                      borderRadius={"0.15rem"}
                      // border={"1px solid blue"}
                    >
                      <Typography variant="h6" fontSize={"0.75rem"}>
                        {item?.from?.name && item?.from?.name[0]}
                      </Typography>
                    </Box>
                  )}
                  {/* ------------Details section--------------- */}
                  <Box
                    width={"88%"}
                    //  border={"1px solid green"}
                  >
                    <Stack direction="row" spacing={1}>
                      <Typography variant="subtitle2" fontWeight={"450"}>
                        {item?.from?.name}
                      </Typography>
                      <Typography sx={classes.duetime}>
                        {/* Time */}
                        {moment(Math.floor(item?.timetoken / 10000)).format(
                          userData?.profileData?.data?.userDateTimeFormat
                        )}
                      </Typography>
                      {/* <Box
                      sx={{ ...classes.tick, cursor: "pointer" }}
                      onClick={() => handleSeeRecipientList(item?.to)}
                    >
                      <img
                        src={
                          item?.to?.length === item?.read_by?.length
                            ? tick_green
                            : tick_gray
                        }
                        alt="Chat Status"
                      />
                    </Box> */}
                    </Stack>
                    <Typography
                      onClick={() => handleSeeRecipientList(item?.to)}
                      variant="subtitle2"
                      display={"inline-block"}
                      sx={classes.userlink}
                    >
                      {item?.is_all_group_member
                        ? "To all group members"
                        : `To ${item?.to[0]?.name}${
                            item?.to?.length > 1
                              ? `, and ${item?.to?.length - 1} more`
                              : ""
                          }`}
                    </Typography>

                    {/* -------------reply---------- */}
                    {item?.thread_original_message &&
                      item?.translations &&
                      item?.thread_original_message?.translations && (
                        <Stack
                          direction="row"
                          spacing={1}
                          width={"100%"}
                          sx={{
                            ...classes.chatRep,
                            cursor: "pointer",
                            wordWrap: "break-word",
                          }}
                          // onClick={() =>
                          //   scrollToRepliedMessage(
                          //     item?.thread_original_message?.temp_timetoken
                          //   )
                          // }
                        >
                          <img src={replaym} alt="reply" />
                          {item?.translations &&
                          item?.thread_original_message?.translations[
                            item?.to?.find(
                              (data) =>
                                data?.id === userData?.profileData?.data?.id
                            )?.languageCode
                          ] ? (
                            <Typography
                              width={"95%"}
                              sx={{ wordWrap: "break-word" }}
                            >
                              {item?.to?.findIndex(
                                (idData) =>
                                  idData?.id === userData?.profileData?.data?.id
                              ) !== -1 &&
                                item?.thread_original_message?.translations[
                                  item?.to?.find(
                                    (data) =>
                                      data?.id ===
                                      userData?.profileData?.data?.id
                                  )?.languageCode
                                ]?.slice(0, 200) + " ..."}
                            </Typography>
                          ) : (
                            <Typography
                              width={"95%"}
                              sx={{ wordWrap: "break-word" }}
                            >
                              {item?.to?.findIndex(
                                (idData) =>
                                  idData?.id === userData?.profileData?.data?.id
                              ) !== -1 &&
                                item?.thread_original_message?.message?.slice(
                                  0,
                                  200
                                ) + " ..."}
                            </Typography>
                          )}
                        </Stack>
                      )}

                    {/* text message */}
                    <Box width={"100%"}>
                      {item?.translations &&
                      item?.translations[
                        item?.to?.find(
                          (data) => data?.id === userData?.profileData?.data?.id
                        )?.languageCode
                      ] ? (
                        <Typography
                          sx={{ wordWrap: "break-word" }}
                          width={"100%"}
                          variant="body2"
                        >
                          {!expanded &&
                            item?.to?.findIndex(
                              (idData) =>
                                idData?.id === userData?.profileData?.data?.id
                            ) !== -1 &&
                            item?.translations[
                              item?.to?.find(
                                (data) =>
                                  data?.id === userData?.profileData?.data?.id
                              )?.languageCode
                            ]
                              ?.slice(0, 200)
                              ?.split(/\n/)
                              ?.map((line, indx) => (
                                <span
                                  key={line + indx + "xyz007"}
                                  style={{
                                    marginBottom: "0.5rem",
                                    display: "block",
                                    border:
                                      line === ""
                                        ? "1px solid transparent"
                                        : "none",
                                  }}
                                >
                                  <>{line === "" ? " " : line}</>
                                </span>
                              ))}{" "}
                          {!expanded &&
                          item?.translations[
                            item?.to?.find(
                              (data) =>
                                data?.id === userData?.profileData?.data?.id
                            )?.languageCode
                          ]?.length > 200
                            ? " ..."
                            : ""}
                          <Collapse
                            sx={{ wordWrap: "break-word" }}
                            in={expanded}
                            timeout="auto"
                            unmountOnExit
                          >
                            {item?.to?.findIndex(
                              (idData) =>
                                idData?.id === userData?.profileData?.data?.id
                            ) !== -1 &&
                              item?.translations[
                                item?.to?.find(
                                  (data) =>
                                    data?.id === userData?.profileData?.data?.id
                                )?.languageCode
                              ]
                                ?.split(/\n/)
                                ?.map((line, indx) => (
                                  <span
                                    key={line + indx + "xyz008"}
                                    style={{
                                      marginBottom: "0.5rem",
                                      display: "block",
                                      border:
                                        line === ""
                                          ? "1px solid transparent"
                                          : "none",
                                    }}
                                  >
                                    <>{line === "" ? " " : line}</>
                                  </span>
                                ))}
                          </Collapse>
                          {item?.translations[
                            item?.to?.find(
                              (data) =>
                                data?.id === userData?.profileData?.data?.id
                            )?.languageCode
                          ]?.length > 200 && (
                            <Typography
                              width={"100%"}
                              expand={expanded}
                              onClick={() => setExpanded(!expanded)}
                              aria-expanded={expanded}
                              aria-label="show more"
                              sx={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              fontSize={"14px"}
                              display={"inline"}
                              color={"#2C86FF"}
                            >
                              {expanded ? "less" : "more"}
                            </Typography>
                          )}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ wordWrap: "break-word" }}
                          width={"100%"}
                          variant="body2"
                        >
                          {!expanded &&
                            item?.to?.findIndex(
                              (idData) =>
                                idData?.id === userData?.profileData?.data?.id
                            ) !== 1 &&
                            item?.message?.slice(0, 200) +
                              (item?.message?.length > 200 ? " ..." : "")}
                          <Collapse in={expanded} timeout="auto" unmountOnExit>
                            {item?.to?.findIndex(
                              (idData) =>
                                idData?.id === userData?.profileData?.data?.id
                            ) !== 1 &&
                              item?.message?.split(/\n/).map((line, indx) => (
                                <span
                                  key={line + indx + "xyz009"}
                                  style={{
                                    marginBottom: "0.5rem",
                                    display: "block",
                                    border:
                                      line === ""
                                        ? "1px solid transparent"
                                        : "none",
                                  }}
                                >
                                  {line}
                                </span>
                              ))}
                          </Collapse>

                          {item?.message?.length > 200 && (
                            <Typography
                              width={"100%"}
                              expand={expanded}
                              onClick={() => setExpanded(!expanded)}
                              aria-expanded={expanded}
                              aria-label="show more"
                              sx={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              fontSize={"14px"}
                              display={"inline"}
                              color={"#2C86FF"}
                            >
                              {expanded ? "less" : "more"}
                            </Typography>
                          )}
                        </Typography>
                      )}
                    </Box>

                    {/* -----------------subUser details------------------ */}
                    {item?.type !== 0 && item?.type != 3 && (
                      <>
                        <Stack
                          mt={"0.8rem"}
                          direction="row"
                          spacing={1}
                          gap={"2.5rem"}
                          width={"90%"}
                        >
                          <Stack direction="row" spacing={1}>
                            {item?.assignee?.pp ? (
                              <Box sx={classes.chatUserprofile}>
                                <img
                                  src={item?.assignee?.pp}
                                  alt="profile"
                                  height="100%"
                                />
                              </Box>
                            ) : (
                              <Box
                                bgcolor={"#E3E1E1"}
                                width={"27px"}
                                height={"27px"}
                                display={"flex"}
                                flex={"none"}
                                alignItems={"center"}
                                flex-wrap={"wrap"}
                                justifyContent={"center"}
                                borderRadius={"0.15rem"}
                              >
                                <Typography variant="h6" fontSize={"0.75rem"}>
                                  {item?.assignee?.name &&
                                    item?.assignee?.name[0]}
                                </Typography>
                              </Box>
                            )}
                            <Box>
                              <Box sx={classes.userInfo}>
                                <Stack direction="row" spacing={1}>
                                  <Typography
                                    variant="subtitle2"
                                    fontWeight={"400"}
                                    fontSize={"0.75rem"}
                                  >
                                    {item?.type === MessageType?.Approval
                                      ? "Approval"
                                      : "Task"}{" "}
                                    : {item?.assignee?.name}
                                  </Typography>
                                </Stack>
                                <Typography
                                  variant="subtitle2"
                                  lineHeight={"15px"}
                                  fontSize={"0.75rem"}
                                >
                                  {" "}
                                  Due on :{" "}
                                  {moment(item?.due_date).format(
                                    userData?.profileData?.data?.userDateFormat
                                  )}{" "}
                                  {moment(item?.due_time, "HH:mm").format(
                                    userData?.profileData?.data?.userTimeFormat
                                  )}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography fontSize={"0.75rem"}>
                                  {item?.type === MessageType?.Task
                                    ? "Task"
                                    : "Approval"}{" "}
                                  Priority :
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: "13px",
                                      height: "13px",
                                      verticalAlign: "middle",
                                      margin: "-1px 5px 0px 5px",
                                      backgroundColor:
                                        priorityValueColor[item?.priority],
                                    }}
                                  ></span>
                                  {priorityValueLable[item?.priority]}
                                </Typography>
                              </Box>
                            </Box>
                          </Stack>

                          <Stack direction="row" spacing={1}>
                            {item?.type === MessageType?.Approval && (
                              <>
                                <Box
                                  display={"flex"}
                                  sx={classes.pendingT}
                                  color={
                                    item?.approval_status ===
                                    ApprovalStatus?.Pending
                                      ? "#000000"
                                      : "#fff"
                                  }
                                  bgcolor={
                                    item?.approval_status ===
                                    ApprovalStatus?.Approved
                                      ? "#007F8F"
                                      : item?.approval_status ===
                                        ApprovalStatus?.Rejected
                                      ? "#4F63AF"
                                      : item?.approval_status ===
                                        ApprovalStatus?.Pending
                                      ? "#F5E120"
                                      : item?.approval_status ===
                                        ApprovalStatus?.Cancelled
                                      ? "#4F63AF"
                                      : "#FF4450"
                                  }
                                >
                                  <Box display={"flex"} alignItems={"center"}>
                                    <ApprovalSvg
                                      circleColor={
                                        item?.approval_status ===
                                        ApprovalStatus?.Pending
                                          ? null
                                          : "#fff"
                                      }
                                      tickColor={
                                        item?.approval_status ===
                                        ApprovalStatus?.Pending
                                          ? null
                                          : "#fff"
                                      }
                                    />
                                  </Box>
                                  <Typography sx={classes.txt}>
                                    {" "}
                                    {item?.approval_status ===
                                    ApprovalStatus?.Approved
                                      ? "Approved"
                                      : item?.approval_status ===
                                        ApprovalStatus?.Rejected
                                      ? "Rejected"
                                      : item?.approval_status ===
                                        ApprovalStatus?.Pending
                                      ? "Pending"
                                      : item?.approval_status ===
                                        ApprovalStatus?.Cancelled
                                      ? "Cancelled"
                                      : "Delayed"}
                                  </Typography>
                                </Box>
                                {(item?.from?.id ===
                                  userData?.profileData?.data?.id ||
                                  item?.assignee?.id ===
                                    userData?.profileData?.data?.id) &&
                                  item?.approval_status !==
                                    ApprovalStatus?.Approved &&
                                  item?.approval_status !==
                                    ApprovalStatus?.Rejected &&
                                  item?.approval_status !==
                                    ApprovalStatus?.Cancelled && (
                                    <Stack
                                      direction="row"
                                      sx={{
                                        ...classes.pendingT,
                                        cursor: "pointer",
                                      }}
                                      color={"#fff"}
                                      bgcolor={"#DB0011"}
                                      onClick={() =>
                                        handleClickTaskUpdate(item)
                                      }
                                    >
                                      <Typography sx={classes.txt}>
                                        {" "}
                                        Update{" "}
                                      </Typography>
                                    </Stack>
                                  )}
                              </>
                            )}
                            {item?.type === MessageType?.Task && (
                              <>
                                <Box
                                  display={"flex"}
                                  sx={classes.pendingT}
                                  color={
                                    item?.task_status === TaskStatus?.Pending
                                      ? "#000000"
                                      : "#fff"
                                  }
                                  bgcolor={taskStatusColor[item?.task_status]}
                                >
                                  <Box display={"flex"} alignItems={"center"}>
                                    <TaskSvg
                                      circleColor={
                                        item?.task_status ===
                                        TaskStatus?.Pending
                                          ? null
                                          : "#fff"
                                      }
                                      tickColor={
                                        item?.task_status ===
                                        TaskStatus?.Pending
                                          ? null
                                          : "#fff"
                                      }
                                    />
                                  </Box>
                                  <Typography sx={classes.txt}>
                                    {" "}
                                    {taskStatusLabels[item?.task_status]}{" "}
                                  </Typography>
                                </Box>

                                {item?.assignee?.id ===
                                  userData?.profileData?.data?.id &&
                                  item?.task_status === TaskStatus?.Pending && (
                                    <Stack
                                      direction="row"
                                      position={"relative"}
                                      sx={{
                                        ...classes.pendingT,
                                        cursor: "pointer",
                                      }}
                                      color={"#fff"}
                                      bgcolor={"#DB0011"}
                                      onClick={() => handleStartTask(item)}
                                    >
                                      {startTaskOpen?.timetoken ===
                                        item?.timetoken && (
                                        <StartTask
                                          open={
                                            startTaskOpen?.timetoken ===
                                            item?.timetoken
                                          }
                                          taskData={item}
                                          setStartTaskOpen={setStartTaskOpen}
                                        />
                                      )}
                                      <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                      >
                                        <TaskSvg
                                          circleColor={"#fff"}
                                          tickColor={"#fff"}
                                        />
                                      </Box>
                                      <Typography sx={classes.txt}>
                                        {" "}
                                        Start{" "}
                                      </Typography>
                                    </Stack>
                                  )}

                                {((item?.from?.id ===
                                  userData?.profileData?.data?.id &&
                                  item?.task_status !==
                                    TaskStatus?.Cancelled) ||
                                  (item?.assignee?.id ===
                                    userData?.profileData?.data?.id &&
                                    item?.task_status === TaskStatus?.Started &&
                                    item?.task_status !== TaskStatus?.Done &&
                                    item?.task_status !==
                                      TaskStatus?.Cancelled)) && (
                                  <Stack
                                    direction="row"
                                    sx={{
                                      ...classes.pendingT,
                                      cursor: "pointer",
                                    }}
                                    color={"#fff"}
                                    bgcolor={"#DB0011"}
                                    onClick={() => handleClickTaskUpdate(item)}
                                  >
                                    <Typography sx={classes.txt}>
                                      {" "}
                                      Update{" "}
                                    </Typography>
                                  </Stack>
                                )}
                              </>
                            )}
                          </Stack>
                        </Stack>
                        <Box ml={"2.18rem"} display={"flex"} gap={"0.8rem"}>
                          <Typography fontSize={"0.75rem"}>
                            Assigned :{" "}
                            {moment(Math.floor(item?.timetoken / 10000)).format(
                              userData?.profileData?.data?.userDateFormat
                            )}
                          </Typography>

                          {item?.start_time > 0 && (
                            <Typography fontSize={"0.75rem"}>
                              Started :{" "}
                              {moment(item?.start_time * 10000).format(
                                userData?.profileData?.data?.userDateFormat
                              )}
                            </Typography>
                          )}
                          {item?.end_time > 0 && (
                            <Typography fontSize={"0.75rem"}>
                              Finished :{" "}
                              {moment(item?.end_time * 10000).format(
                                userData?.profileData?.data?.userDateFormat
                              )}
                            </Typography>
                          )}
                        </Box>

                        {item?.estimated_time?.timeValue && (
                          <Box ml={"2.18rem"} display={"flex"} gap={"0.8rem"}>
                            <Typography fontSize={"0.75rem"}>
                              Estimated time : {item?.estimated_time?.timeValue}{" "}
                              {item?.estimated_time?.timeType}
                            </Typography>

                            {item?.actual_time?.timeValue && (
                              <Typography fontSize={"0.75rem"}>
                                Actual Time : {item?.actual_time?.timeValue}{" "}
                                {item?.actual_time?.timeType}
                              </Typography>
                            )}
                            {item?.diff_time?.timeValue && (
                              <Typography
                                color={
                                  item?.actual_time?.timeValue <
                                  item?.estimated_time?.timeValue
                                    ? "#007F8F"
                                    : "#DB0011"
                                }
                                fontSize={"0.75rem"}
                              >
                                {Math.abs(item?.diff_time?.timeValue)}{" "}
                                {item?.diff_time?.timeType}{" "}
                                {item?.actual_time?.timeValue <
                                item?.estimated_time?.timeValue
                                  ? "Less"
                                  : "More"}
                              </Typography>
                            )}
                          </Box>
                        )}
                      </>
                    )}
                    {/* -----------------Attachments------------------ */}
                    {item?.attachments?.length > 0 && (
                      <Box mt={"0.5rem"}>
                        {item?.attachments?.map((fileData) => (
                          <Link
                            href={fileData?.location}
                            underline="none"
                            target="_blank"
                            rel="noopener"
                            color={"black"}
                            key={fileData?.location}
                          >
                            {" "}
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              gap={"0.2rem"}
                              // bgcolor={"#F3F3F3"}
                            >
                              <img
                                style={{ height: "25px", width: "25px" }}
                                src={getFileIconUrl(fileData?.location)}
                                alt={fileData?.name}
                              />
                              <Typography
                                variant="subtitle2"
                                fontWeight={"400"}
                                fontSize={"0.75rem"}
                              >
                                {fileData?.name}
                              </Typography>
                            </Box>
                          </Link>
                        ))}
                      </Box>
                    )}

                    {/* Which Topic */}
                    <Stack direction="row" spacing={2} mt={"0.8rem"}>
                      <Box
                        display={"flex"}
                        gap={"0.2rem"}
                        alignItems={"center"}
                      >
                        <Typography fontSize={"0.75rem"}>Topic : </Typography>
                        <Typography
                          title={
                            groupTopicData?.data?.length > 0 &&
                            groupTopicData?.data?.find(
                              (data) => data?.id === item?.topic_id
                            )?.name
                          }
                          sx={{
                            ...classes.userlink2,
                            maxWidth: "60%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {groupTopicData?.data?.length > 0 &&
                            groupTopicData?.data?.find(
                              (data) => data?.id === item?.topic_id
                            )?.name}
                        </Typography>{" "}
                      </Box>
                    </Stack>

                    {/* Which Thread */}
                    {item?.replies > 0 && (
                      <Stack direction="row" spacing={2} mt={"0.4rem"}>
                        <Box
                          display={"flex"}
                          gap={"0.2rem"}
                          alignItems={"center"}
                        >
                          <Typography fontSize={"0.75rem"}>
                            Message thread :{" "}
                          </Typography>
                          <Box
                            onClick={() => handlegetThreadMessages(item, false)}
                          >
                            {item?.thread_heading && (
                              <Typography
                                sx={{ ...classes.userlink2, cursor: "pointer" }}
                              >
                                {item?.thread_heading} :
                              </Typography>
                            )}{" "}
                            <Typography
                              sx={{ ...classes.userlink2, cursor: "pointer" }}
                            >
                              {item?.replies} conversation
                            </Typography>{" "}
                          </Box>
                        </Box>
                      </Stack>
                    )}

                    {item?.thread_id && (
                      <Stack direction="row" spacing={2} mt={"0.4rem"}>
                        <Typography
                          onClick={() => {
                            dispatch(
                              handleThreadScrollToBottom(item?.thread_id)
                            );

                            handlegetThreadMessages(item, false);
                          }}
                          sx={{ ...classes.userlink2, cursor: "pointer" }}
                        >
                          View Message thread
                        </Typography>
                      </Stack>
                    )}
                  </Box>
                  {/* ------------Right section--------------- */}
                  <Box
                  // border={"1px solid"}
                  >
                    <Stack
                      // border={"1px solid"}
                      spacing={2}
                      direction="row"
                      alignItems={"center"}
                    >
                      <Box position={"relative"}>
                        <Box
                          ref={threeDotRef}
                          onClick={() => toggleItemMenu(item)}
                          sx={{
                            cursor: "pointer",
                            pointerEvents: isMoreMenuOpen ? "none" : "",
                          }}
                        >
                          <img src={three_dots} alt="more" />
                        </Box>
                        {isMoreMenuOpen?.timetoken === item?.timetoken && (
                          <MoreMenu
                            selectedGroup={selectedGroup}
                            isThreadScreen={isThreadScreen}
                            menuRef={menuRef}
                            messageData={item}
                            setMoreMenu={setMoreMenu}
                            isStarred={item?.isStarred}
                            handleReplyMessage={handlegetThreadMessages}
                            setDeleteGroupMsgOpen={setDeleteGroupMsgOpen}
                            setDeleteGroupMsgOpenIndicatorId={
                              setDeleteGroupMsgOpenIndicatorId
                            }
                            setMoveTopicTriggered={setMoveTopicTriggered}
                            setMoveTopicMsgId={setMoveTopicMsgId}
                            setisTopicOpen={setisTopicOpen}
                            handleReadStatusDrawer={handleReadStatusDrawer}
                          />
                        )}
                        <>
                          {isDeleteGroupMsgOpen &&
                            isDeleteGroupMsgOpenIndicatorId ===
                              item?.timetoken && (
                              <>
                                <InputDialogComponent
                                  title="Delete"
                                  isType="deletegroupmessage"
                                  open={isDeleteGroupMsgOpen}
                                  handleClose={() => {
                                    setDeleteGroupMsgOpen(false);
                                    setDeleteGroupMsgOpenIndicatorId(null);
                                  }}
                                  btnLabel="Delete"
                                  setDeleteGrpMsgType={setDeleteGrpMsgType}
                                  onClick={() => handleDeleteGrpMsg(item)}
                                  isallowedDeleteGrpMsgForall={
                                    (userData?.profileData?.data?.id ===
                                      item?.from?.id ||
                                      userData?.profileData?.data
                                        ?.activePermission ===
                                        AccountLevelArkchatPermission?.ADMIN) &&
                                    moment().diff(
                                      Math.floor(item?.timetoken / 10000),
                                      "minutes"
                                    ) <= 30
                                      ? false
                                      : true
                                  }
                                />
                              </>
                            )}
                        </>
                      </Box>

                      <Box
                        onClick={() => handleStarUnstarMsg(item?.timetoken)}
                        sx={{
                          cursor: "pointer",
                          width: "16px",
                          height: "16px",
                        }}
                      >
                        <img
                          width={"100%"}
                          height={"100%"}
                          src={starred_icon}
                          alt="more"
                        />
                      </Box>
                    </Stack>
                  </Box>
                  {/* ------------------------------------------ */}
                </Stack>
              </div>
            </Stack>
            <Box
              width={"85%"}
              margin={"auto"}
              border={"1px solid #ECECEC"}
            ></Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default StarMessageView;
