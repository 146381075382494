/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CarouselComponent from "../Carousel/Carousel";
import { useTranslation } from "react-i18next";

import { MuiOtpInput } from "mui-one-time-password-input";
import { AuthActionButton } from "../../../common/StyledComponents/AuthComponents";
import {
  otpEmailVarification,
  reSendOtp,
} from "../../../appStore/actions/auth/otp/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useTimer from "../../../customHooks/useTimer";
import { setCookie } from "../../../config/cookie";
import IntroVideo from "../../../common/IntroVideo";
import { fetchToken } from "../../../config/firebase.config";
import { showToastMsg } from "../../../appStore/actions/global/action";

const classes = {
  otpBox: {
    padding: "1.5rem 1rem 2rem 2rem",
    border: "1px solid #C4C4C4",
    height: "calc(100vh - 32px)",
    maxHeight: "700px",
    minHeight: "590px",
    width: "100%",
    maxWidth: "690px",
    margin: "auto",
    position: "relative",
    "@media only screen and (max-width:900px)": {
      margin: "auto !important",
      padding: "1.5rem 2rem 2rem 2rem !important",
    },
    "@media only screen and (max-width:1024px)": {
      height: "100%",
      padding: "1rem",
      marginLeft: "0px",
    },
    "@media only screen and (max-width:1300px)": {
      minHeight: "530px",
    },
  },
  captions: {
    fontSize: "0.87rem !important",
    display: "block",
  },
  verifyText: {
    color: "red !important",
  },
  verifyBtn: {
    position: "absolute",
    bottom: "2rem",
    left: "50%",
    marginLeft: "-2.72rem",
    "@media only screen and (max-width:899px)": {
      position: "relative",
      marginBottom: "-1.8rem",
      bottom: "0 !important",
    },
    "@media only screen and (max-width:1300px)": {
      bottom: "1.5rem",
    },
  },
  verifyemailWrapper: {
    display: "grid",
    alignItems: "center",
    "@media only screen and (max-width:899px)": {
      marginBottom: "25px",
    },

    "@media only screen and (min-width:1199px)": {
      paddingRight: "20px",
    },
  },
  mobscroll: {
    overflowX: "hidden",
    "@media only screen and (max-width:899px)": {
      overflowX: "hidden",
    },
  },
};
const VerifyEmail = () => {
  const [otp, setOtp] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const appContent = t("AppContent", { returnObjects: true });
  const [isForgotPassword, setIsForgotPassword] = useState(
    localStorage.getItem("forgotpassword") || false
  );
  const user = useSelector((state) => state?.profile);
  const [canResend, setCanResend] = useState(false);
  const [isRemember, setIsrememberme] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { time: remainingTime, resetTimer } = useTimer(60);

  useEffect(() => {
    if (remainingTime === 0) {
      setCanResend(true);
    }
  }, [remainingTime]);
  const handleRememberMe = (event) => {
    setIsrememberme(event.target.checked);
  };
  const handleChange = (newValue) => {
    setShowError(false);
    setOtp(newValue);
  };
  const handleSubmit = () => {
    if (otp) {
      setLoading(true);
      const formBody = {
        email: isForgotPassword
          ? user?.emailStore?.data
          : user?.profileData?.data?.email,
        otp,
        oldEmail: "",
        updateEmail: false,
        signIn: false,
        remember: isRemember,
      };
      //
      dispatch(otpEmailVarification(formBody)).then((res) => {
        if (!res?.status) {
          setLoading(false);
          setShowError(true);
        } else {
          fetchToken(res?.data?.id);
          setLoading(false);
          setShowError(false);
          if (isForgotPassword) {
            navigate("/createnewpassword");
          } else {
            if (isRemember) {
              setCookie("isRemember", true, 30);
            }
            const invitationData =
              JSON.parse(localStorage.getItem("invitationData")) || false;
            if (+invitationData?.inviteType === 1) {
              navigate("/individualprofile");
            } else {
              navigate("/profile");
            }
          }
        }
      });

      //
    }
  };
  const reSendEmail = () => {
    if (isForgotPassword && canResend) {
      const userEmail = { email: user?.emailStore?.data };
      dispatch(reSendOtp(userEmail)).then((res) => {
        if (res?.status) {
          dispatch(showToastMsg(res?.message));
          setCanResend(false);
          resetTimer();
        }
      });
    } else {
      const userEmail = { email: user?.profileData?.data?.email };
      dispatch(reSendOtp(userEmail)).then((res) => {
        if (res?.status) {
          dispatch(showToastMsg(res?.message));
          setCanResend(false);
          resetTimer();
        }
      });
    }
  };

  //
  return (
    <Box m={2}>
      <Grid container justifyContent="space-between" sx={classes.mobscroll}>
        <Grid item xs={12} md={5}>
          <IntroVideo />
        </Grid>
        <Grid item xs={12} md={6} sx={classes.verifyemailWrapper}>
          <Box sx={classes.otpBox}>
            <Typography fontSize={"1.7rem"} fontWeight={600} variant="h5">
              {appContent?.verifyEmail}
            </Typography>
            <Typography mt={"0.6rem"} sx={classes.captions} variant="caption">
              {appContent?.twoFactorEmailSentDescription}
            </Typography>
            <Typography mt={"0.2rem"} sx={classes.captions}>
              {isForgotPassword
                ? user?.emailStore?.data
                : user?.profileData?.data?.email}
            </Typography>

            <Box mt={"2.5rem"}>
              <Box mb={"0.6rem"}>
                <Typography sx={classes.captions}>
                  {appContent?.verificationCode}
                </Typography>
              </Box>
              <Box width={"20.5rem"}>
                <MuiOtpInput
                  TextFieldsProps={{ size: "small" }}
                  length={6}
                  value={otp}
                  onChange={handleChange}
                />
              </Box>
              {showError ? (
                <FormHelperText sx={classes.verifyText}>
                  {appContent?.twoFactorEnterValidCode}
                </FormHelperText>
              ) : (
                <></>
              )}

              <Box mt={"1rem"}>
                <Typography color={"#18324282"} variant="caption">
                  {appContent?.twoFactorDidntReceiveCode}{" "}
                  <Link
                    style={{
                      textDecoration: "none",
                      color: canResend ? "#59A0FF" : "#18324282",
                      cursor: canResend ? "pointer" : "not-allowed",
                    }}
                    onClick={reSendEmail}
                  >
                    {" "}
                    {appContent?.resendEmail}
                  </Link>{" "}
                  or{" "}
                  <Link
                    onClick={() => {
                      if (!isForgotPassword) {
                        localStorage.setItem("isChangeEmail", true);
                        navigate("/");
                      } else {
                        localStorage.setItem("isChangeEmail", true);
                        navigate("/forgetpassword");
                      }
                    }}
                    style={{
                      textDecoration: "none",
                      color: "#59A0FF",
                      cursor: "pointer",
                    }}
                  >
                    {appContent?.changeEmail}
                  </Link>
                </Typography>
              </Box>
              {!canResend && (
                <Box>
                  <Typography
                    fontSize={"0.75rem"}
                    color={"#18324282"}
                    variant="caption"
                  >
                    {appContent?.resendCodeTimerText} 00:
                    {`${String(remainingTime).padStart(2, "0")}`}
                  </Typography>
                </Box>
              )}
              <Box mt={"1.4rem"}>
                <Typography
                  fontWeight={400}
                  fontSize={"0.87rem"}
                  color={"#18324282"}
                  variant="caption"
                >
                  {appContent?.checkEmailFoldersSuggestion}
                </Typography>
              </Box>

              {!isForgotPassword && (
                <Box marginTop={"1.5rem"}>
                  <FormControlLabel
                    sx={{
                      ".MuiFormControlLabel-label": {
                        fontSize: "0.85rem",
                      },
                    }}
                    value={appContent?.keepsigned}
                    control={
                      <Checkbox
                        style={{ color: "#183243" }}
                        size="small"
                        onClick={handleRememberMe}
                      />
                    }
                    label={appContent?.keepsigned}
                    labelPlacement="end"
                  />
                </Box>
              )}

              <Box sx={classes.verifyBtn}>
                <AuthActionButton
                  onClick={() => (!isLoading ? handleSubmit() : "")}
                  type="submit"
                  variant="contained"
                  disabled={otp.length >= 6 ? false : true}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    appContent?.verify
                  )}
                </AuthActionButton>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VerifyEmail;
