import React from "react";
import { Box, Paper } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { useTranslation } from "react-i18next";


const classes = {
  arkcarousel: {
    display: "grid",
    alignItems: "center",
  },
  slide: {
    textAlign: "center",
    boxShadow: "none !important",
  },
  dot: {
    color: "rgba(24, 50, 66, 1) !important",
    zIndex: "1000 !important",
    position: "relative",
  },
  inactiveDot: {
    color: "rgba(0, 0, 0, 0.32) !important",
    zIndex: "1000 !important",
    position: "relative",
  },
};

const Image = ({ images }) => {
  const { t } = useTranslation();

  return (
    <Box bgcolor={"#F3F3F3"}>
      <Box sx={classes.arkcarousel}>
        <Carousel
          autoPlay={false}
          interval={null}
          swipe={false}
          animation="fade"
          activeIndicatorIconButtonProps={{ sx: classes.dot }}
          indicatorIconButtonProps={{
            sx: classes.inactiveDot,
          }}
          // navButtonsAlwaysVisible={images?.length>1}
          navButtonsWrapperProps={{
            style: images?.length>1 ? {} : { display: "none" },
          }}
        >
          {images?.length>0&&images?.map((slide, index) => (
            <Paper key={index+"Imgjsx"} sx={classes.slide}>
              <Box sx={classes.cardImage}>
                <img
                  style={{display:"block", height: "100%", width: "100%", objectFit: "cover" }}
                  src={slide?.location}
                  alt="Slide"
                />
              </Box>
             
            </Paper>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default Image;
