export const REQUEST_CREATE_GROUP = "group/REQUEST_CREATE_GROUP";
export const CREATE_GROUP_SUCCESS = "group/CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_ERROR = "group/CREATE_GROUP_ERROR";
export const REQUEST_CREATE_FIRST_GROUP = "group/REQUEST_CREATE_FIRST_GROUP";
export const CREATE_FIRST_GROUP_ERROR = "group/CREATE_FIRST_GROUP_ERROR";
export const REQUEST_ALL_MEMBERS_WITH_GROUP =
  "group/REQUEST_ALL_MEMBERS_WITH_GROUP";

export const REQUEST_ALL_GROUP = "group/REQUEST_ALL_GROUP";
export const RECEIVED_ALL_GROUP_SUCCESS = "group/RECEIVED_ALL_GROUP_SUCCESS";
export const RECEIVED_ALL_GROUP_ERROR = "group/RECEIVED_ALL_GROUP_ERROR";
export const REQUEST_ALL_MEMBERS_WITH_GROUP_ERROR =
  "group/REQUEST_ALL_MEMBERS_WITH_GROUP_ERROR";

export const REQUEST_EDIT_GROUP_NAME = "group/REQUEST_EDIT_GROUP_NAME";
export const EDIT_GROUP_NAME_SUCCESS = "group/EDIT_GROUP_NAME_SUCCESS";

export const REQUEST_GROUP_MEMBER_DETAILS =
  "group/REQUEST_GROUP_MEMBER_DETAILS";
export const RECEVED_GROUDP_MEMBER_SUCCESS =
  "group/RECEVED_GROUDP_MEMBER_SUCCESS";
export const GROUDP_RECIPIENT_DETAILS="GROUDP_RECIPIENT_DETAILS";
export const RECEIVED_ONBOARDING_ACCOUNT_MEMBER =
  "group/RECEIVED_ONBOARDING_ACCOUNT_MEMBER";
export const RECEIVED_USER_WITH_PERMISSION =
  "group/RECEIVED_USER_WITH_PERMISSION";

export const RECEIVED_GROUP_MESSAGE_LIST = "group/RECEIVED_GROUP_MESSAGE_LIST";
export const RECEIVED_GROUP_INSTANT_MESSAGE_LIST =
  "group/RECEIVED_GROUP_INSTANT_MESSAGE_LIST";
export const RECEIVED_OLD_MESSAGE_LIST = "group/RECEIVED_OLD_MESSAGE_LIST";
export const UPDATE_EXISTING_MESSAGE_LIST =
  "group/UPDATE_EXISTING_MESSAGE_LIST";
export const RECEIVED_TEMP_MESSAGE_LIST = "group/RECEIVED_TEMP_MESSAGE_LIST";
export const REQUEST_GET_ALLSTAR_MESSAGE = "group/REQUEST_GET_ALLSTAR_MESSAGE";
export const RECEIVED_ALL_ATTACHMENTS = "file/RECEIVED_ALL_ATTACHMENTS";

export const RECEIVED_THREAD_MESSAGES = "group/RECEIVED_THREAD_MESSAGES";
export const RECEIVED_GROUP_INSTANT_THREAD_MESSAGE_LIST =
  "group/RECEIVED_GROUP_INSTANT_THREAD_MESSAGE_LIST";
export const UPDATE_EXISTING_THREAD_MESSAGE =
  "group/UPDATE_EXISTING_THREAD_MESSAGE";
  export const DELETE_EXISTING_THREAD_MESSAGE =
  "group/DELETE_EXISTING_THREAD_MESSAGE";
// export const CREATE_GROUP_SUCCESS = 'group/CREATE_GROUP_SUCCESS';
// export const CREATE_GROUP_ERROR = 'group/CREATE_GROUP_ERROR';

export const RECEIVED_GROUP_FILTER = "group/RECEIVED_GROUP_FILTER";

export const RECEIVED_ALL_GROUP_DETAILS = "group/RECEIVED_ALL_GROUP_DETAILS";

export const RECEIVED_ALL_TASKLIST = "task/RECEIVED_ALL_TASKLIST";
export const RECEIVED_OLD_TASKLIST_DATA = "task/RECEIVED_OLD_TASKLIST_DATA";
export const UPDATE_EXISTING_TASKLIST_DATA =
  "task/UPDATE_EXISTING_TASKLIST_DATA";
export const UPDATE_EXISTING_TASK_LIST = "UPDATE_EXISTING_TASK_LIST";

export const RECEIVED_ALL_APPROVALLIST = "approval/RECEIVED_ALL_APPROVALLIST";
export const RECEIVED_OLD_APPROVALLIST_DATA =
  "approval/RECEIVED_OLD_APPROVALLIST_DATA";
export const UPDATE_EXISTING_APPROVALLIST_DATA =
  "approval/UPDATE_EXISTING_APPROVALLIST_DATA";
export const UPDATE_EXISTING_APPROVALLIST_LIST =
  "UPDATE_EXISTING_APPROVALLIST_LIST";
export const RECEIVED_ALL_TASKANALYTICS_DATA =
  "RECEIVED_ALL_TASKANALYTICS_DATA";
export const RECEIVED_ALL_APPROVALANALYTICS_DATA =
  "RECEIVED_ALL_APPROVALANALYTICS_DATA";

export const GET_ALL_BRAND_LIST_DONE = "GET_ALL_BRAND_LIST_DONE";
export const GET_ALL_BRAND_LIST_ERROR = "GET_ALL_BRAND_LIST_ERROR";

export const GET_ALL_ARTISTS_LIST_DONE = "GET_ALL_ARTISTS_LIST_DONE";
export const GET_ALL_ARTISTS_LIST_ERROR = "GET_ALL_ARTISTS_LIST_ERROR";

export const CREATE_POST_DONE = "CREATE_POST_DONE";
export const CREATE_POST_ERROR = "CREATE_POST_ERROR";

export const GET_ALL_POST_LIST_DONE = "GET_ALL_POST_LIST_DONE";
export const GET_ALL_POST_LIST_ERROR = "GET_ALL_POST_LIST_ERROR";

export const GET_ALL_POST_LIST_EXPLORE_DONE = "GET_ALL_POST_LIST_EXPLORE_DONE";
export const GET_OLD_POST_DATA_EXPLORE_DONE = "GET_OLD_POST_DATA_EXPLORE_DONE"
export const GET_ALL_POST_LIST_EXPLORE_ERROR =
  "GET_ALL_POST_LIST_EXPLORE_ERROR";
export const EDIT_POST_LIST_EXPLORE = "EDIT_POST_LIST_EXPLORE";
export const GET_LIKE_USER_LIST="GET_LIKE_USER_LIST";

export const GET_ALL_SELLER_TAB_POSTS = "GET_ALL_SELLER_TAB_POSTS";
export const EDIT_SELLER_TAB_POSTS = "EDIT_SELLER_TAB_POSTS";

export const GET_AI_HISTORY_DONE = "GET_AI_HISTORY_DONE";
export const GET_QUERY_ANSWER_DONE = "GET_QUERY_ANSWER_DONE";
export const GET_EDIT_ANSWER_DONE = "GET_EDIT_ANSWER_DONE";
export const CLEAR_CURRENT_AI_SESSION_DATA = "CLEAR_CURRENT_AI_SESSION_DATA";
