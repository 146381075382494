import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  Badge,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { switchAccount } from "../../appStore/actions/subscriber/action";
import { getProfile } from "../../appStore/actions/profile/action";
import { requestallmemberswithgroups } from "../../appStore/actions/ArkchatGroup/action";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import headerNotificationIcon from "../../assets/images/headerNotificationIcon.svg";
import { removeActivityIndicator } from "../../appStore/actions/global/action";
import AlertPopUp from "../../common/AlertPopUp";
import { handleAccountSwitchLoading, handleSwitchAccountRefresh } from "../../appStore/actions/AppState/action";
const theme = createTheme({
  palette: {
    primary: {
      main: "#DB0011",
    },
  },
});
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const SwitchAccount = ({
  setSwitchAccountDrawerOpen,
  setCreateNewOrgAccountDrawerOpen,
}) => {
  const endpoint = window.location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isTermConditionChecked, setTermConditionChecked] = useState(false);
  const [isAlertModalOpen, setAlertModalOpen] = useState(false);
  const userData = useSelector((state) => state?.profile?.profileData);
  const indicatorData = useSelector((state) => state?.global?.indicatorData);
  const switchAccountRefresh = useSelector(
    (state) => state?.appStateData?.isSwitchAccountRefresh
  );

  const handleRedirection = (response, isAtchat) => {
    if (isAtchat) {
      if (response?.accountOwner) {
        if (response?.isFirstGroupCreated && response?.isFirstInviteSent) {
          dispatch(handleSwitchAccountRefresh(!switchAccountRefresh));
        } else if (
          response?.isFirstGroupCreated &&
          !response?.isFirstInviteSent
        ) {
          navigate("/invite");
        } else {
          navigate("/createfirstgroup");
        }
      } else {
        dispatch(handleSwitchAccountRefresh(!switchAccountRefresh));
      }
    } else {
      if (response?.accountOwner) {
        if (response?.isFirstGroupCreated && response?.isFirstInviteSent) {
          navigate("/chat");
        } else if (
          response?.isFirstGroupCreated &&
          !response?.isFirstInviteSent
        ) {
          navigate("/invite");
        } else {
          navigate("/createfirstgroup");
        }
      } else {
        navigate("/chat");
      }
    }
  };

  const handleAccountSwitch = (subscriber_id) => {
    if (userData?.data?.activeSubscriber?.id !== subscriber_id) {
      const data = {
        switch_subscriber_id: subscriber_id,
      };
      dispatch(switchAccount(data)).then((res) => {
        dispatch(handleAccountSwitchLoading(true));
        if (res?.status) {
          if (indicatorData?.length > 0) {
            dispatch(
              removeActivityIndicator(userData?.data?.id, subscriber_id)
            );
          }
          dispatch(getProfile()).then((Profileres) => {
            if (Object?.keys(Profileres).length > 0) {
              setSwitchAccountDrawerOpen(false);
              if (endpoint !== "/chat") {
                handleRedirection(Profileres, false);
              } else {
                handleRedirection(Profileres, true);
              }
            }
          });
        }else{
          dispatch(handleAccountSwitchLoading(false));
        }
      });
    }
  };

  const handleClick = () => {
    if (isTermConditionChecked) {
      setCreateNewOrgAccountDrawerOpen(true);
    } else {
      setAlertModalOpen(true);
    }
  };
  return (
    <Box sx={classes.drawerBody}>
      <AlertPopUp
        title={"Alert"}
        bodyContent={"Please Accept Terms and Condition."}
        open={isAlertModalOpen}
        closeModal={setAlertModalOpen}
      />
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => setSwitchAccountDrawerOpen(false)}
          />
        </Typography>
        <Typography color={"black"}>Switch</Typography>
      </Stack>

      <>
        <Box width={"95%"} ml={"0.5rem"} mt={"1rem"}>
          <Box
            overflow={"auto"}
            maxHeight={"380px"}
            width={"100%"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "3px",
                padding: "0px 2px",
              },
            }}
          >
            {userData?.data?.subscribedTo?.map((data) => (
              <Box
                key={data?.id + "switchaccount"}
                onClick={() => handleAccountSwitch(data?.id)}
                bgcolor={
                  // eslint-disable-next-line eqeqeq
                  userData?.data?.activeSubscriber?.id == data?.id
                    ? "#C4C4C4"
                    : "#E1E1E1"
                }
                sx={{
                  cursor: "pointer",
                  borderRadius: "2px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                mb={"0.72rem"}
                p={"0.5rem 1rem"}
                color={
                  // eslint-disable-next-line eqeqeq
                  userData?.data?.activeSubscriber?.id == data?.id
                    ? "#183243"
                    : "black"
                }
              >
                <Box justifyContent={"flex-end"}>
                  <Typography fontSize={"0.9rem"} fontWeight={400}>
                    {data?.subscriberFirmName}
                  </Typography>
                  <Typography variant="caption">
                    {data?.subscriberAccountType}
                  </Typography>
                </Box>

                {indicatorData?.length > 0 &&
                  indicatorData?.includes(data?.id) && (
                    <Box>
                      <ThemeProvider theme={theme}>
                        <Badge
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          color="primary"
                          variant="dot"
                          invisible={indicatorData?.length === 0}
                        >
                          <img
                            style={{ width: "70%" }}
                            src={headerNotificationIcon}
                            alt="icon"
                          />
                        </Badge>
                      </ThemeProvider>
                    </Box>
                  )}
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          position={"absolute"}
          left={"11.5%"}
          bottom={"1.5rem"}
          width={"80%"}
        >
          <Box display={"flex"}>
            <FormControlLabel
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: "0.85rem",
                },
              }}
              control={
                <Checkbox
                  onChange={(e) => setTermConditionChecked(e.target.checked)}
                  size="medium"
                  sx={{ "& .MuiSvgIcon-root": { color: "#183243" } }}
                />
              }
              // label="To add a new organisation account, I accept the Arkchat Terms of Service"
              labelPlacement="end"
            />
            <Typography>
              To add a new organisation account, I accept the Arkchat{" "}
              <Link
                href={"https://www.arkchat.com/terms-of-service/"}
                underline="none"
                target="_blank"
                rel="noopener"
              >
                Terms of Service
              </Link>
            </Typography>
          </Box>
          <Box marginTop={"2rem"} textAlign={"center"}>
            <Button
              sx={{
                borderColor: "#585858",
                color: "#000000",
                textTransform: "none",
              }}
              variant="outlined"
              onClick={handleClick}
            >
              Add organisation account
            </Button>
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default SwitchAccount;
