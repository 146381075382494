import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getLanguage } from "../../../appStore/actions/masterData/masterDataAction";
import { profileLanguageUpdate } from "../../../appStore/actions/ArkchatGroup/action";
import searchIcon from "../../../assets/images/chats/searchIcon.svg";
import selectTick_Icon from "../../../assets/images/chats/selectTick_Icon.svg";
import {
  CustomInput,
  SearchBox,
  SmallIcon,
} from "../../../common/chatComponents/ScreenSearchSection";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  saveButton: {
    backgroundColor: "#DB0011",
    color: "#fff",
    padding: "0.4rem 1.5rem",
    textTransform: "none",
    borderRadius: "0.15rem",
    ":hover": {
      backgroundColor: "#DB0011",
      color: "#fff",
    },
  },
  cancelBtn: {
    bgcolor: "white",
    color: "#000000",
    border: "1px solid #DB0011",
    padding: "0.4rem 1.5rem",
    textTransform: "none",
    borderRadius: "0.15rem",
    ":hover": {
      bgcolor: "white",
      color: "#000000",
      border: "1px solid #DB0011",
    },
  },
};
const LanguageList = ({ handleLanguageCloseMenu }) => {
  const selectedGroup = useSelector(
    (state) => state?.appStateData?.selectedGroupData
  );
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const languageListData = useSelector((state) => state?.languages);
  const userData = useSelector((state) => state?.profile);
  let filteredData = languageListData?.languageList?.data?.chatLanguages || [];
  filteredData = filteredData?.filter((el) =>
    el.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  useEffect(() => {
    dispatch(getLanguage());
  }, [dispatch]);
  //
  const handleSelectLanguage = (data) => {
    setSelectedLanguage(data);
  };
  const handleCloseTrigger = () => {
    setSelectedLanguage({});
    handleLanguageCloseMenu();
  };
  const handleUpdateLanguage = () => {
    if (selectedLanguage) {
      const formdata = {
        language_code: selectedLanguage?.code,
        language: selectedLanguage?.name,
      };

      dispatch(profileLanguageUpdate(formdata, selectedGroup?.isGroup?selectedGroup?.id:0));
      setSelectedLanguage({});
      handleLanguageCloseMenu();
    }
  };
  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={classes.header}
          >
            <Typography sx={classes.iconDiv}>
              <CloseIcon sx={classes.iconn} onClick={handleCloseTrigger} />
            </Typography>
            <Typography color={"black"}>Chat Language</Typography>
          </Stack>

          <Box
            width={"90%"}
            display={"flex"}
            justifyContent={"flex-end"}
            margin={"auto"}
            mt={"0.5rem"}
          >
            <Box width={"100%"}>
              <SearchBox>
                <CustomInput
                  autoFocus
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search"
                  style={{ padding: "0.2rem 0rem" }}
                />
                <SmallIcon src={searchIcon} />
              </SearchBox>
            </Box>
          </Box>

          <Box width={"90%"} margin={"auto"} mt={"0.5rem"}>
            <Typography>
              Currently Your Chat Language is{" "}
              <b>{userData?.profileData?.data?.language}</b>
            </Typography>
          </Box>

          <Stack
            width={"90%"}
            margin={"auto"}
            mt={"0.4rem"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "3px",
              },
            }}
            maxHeight={"70vh"}
            overflow={"auto"}
          >
            {filteredData?.map((item) => {
              return (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                  padding="0.62rem"
                  border={"1px solid white"}
                  key={item?.code + "languageList"}
                >
                  <Stack
                    width={"85%"}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleSelectLanguage(item)}
                  >
                    <Typography variant="subtitle2">{item?.name}</Typography>
                    {selectedLanguage &&
                      selectedLanguage?.code === item?.code && (
                        <SmallIcon src={selectTick_Icon} />
                      )}

                    {userData?.profileData?.data?.languageCode === item?.code &&
                      !selectedLanguage?.name && (
                        <SmallIcon src={selectTick_Icon} />
                      )}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          gap={"0.5rem"}
          position={"absolute"}
          bottom={"1.4rem"}
        >
          <Button
            sx={classes?.cancelBtn}
            variant="contained"
            onClick={handleLanguageCloseMenu}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdateLanguage}
            sx={classes?.saveButton}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default LanguageList;
