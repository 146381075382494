import axios from "axios";
import {
  CREATE_TOPIC_DONE,
  CREATE_TOPIC_URL,
  DELETE_TOPIC_DONE,
  DELETE_TOPIC_ERROR,
  DELETE_TOPIC_URL,
  GET_TOPIC_DONE,
  GET_TOPIC_ERROR,
  GET_TOPIC_URL,
  UPDATE_TOPIC_DONE,
  UPDATE_TOPIC_ERROR,
  UPDATE_TOPIC_URL,
} from ".";
import { REQUEST_FAILED } from "../global/type";
import { getCookie } from "../../../config/cookie";
import { getGroupTopicData, storeGroupTopicData } from "../../../config/db";
import { showToastMsg } from "../global/action";
//  GET ALL THE TPOICS

export const updateTpoicDataDb = async (groupId, abortSignal) => {
  try {
    let API_URL = GET_TOPIC_URL;
    let TOKEN = getCookie("token");

    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
    };

    if (abortSignal) {
      requestOptions.signal = abortSignal;
    }

    const response = await axios.post(API_URL, groupId, requestOptions);

    if (response.status === 200) {
      const subscriber_id = getCookie("subsCriber_id");
      await storeGroupTopicData(
        subscriber_id,
        groupId?.group_id,
        response?.data?.data
      );
      return response?.data?.data;
    }
  } catch (err) {
    return false;
  }
};


export const getAlltopic = (groupId, forceFetch = false, abortSignal) => {
  let API_URL = GET_TOPIC_URL;
  let TOKEN = getCookie("token");
  return async (dispatch) => {
    try {
      const subscriber_id = getCookie("subsCriber_id");
      const groupTopicData = await getGroupTopicData(
        subscriber_id,
        groupId?.group_id
      );

      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      };

      if (abortSignal) {
        requestOptions.signal = abortSignal;
      }

      if (groupTopicData?.length > 0 && !forceFetch) {
        dispatch({
          type: GET_TOPIC_DONE,
          payload: groupTopicData[0]?.topicData,
        });

        updateTpoicDataDb(groupId, abortSignal).then((updatedData) => {
          if (updatedData) {
            dispatch({
              type: GET_TOPIC_DONE,
              payload: updatedData,
            });
          }
        });
      } else {
        const response = await axios.post(API_URL, groupId, requestOptions);
        if (response.status === 200) {
          await storeGroupTopicData(
            subscriber_id,
            groupId?.group_id,
            response?.data?.data
          );
          dispatch({
            type: GET_TOPIC_DONE,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: GET_TOPIC_ERROR,
            payload: response,
          });
          return response?.data;
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        //
      } else {
        dispatch({ type: REQUEST_FAILED, payload: error?.response });
      }
    }
  };
};


//  CREATE A NEW TOPIC
export const createTopic = (formdata) => {
  let API_URL = CREATE_TOPIC_URL;
  let TOKEN = getCookie("token");
  return (dispatch) => {
    return axios
      .post(API_URL, formdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: CREATE_TOPIC_DONE,
          });
          dispatch(showToastMsg(res?.data?.message||res?.message));
          return res?.data;
        } else {
          dispatch(showToastMsg(res?.data?.message||res?.message));
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};

// UPDATE A TOPIC
export const updateTopic = (topicData, topic_id) => {
  let API_URL = `${UPDATE_TOPIC_URL}/${topic_id}`;
  let TOKEN = getCookie("token");
  return (dispatch) => {
    return axios
      .put(API_URL, topicData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => {
        if (res.code == 200) {
          dispatch({
            type: UPDATE_TOPIC_DONE,
          });
          return res?.data;
        } else {
          dispatch({
            type: UPDATE_TOPIC_ERROR,
          });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};

// DELETE A TOPIC
export const deleleTopic = (topic_id) => {
  let API_URL = `${DELETE_TOPIC_URL}/${topic_id}`;
  let TOKEN = getCookie("token");
  return (dispatch) => {
    return axios
      .delete(API_URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => {
        if (res.code == 200) {
          dispatch({
            type: DELETE_TOPIC_DONE,
          });
          return res?.data;
        } else {
          dispatch({
            type: DELETE_TOPIC_ERROR,
          });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};
