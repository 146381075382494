import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createStyledIndicatorButton } from "../../../common/StyledComponents/SideBarComponents";
import filesvg from "../../../assets/images/chats/file_svg.svg";
import { SmallIcon } from "../../../common/chatComponents/ScreenSearchSection";
import editTopics from "../../../assets/images/editTopic.svg";
import { useDispatch, useSelector } from "react-redux";
import { getFileIconUrl, isGoogleYoutubeUrl } from "../../../utils/findextension";
import InputDialogComponent from "../../../components/InputDialog";
import {
  getAttachments,
  handleEditFileName,
  handleMediaDownload,
} from "../../../appStore/actions/ArkchatGroup/action";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};

const MediaFiles = ({ setMediaFilesOpen }) => {
  const EditButton = createStyledIndicatorButton("#E3E1E1");
  const [isEditFileOpen, setEditFileOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [oldFileName, setOldFileName] = useState("");
  const [newFileName, setNewFileName] = useState("");
  const selectedGroup = useSelector(
    (state) => state?.appStateData?.selectedGroupData
  );
  const selectedTopic = useSelector(
    (state) => state?.appStateData?.selectedTopicData
  );
  const threadMsgList = useSelector(
    (state) => state?.arkchatgroup?.threadMsgList
  );
  const isThreadScreen = useSelector(
    (state) => state?.localAppReducer?.isThreadScreen
  );
  const dispatch = useDispatch();

  let fileListData = useSelector(
    (state) => state?.arkchatgroup?.attachmentData
  );

  const handleGetFiles=()=>{
    if (selectedGroup?.isGroup) {
      if (!isThreadScreen) {
        if (selectedTopic) {
          dispatch(
            getAttachments(
              `${selectedGroup?.pnId}?topicId=${selectedTopic?.id}`
            )
          );
        } else {
          dispatch(getAttachments(`${selectedGroup?.pnId}`));
        }
      } else {
        dispatch(
          getAttachments(
            `${selectedGroup?.pnId}?threadId=${threadMsgList[0]?.id}`
          )
        );
      }
    } else {
      dispatch(getAttachments(`${selectedGroup?.pnId}`));
    }
  }

  useEffect(() => {
    handleGetFiles()
  }, []);

  const handleSaveNewName = (id) => {
    const reqBody = {
      msg_id: id,
      old_name: oldFileName,
      new_name: newFileName,
    };
    dispatch(handleEditFileName(reqBody, id)).then((res) => {
      if (res?.status) {
        handleGetFiles();
        setEditFileOpen(false);
      }
    });
    setEditFileOpen(false);
    setSelectedFileId(null);
    setOldFileName("");
    setNewFileName("");
  };

  const handleFileDownload = (fileData) => {
    if (!isGoogleYoutubeUrl(fileData?.location)) {
      const reqBody = {
        location: fileData?.location,
        filename: fileData?.name,
      };
      dispatch(handleMediaDownload(reqBody)).then((response) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = response;
        downloadLink.download = fileData?.name;
        downloadLink.click();
      });
    } else {
      // Open the Google or YouTube link in a new tab
      window.open(fileData?.location, '_blank');
    }
  };
  
  return (
    <Box sx={classes?.drawerBody}>
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={classes.header}
          onClick={() => setMediaFilesOpen(false)}
        >
          <Typography sx={classes.iconDiv}>
            <CloseIcon sx={classes.iconn} />
          </Typography>
          <Typography color={"black"}>Files</Typography>
        </Stack>
        <Stack
          sx={{
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "3px",
            },
          }}
          m={"0.7rem"}
          maxHeight={"77vh"}
          overflow={"auto"}
        >
          <>
            {fileListData?.attachments?.length > 0 &&
              fileListData?.attachments?.map((item) => (
                <Stack
                  mb={"0.4rem"}
                  direction={"row"}
                  alignItems={"center"}
                  spacing={1}
                  sx={{ cursor: "pointer" }}
                  key={item?.id + "attachmets" + item?.name}
                >
                  {/* <Box width={"21%"}>
                                <Typography>
                                  {moment(data?.createdAt).format(
                                    userData?.profileData?.data?.dateFormat
                                  )}
                                </Typography>
                              </Box> */}
                  <Stack
                    bgcolor={"#F2F2F2"}
                    width={"73%"}
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    padding={"0.2rem 0.5rem"}
                    mb={"8px"}
                  >
                    <Box>
                      <img
                        style={{ height: "28px", width: "28px" }}
                        src={getFileIconUrl(item?.location)}
                        alt="logo"
                      />
                    </Box>
                    <Box width={"70%"} onClick={() => handleFileDownload(item)}>
                      <Typography
                        variant="h5"
                        fontSize={"0.8rem"}
                        fontWeight={400}
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        title={item?.name}
                      >
                        {item?.name}
                      </Typography>
                    </Box>
                    <>
                      {isEditFileOpen && selectedFileId === item?.id && (
                        <InputDialogComponent
                          title={"Edit File Name"}
                          isType={"editFileName"}
                          btnLabel="Save"
                          value={newFileName}
                          onChange={(e) => setNewFileName(e.target.value)}
                          open={isEditFileOpen}
                          handleClose={() => {
                            setEditFileOpen(false);
                            setSelectedFileId(null);
                            setOldFileName("");
                            setNewFileName("");
                          }}
                          onClick={() =>
                            handleSaveNewName(
                              item?.id
                              // data?.conversation_id
                            )
                          }
                        />
                      )}
                      <EditButton
                        onClick={() => {
                          setEditFileOpen(!isEditFileOpen);
                          setSelectedFileId(item?.id);
                          setOldFileName(item?.name);
                          setNewFileName(item?.name);
                        }}
                        sx={{
                          border: "1px solid black",
                          bgcolor: "transparent",
                        }}
                        size="small"
                        variant="contained"
                      >
                        <SmallIcon src={editTopics} />
                      </EditButton>
                    </>
                  </Stack>
                </Stack>
              ))}
          </>

          {fileListData?.attachments?.length === 0 && (
            <Box mt={"48%"}>
              <Box width={"25%"} margin={"auto"} mb={"0.7rem"}>
                <img style={{ width: "100%" }} src={filesvg} alt="icon" />
              </Box>
              <Box textAlign={"center"}>
                <Typography variant="h6" fontSize={"1rem"}>
                  There are no files in this group's Topic
                </Typography>
              </Box>
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default MediaFiles;
