import { useState, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { Button, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";

const PaymentStatus = () => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Success! Payment received.");
          break;

        case "processing":
          setMessage(
            "Payment processing. We'll update you when payment is received."
          );
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage("Payment failed. Please try another payment method.");
          break;

        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  return (
    <Container maxWidth="sm" style={{ marginTop: "2rem" }}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid item>
          <Typography variant="h4" align="center" gutterBottom>
            {message}
          </Typography>
        </Grid>
        {/* <Grid item>
          <Typography variant="body1" align="center" paragraph>
            Thank you for your payment. Your transaction has been completed
            successfully.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" align="center" color="textSecondary">
            Transaction ID: #1234567890{" "}
           
          </Typography>
        </Grid> */}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/chat", { replace: true })}
          >
            {" "}
            {/* Redirect to homepage */}
            Go to Home
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaymentStatus;
