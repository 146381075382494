// Carousel style
export const carouselStyle = {
    main: {
      padding: "0rem 1rem",
      height: "calc(100vh - 32px)",
    },
    mainHeightCond: {
      padding: "0rem 1rem",
      height: "calc(100vh - 65px)",
    },
  };