import { eraseCookie, setCookie } from "../../../config/cookie";
import { clearAllDatabases } from "../../../config/db";
import {
  EMAIL_STORE,
  REMOVE_SET_CURRENT_USER_LOGEDIN,
  SET_CURRENT_USER_LOGEDIN,
} from "../../actions/auth/login";
import { VERIFY_OTP_DONE } from "../../actions/auth/otp";
import { SIGNUP_DONE } from "../../actions/auth/signup";
import {
  PROFILE_DONE,
  PROFILE_DONE_ERROR,
  UPDATE_PROFILE_DONE,
} from "../../actions/profile";
import {
  AUTH_401_REDIRECT,
  SET_TOKEN_DONE,
  VERIFY_EMAIL_OTP_DONE,
} from "../auth";

const initState = {
  data: [],
  error: null,
  status: null,
  token: null,
  emailStore: null,
  shouldRedirect: false,
};

export const profileReducer = (state = initState, action) => {
  switch (action.type) {
    case EMAIL_STORE:
      return {
        ...state,
        emailStore: {
          data: action?.payload,
          error: null,
        },
        shouldRedirect: false,
      };

    case SET_CURRENT_USER_LOGEDIN:
      setCookie("token", action?.payload?.data?.token, 30);
      setCookie("user_Id", action?.payload?.data?.id, 30);
      return {
        ...state,
        profileData: {
          data: action?.payload?.data,
          status: action?.payload?.data?.status,
          error: null,
        },
        shouldRedirect: false,
      };
    case SIGNUP_DONE:
      setCookie("token", action?.payload?.data?.token, 30);
      setCookie("user_Id", action?.payload?.data?.id, 30);
      return {
        ...state,
        profileData: {
          data: action?.payload?.data,
          status: action?.payload?.data?.status,
          error: null,
        },
        shouldRedirect: false,
      };
    case VERIFY_OTP_DONE:
      setCookie("token", action?.payload?.data?.token, 30);
      setCookie("user_Id", action?.payload?.data?.id, 30);
      return {
        ...state,
        profileData: {
          data: action?.payload?.data,
          status: action?.payload?.data?.status,
          error: null,
        },
        shouldRedirect: false,
      };

    case REMOVE_SET_CURRENT_USER_LOGEDIN:
      eraseCookie("token");
      eraseCookie("isRemember");
      eraseCookie("subsCriber_id");
      eraseCookie("user_Id");
      sessionStorage.removeItem("persist:root");
      clearAllDatabases()
      return {
        data: [],
        error: null,
        status: null,
        token: null,
        emailStore: null,
        shouldRedirect: false,
      };

    case AUTH_401_REDIRECT:
      return {
        ...state,
        shouldRedirect: true,
      };

    case VERIFY_EMAIL_OTP_DONE:
      return {
        ...state,
        profileData: {
          data: action?.payload?.data,
          status: action?.payload?.data?.status,
          error: null,
        },
        shouldRedirect: false,
      };
    case SET_TOKEN_DONE:
      setCookie("token", action?.payload, 30);
      return {
        ...state,
        token: action?.payload,
        shouldRedirect: false,
      };

    case PROFILE_DONE:
      setCookie("user_Id", action?.payload?.id, 30);
      return {
        ...state,
        profileData: {
          data: action?.payload,
          status: action?.payload?.data?.status,
          error: null,
        },
      };
    case UPDATE_PROFILE_DONE:
      return {
        ...state,
        profileData: {
          data: action?.payload,
          status: action?.payload?.data?.status,
          error: null,
        },
      };
    case PROFILE_DONE_ERROR:
      return {
        ...state,
        profileData: {
          data: action?.payload?.data,
          status: action?.payload?.state,
          error: null,
        },
      };
    default:
      return state;
  }
};
