import React, { useEffect, useState } from "react";
import { FormControl } from "@mui/base";
import {
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import googleLogo from "../../../assets/images/auth/googleLogo.svg";
import {
  appleprovider,
  auth,
  fetchToken,
  provider,
} from "../../../config/firebase.config";
import { signInWithPopup } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userSignup } from "../../../appStore/actions/auth/signup/action";
import {
  AuthActionButton,
  GoogleButton,
  AppleButton,
  GoogleImg,
} from "../../../common/StyledComponents/AuthComponents";
import { encrypter } from "../../../utils/encryptpassword";
import { handleSocialSignIn } from "../../../appStore/actions/auth/login/loginaction";
import showIcon from "../../../assets/images/auth/visibility-on.svg";
import hideIcon from "../../../assets/images/auth/visibility-off.svg";
import appleIcon from "../../../assets/images/auth/apple.svg";
import { getProfile } from "../../../appStore/actions/profile/action";
import { requestallmemberswithgroups } from "../../../appStore/actions/ArkchatGroup/action";
import LoadingSpinner from "../../../common/LoadingSpinner/LoadingSpinner";
import useLoginCondition from "../../../customHooks/useLoginCondition";
import {
  getCountryList,
  getIndustryList,
  getLanguage,
  getServiceCategory,
} from "../../../appStore/actions/masterData/masterDataAction";
import { characterLimit, serviceTypeName } from "../../../utils/constant";
import {
  GET_ART_SERVICE_CATEGORY,
  GET_PHOTO_SERVICE_CATEGORY,
  GET_PRODUCT_SERVICE_CATEGORY,
  GET_SERVICE_CATEGORY,
  GET_VIDEO_SERVICE_CATEGORY,
} from "../../../appStore/actions/masterData";
import validator from "validator";
import IntroVideo from "../../../common/IntroVideo";
import { showToastMsg } from "../../../appStore/actions/global/action";
const classes = {
  blockInputBox: {
    width: "100%",
  },
  signupBox: {
    height: "calc(100vh - 4rem)",
    maxHeight: "40.7rem",
    minHeight: "28rem",
    width: "100%",
    maxWidth: "43rem",
    position: "relative",
    "@media only screen and (max-width:899px)": {
      height: "100%",
    },
    "@media only screen and (max-width: 1204px)": {
      minHeight: "38rem",
    },
  },
  signupBtn: {
    position: "absolute",
    bottom: "2rem",
    left: "50%",
    marginLeft: "-2.72rem",
    "@media only screen and (max-width:899px)": {
      position: "relative",
      marginBottom: "-1.8rem",
      bottom: "0 !important",
    },
    "@media only screen and (max-width:1300px)": {
      bottom: "1.5rem",
    },
  },
  signbBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    flexWrap: "wrap",
  },
  googleLogo: {
    marginRight: "0.5rem",
    width: "1.6rem", // Adjust the size of the Google logo as needed
    height: "1.6rem",
  },
  captions: {
    fontSize: "0.87rem !important",
    display: "block",
  },
  mobscroll: {
    overflowX: "hidden",
    "@media only screen and (max-width:899px)": {
      overflowX: "hidden",
    },
  },
};
const Signup = () => {
  const { loading } = useLoginCondition();
  const userdetails = useSelector((state) => state?.profile?.profileData);
  const isChangeEmail = localStorage.getItem("isChangeEmail") || false;
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isRequiredEmpty, setIsRequiredEmpty] = useState({
    email: false,
    fName: false,
    lName: false,
    password: false,
  });

  useEffect(() => {
    dispatch(getIndustryList());
    dispatch(getCountryList());
    dispatch(
      getServiceCategory(serviceTypeName?.Art, GET_ART_SERVICE_CATEGORY)
    );
    dispatch(
      getServiceCategory(serviceTypeName?.Service, GET_SERVICE_CATEGORY)
    );
    dispatch(
      getServiceCategory(serviceTypeName?.Product, GET_PRODUCT_SERVICE_CATEGORY)
    );
    dispatch(
      getServiceCategory(serviceTypeName?.Photo, GET_PHOTO_SERVICE_CATEGORY)
    );
    dispatch(
      getServiceCategory(serviceTypeName?.Videos, GET_VIDEO_SERVICE_CATEGORY)
    );
    dispatch(getLanguage());
  }, []);

  const handleEncrypt = (text) => {
    const encrypted = encrypter.encrypt(text);
    return encrypted;
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRedirection = (socialSignInResponse) => {
    if (socialSignInResponse?.data?.accountOwner) {
      if (
        socialSignInResponse?.data?.isFirstGroupCreated &&
        socialSignInResponse?.data?.isFirstInviteSent
      ) {
        navigate("/chat");
      } else if (
        socialSignInResponse?.data?.isFirstGroupCreated &&
        !socialSignInResponse?.data?.isFirstInviteSent
      ) {
        navigate("/invite");
      } else {
        navigate("/createfirstgroup");
      }
    } else {
      navigate("/chat");
    }
  };

  //  google Signup
  const handleGoogle = () => {
    signInWithPopup(auth, provider)
      .then(async (res) => {
        const user = res.user;
        const userdetails = user?.providerData[0];
        const namedetails = userdetails?.displayName.split(" ");
        const reqbody = {
          first_name: namedetails[0],
          last_name: namedetails[1],
          email: userdetails?.email,
          social_id: user?.providerData[0]?.uid,
          type: 1,
          device: "Android",
        };

        const socialSignInResponse = await dispatch(
          handleSocialSignIn(reqbody)
        );

        if (socialSignInResponse?.status) {
          fetchToken(socialSignInResponse?.data?.id);
          dispatch(getProfile());
          const allres = await dispatch(requestallmemberswithgroups({}));
          //
          const invitationData =
            JSON.parse(localStorage.getItem("invitationData")) || false;
          //

          if (
            socialSignInResponse?.data?.isProfileJourneyCompleted &&
            invitationData
          ) {
            navigate("/invitations");
          } else if (
            socialSignInResponse?.data?.isProfileJourneyCompleted &&
            !invitationData
          ) {
            handleRedirection(socialSignInResponse);
          } else if (
            !socialSignInResponse?.data?.isProfileJourneyCompleted &&
            invitationData &&
            +invitationData?.inviteType === 1
          ) {
            navigate("/individualprofile");
          } else {
            navigate("/profile");
          }
        } else {
          dispatch(showToastMsg(socialSignInResponse?.message));
        }
      })
      .catch((err) => {});
  };
  //  validation password
  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&&()#`./<>{}[\]:;"])[A-Za-z\d@$!%*?&&()#`./<>{}[\]:;"]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isLoading) {
      setLoading(true);
      if (firstName && password && email && lastName) {
        let result = validatePassword(password);
        setPasswordError(!result);
        const isValidEmail = validator.isEmail(email);
        setIsValid(isValidEmail);

        const encryptedText = handleEncrypt(password);
        if (result && encryptedText && isValidEmail) {
          let formBody = {
            first_name: firstName.trim(),
            last_name: lastName.trim(),
            password: encryptedText,
            email: email,
          };
          dispatch(userSignup(formBody)).then((res) => {
            setLoading(false);
            if (res?.status) {
              localStorage.removeItem("forgotpassword");
              navigate("/email-verify");
            } else {
              dispatch(showToastMsg(res?.message));
            }

            //
          });
        } else {
          setLoading(false);
        }
      } else {
        const isRequiredValue = {
          ...isRequiredEmpty,
          fName: !firstName ? true : false,
          lName: !lastName ? true : false,
          email: !email ? true : false,
          password: !password ? true : false,
        };
        const isValidEmail = validator.isEmail(email);
        setLoading(false);
        setIsValid(isValidEmail);
        setIsRequiredEmpty(isRequiredValue);
      }
    }
  };

  const handleSignupwithApple = () => {
    signInWithPopup(auth, appleprovider)
      .then(async (response) => {
        const userdetails = response?.user?.providerData?.[0];
        const namedetails = userdetails?.displayName;
        const reqbody = {
          first_name: namedetails ? namedetails?.split(" ")?.[0] : null,
          last_name: namedetails ? namedetails?.split(" ")?.[1] : null,
          email: userdetails?.email,
          social_id: userdetails?.uid,
          type: 2,
          device: "Android",
        };

        const res = await dispatch(handleSocialSignIn(reqbody));
        const allres = await dispatch(requestallmemberswithgroups({}));
        const invitationData =
          JSON.parse(localStorage.getItem("invitationData")) || false;
        //
        if (res?.status) {
          fetchToken(res?.data?.id);
          dispatch(getProfile());
          if (res?.data?.isProfileJourneyCompleted && invitationData) {
            navigate("/invitations");
          } else if (res?.data?.isProfileJourneyCompleted && !invitationData) {
            handleRedirection(res);
          } else if (
            !res?.data?.isProfileJourneyCompleted &&
            invitationData &&
            +invitationData?.inviteType === 1
          ) {
            navigate("/individualprofile");
          } else {
            navigate("/profile");
          }
        } else {
          dispatch(showToastMsg(res?.message));
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (isChangeEmail) {
      setFirstName(userdetails?.data?.lastName);
      setLastName(userdetails?.data?.lastName);
      setEmail(userdetails?.data?.email);
      setPassword("");
    } else {
      setFirstName("");
      setLastName("");
      setEmail("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("isChangeEmail")]);
  return (
    <>
      {loading ? (
        <LoadingSpinner minHeight={"100vh"} />
      ) : (
        <Box m={1}>
          <Grid container sx={classes.mobscroll}>
            <Grid item xs={12} md={5}>
              <IntroVideo />
            </Grid>

            <Grid ml={"auto"} item xs={12} md={6} padding={"0.4rem 1rem"}>
              <Box
                boxShadow={"0px 4px 14px rgba(0, 0, 0, 0.09)"}
                sx={classes.signupBox}
              >
                <Box padding={"1rem"}>
                  <Box mb={1}>
                    <Typography
                      fontSize={"1.7rem"}
                      fontWeight={600}
                      variant="h5"
                    >
                      {appContent?.signUp}
                    </Typography>
                    <Typography
                      mt={"0.4rem"}
                      sx={classes.captions}
                      variant="caption"
                    >
                      {appContent?.alreadyHaveAccount}
                      <Link
                        style={{ textDecoration: "none", color: "#59A0FF" }}
                        to="/signin"
                      >
                        {appContent?.signIn}
                      </Link>
                    </Typography>
                    <Typography
                      mt={"0.6rem"}
                      sx={classes.captions}
                      variant="caption"
                    >
                      {appContent?.termsOfServiceDescription} {"  "}
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#59A0FF",
                          cursor: "pointer",
                        }}
                        to={"https://www.arkchat.com/terms-of-service/"}
                        target="_blank"
                        rel="noopener"
                      >
                        {appContent?.termsOfService}
                      </Link>
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "4px",
                    }}
                    mt={"1rem"}
                    mb={"1rem"}
                  >
                    <GoogleButton variant="outlined" onClick={handleGoogle}>
                      <GoogleImg src={googleLogo} alt="Google Logo" />
                      {appContent?.googleLogin}
                    </GoogleButton>

                    <AppleButton
                      sx={{
                        bgcolor: "#000000",
                        color: "#FFFFFF",
                        border: "1px solid #FFFFFF",
                        display: "flex",
                        alignItems: "center",
                        padding: "0.36rem 0.76rem",
                        borderRadius: "0.49rem",
                        textTransform: "none",
                        ":hover": {
                          bgcolor: "#000000",
                          color: "#FFFFFF",
                          border: "1px solid #FFFFFF",
                        },
                      }}
                      size="small"
                      variant="outlined"
                      onClick={handleSignupwithApple}
                    >
                      <GoogleImg src={appleIcon} alt="Apple Logo" />
                      {appContent?.appleLogin}
                    </AppleButton>
                  </Box>

                  <Box sx={classes.divider}>
                    <div className="devider_or">
                      <div className="rule_line"></div>
                      {appContent?.or} <div className="rule_line"></div>
                    </div>
                  </Box>

                  <Box>
                    <form onSubmit={handleSubmit} noValidate>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControl>
                            <TextField
                              error={!isRequiredEmpty?.fName ? false : true}
                              type="text"
                              label={appContent?.firstName}
                              variant="standard"
                              name="firstName"
                              onChange={(e) => {
                                const inputValue = e.target.value.trim();
                                if (/^[a-zA-Z]*$/.test(inputValue)) {
                                  setFirstName(inputValue);
                                  setIsRequiredEmpty({
                                    ...isRequiredEmpty,
                                    fName:
                                      inputValue.length === 0 ? true : false,
                                  });
                                }
                              }}
                              value={firstName}
                              sx={classes.blockInputBox}
                              inputProps={{
                                maxLength: characterLimit?.firstName,
                              }}
                              required
                            />
                            <FormHelperText
                              error={!isRequiredEmpty?.fName ? false : true}
                            >
                              {appContent?.required}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl>
                            <TextField
                              type="text"
                              error={!isRequiredEmpty?.lName ? false : true}
                              label={appContent?.lastName}
                              variant="standard"
                              name="lastName"
                              value={lastName}
                              onChange={(e) => {
                                const inputValue = e.target.value.trim();
                                if (/^[a-zA-Z]*$/.test(inputValue)) {
                                  setLastName(inputValue);
                                  setIsRequiredEmpty({
                                    ...isRequiredEmpty,
                                    lName:
                                      inputValue.length === 0 ? true : false,
                                  });
                                }
                              }}
                              required
                              inputProps={{
                                maxLength: characterLimit?.lastName,
                              }}
                              sx={classes.blockInputBox}
                            />
                            <FormHelperText
                              error={!isRequiredEmpty?.lName ? false : true}
                            >
                              {appContent?.required}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <FormControl>
                        <TextField
                          type="email"
                          label={appContent?.email}
                          variant="standard"
                          name="email"
                          value={email.trim()}
                          onChange={(e) => {
                            setEmail(e.target.value.trim());
                            setIsValid(
                              validator.isEmail(e.target.value.trim())
                            );
                            setIsRequiredEmpty({
                              ...isRequiredEmpty,
                              email: e.target.value === 0 ? true : false,
                            });
                          }}
                          required
                          sx={classes.blockInputBox}
                          helperText={!isValid && appContent?.invalidEmail}
                          error={
                            !isValid || isRequiredEmpty?.email ? true : false
                          }
                        />
                        <FormHelperText
                          error={
                            !isValid || isRequiredEmpty?.email ? true : false
                          }
                        >
                          {appContent?.required}
                        </FormHelperText>
                      </FormControl>
                      <FormControl>
                        <TextField
                          type={showPassword ? "text" : "password"}
                          label={appContent?.password}
                          variant="standard"
                          name="password"
                          value={password}
                          error={!isRequiredEmpty?.password ? false : true}
                          onChange={(e) => {
                            setPassword(e.target.value.trim());
                            setIsRequiredEmpty({
                              ...isRequiredEmpty,
                              password:
                                !e.target.value.trim()?.length > 0
                                  ? true
                                  : false,
                            });
                            setPasswordError(
                              !validatePassword(e.target.value.trim())
                            );
                          }}
                          required
                          sx={classes.blockInputBox}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleTogglePassword}>
                                  {showPassword ? (
                                    <img src={hideIcon} alt="hide" />
                                  ) : (
                                    <img src={showIcon} alt="show" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <FormHelperText
                          error={
                            passwordError || isRequiredEmpty?.password
                              ? true
                              : false
                          }
                        >
                          {appContent?.passwordRequirements}
                        </FormHelperText>
                      </FormControl>

                      <Box sx={classes.signupBtn}>
                        <AuthActionButton type="submit" variant="contained">
                          {isLoading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            appContent?.signUp
                          )}
                        </AuthActionButton>
                      </Box>
                    </form>
                  </Box>
                  <Box mt={"1rem"} textAlign={"left"}>
                    <Typography>
                      You are signing up for an Arkchat Beta account.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Signup;
