import React from "react";

const TaskSvg = ({ circleColor = "", tickColor }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.73257 19.636C5.53345 19.636 2.11719 16.2198 2.11719 12.0207C2.11719 7.8215 5.53345 4.40528 9.73257 4.40528C10.5745 4.40435 11.4108 4.54332 12.2072 4.81651C12.3133 4.85298 12.4006 4.93011 12.4499 5.03094C12.4991 5.13176 12.5063 5.24803 12.4698 5.35415C12.4334 5.46028 12.3562 5.54757 12.2554 5.59683C12.1546 5.64608 12.0383 5.65327 11.9322 5.6168C11.2242 5.374 10.4807 5.25053 9.73219 5.25143C5.9996 5.25143 2.96296 8.28811 2.96296 12.0207C2.96296 15.7532 5.9996 18.7899 9.73219 18.7899C13.4648 18.7899 16.5014 15.7532 16.5014 12.0207C16.5015 11.5627 16.4555 11.1058 16.3641 10.657C16.353 10.6026 16.3527 10.5465 16.3632 10.4919C16.3738 10.4374 16.395 10.3854 16.4256 10.3391C16.4563 10.2927 16.4957 10.2528 16.5418 10.2217C16.5878 10.1906 16.6395 10.1688 16.694 10.1577C16.7484 10.1466 16.8045 10.1463 16.8591 10.1568C16.9137 10.1674 16.9656 10.1886 17.012 10.2192C17.0583 10.2499 17.0982 10.2893 17.1293 10.3354C17.1605 10.3814 17.1822 10.4332 17.1933 10.4876C17.2961 10.9921 17.3479 11.5058 17.3478 12.0207C17.348 16.2198 13.9317 19.636 9.73257 19.636Z"
        fill={circleColor ? circleColor : "#183243"}
      />
      <path
        d="M17.8667 4.13493C17.6917 3.95763 17.4832 3.81685 17.2533 3.72076C17.0234 3.62467 16.7768 3.5752 16.5276 3.5752C16.2785 3.5752 16.0318 3.62467 15.802 3.72076C15.5721 3.81685 15.3636 3.95763 15.1886 4.13493L13.9844 5.35796L16.6437 8.01726L17.8479 6.81305C18.0264 6.63927 18.1687 6.43176 18.2664 6.20257C18.3641 5.97338 18.4153 5.72707 18.417 5.47792C18.4188 5.22878 18.3711 4.98177 18.2766 4.75123C18.1821 4.52068 18.0428 4.3112 17.8667 4.13493Z"
        fill={tickColor ? tickColor : "#000000"}
      />
      <path
        d="M13.0931 6.24854L7.83091 11.5107C7.78374 11.5592 7.74362 11.6141 7.71175 11.6738L5.96815 15.1923C5.91757 15.2873 5.89227 15.3937 5.89469 15.5013C5.89711 15.6088 5.92717 15.714 5.98198 15.8066C6.03678 15.8992 6.11449 15.9761 6.20763 16.03C6.30077 16.0839 6.40621 16.1129 6.51381 16.1143C6.60937 16.1147 6.70376 16.0932 6.78977 16.0516L10.3083 14.308C10.368 14.2761 10.4229 14.236 10.4714 14.1888L15.7335 8.92665L13.0931 6.24854Z"
        fill={tickColor ? tickColor : "#000000"}
      />
    </svg>
  );
};

export default TaskSvg;
