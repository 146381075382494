import React from "react";
import {
  Box,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import messageNotSeen from "../../../assets/images/chats/not_seen.svg";

const GrouMembeMessageReadStatus = ({ handleCloseMemberDrawer }) => {
  const groupLevelPeople = useSelector(
    (state) => state?.arkchatgroup?.groupmemberWithPermission
  );

  const classes = {
    drawerBody: {
      minWidth: "31.25rem",
      maxWidth:"31.25rem",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      height: "100%",
    },
    header: {
      backgroundColor: "#F9F8F8",
      color: "#0000",
      padding: "7px 8px",
    },
    iconDiv: {
      backgroundColor: "red",
      position: "relative",
      height: "30px",
      width: "30px",
      cursor: "pointer",
      padding: "4px",
      borderRadius: "2px",
    },

    internalExternalButton: {
      background: "white",
      border: "1px solid #183243",
      color: "#183243",
      borderRadius: "0px",
      "&:hover": {
        background: "white",
      },
    },

    activeButton: {
      "&:hover": {
        background: "#183243",
      },
      background: "#183243",
      borderRadius: "0px",
      color: "white",
    },

    iconn: {
      color: "#fff",
      height: "100%",
      width: "100%",
    },
    radiogp: {
      display: "block",
    },
    groupMember: {
      backgroundColor: "#F3F3F3",
      padding: "20px 10px",
    },

    // add group member
    addgmember: {
      transform: "translate(0, 70%)",
      textAlign: "center",
    },
    // add group member
  };

  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={classes.header}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography sx={classes.iconDiv}>
                <CloseIcon
                  sx={classes.iconn}
                  onClick={handleCloseMemberDrawer}
                />
              </Typography>
              <Typography variant="subtitle2">Read Status</Typography>
            </Stack>
          </Stack>
          <React.Fragment>
            {groupLevelPeople?.map((item) => (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                padding="12px 19px 12px 30px"
                key={item?.userInfo?.id+"grpmemberdetails"}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box
                    bgcolor={"#E3E1E1"}
                    width={"30px"}
                    height={"30px"}
                    display={"flex"}
                    align-content={"center"}
                    flex-wrap={"wrap"}
                    justifyContent={"center"}
                    borderRadius={"0.15rem"}
                  >
                    <Typography variant="h6">
                      {item?.userInfo?.firstName &&
                        item?.userInfo?.firstName[0]}
                    </Typography>
                  </Box>
                  <Typography>
                    {item?.userInfo?.firstName} {item?.userInfo?.lastName}
                  </Typography>
                </Stack>
                <Stack>
                  <img src={messageNotSeen} alt=""/>
                </Stack>
              </Stack>
            ))}
          </React.Fragment>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default GrouMembeMessageReadStatus;
