import React from "react";
import taskIllustration from "../../../assets/images/task/Task_illustration.png";
import { Stack, Typography } from "@mui/material";

const NoTaskListComponent = ({content }) => {
  return (
    <Stack
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 160px)",
      }}
    >
      <img
        src={taskIllustration}
        style={{ height: "180px", maxHeight: "250px" }}
        alt=""
      />
      <Typography textAlign={"center"} maxWidth={"320px"} marginTop={"30px"}>
        {content}
      </Typography>
    </Stack>
  );
};

export default NoTaskListComponent;
