import * as actionTypes from "../../actions/Subscription/type";
const initState = {
  allPlans: [],
  allActiveMemebrs: [],
  selectedPlanData: [],
  billingData: {},
  couponData: {},
  paymentIntentData: {},
  billingResponseData: {},
  billingAddress:{},
  subscriptionDetails:[],
  selectedManageSubscriptionData:{},
  isLoading: false,
};

export const subscriptionReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_PLANS:
      return {
        ...state,
        allPlans: action?.payload,
      };
    case actionTypes.GET_ALL_ACTIVE_MEMBERS:
      return {
        ...state,
        allActiveMemebrs: action?.payload,
      };
    case actionTypes.SAVE_PLAN_DATA:
      return {
        ...state,
        selectedPlanData: action?.payload,
      };
    case actionTypes.SAVE_BILLING_FORM_DATA:
      return {
        ...state,
        billingData: action?.payload,
      };
      case actionTypes.MANAGE_SUBSCRIPTION_DATA:
        return {
          ...state,
          selectedManageSubscriptionData: action?.payload,
        };
      case actionTypes.BILLING_ADDRESS:
        return {
          ...state,
          billingAddress: action?.payload,
        };
    case actionTypes.BILLING_RESPONSE_DATA:
      return {
        ...state,
        billingResponseData: action?.payload,
      };
    case actionTypes.SUBSCRIPTION_DETAILS:
      return {
        ...state,
        subscriptionDetails: action?.payload,
      };
    case actionTypes.CONFIRM_COUPON_VALIDITY:
      return {
        ...state,
        couponData: action?.payload,
      };
    case actionTypes.PAYMENT_INTENT_DATA:
      return {
        ...state,
        paymentIntentData: action?.payload,
      };
    case actionTypes.SHOW_LOADING:
      return {
        ...state,
        isLoading: action?.payload,
      };

    default:
      return state;
  }
};
