import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack, Typography, Drawer, Badge } from "@mui/material";
import { styled } from "@mui/system";
import closeLogo from "../../assets/images/chats/closeLogo.svg";
import contactPromt from "../../assets/images/chats/contactPromt.svg";
import promtAddPeopleLogo from "../../assets/images/chats/promtAddPeopleLogo.svg";
import { useTranslation } from "react-i18next";
import InvitationDrawer from "./InvitationDrawer";
import { getAllPendingInvitation } from "../../appStore/actions/subscriber/action";

const PromtBox = styled(Box)({
  position: "relative",
  backgroundColor: "#F3F3F3",
  padding: "0.5rem",
});

const CloseButton = styled(Box)({
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  cursor: "pointer",
  position: "absolute",
  top: "0.5rem",
  left: "0.5rem",
  backgroundColor: "#DB0011",
  padding: "0.3rem",
  color: "#fff",
  width: "1.8rem",
  height: "1.8rem",
  minWidth: "unset",
  minHeight: "unset",
  borderRadius: "0.15rem",
  "&:hover": {
    backgroundColor: "#DB0011",
    color: "#fff",
  },
});
export const CloseIcon = styled("img")({
  width: "70%",
});

const ContactHeader = ({ showPromt, setShowPromt }) => {
  const [isInvitationDrawerOpen, setInvitationDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const allPendingInvitationData = useSelector(
    (state) => state?.subscriber?.allPendingInvitationData
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllPendingInvitation());
  }, []);
  return (
    <Stack spacing={0.5}>
      <Drawer open={isInvitationDrawerOpen} anchor={"right"}>
        <InvitationDrawer setInvitationDrawerOpen={setInvitationDrawerOpen} />
      </Drawer>
      <Box display="flex" bgcolor="#F3F3F3" p="0.2rem 0rem">
        {/* <Box sx={{ cursor: "pointer" }} p={"0rem 1rem"}>
          <Typography fontSize="0.87rem" fontWeight="400" variant="h6">
            {appContent?.rights}
          </Typography>
        </Box> */}
        <Box width="45%" textAlign="center">
          <Typography fontSize="1rem" fontWeight="400" variant="h6">
            {appContent?.internalContacts}
          </Typography>
        </Box>
        <Box width="45%" textAlign="center">
          <Typography fontSize="1rem" fontWeight="400" variant="h6">
            {appContent?.externalContacts}
          </Typography>
        </Box>
        <Box
          onClick={() => setInvitationDrawerOpen(true)}
          sx={{ cursor: "pointer" }}
          p={"0rem 1rem"}
        >
          {" "}
          <Badge
            color="success"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            badgeContent={allPendingInvitationData?.invitations?.length}
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: '#DB0011', // Your custom background color
                fontSize: '0.8rem',
                zIndex: 1199,
              },
            }}
          >
            <Typography fontSize="0.87rem" fontWeight="400" variant="h6">
              {appContent?.invitations}
            </Typography>
          </Badge>
        </Box>
      </Box>

      <PromtBox display={showPromt ? `block` : `none`}>
        <CloseButton
          onClick={() => setShowPromt(false)}
          size="small"
          variant="contained"
        >
          <CloseIcon src={closeLogo} alt="close" />
        </CloseButton>

        <Box>
          <Box textAlign="center">
            <img src={contactPromt} alt="chatIllustration" />
            <Box display="flex" justifyContent="center" gap="0.25rem">
              <Typography fontSize="0.8rem" fontWeight="400" variant="h6">
                {appContent?.promtMsgFirstPart}
              </Typography>
              <Box>
                <img src={promtAddPeopleLogo} alt="addPeople" />
              </Box>
              <Typography fontSize="0.8rem" fontWeight="400" variant="h6">
                {appContent?.promtMsgSecondPart}
              </Typography>
            </Box>
          </Box>
        </Box>
      </PromtBox>
    </Stack>
  );
};

export default ContactHeader;
