import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const AccountSwitchLoading = () => {
  const isAccountSwitchLoading = useSelector(
    (state) => state?.localAppReducer?.isAccountSwitchLoading
  );
  return (
    <>
      {isAccountSwitchLoading && (
        <Box
          zIndex={2011}
          position={"absolute"}
          width={"97%"}
          height={"100%"}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor={"rgba(0, 0, 0, 0.5)"}
        >
          <CircularProgress sx={{ color: "#DB0011" }} />
        </Box>
      )}
    </>
  );
};

export default AccountSwitchLoading;
