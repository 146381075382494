import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Stack, Tabs, Tab, Button, Chip } from "@mui/material";
import gridView_active from "../../../assets/images/task/gridView_active.svg";
import listView from "../../../assets/images/task/listView.svg";
import TaskListComponent from ".";
import {
  handleGetAllTaskList,
  handleGetTaskAnalytics,
} from "../../../appStore/actions/ArkchatGroup/action";
import TaskAnalytics from "./TaskAnalytics";
import { useTranslation } from "react-i18next";
import HelperScreen from "../../../common/ToolTip/HelperScreen";
import { handleLoading } from "../../../appStore/actions/AppState/action";
import useAppSelectors from "../../../customHooks/useAppSelectors";
import { AccountLevelArkchatPermission } from "../../../utils/constant";

const classes = {
  Tabs_01: {
    minHeight: "38px",
    width: "14%",
    minWidth: "152px",
    "& .MuiTab-root.Mui-selected": {
      background: "#183243",
      color: "#fff",
    },
  },

  tab1: {
    color: "#183243",
    textTransform: "none",
    minWidth: "unset",
    minHeight: "2.2rem",
    gap: "0 8px",
    display: "flex",
    flexDirection: "row",
    // borderRadius: "3px",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px 12px 15px 12px !important",
    maxHeight: "30px",
    lineHeight: "normal",
    background: "#D9D9D9",
    "&:first-Child": {
      borderTopLeftRadius: "3px",
      borderBottomLeftRadius: "3px",
    },
    "&:nth-Child(2)": {
      borderTopRightRadius: "3px",
      borderBottomRightRadius: "3px",
    },
    img: {
      margin: "0 !important",
    },
    "&.Mui-selected": {
      // color: "black",
    },
    "@media (max-width: 64em)": {
      // marginRight: "2.5rem",
    },
  },

  Tabs: {
    minHeight: "38px",
    width: "86%",
    "& .MuiTabs-flexContainer": {
      // gap: "0 10px",
    },
    "@media (max-width: 63em)": {
      "& .MuiTabs-flexContainer": {
        // gap: "0 3%",
      },
    },
    "& .MuiTab-root.Mui-selected": {
      background: "#183243",
      color: "#fff",
    },
  },

  tabs: {
    marginLeft: "0.5rem",
    color: "#183243",
    textTransform: "none",
    minWidth: "5rem",
    minHeight: "2.2rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px 12px 15px 12px !important",
    maxHeight: "30px",
    lineHeight: "normal",
    background: "#fff",
    borderRadius: "3px",
    border: "1px solid #000000",
  },
  analyticsParentBox: {
    overflow: "scroll",
    height: "calc(100vh - 11rem)",
    "&::-webkit-scrollbar": {
      width: "1px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#F9F8F8",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "grey",
    },
  },
};

const TabPanel = ({ children, value, index }) => (
  <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>
);

const TaskListTab = ({
  handleClicktabButton,
  setShowSidebar,
  setTaskApprovalViewValue,
  taskApprovalViewValue,
  handleClickTaskUpdate,
  taskTabValue,
  setTaskTabValue,
  selectedTaskmember,
  setSelectedTaskMember,
  selectedTaskGroup,
  setSelectedTaskGroup,
  filterChecked,
  setFilterChecked,
  handleApplyTaskFilter,
  handleViewThreadClick,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const { currentUserData } = useAppSelectors();
  const dispatch = useDispatch();

  const handleViewChange = (event, newValue) => {
    if (newValue === 0) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
    setTaskTabValue(0);
    setTaskApprovalViewValue(newValue);
    setSelectedTaskGroup([]);
    setSelectedTaskMember([]);
    setFilterChecked([]);
  };

  const handleChange = (event, newValue) => {
    // for list data filter
    if (taskApprovalViewValue === 1) {
      dispatch(handleLoading(true));
      if (
        currentUserData?.activePermission ===
        AccountLevelArkchatPermission?.ADMIN
      ) {
        if (newValue === 0 || newValue === 2 || newValue === 3) {
          if (newValue === 2) {
            handleClicktabButton("Team");
          } else if (newValue === 3) {
            handleClicktabButton("Group");
          }
          dispatch(handleGetAllTaskList()).then(() =>
            dispatch(handleLoading(false))
          );
        } else if (newValue === 1) {
          dispatch(handleGetAllTaskList(`userId=${currentUserData?.id}`)).then(
            () => dispatch(handleLoading(false))
          );
        } else if (newValue === 4) {
          dispatch(handleGetAllTaskList(`is_archived=${true}`)).then(() =>
            dispatch(handleLoading(false))
          );
        }
      } else {
        if (newValue === 0) {
          dispatch(handleGetAllTaskList(`userId=${currentUserData?.id}`)).then(
            () => dispatch(handleLoading(false))
          );
        } else if (newValue === 1) {
          dispatch(handleGetAllTaskList(`is_archived=${true}`)).then(() =>
            dispatch(handleLoading(false))
          );
        }
      }

      setTaskTabValue(newValue);
      setSelectedTaskGroup([]);
      setSelectedTaskMember([]);
      setFilterChecked([]);
    }
    //for analytics data filter
    else {
      if (
        currentUserData?.activePermission ===
        AccountLevelArkchatPermission?.ADMIN
      ) {
        if (newValue === 0 || newValue === 2 || newValue === 3) {
          if (newValue === 2) {
            handleClicktabButton("Team");
          } else if (newValue === 3) {
            handleClicktabButton("Group");
          }
          dispatch(handleGetTaskAnalytics());
        } else if (newValue === 1) {
          dispatch(handleGetTaskAnalytics(`userId=${currentUserData?.id}`));
        } else if (newValue === 4) {
          dispatch(handleGetTaskAnalytics(`is_archived=${true}`));
        }
      } else {
        if (newValue === 0) {
          dispatch(handleGetTaskAnalytics(`userId=${currentUserData?.id}`));
        } else if (newValue === 1) {
          dispatch(handleGetTaskAnalytics(`is_archived=${true}`));
        }
      }

      setTaskTabValue(newValue);
      setTaskTabValue(newValue);
      setSelectedTaskGroup([]);
      setSelectedTaskMember([]);
      setFilterChecked([]);
    }
  };

  const handleRemoveSelectedTabFilter = (isTaskTab = true) => {
    if (isTaskTab) {
      setSelectedTaskMember([]);
    } else {
      setSelectedTaskGroup([]);
    }
    handleChange("", 0);
  };

  const handleRemoveStatusFilter = (item) => {
    const checkedData = filterChecked?.filter(
      (data) => data?.type !== item?.type
    );
    setFilterChecked(checkedData);
    handleApplyTaskFilter(checkedData);
  };

  useEffect(() => {
    dispatch(handleLoading(true));
    if (
      currentUserData?.activePermission === AccountLevelArkchatPermission?.ADMIN
    ) {
      dispatch(handleGetAllTaskList()).then(() =>
        dispatch(handleLoading(false))
      );
      dispatch(handleGetTaskAnalytics());
    } else {
      dispatch(handleGetAllTaskList(`userId=${currentUserData?.id}`)).then(() =>
        dispatch(handleLoading(false))
      );
      dispatch(handleGetTaskAnalytics(`userId=${currentUserData?.id}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {!currentUserData?.tooltipTaskScreenWeb && (
        <HelperScreen
          updateKey={"tooltip_task_screen_web"}
          content={[
            appContent?.taskContent1,
            appContent?.taskContent2,
            appContent?.commonHelperContent,
          ]}
        />
      )}

      <Box>
        <Stack direction={"row"}>
          <Tabs
            value={taskApprovalViewValue}
            onChange={handleViewChange}
            sx={classes.Tabs_01}
            TabIndicatorProps={{
              style: { backgroundColor: "transparent" },
            }}
          >
            <Tab
              disableRipple
              sx={classes.tab1}
              label="View"
              icon={<img src={gridView_active} alt="" />}
            />
            <Tab
              disableRipple
              sx={classes.tab1}
              icon={<img src={listView} alt="" />}
              label="View"
            />
          </Tabs>
          <Tabs
            value={taskTabValue}
            onChange={handleChange}
            TabIndicatorProps={{
              style: { backgroundColor: "transparent" },
            }}
            sx={classes.Tabs}
          >
            {currentUserData?.activePermission ===
              AccountLevelArkchatPermission?.ADMIN && (
              <Tab
                disableRipple
                sx={classes.tabs}
                // label={subHeaderLabels?.chats}
                label="All"
              />
            )}

            <Tab
              disableRipple
              sx={classes.tabs}
              // label={subHeaderLabels?.tasks}
              label="Mine"
            />
            {currentUserData?.activePermission ===
              AccountLevelArkchatPermission?.ADMIN && (
              <Tab
                disableRipple
                sx={classes.tabs}
                // label={subHeaderLabels?.approvals}
                // onClick={toggleDrawer(true)}
                label="Team"
              />
            )}
            {currentUserData?.activePermission ===
              AccountLevelArkchatPermission?.ADMIN && (
              <Tab
                disableRipple
                sx={classes.tabs}
                // label={subHeaderLabels?.sellers}
                label="Group"
              />
            )}
            {taskApprovalViewValue === 1 && (
              <Tab
                disableRipple
                sx={classes.tabs}
                // label={subHeaderLabels?.sellers}
                label="Archive"
              />
            )}
          </Tabs>
        </Stack>

        {(selectedTaskmember?.length > 0 ||
          selectedTaskGroup?.length > 0 ||
          filterChecked?.length > 0 ||
          taskTabValue === 2 ||
          taskTabValue === 3) && (
          <Box
            bgcolor={"#F3F3F3"}
            width={"100%"}
            display={"flex"}
            gap={"0.2rem"}
            alignItems={"center"}
          >
            <Box
              sx={{
                "::-webkit-scrollbar": {
                  height: "0px",
                },
              }}
              maxWidth={"53.8rem"}
              overflow={"auto"}
              display={"flex"}
              alignItems={"center"}
              gap={"0.2rem"}
            >
              <Box>
                {selectedTaskmember?.length > 0 && (
                  <Chip
                    label={`${selectedTaskmember[0]?.userInfo?.firstName} ${selectedTaskmember[0]?.userInfo?.lastName}`}
                    variant="outlined"
                    onDelete={() => handleRemoveSelectedTabFilter()}
                  />
                )}
              </Box>
              <Box display={"flex"} gap={"0.2rem"} alignItems={"center"}>
                {filterChecked?.length > 0 && (
                  <>
                    {filterChecked?.map((item) => (
                      <Chip
                        label={`${item?.type}`}
                        variant="outlined"
                        onDelete={() => handleRemoveStatusFilter(item)}
                      />
                    ))}
                  </>
                )}
              </Box>
              <Box>
                {selectedTaskGroup?.length > 0 && (
                  <Chip
                    label={`${selectedTaskGroup[0]?.name}`}
                    variant="outlined"
                    onDelete={() => handleRemoveSelectedTabFilter(false)}
                  />
                )}
              </Box>
            </Box>
            {taskTabValue === 2 && (
              <Box alignSelf={"flex-end"} marginLeft={"auto"}>
                <Button
                  style={{
                    background: "#DB0011",
                    color: "white",
                    borderRadius: "3px",
                    textTransform: "none",
                  }}
                  variant="contained"
                  onClick={() => handleClicktabButton("Team")}
                >
                  Select team Members
                </Button>
              </Box>
            )}

            {taskTabValue === 3 && (
              <Box alignSelf={"flex-end"} marginLeft={"auto"}>
                <Button
                  style={{
                    background: "#DB0011",
                    color: "white",
                    borderRadius: "3px",
                    textTransform: "none",
                  }}
                  variant="contained"
                  onClick={() => handleClicktabButton("Group")}
                >
                  Select Groups
                </Button>
              </Box>
            )}
          </Box>
        )}

        <Stack mt={"1rem"}>
          <TabPanel value={taskTabValue} index={0}>
            {taskApprovalViewValue === 1 ? (
              <TaskListComponent
                taskTabValue={taskTabValue}
                handleClickTaskUpdate={handleClickTaskUpdate}
                handleViewThreadClick={handleViewThreadClick}
                handleApplyTaskFilter={handleApplyTaskFilter}
                filterChecked={filterChecked}
              />
            ) : (
              <Box sx={classes.analyticsParentBox}>
                <TaskAnalytics
                  taskTabValue={taskTabValue}
                  selectedTaskGroup={selectedTaskGroup}
                  selectedTaskmember={selectedTaskmember}
                />
              </Box>
            )}
          </TabPanel>
          <TabPanel value={taskTabValue} index={1}>
            {taskApprovalViewValue === 1 ? (
              <TaskListComponent
                taskTabValue={taskTabValue}
                setTaskTabValue={setTaskTabValue}
                handleClickTaskUpdate={handleClickTaskUpdate}
                handleViewThreadClick={handleViewThreadClick}
                handleApplyTaskFilter={handleApplyTaskFilter}
                filterChecked={filterChecked}
              />
            ) : (
              <Box sx={classes.analyticsParentBox}>
                <TaskAnalytics
                  taskTabValue={taskTabValue}
                  selectedTaskGroup={selectedTaskGroup}
                  selectedTaskmember={selectedTaskmember}
                />
              </Box>
            )}
          </TabPanel>
          <TabPanel value={taskTabValue} index={2}>
            {taskApprovalViewValue === 1 ? (
              <TaskListComponent
                taskTabValue={taskTabValue}
                setTaskTabValue={setTaskTabValue}
                handleClickTaskUpdate={handleClickTaskUpdate}
                handleViewThreadClick={handleViewThreadClick}
                handleApplyTaskFilter={handleApplyTaskFilter}
                filterChecked={filterChecked}
              />
            ) : (
              <Box sx={classes.analyticsParentBox}>
                <TaskAnalytics
                  taskTabValue={taskTabValue}
                  setTaskTabValue={setTaskTabValue}
                  handleClickTaskUpdate={handleClickTaskUpdate}
                  handleViewThreadClick={handleViewThreadClick}
                  selectedTaskGroup={selectedTaskGroup}
                  selectedTaskmember={selectedTaskmember}
                />
              </Box>
            )}
          </TabPanel>
          <TabPanel value={taskTabValue} index={3}>
            {taskApprovalViewValue === 1 ? (
              <TaskListComponent
                taskTabValue={taskTabValue}
                handleClickTaskUpdate={handleClickTaskUpdate}
                handleViewThreadClick={handleViewThreadClick}
                handleApplyTaskFilter={handleApplyTaskFilter}
                filterChecked={filterChecked}
              />
            ) : (
              <Box sx={classes.analyticsParentBox}>
                <TaskAnalytics
                  taskTabValue={taskTabValue}
                  selectedTaskGroup={selectedTaskGroup}
                  selectedTaskmember={selectedTaskmember}
                />
              </Box>
            )}
          </TabPanel>
          <TabPanel value={taskTabValue} index={4}>
            {taskApprovalViewValue === 1 ? (
              <TaskListComponent
                taskTabValue={taskTabValue}
                handleClickTaskUpdate={handleClickTaskUpdate}
                handleViewThreadClick={handleViewThreadClick}
                handleApplyTaskFilter={handleApplyTaskFilter}
                filterChecked={filterChecked}
              />
            ) : (
              <Box sx={classes.analyticsParentBox}>
                <TaskAnalytics
                  taskTabValue={taskTabValue}
                  selectedTaskGroup={selectedTaskGroup}
                  selectedTaskmember={selectedTaskmember}
                />
              </Box>
            )}
          </TabPanel>
        </Stack>
      </Box>
    </>
  );
};

export default TaskListTab;
