import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { getFaqData } from "../../../appStore/actions/masterData/masterDataAction";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "1rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const Faq = ({ setFaqDrawerOpen }) => {
  const endpoint = window.location.pathname;
  const currentHomeIndexTab = useSelector(
    (state) => state?.localAppReducer?.currentHomeIndexTab
  );
  const faqList = useSelector((state) => state?.faq?.faqData);
  const dispatch = useDispatch();
  useEffect(() => {
    // chats//
    // marketplace//
    // explore//
    // generative-ai
    // contacts//
    // switch
    // post//
    if (endpoint === "/chat" && currentHomeIndexTab === 0) {
      dispatch(getFaqData("chats"));
    } else if (endpoint === "/chat" && currentHomeIndexTab === 3) {
      dispatch(getFaqData("marketplace"));
    } else if (endpoint === "/contact") {
      dispatch(getFaqData("contacts"));
    } else if (endpoint === "/post") {
      dispatch(getFaqData("post"));
    } else if (endpoint === "/explore") {
      dispatch(getFaqData("explore"));
    }
  }, []);
  return (
    <Box sx={classes.drawerBody}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => setFaqDrawerOpen(false)}
          />
        </Typography>
        <Typography color={"black"}>FAQ</Typography>
      </Stack>

      <Box
        sx={{
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "3px",
          },
        }}
        m={"0.5rem"}
        maxHeight={"80vh"}
        overflow={"auto"}
        width={"95%"}
        margin={"auto"}
      >
        {faqList?.map((data) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography fontWeight={500}>{data?.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {data?.same ? (
                  <>
                    {data?.answerMobile?.split(/\n/).map((line, indx) => (
                      <span
                        key={line + indx}
                        style={{ marginBottom: "0.5rem", display: "block" }}
                      >
                        {line}
                      </span>
                    ))}
                  </>
                ) : (
                  <>
                    {data?.answerWeb?.split(/\n/).map((line, indx) => (
                      <span
                        key={line + indx}
                        style={{ marginBottom: "0.5rem", display: "block" }}
                      >
                        {line}
                      </span>
                    ))}
                  </>
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default Faq;
