import CryptoJS from 'crypto-js';
  //  secrate keys for changes password 
  const key = process.env.REACT_APP_SECRATE_KEY;
  const iv = process.env.REACT_APP_SECRATE_IV;

  //  password incryption 
export  const encrypter = {
    encrypt: (text) => {
      const ciphertext = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      return ciphertext.toString();
    },
  };