import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { AccountLevelArkchatPermission } from "../../../utils/constant";
import ProfileDetailModal from "../../../common/ProfileDetailModal";
import { findUser, setProfileData } from "../../../utils/helper";
import { getUserProfileDetails } from "../../../appStore/actions/AppState/action";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },

  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  chatUserprofile: {
    bgcolor: "#E3E1E1",
    width: "30px",
    height: "30px",
    display: "flex",
    display: "-webkit-box",
    display: "-ms-flexbox",
    display: "-webkit-flex,",
    alignContent: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    WebkitBoxPack: "center",
    msFlexPack: "center",
    borderRadius: "0.15rem",
    fontWeight: "500",
    objectFit: "cover",
    img: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
      borderRadius: "3px",
    },
  },
};
const GrouMemberList = ({ handleCloseMemberDrawer }) => {
  const [profileViewData, setProfileViewData] = useState("");
  const [isProfileViewOpen, setProfileViewModal] = useState(false);
  const dispatch = useDispatch();
  const groupLevelPeople = useSelector(
    (state) => state?.arkchatgroup?.groupmember
  );
  const handleShowUserProfile = (userToFindData) => {
    let data = findUser(groupLevelPeople, userToFindData);
    
    if (!data) {
      setProfileViewModal(true);
      dispatch(getUserProfileDetails(userToFindData)).then((res) => {
        data = res;
        setProfileData(data,setProfileViewData,setProfileViewModal);
      });
    } else {
      setProfileData(data,setProfileViewData,setProfileViewModal);
    }
  };
  // const handleShowUserProfile = (userToFindData) => {
  //   const data = findUser(groupLevelPeople, userToFindData);
  //   const viewData = {
  //     companyName: data?.userData?.subscriberFirmName,
  //     userName: `${data?.userInfo?.firstName} ${data?.userInfo?.lastName}`,
  //     indusrtyName: data?.userData?.subscriberAccountIndustry,
  //     cityName: data?.userData?.city,
  //     countryName: data?.userData?.country,
  //     profileBrief: data?.userInfo?.profileBrief,
  //     profileImgUrl: data?.userInfo?.profileImgUrl,
  //     coverImgUrl: data?.userInfo?.coverImgUrl,
  //     isGroup: false,
  //     website: data?.userInfo?.website,
  //     linkedin: data?.userInfo?.linkedin,
  //   };
  //   setProfileViewData(viewData);
  //   setProfileViewModal(true);
  // };
  return (
    <React.Fragment>
      <ProfileDetailModal
        setProfileViewModal={setProfileViewModal}
        isProfileViewOpen={isProfileViewOpen}
        profileViewData={profileViewData}
        setProfileViewData={setProfileViewData}
      />
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={classes.header}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography sx={classes.iconDiv}>
                <CloseIcon
                  sx={classes.iconn}
                  onClick={handleCloseMemberDrawer}
                />
              </Typography>
              <Typography color={"#000000"} variant="subtitle2">
                Group Members
              </Typography>
            </Stack>
          </Stack>
          <React.Fragment>
            <Box padding="12px">
              <Typography fontWeight={600}>Internal</Typography>
            </Box>
            {[...groupLevelPeople?.internalMember]?.map((item) => (
              <>
                {item?.permissionType !== 4 && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    padding="12px"
                    key={item?.userInfo?.id + "grpmemberdetails"}
                  >
                    {item?.userInfo?.profileImgUrl ? (
                      <Box
                        onClick={() => handleShowUserProfile(item?.userId)}
                        sx={{ ...classes.chatUserprofile, cursor: "pointer" }}
                      >
                        <img
                          src={item?.userInfo?.profileImgUrl}
                          alt="profile"
                          width={"100%"}
                          height="100%"
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleShowUserProfile(item?.userId)}
                        bgcolor={"#E3E1E1"}
                        width={"30px"}
                        height={"30px"}
                        display={"flex"}
                        flex={"none"}
                        alignItems={"center"}
                        flex-wrap={"wrap"}
                        justifyContent={"center"}
                        borderRadius={"0.15rem"}
                        // border={"1px solid blue"}
                      >
                        <Typography variant="h6" fontSize={"0.75rem"}>
                          {item?.userInfo?.firstName[0]}
                        </Typography>
                      </Box>
                    )}
                    <Typography>
                      {item?.userInfo?.firstName} {item?.userInfo?.lastName}
                    </Typography>
                  </Stack>
                )}
              </>
            ))}

            <Box padding="12px">
              <Typography fontWeight={600}>External</Typography>
            </Box>
            {[...groupLevelPeople?.externalMember]?.map((item) => (
              <>
                {item?.permissionType !== 4 && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    padding="12px"
                    key={item?.userInfo?.id + "grpmemberdetails"}
                  >
                    {item?.userInfo?.profileImgUrl ? (
                      <Box
                        border={"1px solid red"}
                        onClick={() => handleShowUserProfile(item?.userId)}
                        sx={{ ...classes.chatUserprofile, cursor: "pointer" }}
                      >
                        <img
                          src={item?.userInfo?.profileImgUrl}
                          alt="profile"
                          width={"100%"}
                          height="100%"
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleShowUserProfile(item?.userId)}
                        bgcolor={"#E3E1E1"}
                        width={"30px"}
                        height={"30px"}
                        display={"flex"}
                        flex={"none"}
                        alignItems={"center"}
                        flex-wrap={"wrap"}
                        justifyContent={"center"}
                        borderRadius={"0.15rem"}
                        // border={"1px solid blue"}
                      >
                        <Typography variant="h6" fontSize={"0.75rem"}>
                          {item?.userInfo?.firstName[0]}
                        </Typography>
                      </Box>
                    )}
                    <Typography>
                      {item?.userInfo?.firstName} {item?.userInfo?.lastName}
                    </Typography>
                  </Stack>
                )}
              </>
            ))}
          </React.Fragment>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default GrouMemberList;
