import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  AccountLevelArkchatPermission,
  GroupLevelArkchatPermission,
  getChatGroupMenu,
} from "../../../utils/constant";
import arrowForwardIosOutlined from "../../../assets/images/chats/down_arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { handleExitFromGroup } from "../../../appStore/actions/ArkchatGroup/action";
import { showToastMsg } from "../../../appStore/actions/global/action";
import { useTranslation } from "react-i18next";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};

const ItemBox = ({ item, handleSelect }) => (
  <Box
    bgcolor={"#E1E1E1"}
    sx={{
      cursor: "pointer",
      borderRadius: "2px",
    }}
    mb={"0.9rem"}
    p={"0.5rem 1rem"}
    position={"relative"}
    onClick={() => handleSelect(item)}
  >
    <Typography color={"black"} fontSize={"0.87rem"} fontWeight={400}>
      {item?.name}
    </Typography>
    <Box position={"absolute"} right={"0.4rem"} top={"20%"}>
      <img src={arrowForwardIosOutlined} alt="logo" />
    </Box>
  </Box>
);

const GroupMenuDrawer = ({
  setLanguageMenuOpen,
  selectedGroup,
  handleSelectedGroupmenu,
  handleCloseGroupMenu,
  setDatAndTimeModal,
  setNotificationpreferenceModal,
  setMediaFilesOpen,
  setdisableprivatechat,
}) => {
  const { t } = useTranslation();
  const appConstant = t("AppConstant", { returnObjects: true });
  const chatGroupMenu = getChatGroupMenu(appConstant);
  const userData = useSelector((state) => state?.profile?.profileData?.data);
  const groupmemberdetails = useSelector(
    (state) => state?.arkchatgroup?.groupmember
  );
  const isNotGroupMember =
    groupmemberdetails?.internalMember?.length > 0
      ? groupmemberdetails?.internalMember.find(
          (item) =>
            item?.userId === userData?.id &&
            item?.permissionType === GroupLevelArkchatPermission?.REMOVE
        )
      : undefined;

  const dispatch = useDispatch();

  const handleSelect = (item) => {
    handleSelectedGroupmenu(item);
    switch (item?.value) {
      case "language":
        setLanguageMenuOpen(true);
        break;
      case "dateformat":
        setDatAndTimeModal(true);
        break;
      case "notification":
        setNotificationpreferenceModal(true);
        break;
      case "media":
        setMediaFilesOpen(true);
        break;
      case "privatechat":
        setdisableprivatechat(true);
        break;
      case "exit":
        dispatch(handleExitFromGroup({ group_id: selectedGroup?.id })).then(
          (res) => {
            handleCloseGroupMenu();
            dispatch(showToastMsg(res?.message));
          }
        );
        break;
      default:
        break;
    }
  };

  const renderItems = (items) => {
    return items.map((item) => {
      if (!selectedGroup?.isGroup) {
        // Render specific items when selectedGroup is false
        if (
          item.value === "language" ||
          item.value === "dateformat" ||
          item.value === "notification" ||
          item.value === "media"
        ) {
          return (
            <ItemBox key={item.value} item={item} handleSelect={handleSelect} />
          );
        }
      } else {
        // Render items based on user permissions and group membership
        if (
          (item.value === "delete" &&
            userData?.activePermission ===
              AccountLevelArkchatPermission?.ADMIN &&
            isNotGroupMember) ||
          (item.value === "exit" &&
            ((userData?.activePermission ===
              AccountLevelArkchatPermission?.ADMIN &&
              !isNotGroupMember) ||
              userData?.activePermission !==
                AccountLevelArkchatPermission?.ADMIN)) ||
          (item.value !== "delete" && item.value !== "exit")
        ) {
          return (
            <ItemBox key={item.value} item={item} handleSelect={handleSelect} />
          );
        }
      }
      return null;
    });
  };


  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={classes.header}
          >
            <Typography sx={classes.iconDiv}>
              <CloseIcon sx={classes.iconn} onClick={handleCloseGroupMenu} />
            </Typography>
            <Typography color={"#000000"} variant="subtitle2">
              {selectedGroup?.name}
            </Typography>
          </Stack>
          <Stack margin="15px 30px">{renderItems(chatGroupMenu)}</Stack>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default GroupMenuDrawer;