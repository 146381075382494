import React, { useRef, useState } from "react";
import {
  Box,
  Stack,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { FormControl } from "@mui/base";
import { AuthActionButton } from "../../common/StyledComponents/AuthComponents";
import profileBanner from "../../assets/images/Network.svg";
import companyLogo from "../../assets/images/auth/companyLogo.svg";
import CustomDrawer from "./CustomDrawer";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import editLogo from "../../assets/images/auth/editLogo.svg";
import cameraLogo from "../../assets/images/auth/cameraLogo.svg";
import { ActionButton } from "../../common/chatComponents/ScreenSearchSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  updateFirmPic,
} from "../../appStore/actions/profile/action";
import { useNavigate } from "react-router-dom";
import { createSubscriber } from "../../appStore/actions/subscriber/action";
import { requestallmemberswithgroups } from "../../appStore/actions/ArkchatGroup/action";
import smallCloseIcon from "../../assets/images/post/closeIcon.svg";
import { handleSwitchAccountRefresh } from "../../appStore/actions/AppState/action";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  formBox: {
    maxHeight: "60%",
    height: "60%",
    marginTop: "8%",
  },
  captions: {
    fontSize: "0.75rem",
    display: "block",
  },
  signInBox: {
    height: "calc(100vh - 32px)",
    margin: "auto",
    position: "relative",
  },
  buttonStyle: {
    position: "absolute",
    bottom: "2%",
    left: "40%",
  },
};

const NewOrganisationProfile = ({
  setCreateNewOrgAccountDrawerOpen,
  setSwitchAccountDrawerOpen,
}) => {
  const endpoint = window.location.pathname;
  const [userProfileData, setUserProfileData] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [coverImage, setCoverImage] = useState({
    src: profileBanner,
    isCoverSelected: false,
    files: "",
  });
  const [profileImage, setProfileImage] = useState({
    src: companyLogo,
    isProfileSelected: false,
    files: "",
  });
  const [masterListData, setMasterListData] = useState({});
  const [type, setType] = useState("industry");
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const coverPicRef = useRef(null);
  const profilePicRef = useRef(null);
  const switchAccountRefresh = useSelector(
    (state) => state?.appStateData?.isSwitchAccountRefresh
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserProfileData({
      ...userProfileData,
      [name]: value,
    });
  };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (event.target.name === "cover") {
        setCoverImage({
          ...coverImage,
          src: URL.createObjectURL(file),
          isCoverSelected: true,
          files: file,
        });
      } else {
        setProfileImage({
          ...profileImage,
          src: URL.createObjectURL(file),
          isProfileSelected: true,
          files: file,
        });
      }
    }
  };

  const handleRemovePic = (type) => {
    if (type === "cover") {
      setCoverImage({
        src: profileBanner,
        isCoverSelected: false,
        files: "",
      });
    } else {
      setProfileImage({
        src: companyLogo,
        isProfileSelected: false,
        files: "",
      });
    }
  };

  const handleButtonClick = (type) => {
    if (type === "cover") {
      coverPicRef.current.click();
    } else {
      profilePicRef.current.click();
    }
  };
  const handleDrawerOpen = (type) => {
    setIsDrawerOpen(true);
    setType(type);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleOtherApi = async (resData) => {
    try {
      if (coverImage?.isCoverSelected) {
        const formData = new FormData();
        formData.append("files", coverImage?.files);
        formData.append("data", JSON.stringify({ is_cover_image: true }));
        dispatch(updateFirmPic(formData, resData?.data?.id));
      }

      if (profileImage?.isProfileSelected) {
        const formData = new FormData();
        formData.append("files", profileImage?.files);
        formData.append("data", JSON.stringify({ is_cover_image: false }));
        dispatch(updateFirmPic(formData, resData?.data?.id));
      }
      dispatch(getProfile(true)).then((Profileres) => {
        if (Profileres?.status) {
          dispatch(requestallmemberswithgroups({ forceFetch: true })).then(
            (allgrpres) => {
              setCreateNewOrgAccountDrawerOpen(false);
              setSwitchAccountDrawerOpen(false);
              if (endpoint !== "/chat") {
                navigate("/chat");
              } else {
                dispatch(handleSwitchAccountRefresh(!switchAccountRefresh));
              }
              navigate("/chat");
            }
          );
        }
      });
    } catch (error) {
      // Handle errors here.
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      subscriber_account_type: "",
      subscriber_account_industry: masterListData?.industryName,
      subscriber_account_expertise: "",
      subscriber_priority: "2",
      subscriber_firm_name: userProfileData?.subscriber_firm_name || "",
      job_title: "",
      website: userProfileData?.website || "",
      city: userProfileData?.city,
      country: masterListData?.countryName || userProfileData?.country,
      country_code: masterListData?.countryCode || userProfileData?.countryCode,
      new_account: false,
    };
    try {
      let res = await dispatch(createSubscriber(formData));
      if (res?.status) {
        handleOtherApi(res);
      }
    } catch (error) {
      // Handle errors here.
    }
  };
  return (
    <>
      <Box sx={classes.drawerBody}>
        <CustomDrawer
          isDrawerOpen={isDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          type={type}
          setMasterListData={setMasterListData}
          isFullWidth={true}
        />
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={classes.header}
        >
          <Typography sx={classes.iconDiv}>
            <CloseIcon
              sx={classes.iconn}
              onClick={() => setCreateNewOrgAccountDrawerOpen(false)}
            />
          </Typography>
          <Typography color={"black"}>Organisation Profile</Typography>
        </Stack>
        <Box
          width={"90%"}
          margin={"auto"}
          overflow={"scroll"}
          height={"calc(100vh - 5rem)"}
          sx={{
            "&::-webkit-scrollbar": {
              width: "1px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#F9F8F8",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "grey",
            },
          }}
        >
          <Box sx={classes.signInBox}>
            <>
              <Box
                maxHeight={"21%"}
                height="21%"
                width={"100%"}
                position={"relative"}
              >
                <Box height={"100%"} overflow={"hidden"}>
                  {coverImage?.isCoverSelected && (
                    <Box
                      sx={{ cursor: "pointer" }}
                      zIndex={100}
                      position={"absolute"}
                      top={"0.1rem"}
                      right={"0.1rem"}
                      onClick={() => handleRemovePic("cover")}
                    >
                      <img src={smallCloseIcon} alt="close" />
                    </Box>
                  )}
                  <img
                    src={
                      coverImage?.isCoverSelected
                        ? coverImage?.src
                        : coverImage?.src
                    }
                    alt="name"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>

                <Box
                  display={"flex"}
                  position={"absolute"}
                  alignItems={"center"}
                  right={"1%"}
                  bottom={"2%"}
                  gap={"0.5rem"}
                >
                  <input
                    type="file"
                    name="cover"
                    accept="image/*"
                    ref={coverPicRef}
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                  />
                  <Box>
                    <Typography
                      variant="h6"
                      fontSize={"0.85rem"}
                      color={"#fff"}
                    >
                      Optional
                    </Typography>
                  </Box>
                  <ActionButton
                    variant="contained"
                    size="small"
                    onClick={() => handleButtonClick("cover")}
                  >
                    <img src={editLogo} alt="edit" />
                  </ActionButton>
                </Box>

                <Box
                  bgcolor={"#183242"}
                  position={"absolute"}
                  left="50%"
                  marginLeft={"-50px"}
                  transform="translateX(-40%)"
                  bottom="-30%"
                  borderRadius={"0.2rem"}
                  overflow={"hidden"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    position={"relative"}
                    height={"100px"}
                    width={"100px"}
                    minHeight={"100%"}
                    minWidth={"100%"}
                    bgcolor={"#EAEAEA"}
                    padding={
                      profileImage?.isProfileSelected ? "0rem" : "0.9rem"
                    }
                  >
                    {profileImage?.isProfileSelected && (
                      <Box
                        sx={{ cursor: "pointer" }}
                        zIndex={100}
                        position={"absolute"}
                        top={"0.1rem"}
                        right={"0.1rem"}
                        onClick={() => handleRemovePic("profilepic")}
                      >
                        <img src={smallCloseIcon} alt="close" />
                      </Box>
                    )}
                    <img
                      src={
                        profileImage?.isProfileSelected
                          ? profileImage?.src
                          : profileImage?.src
                      }
                      alt="name"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "fill",
                      }}
                    />
                    <Box position={"absolute"} right={"1%"} bottom={"1.5%"}>
                      <input
                        type="file"
                        accept="image/*"
                        ref={profilePicRef}
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                      />
                      <ActionButton
                        variant="contained"
                        size="small"
                        onClick={() => handleButtonClick("profile")}
                      >
                        <img src={cameraLogo} alt="edit" />
                      </ActionButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>

            <Box sx={classes?.formBox}>
              <form
                onSubmit={handleSubmit}
                style={{
                  height: "100%",
                }}
              >
                <Box
                  height={"100%"}
                  overflow={"auto"}
                  display={"flex"}
                  flexDirection={"column"}
                  sx={{
                    "&::-webkit-scrollbar": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#888",
                      borderRadius: "3px",
                    },
                  }}
                >
                  <Box flex={1}>
                    <FormControl sx={{}}>
                      <TextField
                        size="small"
                        type="text"
                        label={appContent?.organizationName}
                        variant="standard"
                        name="subscriber_firm_name"
                        required
                        onChange={handleChange}
                        fullWidth
                        InputLabelProps={{
                          style: {
                            fontSize: "0.85rem",
                          },
                        }}
                      />
                      <FormHelperText sx={classes?.captions}>
                        Required
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  <Box flex={1}>
                    <FormControl>
                      <TextField
                        size="small"
                        type="text"
                        label={appContent?.website}
                        onChange={handleChange}
                        variant="standard"
                        name="website"
                        InputLabelProps={{
                          style: {
                            fontSize: "0.85rem",
                          },
                        }}
                        fullWidth
                      />
                      <FormHelperText sx={classes?.captions}>
                        Optional(highly recommended)
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  <Box flex={1}>
                    <FormControl>
                      <TextField
                        size="small"
                        label="Industry"
                        onChange={handleChange}
                        onFocus={() => handleDrawerOpen("industry")}
                        variant="standard"
                        value={masterListData?.industryName}
                        name="industry"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <ArrowForwardIosOutlined />
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          shrink: !!masterListData?.industryName
                            ? true
                            : undefined,
                          style: {
                            fontSize: "0.85rem",
                          },
                        }}
                      />
                      <FormHelperText sx={classes?.captions}>
                        Required
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  <Box flex={1}>
                    <FormControl>
                      <TextField
                        size="small"
                        type="text"
                        label={appContent?.city}
                        variant="standard"
                        value={userProfileData?.city}
                        name="city"
                        onChange={handleChange}
                        required
                        fullWidth
                      />
                      <FormHelperText sx={classes?.captions}>
                        Required
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <FormControl>
                      <TextField
                        size="small"
                        value={masterListData?.countryName}
                        type="text"
                        label={appContent?.country}
                        variant="standard"
                        name="country"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <ArrowForwardIosOutlined />
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          shrink: !!masterListData?.countryName
                            ? true
                            : undefined,
                          style: {
                            fontSize: "0.85rem",
                          },
                        }}
                        onFocus={() => handleDrawerOpen("country")}
                        required
                        fullWidth
                      />
                      <FormHelperText sx={classes?.captions}>
                        Required
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <Box sx={classes.buttonStyle}>
                    <AuthActionButton type="submit" variant="contained">
                      {appContent?.finish}
                    </AuthActionButton>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NewOrganisationProfile;
