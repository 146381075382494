import React, { useEffect, useState } from "react";
import { Box, Button, Drawer, Typography } from "@mui/material";
import right_arrow_icon from "../../../assets/images/subscription/right_arrow_icon.svg";
import UpdateBillingAddress from "./UpdateBillingAddress";
import { useDispatch, useSelector } from "react-redux";
import {
  getBillingAddress,
  getSubscriptionDetail,
} from "../../../appStore/actions/Subscription/action";
import ManageMemberFromSubscription from "./ManageMemberFromSubscription";
import { MANAGE_SUBSCRIPTION_DATA } from "../../../appStore/actions/Subscription/type";
import CancelSubscription from "./CancelSubscription";

const RenewHome = () => {
  const [isManageBillingAddressOpen, setBillingAddressOpen] = useState(false);
  const [isManageMemberOpen, setManageMemberOpen] = useState(false);
  const [isCancleSubscriptionOpen, setCancleSubscriptionOpen] = useState(false);
  const dispatch = useDispatch();
  const currentuserdetails = useSelector(
    (state) => state?.profile?.profileData?.data
  );
  const subscriptionDetails = useSelector(
    (state) => state?.subscription?.subscriptionDetails
  );

  const handleManageClick = (data) => {
    dispatch({
      type: MANAGE_SUBSCRIPTION_DATA,
      payload: data,
    });
    setManageMemberOpen(true);
  };
  const handleCancleClick = (data) => {
    dispatch({
      type: MANAGE_SUBSCRIPTION_DATA,
      payload: data,
    });
    setCancleSubscriptionOpen(true);
  };

  useEffect(() => {
    dispatch(getSubscriptionDetail(currentuserdetails?.activeSubscriber?.id));
    dispatch(getBillingAddress());
  }, []);
  return (
    <>
      <Drawer
        sx={{ zIndex: 1202 }}
        open={isManageBillingAddressOpen}
        anchor={"right"}
      >
        <UpdateBillingAddress setBillingAddressOpen={setBillingAddressOpen} />
      </Drawer>
      <Drawer
        sx={{ zIndex: 1202 }}
        open={isCancleSubscriptionOpen}
        anchor={"right"}
      >
        <CancelSubscription
          setCancleSubscriptionOpen={setCancleSubscriptionOpen}
        />
      </Drawer>

      <Drawer sx={{ zIndex: 1202 }} open={isManageMemberOpen} anchor={"right"}>
        <ManageMemberFromSubscription
        subscriptionDetails={subscriptionDetails}
          setManageMemberOpen={setManageMemberOpen}
        />
      </Drawer>
      <Box
        overflow={"scroll"}
        height={"calc(100vh - 8rem)"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "1px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#F9F8F8",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "grey",
          },
          // "@media (max-width: 1194px)": {
          //   height: "calc(100vh - 20rem)",
          // },
        }}
      >
        <Box width={"95%"}>
          {subscriptionDetails?.length > 0 &&
            subscriptionDetails?.map((item, i) => (
              <Box
                borderRadius={"2px"}
                bgcolor={"#F3F3F3"}
                padding={"0.5rem 0rem"}
                boxShadow="0px 1px 4px 0px #00000040"
                mb={"0.8rem"}
                key={i + "subscription"}
              >
                <Box width={"90%"} margin={"auto"} textAlign={"center"}>
                  <Typography
                    variant="h6"
                    fontSize={"1rem"}
                    color={"#183243"}
                    fontWeight={600}
                  >
                    {item?.name}
                  </Typography>
                  <Box mt={"0.5rem"}>
                    <Typography
                      fontWeight={600}
                      variant="caption"
                      fontSize={"1rem"}
                    >
                      {item?.symbol} {item?.price}
                    </Typography>
                    <Typography variant="caption" display={"block"}>
                      for {item?.quantity} person/month, billed monthly
                    </Typography>
                    <Typography
                      fontWeight={600}
                      variant="caption"
                      fontSize={"0.8rem"}
                    >
                      Billing cycle : {item?.billingCycle}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  width={"90%"}
                  margin={"auto"}
                  textAlign={"center"}
                  mt={"0.5rem"}
                >
                  <Box
                    display={"flex"}
                    gap={"0.4rem"}
                    justifyContent={"center"}
                  >
                    <Button
                      onClick={() => handleCancleClick(item)}
                      sx={{
                        backgroundColor: "transparent",
                        color: "#183243",
                        padding: "0.2rem 0.4rem",
                        textTransform: "none",
                        borderRadius: "0.15rem",
                        border: "1px solid #DB0011",
                        ":hover": {
                          backgroundColor: "transparent",
                          color: "#183243",
                        },
                      }}
                      variant="contained"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => handleManageClick(item)}
                      sx={{
                        backgroundColor: "#DB0011",
                        color: "#fff",
                        padding: "0.2rem 0.4rem",
                        textTransform: "none",
                        borderRadius: "0.15rem",
                        ":hover": {
                          backgroundColor: "#DB0011",
                          color: "#fff",
                        },
                      }}
                      variant="contained"
                    >
                      Manage
                    </Button>

                    {/* <Button
                    sx={{
                      backgroundColor: "#DB0011",
                      color: "#fff",
                      padding: "0.2rem 0.4rem",
                      textTransform: "none",
                      borderRadius: "0.15rem",
                      ":hover": {
                        backgroundColor: "#DB0011",
                        color: "#fff",
                      },
                    }}
                    variant="contained"
                  >
                    Renew
                  </Button> */}
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>

        <Box width={"95%"}>
          <Box
            sx={{ cursor: "pointer" }}
            padding={"0.8rem 0.8rem 0.8rem 0.5rem"}
            bgcolor={"#F3F3F3"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography>Payment History</Typography>
            <Box>
              <img src={right_arrow_icon} alt="icon" />
            </Box>
          </Box>

          <Box
            sx={{ cursor: "pointer" }}
            mt={"0.6rem"}
            mb={"0.6rem"}
            padding={"0.8rem 0.8rem 0.8rem 0.5rem"}
            bgcolor={"#F3F3F3"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            onClick={() => setBillingAddressOpen(true)}
          >
            <Typography>Manage billing information</Typography>
            <Box>
              <img src={right_arrow_icon} alt="icon" />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RenewHome;
