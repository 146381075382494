import React, { useEffect, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import { CloseButton, CloseIcon } from "../page/Profille/CustomDrawer";
import closeIcon from "../assets/images/chats/closeLogo.svg";
import { useDispatch } from "react-redux";
import { getInviteUrl } from "../appStore/actions/subscriber/action";
import { handleCopyClick } from "../utils/copytoClipboard";
import { showToastMsg } from "../appStore/actions/global/action";
import { useTranslation } from "react-i18next";
import AlertPopUp from "./AlertPopUp";
import useAppSelectors from "../customHooks/useAppSelectors";
import { AccountLevelArkchatPermission, InviteType } from "../utils/constant";

const InvitePopup = ({ closePopUp }) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const { currentUserData } = useAppSelectors();
  const [isPermissionModalOpen, setPermissionModal] = useState(false);
  const [internalInviteLink, setInternalInviteLink] = useState("");
  const [externalInviteLink, setExternalInviteLink] = useState("");

  const dispatch = useDispatch();

  const handleCopyClickCallback = (type) => {
    if (type === "error") {
      dispatch(showToastMsg(appContent?.wrongError));
    } else {
      dispatch(showToastMsg(appContent?.copiedClipboard));
    }
    closePopUp();
  };

  const handleCopyInvite = (inviteLink, type) => {
    if (inviteLink) {
      if (
        currentUserData?.activePermission ===
          AccountLevelArkchatPermission?.USER &&
        type !== InviteType?.external
      ) {
        setPermissionModal(true);
      } else {
        handleCopyClick(inviteLink, handleCopyClickCallback);
      }
    } else {
      if (
        currentUserData?.activePermission ===
          AccountLevelArkchatPermission?.USER &&
        type !== InviteType?.external
      ) {
        setPermissionModal(true);
      } else {
        dispatch(showToastMsg(appContent?.wrongError));
      }
    }
  };

  const handleGetInviteLink = async (type) => {
    const res = await dispatch(getInviteUrl(type));
    const inviteMessage = res?.data?.inviteMessage;

    if (type === InviteType.internal) {
      setInternalInviteLink(inviteMessage);
    } else if (type === InviteType.external) {
      setExternalInviteLink(inviteMessage);
    }
  };

  const fetchInviteLinks = async () => {
    if (
      currentUserData?.activePermission !== AccountLevelArkchatPermission?.USER
    ) {
      await handleGetInviteLink(InviteType.internal);
    }
    await handleGetInviteLink(InviteType.external);
  };

  useEffect(() => {
    fetchInviteLinks();
  }, [currentUserData]);

  return (
    <>
      <AlertPopUp
        title={"Access Denied ."}
        bodyContent={appContent?.inviteAlert}
        open={isPermissionModalOpen}
        closeModal={() => setPermissionModal(false)}
      />
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height="100%"
        bgcolor="rgba(0, 0, 0, 0.7)"
        zIndex={1202}
      >
        <Box
          zIndex={10}
          position="absolute"
          bgcolor="#FFFFFF"
          width={"28.5rem"}
          padding={"1rem"}
          top="27%"
          left="38%"
          transform={`translate(-50%, -50%)`}
          boxShadow={`0px 3.4402036666870117px 3.4402036666870117px 0px rgba(0, 0, 0, 0.25)`}
          borderRadius={"0.12rem"}
        >
          <Box display={"flex"} gap={"0.6rem"}>
            <Box>
              <CloseButton onClick={closePopUp}>
                <CloseIcon src={closeIcon} alt="close" />
              </CloseButton>
            </Box>
            <Box>
              <Typography fontSize={"1rem"} variant="h6">
                Invite People
              </Typography>
            </Box>
          </Box>

          <Box width={"81.8%"} margin={"auto"}>
            <Box mt={"0.5rem"}>
              <Typography
                fontWeight={"400"}
                fontSize={"0.75rem"}
                variant="h6"
                color={"#183242"}
                textAlign={"start"}
              >
                Your invitee will join your contacts. You can add them to groups
                by clicking the people icon in the respective groups.
              </Typography>
            </Box>

            <Box mb={"1rem"} mt={"1rem"}>
              <Button
                onClick={() => handleCopyInvite(internalInviteLink, 1)}
                sx={{
                  textTransform: "none",
                  backgroundColor: "#DB0011",
                  padding: "5px 10px",
                  color: "#fff",
                  fontSize: "0.8rem",
                  minWidth: "unset",
                  minHeight: "unset",
                  borderRadius: "0.15rem",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#DB0011",
                    color: "#fff",
                  },
                }}
                disabled={!internalInviteLink&&!externalInviteLink}
              >
                {appContent?.internalContact}
              </Button>
              <Typography
                mt={"0.5rem"}
                display={"block"}
                variant="caption"
                fontSize={"0.75rem"}
              >
                Your colleagues
              </Typography>
            </Box>

            <Box>
              <Button
                onClick={() => handleCopyInvite(externalInviteLink, 2)}
                sx={{
                  textTransform: "none",
                  backgroundColor: "#DB0011",
                  padding: "5px 10px",
                  color: "#fff",
                  fontSize: "0.8rem",
                  minWidth: "unset",
                  minHeight: "unset",
                  borderRadius: "0.15rem",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#DB0011",
                    color: "#fff",
                  },
                }}
                disabled={!externalInviteLink&&!internalInviteLink}
              >
                {appContent?.externalContact}
              </Button>
              <Typography
                mt={"0.5rem"}
                display={"block"}
                variant="caption"
                fontSize={"0.75rem"}
              >
                Your vendor, partner, and customer
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default InvitePopup;
