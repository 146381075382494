import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NavigationBtnBox = ({
  handleNext,
  handleClick,
  activeStep,
  setActiveStep,
  isLoading,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const navigate = useNavigate();
  return (
    <Box
      width={"60%"}
      margin={"auto"}
      display={"flex"}
      gap={"1rem"}
      justifyContent={"center"}
      mt={"2.1rem"}
    >
      {activeStep !== 2 && (
        <>
          <Box>
            <Button
              onClick={() => navigate("/post")}
              variant="outlined"
              sx={{
                borderRadius: "2px",
                color: "#000000",
                border: "1px solid #000000",
                ":hover": {
                  color: "#000000",
                  border: "1px solid #000000",
                },
              }}
            >
              {appContent?.cancel}
            </Button>
          </Box>
          <Box>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "2px",
                border: "1px solid #DB0011",
                backgroundColor: "#DB0011",
                color: "white",
                ":hover": {
                  border: "1px solid #DB0011",
                  backgroundColor: "#DB0011",
                  color: "white",
                },
              }}
              onClick={!isLoading ? handleClick : null}
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : appContent?.next}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default NavigationBtnBox;
