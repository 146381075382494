import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({
  priorityValue,
  lowPriorityColor,
  mediumPriorityColor,
  highPriorityColor,
  totalValue,
}) => {
  const options = {
    chart: {
      width: "24.5%",
      type: "pie",
    },
    labels: ["High", "Medium", "Low"],
    colors: [highPriorityColor, mediumPriorityColor, lowPriorityColor],
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
            position: "centre",
          },
        },
      },
    ],
  };

  const series = [
    priorityValue[0]?.count,
    priorityValue[1]?.count,
    priorityValue[2]?.count,
  ];

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        width={"100%"}
        height="300"
      />
    </div>
  );
};

export default BarChart;
