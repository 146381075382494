import React, { useEffect, useRef, useState } from "react";
import ChatSend from "../../../common/SvgImageView/Send";
import { Box, Paper, TextareaAutosize, Typography } from "@mui/material";
import {
  StyledBorderDiv,
  StyledFlex,
  StyledSpaceBetween,
} from "../../../Styled";
import LableToolTip from "../../../common/LableToolTip";
import { useTranslation } from "react-i18next";
import ChatAdd from "../../../assets/images/chat_add.svg";
import { generateUniqueId } from "../../../utils/copytoClipboard";
import { useDispatch } from "react-redux";
import { showToastMsg } from "../../../appStore/actions/global/action";
import useAppSelectors from "../../../customHooks/useAppSelectors";

import filesvg from "../../../assets/images/chats/file_svg.svg";
import googlesvg from "../../../assets/images/chats/google_svg.svg";
import youtubesvg from "../../../assets/images/chats/youtube_svg.svg";
import SelectedAttachment from "./SelectedAttachment";

// newMessages,
// handleMessageSend,
// handleOnChangeNewMessage,
// handleClickYouTube,
// setFileAttachment,
// handleClickGoogle,
// fileAttachment,
// attachment,
// setisTopicOpen,
// setAttachment,
const DrawerInput = ({
  taskData,
  checkedType,
  handleUpdateTaskApproval,
  setUpdateTaskApprovalMsg,
  updateTaskApprovalMsg,
  selectedRecipients,
  actualTimeValue,
  handleClickYouTube,
  setFileAttachment,
  handleClickGoogle,
  fileAttachment,
  attachment,
  setisTopicOpen,
  setAttachment,
  setReplyParentMsg,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const fileRef = useRef(null);
  const menuRef = useRef(null);
  const [allAttachment, setAllAttachment] = useState([]);
  const [isAttachmentMenuOpen, setAttachmentMenuOpen] = useState(false);
  const [isFileSectionDisabled, setFileSectionDisabled] = useState(false);
  const { selectedGroup } = useAppSelectors();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleCloseAttachmentMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const combinedLength = fileAttachment?.length + attachment?.length;
    const allFilesData = [...fileAttachment, ...attachment];
    setAllAttachment(allFilesData);

    // Setting the disabled state of the checkbox not more than 5 overall attachment
    setFileSectionDisabled(combinedLength === 5);
  }, [fileAttachment, attachment]);
  //
  const handleFileChange = (e) => {
    setAttachmentMenuOpen(false);
    const files = e.target.files; // Get the selected files
    const maxFiles = 5;
    if (files?.length + attachment.length > maxFiles) {
      e.preventDefault();
      dispatch(showToastMsg(`You can only select up to 5 attachment.`));
      return;
    }

    // Convert the FileList to an array

    const fileListWithIds = Array.from(files).map((file) => ({
      id: generateUniqueId(file?.name),
      file: file,
    }));

    // Update the state with the array of files
    setFileAttachment(fileListWithIds);
  };
  const handleAttachmentMenu = () => {
    if (selectedGroup) {
      if (setAttachmentMenuOpen) {
        setAttachmentMenuOpen(!isAttachmentMenuOpen);
      }
    }
  };
  const handleCloseAttachmentMenu = () => {
    setAttachmentMenuOpen(false);
  };

  const handleRemoveAttachment = (attachmentId) => {
    // Remove the file with the specified ID from the state
    const updatedattachment = attachment?.filter(
      (file) => file.id !== attachmentId
    );
    setAttachment(updatedattachment);
    setReplyParentMsg("");
    const updatedFileattachment = fileAttachment?.filter(
      (file) => file.id !== attachmentId
    );
    setFileAttachment(updatedFileattachment);

    setAllAttachment([...updatedFileattachment, ...updatedattachment]);
  };

  const triggerHandelSend = () => {
    if (updateTaskApprovalMsg) {
      handleUpdateTaskApproval(taskData);
    }
  };
  return (
    <StyledBorderDiv
      style={{
        position: "relative",
        zIndex: 1201,
        backgroundColor: "#ffff",
        marginBottom: "0.6rem",
        padding:"0rem 0.25rem"
      }}
    >
      <TextareaAutosize
        style={{
          width: "100%",
          resize: "vertical",
          border: "none",
          outline: "none",
          lineHeight: "1.25rem",
          padding: "5px 8px",
          fontFamily: "Poppins",
          fontSize: "0.87rem",
          maxHeight: "62vh",
          minHeight: "1.1rem",
          overflow: "auto",
          marginBottom: "0.7rem",
        }}
        minRows={"1"}
        placeholder="Message ..."
        //   disabled={!selectedGroup}
        //   value={newMessages}
        onChange={(e) => setUpdateTaskApprovalMsg(e.target.value)}
      />
      {allAttachment?.length > 0 && (
        <Box
          width={"100%"}
          position={"relative"}
          height={"3rem"}
          overflow={"scroll"}
          sx={{
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              width: "1px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "transparent",
            },
          }}
        >
          <SelectedAttachment
            data={allAttachment}
            handleRemoveAttachment={handleRemoveAttachment}
          />
        </Box>
      )}
      <StyledSpaceBetween
       style={{
        width: "100%",
        alignItems:"center"
      }}
      >
        <input
          type="file"
          multiple
          name="file"
          ref={fileRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <StyledFlex>
          <Box position={"relative"} width={"30px"} height={"30px"}>
            {isAttachmentMenuOpen && (
              <Box
                ref={menuRef}
                position="absolute"
                top={"-9.2rem"}
                right={"-10.5rem"}
                width={"12.3rem"}
              >
                <Paper
                  sx={{
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                    padding: "0.7rem 0rem",
                  }}
                >
                  <Box>
                    <Box
                      p={"0.4rem 1rem"}
                      sx={{
                        cursor: "pointer",
                        pointerEvents: isFileSectionDisabled ? "none" : "",
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        onClick={() => fileRef.current.click()}
                      >
                        <Box>
                          <Typography>Files</Typography>
                        </Box>
                        <Box>
                          <img src={filesvg} alt="file" />
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      mt={"0.7rem"}
                      p={"0.4rem 1rem"}
                      sx={{ cursor: "pointer" }}
                      onClick={handleClickGoogle}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box>
                          <Typography>Google</Typography>
                        </Box>
                        <Box>
                          <img src={googlesvg} alt="file" />
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      mt={"0.7rem"}
                      p={"0.4rem 1rem"}
                      sx={{ cursor: "pointer" }}
                      onClick={handleClickYouTube}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box>
                          <Typography>Youtube</Typography>
                        </Box>
                        <Box>
                          <img src={youtubesvg} alt="file" />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            )}
            <LableToolTip title={appContent?.chatAttachmentBtnLable}>
              <img
                className="chat-input-icon"
                src={ChatAdd}
                alt="attachment"
                onClick={handleAttachmentMenu}
              />
            </LableToolTip>
          </Box>
        </StyledFlex>

        <StyledFlex>
          <Box onClick={triggerHandelSend} className="chat-input-left-icon">
            <ChatSend
              color={
                (updateTaskApprovalMsg && checkedType) ||
                (selectedRecipients?.selectedInternalRecipients?.length > 0 &&
                  checkedType) ||
                (selectedRecipients?.selectedExternalRecipients?.length > 0 &&
                  checkedType)
                  ? null
                  : "#8C8C8C"
              }
            />
          </Box>
        </StyledFlex>
      </StyledSpaceBetween>
    </StyledBorderDiv>
  );
};

export default DrawerInput;
