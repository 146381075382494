import React, { useState } from "react";
import {
  Box,
  Typography,
  Drawer,
  Stack,
  Button,
  ListItem,
  List,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
  },
  header: {
    // backgroundColor: '#183243',
    backgroundColor: "#F9F8F8",
    color: "#000000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};

const ExternalAccountGroupInviteTooltip = () => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [isDrawerOpen, setDrawerOpen] = useState(true);
  const handleDone = () => {
    setDrawerOpen(false);
    localStorage.removeItem("inviteName");
  };
  return (
    <Drawer sx={{ zIndex: 1202 }} open={isDrawerOpen} anchor="right">
      <Box sx={classes.drawerBody}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={classes.header}
        >
          <Typography onClick={handleDone} sx={classes.iconDiv}>
            <CloseIcon sx={classes.iconn} />
          </Typography>
          <Typography>{appContent?.tooltip}</Typography>
        </Stack>

        <Box width={"95%"} margin={"auto"} padding={"0rem 0.5rem"}>
          <Typography fontSize={"0.875rem"} fontWeight={600} mb={"0.8rem"}>
            {appContent?.welcometoArkchat}
          </Typography>

          <Typography fontSize={"0.875rem"} mb={"0.8rem"}>
            {appContent?.KEY7}{" "}
            <b>{JSON.parse(localStorage.getItem("inviteName"))} </b>.
          </Typography>

          <Typography fontSize={"0.875rem"} mb={"0.8rem"}>
            {appContent?.KEY8}
          </Typography>

          <Typography fontSize={"0.875rem"} mb={"0.8rem"}>
            {appContent?.KEY9}
          </Typography>

          <Typography fontSize={"0.875rem"}>{appContent?.KEY10}:</Typography>

          <Box width={"90%"} margin={"auto"}>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                "& .MuiListItem-root": {
                  display: "list-item",
                  paddingTop: "0px",
                  paddingLeft: "0px",
                },
              }}
            >
              <ListItem>
                <Typography fontSize={"0.875rem"}>
                  {appContent?.KEY2}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography fontSize={"0.875rem"}>
                  {appContent?.KEY3}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography fontSize={"0.875rem"}>
                  {appContent?.KEY11}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography fontSize={"0.875rem"}>
                  {appContent?.KEY1}
                </Typography>
              </ListItem>
            </List>
          </Box>

          <Typography fontSize={"0.875rem"} mb={"0.8rem"}>
            {appContent?.KE12}
          </Typography>

          <Typography fontSize={"0.875rem"}>{appContent?.KE13}</Typography>
        </Box>

        <Box
          textAlign={"center"}
          width={"100%"}
          position={"absolute"}
          bottom={"1.5rem"}
        >
          <Button
            onClick={handleDone}
            style={{
              background: "#DB0011",
              color: "white",
              borderRadius: "3px",
              textTransform: "none",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
            variant="contained"
          >
            Got it
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default ExternalAccountGroupInviteTooltip;
