export const handleCopyClick = (textToCopy, callback) => {
  const isIOS = /ipad|iphone|ipod/i.test(navigator.userAgent) || 
                (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  // Fallback for iOS or Safari
  if (!navigator.clipboard || isIOS || isSafari) {
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    textarea.style.position = "fixed";
    textarea.style.opacity = "0";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    try {
      const successful = document.execCommand("copy");
      document.body.removeChild(textarea);
      if (successful) {
        callback(); // Success callback
      } else {
        callback("error"); // Error callback
      }
    } catch (err) {
      document.body.removeChild(textarea);
      callback("error"); // Error callback
    }

    return;
  }

  // Clipboard API for non-iOS, non-Safari environments
  navigator.clipboard
    .writeText(textToCopy)
    .then(() => {
      callback(); // Success callback
    })
    .catch((err) => {
      console.error("Clipboard API error:", err);
      callback("error"); // Error callback
    });
};



 export function generateUniqueId(prefix = 'id') {
    const timestamp = Date.now().toString(36);
    const randomNumber = Math.random().toString(36).substring(2, 8);
    return `${prefix}-${timestamp}-${randomNumber}`;
  }
