import React, { useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import notificationImg from "../../../assets/images/notificationimg.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllNotification,
  clearNotificationById,
  getAllNotification,
} from "../../../appStore/actions/notification/action";
import NotificationDetails from "./NotificationDetails";
import { useTranslation } from "react-i18next";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth:"31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const Notification = ({ setNotification }) => {
  const allNotificationData = useSelector(
    (state) => state?.notification?.allNotificationData
  );
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  //
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllNotification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //

  const handleClearAllNotification = () => {
    dispatch(clearAllNotification()).then((res) => {
      if (res?.status) {
        dispatch(getAllNotification());
      }
    });
  };

  const handleClearSingleNotification = (id) => {
    dispatch(clearNotificationById(id)).then((res) => {
      if (res?.status) {
        dispatch(getAllNotification());
      }
    });
  };

  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
            spacing={2}
            sx={classes.header}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography sx={classes.iconDiv}>
                <CloseIcon
                  sx={classes.iconn}
                  onClick={() => setNotification(false)}
                />
              </Typography>
              <Typography color={"#000000"}>{appContent?.notification}</Typography>
            </Stack>
          </Stack>

          {allNotificationData?.data?.length === 0 && (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign={"center"}
              spacing={1}
              padding="12px 19px 12px 12px"
              height={"calc(100vh - 160px)"}
            >
              <Typography>
                <img src={notificationImg} alt="Notification Bell" />
                <Typography>
                  {appContent?.emptyNotificationMsg}
                </Typography>
              </Typography>
            </Stack>
          )}

          {allNotificationData?.data?.length > 0 && (
            <Box p={"0.5rem"} mt={"0.6rem"}>
             
              <Box
                mt={"0.6rem"}
                maxHeight={"29rem"}
                overflow={"auto"}
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888",
                    borderRadius: "3px",
                  },
                }}
              >
                {allNotificationData?.data &&
                  allNotificationData?.data?.map((data,i) => (
                    <NotificationDetails
                      handleClearSingleNotification={
                        handleClearSingleNotification
                      }
                      data={data}
                      key={data?.message+"allNotificationData"+i}
                    />
                  ))}
              </Box>
            </Box>
          )}
        </Stack>

        {allNotificationData?.data?.length > 0 && (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            padding="0.25rem "
            key={allNotificationData?.data?.id}
          >
            <Button
              onClick={handleClearAllNotification}
              style={{
                background: "#DB0011",
                color: "white",
                borderRadius: "0px",
                textTransform: "none",
              }}
              variant="contained"
            >
              {appContent?.clear}
            </Button>
          </Stack>
        )}
      </Box>
    </React.Fragment>
  );
};

export default Notification;
