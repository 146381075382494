/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { FormControl } from "@mui/base";
import {
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Box,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { AuthActionButton } from "../../common/StyledComponents/AuthComponents";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CarouselComponent from "../auth/Carousel/Carousel";
import backArrow from "../../assets/images/chats/back_arrow.svg";

const classes = {
  blockInputBox: {
    width: "100%",
    marginTop: "1.5rem",
  },
  createNewBox: {
    padding: "1.5rem 1rem 2rem 2rem",
    height: "calc(100vh - 82px)",
    position: "relative",
  },
  resetPasswordBox: {
    padding: "1.5rem 1rem 2rem 2rem",
    height: "85vh",
    position: "relative",
  },
  captions: {
    fontSize: "0.87rem !important",
    display: "block",
  },
};
const ChangeEmail = () => {
  const userData = useSelector((state) => state?.profile?.profileData);
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [passwordError, setPasswordError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };
  return (
    <>
      <>
        <Box>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={5}>
              <CarouselComponent showLogo={false} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                m={1}
                sx={classes.createNewBox}
                boxShadow={"0px 4px 14px rgba(0, 0, 0, 0.09)"}
                borderRadius={"2px"}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <Box
                    onClick={() => navigate("/settings")}
                    sx={{ cursor: "pointer" }}
                    mr={"0.7rem"}
                    mt={"0.15rem"}
                  >
                    <img src={backArrow} alt="back" />
                  </Box>
                  <Box>
                    <Typography
                      fontSize={"1.7rem"}
                      fontWeight={600}
                      variant="h5"
                    >
                      Change email
                    </Typography>
                  </Box>
                </Box>

                <Box mt={""}>
                  <form >
                    <FormControl>
                      <TextField
                        type={"email"}
                        variant="standard"
                        name="password"
                        value={userData?.data?.email}
                        required
                        disabled
                        sx={classes.blockInputBox}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        type={"email"}
                        label={"New Email"}
                        variant="standard"
                        name="reEnterPassword"
                        required
                        sx={classes.blockInputBox}
                      />
                      <FormHelperText>{appContent?.required}</FormHelperText>
                    </FormControl>
                    <Box sx={{ position: "absolute", bottom: "2rem" }}>
                      <AuthActionButton
                        type="submit"
                        variant="contained"
                      >
                        Next
                      </AuthActionButton>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    </>
  );
};

export default ChangeEmail;
