import { ALL_NOTIFICATION_DONE } from "../../actions/notification";

const initState = {
  data: [],
  error: null,
  status: null,
};

export const notificationReducer = (state = initState, action) => {
  switch (action.type) {
    case ALL_NOTIFICATION_DONE:
      return {
        ...state,
        allNotificationData: {
          data: action?.payload,
          status: action?.payload?.data?.status,
          error: null,
        },
      };
    default:
      return state;
  }
};
