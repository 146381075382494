import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import IntroVideo from "../common/IntroVideo";
import playStore_icon from "../assets/images/playStore_icon.svg";
import appleAppstore_icon from "../assets/images/appleAppstore_icon.svg";

const MobileView = () => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });

  return (
    <>
      <Box width={"90%"} margin={"auto"}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <IntroVideo height="28rem" />
          </Grid>
          <Grid item xs={12}>
            <Box mb={"0.8rem"}>
              {/* Additional content can be placed here */}
              <Typography mb={"0.8rem"} variant="h5">
                {appContent?.signup}
              </Typography>
              <Typography variant="body1">
                {appContent?.mobileViewSubtitle1}
              </Typography>
              <Box mt={"1rem"} mb={"1rem"}>
                <Box textAlign={"center"} mb={"0.5rem"}>
                  <a href="https://apps.apple.com/in/app/arkchat/id1618932796">
                    <img
                      style={{ cursor: "pointer" }}
                      src={appleAppstore_icon}
                      alt=""
                    />
                  </a>
                </Box>
                <Box textAlign={"center"}>
                  <a href="https://play.google.com/store/apps/details?id=com.arkchat">
                  <img
                    style={{ cursor: "pointer" }}
                    src={playStore_icon}
                    alt=""
                  />
                  </a>
                </Box>
              </Box>
              <Typography variant="body1">
                {appContent?.mobileViewSubtitle2}{" "}
                <a href="https://platform.arkchat.com/">
                  https://platform.arkchat.com/
                </a>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MobileView;
