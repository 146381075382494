import React from "react";
import { useSelector } from "react-redux";
import FilterCategory from "../../MarketPlace/FilterCategory";
import { serviceTypeName } from "../../../utils/constant";
const ExploreArtFilterTab = ({ handleFilter,clearFilter,setChecked,checked }) => {
  const artServiceCategoryData = useSelector(
    (state) => state?.serviceCategory?.artServiceCategoryData
  );
  const handleFilterType = (categoryId) => {
    handleFilter(serviceTypeName?.Art,categoryId);
  };
  return (
    <>
      <FilterCategory
        data={artServiceCategoryData}
        handleFilterApply={handleFilterType}
        clearFilter={clearFilter}
        checked={checked}
        setChecked={setChecked}
      />
    </>
  );
};

export default ExploreArtFilterTab;
