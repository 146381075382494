import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import {
  FilterIcon,
  StyledSideBarHeader,
  createStyledIndicatorButton,
} from "../../../common/StyledComponents/SideBarComponents";
import Filtericon from "../../../assets/images/task/Filter_icon.svg";
import { useTranslation } from "react-i18next";
const classes = {
  chatBox1: {
    minWidth: "280px",
    backgroundColor: "#F9F8F8",
    height: "calc(100vh - 64px)",
    width: "calc(100% - 71.7em)",
    maxWidth: "500px",
    "@media only screen and (max-width:900px)": {
      flex: "1",
    },
  },
  chatBox2: {
    backgroundColor: "#F9F8F8",
    height: "calc(100vh - 64px)",
    minWidth: "250px",
    maxWidth: "350px",
    "@media only screen and (max-width:900px)": {
      flex: "1",
    },
  },
  headingText: {
    color: "black",
    fontSize: "14px",
    fontWeight: "400",
    "@media (max-width: 63em)": {
      fontSize: "0.8rem",
    },
  },

  selectedItem: {
    // backgroundColor: "#183243",
    backgroundColor: "#E1E1E1",
    color: "#000",
  },
  nonselectedItem: {
    color: "#000",
  },
  taskmargin: {
    "@media only screen and (max-width:63em)": {
      marginLeft: "0 0px",
    },
  },
};
const Sidebar = ({
  handleCheckedFilter,
  filterChecked,
  sidebarmenudata,
  handleClearFilter,
  handleApplyFilter,
  currentHomeIndexTab,
  setSideBarOpen
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const SmallActionButton = createStyledIndicatorButton("#DB0011");

  return (
    <>
      <Box sx={{ height: "100%" }}>
        {/* Sidebar */}
        <StyledSideBarHeader position={"relative"}>
          <Box display="flex" alignItems="center" gap={"0.5rem"}>
            <SmallActionButton onClick={()=>setSideBarOpen(false)} size="small" variant="contained">
              <FilterIcon src={Filtericon} />
            </SmallActionButton>
            <Box>
              <Typography sx={classes.headingText} variant="h6">
                {appContent?.filter}
              </Typography>
            </Box>
          </Box>
        </StyledSideBarHeader>
        <Stack
          direction="column"
          justifyContent="space-between"
          sx={{ height: "85%" }}
        >
          <Stack>
            {sidebarmenudata?.map((item) => {
              return (
                <Stack
                  key={item?.label + "sidebarmenudata"}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                  padding="0px 19px 0px 12px"
                  marginLeft="30px"
                  sx={classes.taskmargin}
                >
                  <Typography>{item?.label}</Typography>
                  <FormControlLabel
                    checked={filterChecked?.some(
                      (selectedItem) => selectedItem?.type === item?.label
                    )}
                    name={item?.label}
                    value={item?.value}
                    onChange={handleCheckedFilter}
                    control={
                      <Checkbox
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 25,
                            color: "#183243",
                          },
                        }}
                      />
                    }
                  />
                </Stack>
              );
            })}
          </Stack>
          <Stack>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              padding="12px 19px 12px 12px"
            >
              <Button
                onClick={() => handleClearFilter(null)}
                sx={{
                  textTransform: "none",
                  background: "#DB0011",
                  color: "white",
                  borderRadius: "3px",
                }}
                variant="contained"
              >
                Clear
              </Button>

              <Button
                onClick={() => handleApplyFilter(filterChecked)}
                sx={{
                  textTransform: "none",
                  background: "#DB0011",
                  color: "white",
                  borderRadius: "3px",
                }}
                variant="contained"
              >
                Apply
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default Sidebar;
