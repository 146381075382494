import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import tick_gray from "../../../assets/images/chats/gray_light.svg";
import tick_green from "../../../assets/images/chats/double-tick_green.svg";
import { createStyledIndicatorButton } from "../../../common/StyledComponents/SideBarComponents";
import { useTranslation } from "react-i18next";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const RecipientList = ({
  recipientList,
  setRecipientListOpen,
  readby,
  handleShowUserProfile,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const NameBadge = createStyledIndicatorButton("#E3E1E1");
  return (
    <Box sx={classes?.drawerBody}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={classes.header}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography sx={classes.iconDiv}>
            <CloseIcon
              sx={classes.iconn}
              onClick={() => setRecipientListOpen(false)}
            />
          </Typography>
          <Typography color={"black"}>Message Recipients</Typography>
        </Stack>
      </Stack>

      <Box>
        <Box padding="0px 19px 0px 12px">
          <Typography variant="caption" color={"black"}>
            {appContent?.msgRecipientListDrawerSubTitle}
          </Typography>
        </Box>
        {recipientList?.map((item) => (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            padding="11px"
            key={item?.id + "recipientList"}
          >
            <NameBadge
              onClick={() => handleShowUserProfile(item?.id)}
              sx={{
                color: "#183243",
                padding: item?.pp && "0px",
                overflow: "hidden",
              }}
              size="small"
              variant="contained"
            >
              {item?.pp ? (
                <img src={item?.pp} alt="profile" height="100%" />
              ) : (
                <Typography color={"#183243"} fontSize={"0.75rem"}>
                  {item?.name && item?.name[0]}
                </Typography>
              )}
            </NameBadge>
            <Typography color={"#183243"} fontSize={"0.75rem"}>
              {item?.name}
            </Typography>
            <Box>
              <img
                src={readby?.includes(item?.id) ? tick_green : tick_gray}
                alt="sent"
              />
            </Box>
          </Stack>
        ))}
      </Box>
    </Box>
  );
};

export default RecipientList;
