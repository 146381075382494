import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { StyledSubHeadingBox } from "../chat/Tabs";
import PostServices from "./Tabs/PostServices";
import PostProduct from "./Tabs/PostProduct";
import PostArt from "./Tabs/PostArt";
import PostPhotos from "./Tabs/PostPhotos";
import PostVideos from "./Tabs/PostVideos";
import { serviceTypeValue } from "../../utils/constant";

// 1 = Service
// 2 = Product
// 3 = Arts
// 4 = Photo
// 5 = Video
const TabPanel = ({ children, value, index }) => (
  <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>
);
const PostTabs = ({
  activeStep,
  setActiveStep,
  value,
  setValue,
  postServiceTypeValue,
  setLoading,
  isLoading,
}) => {
  useEffect(() => {
    if (localStorage.getItem("editTabValue")) {
      setValue(JSON.parse(localStorage.getItem("editTabValue")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("editTabValue")]);

  return (
    <Box>
      <StyledSubHeadingBox>
        <Box justifyContent={"center"} width={"100%"}>
          <Box
            textAlign={"center"}
            width={"100%"}
            height={"1.5rem"}
            backgroundColor="#F3F3F3"
          >
            <Typography>
              Post {serviceTypeValue[postServiceTypeValue]}
            </Typography>
          </Box>
        </Box>
      </StyledSubHeadingBox>
      <TabPanel value={value} index={0}>
        {+postServiceTypeValue === 1 && (
          <PostServices
            setLoading={setLoading}
            isLoading={isLoading}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
          />
        )}
        {+postServiceTypeValue === 2 && (
          <PostProduct
            setLoading={setLoading}
            isLoading={isLoading}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
          />
        )}
        {+postServiceTypeValue === 3 && (
          <PostArt
            setLoading={setLoading}
            isLoading={isLoading}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PostPhotos
          setLoading={setLoading}
          isLoading={isLoading}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PostVideos
          setLoading={setLoading}
          isLoading={isLoading}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
        />
      </TabPanel>
    </Box>
  );
};

export default PostTabs;
