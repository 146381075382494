import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { createStyledIndicatorButton } from "../../../../common/StyledComponents/SideBarComponents";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#E1E1E1",
    color: "black",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },

  internalExternalButton: {
    background: "white",
    border: "1px solid #183243",
    color: "#183243",
    borderRadius: "0px",
    "&:hover": {
      background: "white",
    },
  },

  activeButton: {
    "&:hover": {
      background: "#183243",
    },
    background: "#183243",
    borderRadius: "0px",
    color: "white",
  },

  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  radiogp: {
    display: "block",
  },
  groupMember: {
    backgroundColor: "#F3F3F3",
    padding: "20px 10px",
  },

  // add group member
  addgmember: {
    transform: "translate(0, 70%)",
    textAlign: "center",
  },
  // add group member
};

const TaskTeamList = ({
  handlecloseTeamlist,
  handleSelectTaskMemberCheck,
  selectedTaskmember,
}) => {
  const accountLevelPeople = useSelector(
    (state) => state?.arkchatgroup?.accountLevelPeople
  );
  const NameBadge = createStyledIndicatorButton("#E3E1E1");

  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={classes.header}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography sx={classes.iconDiv}>
                <CloseIcon sx={classes.iconn} onClick={handlecloseTeamlist} />
              </Typography>
              <Typography variant="subtitle2">Team</Typography>
            </Stack>
          </Stack>
          <Stack
            mt={"0.5rem"}
            overflow={"scroll"}
            overflowY={"hidden"}
            height={"calc(100vh - 7rem)"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "2px",
                padding: "0px 2px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#F9F8F8",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "grey",
              },
            }}
            padding={" 10px 25px"}
          >
            <Typography
              sx={{ margin: "10px", fontWeight: "bold" }}
              variant="subtitle2"
            >
              Internal Members
            </Typography>
            {accountLevelPeople?.internalMember?.map((item) => {
              return (
                <FormControlLabel
                  key={item?.userId + "internalTaskTeamList"}
                  sx={{ marginBottom: "10px" }}
                  checked={
                    selectedTaskmember?.length > 0 &&
                    selectedTaskmember[0]?.userId === item?.userId
                  }
                  control={
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 30,
                          color: "#183243",
                        },
                      }}
                      onChange={(e) => handleSelectTaskMemberCheck(e, item)}
                      icon={
                        <NameBadge
                          sx={{ color: "#183243" }}
                          size="small"
                          variant="contained"
                        >
                          <Typography
                            textTransform={"uppercase"}
                            variant="h6"
                            fontSize={"0.75rem"}
                            color={"#183243"}
                          >
                            {item?.userInfo?.firstName &&
                              item?.userInfo?.firstName[0]}
                          </Typography>
                        </NameBadge>
                      }
                    />
                  }
                  label={`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                />
              );
            })}
            <Typography
              sx={{ margin: "10px", fontWeight: "bold" }}
              variant="subtitle2"
            >
              External Members
            </Typography>
            {accountLevelPeople?.externalMember?.map((item) => {
              return (
                <FormControlLabel
                  key={item?.userId + "externalTaskTeamList"}
                  sx={{ marginBottom: "10px" }}
                  checked={
                    selectedTaskmember?.length > 0 &&
                    selectedTaskmember[0]?.userId === item?.userId
                  }
                  control={
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 30,
                          color: "#183243",
                        },
                      }}
                      onChange={(e) => handleSelectTaskMemberCheck(e, item)}
                      icon={
                        <NameBadge
                          sx={{ color: "#183243" }}
                          size="small"
                          variant="contained"
                        >
                          <Typography
                            textTransform={"uppercase"}
                            variant="h6"
                            fontSize={"0.75rem"}
                            color={"#183243"}
                          >
                            {item?.userInfo?.firstName &&
                              item?.userInfo?.firstName[0]}
                          </Typography>
                        </NameBadge>
                      }
                    />
                  }
                  label={`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                />
              );
            })}
          </Stack>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default TaskTeamList;
