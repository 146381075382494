import { BASE_API_URL } from "../../../config/url"

export const CREATE_TOPIC_DONE = 'CREATE_TOPIC_DONE'
export const UPDATE_TOPIC_DONE = 'UPDATE_TOPIC_DONE'
export const DELETE_TOPIC_DONE = 'DELETE_TOPIC_DONE'
export const GET_TOPIC_DONE = 'GET_TOPIC_DONE'

export const CREATE_TOPIC_ERROR = 'CREATE_TOPIC_ERROR'
export const UPDATE_TOPIC_ERROR = 'UPDATE_TOPIC_ERROR'
export const DELETE_TOPIC_ERROR = 'DELETE_TOPIC_ERROR'
export const GET_TOPIC_ERROR = 'GET_TOPIC_ERROR'


// URL CREATEION 

export const CREATE_TOPIC_URL = `${BASE_API_URL}/group/topic/create`
export const UPDATE_TOPIC_URL = `${BASE_API_URL}/group/topic/edit`  // takes toipc id as a param 
export const DELETE_TOPIC_URL = `${BASE_API_URL}/group/topic`  // takes the topic id 
export const GET_TOPIC_URL = `${BASE_API_URL}/group/topic/all`

