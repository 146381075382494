import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCurrentAiSessionData,
  handleDeactivatePrivateChat,
  handleFetchConversation,
  handleFetchMessages,
  handleGetAllApprovalList,
  handleGetAllTaskList,
  handleGetApprovalAnalytics,
  handleGetTaskAnalytics,
  handleGetThreadMessage,
  handleSaveTempMsgData,
  handleTaskUpdate,
  handleUpdateMsgToDb,
  requestDeleteGroup,
  requestEditGroupName,
  uploadAttachment,
} from "../appStore/actions/ArkchatGroup/action";
import { deleteTopicData } from "../config/db";
import { profileUpdate } from "../appStore/actions/profile/action";
import {
  AccountLevelArkchatPermission,
  accountType,
  ApprovalStatus,
  GroupLevelArkchatPermission,
  MessageType,
  TaskStatus,
} from "../utils/constant";
import { getCookie } from "../config/cookie";
import {
  handleAppSelectedTopicToSaveMsg,
  handleHomeTabValue,
  handleOpenCloseThreadView,
  handleScrollToBottom,
  handleShowAIScreen,
  saveSelectedAppGroupData,
} from "../appStore/actions/AppState/action";
import dayjs from "dayjs";
import {
  RECEIVED_THREAD_MESSAGES,
  UPDATE_EXISTING_MESSAGE_LIST,
} from "../appStore/actions/ArkchatGroup/type";
import {
  generateRandomIV,
  handleDecryptSingleMessageData,
  msgEncrypter,
} from "../utils/messageEncrypt";
import { showToastMsg } from "../appStore/actions/global/action";
import { groupExternalMember } from "../utils/externalMemberGroping";
import { useTranslation } from "react-i18next";
import useAppSelectors from "./useAppSelectors";
import { logWrapper } from "../utils/helper";

const useTabState = ({
  pubnub,
  setFilterChecked,
  setNewMessages,
  setisTopicOpen,
  newMessages,
  dmUserDeatils,
  isAddPeopledarwer,
  setAddpeopledarwer,
  handleCloseAddPeopleDrawer,
  groupname,
  setGroupname,
  setShowSidebar,
  setTaskTabValue,
  setApprovalTabValue,
  selectedTaskGroup,
  setSelectedTaskGroup,
  selectedTaskmember,
  setSelectedTaskMember,
  taskApprovalViewValue,
  setTaskApprovalViewValue,
  setShareAiText,
  isShareAiText,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const { currentUserData, groupmemberdetails } = useAppSelectors();
  // const userData = useSelector((state) => state?.profile?.profileData);
  // const groupmemberdetails = useSelector(
  //   (state) => state?.arkchatgroup?.groupmember
  // );
  const messageList = useSelector((state) => state?.arkchatgroup?.messageList);
  const isAiScreenActive = useSelector(
    (state) => state?.appStateData?.isAiScreenActive
  );
  const accountLevelPeople = useSelector(
    (state) => state?.arkchatgroup?.accountLevelPeople
  );
  const groupTopicData = useSelector((state) => state?.topic?.groupTopicData);
  const selectedGroup = useSelector(
    (state) => state?.appStateData?.selectedGroupData
  );
  const selectedTopic = useSelector(
    (state) => state?.appStateData?.selectedTopicData
  );
  const selectedTopicToSaveMsg = useSelector(
    (state) => state?.appStateData?.selectedTopicToSaveMsg
  );
  const currentHomeIndexTab = useSelector(
    (state) => state?.localAppReducer?.currentHomeIndexTab
  );
  const isThreadScreen = useSelector(
    (state) => state?.localAppReducer?.isThreadScreen
  );
  const groupList = useSelector((state) => state?.arkchatgroup?.groupList);
  const groupRecipientDetails = useSelector(
    (state) => state?.arkchatgroup?.groupRecipient
  );
  const externalGroupRecipient = groupRecipientDetails?.filter(
    (item) => item?.targetSubscriberId !== currentUserData?.activeSubscriber?.id
  );
  const threadMsgList = useSelector(
    (state) => state?.arkchatgroup?.threadMsgList
  );
  const externalGroupRecipientCompanyWiseData = groupExternalMember(
    externalGroupRecipient,
    true,
    appContent?.individual
  );
  const dispatch = useDispatch();
  const [stepper, setStepper] = useState("user");
  const [initialopenDialog, setOpenInitialDialog] = useState(true);
  const [isGroupmenuOpen, setGroupmenuOpen] = useState(false);
  const [isEditGroupmenu, setEditGroupmenu] = useState(false);
  const [isDeleteGroupmenu, setDeletegroupmenu] = useState(false);
  // const [selectedgroupmenu, setselectedgroupmenu] = useState(null);
  const [delInputval, setdeleteInputVal] = useState("");
  const [delrequired, setDeleteRequired] = useState(false);
  const [isGroupmembermenu, setGroupMembermenu] = useState(false);
  const [isLanguageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [isdateAndTimeModalOpen, setDatAndTimeModal] = useState(false);
  const [isMediaFilesOpen, setMediaFilesOpen] = useState(false);
  const [dateFormate, setDateFormate] = useState("");
  const [isYYMMDD, setYYMMDD] = useState(false);
  const [isMMDDYY, setMMDDYY] = useState(false);
  const [isDDMMYY, setDDMMYY] = useState(false);
  const [is24Hour, set24Hour] = useState(false);
  const [isNotificationpreferenceModalOpen, setNotificationpreferenceModal] =
    useState(false);
  const [isEmailChecked, setEmailChecked] = useState(false);
  const [isMobileChecked, setMobileChecked] = useState(false);
  const [msgType, setMessageType] = useState(0);
  const [attachment, setAttachment] = useState([]);
  const [attachmentType, setAttachmenttype] = useState("");
  const [attachmentdrawer, setAttachmentDrawer] = useState({
    isYoutube: false,
    isGoogle: false,
    isfile: false,
    isArkchatExplore: false,
  });
  const [fileAttachment, setFileAttachment] = useState([]);
  const [replyParentMsg, setReplyParentMsg] = useState("");
  const [isdisableprivatchat, setdisableprivatechat] = useState(false);
  const [isPrivateDisableChat, setPrivateDisableChat] = useState(false);

  const [readStatusdrawer, setReadstatusdrawer] = useState(false);
  const [isPermissionModalOpen, setPermissionModal] = useState(false);
  const [isownershipright, setownershipright] = useState(false);

  const [taskButtonDrawer, setTaskButtonDrawer] = useState(null);
  const [aiQueryValue, setAiQueryValue] = useState("");
  const [tempAiQuery, setTempAiQuery] = useState("");
  const [showTempQuery, setShowTempQuery] = useState(false);
  const [showStaredAiHistory, setShowStaredAiHistory] = useState(false);
  const [selecteAnswer, setSelectedAnswer] = useState(null);
  const [queryParam, setQueryparam] = useState(null);
  const [selectRecipientsDrawerOpen, setSelectRecipientsDrawerOpen] =
    useState(false);
  const [selectedRecipients, setSelectedRecipients] = useState({
    selectedInternalRecipients: [],
    selectedExternalRecipients: [],
  });
  const [externalGroupMember, setExternalGroupMemebr] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [taskAssignedUser, setTaskAssignedUser] = useState({});
  const [dueTime, setDueTime] = useState(
    dayjs().set("hour", 23).set("minute", 59).set("second", 0)
  );
  const [dueDate, setDueDate] = useState(dayjs());
  const [priority, setPriority] = useState(1);
  const [isRepeat, setRepeat] = useState(false);
  const [repeatType, setRepeatType] = useState(null);
  const [selecteRepeatEvery, setSelectedRepeatEvery] = useState(null);
  const [isTaskUpdateOpen, setTaskUpdateOpen] = useState(false);
  const [taskData, setTaskData] = useState({});
  const [updateTaskApprovalMsg, setUpdateTaskApprovalMsg] = useState("");
  const [checkedType, setCheckedType] = useState(null);
  const [progress, setProgress] = useState(0);
  const [
    isThreadScreenFromTaskApprovalTab,
    setIsThreadScreenFromTaskApprovalTab,
  ] = useState(null);

  useEffect(() => {
    setPrivateDisableChat(
      !groupList?.find((item) => item?.id == selectedGroup?.id)?.isPrivateChatOn
    );
    setSelectedRecipients({
      selectedInternalRecipients: [],
      selectedExternalRecipients: [],
    });
    setPriority(1);
    // setDueTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
    // setDueDate(dayjs("2024-05-12"));
    setTaskAssignedUser({});
    setExternalGroupMemebr({});
    setSelectRecipientsDrawerOpen(false);
    setMessageType(MessageType?.Message);
    setStepper("user");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup]);

  useEffect(() => {
    dispatch(handleGetAllTaskList(queryParam));
    dispatch(handleGetAllApprovalList(queryParam));
  }, [queryParam]);

  useEffect(() => {
    dispatch(handleOpenCloseThreadView(false));
    setReplyParentMsg("");
  }, [selectedGroup]);

  useEffect(() => {
    dispatch(handleOpenCloseThreadView(false));
    setReplyParentMsg("");
  }, [selectedGroup]);

  useEffect(() => {
    setDDMMYY(currentUserData?.dateFormat === "DD-MM-YY");
    setMMDDYY(currentUserData?.dateFormat === "MM-DD-YY");
    setYYMMDD(currentUserData?.dateFormat === "YY-MM-DD");
    setEmailChecked(currentUserData?.notifyOnEmail);
    setMobileChecked(currentUserData?.notifyOnMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleCloseInitialDialog = () => {
    setOpenInitialDialog(false);
  };

  useEffect(() => {
    setGroupname(selectedGroup?.name);
  }, [selectedGroup]);

  const handleSelectedGroupmenuClick = (item) => {
    switch (item) {
      case "edit":
        setEditGroupmenu(true);
        break;

      case "delete":
        setDeletegroupmenu(true);
        break;

      case "members":
        setGroupMembermenu(true);
        break;
      default:
        break;
    }
  };

  const handleSelectedGroupmenu = (selectedmenu) => {
    // setselectedgroupmenu(selectedmenu);
    handleSelectedGroupmenuClick(selectedmenu?.value);
  };

  const handleCloseMemberDrawer = () => {
    setGroupMembermenu(false);
  };

  const handleChange = (event, newValue) => {
    setSelectedTaskMember([]);
    setSelectedTaskGroup([]);
    setFilterChecked([]);
    setTaskApprovalViewValue(1);
    setShowSidebar(true);
    setTaskTabValue(0);
    setApprovalTabValue(0);
    dispatch(handleHomeTabValue(newValue));
    dispatch(handleScrollToBottom(true));
  };

  const handleclickAttherate = () => {
    if (selectedGroup?.isGroup) {
      setSelectRecipientsDrawerOpen(true);
    }
  };

  const handleCloseUserChatAttach = () => {
    setSelectRecipientsDrawerOpen(false);
  };

  const handleOpenGroupDrawer = () => {
    if (selectedGroup && (selectedGroup?.isGroup || !selectedGroup?.isGroup)) {
      setGroupmenuOpen(true);
    }
  };

  const handleCloseGroupMenu = () => {
    setGroupmenuOpen(false);
    // setselectedgroupmenu(null);
  };
  const handleLanguageCloseMenu = () => {
    setLanguageMenuOpen(false);
  };

  const handleOnChangeNewMessage = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      setNewMessages((prevMessages) => prevMessages + "\n");
    } else {
      setNewMessages(event.target.value);
    }
  };

  const handleOnInputChange = (event) => {
    setGroupname(event.target.value);
  };

  const handleOnDeleteInput = (event) => {
    setdeleteInputVal(event.target.value);
  };

  const handleDeleteGroup = async () => {
    if (delInputval) {
      if (delInputval === "DELETE") {
        setDeletegroupmenu(false);
        setdeleteInputVal("");
        await dispatch(requestDeleteGroup(selectedGroup?.id));
        deleteTopicData(selectedGroup?.id);
        //////
        handleCloseGroupMenu();
      } else {
      }
    } else {
      setDeleteRequired(true);
    }
  };

  const handleUpdateGroupname = async () => {
    const reqBody = {
      group_name: groupname,
      description: selectedGroup?.description,
    };
    if (groupname) {
      setEditGroupmenu(false);
      setGroupname("");
      dispatch(requestEditGroupName(selectedGroup?.id, reqBody)).then(
        (response) => {
          if (response?.data?.status) {
          } else {
            setGroupname(selectedGroup?.name);
            dispatch(saveSelectedAppGroupData(selectedGroup));
            setisTopicOpen(true);
          }
        }
      );
      handleCloseGroupMenu();
    }
  };

  const handleAddgroupMember = () => {
    if (selectedGroup && selectedGroup?.isGroup) {
      setAddpeopledarwer(true);
    }
  };

  const handleDateTimeUpdate = () => {
    if (dateFormate || is24Hour || !is24Hour) {
      const reqBody = {
        date_format: dateFormate ? dateFormate : currentUserData?.dateFormat,
        time_format: is24Hour ? "24" : "12",
      };
      dispatch(profileUpdate(reqBody)).then((res) => {
        if (res?.status) {
          setDatAndTimeModal(false);
        }
      });
    }
  };

  const handleNotificationPreference = () => {
    if (isEmailChecked || isMobileChecked) {
      const reqBody = {
        notify_on_email: isEmailChecked,
        notify_on_mobile: isMobileChecked,
      };
      dispatch(profileUpdate(reqBody)).then((res) => {
        if (res?.status) {
          setNotificationpreferenceModal(false);
        }
      });
    }
  };

  const handleClickTaskUpdate = (taskData) => {
    if (
      taskData?.assignee?.id === currentUserData?.id ||
      taskData?.from?.id === currentUserData?.id
    ) {
      if (!taskData?.id) {
        const reqBody = {
          pn_id: selectedGroup?.pnId,
          timetoken: [taskData?.temp_timetoken],
        };
        dispatch(handleFetchConversation(reqBody)).then((res) => {
          const decryptedData = handleDecryptSingleMessageData(res[0]?.message);
          setTaskData(decryptedData);
          setReplyParentMsg(decryptedData);
          setPriority(decryptedData?.priority);
          setMessageType(decryptedData?.type);
          setDueTime(dayjs(decryptedData?.due_time, "HH:mm"));
          setDueDate(dayjs(decryptedData?.due_date));
          setTaskUpdateOpen(true);
          dispatch(handleUpdateMsgToDb(decryptedData));
        });
      }
      if (currentUserData?.id && taskData?.id) {
        setReplyParentMsg(taskData);
        setTaskData(taskData);
        setPriority(taskData?.priority);
        setMessageType(taskData?.type);
        setDueTime(dayjs(taskData?.due_time, "HH:mm"));
        setDueDate(dayjs(taskData?.due_date));
        setTaskUpdateOpen(true);
      }
    }
  };

  const handleDmMsgLogic = async (dmUserDetails, initReqBody) => {
    const dmUserData = [
      ...accountLevelPeople?.externalMember,
      ...accountLevelPeople?.internalMember,
    ]?.filter((data) => data?.userId === dmUserDetails?.id);

    initReqBody.messageType = 3;
    initReqBody.priority = 0;
    dmUserData[0]?.userId !== currentUserData?.id &&
      initReqBody.recipients.push({
        id: dmUserData[0]?.userId,
        pp: dmUserData[0]?.userInfo?.profileImgUrl,
        name: `${dmUserData[0]?.userInfo?.firstName} ${dmUserData[0]?.userInfo?.lastName}`,
        target_subscriber_id: dmUserData[0]?.userInfo?.activeSubscriber?.id,
        languageCode: dmUserData[0]?.languageCode,
      });

    initReqBody.recipients.push({
      id: currentUserData?.id,
      pp: currentUserData?.profileImgUrl,
      name: `${currentUserData?.firstName} ${currentUserData?.lastName}`,
      target_subscriber_id: currentUserData?.activeSubscriber?.id,
      languageCode: currentUserData?.languageCode,
    });
  };

  const handleSendMsgToAllGroupMember = (initReqBody) => {
    initReqBody.topic_id = selectedTopicToSaveMsg
      ? selectedTopicToSaveMsg?.id
      : groupTopicData?.data[0]?.id;
    initReqBody.group_subscriber_id = selectedGroup?.subscriberId;
    initReqBody.group_id = selectedGroup?.id;
    initReqBody.is_all_group_member = true;

    // Use a Set to track unique recipient IDs
    const recipientSet = new Set();

    const addRecipient = (recipient) => {
      const identifier = `${recipient.id}-${recipient.target_subscriber_id}`;
      if (!recipientSet.has(identifier)) {
        recipientSet.add(identifier);
        initReqBody.recipients.push(recipient);
      }
    };

    // For internal members
    [...groupmemberdetails?.internalMember]?.forEach((item) => {
      if (
        item?.permission === AccountLevelArkchatPermission?.ADMIN ||
        item?.permissionType !== GroupLevelArkchatPermission?.REMOVE
      ) {
        addRecipient({
          id: item?.userId,
          pp: item?.userInfo?.profileImgUrl,
          name: `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`,
          target_subscriber_id: item?.subscriberId,
          languageCode: item?.languageCode,
        });
      }
    });

    // For external members
    externalGroupRecipient?.forEach((item) => {
      if (currentUserData?.activeSubscriber?.id !== item?.targetSubscriberId) {
        addRecipient({
          id: item?.userId,
          pp: item?.profileImgUrl,
          name: `${item?.firstName} ${item?.lastName}`,
          target_subscriber_id: item?.targetSubscriberId,
          languageCode: item?.languageCode,
        });
      }
    });
  };

  // const handleSendMsgToAllGroupMember = (initReqBody) => {
  //   initReqBody.topic_id = selectedTopicToSaveMsg
  //     ? selectedTopicToSaveMsg?.id
  //     : groupTopicData?.data[0]?.id;
  //   initReqBody.group_subscriber_id = selectedGroup?.subscriberId;
  //   initReqBody.group_id = selectedGroup?.id;
  //   initReqBody.is_all_group_member = true;
  //   // eslint-disable-next-line array-callback-return

  //   //for internal member
  //   [...groupmemberdetails?.internalMember]?.forEach((item) => {
  //     (item?.permission === AccountLevelArkchatPermission?.ADMIN ||
  //       item?.permission === AccountLevelArkchatPermission?.MANAGER ||
  //       item?.permissionType !== GroupLevelArkchatPermission?.REMOVE) &&
  //       initReqBody.recipients.push({
  //         id: item?.userId,
  //         pp: item?.userInfo?.profileImgUrl,
  //         name: `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`,
  //         target_subscriber_id: item?.userInfo?.activeSubscriber?.id,
  //         languageCode: item?.languageCode,
  //       });
  //   });
  //   //for external member
  //   externalGroupRecipient?.forEach((item) => {
  //     currentUserData?.activeSubscriber?.id !== item?.targetSubscriberId &&
  //       initReqBody.recipients.push({
  //         id: item?.userId,
  //         pp: item?.profileImgUrl,
  //         name: `${item?.firstName} ${item?.lastName}`,
  //         target_subscriber_id: item?.targetSubscriberId,
  //         languageCode: item?.languageCode,
  //       });
  //   });
  // };

  // const handleGroupPrivateMsgLogic = async (initReqBody) => {
  //   initReqBody.topic_id = selectedTopicToSaveMsg?.id
  //     ? selectedTopicToSaveMsg?.id
  //     : groupTopicData?.data[0]?.id;
  //   initReqBody.group_subscriber_id = selectedGroup?.subscriberId;
  //   initReqBody.group_id = selectedGroup?.id;
  //   initReqBody.is_all_group_member =
  //     [
  //       ...selectedRecipients?.selectedInternalRecipients,
  //       ...selectedRecipients?.selectedExternalRecipients,
  //     ]?.length === groupRecipientDetails?.length
  //       ? true
  //       : false;
  //   // for internal member user only (permission user)
  //   [...selectedRecipients?.selectedInternalRecipients]?.forEach((item) => {
  //     let prmesion = item?.permission || item?.accountPermission;
  //     const isParticipant = isThreadScreen
  //       ? threadMsgList?.[0]?.participants?.includes(item.userId)
  //       : false; // Default to false if not in thread mode

  //     if (
  //       prmesion !== AccountLevelArkchatPermission?.ADMIN &&
  //       item?.userId !== currentUserData?.id &&
  //       (!isThreadScreen || !isParticipant)
  //     ) {
  //       initReqBody.recipients.push({
  //         id: item?.userId,
  //         pp: item?.userInfo?.profileImgUrl,
  //         name: `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`,
  //         target_subscriber_id: item?.userInfo?.activeSubscriber?.id,
  //         languageCode: item?.languageCode,
  //       });
  //     }
  //   });

  //   // for internal member admin, owner, account manager, group manager
  //   [...groupmemberdetails?.internalMember]?.forEach((item) => {
  //     const isParticipant = isThreadScreen
  //       ? threadMsgList?.[0]?.participants?.includes(item.userId)
  //       : false; // Default to false if not in thread mode

  //     if (
  //       item?.permission === AccountLevelArkchatPermission?.ADMIN &&
  //       item?.userId !== currentUserData?.id &&
  //       (!isThreadScreen || !isParticipant)
  //     ) {
  //       initReqBody.recipients.push({
  //         id: item?.userId,
  //         pp: item?.userInfo?.profileImgUrl,
  //         name: `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`,
  //         target_subscriber_id: item?.userInfo?.activeSubscriber?.id,
  //         languageCode: item?.languageCode,
  //       });
  //     }
  //   });

  //   // for external member
  //   [...selectedRecipients?.selectedExternalRecipients]?.forEach((item) => {
  //     const isParticipant = isThreadScreen
  //       ? threadMsgList?.[0]?.participants?.includes(item.userId)
  //       : false; // Default to false if not in thread mode

  //     if (
  //       (item?.accountPermission !== AccountLevelArkchatPermission?.ADMIN ||
  //         item?.subscriberPriority === accountType?.Individual) &&
  //       item?.userId !== currentUserData?.id &&
  //       (!isThreadScreen || !isParticipant)
  //     ) {
  //       initReqBody.recipients.push({
  //         id: item?.userId,
  //         pp: item?.profileImgUrl,
  //         name: `${item?.firstName} ${item?.lastName}`,
  //         target_subscriber_id: item?.targetSubscriberId,
  //         languageCode: item?.languageCode,
  //       });
  //     }
  //   });

  //   externalGroupRecipientCompanyWiseData?.forEach((company, index) => {
  //     if (
  //       externalGroupMember[Object.keys(company)[0]]?.length > 0 &&
  //       Object.values(company)[0][0]?.subscriberPriority !==
  //         accountType?.Individual
  //     ) {
  //       Object.values(company)[0]?.forEach((item) => {
  //         if (
  //           item?.accountPermission === AccountLevelArkchatPermission?.ADMIN &&
  //           item?.userId !== currentUserData?.id
  //         ) {
  //           initReqBody.recipients.push({
  //             id: item?.userId,
  //             pp: item?.profileImgUrl,
  //             name: `${item?.firstName} ${item?.lastName}`,
  //             target_subscriber_id: item?.targetSubscriberId,
  //             languageCode: item?.languageCode,
  //           });
  //         }
  //       });
  //     }
  //   });

  //   // user sending msg
  //   initReqBody.recipients.push({
  //     id: currentUserData?.id,
  //     pp: currentUserData?.profileImgUrl,
  //     name: `${currentUserData?.firstName} ${currentUserData?.lastName}`,
  //     target_subscriber_id: currentUserData?.activeSubscriber?.id,
  //     languageCode: currentUserData?.languageCode,
  //   });
  // };

  const handleGroupPrivateMsgLogic = async (initReqBody) => {
    initReqBody.topic_id = selectedTopicToSaveMsg?.id
      ? selectedTopicToSaveMsg?.id
      : groupTopicData?.data[0]?.id;
    initReqBody.group_subscriber_id = selectedGroup?.subscriberId;
    initReqBody.group_id = selectedGroup?.id;
    initReqBody.is_all_group_member =
      [
        ...selectedRecipients?.selectedInternalRecipients,
        ...selectedRecipients?.selectedExternalRecipients,
      ]?.length === groupRecipientDetails?.length
        ? true
        : false;

    // Use a Set to track unique recipient IDs
    const recipientSet = new Set();

    const addRecipient = (recipient) => {
      const identifier = `${recipient.id}-${recipient.target_subscriber_id}`;
      if (!recipientSet.has(identifier)) {
        recipientSet.add(identifier);
        initReqBody.recipients.push(recipient);
      }
    };

    // For internal member users only (non-admins)
    [...selectedRecipients?.selectedInternalRecipients]?.forEach((item) => {
      let prmesion = item?.permission || item?.accountPermission;
      let profilePic = item?.userInfo
        ? item?.userInfo?.profileImgUrl
        : item?.profileImgUrl;
      let userName = item?.userInfo
        ? `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`
        : `${item?.firstName} ${item?.lastName}`;
      let targetSubId = item?.userInfo
        ? item?.subscriberId
        : item?.targetSubscriberId;
      if (
        prmesion !== AccountLevelArkchatPermission?.ADMIN &&
        item?.userId !== currentUserData?.id
      ) {
        addRecipient({
          id: item?.userId,
          pp: profilePic,
          name: userName,
          target_subscriber_id: targetSubId,
          languageCode: item?.languageCode,
        });
      }
    });

    // For internal members who are admins, owners, etc.
    [...groupmemberdetails?.internalMember]?.forEach((item) => {
      if (
        item?.permission === AccountLevelArkchatPermission?.ADMIN &&
        item?.userId !== currentUserData?.id
      ) {
        addRecipient({
          id: item?.userId,
          pp: item?.userInfo?.profileImgUrl,
          name: `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`,
          target_subscriber_id: item?.subscriberId,
          languageCode: item?.languageCode,
        });
      }
    });

    // For external members
    [...selectedRecipients?.selectedExternalRecipients]?.forEach((item) => {
      if (
        (item?.accountPermission !== AccountLevelArkchatPermission?.ADMIN ||
          item?.subscriberPriority === accountType?.Individual) &&
        item?.userId !== currentUserData?.id
      ) {
        addRecipient({
          id: item?.userId,
          pp: item?.profileImgUrl,
          name: `${item?.firstName} ${item?.lastName}`,
          target_subscriber_id: item?.targetSubscriberId,
          languageCode: item?.languageCode,
        });
      }
    });

    // For external group recipients based on company
    externalGroupRecipientCompanyWiseData?.forEach((company) => {
      if (
        externalGroupMember[Object.keys(company)[0]]?.length > 0 &&
        Object.values(company)[0][0]?.subscriberPriority !==
          accountType?.Individual
      ) {
        Object.values(company)[0]?.forEach((item) => {
          if (
            item?.accountPermission === AccountLevelArkchatPermission?.ADMIN &&
            item?.userId !== currentUserData?.id
          ) {
            addRecipient({
              id: item?.userId,
              pp: item?.profileImgUrl,
              name: `${item?.firstName} ${item?.lastName}`,
              target_subscriber_id: item?.targetSubscriberId,
              languageCode: item?.languageCode,
            });
          }
        });
      }
    });

    if (isThreadScreen) {
      threadMsgList?.[0]?.to?.forEach((item) => addRecipient(item));
    }

    // Add the user sending the message
    addRecipient({
      id: currentUserData?.id,
      pp: currentUserData?.profileImgUrl,
      name: `${currentUserData?.firstName} ${currentUserData?.lastName}`,
      target_subscriber_id: currentUserData?.activeSubscriber?.id,
      languageCode: currentUserData?.languageCode,
    });
  };

  const handleTaskApprovalMsgLogic = async (initReqBody) => {
    initReqBody.topic_id = selectedTopicToSaveMsg?.id
      ? selectedTopicToSaveMsg?.id
      : groupTopicData?.data[0]?.id;
    initReqBody.group_subscriber_id = selectedGroup?.subscriberId;
    initReqBody.group_id = selectedGroup?.id;
    msgType === MessageType?.Task
      ? (initReqBody.task_status = TaskStatus?.Pending)
      : (initReqBody.approval_status = ApprovalStatus?.Pending);
    initReqBody.messageType =
      msgType === MessageType?.Task ? MessageType?.Task : MessageType?.Approval;
    initReqBody.due_date = dueDate.format("YYYY-MM-DD");
    initReqBody.due_time = dueTime.format("HH:mm");
    initReqBody.priority = priority;
    initReqBody.repeat = repeatType;
    initReqBody.repeat_every = selecteRepeatEvery;
    initReqBody.repeatable = isRepeat;
    initReqBody.is_all_group_member =
      [
        ...selectedRecipients?.selectedInternalRecipients,
        ...selectedRecipients?.selectedExternalRecipients,
      ]?.length === groupRecipientDetails?.length
        ? true
        : false;

    // Use a Set to track unique recipient IDs
    const recipientSet = new Set();

    const addRecipient = (recipient) => {
      const identifier = `${recipient.id}-${recipient.target_subscriber_id}`;
      if (!recipientSet.has(identifier)) {
        recipientSet.add(identifier);
        initReqBody.recipients.push(recipient);
      }
    };

    // For internal users only (non-admins)
    [...selectedRecipients?.selectedInternalRecipients]?.forEach((item) => {
      let permission = item?.permission || item?.accountPermission;
      let profilePic = item?.userInfo
        ? item?.userInfo?.profileImgUrl
        : item?.profileImgUrl;
      let userName = item?.userInfo
        ? `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`
        : `${item?.firstName} ${item?.lastName}`;
      let targetSubId = item?.userInfo
        ? item?.subscriberId
        : item?.targetSubscriberId;

      if (
        permission !== AccountLevelArkchatPermission?.ADMIN &&
        item?.userId !== taskAssignedUser?.userId &&
        item?.userId !== currentUserData?.id
      ) {
        addRecipient({
          id: item?.userId,
          pp: profilePic,
          name: userName,
          target_subscriber_id: targetSubId,
          languageCode: item?.languageCode,
        });
      }
    });

    // For internal members who are admins, owners, etc.
    [...groupmemberdetails?.internalMember]?.forEach((item) => {
      if (
        item?.permission === AccountLevelArkchatPermission?.ADMIN &&
        item?.userId !== taskAssignedUser?.userId &&
        item?.userId !== currentUserData?.id
      ) {
        addRecipient({
          id: item?.userId,
          pp: item?.userInfo?.profileImgUrl,
          name: `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`,
          target_subscriber_id: item?.subscriberId,
          languageCode: item?.languageCode,
        });
      }
    });

    // For external members
    [...selectedRecipients?.selectedExternalRecipients]?.forEach((item) => {
      if (
        (item?.accountPermission !== AccountLevelArkchatPermission?.ADMIN ||
          item?.subscriberPriority === accountType?.Individual) &&
        item?.userId !== taskAssignedUser?.userId &&
        item?.userId !== currentUserData?.id
      ) {
        addRecipient({
          id: item?.userId,
          pp: item?.profileImgUrl,
          name: `${item?.firstName} ${item?.lastName}`,
          target_subscriber_id: item?.targetSubscriberId,
          languageCode: item?.languageCode,
        });
      }
    });

    // For external group recipients based on company
    externalGroupRecipientCompanyWiseData?.forEach((company) => {
      if (
        externalGroupMember[Object.keys(company)[0]]?.length > 0 &&
        Object.values(company)[0][0]?.subscriberPriority !==
          accountType?.Individual
      ) {
        Object.values(company)[0]?.forEach((item) => {
          if (
            item?.accountPermission === AccountLevelArkchatPermission?.ADMIN &&
            item?.userId !== taskAssignedUser?.userId &&
            item?.userId !== currentUserData?.id
          ) {
            addRecipient({
              id: item?.userId,
              pp: item?.profileImgUrl,
              name: `${item?.firstName} ${item?.lastName}`,
              target_subscriber_id: item?.targetSubscriberId,
              languageCode: item?.languageCode,
            });
          }
        });
      }
    });

    if (isThreadScreen) {
      threadMsgList?.[0]?.to?.forEach((item) => addRecipient(item));
    }

    // Add the user assigned to the task
    addRecipient(
      {
        id: taskAssignedUser?.userId,
        pp: taskAssignedUser?.profileImgUrl,
        name: `${taskAssignedUser?.firstName} ${taskAssignedUser?.lastName}`,
        target_subscriber_id: taskAssignedUser?.targetSubscriberId,
        languageCode: taskAssignedUser?.languageCode,
      },
      "taksAss"
    );

    // Add the current user if they are not the assigned user
    if (taskAssignedUser?.userId !== currentUserData?.id) {
      addRecipient(
        {
          id: currentUserData?.id,
          pp: currentUserData?.profileImgUrl,
          name: `${currentUserData?.firstName} ${currentUserData?.lastName}`,
          target_subscriber_id: currentUserData?.activeSubscriber?.id,
          languageCode: currentUserData?.languageCode,
        },
        "crnt"
      );
    }

    // Set the assigned user details
    initReqBody.assignedTo = {
      id: taskAssignedUser?.userId,
      pp: taskAssignedUser?.profileImgUrl,
      name: `${taskAssignedUser?.firstName} ${taskAssignedUser?.lastName}`,
      target_subscriber_id: taskAssignedUser?.targetSubscriberId,
      languageCode: taskAssignedUser?.languageCode,
    };
  };

  // const handleTaskApprovalMsgLogic = async (initReqBody) => {
  //   initReqBody.topic_id = selectedTopicToSaveMsg?.id
  //     ? selectedTopicToSaveMsg?.id
  //     : groupTopicData?.data[0]?.id;
  //   initReqBody.group_subscriber_id = selectedGroup?.subscriberId;
  //   initReqBody.group_id = selectedGroup?.id;
  //   msgType === MessageType?.Task
  //     ? (initReqBody.task_status = TaskStatus?.Pending)
  //     : (initReqBody.approval_status = ApprovalStatus?.Pending);
  //   initReqBody.messageType =
  //     msgType === MessageType?.Task ? MessageType?.Task : MessageType?.Approval;
  //   initReqBody.due_date = dueDate.format("YYYY-MM-DD");
  //   initReqBody.due_time = dueTime.format("HH:mm");
  //   initReqBody.priority = priority;
  //   initReqBody.repeat = repeatType;
  //   initReqBody.repeat_every = selecteRepeatEvery;
  //   initReqBody.repeatable = isRepeat;
  //   initReqBody.is_all_group_member =
  //     [
  //       ...selectedRecipients?.selectedInternalRecipients,
  //       ...selectedRecipients?.selectedExternalRecipients,
  //     ]?.length === groupRecipientDetails?.length
  //       ? true
  //       : false;

  //   // for internal user
  //   [...selectedRecipients?.selectedInternalRecipients]?.forEach((item) => {
  //     item?.permission !== AccountLevelArkchatPermission?.ADMIN &&
  //       item?.userId !== taskAssignedUser?.userId &&
  //       item?.userId !== currentUserData?.id &&
  //       initReqBody.recipients.push({
  //         id: item?.userId,
  //         pp: item?.userInfo?.profileImgUrl,
  //         name: `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`,
  //         target_subscriber_id: item?.userInfo?.activeSubscriber?.id,
  //         languageCode: item?.languageCode,
  //       });
  //   });

  //   // for internal member admin,owner, accountmanager,group manager
  //   [...groupmemberdetails?.internalMember]?.forEach((item) => {
  //     item?.permission === AccountLevelArkchatPermission?.ADMIN &&
  //       item?.userId !== taskAssignedUser?.userId &&
  //       item?.userId !== currentUserData?.id &&
  //       initReqBody.recipients.push({
  //         id: item?.userId,
  //         pp: item?.userInfo?.profileImgUrl,
  //         name: `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`,
  //         target_subscriber_id: item?.userInfo?.activeSubscriber?.id,
  //         languageCode: item?.languageCode,
  //       });
  //   });

  //   //external member user
  //   [...selectedRecipients?.selectedExternalRecipients].forEach((item) => {
  //     (item?.accountPermission !== AccountLevelArkchatPermission?.ADMIN ||
  //       item?.subscriberPriority === accountType?.Individual) &&
  //       item?.userId !== taskAssignedUser?.userId &&
  //       item?.userId !== currentUserData?.id &&
  //       initReqBody.recipients.push({
  //         id: item?.userId,
  //         pp: item?.profileImgUrl,
  //         name: `${item?.firstName} ${item?.lastName}`,
  //         target_subscriber_id: item?.targetSubscriberId,
  //         languageCode: item?.languageCode,
  //       });
  //   });
  //   //external member member admin,owner, accountmanager,group manager
  //   externalGroupRecipientCompanyWiseData.forEach((company, index) => {
  //     if (
  //       externalGroupMember[Object.keys(company)[0]]?.length > 0 &&
  //       Object.values(company)[0][0]?.subscriberPriority !==
  //         accountType?.Individual
  //     ) {
  //       Object.values(company)[0]?.forEach((item) => {
  //         if (
  //           item?.accountPermission === AccountLevelArkchatPermission?.ADMIN &&
  //           item?.userId !== taskAssignedUser?.userId &&
  //           item?.userId !== currentUserData?.id
  //         ) {
  //           initReqBody.recipients.push({
  //             id: item?.userId,
  //             pp: item?.profileImgUrl,
  //             name: `${item?.firstName} ${item?.lastName}`,
  //             target_subscriber_id: item?.targetSubscriberId,
  //             languageCode: item?.languageCode,
  //           });
  //         }
  //       });
  //     }
  //   });

  //   initReqBody.recipients.push({
  //     id: taskAssignedUser?.userId,
  //     pp: taskAssignedUser?.profileImgUrl,
  //     name: `${taskAssignedUser?.firstName} ${taskAssignedUser?.lastName}`,
  //     target_subscriber_id: taskAssignedUser?.targetSubscriberId,
  //     languageCode: taskAssignedUser?.languageCode,
  //     // inviteType: taskAssignedUser?.inviteType,
  //   });

  //   if (taskAssignedUser?.userId !== currentUserData?.id) {
  //     initReqBody.recipients.push({
  //       id: currentUserData?.id,
  //       pp: currentUserData?.profileImgUrl,
  //       name: `${currentUserData?.firstName} ${currentUserData?.lastName}`,
  //       target_subscriber_id: currentUserData?.activeSubscriber?.id,
  //       languageCode: currentUserData?.languageCode,
  //     });
  //   }

  //   initReqBody.assignedTo = {
  //     id: taskAssignedUser?.userId,
  //     pp: taskAssignedUser?.profileImgUrl,
  //     name: `${taskAssignedUser?.firstName} ${taskAssignedUser?.lastName}`,
  //     target_subscriber_id: taskAssignedUser?.targetSubscriberId,
  //     languageCode: taskAssignedUser?.languageCode,
  //   };
  // };

  const handleResetState = () => {
    setReplyParentMsg("");
    setAttachment([]);
    setFileAttachment([]);
    setNewMessages("");
    setSelectedRecipients({
      selectedInternalRecipients: [],
      selectedExternalRecipients: [],
    });
    setExternalGroupMemebr({});
    dispatch(clearCurrentAiSessionData());
    dispatch(handleShowAIScreen(false));
    setTempAiQuery(false);
    setShareAiText(false);
    setSelectRecipientsDrawerOpen(false);
    setMessageType(MessageType?.Message);
    setDueDate(dayjs());
    setDueTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
    setSelectedRepeatEvery(null);
    setRepeatType(null);
    setRepeat(false);
    setPriority(1);
    setTaskAssignedUser({});
    setStepper("user");
    setTaskUpdateOpen(false);
    setCheckedType(null);
    setUpdateTaskApprovalMsg("");
    dispatch(handleAppSelectedTopicToSaveMsg(selectedTopic));
  };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () =>
        resolve({
          dataURL: reader.result,
          name: file.name,
          size: file.size,
        });
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  const handleMessageSend = async () => {
    const initReqBody = {
      message: newMessages,
      group_subscriber_id: 0,
      topic_id: 0,
      messageType: 0,
      from: {
        id: currentUserData?.id,
        name: `${currentUserData?.firstName} ${currentUserData?.lastName}`,
        pp: currentUserData?.profileImgUrl,
        target_subscriber_id: currentUserData?.activeSubscriber?.id,
        languageCode: currentUserData?.languageCode,
      },
      recipients: [],
      group_id: 0,
      subscriber_id: currentUserData?.activeSubscriber?.id,
      assignedTo: null,
      task_status: 0,
      approval_status: 0,
      due_date: null,
      repeatable: false,
      repeat: null,
      repeat_every: null,
      priority: 1,
      due_time: null,
      attachments: [],
      is_all_group_member: false,
      ai: isAiScreenActive ? true : false,
      estimated_time: {},
      start_time: 0,
      actual_time: {},
      end_time: 0,
    };

    if (dmUserDeatils && !selectedGroup?.isGroup) {
      handleDmMsgLogic(dmUserDeatils, initReqBody);
    } else {
      if (
        msgType === MessageType?.Message &&
        (selectedRecipients?.selectedInternalRecipients?.length > 0 ||
          selectedRecipients?.selectedExternalRecipients?.length > 0 ||
          isThreadScreen)
      ) {
        handleGroupPrivateMsgLogic(initReqBody);
      } else if (
        msgType === MessageType?.Task ||
        msgType === MessageType?.Approval
      ) {
        handleTaskApprovalMsgLogic(initReqBody);
      } else {
        handleSendMsgToAllGroupMember(initReqBody);
      }
    }

    const kuncikaR = generateRandomIV().toString();
    let reqbody = {
      message: msgEncrypter.encrypt(
        initReqBody?.message,
        process.env.REACT_APP_ENC_KEY,
        kuncikaR
      ),
      kuncika: kuncikaR,
      group_subscriber_id: initReqBody?.group_subscriber_id || null,
      to: initReqBody?.recipients,
      subscriber_id: initReqBody?.subscriber_id,
      group_id: initReqBody?.group_id,
      topic_id: initReqBody?.topic_id,
      type: initReqBody?.messageType,
      from: initReqBody?.from,
      assignee: initReqBody?.assignedTo,
      task_status: initReqBody?.task_status,
      approval_status: initReqBody?.approval_status,
      due_date: initReqBody?.due_date,
      attachments:
        attachmentType === "serachedLinks"
          ? attachment
          : initReqBody?.attachments,
      repeat_every: initReqBody?.repeat_every,
      repeat: initReqBody?.repeat,
      ai: isAiScreenActive ? true : false,
      priority: initReqBody?.priority,
      due_time: initReqBody?.due_time,
      repeatable: initReqBody?.repeatable,
      is_all_group_member: initReqBody?.is_all_group_member,
      temp_timetoken: `temp.${selectedGroup?.pnId}.${currentUserData?.id}.${
        Math.floor(new Date().getTime()) * 1000
      }`,
    };

    const newReqBody = {
      ...reqbody,
      pnId: selectedGroup?.pnId,
    };

    if (isThreadScreen) {
      newReqBody.thread_id =
        threadMsgList?.length > 0
          ? threadMsgList[0]?.thread_id
            ? threadMsgList[0]?.thread_id
            : threadMsgList[0]?.id
          : null;

      newReqBody.thread_original_message = replyParentMsg?.id
        ? {
            id: replyParentMsg?.id,
            message: msgEncrypter.encrypt(
              replyParentMsg?.message,
              process.env.REACT_APP_ENC_KEY,
              newReqBody?.kuncika
            ),
            temp_timetoken: replyParentMsg?.temp_timetoken,
            timetoken: replyParentMsg?.timetoken,
            translations: msgEncrypter.encrypt(
              JSON.stringify(replyParentMsg?.translations),
              process.env.REACT_APP_ENC_KEY,
              newReqBody?.kuncika
            ),
          }
        : null;
    }
    handleResetState();
    const formData = new FormData();
    let responseFilesData = [];
    let fileDataPromises;
    let fileData;
    if (fileAttachment.length > 0) {
      fileDataPromises = fileAttachment.map((file) =>
        readFileAsDataURL(file.file)
      );
      fileData = await Promise.all(fileDataPromises);
      fileAttachment.forEach((file) => formData.append("files", file?.file));
    }
    // Save temporary message data first
    fileAttachment.length > 0
      ? dispatch(
          handleSaveTempMsgData(
            { ...newReqBody, fileData },
            selectedGroup?.pnId
          )
        )
      : dispatch(handleSaveTempMsgData(newReqBody, selectedGroup?.pnId));

    if (fileAttachment.length > 0 && navigator.onLine) {
      await dispatch(
        uploadAttachment(formData, selectedGroup?.pnId, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        })
      ).then((res) => {
        responseFilesData = res;
        setProgress(0);
        setFileAttachment([]);
      });
    }

    // Update reqbody with the uploaded file data
    if (responseFilesData.length > 0) {
      reqbody.attachments = responseFilesData;
    }

    let publishMessageFormat;
    if (selectedGroup?.isGroup) {
      if (!isThreadScreen) {
        publishMessageFormat = {
          type: "message",
          content: {
            message: {
              ...reqbody,
              attachments:
                responseFilesData.length > 0
                  ? responseFilesData
                  : reqbody.attachments,
              is_private: reqbody.is_all_group_member ? false : true,
            },
            type: "conversation-message",
          },
          token: getCookie("token"),
          is_private: reqbody.is_all_group_member ? false : true,
        };
      } else {
        publishMessageFormat = {
          type: "message",
          content: {
            message: {
              ...reqbody,
              attachments:
                responseFilesData.length > 0
                  ? responseFilesData
                  : reqbody.attachments,
              thread_id:
                threadMsgList.length > 0 && threadMsgList[0].thread_id
                  ? threadMsgList[0].thread_id
                  : threadMsgList[0].id,
              thread_original_message: replyParentMsg?.id
                ? {
                    id: replyParentMsg?.id,
                    message: msgEncrypter.encrypt(
                      replyParentMsg.message,
                      process.env.REACT_APP_ENC_KEY,
                      reqbody.kuncika
                    ),
                    temp_timetoken: replyParentMsg.temp_timetoken,
                    timetoken: replyParentMsg.timetoken,
                    translations: msgEncrypter.encrypt(
                      JSON.stringify(replyParentMsg.translations),
                      process.env.REACT_APP_ENC_KEY,
                      reqbody.kuncika
                    ),
                  }
                : null,
              is_private: reqbody.is_all_group_member ? false : true,
            },
            type: "message-thread",
          },
          token: getCookie("token"),
          is_private: reqbody.is_all_group_member ? false : true,
        };
        // logWrapper(publishMessageFormat, "publishMessageFormat_thread");
      }
    } else {
      publishMessageFormat = {
        type: "message",
        content: {
          message: {
            ...reqbody,
            attachments:
              responseFilesData.length > 0
                ? responseFilesData
                : reqbody.attachments,
            thread_original_message: replyParentMsg?.id
              ? {
                  id: replyParentMsg?.id,
                  message: msgEncrypter.encrypt(
                    replyParentMsg.message,
                    process.env.REACT_APP_ENC_KEY,
                    reqbody.kuncika
                  ),
                  temp_timetoken: replyParentMsg.temp_timetoken,
                  timetoken: replyParentMsg.timetoken,
                  translations: msgEncrypter.encrypt(
                    JSON.stringify(replyParentMsg.translations),
                    process.env.REACT_APP_ENC_KEY,
                    reqbody.kuncika
                  ),
                }
              : null,
          },
          type: "direct-message",
        },
        token: getCookie("token"),
      };
    }

    if (navigator.onLine) {
      pubnub
        .publish({
          channel: publishMessageFormat?.is_private
            ? `${selectedGroup?.pnId}.user_${currentUserData?.id}`
            : selectedGroup?.pnId,
          message: publishMessageFormat,
        })
        .catch((error) => {
          // Handle publish error here
          dispatch(showToastMsg("Something went wrong please try again!"));
        });
    } else {
      dispatch(handleSaveTempMsgData(newReqBody, selectedGroup?.pnId));
      setFileAttachment([]);
    }
  };

  const handlegetThreadMessages = async (
    item,
    isRepliedClicked = true,
    isThreadScreenFromTaskApproval = false
  ) => {
    setisTopicOpen(false);
    if (isThreadScreenFromTaskApproval) {
      setIsThreadScreenFromTaskApprovalTab(isThreadScreenFromTaskApproval);
    }
    if (!item?.id) {
      const reqBody = {
        pn_id: selectedGroup?.pnId,
        timetoken: [item?.temp_timetoken],
      };
      //here response from handleFetchConversation will be single in array
      dispatch(handleFetchConversation(reqBody)).then((res) => {
        let newResMessageData = {};
        if (res?.length > 0) {
          newResMessageData = handleDecryptSingleMessageData(res[0]?.message);
        }
        if (isRepliedClicked) {
          dispatch({
            type: UPDATE_EXISTING_MESSAGE_LIST,
            payload: newResMessageData,
          });
          dispatch(handleUpdateMsgToDb(newResMessageData));
          setReplyParentMsg(newResMessageData);
        }
        dispatch(handleGetThreadMessage(newResMessageData));
        dispatch(handleScrollToBottom(true));
        dispatch(handleOpenCloseThreadView(true));
      });
    }
    if (item?.id) {
      if (isRepliedClicked) {
        setReplyParentMsg(item);
      }
      dispatch(handleGetThreadMessage(item)).then((res) => {
        dispatch(handleScrollToBottom(true));
        dispatch(handleOpenCloseThreadView(true));
      });
    }
  };

  const handleViewThreadClickTaskApprovalTab = (item, tabValue) => {
    dispatch(handleHomeTabValue(0));
    handlegetThreadMessages(item, false, tabValue);
  };

  const encryptPendingMsg = (msgData) => {
    let encryptedMsgBody;
    if (
      msgData?.thread_original_message &&
      msgData?.thread_original_message?.id
    ) {
      // msgEncrypter.encrypt(
      //   replyParentMsg?.message,
      //   process.env.REACT_APP_ENC_KEY,
      //   "your-32-length-s"
      // ),
      encryptedMsgBody = {
        ...msgData,
        message: msgEncrypter.encrypt(
          msgData?.message,
          process.env.REACT_APP_ENC_KEY,
          "your-32-length-s"
        ),
        thread_original_message: {
          ...msgData?.thread_original_message,
          message: msgEncrypter.encrypt(
            msgData?.thread_original_message?.message,
            process.env.REACT_APP_ENC_KEY,
            "your-32-length-s"
          ),

          translations: msgEncrypter.encrypt(
            JSON.stringify(msgData?.thread_original_message?.translations),
            process.env.REACT_APP_ENC_KEY,
            "your-32-length-s"
          ),
        },
      };
    } else {
      encryptedMsgBody = {
        ...msgData,
        message: msgEncrypter.encrypt(
          msgData?.message,
          process.env.REACT_APP_ENC_KEY,
          "your-32-length-s"
        ),
      };
    }
    return encryptedMsgBody;
  };

  const handleSendTempMsg = async (reqBoby) => {
    const { pnId, ...requestBodyWithoutPnId } = reqBoby;
    let publishMessageFormat;
    let encryptedMsgBody = encryptPendingMsg(requestBodyWithoutPnId);
    if (reqBoby?.type === MessageType?.Message && !reqBoby?.thread_id) {
      publishMessageFormat = {
        type: "message",
        content: {
          message: encryptedMsgBody,
          type: "conversation-message",
        },
        token: getCookie("token"),
      };
    } else if (reqBoby?.type === MessageType?.Message && reqBoby?.thread_id) {
      publishMessageFormat = {
        type: "message",
        content: {
          message: encryptedMsgBody,
          type: "message-thread",
        },
        token: getCookie("token"),
      };
    } else if (
      reqBoby?.type === MessageType?.Direct_Mesage &&
      !reqBoby?.thread_id
    ) {
      publishMessageFormat = {
        type: "message",
        content: {
          message: encryptedMsgBody,
          type: "direct-message",
        },
        token: getCookie("token"),
      };
    }
    if (navigator.onLine) {
      logWrapper(publishMessageFormat, ":><:><:22");
      pubnub
        .publish({
          channel: selectedGroup?.pnId,
          message: publishMessageFormat,
        })
        .catch((error) => {
          // Handle publish error here
          dispatch(showToastMsg("Failed to publish message"));
        });
    }
  };

  const handleUpdateTaskApproval = async (reqBoby) => {
    const initReqBody = {
      message: newMessages,
      group_subscriber_id: 0,
      topic_id: 0,
      messageType: 0,
      recipients: [],
      group_id: 0,
      assignedTo: null,
      task_status: 0,
      approval_status: 0,
      due_date: null,
      repeatable: false,
      repeat: null,
      repeat_every: null,
      priority: 1,
      due_time: null,
      attachments: [],
    };
    let newReqbody;
    const kuncikaR = generateRandomIV().toString();
    if (checkedType === "changeValues") {
      newReqbody = {
        ...reqBoby,
        thread_id: reqBoby?.thread_id ? reqBoby?.thread_id : reqBoby?.id,
        message: msgEncrypter.encrypt(
          updateTaskApprovalMsg,
          process.env.REACT_APP_ENC_KEY,
          kuncikaR
        ),
        kuncika: kuncikaR,
        priority: priority,
        due_time: dueTime.format("HH:mm"),
        due_date: dueDate.format("YYYY-MM-DD"),
        from: {
          id: currentUserData?.id,
          name: `${currentUserData?.firstName} ${currentUserData?.lastName}`,
          pp: currentUserData?.profileImgUrl,
          target_subscriber_id: currentUserData?.activeSubscriber?.id,
          languageCode: currentUserData?.languageCode,
        },
        subscriber_id: currentUserData?.activeSubscriber?.id,
        temp_timetoken: `temp.${selectedGroup?.pnId}.${currentUserData?.id}.${
          Math.floor(new Date().getTime()) * 1000
        }`,
        thread_original_message: replyParentMsg?.id
          ? {
              id: replyParentMsg?.id,
              message: msgEncrypter.encrypt(
                replyParentMsg?.message,
                process.env.REACT_APP_ENC_KEY,
                kuncikaR
              ),
              temp_timetoken: replyParentMsg?.temp_timetoken,
              timetoken: replyParentMsg?.timetoken,
              translations: msgEncrypter.encrypt(
                JSON.stringify(replyParentMsg?.translations),
                process.env.REACT_APP_ENC_KEY,
                kuncikaR
              ),
            }
          : null,
      };
    } else if (checkedType === "reassignTask") {
      handleTaskApprovalMsgLogic(initReqBody);
      const reqBody = {
        message: msgEncrypter.encrypt(
          initReqBody?.message,
          process.env.REACT_APP_ENC_KEY,
          kuncikaR
        ),
        kuncika: kuncikaR,
        group_subscriber_id: initReqBody?.group_subscriber_id,
        to: initReqBody?.recipients,
        subscriber_id: initReqBody?.subscriber_id,
        group_id: initReqBody?.group_id,
        topic_id: initReqBody?.topic_id,
        type: initReqBody?.messageType,
        from: initReqBody?.from,
        assignee: initReqBody?.assignedTo,
        task_status: initReqBody?.task_status,
        approval_status: initReqBody?.approval_status,
        due_date: initReqBody?.due_date,
        attachments:
          attachmentType == "youtube" ? attachment : initReqBody?.attachments,
        repeat_every: initReqBody?.repeat_every,
        repeat: initReqBody?.repeat,
        priority: initReqBody?.priority,
        due_time: initReqBody?.due_time,
        repeatable: initReqBody?.repeatable,
      };
      newReqbody = {
        ...reqBoby,
        ...reqBody,
        thread_id: reqBoby?.thread_id ? reqBoby?.thread_id : reqBoby?.id,
        message: msgEncrypter.encrypt(
          updateTaskApprovalMsg,
          process.env.REACT_APP_ENC_KEY,
          kuncikaR
        ),
        from: {
          id: currentUserData?.id,
          name: `${currentUserData?.firstName} ${currentUserData?.lastName}`,
          pp: currentUserData?.profileImgUrl,
          target_subscriber_id: currentUserData?.activeSubscriber?.id,
          languageCode: currentUserData?.languageCode,
        },
        subscriber_id: currentUserData?.activeSubscriber?.id,
        temp_timetoken: `temp.${selectedGroup?.pnId}.${currentUserData?.id}.${
          Math.floor(new Date().getTime()) * 1000
        }`,
        thread_original_message: replyParentMsg?.id
          ? {
              id: replyParentMsg?.id,
              message: msgEncrypter.encrypt(
                replyParentMsg?.message,
                process.env.REACT_APP_ENC_KEY,
                kuncikaR
              ),
              temp_timetoken: replyParentMsg?.temp_timetoken,
              timetoken: replyParentMsg?.timetoken,
              translations: msgEncrypter.encrypt(
                JSON.stringify(replyParentMsg?.translations),
                process.env.REACT_APP_ENC_KEY,
                kuncikaR
              ),
            }
          : null,
      };
    } else {
      newReqbody = {
        ...reqBoby,
        thread_id: reqBoby?.thread_id ? reqBoby?.thread_id : reqBoby?.id,
        message: msgEncrypter.encrypt(
          updateTaskApprovalMsg,
          process.env.REACT_APP_ENC_KEY,
          kuncikaR
        ),
        kuncika: kuncikaR,
        from: {
          id: currentUserData?.id,
          name: `${currentUserData?.firstName} ${currentUserData?.lastName}`,
          pp: currentUserData?.profileImgUrl,
          target_subscriber_id: currentUserData?.activeSubscriber?.id,
          languageCode: currentUserData?.languageCode,
        },
        subscriber_id: currentUserData?.activeSubscriber?.id,
        temp_timetoken: `temp.${selectedGroup?.pnId}.${currentUserData?.id}.${
          Math.floor(new Date().getTime()) * 1000
        }`,
        thread_original_message: replyParentMsg?.id
          ? {
              id: replyParentMsg?.id,
              message: msgEncrypter.encrypt(
                replyParentMsg?.message,
                process.env.REACT_APP_ENC_KEY,
                kuncikaR
              ),
              temp_timetoken: replyParentMsg?.temp_timetoken,
              timetoken: replyParentMsg?.timetoken,
              translations: msgEncrypter.encrypt(
                JSON.stringify(replyParentMsg?.translations),
                process.env.REACT_APP_ENC_KEY,
                kuncikaR
              ),
            }
          : null,
      };
    }
    const formData = new FormData();
    let responseFilesData = [];
    let fileDataPromises;
    let fileData;

    if (fileAttachment.length > 0) {
      fileDataPromises = fileAttachment.map((file) =>
        readFileAsDataURL(file.file)
      );
      fileData = await Promise.all(fileDataPromises);
      fileAttachment.forEach((file) => formData.append("files", file?.file));
    }

    // Save temporary message data first
    fileAttachment.length > 0
      ? dispatch(
          handleSaveTempMsgData(
            { ...newReqbody, fileData },
            selectedGroup?.pnId
          )
        )
      : dispatch(handleSaveTempMsgData(newReqbody, selectedGroup?.pnId));

    // Upload files if online
    if (fileAttachment.length > 0 && navigator.onLine) {
      await dispatch(
        uploadAttachment(formData, selectedGroup?.pnId, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        })
      ).then((res) => {
        responseFilesData = res;
        setProgress(0);
        setFileAttachment([]);
      });
    }

    // Update attachments in newReqbody with uploaded file data
    if (responseFilesData.length > 0) {
      newReqbody.attachments = responseFilesData;
    }

    if (navigator.onLine && updateTaskApprovalMsg && checkedType) {
      logWrapper(newReqbody, "newReqbody");
      dispatch(handleTaskUpdate(newReqbody, reqBoby?.id));
    }
    handleResetState();
  };

  const handleClickYouTube = () => {
    setAttachmentDrawer({
      ...attachmentdrawer,
      isYoutube: true,
    });
  };

  const handleCloseYoutubeDrawer = () => {
    setAttachmentDrawer({
      ...attachmentdrawer,
      isYoutube: false,
    });
  };

  const handleSendYouTubeAttachment = (videoData) => {
    const attachedLink = videoData?.map((item) => {
      return {
        thumbnail: item?.thumbnail,
        name: item?.title,
        location: item?.videoLink,
        id: item?.videoid,
      };
    });
    setAttachment(attachedLink);
    setAttachmenttype("serachedLinks");
    handleCloseYoutubeDrawer();
  };

  const handleClickGoogle = () => {
    setAttachmentDrawer({
      ...attachmentdrawer,
      isYoutube: false,
      isGoogle: true,
    });
  };

  const handleCloseGoogleDrawer = () => {
    setAttachmentDrawer({
      ...attachmentdrawer,
      isYoutube: false,
      isGoogle: false,
    });
  };

  const handleSendGoogleAttachment = (videoData) => {
    const attachedLink = videoData?.map((item) => {
      return {
        thumbnail: "",
        name: item?.title,
        location: item?.videoLink,
        id: item?.cacheId,
      };
    });
    setAttachment(attachedLink);
    setAttachmenttype("serachedLinks");
    handleCloseGoogleDrawer();
  };

  const handleBacktoMessage = () => {
    dispatch(handleOpenCloseThreadView(false));
    dispatch({
      type: RECEIVED_THREAD_MESSAGES,
      payload: [],
    });
    setReplyParentMsg("");
    if (isThreadScreenFromTaskApprovalTab) {
      dispatch(handleHomeTabValue(isThreadScreenFromTaskApprovalTab));
      setIsThreadScreenFromTaskApprovalTab(null);
    } else {
      handleFetchMessages(groupList[0]?.pnId, selectedTopic?.id);
      dispatch(handleScrollToBottom(true));
    }
  };

  const handleDisablePrivateChat = async () => {
    const reqbody = {
      is_task_on: !isPrivateDisableChat,
      is_private_chat_on: !isPrivateDisableChat,
    };

    const response = await dispatch(
      handleDeactivatePrivateChat(reqbody, selectedGroup?.id)
    );

    setdisableprivatechat(false);
  };

  const handleReadStatusDrawer = () => {
    setReadstatusdrawer(true);
  };

  const handleOkayButtonClicked = () => {};

  const handleCancelButtonClick = () => {};

  const handleClicktabButton = (name) => {
    setTaskButtonDrawer({
      header: name,
      isOpen: true,
    });
  };

  const handlecloseTeamlist = () => {
    setTaskButtonDrawer({ ...taskButtonDrawer, isOpen: false });
  };

  const handleTaskgroupItemclick = (event, item) => {
    if (!event.target.checked) {
      setSelectedTaskGroup([]);
    }
    if (event.target.checked) {
      setSelectedTaskGroup([item]);
      if (currentHomeIndexTab === 1) {
        if (taskApprovalViewValue === 1) {
          dispatch(handleGetAllTaskList(`groupId=${item?.id}`));
        } else {
          dispatch(handleGetTaskAnalytics(`groupId=${item?.id}`));
        }
      } else {
        if (taskApprovalViewValue === 1) {
          dispatch(handleGetAllApprovalList(`groupId=${item?.id}`));
        } else {
          dispatch(handleGetApprovalAnalytics(`groupId=${item?.id}`));
        }
      }
    }

    handlecloseTeamlist();
  };

  const handleSelectTaskMemberCheck = (event, item) => {
    if (!event.target.checked) {
      setSelectedTaskMember([]);
    }
    if (event.target.checked) {
      setSelectedTaskMember([item]);
      if (currentHomeIndexTab === 1) {
        if (taskApprovalViewValue === 1) {
          dispatch(handleGetAllTaskList(`userId=${item?.userId}`));
        } else {
          dispatch(handleGetTaskAnalytics(`userId=${item?.userId}`));
        }
      } else {
        if (taskApprovalViewValue === 1) {
          dispatch(handleGetAllApprovalList(`userId=${item?.userId}`));
        } else {
          dispatch(handleGetApprovalAnalytics(`userId=${item?.userId}`));
        }
      }
    }

    handlecloseTeamlist();
  };
  return {
    initialopenDialog,
    setOpenInitialDialog,
    isGroupmenuOpen,
    setGroupmenuOpen,
    setLanguageMenuOpen,
    isLanguageMenuOpen,
    setGroupMembermenu,
    isGroupmembermenu,
    setDeleteRequired,
    delrequired,
    setdeleteInputVal,
    delInputval,
    selectedGroup,
    // setselectedgroupmenu,
    // selectedgroupmenu,
    setDeletegroupmenu,
    isDeleteGroupmenu,
    setEditGroupmenu,
    isEditGroupmenu,
    setNotificationpreferenceModal,
    isNotificationpreferenceModalOpen,
    set24Hour,
    is24Hour,
    setDDMMYY,
    isDDMMYY,
    setMMDDYY,
    isMMDDYY,
    setYYMMDD,
    isYYMMDD,
    setDateFormate,
    dateFormate,
    setMediaFilesOpen,
    isMediaFilesOpen,
    setDatAndTimeModal,
    isdateAndTimeModalOpen,
    setAddpeopledarwer,
    isAddPeopledarwer,
    setSelectedTaskMember,
    selectedTaskmember,
    setSelectedTaskGroup,
    selectedTaskGroup,
    setownershipright,
    isownershipright,
    setPermissionModal,
    isPermissionModalOpen,
    setReadstatusdrawer,
    readStatusdrawer,
    setPrivateDisableChat,
    isPrivateDisableChat,
    setdisableprivatechat,
    isdisableprivatchat,
    setReplyParentMsg,
    replyParentMsg,
    setFileAttachment,
    fileAttachment,
    setAttachmentDrawer,
    attachmentdrawer,
    setSelectedAnswer,
    selecteAnswer,
    setShowStaredAiHistory,
    showStaredAiHistory,
    setShareAiText,
    isShareAiText,
    setShowTempQuery,
    showTempQuery,
    setTempAiQuery,
    tempAiQuery,
    setAiQueryValue,
    aiQueryValue,
    setTaskButtonDrawer,
    taskButtonDrawer,
    selectRecipientsDrawerOpen,
    setSelectRecipientsDrawerOpen,
    externalGroupMember,
    setExternalGroupMemebr,
    setTaskApprovalViewValue,
    taskApprovalViewValue,
    setAttachment,
    attachment,
    setMessageType,
    msgType,
    isEmailChecked,
    isMobileChecked,
    setEmailChecked,
    setMobileChecked,
    selectedRecipients,
    setSelectedRecipients,
    taskAssignedUser,
    setTaskAssignedUser,
    dueTime,
    setDueTime,
    dueDate,
    setDueDate,
    priority,
    setPriority,
    isRepeat,
    setRepeat,
    repeatType,
    setRepeatType,
    selecteRepeatEvery,
    setSelectedRepeatEvery,
    stepper,
    setStepper,
    taskData,
    setTaskData,
    isTaskUpdateOpen,
    setTaskUpdateOpen,
    updateTaskApprovalMsg,
    setUpdateTaskApprovalMsg,
    checkedType,
    setCheckedType,
    progress,
    groupList,
    currentUserData,
    messageList,
    handleLanguageCloseMenu,
    handleCloseGroupMenu,
    handleOpenGroupDrawer,
    handleCloseUserChatAttach,
    handleclickAttherate,
    handleChange,
    handleCloseMemberDrawer,
    handleSelectedGroupmenu,
    handleSelectedGroupmenuClick,
    handleCloseInitialDialog,
    handleMessageSend,
    handleSendTempMsg,
    handleUpdateTaskApproval,
    handleNotificationPreference,
    handleDateTimeUpdate,
    handleCloseAddPeopleDrawer,
    handleClickTaskUpdate,
    handleViewThreadClickTaskApprovalTab,
    handleAddgroupMember,
    handleUpdateGroupname,
    handleDeleteGroup,
    handleOnDeleteInput,
    handleOnInputChange,
    handleOnChangeNewMessage,
    handleSelectTaskMemberCheck,
    handleTaskgroupItemclick,
    handlecloseTeamlist,
    handleClicktabButton,
    handleOkayButtonClicked,
    handleReadStatusDrawer,
    handleDisablePrivateChat,
    handleBacktoMessage,
    handlegetThreadMessages,
    handleSendGoogleAttachment,
    handleCloseGoogleDrawer,
    handleClickGoogle,
    handleSendYouTubeAttachment,
    handleCloseYoutubeDrawer,
    handleClickYouTube,
  };
};

export default useTabState;
