import React from "react";

const AiQuerySend = ({ color = "" }) => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.585938" width="30.4134" height="30" rx="2" fill="#DB0011" />
      <path
        d="M14.6501 7.25005L9.97282 11.7122C9.62249 12.0464 9.62379 12.587 9.97587 12.9197C10.3279 13.2523 10.8973 13.251 11.2477 12.9168L14.3874 9.92146L14.3874 23.1462C14.3874 23.6177 14.79 24 15.2867 24C15.7834 24 16.186 23.6177 16.186 23.1462L16.186 9.9215L19.3258 12.9168C19.6761 13.251 20.2455 13.2523 20.5976 12.9196C20.9497 12.5869 20.9509 12.0463 20.6006 11.7121L15.9242 7.25078C15.5726 6.91638 15.0005 6.91668 14.6501 7.25005Z"
        fill={color ? color : "white"}
      />
    </svg>
  );
};

export default AiQuerySend;
