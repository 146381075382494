import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/material";
import NoTaskListComponent from "../Task/NoTaskListComponent";
import TaskMessage from "../Task/TaskMessage";
import { fetchOldApprovalTabDataFromApi } from "../../../appStore/actions/ArkchatGroup/action";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../common/LoadingSpinner/LoadingSpinner";

const ApprovalListComponent = ({
  handleClickTaskUpdate,
  approvalTabValue,
  handleViewThreadClick,
  handleApplyApprovalFilter,
  filterChecked,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const storeApprovallist = useSelector(
    (state) => state?.arkchatgroup?.approvalList
  );
  const isLoading = useSelector((state) => state?.localAppReducer?.isLoading);
  const [isScrollingToBottom, setIsScrollingToBottom] = useState(false);
  const approvalContainerRef = useRef(null);
  const dispatch = useDispatch();
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      approvalContainerRef.current;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 50;
    setIsScrollingToBottom(isAtBottom);
  };
  let content = appContent?.defaultEmptyApprovalMsg;
  if (filterChecked?.length) {
    content = appContent?.emptyApprovalFilter;
  } else if (approvalTabValue === 4) {
    content = appContent?.emptyApprovalArchive;
  }
  useEffect(() => {
    if (isScrollingToBottom) {
      const page = Math.floor(storeApprovallist?.length / 4) + 1;
      handleApplyApprovalFilter(
        filterChecked,
        page,
        storeApprovallist[storeApprovallist?.length - 1]
      );
    }
  }, [isScrollingToBottom]);

  if (storeApprovallist?.length > 0) {
    return (
      <Box
        ref={approvalContainerRef}
        onScroll={handleScroll}
        overflow={"auto"}
        height={"calc(100vh - 15rem)"}
      >
        {isLoading && <LoadingSpinner />}
        {!isLoading &&
          storeApprovallist?.map((item, indx) => {
            return (
              <TaskMessage
                handleClickTaskUpdate={handleClickTaskUpdate}
                handleViewThreadClick={handleViewThreadClick}
                taskItem={item}
                indx={indx}
                tabValue={2}
              />
            );
          })}
      </Box>
    );
  } else {
    return (
      <>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <NoTaskListComponent content={content} />
        )}
      </>
    );
  }
};

export default ApprovalListComponent;
