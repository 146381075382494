import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Typography from "@mui/material/Typography";
import {
  Box,
  Checkbox,
  CircularProgress,
  DialogContentText,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { StyledFormControlLabel } from "./contact/InternalContact";
import { groupMsgDeleteType } from "../utils/constant";
import { useSelector } from "react-redux";

const ArkchatDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(10),
  },
}));

function ArkchatDialogTitle(props) {
  const { children, onClose, classes, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: "0.5rem 0rem", display: "flex", alignItems: "center" }}
      {...other}
    >
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            height: "25px",
            width: "25px",
            borderRadius: "2px",
            margin: "0 10px",
            background: "#DB0011",
            "&:hover": {
              background: "#DB0011",
            },
          }}
        >
          <CloseIcon sx={classes?.iconn} />
        </IconButton>
      ) : null}
      <Typography variant="subtitle2">{children}</Typography>
    </DialogTitle>
  );
}

ArkchatDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function InputDialogComponent(props) {
  const {
    open,
    handleClose,
    title,
    onChange,
    value,
    btnLabel,
    onClick,
    isType = "date&time",
    required = false,
    inputLable,
    setYYMMDD,
    setMMDDYY,
    setDDMMYY,
    set24Hour,
    is24Hour,
    isYYMMDD,
    isMMDDYY,
    isDDMMYY,
    setDateFormate,
    isMobileChecked,
    isEmailChecked,
    setEmailChecked,
    setMobileChecked,
    isallowedDeleteGrpMsgForall,
    setDeleteGrpMsgType,
    // isTypedisableprivateChat,
    handleDisableEnablepvtChat,
    // isPrivateDisableChat,
    alternateBtnLabel = "",
    handleAlternateBtnClick,
    disabled = false,
    isDeleteLoading = false,
    isLoading = false,
  } = props;
  const userData = useSelector((state) => state?.profile?.profileData?.data);
  const classes = {
    iconn: {
      color: "#fff",
    },
    radioStyle: {
      color: "#092C4C",
      padding: "0.25rem",
      "&.Mui-checked": {
        color: "#092C4C",
      },
    },
  };
  const handleCloseAction = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }

    handleClose();
  };
  const handleDateFormatChange = (e) => {
    setDDMMYY(e.target.value === "DD-MM-YY");
    setMMDDYY(e.target.value === "MM-DD-YY");
    setYYMMDD(e.target.value === "YY-MM-DD");

    setDateFormate(e.target.value);
  };
  const handleTimeFormateChange = (e) => {
    if (e.target.checked) {
      set24Hour(true);
    } else {
      set24Hour(false);
    }
  };

  const handleEmailChange = () => {
    setEmailChecked(true);
    setMobileChecked(false);
  };

  const handleMobileChange = () => {
    setMobileChecked(true);
    setEmailChecked(false);
  };
  React.useEffect(() => {
    if (set24Hour) {
      if (+userData?.timeFormat === 24) {
        set24Hour(true);
      } else {
        set24Hour(false);
      }
    }
  }, []);
  return (
    <div>
      <ArkchatDialog
        sx={{ border: "1px solid" }}
        onClose={handleCloseAction}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="xs"
        onBac
      >
        <ArkchatDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          classes={classes}
        >
          {title}
        </ArkchatDialogTitle>
        <DialogContent dividers>
          {isType === "delete" && (
            <>
              <DialogContentText sx={{ marginBottom: "15px" }}>
                <Typography variant="caption">
                  Are you sure you want to delete this Group? Once deleted, you
                  will not be able to recover it.
                </Typography>
              </DialogContentText>
              <TextField
                variant="standard"
                fullWidth
                required
                placeholder="Write 'DELETE"
                padding="11px"
                error={value ? false : required}
                onChange={onChange}
                value={value}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </>
          )}

          {isType === "disableprivatechat" && (
            <DialogContentText sx={{ marginBottom: "15px" }}>
              <Typography variant="caption" sx={{ marginBottom: "15px" }}>
                You can deactivate the following features for this group. Once
                chat start in the group, you can’t this option.
              </Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 30, color: "#183243" },
                    }}
                  />
                }
                label="Deactivate private chats & To Dos"
                name="enabledisable"
                checked={value}
                onChange={handleDisableEnablepvtChat}
              />
            </DialogContentText>
          )}

          {isType === "ownershipright" && (
            <DialogContentText sx={{ marginBottom: "15px" }}>
              <Typography
                variant="caption"
                sx={{ marginBottom: "15px", display: "block" }}
              >
                Are you sure you want to transfer ownership of this group?
              </Typography>
              <Typography variant="caption" sx={{ display: "block" }}>
                Once you transfer ownership you can’t reclaim it. Only the new
                owner can transfer ownership to you.
              </Typography>
            </DialogContentText>
          )}
          {isType === "edit" && (
            <TextField
              variant="standard"
              label={inputLable}
              required
              padding="11px"
              onChange={onChange}
              value={value}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          {isType === "add" && (
            <>
              <TextField
                variant="standard"
                label={inputLable}
                required
                padding="11px"
                fullWidth
                onChange={onChange}
                value={value}
              />
            </>
          )}

          {/* Date&Time Update Modal */}
          {isType === "date&time" && (
            <>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  <Box>
                    <RadioGroup>
                      <StyledFormControlLabel
                        sx={{ marginBottom: "0.5rem" }}
                        value="DD-MM-YY"
                        control={
                          <Radio
                            checked={isDDMMYY}
                            onChange={handleDateFormatChange}
                            sx={classes.radioStyle}
                          />
                        }
                        label="DD-MM-YY"
                        labelPlacement="end"
                      />
                      <StyledFormControlLabel
                        value="MM-DD-YY"
                        control={
                          <Radio
                            checked={isMMDDYY}
                            onChange={handleDateFormatChange}
                            sx={classes.radioStyle}
                          />
                        }
                        label="MM-DD-YY"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </Box>
                </Box>
                <Box>
                  <RadioGroup>
                    <StyledFormControlLabel
                      sx={{ marginBottom: "0.5rem" }}
                      value="YY-MM-DD"
                      control={
                        <Radio
                          checked={isYYMMDD}
                          onChange={handleDateFormatChange}
                          sx={classes.radioStyle}
                        />
                      }
                      label="YY-MM-DD"
                      labelPlacement="end"
                    />
                    <StyledFormControlLabel
                      value="24-Hour Time"
                      control={
                        <Checkbox
                          onClick={handleTimeFormateChange}
                          checked={is24Hour}
                          sx={{
                            "& .MuiSvgIcon-root": { color: "#183243" },
                          }}
                        />
                      }
                      label="24-Hour Time"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </Box>
              </Box>
            </>
          )}

          {/* Notification Preference Modal */}
          {/* notification */}
          {isType === "notification" && (
            <>
              <Box>
                <Box>
                  <Typography fontSize={"0.87rem"} variant="h6">
                    You must choose notification preference to get notifications
                    about new messages, task and approval reminders.
                  </Typography>
                </Box>
                <Box display={"flex"} gap={"1rem"}>
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ "& .MuiSvgIcon-root": { color: "#183243" } }}
                          checked={isEmailChecked}
                          onChange={handleEmailChange}
                          name="email"
                        />
                      }
                      label="Email"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ "& .MuiSvgIcon-root": { color: "#183243" } }}
                          checked={isMobileChecked}
                          onChange={handleMobileChange}
                          name="mobile"
                        />
                      }
                      label="Mobile"
                    />
                  </>
                </Box>
              </Box>
            </>
          )}

          {/*Delete Group Message */}
          {isType === "deletegroupmessage" && (
            <>
              <Box>
                <Box margin={"auto"}>
                  <Box>
                    <RadioGroup>
                      <StyledFormControlLabel
                        sx={{ marginBottom: "0.5rem" }}
                        value={groupMsgDeleteType?.me}
                        control={
                          <Radio
                            sx={classes.radioStyle}
                            onChange={(e) =>
                              setDeleteGrpMsgType(e.target.value)
                            }
                          />
                        }
                        label="Delete for me"
                        labelPlacement="end"
                      />
                      <StyledFormControlLabel
                        value={groupMsgDeleteType?.all}
                        control={
                          <Radio
                            disabled={isallowedDeleteGrpMsgForall}
                            onChange={(e) =>
                              setDeleteGrpMsgType(e.target.value)
                            }
                            sx={classes.radioStyle}
                          />
                        }
                        label="Delete for all"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </Box>
                </Box>
              </Box>
            </>
          )}

          {isType === "editFileName" && (
            <TextField
              variant="standard"
              label={inputLable}
              required
              padding="11px"
              onChange={onChange}
              value={value}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          <Stack
            direction="row"
            justifyContent="center"
            marginTop="30px"
            alignItems="center"
            spacing={3}
            padding="12px 19px 12px 12px"
          >
            {alternateBtnLabel && (
              <Button
                style={{
                  background: "#DB0011",
                  color: "white",
                  borderRadius: "0px",
                  cursor: "pointer",
                }}
                variant="contained"
                onClick={handleAlternateBtnClick}
              >
                {isDeleteLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  alternateBtnLabel
                )}
              </Button>
            )}

            <Button
              style={{
                background: disabled ? "lightgray" : "#DB0011",
                color: "white",
                borderRadius: "0px",
              }}
              disabled={disabled}
              variant="contained"
              onClick={onClick}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                btnLabel
              )}
            </Button>
          </Stack>
        </DialogContent>
      </ArkchatDialog>
    </div>
  );
}
