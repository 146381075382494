import * as React from "react";

import dayjs from "dayjs";

import { Box, Stack } from "@mui/system";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  daysOfWeek,
  MessageType,
  months,
  priorityValue,
} from "../../../utils/constant";
import Time from "./Time";

const classes = {
  timePbody: {
    "& input": {
      padding: 0,
      width: "80px",
    },
    "& fieldset": {
      border: "none",
    },
  },
  paddingLeft30: {
    paddingLeft: "30px",
    minWidth: "130px",
    fontSize: "0.75rem",
  },
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    // backgroundColor: "#183243",
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  radiogp: {
    display: "block",
  },
  active2: {
    backgroundColor: "#F3F3F3",
    fontSize: "0.75rem",
    padding: "0.5rem 0rem",
  },
  weaklist: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none;",
      fontSize: "0.75rem",
    },
    "& .MuiSelect-select": {
      padding: "0",
      fontSize: "0.75rem",
    },
    "&..css-156hn9k-MuiButtonBase-root-MuiMenuItem-root": {
      fontSize: "0.75rem",
    },
  },
  typeBox: {
    fontSize: "12px",
    color: "#183243",
    background: "#E1E1E1",
    borderRadius: "3px",
    padding: "0",
    borderColor: "#E1E1E1",
    textTransform: "none",
    ":hover": {
      color: "#183243",
      background: "#E1E1E1",
      borderRadius: "3px",
      padding: "0",

      borderColor: "#E1E1E1",
    },
  },
};
const Repeat = ({
  handleClostRecipientsDrawer,
  handleNextStep,
  taskAssignedUser,
  selectedRecipients,
  handleTimeChange,
  handleDateChange,
  setType,
  dueTime,
  dueDate,
  setDueDate,
  setPriority,
  priority,
  setMessageType,
  msgType,
  setRepeat,
  checkedType,
  setCheckedType,
  updateTaskApprovalMsg,
  setUpdateTaskApprovalMsg,
  handleUpdateTaskApproval,
  isTaskUpdateOpen,
  taskData,
  setRepeatType,
  repeatType,
  setSelectedRepeatEvery,
  setStepper,
}) => {
  const [selectedDay, setSelectedDay] = React.useState("");
  const [selectedMonth, setSelectedMonth] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState("");
  const [selectedYearMonth, setSelectedYearMonth] = React.useState("");
  const [selectedYearDate, setSelectedYearDate] = React.useState("");

  // for every year
  const handleYearMonthChange = (event) => {
    setSelectedYearMonth(event.target.value);
    setSelectedYearDate("");
  };

  const handleYearMonthDateChange = (event) => {
    const formattedDate = dayjs(
      `2023-${selectedYearMonth}-${event.target.value}`
    ).format("YYYY-MM-DD");
    //
    setSelectedYearDate(event.target.value);
    setRepeatType("yearly");
    setSelectedRepeatEvery(formattedDate);
  };


  const handleMonthDateChange = (event) => {
    const formattedDate = dayjs(
      `2023-${selectedMonth}-${event.target.value}`
    ).format("YYYY-MM-DD");
    setSelectedDate(event.target.value);
    setRepeatType("monthly");
    setSelectedRepeatEvery(formattedDate);
  };

  // for weekly
  const handleWeekDayChange = (event) => {
    setRepeatType("weekly");
    setSelectedDay(event.target.value);
    setSelectedRepeatEvery(event.target.value);
  };
  const daysInMonth = new Date(2023, selectedMonth, 0).getDate();
  const availableDates = Array.from(
    { length: daysInMonth },
    (_, index) => index + 1
  );
  const daysInMonthForYear = new Date(2023, selectedYearMonth, 0).getDate();
  const availableDatesForYear = Array.from(
    { length: daysInMonthForYear },
    (_, index) => index + 1
  );
  return (
    <>
      <React.Fragment>
        <Box sx={classes.drawerBody}>
          <Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={classes.header}
            >
              <Typography sx={classes.iconDiv}>
                <CloseIcon
                  sx={classes.iconn}
                  onClick={() => setStepper("datetime")}
                />
              </Typography>
              <Typography color={"#000"}>Repeat</Typography>
            </Stack>
            <Box
              borderBottom={"1px solid #e1e1e1"}
              padding={"0px 0px 8px 0px"}
              mb={"0.5rem"}
            >
              <>
                <Stack
                  sx={classes.active2}
                  fontSize={"14px"}
                  direction="row"
                  alignItems=""
                  spacing={5}
                  marginTop={1}
                  onClick={() => {
                    setRepeatType("daily");
                    setSelectedRepeatEvery("");
                  }}
                >
                  <Typography sx={classes.paddingLeft30}>Every Day</Typography>
                </Stack>
              </>
              <>
                <Stack
                  sx={classes.active2}
                  direction="row"
                  alignItems=""
                  spacing={5}
                  marginTop={1}
                >
                  <Typography sx={classes.paddingLeft30}>
                    Every Week:{" "}
                  </Typography>

                  <Box>
                    <FormControl>
                      <Select
                        value={selectedDay}
                        onChange={handleWeekDayChange}
                        sx={classes.weaklist}
                      >
                        {Object.keys(daysOfWeek).map((day) => (
                          <MenuItem
                          sx={{ fontSize: "0.75rem" }}
                            key={day + "daysofweek"}
                            value={daysOfWeek[day]}
                          >
                            {day.charAt(0).toUpperCase() + day.slice(1)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </>
              <>
                <Stack
                  sx={classes.active2}
                  direction="row"
                  alignItems=""
                  spacing={5}
                  marginTop={1}
                >
                  <Typography sx={classes.paddingLeft30}>
                    Every Month:{" "}
                  </Typography>
                  <Box>
                    <FormControl>
                      <Select
                        value={selectedDate}
                        onChange={handleMonthDateChange}
                        sx={classes.weaklist}
                      >
                        {availableDates.map((date) => (
                          <MenuItem sx={{ fontSize: "0.75rem" }} key={date + "availabledates"} value={date}>
                            {date}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </>
              <>
                <Stack
                  sx={classes.active2}
                  direction="row"
                  alignItems=""
                  spacing={5}
                  marginTop={1}
                >
                  <Typography sx={classes.paddingLeft30}>
                    Every Year:{" "}
                  </Typography>
                  <Box>
                    <FormControl>
                      <Select
                        value={selectedYearMonth}
                        onChange={handleYearMonthChange}
                        sx={classes.weaklist}
                      >
                        {months.map((month) => (
                          <MenuItem
                            sx={{ fontSize: "0.75rem" }}
                            key={month.value + "yearmonths"}
                            value={month.value}
                          >
                            {month.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl>
                      <Select
                        value={selectedYearDate}
                        onChange={handleYearMonthDateChange}
                        sx={classes.weaklist}
                      >
                        {availableDatesForYear.map((date) => (
                          <MenuItem
                            sx={{ fontSize: "0.75rem" }}
                            key={date + "availableDatesForYear"}
                            value={date}
                          >
                            {date}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </>
            </Box>

            <Box>
              <Box width={"90%"} margin={"auto"}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={"5px"}
                  mb={"0.5rem"}
                >
                  <Box>
                    <Button sx={classes?.typeBox}>Time</Button>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">:</Typography>
                  </Box>
                  <Box>
                    <Time
                      defaultValue={dueTime}
                      handleTimeChange={handleTimeChange}
                    />
                  </Box>
                </Box>

                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={"5px"}
                  mb={"0.5rem"}
                >
                  <Box>
                    <Button sx={classes?.typeBox}>Priority</Button>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">:</Typography>
                  </Box>

                  <Box display={"flex"}>
                    <Button
                      onClick={() => setPriority(priorityValue?.Low)}
                      variant="outlined"
                      sx={{
                        fontSize: "12px",
                        backgroundColor:
                          priority === priorityValue?.Low ? "#183243" : "#fff",
                        borderRadius: "3px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        padding: "0",
                        textTransform: "none",
                        color:
                          priority === priorityValue?.Low ? "#fff" : "#183243",
                        borderColor: "#183243",
                        ":hover": {
                          backgroundColor:
                            priority === priorityValue?.Low ? "#183243" : "#fff",
                          color:
                            priority === priorityValue?.Low ? "#fff" : "#183243",
                          borderRadius: "3px",
                          padding: "0",

                          borderColor: "#183243",
                        },
                      }}
                    >
                      Low
                    </Button>
                    <Button
                      onClick={() => setPriority(priorityValue?.Medium)}
                      variant="outlined"
                      sx={{
                        fontSize: "12px",
                        borderRadius: "3px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        padding: "0",
                        textTransform: "none",
                        backgroundColor:
                          priority === priorityValue?.Medium
                            ? "#183243"
                            : "#fff",
                        color:
                          priority === priorityValue?.Medium
                            ? "#fff"
                            : "#183243",
                        borderColor: "#183243",
                        ":hover": {
                          backgroundColor:
                            priority === priorityValue?.Medium
                              ? "#183243"
                              : "#fff",
                          color:
                            priority === priorityValue?.Medium
                              ? "#fff"
                              : "#183243",
                          borderRadius: "3px",
                          padding: "0",

                          borderColor: "#183243",
                        },
                      }}
                    >
                      Medium
                    </Button>

                    <Button
                      variant="outlined"
                      onClick={() => setPriority(priorityValue?.High)}
                      sx={{
                        fontSize: "12px",
                        borderRadius: "3px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderLeft: "0px",
                        padding: "0",
                        textTransform: "none",
                        backgroundColor:
                          priority === priorityValue?.High ? "#183243" : "#fff",
                        color:
                          priority === priorityValue?.High ? "#fff" : "#183243",
                        borderColor: "#183243",
                        ":hover": {
                          backgroundColor:
                            priority === priorityValue?.High
                              ? "#183243"
                              : "#fff",
                          color:
                            priority === priorityValue?.High
                              ? "#fff"
                              : "#183243",
                          borderRadius: "3px",
                          padding: "0",

                          borderColor: "#183243",
                        },
                      }}
                    >
                      High
                    </Button>
                  </Box>
                </Box>

                <Box display={"flex"} alignItems={"center"} gap={"5px"}>
                  <Box>
                    <Button sx={classes?.typeBox}>To Do</Button>
                  </Box>

                  <Box>
                    <Typography variant="subtitle1">:</Typography>
                  </Box>

                  <Box display={"flex"}>
                    <Button
                      onClick={() => setMessageType(MessageType?.Task)}
                      variant="outlined"
                      sx={{
                        fontSize: "12px",
                        borderRadius: "3px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        padding: "0",

                        textTransform: "none",
                        backgroundColor:
                          msgType === MessageType?.Task ? "#183243" : "#fff",
                        color:
                          msgType === MessageType?.Task ? "#fff" : "#183243",
                        borderColor: "#183243",
                        ":hover": {
                          backgroundColor:
                            msgType === MessageType?.Task ? "#183243" : "#fff",
                          color:
                            msgType === MessageType?.Task ? "#fff" : "#183243",
                          borderRadius: "3px",
                          padding: "0",

                          borderColor: "#183243",
                        },
                      }}
                    >
                      Task
                    </Button>
                    <Button
                      onClick={() => setMessageType(MessageType?.Approval)}
                      variant="outlined"
                      sx={{
                        fontSize: "12px",
                        borderRadius: "3px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        padding: "0",
                        textTransform: "none",
                        backgroundColor:
                          msgType === MessageType?.Approval
                            ? "#183243"
                            : "#fff",
                        color:
                          msgType === MessageType?.Approval
                            ? "#fff"
                            : "#183243",
                        borderColor: "#183243",
                        ":hover": {
                          backgroundColor:
                            msgType === MessageType?.Approval
                              ? "#183243"
                              : "#fff",
                          color:
                            msgType === MessageType?.Approval
                              ? "#fff"
                              : "#183243",
                          borderRadius: "3px",
                          padding: "0",

                          borderColor: "#183243",
                        },
                      }}
                    >
                      Approval
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Box>
      </React.Fragment>
    </>
  );
};

export default Repeat;
