import React, { useState } from "react";
import {useSelector} from "react-redux";
import {
  Box,
  Typography,
  Drawer,
  Stack,
  Button,
  ListItem,
  List,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth:"31.25rem",
    height: "100%",
  },
  header: {
    // backgroundColor: '#183243',
    backgroundColor: "#F9F8F8",
    color: "#000000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const InternalInvitieTooltip = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(true);
  const userData = useSelector((state) => state?.profile?.profileData?.data);
  const handleDone = () => {
    setDrawerOpen(false);
  };
  return (
    <Drawer sx={{ zIndex: 1202 }} open={isDrawerOpen} anchor="right">
      <Box sx={classes.drawerBody}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={classes.header}
        >
          <Typography onClick={handleDone} sx={classes.iconDiv}>
            <CloseIcon sx={classes.iconn} />
          </Typography>
          <Typography>Tooltip</Typography>
        </Stack>

        <Box width={"95%"} margin={"auto"} padding={"0rem 0.5rem"}>
          <Typography fontSize={"0.875rem"} fontWeight={600} mb={"0.8rem"}>Welcome to Arkchat</Typography>

          <Typography fontSize={"0.875rem"} mb={"0.8rem"}>
            You have landed in <b>{userData?.activeSubscriber?.subscriberFirmName} </b>account as an internal
            contact. Here, you can collaborate with your colleagues in groups or
            send them DMs.
          </Typography>

          <Typography fontSize={"0.875rem"}>Using Arkchat, you can: </Typography>

          <Box width={"90%"} margin={"auto"}>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                "& .MuiListItem-root": {
                  display: "list-item",
                  paddingTop:"0px",
                  paddingLeft:"0px"
                },
              }}
            >
              <ListItem>
                <Typography fontSize={"0.875rem"}>
                  Converse privately with select colleagues in chat groups.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography fontSize={"0.875rem"}>
                  Convert messages to tasks or approval requests.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography fontSize={"0.875rem"}>Organise messages topic-wise.</Typography>
              </ListItem>
              <ListItem>
                <Typography fontSize={"0.875rem"}>Use Arkchat Generative AI bot.</Typography>
              </ListItem>
            </List>
          </Box>

          <Typography fontSize={"0.875rem"} mb={"0.8rem"}>
            You can switch to your personal account by clicking ‘Switch’ on the
            top right corner of this page.{" "}
          </Typography>

          <Typography fontSize={"0.875rem"} mb={"0.8rem"}>To learn more, please click on FAQs.</Typography>

          <Typography fontSize={"0.875rem"}>Happy Arkchatting!</Typography>
        </Box>

        <Box
          textAlign={"center"}
          width={"100%"}
          position={"absolute"}
          bottom={"1.5rem"}
        >
          <Button
            onClick={handleDone}
            style={{
              background: "#DB0011",
              color: "white",
              borderRadius: "3px",
              textTransform: "none",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
            variant="contained"
          >
            Got it
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default InternalInvitieTooltip;
