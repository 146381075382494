import React from "react";
import {Box, Typography} from "@mui/material";
import { useDispatch} from "react-redux";
import {
  createStyledIndicatorButton,
} from "../../../common/StyledComponents/SideBarComponents";
import unstar_icon from "../../../assets/images/chats/unstar_icon.svg";
import star_icon from "../../../assets/images/chats/starred_icon.svg";
import {
  handleGetAiHistory,
  handleStar_UnstarAiHistory,
} from "../../../appStore/actions/ArkchatGroup/action";
const AIQuestionBox = ({
  questionData,
  history_id,
  is_starred,
  screenType,
}) => {
  const BadgeButton = createStyledIndicatorButton("#F3F3F3");
  const dispatch = useDispatch();
  const handleStar_UnStar_History = (history_id) => {
    dispatch(handleStar_UnstarAiHistory(history_id)).then((res) => {
      if (res?.status) {
        dispatch(handleGetAiHistory());
      }
    });
  };
  return (
    <Box>
      <Box display={"flex"} maxWidth={"96%"}>
        <Box mr={"0.5rem"}>
          <BadgeButton size="small" variant="contained">
            <Typography
              variant="subtitle2"
              fontSize={"0.87rem"}
              color={"#183242"}
              fontWeight={600}
            >
              Q
            </Typography>
          </BadgeButton>
        </Box>

        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Box maxWidth={"90%"}>
            <Typography
              variant="subtitle2"
              fontSize={"0.87rem"}
              color={"#000000"}
              fontWeight={600}
            >
              {questionData}
            </Typography>
          </Box>

          {screenType === "aiHistory" && (
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => handleStar_UnStar_History(history_id)}
            >
              <img src={is_starred ? star_icon : unstar_icon} alt="" />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AIQuestionBox;
