import * as actionTypes from "../../actions/ArkchatGroup/type";

const initState = {
  firstGroup: {},
  groupList: [],
  cloneGroupList: [],
  isLoading: false,
  groupmember: null,
  accountLevelPeople: null,
  groupmemberWithPermission: null,
  attachmentData: {},
  messageList: [],
  tempMessageList: [],
  threadMsgList: [],
  starMsgData: [],
  cloneGroupMessageList: [],
  taskList: [],
  approvalList: [],
  taskAnalyticsData: [],
  approvalAnalyticsData: [],
  aiQuerySession: [],
  aiHistoryData: [],
  likeUserList: [],
  groupRecipient: [],
};

export const arkchatgroupReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes?.REQUEST_CREATE_FIRST_GROUP:
      return {
        ...state,
        firstGroup: action?.payload,
      };
    case actionTypes?.REQUEST_ALL_GROUP:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes?.RECEIVED_ALL_GROUP_SUCCESS:
      return {
        ...state,
        groupList: action?.payload,
        cloneGroupList: action?.payload,
        isLoading: false,
      };
    case actionTypes?.RECEVED_GROUDP_MEMBER_SUCCESS:
      return {
        ...state,
        groupmember: action?.payload,
      };
    case actionTypes?.GROUDP_RECIPIENT_DETAILS:
      return {
        ...state,
        groupRecipient: action?.payload,
      };

    case actionTypes.RECEIVED_GROUP_FILTER:
      return {
        ...state,
        groupList: action?.payload,
      };
    case actionTypes.RECEIVED_USER_WITH_PERMISSION:
      return {
        ...state,
        groupmemberWithPermission: action?.payload,
      };

    case actionTypes?.RECEIVED_ONBOARDING_ACCOUNT_MEMBER:
      return {
        ...state,
        accountLevelPeople: action?.payload,
      };
    case actionTypes?.RECEIVED_ALL_ATTACHMENTS:
      return {
        ...state,
        attachmentData: action?.payload,
      };
    case actionTypes.RECEIVED_TEMP_MESSAGE_LIST:
      return {
        ...state,
        tempMessageList: action?.payload,
      };
    case actionTypes.RECEIVED_GROUP_MESSAGE_LIST:
      return {
        ...state,
        messageList: action?.payload,
        cloneGroupMessageList: action?.payload,
      };
    case actionTypes.RECEIVED_OLD_MESSAGE_LIST:
      return {
        ...state,
        messageList: [...action?.payload, ...state?.messageList],
      };
    case actionTypes.RECEIVED_GROUP_INSTANT_MESSAGE_LIST:
      return {
        ...state,
        messageList: [...state?.messageList, action?.payload],
        cloneGroupMessageList: action?.payload,
      };
    case actionTypes.UPDATE_EXISTING_MESSAGE_LIST:
      const index = state?.messageList?.findIndex(
        (obj) => obj.timetoken === action?.payload?.timetoken
      );
      if (index !== -1) {
        const updatedMessages = [...state.messageList];
        updatedMessages[index] = {
          ...updatedMessages[index],
          ...action?.payload,
        };

        return {
          ...state,
          messageList: updatedMessages,
        };
      }
      return state;

    case actionTypes.RECEIVED_THREAD_MESSAGES:
      return {
        ...state,
        threadMsgList: action?.payload,
      };
    case actionTypes.RECEIVED_GROUP_INSTANT_THREAD_MESSAGE_LIST:
      if (
        state?.threadMsgList?.length > 0 &&
        state?.threadMsgList[0]?.thread_id === null &&
        state?.threadMsgList[0]?.id === action?.payload?.thread_id
      ) {
        return {
          ...state,
          threadMsgList: [...state?.threadMsgList, action?.payload],
        };
      }
      return state;
    case actionTypes.UPDATE_EXISTING_THREAD_MESSAGE:
      const msgindex = state?.threadMsgList?.findIndex(
        (obj) => obj.temp_timetoken === action?.payload?.temp_timetoken
      );
      let updatedMessages = [...state?.threadMsgList];
      if (msgindex !== -1) {
        updatedMessages[msgindex] = {
          ...updatedMessages[msgindex],
          ...action?.payload,
        };
        return {
          ...state,
          threadMsgList: updatedMessages,
        };
      }
      return state;

    case actionTypes.DELETE_EXISTING_THREAD_MESSAGE:
      const filteredMessages = state?.threadMsgList?.filter(
        (obj) => obj.temp_timetoken !== action?.payload
      );
      return {
        ...state,
        threadMsgList: filteredMessages,
      };

    case actionTypes?.RECEIVED_ALL_TASKLIST:
      return {
        ...state,
        taskList: action?.payload,
      };

    case actionTypes?.RECEIVED_OLD_TASKLIST_DATA:
      return {
        ...state,
        taskList: [...state?.taskList, ...action?.payload],
      };

    case actionTypes?.UPDATE_EXISTING_TASK_LIST:
      const updateTaskList = state?.taskList?.filter(
        (obj) => obj.id !== action?.payload?.id
      );
      return {
        ...state,
        taskList: updateTaskList,
      };

    case actionTypes?.UPDATE_EXISTING_TASKLIST_DATA:
      const taskindex = state?.taskList?.findIndex(
        (obj) => obj.timetoken === action?.payload?.timetoken
      );
      if (taskindex !== -1) {
        const updatedMessages = [...state.taskList];
        updatedMessages[taskindex] = {
          ...updatedMessages[taskindex],
          ...action?.payload,
        };
        return {
          ...state,
          taskList: updatedMessages,
        };
      }
      return state;

    case actionTypes?.RECEIVED_ALL_APPROVALLIST:
      return {
        ...state,
        approvalList: action?.payload,
      };

    case actionTypes?.UPDATE_EXISTING_APPROVALLIST_LIST:
      const updatedApprovalList = state?.approvalList?.filter(
        (obj) => obj.id !== action?.payload?.id
      );
      return {
        ...state,
        approvalList: updatedApprovalList,
      };

    case actionTypes?.RECEIVED_OLD_APPROVALLIST_DATA:
      return {
        ...state,
        approvalList: [...state?.approvalList, ...action?.payload],
      };

    case actionTypes?.UPDATE_EXISTING_APPROVALLIST_DATA:
      const approvalIndex = state?.approvalList?.findIndex(
        (obj) => obj.timetoken === action?.payload?.timetoken
      );
      if (approvalIndex !== -1) {
        const updatedMessages = [...state.approvalList];
        updatedMessages[approvalIndex] = {
          ...updatedMessages[approvalIndex],
          ...action?.payload,
        };
        return {
          ...state,
          approvalList: updatedMessages,
        };
      }
      return state;

    case actionTypes?.RECEIVED_ALL_TASKANALYTICS_DATA:
      return {
        ...state,
        taskAnalyticsData: action?.payload,
      };
    case actionTypes?.RECEIVED_ALL_APPROVALANALYTICS_DATA:
      return {
        ...state,
        approvalAnalyticsData: action?.payload,
      };

    case actionTypes?.GET_ALL_SELLER_TAB_POSTS:
      return {
        ...state,
        sellerPostList: action?.payload,
      };
    case actionTypes?.GET_AI_HISTORY_DONE:
      return {
        ...state,
        aiHistoryData: action?.payload,
      };
    case actionTypes?.GET_QUERY_ANSWER_DONE:
      return {
        ...state,
        aiQuerySession: [...state?.aiQuerySession, action?.payload],
      };
    case actionTypes?.GET_EDIT_ANSWER_DONE:
      const aiDataIdindex = state?.aiQuerySession?.findIndex(
        (obj) => obj.id === action?.payload?.id
      );
      if (aiDataIdindex !== -1) {
        const updatedAiAnswer = [...state.aiQuerySession];
        updatedAiAnswer[aiDataIdindex] = {
          ...updatedAiAnswer[aiDataIdindex],
          ...action?.payload,
        };

        return {
          ...state,
          aiQuerySession: updatedAiAnswer,
        };
      }
      return state;

    case actionTypes?.CLEAR_CURRENT_AI_SESSION_DATA:
      return {
        ...state,
        aiQuerySession: [],
      };
    case actionTypes.REQUEST_GET_ALLSTAR_MESSAGE:
      return {
        ...state,
        starMsgData: action?.payload,
      };
    case actionTypes?.CREATE_POST_DONE:
      return {
        ...state,
        postPreviewData: action?.payload,
      };
    case actionTypes.GET_ALL_BRAND_LIST_DONE:
      return {
        ...state,
        brandDataList: {
          data: action?.payload,
        },
      };
    case actionTypes.GET_ALL_ARTISTS_LIST_DONE:
      return {
        ...state,
        artistDataList: {
          data: action?.payload,
        },
      };
    case actionTypes.GET_ALL_POST_LIST_DONE:
      return {
        ...state,
        postDataList: {
          data: action?.payload,
        },
      };
    case actionTypes.GET_ALL_POST_LIST_EXPLORE_DONE:
      return {
        ...state,
        exploreDataList: action?.payload,
      };
    case actionTypes.GET_OLD_POST_DATA_EXPLORE_DONE:
      const { count, posts } = action?.payload || {};
      return {
        ...state,
        exploreDataList: {
          count, // The updated post count from the new data
          posts: [...(state.exploreDataList.posts || []), ...posts], // Combine previous posts with new ones
        },
      };

    case actionTypes.GET_LIKE_USER_LIST:
      return {
        ...state,
        likeUserList: action?.payload,
      };

    case actionTypes.EDIT_POST_LIST_EXPLORE:
      const explorePostIdIndex = state?.exploreDataList?.posts?.findIndex(
        (obj) => obj.id === action?.payload
      );
      if (explorePostIdIndex !== -1) {
        const updatedPostData = [...state.exploreDataList?.posts];
        updatedPostData[explorePostIdIndex] = {
          ...updatedPostData[explorePostIdIndex],
          isLiked: !updatedPostData[explorePostIdIndex]?.isLiked,
        };
        return {
          ...state,
          exploreDataList: { ...state.exploreDataList, posts: updatedPostData },
        };
      }
      return state;
    default:
      return state;
  }
};
