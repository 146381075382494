import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import {
  ChatSearchButton,
  CustomInput,
  SearchBox,
  SmallIcon,
} from "../../common/chatComponents/ScreenSearchSection";
import { ActionButton } from "../explore";
import searchIcon from "../../assets/images/chats/gallery_active.svg";
import saveIcon from "../../assets/images/explore/SaveIcon.svg";
import {
  getSellerTabPost,
  handleBookmarkPost,
  handleLikePost,
} from "../../appStore/actions/ArkchatGroup/action";
import PostCard from "../post/commonComponent/PostCard/PostCard";
import { serviceTypeName, serviceTypeValue } from "../../utils/constant";
import ExploreView from "../explore/ExploreView";
import HelperScreen from "../../common/ToolTip/HelperScreen";
import { useTranslation } from "react-i18next";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import LoadingSpinner from "../../common/LoadingSpinner/LoadingSpinner";
import { getServiceCategory } from "../../appStore/actions/masterData/masterDataAction";
import { handleSavePreviewEditPostData, handleSetserviceTypePost } from "../../appStore/actions/AppState/action";
import { useNavigate } from "react-router-dom";
const MarketPlaceViewList = ({ setSellerTabLoading, isSellerTabLoading }) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const navigate = useNavigate();
  const sellerPostList = useSelector(
    (state) => state?.arkchatgroup?.sellerPostList
  );
  const userData = useSelector((state) => state?.profile?.profileData?.data);
  const dispatch = useDispatch();
  useEffect(() => {
    setSellerTabLoading(true);
    dispatch(getSellerTabPost()).then(() => {
      setSellerTabLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleLikeClick = (post_id) => {
    dispatch(handleLikePost(post_id)).then(() => {
      dispatch(getSellerTabPost()).then(() => {
        setSellerTabLoading(false);
      });
    });
  };
  const handleBookmarkClick = (post_id) => {
    dispatch(handleBookmarkPost(post_id)).then(() => {
      dispatch(getSellerTabPost()).then(() => {
        setSellerTabLoading(false);
      });
    });
  };
  const handleEditClick = (data) => {
    dispatch(handleSetserviceTypePost(data?.type));
    dispatch(getServiceCategory(data?.type));
    const createPostData = {
      category: data?.category,
      core_team: {},
      subscriber_id: data?.subscriber_id,
      tags: data?.tags || [],
      topic_id: 0,
      type: data?.type,
      countries: data?.countries || [],
      post_data: data?.postData,
      tagsInput: "",
    };
    const countriesArray = data?.countries?.map((name) => ({ name }));

    const localPreviewData = {
      selectedCategory: data?.category,
      createPostData: createPostData,
      selectedCountries: countriesArray,
      selectedImageList: data?.attachments,
      selectedVideoList: data?.attachments,
      post_id: data?.post_id,
      authorized_seller: data?.authorizedSeller,
      editBrandData: data?.brandInfo,
      editArtistData: data?.artistInfo,
    };
    dispatch(handleSavePreviewEditPostData(localPreviewData));
    localStorage.setItem("post_id", JSON.stringify(data?.id));
    let tabValue;
    if (
      data?.type === serviceTypeName?.Service ||
      data?.type === serviceTypeName?.Art ||
      data?.type === serviceTypeName?.Product
    ) {
      tabValue = 0;
    } else if (data?.type === serviceTypeName?.Photo) {
      tabValue = 1;
    } else if (data?.type === serviceTypeName?.Videos) {
      tabValue = 2;
    }
    localStorage?.setItem("tabValue", tabValue);
    navigate("/post/post-create");
  };
  return (
    <Box>
      {!userData?.tooltipMarketplaceScreenWeb && (
        <HelperScreen
          updateKey={"tooltip_marketplace_screen_web"}
          content={[
            appContent?.marketPlaceContent1,
            appContent?.marketPlaceContent2,
            appContent?.marketPlaceContent3,
            appContent?.marketPlaceContent4,
          ]}
        />
      )}

      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
        paddingX={"20px"}
        marginTop={"0.8rem"}
      >
        <SearchBox sx={{ width: "100%" }}>
          <CustomInput type="text" placeholder="Search" />
          <Box>
            <ChatSearchButton size="small" variant="contained">
              <SmallIcon src={searchIcon} />
            </ChatSearchButton>
          </Box>
        </SearchBox>

        <Box>
          <ActionButton
            // onClick={() => setBookMarkedPost(!isBookMarkedPost)}
            size="small"
            variant="contained"
          >
            <SmallIcon src={saveIcon} />
          </ActionButton>
        </Box>
      </Stack>
      {isSellerTabLoading && (
        <Box>
          <LoadingSpinner />
        </Box>
      )}
      {!isSellerTabLoading && (
        <Box
          mt={"1.2rem"}
          sx={{
            maxHeight: "70vh",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "0px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "3px",
            },
          }}
        >
          <>
            {sellerPostList?.posts?.length > 0 ? (
              <>
                <Box>
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 1194: 2, 1195: 3 }}
                  >
                    <Masonry gutter="0.5rem">
                      {sellerPostList?.posts?.length > 0 &&
                        sellerPostList?.posts.map((data, i) => (
                          <Box width={"100%"} key={i + "sellerPostdata"}>
                            <PostCard
                              type={serviceTypeValue[data?.type]}
                              postPreviewData={data}
                              showBtn={false}
                              sellerTab={true}
                              handleBookmarkClick={handleBookmarkClick}
                              handleLikeClick={handleLikeClick}
                              handleEditClick={handleEditClick}
                            />
                          </Box>
                        ))}
                    </Masonry>
                  </ResponsiveMasonry>
                </Box>
              </>
            ) : (
              <ExploreView />
            )}
            {/* {!sellerPostList?.posts?.some(
            (data) => !isBookMarkedPost || data?.isBookmarked === true
          ) && <ExploreView />} */}
          </>
        </Box>
      )}
    </Box>
  );
};

export default MarketPlaceViewList;
