import axios from "axios";
import {
  COUNTRY_API_URL,
  FAQ_LIST_URL,
  GET_COUNTRY,
  GET_COUNTRY_ERROR,
  GET_FAQ_LIST_DATA,
  GET_INDUSTRY_VIRTICAL,
  GET_INDUSTRY_VIRTICAL_ERROR,
  GET_LANGUAGE,
  GET_LANGUAGE_ERROR,
  GET_SERVICE_CATEGORY,
  GET_SERVICE_CATEGORY_ERROR,
  INDUSTRY_VIRTICAL_API_URL,
  LANGUAGE_API_URL,
  SERVICE_CATEGORY_URL,
} from ".";
import { REQUEST_FAILED } from "../global/type";
import {
  getCategoryMasterData,
  getCountryMasterData,
  getFaqDataDb,
  getIndustryMasterData,
  getLanguageMasterData,
  storeCategoryMasterData,
  storeCountryMasterData,
  storeFaqData,
  storeIndustryMasterData,
  storeLanguageMasterData,
} from "../../../config/db";
//  getting Country data
export const getCountryList = () => {
  let API_URL = COUNTRY_API_URL;
  return async (dispatch) => {
    try {
      const countryMasterData = await getCountryMasterData();
      if (countryMasterData?.length > 0) {
        dispatch({
          type: GET_COUNTRY,
          payload: countryMasterData[0]?.countryMasterData,
        });
        return countryMasterData;
      } else {
        const response = await axios.get(API_URL, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          await storeCountryMasterData(response.data.data);
          dispatch({
            type: GET_COUNTRY,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: GET_COUNTRY_ERROR,
            payload: response?.data?.data,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({ type: REQUEST_FAILED, payload: error?.response });
    }
  };
};

export const getIndustryList = () => {
  let API_URL = INDUSTRY_VIRTICAL_API_URL;
  return async (dispatch) => {
    try {
      const industryMasterData = await getIndustryMasterData();
      if (industryMasterData?.length > 0) {
        dispatch({
          type: GET_INDUSTRY_VIRTICAL,
          payload: industryMasterData[0]?.industryData,
        });
        return industryMasterData[0]?.industryData;
      } else {
        const response = await axios.get(API_URL, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          await storeIndustryMasterData(response.data.data);
          dispatch({
            type: GET_INDUSTRY_VIRTICAL,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: GET_INDUSTRY_VIRTICAL_ERROR,
            payload: response?.data?.data,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({ type: REQUEST_FAILED, payload: error?.response });
    }
    return axios
      .get(API_URL)
      .then((res) => {})
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
        //
      });
  };
};

//  getting language Data

// export async function storeLanguageMasterData(languageData) {
//   const db = initializeMasterDatabase();
//   db.languageMasterData.clear();
//   return db.languageMasterData.put({ languageData });
// }

// export async function getLanguageMasterData() {
//   const db = initializeMasterDatabase();
//   return db.languageMasterData.toArray();
// }

export const getLanguage = () => {
  let API_URL = LANGUAGE_API_URL;
  return async (dispatch) => {
    try {
      const languageData =await getLanguageMasterData();
      if (languageData?.length > 0) {
        dispatch({
          type: GET_LANGUAGE,
          payload: languageData[0]?.languageData,
        });
      } else {
        const response = await axios.get(API_URL, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          await storeLanguageMasterData(response?.data?.data);
          dispatch({
            type: GET_LANGUAGE,
            payload: response?.data?.data,
          });
          return response?.data;
        } else {
          dispatch({
            type: GET_LANGUAGE_ERROR,
            payload: response?.data?.data,
          });
          return response?.data;
        }
      }
    } catch (error) {
      dispatch({ type: REQUEST_FAILED, payload: error?.response });
    }
  };
};

//getting Service Category For Create Post
export const getServiceCategory = (type, category) => {
  let API_URL = `${SERVICE_CATEGORY_URL}?type=${type}`;
  return async (dispatch) => {
    try {
      const serviceCategoryData = await getCategoryMasterData(type);
      if (serviceCategoryData?.length > 0) {
        dispatch({
          type: category,
          payload: serviceCategoryData[0]?.categoryData,
        });
        return serviceCategoryData[0]?.categoryData;
      } else {
        const responsedata = await axios.get(API_URL, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (responsedata.status === 200) {
          await storeCategoryMasterData(type, responsedata?.data?.data);
          dispatch({
            type: category,
            payload: responsedata?.data?.data,
          });
          return responsedata?.data;
        } else {
          dispatch({
            type: GET_SERVICE_CATEGORY_ERROR,
            payload: responsedata?.data?.data,
          });
          return responsedata?.data;
        }
      }
    } catch (error) {
      dispatch({ type: REQUEST_FAILED, payload: error?.response });
    }
  };
};


// getting FAQ data
export const getFaqData = (section) => {
  let API_URL = `${FAQ_LIST_URL}/${section}`;
  return async (dispatch) => {
    try {
      const faqData=await getFaqDataDb(section);
      if(faqData?.length>0){
        dispatch({
          type:GET_FAQ_LIST_DATA,
          payload:faqData
        })
        return
      }
      else{
        const responsedata = await axios.get(API_URL, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        dispatch({
          type:GET_FAQ_LIST_DATA,
          payload:responsedata?.data?.data
        });
        await storeFaqData(responsedata?.data?.data);
        return
      }
        
      
    } catch (error) {
      dispatch({ type: REQUEST_FAILED, payload: error?.response });
    }
  };
};
