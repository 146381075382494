import React from "react";
import { useSelector } from "react-redux";
import FilterCategory from "../../MarketPlace/FilterCategory";
import { serviceTypeName } from "../../../utils/constant";
const ExploreServiceFilterTab = ({
  handleFilter,
  clearFilter,
  setChecked,
  checked,
}) => {
  const serviceCategoryData = useSelector(
    (state) => state?.serviceCategory?.serviceCategoryData
  );
  const handleFilterType = (categoryId) => {
    handleFilter(serviceTypeName?.Service, categoryId);
  };
  return (
    <>
      <FilterCategory
        data={serviceCategoryData}
        handleFilterApply={handleFilterType}
        clearFilter={clearFilter}
        checked={checked}
        setChecked={setChecked}
      />
    </>
  );
};

export default ExploreServiceFilterTab;
