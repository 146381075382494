/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import UserListDrawer from "./UserList";
import UserDateTimeDrawer from "./UserdateTime";
import Repeat from "./Repeat";
import { groupExternalMember } from "../../../utils/externalMemberGroping";
import { MessageType } from "../../../utils/constant";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import AlertPopUp from "../../../common/AlertPopUp";
import useAppSelectors from "../../../customHooks/useAppSelectors";

const Drawers = ({
  setSelectRecipientsDrawerOpen,
  setMessageType,
  msgType,
  handleMessageSend,
  newMessages,
  handleOnChangeNewMessage,
  selectedRecipients,
  setSelectedRecipients,
  taskAssignedUser,
  setTaskAssignedUser,
  dueTime,
  setDueTime,
  dueDate,
  setDueDate,
  priority,
  setPriority,
  isRepeat,
  setRepeat,
  repeatType,
  setRepeatType,
  selecteRepeatEvery,
  setSelectedRepeatEvery,
  stepper,
  setStepper,
  checkedType,
  setCheckedType,
  updateTaskApprovalMsg,
  setUpdateTaskApprovalMsg,
  isTaskUpdateOpen,
  handleUpdateTaskApproval,
  taskData,
  externalGroupMember,
  setExternalGroupMemebr,
  setAttachment,
  handleClickYouTube,
  setFileAttachment,
  handleClickGoogle,
  fileAttachment,
  attachment,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const {
    currentUserData,
    selectedGroup,
    threadMsgList,
    groupmemberdetails,
    groupRecipientDetails,
    isThreadScreen,
  } = useAppSelectors();
  const [type, setType] = useState("Task");
  const [isAlertModalOpen, setAlertModal] = useState(false);
  const externalGroupRecipient = groupRecipientDetails?.filter(
    (item) => item?.targetSubscriberId !== currentUserData?.activeSubscriber?.id
  );
  const internalGroupRecipient = groupRecipientDetails?.filter(
    (item) => item?.targetSubscriberId === currentUserData?.activeSubscriber?.id
  );
  let threadCoreMemberInternal = [];
  let threadCoreMemberExternal = [];
  if (isThreadScreen) {
    threadCoreMemberInternal = internalGroupRecipient?.filter((item) =>
      threadMsgList[0]?.participants?.includes(item.userId)
    );
    threadCoreMemberExternal = externalGroupRecipient?.filter((item) =>
      threadMsgList[0]?.participants?.includes(item.userId)
    );
  }
  const [externalMemberData, setExternalMemberData] = useState([]);
  useEffect(() => {
    if (externalGroupRecipient?.length > 0) {
      const groupedExternalData = groupExternalMember(
        externalGroupRecipient,
        true,
        appContent?.individual
      );
      setExternalMemberData(groupedExternalData);

      // let extgrpMemberObj = {};
      // groupedExternalData.forEach((company, index) => {
      //   const companyValues = Object.values(company)[0];
      //   if (companyValues) {
      //     const userIds = companyValues?.map((item) => item?.userId);
      //     const companyName = Object.keys(company)[0];
      //     const companyUserId = { [companyName]: userIds };
      //     extgrpMemberObj = { ...extgrpMemberObj, ...companyUserId };
      //   }
      // });
    }

    if (isThreadScreen) {
      const threadCoreExternalMemberData = groupExternalMember(
        threadCoreMemberExternal,
        true,
        appContent?.individual
      );
      let extgrpMemberObj = {};
      threadCoreExternalMemberData.forEach((company, index) => {
        const companyValues = Object.values(company)[0];
        const userIds = companyValues?.map((item) => item?.userId);
        const companyName = Object.keys(company)[0];
        const companyUserId = { [companyName]: userIds };
        extgrpMemberObj = { ...extgrpMemberObj, ...companyUserId };
      });
      setSelectedRecipients({
        ...selectedRecipients,
        selectedInternalRecipients: threadCoreMemberInternal,
        selectedExternalRecipients: threadCoreMemberExternal,
      });
      setExternalGroupMemebr({ ...extgrpMemberObj });
    }
  }, [selectedGroup, groupRecipientDetails]);

  const handleSelectedRecipients = (event, memberData, selectType) => {
    if (event.target.checked) {
      if (selectType === "AllMember") {
        setSelectedRecipients({
          ...selectedRecipients,
          selectedInternalRecipients: internalGroupRecipient,
          selectedExternalRecipients: externalGroupRecipient,
        });
        // for external member details.
        let extgrpMemberObj = {};
        externalMemberData.forEach((company, index) => {
          const companyValues = Object.values(company)[0];
          const userIds = companyValues?.map((item) => item?.userId);
          const companyName = Object.keys(company)[0];
          const companyUserId = { [companyName]: userIds };
          extgrpMemberObj = { ...extgrpMemberObj, ...companyUserId };
        });

        setExternalGroupMemebr({ ...extgrpMemberObj });
      } else if (selectType === "MyTeam") {
        setSelectedRecipients({
          ...selectedRecipients,
          selectedInternalRecipients: internalGroupRecipient,
        });
      } else {
        setSelectedRecipients({
          ...selectedRecipients,
          selectedInternalRecipients: [
            ...selectedRecipients?.selectedInternalRecipients,
            memberData,
          ],
        });
      }
    } else {
      if (selectType === "AllMember") {
        if (isThreadScreen) {
          setSelectedRecipients({
            ...selectedRecipients,
            selectedInternalRecipients: threadCoreMemberInternal,
            selectedExternalRecipients: threadCoreMemberExternal,
          });
        } else {
          setSelectedRecipients({
            ...selectedRecipients,
            selectedInternalRecipients: [],
            selectedExternalRecipients: [],
          });
        }
        setExternalGroupMemebr({});
      } else if (selectType === "MyTeam") {
        if (isThreadScreen) {
          setSelectedRecipients({
            ...selectedRecipients,
            selectedInternalRecipients: threadCoreMemberInternal,
          });
        } else {
          setSelectedRecipients({
            ...selectedRecipients,
            selectedInternalRecipients: [],
          });
        }
      } else {
        const updatedInternalRecipients =
          selectedRecipients?.selectedInternalRecipients?.filter(
            (user) => user?.userId !== memberData?.userId
          );
        setSelectedRecipients({
          ...selectedRecipients,
          selectedInternalRecipients: updatedInternalRecipients,
        });
      }
    }
  };
  const handleSelectExternalRecipients = (
    event,
    selectedPerson,
    companyName,
    companyAllMemberData
  ) => {
    if (event.target.checked) {
      setSelectedRecipients({
        ...selectedRecipients,
        selectedExternalRecipients: [
          ...selectedRecipients?.selectedExternalRecipients,
          selectedPerson,
        ],
      });
      if (externalGroupMember[companyName]?.length > 0) {
        const companyUserId = {
          [companyName]: [
            ...externalGroupMember[companyName],
            selectedPerson?.userId,
          ],
        };
        setExternalGroupMemebr({ ...externalGroupMember, ...companyUserId });
      } else {
        const companyUserId = { [companyName]: [selectedPerson?.userId] };
        setExternalGroupMemebr({ ...externalGroupMember, ...companyUserId });
      }
    } else {
      const updatedCompanyUserId = externalGroupMember[companyName]?.filter(
        (item) => item !== selectedPerson?.userId
      );
      const updatedData = { [companyName]: updatedCompanyUserId };
      const updatedInternalRecipients =
        selectedRecipients.selectedExternalRecipients?.filter(
          (item) => item?.userId !== selectedPerson?.userId
        );
      setSelectedRecipients({
        ...selectedRecipients,
        selectedExternalRecipients: updatedInternalRecipients,
      });
      setExternalGroupMemebr({ ...externalGroupMember, ...updatedData });
    }
  };

  const handleSelectCompanyAllRecipients = (
    event,
    selectedCompanyPerson,
    companyName
  ) => {
    if (event.target.checked) {
      const userIdData = selectedCompanyPerson[companyName]?.map(
        (user) => user?.userId
      );
      const updatedInternalRecipients = selectedCompanyPerson[companyName]?.map(
        (user) => user
      );
      setSelectedRecipients({
        ...selectedRecipients,
        selectedExternalRecipients: [
          ...selectedRecipients?.selectedExternalRecipients,
          ...updatedInternalRecipients,
        ],
      });
      const companyUserId = { [companyName]: userIdData };
      setExternalGroupMemebr({ ...externalGroupMember, ...companyUserId });
    } else {
      handleRemoveSelectedCompanyMembers(externalGroupMember[companyName]);
      const updatedExternalGroupMember = { ...externalGroupMember };
      delete updatedExternalGroupMember[companyName];
      setExternalGroupMemebr(updatedExternalGroupMember);
    }
  };
  const handleRemoveSelectedCompanyMembers = (idsToRemove) => {
    const updatedSelectedExternalGroupMember =
      selectedRecipients.selectedExternalRecipients?.filter(
        (member) => !idsToRemove.includes(member?.userId)
      );
    if (isThreadScreen) {
      setSelectedRecipients({
        ...selectedRecipients,
        selectedExternalRecipients: [
          ...updatedSelectedExternalGroupMember,
          ...threadCoreMemberExternal,
        ],
      });
    } else {
      setSelectedRecipients({
        ...selectedRecipients,
        selectedExternalRecipients: updatedSelectedExternalGroupMember,
      });
    }
  };
  const handleCLickTodo = (memberData, isInternal = true) => {
    if (
      selectedRecipients?.selectedInternalRecipients?.length !== 0 ||
      selectedRecipients?.selectedExternalRecipients?.length !== 0
    ) {
      if (msgType === MessageType?.Message) {
        setMessageType(MessageType?.Task);
      }
      let member = isInternal
        ? {
            userId: memberData?.userId,
            profileImgUrl: memberData?.userInfo?.profileImgUrl,
            firstName: memberData?.userInfo?.firstName,
            lastName: memberData?.userInfo?.lastName,
            targetSubscriberId: memberData?.subscriberId,
            languageCode: memberData?.languageCode,
          }
        : {
            userId: memberData?.userId,
            profileImgUrl: memberData?.profileImgUrl,
            firstName: memberData?.firstName,
            lastName: memberData?.lastName,
            targetSubscriberId: memberData?.targetSubscriberId,
            languageCode: memberData?.languageCode,
          };
      setTaskAssignedUser(member);
      handleNextStep("datetime");
    } else {
      setAlertModal(true);
    }
  };

  const handleTimeChange = (newTime) => {
    const formattedTime = newTime.format("HH:mm");
    //
    setDueTime(newTime);
  };
  //
  const handleDateChange = (newDate) => {
    setDueDate(newDate);
  };
  const handleNextStep = (steps) => {
    setStepper(steps);
  };

  const handleClostRecipientsDrawer = () => {
    setSelectedRecipients({
      selectedInternalRecipients: [],
      selectedExternalRecipients: [],
    });
    setTaskAssignedUser({});
    setExternalGroupMemebr({});
    setSelectRecipientsDrawerOpen(false);
    if (stepper !== "datetime" && !isTaskUpdateOpen) {
      setDueDate(dayjs());
      setDueTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
      setMessageType(MessageType?.Message);
      setStepper("user");
    } else if (stepper === "datetime" && isTaskUpdateOpen) {
      setDueTime(dayjs(taskData?.due_time, "HH:mm"));
      setDueDate(dayjs(taskData?.due_date));
      setMessageType(taskData?.type);
      setStepper("user");
    } else if (stepper !== "datetime" && isTaskUpdateOpen) {
      setDueTime(dayjs(taskData?.due_time, "HH:mm"));
      setDueDate(dayjs(taskData?.due_date));
      setMessageType(taskData?.type);
      setStepper("user");
    }
  };

  return (
    <React.Fragment>
      <AlertPopUp
        title={"Alert."}
        bodyContent={
          "To convert your message to a task or approval request, first select recipients, then click 'To Do'"
        }
        open={isAlertModalOpen}
        closeModal={setAlertModal}
      />
      {stepper === "user" && (
        <UserListDrawer
          handleSelectedRecipients={handleSelectedRecipients}
          handleSelectCompanyAllRecipients={handleSelectCompanyAllRecipients}
          handleSelectExternalRecipients={handleSelectExternalRecipients}
          groupMemberData={groupmemberdetails}
          internalMemebrData={internalGroupRecipient}
          externalGroupRecipient={externalGroupRecipient} //this is array for checcking length
          currentUserData={currentUserData}
          externalMemberData={externalMemberData}
          externalGroupMember={externalGroupMember}
          handleClostRecipientsDrawer={handleClostRecipientsDrawer}
          handleNextStep={handleNextStep}
          setMessageType={setMessageType}
          handleOnChangeNewMessage={handleOnChangeNewMessage}
          newMessages={newMessages}
          selectedRecipients={selectedRecipients}
          handleCLickTodo={handleCLickTodo}
          setStepper={setStepper}
        />
      )}

      {stepper === "datetime" && (
        <UserDateTimeDrawer
          handleClostRecipientsDrawer={handleClostRecipientsDrawer}
          handleNextStep={handleNextStep}
          taskAssignedUser={taskAssignedUser}
          selectedRecipients={selectedRecipients}
          handleTimeChange={handleTimeChange}
          handleDateChange={handleDateChange}
          setType={setType}
          dueTime={dueTime}
          dueDate={dueDate}
          setDueDate={setDueDate}
          setPriority={setPriority}
          priority={priority}
          setMessageType={setMessageType}
          msgType={msgType}
          setRepeat={setRepeat}
          setStepper={setStepper}
          checkedType={checkedType}
          setCheckedType={setCheckedType}
          updateTaskApprovalMsg={updateTaskApprovalMsg}
          setUpdateTaskApprovalMsg={setUpdateTaskApprovalMsg}
          handleUpdateTaskApproval={handleUpdateTaskApproval}
          isTaskUpdateOpen={isTaskUpdateOpen}
          taskData={taskData}
          setAttachment={setAttachment}
          handleClickYouTube={handleClickYouTube}
          setFileAttachment={setFileAttachment}
          handleClickGoogle={handleClickGoogle}
          fileAttachment={fileAttachment}
          attachment={attachment}
        />
      )}

      {stepper === "repeat" && (
        <Repeat
          handleClostRecipientsDrawer={handleClostRecipientsDrawer}
          handleNextStep={handleNextStep}
          taskAssignedUser={taskAssignedUser}
          selectedRecipients={selectedRecipients}
          handleTimeChange={handleTimeChange}
          handleDateChange={handleDateChange}
          setType={setType}
          dueTime={dueTime}
          dueDate={dueDate}
          setDueDate={setDueDate}
          setPriority={setPriority}
          priority={priority}
          setMessageType={setMessageType}
          msgType={msgType}
          setRepeat={setRepeat}
          setStepper={setStepper}
          checkedType={checkedType}
          setCheckedType={setCheckedType}
          updateTaskApprovalMsg={updateTaskApprovalMsg}
          setUpdateTaskApprovalMsg={setUpdateTaskApprovalMsg}
          handleUpdateTaskApproval={handleUpdateTaskApproval}
          isTaskUpdateOpen={isTaskUpdateOpen}
          taskData={taskData}
          setRepeatType={setRepeatType}
          repeatType={repeatType}
          setSelectedRepeatEvery={setSelectedRepeatEvery}
        />
      )}
    </React.Fragment>
  );
};

export default Drawers;
