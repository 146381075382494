import React, { useState } from "react";
import { FormControl } from "@mui/base";
import {
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import validator from "validator";
import IntroVideo from "../../common/IntroVideo";
import { showToastMsg } from "../../appStore/actions/global/action";
import {
  AppleButton,
  AuthActionButton,
  GoogleButton,
  GoogleImg,
} from "../../common/StyledComponents/AuthComponents";
import {
  deleteAccountDetails,
  handleDeleteAccountFromOpenRoute,
  handleOtpVerifyDeleteAccountFromOpenRoute,
} from "../../appStore/actions/AppState/action";
import { appleprovider, auth, provider } from "../../config/firebase.config";
import googleLogo from "../../assets/images/auth/googleLogo.svg";
import appleIcon from "../../assets/images/auth/apple.svg";
import ConfirmationPopUp from "./ConfirmationPopUp";

const classes = {
  blockInputBox: {
    width: "100%",
  },
  forgotPasswordBox: {
    height: "calc(100vh - 4rem)",
    maxHeight: "40.7rem",
    minHeight: "28rem",
    width: "100%",
    maxWidth: "43rem",
    position: "relative",
    "@media only screen and (max-width:899px)": {
      height: "100%",
    },
    "@media only screen and (max-width: 1204px)": {
      minHeight: "38rem",
    },
  },
  captions: {
    fontSize: "0.87rem !important",
    display: "block",
  },

  sendBtn: {
    position: "absolute",
    bottom: "2rem",
    left: "50%",
    marginLeft: "-2.72rem",
    "@media only screen and (max-width:899px)": {
      position: "relative",
      marginBottom: "-1.8rem",
      bottom: "0 !important",
    },
    "@media only screen and (max-width:1300px)": {
      bottom: "1.5rem",
    },
  },
  mobscroll: {
    overflowX: "hidden",
    "@media only screen and (max-width:899px)": {
      overflowX: "hidden",
    },
  },
};

const DeleteAccount = () => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(true);
  const [accountDetail, setAccountDetails] = useState({});
  const [isRequiredEmpty, setIsRequiredEmpty] = useState({
    email: false,
    password: false,
  });
  const handleGoogle = () => {
    signInWithPopup(auth, provider)
      .then(async (res) => {
        const user = res?.user;
        const userdetails = user?.providerData[0];
        const reqbody = {
          email: userdetails?.email,
          social_id: user?.providerData[0]?.uid,
          type: 1,
        };

        const response = await dispatch(
          handleDeleteAccountFromOpenRoute(reqbody)
        );
        if (response?.status) {
          setAccountDetails(response?.data);
          setPopupOpen(true);
        } else {
          dispatch(showToastMsg(response?.message));
        }
      })
      .catch((err) => {});
  };

  const handleloginwithApple = () => {
    signInWithPopup(auth, appleprovider)
      .then(async (response) => {
        const userdetails = response?.user?.providerData?.[0];
        const reqbody = {
          email: userdetails?.email,
          social_id: userdetails?.uid,
          type: 2,
        };
        const res = await dispatch(handleDeleteAccountFromOpenRoute(reqbody));
        if (res?.status) {
          setAccountDetails(response?.data);
          setPopupOpen(true);
        } else {
          dispatch(showToastMsg("Something went wrong !"));
        }
      })
      .catch(() => {
        //
      });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    if(email){
      const reqbody = {
        email: email,
      };
      const response = await dispatch(
        handleDeleteAccountFromOpenRoute(reqbody)
      );
      if (response?.status) {
        dispatch(deleteAccountDetails(response?.data))
        navigate("/delete-account-otp-verify")
      } else {
        dispatch(showToastMsg("Something went wrong !"));
      }
    }else {
      const isRequiredValue = {
        ...isRequiredEmpty,
        email: !email ? true : false,
      };
      const isValidEmail = validator.isEmail(email);
      setIsValid(isValidEmail);
      setIsRequiredEmpty(isRequiredValue);
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    dispatch(
      handleOtpVerifyDeleteAccountFromOpenRoute({
        email: accountDetail?.email,
        user_id: accountDetail?.id,
      })
    ).then((res) => {
      if(res?.status){
        dispatch(showToastMsg(res?.message));
      }else{
        dispatch(showToastMsg(res?.message||"Something went wrong !"));
      }
      setPopupOpen(false);
    });
  };

  return (
    <Box m={2}>
      <ConfirmationPopUp
        open={isPopupOpen}
        closeModal={setPopupOpen}
        title={appContent?.deleteAccountConfirmationTitle}
        handleDone={handleConfirm}
      />
      <Grid container justifyContent="space-between" sx={classes.mobscroll}>
        <Grid item xs={12} md={5}>
          <IntroVideo />
        </Grid>
        <Grid ml={"auto"} item xs={12} md={6} padding={"0.4rem 1rem"}>
          <Box
            boxShadow={"0px 4px 14px rgba(0, 0, 0, 0.09)"}
            padding={"1rem"}
            sx={classes.forgotPasswordBox}
          >
            <Typography fontSize={"1.7rem"} fontWeight={600} variant="h5">
              {appContent?.deleteYourAccount}
            </Typography>
            <Typography mt={"0.4em"} sx={classes.captions} variant="caption">
              {appContent?.deleteAccountSubtitle}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "4px",
              }}
              mt={"1rem"}
            >
              <GoogleButton variant="outlined" onClick={handleGoogle}>
                <GoogleImg src={googleLogo} alt="Google Logo" />
                {appContent?.googleLogin}
              </GoogleButton>
              <AppleButton
                sx={{
                  bgcolor: "#000000",
                  color: "#FFFFFF",
                  border: "1px solid #FFFFFF",
                  display: "flex",
                  alignItems: "center",
                  padding: "0.36rem 0.76rem",
                  borderRadius: "0.49rem",
                  textTransform: "none",
                  ":hover": {
                    bgcolor: "#000000",
                    color: "#FFFFFF",
                    border: "1px solid #FFFFFF",
                  },
                }}
                size="small"
                variant="outlined"
                onClick={handleloginwithApple}
              >
                <GoogleImg src={appleIcon} alt="Apple Logo" />
                {appContent?.appleLogin}
              </AppleButton>
            </Box>
            <form onSubmit={handleSubmit} noValidate>
              <FormControl>
                <TextField
                  type="email"
                  label="Email"
                  variant="standard"
                  error={!isValid || isRequiredEmpty?.email ? true : false}
                  value={email.trim()}
                  onChange={(e) => {
                    setEmail(e.target.value.trim());
                    setIsValid(validator.isEmail(e.target.value.trim()));
                  }}
                  required
                  sx={classes.blockInputBox}
                />
                <FormHelperText
                  error={!isValid || isRequiredEmpty?.email ? true : false}
                >
                  {appContent?.required}
                </FormHelperText>
              </FormControl>

              <Box sx={classes.sendBtn}>
                <AuthActionButton
                  disabled={isLoading}
                  type="submit"
                  variant="contained"
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    appContent?.send
                  )}
                </AuthActionButton>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeleteAccount;
