import React, { useState } from "react";
import {
  Box,
  Typography,
  Drawer,
  Stack,
  Button,
  ListItem,
  List,
} from "@mui/material";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { profileUpdate } from "../../appStore/actions/profile/action";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth:"31.25rem",
    height: "100%",
  },
  header: {
    // backgroundColor: '#183243',
    backgroundColor: "#F9F8F8",
    color: "#000000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const FirstGroupToolTip = ({ updateKey }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(true);
  const dispatch = useDispatch();
  const handleDone = () => {
    setDrawerOpen(false);
    dispatch(profileUpdate({ [updateKey]: true }, false));
  };
  return (
    <Drawer sx={{ zIndex: 1202 }} open={isDrawerOpen} anchor="right">
      <Box sx={classes.drawerBody}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={classes.header}
        >
          <Typography onClick={handleDone} sx={classes.iconDiv}>
            <CloseIcon sx={classes.iconn} />
          </Typography>
          <Typography>Tooltip</Typography>
        </Stack>

        <Box width={"95%"} margin={"auto"} padding={"0rem 0.5rem"}>
          <Typography fontSize={"0.875rem"} fontWeight={600} mb={"0.8rem"}>Welcome to Arkchat</Typography>

          <Typography fontSize={"0.875rem"} mb={"0.8rem"}>
            Follow the screen prompts to start using Arkchat in minutes. After
            creating your first group, you can:
          </Typography>

          <Box idth={"90%"} margin={"auto"}>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                "& .MuiListItem-root": {
                  display: "list-item",
                  paddingTop:"0px",
                  paddingLeft:"0px"
                },
              }}
            >
              <ListItem>
                <Typography fontSize={"0.875rem"}>
                  Converse privately with select colleagues in your chat group.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography fontSize={"0.875rem"}>
                  Convert messages to tasks or approval requests.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography fontSize={"0.875rem"}>Organise messages topic-wise.</Typography>
              </ListItem>
              <ListItem>
                <Typography fontSize={"0.875rem"}> Use Arkchat Generative AI bot.</Typography>
              </ListItem>
            </List>
          </Box>

          <Typography >
            Now, manage your business effortlessly through day-to-day messaging.{" "}
          </Typography>

          <Typography>To learn more, please click on FAQs.</Typography>

          <Typography>Happy Arkchatting!</Typography>
        </Box>

        <Box
          textAlign={"center"}
          width={"100%"}
          position={"absolute"}
          bottom={"1.5rem"}
        >
          <Button
            onClick={handleDone}
            style={{
              background: "#DB0011",
              color: "white",
              borderRadius: "3px",
              textTransform: "none",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
            variant="contained"
          >
            Got it
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default FirstGroupToolTip;
