import React from "react";
import ProfileForm from "./ProfileForm";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import IntroVideo from "../../common/IntroVideo";
const classes = {
  Wrapper: {
    display: "flex",
    alignItems: "center",

    "@media only screen and (min-width: 1901px)": {
      width: "80%",
      margin: "auto",
    },
  },
};
const IndividualProfile = () => {
    const profileData = useSelector((state) => state?.profile?.profileData);
  return (
    <Box m={2}>
      <Grid container justifyContent="space-between" sx={classes?.Wrapper}>
        <Grid item xs={12} md={5}>
        <IntroVideo />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProfileForm profileData={profileData}/>
        </Grid>
      </Grid>
    </Box>
    // <div>
    //   <ProfileForm/>
    // </div>
  );
};

export default IndividualProfile;
