import React from "react";

const ChatSend = ({ color = "" }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 568">
        <rect id="Rectangle 99" width="30" height="30" rx="2" fill="#DB0011" />
        <path
          id="Vector"
          d="M23.07 14.67L23.02 14.71L16.06 21.69H6L13.02 14.67L6.36 8H16.4L23.02 14.62L23.07 14.67Z"
          fill={color ? color : "white"}
        />
      </g>
    </svg>
  );
};

export default ChatSend;
