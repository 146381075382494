import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import exploreIllustration from "../../assets/images/explore/Explore_illustration.svg";
import { serviceTypeValue } from "../../utils/constant";

const ExploreView = ({ bookmarkedMsg, type }) => {
  return (
    <>
      <Box>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 175px)",
          }}
        >
          <img
            src={exploreIllustration}
            style={{ height: "250px", maxHeight: "300px" }}
            alt=""
          />
          <Typography
            textAlign={"center"}
            maxWidth={"250px"}
            marginTop={"30px"}
          >
            {type === 0 ? (
              <>No one has yet posted any service</>
            ) : (
              <>No one has yet posted any {serviceTypeValue[type]}</>
            )}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default ExploreView;
