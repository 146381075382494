import React from "react";
import { Box, Typography } from "@mui/material";
import { ActionButton } from "../../../common/chatComponents/ScreenSearchSection";
import { useSelector } from "react-redux";
import editLogo from "../../../assets/images/auth/editLogo.svg";
import cameraLogo from "../../../assets/images/auth/cameraLogo.svg";
import smallCloseIcon from "../../../assets/images/post/closeIcon.svg";

const PictureComponent = ({
  handleCameraButtonClick,
  coverPicRef,
  profilePicRef,
  handleProfileImgInputChange,
  coverImage,
  profileImage,
  handleRemovePic,
}) => {
  const userData = useSelector((state) => state?.profile?.profileData);
  return (
    <Box maxHeight={"9rem"} height="9rem" width={"100%"} position={"relative"}>
      <Box height={"100%"} overflow={"hidden"}>
        <img
          src={coverImage?.src}
          alt="name"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
        {coverImage?.isCoverSelected && (
          <Box
            sx={{ cursor: "pointer" }}
            zIndex={100}
            position={"absolute"}
            top={"0.2rem"}
            right={"0.2rem"}
            onClick={() => handleRemovePic("cover")}
          >
            <img src={smallCloseIcon} alt="close" />
          </Box>
        )}
      </Box>

      <Box
        display={"flex"}
        position={"absolute"}
        alignItems={"center"}
        right={"1%"}
        bottom={"2%"}
        gap={"0.5rem"}
      >
        <input
          type="file"
          name="cover"
          accept="image/*"
          ref={coverPicRef}
          style={{ display: "none" }}
          onChange={handleProfileImgInputChange}
        />
        <Box>
          <Typography variant="h6" fontSize={"0.85rem"} color={"#fff"}>
            Optional
          </Typography>
        </Box>
        <ActionButton
          variant="contained"
          size="small"
          onClick={() => handleCameraButtonClick("cover")}
        >
          <img src={editLogo} alt="edit" />
        </ActionButton>
      </Box>

      <Box
        bgcolor={"#183242"}
        position={"absolute"}
        left="50%"
        marginLeft={"-50px"}
        transform="translateX(-40%)"
        bottom="-20%"
        borderRadius={"0.2rem"}
        overflow={"hidden"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          position={"relative"}
          height={"6.25rem"}
          width={"6.25rem"}
          minHeight={"100%"}
          minWidth={"100%"}
          bgcolor={"#EAEAEA"}
          padding={
            userData?.data?.profileImgUrl?.length===0 ? "0.9rem" : "0rem"
          }
        >
          <img
            src={profileImage?.src}
            alt="name"
            style={{ width: "100%", height: "100%", objectFit: "fill" }}
          />
          {profileImage?.isProfileSelected && (
            <Box
              sx={{ cursor: "pointer" }}
              zIndex={100}
              position={"absolute"}
              top={"0.1rem"}
              right={"0.1rem"}
              onClick={() => handleRemovePic("profilepic")}
            >
              <img src={smallCloseIcon} alt="close" />
            </Box>
          )}
          <Box position={"absolute"} right={"1%"} bottom={"1.5%"}>
            <input
              type="file"
              accept="image/*"
              ref={profilePicRef}
              style={{ display: "none" }}
              onChange={handleProfileImgInputChange}
            />
            <ActionButton
              variant="contained"
              size="small"
              onClick={() => handleCameraButtonClick("profile")}
            >
              <img src={cameraLogo} alt="edit" />
            </ActionButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PictureComponent;
