export function findUser(usersData, userDataToFind) {
  const user = [
    ...usersData?.internalMember,
    ...usersData?.externalMember,
  ]?.find((userData) => userData?.userId === userDataToFind);
  return user;
}
export function findGroupRecipientData(usersData, userDataToFind) {
  const user = usersData?.find(
    (userData) => userData?.userId === userDataToFind
  );
  return user;
}

export const setProfileData = (
  data,
  setProfileViewData,
  setProfileViewModal
) => {
  const viewData = {
    companyName: data?.userData?.subscriberFirmName || "",
    userName: data?.userInfo
      ? `${data?.userInfo?.firstName} ${data?.userInfo?.lastName}`
      : `${data?.firstName} ${data?.lastName}`,
    indusrtyName:
      data?.userData?.subscriberAccountIndustry ||
      data?.activeSubscriber?.subscriberAccountIndustry,
    cityName: data?.userData?.city || data?.city,
    countryName: data?.userData?.country || data?.country,
    profileBrief: data?.userInfo?.profileBrief || data?.profileBrief,
    profileImgUrl: data?.userInfo?.profileImgUrl || data?.profileImgUrl,
    coverImgUrl: data?.userInfo?.coverImgUrl || data?.coverImgUrl,
    isGroup: false,
    website: data?.userInfo?.website || data?.website,
    linkedin: data?.userInfo?.linkedin || data?.linkedin,
  };

  setProfileViewData(viewData);
  setProfileViewModal(true);
};

export function checkDoesHaveSid(sidList, sid) {
  const isSidPresent = sidList?.find((item) => item?.id === sid);
  return isSidPresent;
}



export function getLast12Months() {
  const months = [];
  const currentDate = new Date();

  for (let i = 0; i < 12; i++) {
      // Create a new date for each month
      const monthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
      // Get the month and year
      const monthName = monthDate.toLocaleString('default', { month: 'long' }); // Full month name
      const year = monthDate.getFullYear();
      months.push(`${monthName} ${year}`);
  }

  return months.reverse(); // Reverse to get the order from the past to the present
}




export function logWrapper(...args) {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args);
  }
}