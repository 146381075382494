import React, { useState } from "react";
import { FormControl } from "@mui/base";
import {
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useTranslation } from "react-i18next";
import { AuthActionButton } from "../../../common/StyledComponents/AuthComponents";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { encrypter } from "../../../utils/encryptpassword";
import { resetPassword } from "../../../appStore/actions/auth/resetpassword/resetpassword";
import IntroVideo from "../../../common/IntroVideo";
import { showToastMsg } from "../../../appStore/actions/global/action";

const classes = {
  blockInputBox: {
    width: "100%",
    marginTop: "1.5rem",
  },
  createNewBox: {
    padding: "1.5rem 1rem 2rem 2rem",
    height: "calc(100vh - 42px)",
    position: "relative",
  },
  resetPasswordBox: {
    padding: "1.5rem 1rem 2rem 2rem",
    height: "85vh",
    position: "relative",
  },
  captions: {
    fontSize: "0.87rem !important",
    display: "block",
  },
};
const NewPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.profile?.emailStore?.data);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleReEnterPassword = () => {
    setShowRePassword(!showRePassword);
  };
  //  validation password
  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&&()#`./<>{}[\]:;"])[A-Za-z\d@$!%*?&&()#`./<>{}[\]:;"]{8,}$/;
    return passwordRegex.test(password);
  };
  const handleEncrypt = (text) => {
    const encrypted = encrypter.encrypt(text);
    return encrypted;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let result = validatePassword(password);
    setPasswordError(!result);
    if (!isLoading) {
      setLoading(true);
      if (password === reEnterPassword && result) {
        const encryptedText = handleEncrypt(password);
        if (encryptedText) {
          let formBody = {
            password: encryptedText,
            email: user,
          };
          dispatch(resetPassword(formBody)).then((res) => {
            if (res?.status) {
              setLoading(false);
              localStorage.removeItem("forgotpassword");
              navigate("/");
            } else {
              setLoading(false);
              dispatch(showToastMsg(res?.message));
            }
          });
        }
      }
    }
  };
  return (
    <Box m={2}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={5}>
          {/* <CarouselComponent showLogo={showLogo} /> */}
          <IntroVideo />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            m={1}
            sx={classes.createNewBox}
            boxShadow={"0px 4px 14px rgba(0, 0, 0, 0.09)"}
            borderRadius={"2px"}
          >
            <>
              <Typography fontSize={"1.7rem"} fontWeight={600} variant="h5">
                {appContent?.createNewPassword}
              </Typography>
              <Typography mt={"0.4em"} sx={classes.captions} variant="caption">
                {appContent?.previousPassShouldntMatchNew}
              </Typography>
            </>

            <Box mt={""}>
              <form onSubmit={handleSubmit}>
                <FormControl>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    label={appContent?.newpassword}
                    variant="standard"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    sx={classes.blockInputBox}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword}>
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <RemoveRedEyeIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText error={passwordError ? true : false}>
                    {" "}
                    {appContent?.passwordRequirements}
                  </FormHelperText>
                </FormControl>
                <FormControl>
                  <TextField
                    type={showRePassword ? "text" : "password"}
                    label={appContent?.reEnterPassword}
                    variant="standard"
                    name="reEnterPassword"
                    onChange={(e) => setReEnterPassword(e.target.value)}
                    required
                    sx={classes.blockInputBox}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleToggleReEnterPassword}>
                            {showRePassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <RemoveRedEyeIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText>{appContent?.required}</FormHelperText>
                </FormControl>
                <Box sx={{ position: "absolute", bottom: "2rem" }}>
                  <AuthActionButton
                    type="submit"
                    variant="contained"
                    disabled={
                      password.length > 0 && password === reEnterPassword
                        ? false
                        : true
                    }
                  >
                    {isLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      appContent?.save
                    )}
                  </AuthActionButton>
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewPassword;
