import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch} from "react-redux";
import arkChatLogo from "./assets/images/arkchatlogoLoading.svg";
import { Box, Typography } from "@mui/material";
import { getProfile } from "./appStore/actions/profile/action";
import { getCookie } from "./config/cookie";
import { createRetriveInvite } from "./appStore/actions/subscriber/action";

const Invitation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleJoinProcess = async () => {
    if (getCookie("token")) {
      const userData = await dispatch(getProfile(true));
      // if user is logged in and completed profile update process
      if (userData?.data?.isProfileJourneyCompleted) {
        navigate("/invitations");
      }
      // if user is new
      if (!userData?.data?.isProfileJourneyCompleted) {
        const invitationData =
          JSON.parse(localStorage.getItem("invitationData")) || false;
        // If Invite is Internal and user is new
        if (invitationData && +invitationData?.inviteType === 1) {
          if (
            userData?.data?.subscribedTo?.length === 0 &&
            !userData?.data?.isProfileJourneyCompleted
          ) {
            navigate("/individualprofile");
          } else if (userData?.data?.subscribedTo?.length > 0) {
            const reqBody = {
              invite_token: invitationData?.token,
              invite_code:invitationData?.inviteCode,
              invite_name:invitationData?.inviteName,
              invite_type: invitationData?.inviteType,
              target_subscriber_id:
                localStorage.getItem("newProfile_subscriber_id") ||
                userData?.data?.profileData?.data?.subscribedTo[0]?.id,
            };
            dispatch(createRetriveInvite(reqBody))
            navigate("/invitations");
          }
        } else if (invitationData && +invitationData?.inviteType === 2) {
          navigate("/profile");
        }
      }
    } else {
      navigate("/");
    }
  };



  useEffect(() => {
    localStorage.removeItem("newProfile_subscriber_id");
    const urlString = window.location.href;
    const url = new URL(urlString);

    const queryParams = new URLSearchParams(url.search);
    const params = {};
    for (const [key, value] of queryParams.entries()) {
      params[key] = value;
    }
    const localStoragePromise = new Promise((resolve) => {
      localStorage.setItem("invitationData", JSON.stringify(params));
      resolve();
    });

    // Wait for localStorage to be set, then proceed with redirection
    localStoragePromise.then(() => {
      handleJoinProcess();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      
      {isLoading && (
        <Box
          position={"absolute"}
          left="40%"
          transform="translateX(-50%)"
          top="30%"
          borderRadius={"0.2rem"}
          overflow={"hidden"}
        >
          <Box width={"30%"}>
            <img style={{ width: "100%" }} src={arkChatLogo} alt="Arkchat" />
          </Box>
          <Box>
            <Typography className="loading-dots" variant="h5">
              Loading...
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Invitation;
