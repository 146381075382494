import { Box, Button, Typography, styled } from "@mui/material";



export const StyledSideBarHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#F3F3F3",
    padding:"0.3rem",
    marginBottom: "0.2rem",
    minHeight:"2.37rem",
  });
  export const StyledTopicsHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#F3F3F3",
    padding: "0.25rem 0.5rem",
  });
  export const StyledSideBarInviteButton = styled(Typography)({
    backgroundColor: "transparent",
    color: "black",
    fontSize: "14px",
    fontWeight:"500",
    textTransform: "none",
    "&:hover": {
      cursor:"pointer"
    },
     "@media (max-width: 63em)": {
     padding: "0.25rem 0.25rem",
  },
  });
  

  export const StyledSidebarButton = styled(Button)({
    backgroundColor: "#DB0011",
    padding: "4px 8px",
    color: "#fff",
    fontSize: "0.8rem",
    minWidth: "unset",
    minHeight: "unset",
    borderRadius: "0.15rem",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#DB0011",
      color: "#fff",
    },
     "@media (max-width: 63em)": {
     
  },
  });
  
  export const createStyledIndicatorButton = (backgroundColor) =>
    styled(Button)({
      backgroundColor: backgroundColor,
      width: "1.87rem",
      height: "1.87rem",
      minWidth: "unset",
      minHeight: "unset",
      borderRadius: "0.15rem",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: backgroundColor,
        boxShadow: "none",
      },
    });
  
  export const IndicatorIcon = styled("img")({
    width: "1.2rem",
    height: "1.4rem",
     "@media (max-width: 63em)": {
        width: "1rem",
        height: "1.2rem",
  },
  });
  export const FilterIcon = styled("img")({
    width: "1.2rem",
    height: "1.4rem",
     "@media (max-width: 63em)": {
        width: "1rem",
        height: "1.2rem",
  },
  });
  export const EditTIcon = styled("img")({
    width: "1.4rem",
    height: "1.4rem",
     "@media (max-width: 63em)": {
        width: "1rem",
        height: "1rem",
  },
  });
  
  export const StyledSideBarInfoBox = styled(Box)({
    backgroundColor: "#F9F8F8",
    padding:"0.3rem",
  });
  export const StyledTopicSideBarInfoBox = styled(Box)({
    backgroundColor: "#F9F8F8",
    padding: "8px",
   
    "@media (max-width: 63em)": {
      padding: "0.25rem 0.8rem",
   },
   "@media (max-width: 1366)": {
    padding:"4px 8px 4px 8px",
 },
  });