import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import youTube_logo from "../../../assets/images/chats/YouTube_Logo_2017 1.svg";
import searchIcon from "../../../assets/images/chats/gallery_active.svg";
import {
  ChatSearchButton,
  CustomInput,
  SearchBox,
  SmallIcon,
} from "../../../common/chatComponents/ScreenSearchSection";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth:"31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#EAEAEA",
    color: "#000000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "26px",
    width: "26px",
    padding: "4px",
    borderRadius: "2px",
    cursor: "pointer",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const YoutubeDrawer = ({
  setYoutubeDrawerOpen,
  queryValue,
  setQueryValue,
  handleSearch,
  youtubeVideoList,
  handleSelectYoutubeVideo,
  selectedVideos,
  selectedYoutubeVideoList,
  setYoutubeVideoList,
  setSelectedYoutubeVideoList,
}) => {
  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={classes.header}
          >
            <Stack
              onClick={() => {
                setYoutubeDrawerOpen(false);
                setQueryValue("");
                setYoutubeVideoList([]);
                setSelectedYoutubeVideoList([]);
              }}
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Typography sx={classes.iconDiv}>
                <CloseIcon sx={classes.iconn} />
              </Typography>
              <Typography fontSize={"1rem"} variant="h6" fontWeight={400}>
                Youtube
              </Typography>
            </Stack>
          </Stack>

          <Stack width={"32%"} margin={"auto"} mt={2} mb={2}>
            <img src={youTube_logo} alt="google" />
          </Stack>

          <Stack width={"90%"} margin={"auto"}>
            <SearchBox>
              <CustomInput
                value={queryValue}
                onChange={(e) => setQueryValue(e.target.value)}
                placeholder="Search"
              />
              <ChatSearchButton
                onClick={handleSearch}
                size="small"
                variant="contained"
              >
                <SmallIcon src={searchIcon} />
              </ChatSearchButton>
            </SearchBox>
          </Stack>
          <>
            <Box
              width={"90%"}
              margin={"auto"}
              display={"flex"}
              flexWrap={"wrap"}
              mt={"0.7rem"}
              maxHeight={"55vh"}
              overflow={"auto"}
              height={"55vh"}
              sx={{
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "3px",
                },
              }}
            >
              {youtubeVideoList?.map((item, index) => (
                <Stack
                  key={item?.videoid}
                  width={"91%"}
                  maxWidth={"91%"}
                  margin={"auto"}
                  mb={5}
                >
                  <Box>
                    <Box>
                      <iframe
                        title={item?.title}
                        style={{ width: "100%" }}
                        allowFullScreen="allow"
                        src={`https://www.youtube.com/embed/${item?.videoid}`}
                      />
                    </Box>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box width={"82%"}>
                        <Typography
                          sx={{
                            maxWidth: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </Box>
                      <Box>
                        <FormControlLabel
                          disabled={
                            selectedVideos?.length === 1 &&
                            !selectedYoutubeVideoList.some(
                              (itemInList) =>
                                itemInList.videoid === item.videoid
                            )
                          }
                          control={
                            <Checkbox
                              sx={{
                                padding: "0rem",
                                "& .MuiSvgIcon-root": {
                                  backgroundColor: "white",
                                  padding: "0rem",
                                  color: "#183243"
                                },
                              }}
                              size="medium"
                              onChange={(event) =>
                                handleSelectYoutubeVideo(event, item)
                              }
                            />
                          }
                        />
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              ))}
            </Box>
          </>
          {youtubeVideoList?.length > 0 && (
            <>
              <Box textAlign={"center"} mt={"1.5rem"}>
                <Button onClick={handleSearch}>Show More</Button>
              </Box>
              <Box textAlign={"center"} mt={"1.5rem"} position={"relative"}>
                <Button
                  style={{
                    background: "#DB0011",
                    color: "white",
                    borderRadius: "2px",
                  }}
                  variant="contained"
                  onClick={() => setYoutubeDrawerOpen(false)}
                >
                  Done
                </Button>
                <Box
                  position={"absolute"}
                  right={"1.5rem"}
                  bottom="20%"
                  transform="translateY(-50%)"
                >
                  <Typography fontSize={"0.85rem"}>
                    {selectedVideos?.length}/1
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default YoutubeDrawer;
