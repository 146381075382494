import React, { useEffect, useRef, useState } from "react";
import { Box, Stack, Tabs, Tab, Button, Fab } from "@mui/material";
import { styled } from "@mui/system";
import Sidebar from "./Sidebar/Sidebar";
import { SmallIcon } from "../../common/chatComponents/ScreenSearchSection";
import saveIcon from "../../assets/images/explore/SaveIcon.svg";
import AllPost from "./Tabs/AllPost";
import { useDispatch, useSelector } from "react-redux";
import {
  handleBookmarkPost,
  handleFetchPostList,
  handleLikePost,
  handleLikeUpdate,
} from "../../appStore/actions/ArkchatGroup/action";
import VideoPost from "./Tabs/VideoPost";
import PhotosPost from "./Tabs/PhotosPost";
import ServicesPost from "./Tabs/ServicesPost";
import ProductsPost from "./Tabs/ProductsPost";
import {
  exploreServiceTypeNameRelation,
  serviceTypeName,
} from "../../utils/constant";
import { getServiceCategory } from "../../appStore/actions/masterData/masterDataAction";
import { useNavigate } from "react-router-dom";
import ArtPost from "./Tabs/ArtPost";
import { useTranslation } from "react-i18next";
import HelperScreen from "../../common/ToolTip/HelperScreen";
import {
  handleSavePreviewEditPostData,
  handleSetserviceTypePost,
} from "../../appStore/actions/AppState/action";
import { showToastMsg } from "../../appStore/actions/global/action";
import AddIcon from "@mui/icons-material/Add";
import {
  createStyledIndicatorButton,
  FilterIcon,
} from "../../common/StyledComponents/SideBarComponents";
import Filtericon from "../../assets/images/task/Filter_icon.svg";

export const ActionButton = styled(Button)({
  backgroundColor: "#DB0011",
  padding: "4px",
  color: "#fff",
  width: "30px",
  height: "30px",
  minWidth: "unset",
  minHeight: "unset",
  borderRadius: "0.15rem",
  "&:hover": {
    backgroundColor: "#DB0011",
    color: "#fff",
  },
});

const TabPanel = ({ children, value, index }) => (
  <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>
);
const StyledSubHeadingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#F3F3F3",
  marginBottom: "0.5rem",
});
const classes = {
  chatBox1: {
    minWidth: "27%",
    maxWidth: "27%",
    backgroundColor: "#F9F8F8",
    height: "calc(100vh - 4rem)",
    "@media (max-width: 1194px)": {
      minWidth: "30%",
      maxWidth: "30%",
      height: "calc(100vh - 50rem)",
    },
  },
  Tabs: {
    minHeight: "38px",
    width: "100%",
    "& .MuiTabs-flexContainer": {
      gap: "6%",

      paddingLeft: "0.8rem",
    },
  },
  tabs: {
    color: "black",
    padding: "0px 0px",
    textTransform: "none",
    minWidth: "unset",
    minHeight: "2.2rem",
    // marginRight: "13.5%",
    "&.Mui-selected": {
      color: "black",
    },
    "@media (max-width: 64em)": {
      // marginRight: "2.5rem",
    },
  },
};


const ExploreHome = () => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const exploreContainerRef = useRef(null);
  const SmallActionButton = createStyledIndicatorButton("#DB0011");
  const [exploreTabValue, setExploreTabValue] = useState(0);
  const [isBookMarkedPost, setBookMarkedPost] = useState(false);
  const [checked, setChecked] = useState([]);
  const [isScrollingToBottom, setIsScrollingToBottom] = useState(false);
  const [isSideBarOpen, setSideBarOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const userData = useSelector((state) => state?.profile?.profileData?.data);
  const exploreDataList = useSelector(
    (state) => state?.arkchatgroup?.exploreDataList
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleViewChange = (event, newValue) => {
    localStorage.removeItem("FilterSwitch");
    localStorage.removeItem("PostType");
    setExploreTabValue(newValue);
  };
  const handleLikeClick = (post_id) => {
    dispatch(handleLikePost(post_id)).then((res) => {
      if (res?.data?.status) {
        dispatch(handleLikeUpdate("", post_id));
      } else {
        dispatch(showToastMsg("Something went wrong !"));
      }
    });
  };
  const handleBookmarkClick = (post_id) => {
    dispatch(handleBookmarkPost(post_id)).then((res) => {
      if (res?.data?.status) {
        dispatch(handleFetchPostList({}));
      } else {
        dispatch(showToastMsg("Something went wrong !"));
      }
    });
  };
  const handleEditClick = (data) => {
    dispatch(handleSetserviceTypePost(data?.type));
    dispatch(getServiceCategory(data?.type));
    const createPostData = {
      category: data?.category,
      core_team: {},
      subscriber_id: data?.subscriber_id,
      tags: data?.tags || [],
      topic_id: 0,
      type: data?.type,
      countries: data?.countries || [],
      post_data: data?.postData,
      tagsInput: "",
    };
    const countriesArray = data?.countries?.map((name) => ({ name }));

    const localPreviewData = {
      selectedCategory: data?.category,
      createPostData: createPostData,
      selectedCountries: countriesArray,
      selectedImageList: data?.attachments,
      selectedVideoList: data?.attachments,
      post_id: data?.post_id,
      authorized_seller: data?.authorizedSeller,
      editBrandData: data?.brandInfo,
      editArtistData: data?.artistInfo,
    };
    dispatch(handleSavePreviewEditPostData(localPreviewData));
    localStorage.setItem("post_id", JSON.stringify(data?.id));
    let tabValue;
    if (
      data?.type === serviceTypeName?.Service ||
      data?.type === serviceTypeName?.Art ||
      data?.type === serviceTypeName?.Product
    ) {
      tabValue = 0;
    } else if (data?.type === serviceTypeName?.Photo) {
      tabValue = 1;
    } else if (data?.type === serviceTypeName?.Videos) {
      tabValue = 2;
    }
    localStorage?.setItem("tabValue", tabValue);
    navigate("/post/post-create");
  };
  const handleFilter = (type) => {
    localStorage.setItem("FilterSwitch", true);
    localStorage.setItem("PostType", type);
    setLoading(true);
    dispatch(
      handleFetchPostList({
        page: 1,
        type: type, // Assuming you're passing this as the 'type'
        category: checked?.join(","),
      })
    ).then((res) => {
      setLoading(false);
    });
    setExploreTabValue(exploreServiceTypeNameRelation[type]);
  };

  const handlePagination = (page = 1) => {   
    dispatch(
      handleFetchPostList({
        page: page,
        type: exploreServiceTypeNameRelation[exploreTabValue],
        category: checked?.join(","),
        fetchOld:true
      })
    );
  };

  const clearFilter = () => {
    if (localStorage.getItem("PostType")) {
      setChecked([]);
      dispatch(
        handleFetchPostList({
          type: localStorage.getItem("PostType"),
        })
      );
    }
    localStorage.removeItem("FilterSwitch");
    localStorage.removeItem("PostType");
  };

  const handleGetBookMark = () => {
    setLoading(true);
    dispatch(
      handleFetchPostList({
        bookmark:true,
      })
    ).then((res) => {
      setLoading(false);
    });
  };
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      exploreContainerRef.current;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 501;
    setIsScrollingToBottom(isAtBottom);
  };
  useEffect(() => {
    if (isScrollingToBottom&&exploreDataList?.posts?.length<exploreDataList?.count) {
      const page = Math.floor(exploreDataList?.posts?.length / 50) + 1;
      handlePagination(page);
    }
  }, [isScrollingToBottom]);
  return (
    <>
      <Box>
        {!userData?.tooltipExploreScreenWeb && (
          <HelperScreen
            updateKey={"tooltip_explore_screen_web"}
            content={[appContent?.exploreContent1, appContent?.exploreContent2]}
          />
        )}
        <Stack direction="row" spacing={1}>
          {isSideBarOpen && (
            <Box sx={classes.chatBox1}>
              <Sidebar
                checked={checked}
                setChecked={setChecked}
                setSideBarOpen={setSideBarOpen}
                isSideBarOpen={isSideBarOpen}
                handleFilter={handleFilter}
                clearFilter={clearFilter}
              />
            </Box>
          )}

          <Box flex={5.2} sx={classes.chatBox3}>
            <Box>
              <StyledSubHeadingBox>
                <Box width={"100%"} display={"flex"} alignItems={"center"}>
                  {!isSideBarOpen && (
                    <Box display="flex" alignItems="center" gap={"0.5rem"}>
                      <SmallActionButton
                        onClick={() => setSideBarOpen(!isSideBarOpen)}
                        size="small"
                        variant="contained"
                      >
                        <FilterIcon src={Filtericon} />
                      </SmallActionButton>
                    </Box>
                  )}
                  <Tabs
                    value={exploreTabValue}
                    onChange={handleViewChange}
                    TabIndicatorProps={{
                      style: { backgroundColor: "#183243" },
                    }}
                    sx={classes.Tabs}
                  >
                    <Tab
                      disableRipple
                      sx={classes.tabs}
                      // label={subHeaderLabels?.chats}
                      label="All"
                    />
                    <Tab
                      disableRipple
                      sx={classes.tabs}
                      // label={subHeaderLabels?.ExploreHomes}
                      label="Videos"
                    />
                    <Tab
                      disableRipple
                      sx={classes.tabs}
                      // label={subHeaderLabels?.approvals}
                      label="Photos"
                    />
                    <Tab
                      disableRipple
                      sx={classes.tabs}
                      // label={subHeaderLabels?.sellers}
                      label="Service"
                    />
                    <Tab
                      disableRipple
                      sx={classes.tabs}
                      // label={subHeaderLabels?.sellers}
                      label="Products"
                    />
                    <Tab
                      disableRipple
                      sx={classes.tabs}
                      // label={subHeaderLabels?.sellers}
                      label="Art"
                    />
                  </Tabs>

                  <Box>
                    <ActionButton
                      onClick={handleGetBookMark}
                      size="small"
                      variant="contained"
                    >
                      <SmallIcon src={saveIcon} />
                    </ActionButton>
                  </Box>
                </Box>

                {/* <Box mr={"0.25rem"}>
                  <Button sx={{ textTransform: "none", color: "#000000" }}>
                    <Box>
                      <Typography>Messages</Typography>
                    </Box>
                  </Button>
                </Box> */}
              </StyledSubHeadingBox>
              <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                paddingX={"20px"}
                marginTop={"0.8rem"}
              >
                {/* <SearchBox sx={{ width: "100%" }}>
                  <CustomInput
                    type="text"
                    placeholder="Explore & Post products, service, videos and photos."
                  />
                  <Box>
                    <ChatSearchButton size="small" variant="contained">
                      <SmallIcon src={searchIcon} />
                    </ChatSearchButton>
                  </Box>
                </SearchBox> */}
              </Stack>
              <Box
                ref={exploreContainerRef}
                onScroll={handleScroll}
                bgcolor={"#f1f1f1"}
                mt={"1.2rem"}
                sx={{
                  maxHeight: "75vh",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "0px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888",
                    borderRadius: "3px",
                  },
                }}
              >
                <TabPanel value={exploreTabValue} index={0}>
                  <AllPost
                    handleLikeClick={handleLikeClick}
                    handleBookmarkClick={handleBookmarkClick}
                    isBookMarkedPost={isBookMarkedPost}
                    handleEditClick={handleEditClick}
                  />
                </TabPanel>
                <TabPanel value={exploreTabValue} index={1}>
                  <VideoPost
                    handleLikeClick={handleLikeClick}
                    handleBookmarkClick={handleBookmarkClick}
                    isBookMarkedPost={isBookMarkedPost}
                    filterSwitchLoading={isLoading}
                    handleEditClick={handleEditClick}
                  />
                </TabPanel>
                <TabPanel value={exploreTabValue} index={2}>
                  <PhotosPost
                    handleLikeClick={handleLikeClick}
                    handleBookmarkClick={handleBookmarkClick}
                    isBookMarkedPost={isBookMarkedPost}
                    filterSwitchLoading={isLoading}
                    handleEditClick={handleEditClick}
                  />
                </TabPanel>
                <TabPanel value={exploreTabValue} index={3}>
                  <ServicesPost
                    handleLikeClick={handleLikeClick}
                    handleBookmarkClick={handleBookmarkClick}
                    isBookMarkedPost={isBookMarkedPost}
                    filterSwitchLoading={isLoading}
                    handleEditClick={handleEditClick}
                  />
                </TabPanel>
                <TabPanel value={exploreTabValue} index={4}>
                  <ProductsPost
                    handleLikeClick={handleLikeClick}
                    handleBookmarkClick={handleBookmarkClick}
                    isBookMarkedPost={isBookMarkedPost}
                    filterSwitchLoading={isLoading}
                    handleEditClick={handleEditClick}
                  />
                </TabPanel>
                <TabPanel value={exploreTabValue} index={5}>
                  <ArtPost
                    handleLikeClick={handleLikeClick}
                    handleBookmarkClick={handleBookmarkClick}
                    isBookMarkedPost={isBookMarkedPost}
                    filterSwitchLoading={isLoading}
                    handleEditClick={handleEditClick}
                  />
                </TabPanel>
                <Box
                  zIndex={1000}
                  position={"fixed"}
                  bottom={"1rem"}
                  right={"2.4rem"}
                >
                  <Fab
                    onClick={() => navigate("/post")}
                    sx={{
                      bgcolor: "#DB0011",
                      "&:hover": {
                        backgroundColor: "#DB0011",
                      },
                    }}
                    color="primary"
                  >
                    <AddIcon />
                  </Fab>
                </Box>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ExploreHome;
