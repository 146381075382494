import React from "react";
import CreateNewPassword from "../auth/CreateNewPassword";

const ResetPassword = ({setResetPasswordDrawerOpen}) => {
  return (
    <>
      <CreateNewPassword isResetPassword={true} setResetPasswordDrawerOpen={setResetPasswordDrawerOpen}/>
    </>
  );
};

export default ResetPassword;
