import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import createGroup from "../../assets/images/chats/createGroup.svg";
import Button from "@mui/material/Button";
import { CircularProgress, InputBase, Typography, styled } from "@mui/material";

import { useTranslation } from "react-i18next";
import { requestCreatFirstGroup } from "../../appStore/actions/ArkchatGroup/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getProfile,
  profileUpdate,
} from "../../appStore/actions/profile/action";
import { REQUEST_FAILED } from "../../appStore/actions/global/type";
import HelperScreen from "../../common/ToolTip/HelperScreen";
import FirstGroupToolTip from "../../common/ToolTip/FirstGroupToolTip";
import ExternalAccountInviteTooltip from "../../common/ToolTip/ExternalAccountInviteTooltip";
import { showToastMsg } from "../../appStore/actions/global/action";
const classes = {
  inputField: {
    borderBottom: "1px solid grey",
    fontSize: "0.875rem",
    width: "100%",
    textAlign: "center",
    "& input": {
      textAlign: "center",
    },
  },
  image: {
    width: "100%",
  },
  headerBox: {
    display: "flex",
    width: "100%",
    padding: "0.2rem 0rem",
    justifyContent: "center",
    backgroundColor: "#F3F3F3 !important",
  },
};

export const InviteButton = styled(Button)({
  backgroundColor: "#DB0011",
  borderColor: "#DB0011",
  color: "#fff",
  padding: "0.31rem 3.4rem",
  borderRadius: "0",
  display: "block",
  margin: "auto",
  textTransform: "none",
  ":hover": {
    backgroundColor: "#DB0011",
    borderColor: "#DB0011",
    color: "#fff",
  },
});

const CreateFirstGroupComponent = (setType) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const userData = useSelector((state) => state?.profile);
  const [groupName, setGroupName] = useState("");
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);
  //
  const handleCreateGroup = async () => {
    //group_name, topic_name, subscriber_id, city, country, country_code, description, is_topic_on
    const reqBody = {
      group_name: groupName,
      subscriber_id: userData?.profileData?.data?.activeSubscriber?.id,
      topic_name: "General",
      description: "",
      city: userData?.profileData?.data?.city,
      country: userData?.profileData?.data?.country,
      country_code: userData?.profileData?.data?.countryCode,
      is_topic_on: true,
    };
    setLoading(true);
    //
    dispatch(requestCreatFirstGroup(reqBody)).then((res) => {
      setLoading(false);
      if (res?.status) {
        // Updating is_first_group_created key to keep track.
        dispatch(profileUpdate({ is_first_group_created: true }, false))
          .then((res) => {
            navigate("/invite");
          })
          .catch((err) => {
            dispatch(showToastMsg(appContent?.wrongError))
            dispatch({ type: REQUEST_FAILED, payload: err?.response });
          });
      }else{
        dispatch(showToastMsg(appContent?.wrongError))
      }
    });
  };

  return (
    <>
      {userData?.profileData &&
        !userData?.profileData?.data?.tooltipWelcomeJourneyCompletedWeb &&
        userData?.profileData?.data?.tooltipFirstInviteScreenWeb === 0 &&
        !userData?.profileData?.data?.tooltipFirstGroupScreenWeb && (
          <FirstGroupToolTip
            updateKey={"tooltip_first_group_screen_web"}
            content={[]}
          />
        )}

      {userData?.profileData &&
        !userData?.profileData?.data?.tooltipWelcomeJourneyCompletedWeb &&
        userData?.profileData?.data?.tooltipFirstInviteScreenWeb === 2 && (
          <ExternalAccountInviteTooltip />
        )}
      <Box sx={{ height: "89vh" }} position={"relative"}>
        <Box mb={4} sx={classes?.headerBox}>
          <Typography fontSize={"1rem"}>Get Started</Typography>
        </Box>

        <Box width={"21%"} margin={"auto"}>
          <img
            style={{ width: "290px", height: "180px" }}
            src={createGroup}
            alt="createGroup"
          />
        </Box>
        <Box mt={2}>
          <Typography
            fontSize={"0.875rem"}
            align="center"
            variant="subtitle1"
            gutterBottom
          >
            {appContent?.createGroup}
          </Typography>

          <Box width={"21%"} margin={"auto"} mt={"1.5rem"}>
            <InputBase
              sx={classes.inputField}
              placeholder={appContent?.groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
          </Box>

          <Box mt={"2rem"} textAlign="center">
            <Typography variant="body2">{appContent?.chatWithTeam}</Typography>
            <Typography variant="body2">{appContent?.chatUsingAi}</Typography>
          </Box>
          <Box mt={"4rem"} position={"absolute"} bottom={"1rem"} width={"100%"}>
            <InviteButton
              sx={{
                padding: "0.5rem 2rem",
                borderRadius: "0.12rem",
              }}
              onClick={handleCreateGroup}
              variant="outlined"
              disabled={groupName ? false : true}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <Typography color={groupName ? "#ffff" : "#e1e1e1ad"}>
                  {" "}
                  {appContent?.next}
                </Typography>
              )}
            </InviteButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateFirstGroupComponent;
