import React from "react";
import { Box, Button, Modal, TextareaAutosize } from "@mui/material";
import { CloseButton, CloseIcon } from "../../Profille/CustomDrawer";
import closeLogo from "../../../assets/images/chats/closeLogo.svg";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.30)",
  borderRadius: "4px",
};

const AiEditAnswer = ({
  isEditModalOpen,
  setEditModalOpen,
  editAnswerData,
  handelAnswerChange,
  handleEditDone
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };
  return (
    <div>
      <Modal open={isEditModalOpen} onClose={handleCloseEditModal}>
        <Box sx={{ ...style, width: "700px" }}>
          <Box
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            gap={"0.4rem"}
            bgcolor={"rgba(234, 234, 234, 1)"}
            padding={"0.5rem"}
          >
            <Box>
              <CloseButton
                onClick={handleCloseEditModal}
                size="small"
                variant="contained"
              >
                <CloseIcon src={closeLogo} alt="close" />
              </CloseButton>
            </Box>
          </Box>
          <Box>
            <Box padding={"0px 5px"}>
              <TextareaAutosize
                onChange={handelAnswerChange}
                style={{
                  width: "100%",
                  resize: "vertical",
                  border: "none",
                  outline: "none",
                  lineHeight: "1.25rem",
                  padding: "5px 8px",
                  fontFamily: "Poppins",
                  fontSize: "0.87rem",
                  maxHeight: "62vh",
                  minHeight: "1.1rem",
                  overflow: "auto",
                  // marginBottom: "3rem",
                }}
                minRows={"6"}
                variant="outlined"
                fullWidth
                value={editAnswerData?.answer || ""}
              />
            </Box>

            <Box textAlign={"center"} mb={"1.5rem"} mt={"1.5rem"}>
              <Button
              onClick={handleEditDone}
                size="small"
                variant="contained"
                sx={{
                  textTransform: "none",
                  borderRadius: "0.15rem",
                  backgroundColor: "#DB0011",
                  padding: "4px",
                  color: "#fff",
                  height: "30px",
                  minHeight: "unset",
                  "&:hover": {
                    backgroundColor: "#DB0011",
                    color: "#fff",
                  },
                }}
              >
                {appContent?.done}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AiEditAnswer;
