import { accountType } from "./constant";

export const groupExternalMember = (
  external,
  isRecipientList = false,
  individual
) => {
  let groupedData = [];
  const groupedDataObj = {};
  const indvidualGroupedDataObj = {};

  if(external?.length===0){
    return [];
  }

  if (isRecipientList) {
    external?.forEach((item) => {
      if (item?.subscriberPriority === accountType?.Organisation) {
        if (!groupedDataObj[item?.subscriberFirmName]) {
          groupedDataObj[item?.subscriberFirmName] = [item];
        } else {
          groupedDataObj[item?.subscriberFirmName] = [
            ...groupedDataObj[item?.subscriberFirmName],
            item,
          ];
        }
      } else {
        if (!indvidualGroupedDataObj[individual]) {
          indvidualGroupedDataObj[individual] = [item];
        } else {
          indvidualGroupedDataObj[individual] = [
            ...indvidualGroupedDataObj[individual],
            item,
          ];
        }
      }
    });

    if (
      Object.keys(groupedDataObj).length > 0 &&
      Object.keys(indvidualGroupedDataObj).length > 0
    ) {
      for (let companyKey in groupedDataObj) {
        groupedData.push({ [companyKey]: groupedDataObj[companyKey] });
      }
      groupedData.push(indvidualGroupedDataObj);
    } else if (
      Object.keys(groupedDataObj).length > 0 &&
      !Object.keys(indvidualGroupedDataObj).length
    ) {
      for (let companyKey in groupedDataObj) {
        groupedData.push({ [companyKey]: groupedDataObj[companyKey] });
      }
    } else if (
      Object.keys(indvidualGroupedDataObj).length > 0 &&
      !Object.keys(groupedDataObj).length
    ) {
      groupedData.push(indvidualGroupedDataObj);
    }

    return groupedData;
  } else {
    external?.forEach((item) => {
      if (item?.userData?.subscriberPriority === accountType?.Organisation) {
        if (!groupedDataObj[item?.userData?.subscriberFirmName]) {
          groupedDataObj[item?.userData?.subscriberFirmName] = [item];
        } else {
          groupedDataObj[item?.userData?.subscriberFirmName] = [
            ...groupedDataObj[item?.userData?.subscriberFirmName],
            item,
          ];
        }
      } else {
        if (!indvidualGroupedDataObj[individual]) {
          indvidualGroupedDataObj[individual] = [item];
        } else {
          indvidualGroupedDataObj[individual] = [
            ...indvidualGroupedDataObj[individual],
            item,
          ];
        }
      }
    });

    if (
      Object.keys(groupedDataObj).length > 0 &&
      Object.keys(indvidualGroupedDataObj).length > 0
    ) {
      for (let companyKey in groupedDataObj) {
        groupedData.push({ [companyKey]: groupedDataObj[companyKey] });
      }
      groupedData.push(indvidualGroupedDataObj);
    } else if (
      Object.keys(groupedDataObj).length > 0 &&
      !Object.keys(indvidualGroupedDataObj).length
    ) {
      for (let companyKey in groupedDataObj) {
        groupedData.push({ [companyKey]: groupedDataObj[companyKey] });
      }
    } else if (
      Object.keys(indvidualGroupedDataObj).length > 0 &&
      !Object.keys(groupedDataObj).length
    ) {
      groupedData.push(indvidualGroupedDataObj);
    }

    return groupedData;
  }
};
