import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "1rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  Tabs_01: {
    minHeight: "38px",
    width: "100%",
    "& .MuiTab-root.Mui-selected": {
      background: "#183243",
      color: "#fff",
    },
  },

  tabs: {
    marginRight: "0.4rem",
    color: "#183243",
    textTransform: "none",
    minWidth: "80px",
    minHeight: "1.2rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "1.2rem",
    lineHeight: "normal",
    background: "#fff",
    borderRadius: "3px",
    border: "1px solid #000000",

    img: {
      margin: "0 !important",
    },
    "&.Mui-selected": {},
    "@media (max-width: 64em)": {},
  },
};
const CheckoutForm = ({ setCheckoutDrawerOpen }) => {
  const paymentIntentData = useSelector(
    (state) => state?.subscription?.paymentIntentData
  );
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    if (!stripe || !elements) {
      return;
    }

    try {
      setLoading(true);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment-status`,
        },
      });

      if (error) {
        setErrorMessage(error.message);
      } else {
        // Payment confirmation successful
      }
    } catch (error) {
      // Handle other errors (e.g., network issues)
      setErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={classes.drawerBody}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => setCheckoutDrawerOpen(false)}
          />
        </Typography>
        <Typography color={"black"}>Pricing</Typography>
      </Stack>
      <Box width={"90%"} margin={"auto"}>
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          {/* Show error message to your customers */}
          {errorMessage && (
            <Box textAlign={"center"} mt={"1.5rem"}>
              <Typography fontSize={"0.87rem"} color={"red"} variant="h6">
                {errorMessage}
              </Typography>
            </Box>
          )}

          <Box textAlign={"center"} mt={"4rem"}>
            <Button
              type="submit"
              sx={{
                backgroundColor: "#DB0011",
                color: "#fff",
                ":hover": {
                  backgroundColor: "#DB0011",
                  color: "#fff",
                },
              }}
              disabled={!stripe || loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                `${paymentIntentData?.symbol} ${paymentIntentData?.total}`
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default CheckoutForm;
