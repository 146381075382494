import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Link,
  Drawer,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Image from "./Image";
import { Stack } from "@mui/material";
import likepost from "../../../../assets/images/postcard/LikePost.svg";
import sharepost from "../../../../assets/images/postcard/SharePost.svg";
import savepost from "../../../../assets/images/postcard/SavePost.svg";
import twitterbadge from "../../../../assets/images/postcard/Twitter_Verified_Badge 1.svg";
import linkedin from "../../../../assets/images/postcard/Linkedin.svg";
import { createStyledIndicatorButton } from "../../../../common/StyledComponents/SideBarComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  AccountLevelArkchatPermission,
  sellerTypeValue,
} from "../../../../utils/constant";
import moment from "moment";
import ReactPlayer from "react-player";
import likedLogo from "../../../../assets/images/explore/likedLogo.svg";
import bookmarkedLogo from "../../../../assets/images/explore/bookmarkedLogo.svg";
import editIcon from "../../../../assets/images/editTopic.svg";
import { SmallIcon } from "../../../../common/chatComponents/ScreenSearchSection";
import {
  handleViewLikes,
  sellerCollaborate,
  sellerConnect,
} from "../../../../appStore/actions/ArkchatGroup/action";
import ProfileViewModal from "../../../../common/ProfileViewModal";
import ViewLikeDrawer from "./ViewLikeDrawer";
import AlertPopUp from "../../../../common/AlertPopUp";
import { showToastMsg } from "../../../../appStore/actions/global/action";
import { useTranslation } from "react-i18next";
const SmallActionButton = createStyledIndicatorButton("#E3E1E1");
const clasess = {
  postAction: {
    position: "absolute",
    width: "100%",
    top: "-1.5rem",
    zIndex: "999",

    img: {
      cursor: "pointer",
    },
  },
  abcd: {
    marginLeft: "30px !important",
  },
};

const PostCard = ({
  postPreviewData,
  type,
  handleSelecteCoreTeam,
  handlePublish,
  setActiveStep,
  handleDeletePostById,
  activeStep,
  handleEditClick,
  handleDeleteClick,
  showBtn = true,
  handleBookmarkClick,
  handleLikeClick,
  previev = true,
  sellerTab,
  showConnect = false,
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [expanded, setExpanded] = useState(false);
  const [isProfileViewOpen, setProfileViewModal] = useState(false);
  const [profileViewData, setProfileViewData] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isViewLikeDarwerOpen, setViewLikeDarwerOpen] = useState(false);
  const accountLevelPeople = useSelector(
    (state) => state?.arkchatgroup?.accountLevelPeople
  );
  const userProfileData = useSelector((state) => state?.profile?.profileData);
  const [profileCoreMember, setProfileCoreMember] = useState(
      Object.keys(userProfileData?.data?.activeSubscriber?.coreTeam || {})
        .length === 0
      ? {
          id: userProfileData?.data?.id,
          pp: userProfileData?.data?.profileImgUrl,
          name: `${userProfileData?.data?.firstName} ${userProfileData?.data?.lastName}`,
          target_subscriber_id: userProfileData?.data?.activeSubscriber?.id,
        }
      : userProfileData?.data?.activeSubscriber?.coreTeam
  );
  const dispatch = useDispatch();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const NameBadge = createStyledIndicatorButton("#E3E1E1");
  const handleProfileBadgeClick = (
    data,
    isGroup,
    isBuyerSellerGroup = false
  ) => {
    const viewData = {
      companyName: "",
      userName: data?.subscriberInfo?.subscriberFirmName,
      indusrtyName: data?.subscriberInfo?.subscriberAccountIndustry,
      cityName: data?.subscriberInfo?.city,
      countryName: data?.subscriberInfo?.country,
      profileBrief: data?.subscriberInfo?.profileBrief,
      profileImgUrl: data?.subscriberInfo?.profileImgUrl,
      coverImgUrl: data?.subscriberInfo?.coverImgUrl,
      isGroup: isGroup,
      isBuyerSellerGroup: isBuyerSellerGroup,
      website: data?.subscriberInfo?.website,
      linkedin: data?.subscriberInfo?.linkedin,
    };
    setProfileViewData(viewData);
    setProfileViewModal(true);
  };
  const handleViewLike = (id) => {
    dispatch(handleViewLikes(postPreviewData?.id)).then((res) => {
      setViewLikeDarwerOpen(true);
    });
  };
  const handleClickCollab = (post_id) => {
    dispatch(sellerCollaborate(post_id)).then((res) => {
      if (res?.status) {
        setPopupOpen(true);
      } else {
        dispatch(showToastMsg("Something went wrong !"));
      }
    });
  };
  return (
    <>
      <ProfileViewModal
        setProfileViewModal={setProfileViewModal}
        isProfileViewOpen={isProfileViewOpen}
        profileViewData={profileViewData}
      />
      <AlertPopUp
        title={"Collaborate"}
        bodyContent={
          "Thank you for your interest in this seller. We have created a Buyer/Seller Group. You can collaborate with the seller in this group."
        }
        open={isPopupOpen}
        closeModal={setPopupOpen}
      />
      {postPreviewData?.post && previev ? (
        <Box>
          <Card sx={{ maxWidth: 455, margin: "auto" }}>
            {type === "Videos" ? (
              <Box height={"21.8rem"}>
                <ReactPlayer
                  width="100%"
                  height="95%"
                  url={`${postPreviewData?.post?.attachments[0]?.location}`}
                  controls={true}
                  volume={null}
                  muted={true}
                  config={{
                    youtube: {
                      playerVars: {
                        controls: 1,
                        mute: 1,
                        showinfo: 0,
                      },
                    },
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}
                />
              </Box>
            ) : (
              <Image
                images={
                  postPreviewData?.post?.attachments?.length > 0 &&
                  postPreviewData?.post?.attachments
                }
              />
            )}

            <Box position={"relative"}>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent={"space-between"}
                sx={clasess.postAction}
                bgcolor={"#F3F3F3"}
                padding={"0.2rem 0.4rem"}
              >
                <Stack alignItems="center" direction="row" spacing={2}>
                  <Box>
                    <img src={likepost} alt="like_icon" />
                  </Box>
                  {/* <Box>
                    <img src={sharepost} alt="share_icon" className="ml-30" />
                  </Box> */}
                  {/* <Box>
                    <img src={emailpost} alt="email_icon" />
                  </Box> */}
                  {/* <Box>
                    {" "}
                    <img src={actionpost} alt="action_icon" />
                  </Box> */}
                </Stack>
                <Stack>
                  <img src={savepost} alt="action_icon" />
                </Stack>
              </Stack>
            </Box>
            <CardContent>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  <Typography variant="h6" fontWeight={500} fontSize={"14px"}>
                    {type}
                  </Typography>
                </Box>
                {type === "Product" && (
                  <Box>
                    <Typography variant="h6" fontWeight={500} fontSize={"14px"}>
                      {postPreviewData?.userDetails?.activeSubscriber
                        ?.manufacturer
                        ? "Manufacturer"
                        : sellerTypeValue[
                            postPreviewData?.userDetails?.activeSubscriber
                              ?.sellerType
                          ]}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box>
                <Typography variant="h6" fontWeight={400} fontSize={"14px"}>
                  {postPreviewData?.post?.category?.name}
                </Typography>
              </Box>
              {type === "Product" && (
                <Box>
                  <Typography variant="h6" fontWeight={400} fontSize={"14px"}>
                    Brand : {postPreviewData?.post?.brandInfo?.name} |{" "}
                    {postPreviewData?.post?.brandInfo?.website}
                  </Typography>
                </Box>
              )}

              <Box padding={"10px 0"}>
                <Box>
                  <Typography fontSize={"12px"} fontWeight={600} variant="h6">
                    {postPreviewData?.post?.postData?.title}
                  </Typography>
                </Box>
                <span>
                  <Typography variant="body2">
                    {!expanded &&
                      postPreviewData?.post?.postData?.description?.slice(
                        0,
                        100
                      ) + " ..."}{" "}
                    <>
                      {postPreviewData?.post?.postData?.description?.length >
                        100 &&
                        !expanded && (
                          <span
                            onClick={handleExpandClick}
                            style={{
                              fontSize: "0.875rem",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {expanded ? "...less" : "more"}
                          </span>
                        )}
                    </>
                  </Typography>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Typography variant="body2">
                      {postPreviewData?.post?.postData?.description}
                      <span
                        onClick={handleExpandClick}
                        style={{
                          fontSize: "0.875rem",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        {expanded ? "...less" : "more"}
                      </span>
                    </Typography>
                  </Collapse>
                </span>
              </Box>
              {/* <Typography fontWeight={600} fontSize={"14px"} paddingBottom={"10px"}>
            {" "}
            Illustrations for social media post
          </Typography> */}

              <Box>
                <Box display="flex" alignItems="flex-start" gap={"0.5rem"}>
                  <SmallActionButton
                    size="small"
                    variant="contained"
                    sx={{ mr: "4px" }}
                  >
                    <>
                      {postPreviewData?.userDetails?.activeSubscriber
                        ?.profileImgUrl ? (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={
                            postPreviewData?.userDetails?.activeSubscriber
                              ?.profileImgUrl
                          }
                          alt=""
                        />
                      ) : (
                        <Typography
                          variant="h6"
                          fontSize={"0.75rem"}
                          color={"#183243"}
                        >
                          {postPreviewData?.userDetails?.activeSubscriber
                            ?.subscriberFirmName &&
                            postPreviewData?.userDetails?.activeSubscriber
                              ?.subscriberFirmName[0]}
                        </Typography>
                      )}
                    </>
                  </SmallActionButton>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <Stack direction={"column"} spacing={"0.2rem"}>
                      <Box maxWidth={"95%"}>
                        <Typography
                          fontSize="14px"
                          fontWeight="600 !important"
                          color={"black"}
                          variant="h6"
                          width={"min-content"}
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Stack direction={"row"} gap={"5px"}>
                            {
                              postPreviewData?.userDetails?.activeSubscriber
                                ?.subscriberFirmName
                            }
                            <img
                              src={twitterbadge}
                              title="Twitter Status"
                              alt=""
                            />
                          </Stack>
                        </Typography>
                      </Box>
                      <Box maxWidth={"95%"}>
                        <Typography
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          fontSize="14px"
                          fontWeight="400"
                          color={"black"}
                          variant="h6"
                          lineHeight={1}
                        >
                          {
                            postPreviewData?.userDetails?.activeSubscriber
                              ?.subscriberAccountIndustry
                          }
                        </Typography>
                      </Box>

                      <Box maxWidth={"95%"}>
                        <Typography
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          fontSize="14px"
                          fontWeight="400"
                          color={"black"}
                          variant="h6"
                        >
                          {postPreviewData?.userDetails?.activeSubscriber?.city}
                          ,{" "}
                          {
                            postPreviewData?.userDetails?.activeSubscriber
                              ?.countryCode
                          }
                        </Typography>
                      </Box>

                      <Typography
                        sx={{ textDecoration: "underline" }}
                        fontSize={"14px"}
                        display={"inline"}
                      >
                        {
                          postPreviewData?.userDetails?.activeSubscriber
                            ?.website
                        }
                      </Typography>
                    </Stack>
                    <Box>
                      <img src={linkedin} title="Linkedin Status" alt="" />
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </CardContent>
            {showBtn && (
              <Box
                sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
                mb={"0.5rem"}
              >
                <Box>
                  <Button
                    onClick={() => {
                      setActiveStep(1);
                      localStorage.setItem(
                        "post_id",
                        JSON.stringify(postPreviewData?.post?.id)
                      );
                      localStorage.setItem("isEditPost", JSON.stringify(true));
                    }}
                    variant="outlined"
                    sx={{
                      borderRadius: "2px",
                      color: "#000000",
                      border: "1px solid #000000",
                      ":hover": {
                        color: "#000000",
                        border: "1px solid #000000",
                      },
                      textTransform: "none",
                    }}
                  >
                    Edit
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      localStorage.setItem(
                        "post_id",
                        JSON.stringify(postPreviewData?.post?.id)
                      );
                      handleDeletePostById();
                    }}
                    variant="outlined"
                    sx={{
                      borderRadius: "2px",
                      color: "#000000",
                      border: "1px solid #000000",
                      ":hover": {
                        color: "#000000",
                        border: "1px solid #000000",
                      },
                      textTransform: "none",
                    }}
                  >
                    Delete
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={!isLoading ? handlePublish : null}
                    variant="outlined"
                    sx={{
                      borderRadius: "2px",
                      border: "1px solid #DB0011",
                      backgroundColor: "#DB0011",
                      color: "white",
                      ":hover": {
                        border: "1px solid #DB0011",
                        backgroundColor: "#DB0011",
                        color: "white",
                      },
                      textTransform: "none",
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      appContent?.publish
                    )}
                  </Button>
                </Box>
              </Box>
            )}
          </Card>

          <Box
            position={"absolute"}
            top={"2rem"}
            right={0}
            height={"80vh"}
            maxHeight={"80vh"}
            padding={"0rem 1.5rem"}
            boxShadow={"0px 0px 2px rgba(0, 0, 0, 0.25)"}
          >
            {/* Core Team BOX */}
            <Box mb={"0.4rem"} mt={"0.4rem"}>
              <Typography>Who will receive messages</Typography>
            </Box>
            {accountLevelPeople?.internalMember?.length > 0 &&
              accountLevelPeople?.internalMember
                ?.filter(
                  (item) =>
                    item.inviteType !== 2 &&
                    (item?.permission ===
                      AccountLevelArkchatPermission?.ADMIN ||
                      item?.permission ===
                        AccountLevelArkchatPermission?.MANAGER)
                )
                .map((userData) => (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    mt={""}
                    key={userData?.userId + "PostCard"}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 30,
                              color: "#183243",
                            },
                          }}
                          onChange={(e) =>
                            handleSelecteCoreTeam(
                              e,
                              userData,
                              setProfileCoreMember,
                              profileCoreMember
                            )
                          }
                          checked={profileCoreMember?.id === userData?.userId}
                          icon={
                            <NameBadge
                              sx={{ color: "#183243" }}
                              size="small"
                              variant="contained"
                            >
                              <Typography color={"#183243"}>
                                {userData?.userInfo?.firstName &&
                                  userData?.userInfo?.firstName[0]}
                              </Typography>
                            </NameBadge>
                          }
                        />
                      }
                      label={`${userData?.userInfo?.firstName} ${userData?.userInfo?.lastName}`}
                    />
                  </Stack>
                ))}
          </Box>
        </Box>
      ) : (
        <>
          {/* Manage Post */}
          <Box>
            <Card sx={{ maxWidth: 455, margin: "auto" }}>
              {type === "Videos" ? (
                <Box height={"21.8rem"}>
                  <ReactPlayer
                    width="100%"
                    height="95%"
                    url={`${postPreviewData?.attachments[0]?.location}`}
                    controls={true}
                    volume={null}
                    muted={true}
                    config={{
                      youtube: {
                        playerVars: {
                          mute: 1,
                          modestbranding: 1,
                          controls: 1,
                          disablekb: 1,
                          enablejsapi: 1,
                          iv_load_policy: 3,
                          rel: 0,
                          showinfo: 0,
                        },
                      },
                      file: {
                        attributes: {
                          controlsList: "nodownload",
                        },
                      },
                    }}
                  />
                </Box>
              ) : (
                <Box>
                  <Image
                    images={
                      postPreviewData?.attachments?.length > 0 &&
                      postPreviewData?.attachments
                    }
                  />
                </Box>
              )}

              <Box position={"relative"}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent={"space-between"}
                  sx={clasess.postAction}
                  bgcolor={"#F3F3F3"}
                  padding={"0.2rem 0.4rem"}
                >
                  <Stack alignItems="center" direction="row" spacing={2}>
                    <Box
                      onClick={
                        showBtn
                          ? null
                          : () => handleLikeClick(postPreviewData?.id)
                      }
                    >
                      {showBtn ? (
                        <img src={likepost} alt="like_icon" />
                      ) : (
                        <img
                          src={postPreviewData?.isLiked ? likedLogo : likepost}
                          alt="like_icon"
                        />
                      )}
                    </Box>
                    <Drawer
                      sx={{ zIndex: 1202 }}
                      open={isViewLikeDarwerOpen}
                      anchor={"right"}
                    >
                      <ViewLikeDrawer
                        setViewLikeDarwerOpen={setViewLikeDarwerOpen}
                      />
                    </Drawer>
                    {postPreviewData?.isLiked && (
                      <Typography
                        onClick={() => handleViewLike(postPreviewData?.id)}
                        sx={{ cursor: "pointer" }}
                        color={"#183243"}
                        fontWeight={600}
                        variant="caption"
                      >
                        View
                      </Typography>
                    )}

                    {/* <Box>
                      <img src={sharepost} alt="share_icon" className="ml-30" />
                    </Box> */}
                    {/* <Box>
                      <img src={emailpost} alt="email_icon" />
                    </Box> */}
                    {/* <Box>
                      {" "}
                      <img src={actionpost} alt="action_icon" />
                    </Box> */}
                  </Stack>
                  <Stack alignItems="center" direction="row" spacing={2}>
                    {userProfileData?.data?.id === postPreviewData?.userId && (
                      <Box
                        onClick={() => {
                          handleEditClick(postPreviewData);
                          localStorage.setItem(
                            "isEditPost",
                            JSON.stringify(true)
                          );
                        }}
                      >
                        <SmallIcon src={editIcon} />
                      </Box>
                    )}

                    <Box
                      onClick={
                        showBtn
                          ? null
                          : () => handleBookmarkClick(postPreviewData?.id)
                      }
                    >
                      <img
                        src={
                          postPreviewData?.isBookmarked
                            ? bookmarkedLogo
                            : savepost
                        }
                        alt="action_icon"
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Box>
              <CardContent>
                <Box>
                  <Box>
                    <Box>
                      <Box width={"100%"} position={"relative"}>
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          fontSize={"15px"}
                        >
                          {type}
                        </Typography>
                        <Box>
                          {" "}
                          {showConnect &&
                            userProfileData?.data?.activeSubscriber?.id !==
                              postPreviewData?.userInfo?.activeSubscriber
                                ?.id && (
                              <Box position={"absolute"} right={0} top={0}>
                                <Button
                                  variant="outlined"
                                  sx={{
                                    borderRadius: "2px",
                                    border: "1px solid #DB0011",
                                    backgroundColor: "#fff",
                                    color: "black",
                                    ":hover": {
                                      border: "1px solid #DB0011",
                                      backgroundColor: "#fff",
                                      color: "black",
                                    },
                                    textTransform: "none",
                                  }}
                                  onClick={() =>
                                    dispatch(sellerConnect(postPreviewData?.id))
                                  }
                                >
                                  Connect
                                </Button>
                              </Box>
                            )}
                          {sellerTab &&
                            userProfileData?.data?.activeSubscriber?.id !==
                              postPreviewData?.subscriberId && (
                              <Box position={"absolute"} right={0} top={0}>
                                <Button
                                  variant="outlined"
                                  sx={{
                                    borderRadius: "2px",
                                    border: "1px solid #DB0011",
                                    backgroundColor: "#fff",
                                    color: "black",
                                    ":hover": {
                                      border: "1px solid #DB0011",
                                      backgroundColor: "#fff",
                                      color: "black",
                                    },
                                    textTransform: "none",
                                  }}
                                  onClick={() =>
                                    handleClickCollab(postPreviewData?.id)
                                  }
                                >
                                  Collaborate
                                </Button>
                              </Box>
                            )}
                        </Box>
                      </Box>
                      {type === "Product" && (
                        <Box>
                          <Typography
                            variant="h6"
                            fontWeight={600}
                            fontSize={"14px"}
                          >
                            {postPreviewData?.userInfo?.activeSubscriber
                              ?.manufacturer
                              ? "Manufacturer"
                              : sellerTypeValue[
                                  postPreviewData?.userInfo?.activeSubscriber
                                    ?.sellerType
                                ]}
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <Box width={"100%"}>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={"14px"}
                      >
                        {postPreviewData?.category?.name}
                      </Typography>
                    </Box>
                    {type === "Product" && (
                      <Box>
                        <Typography
                          variant="h6"
                          fontWeight={400}
                          fontSize={"14px"}
                        >
                          Brand : {postPreviewData?.brandInfo?.name} |{" "}
                          {postPreviewData?.brandInfo?.website}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box padding={"10px 0"}>
                  <Box>
                    <Typography fontSize={"12px"} fontWeight={600} variant="h6">
                      {postPreviewData?.postData?.title}
                    </Typography>
                  </Box>
                  <span>
                    <Typography variant="body2">
                      {!expanded &&
                        postPreviewData?.postData?.description.slice(0, 100) +
                          (postPreviewData?.postData?.description.length > 100
                            ? " ..."
                            : "")}
                      <>
                        {postPreviewData?.postData?.description.length > 100 &&
                          !expanded && (
                            <span
                              onClick={handleExpandClick}
                              style={{
                                fontSize: "0.875rem",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              {expanded ? "...less" : "more"}
                            </span>
                          )}
                      </>
                    </Typography>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <Typography variant="body2">
                        {postPreviewData?.postData?.description}
                        <span
                          onClick={handleExpandClick}
                          style={{
                            fontSize: "0.875rem",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          {expanded ? "...less" : "more"}
                        </span>
                      </Typography>
                    </Collapse>
                  </span>
                </Box>

                {/* <Typography fontWeight={600} fontSize={"14px"} paddingBottom={"10px"}>
            {" "}
            Illustrations for social media post
          </Typography> */}
                <Box
                  position={"relative"}
                  display="flex"
                  alignItems="flex-start"
                  gap={"0.5rem"}
                >
                  <SmallActionButton
                    sx={{
                      padding: postPreviewData?.subscriberInfo?.profileImgUrl
                        ? "0px"
                        : "6px",
                      overflow: "hidden",
                      color: "#183243",
                      mr: "4px",
                    }}
                    size="small"
                    variant="contained"
                    onClick={() => handleProfileBadgeClick(postPreviewData)}
                  >
                    <>
                      {postPreviewData?.subscriberInfo?.profileImgUrl ? (
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={postPreviewData?.subscriberInfo?.profileImgUrl}
                          alt=""
                        />
                      ) : (
                        <Typography
                          variant="h6"
                          fontSize={"0.75rem"}
                          color={"#183243"}
                        >
                          {postPreviewData?.subscriberInfo
                            ?.subscriberFirmName &&
                            postPreviewData?.subscriberInfo
                              ?.subscriberFirmName[0]}
                        </Typography>
                      )}
                    </>
                  </SmallActionButton>

                  <Stack width={"91%"} direction={"row"}>
                    <Stack
                      width={"92%"}
                      direction={"column"}
                      spacing={"0.2rem"}
                    >
                      <Box maxWidth={"95%"}>
                        <Typography
                          fontSize="14px"
                          fontWeight="600 !important"
                          color={"black"}
                          variant="h6"
                          width={"min-content"}
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Stack direction={"row"} gap={"5px"}>
                            {
                              postPreviewData?.subscriberInfo
                                ?.subscriberFirmName
                            }
                            {postPreviewData?.subscriberInfo
                              ?.isAccountVerified && (
                              <img
                                src={twitterbadge}
                                title="Twitter Status"
                                alt=""
                              />
                            )}
                          </Stack>
                        </Typography>
                      </Box>
                      <Box maxWidth={"95%"}>
                        <Typography
                          fontSize="14px"
                          fontWeight="400"
                          color={"black"}
                          variant="h6"
                          lineHeight={1}
                          width={"100%"}
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {
                            postPreviewData?.subscriberInfo
                              ?.subscriberAccountIndustry
                          }
                        </Typography>
                      </Box>

                      <Box maxWidth={"95%"}>
                        <Typography
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          fontSize="14px"
                          fontWeight="400"
                          color={"black"}
                          variant="h6"
                        >
                          {postPreviewData?.subscriberInfo?.city},{" "}
                          {postPreviewData?.subscriberInfo?.countryCode}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          sx={{ textDecoration: "underline" }}
                          fontSize={"14px"}
                          display={"inline"}
                        >
                          <Link
                            href={postPreviewData?.subscriberInfo?.website}
                            underline="none"
                            target="_blank"
                            rel="noopener"
                            color={"black"}
                          >
                            {postPreviewData?.subscriberInfo?.website}
                          </Link>
                        </Typography>
                      </Box>
                    </Stack>
                    <Box>
                      <Link
                        href={postPreviewData?.subscriberInfo?.linkedin}
                        underline="none"
                        target="_blank"
                        rel="noopener"
                        color={"black"}
                      >
                        <img
                          style={{ width: "1.2rem", height: "1.2rem" }}
                          src={linkedin}
                          title="Linkedin Status"
                          alt="linkedin"
                        />
                      </Link>
                    </Box>
                  </Stack>
                </Box>
              </CardContent>
              <>
                {showBtn && (
                  <>
                    <Box textAlign={"center"} mb={"0.5rem"}>
                      <Typography>
                        Posted by {postPreviewData?.postData?.post_by} {"|"}{" "}
                        {moment(postPreviewData?.updatedAt).format(
                          "DD-MM-YYYY"
                        )}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                      mb={"0.5rem"}
                    >
                      <Box>
                        <Button
                          onClick={() => {
                            handleEditClick(postPreviewData);
                            localStorage.setItem(
                              "isEditPost",
                              JSON.stringify(true)
                            );
                          }}
                          variant="outlined"
                          sx={{
                            borderRadius: "2px",
                            color: "#000000",
                            border: "1px solid #000000",
                            ":hover": {
                              color: "#000000",
                              border: "1px solid #000000",
                            },
                            textTransform: "none",
                          }}
                        >
                          Edit
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          onClick={() => handleDeleteClick(postPreviewData?.id)}
                          variant="outlined"
                          sx={{
                            borderRadius: "2px",
                            color: "#000000",
                            border: "1px solid #000000",
                            ":hover": {
                              color: "#000000",
                              border: "1px solid #000000",
                            },
                            textTransform: "none",
                          }}
                        >
                          Delete
                        </Button>
                      </Box>
                      <Box display={"none"}>
                        <Button
                          onClick={!isLoading ? handlePublish : null}
                          variant="outlined"
                          sx={{
                            borderRadius: "2px",
                            border: "1px solid #DB0011",
                            backgroundColor: "#DB0011",
                            color: "white",
                            ":hover": {
                              border: "1px solid #DB0011",
                              backgroundColor: "#DB0011",
                              color: "white",
                            },
                            textTransform: "none",
                          }}
                        >
                          {isLoading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            appContent?.publish
                          )}
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};

export default PostCard;
