import React, { useEffect, useState } from "react";
import {
  FormHelperText,
  Grid,
  Link,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useDispatch, useSelector } from "react-redux";
import {
  otpVarification,
  reSendOtp,
} from "../../../appStore/actions/auth/otp/action";
import { AuthActionButton } from "../../../common/StyledComponents/AuthComponents";
import { useNavigate } from "react-router-dom";
import { requestallmemberswithgroups } from "../../../appStore/actions/ArkchatGroup/action";
import useTimer from "../../../customHooks/useTimer";
import { getCookie } from "../../../config/cookie";
import IntroVideo from "../../../common/IntroVideo";
import { fetchToken } from "../../../config/firebase.config";
import { showToastMsg } from "../../../appStore/actions/global/action";

const classes = {
  captions: {
    fontSize: "0.87rem !important",
    display: "block",
  },
  verifyText: {
    color: "red !important",
  },
  otpBoxWrapper: {
    display: "grid",
    alignItems: "center",
    "@media only screen and (max-width:899px)": {
      marginBottom: "25px",
    },
    "@media only screen and (min-width:1199px)": {
      paddingRight: "20px",
    },
  },
  otpBox: {
    padding: "1.5rem 2rem 2rem 2rem",
    border: "1px solid #C4C4C4",
    height: "calc(100vh - 32px)",
    maxHeight: "700px",
    minHeight: "590px",
    width: "100%",
    maxWidth: "690px",
    margin: "auto",
    position: "relative",
    "@media only screen and (max-width:900px)": {
      margin: "auto !important",
      padding: "1.5rem 2rem 2rem 2rem !important",
    },
    "@media only screen and (max-width:1024px)": {
      height: "100%",
      padding: "1rem",
      marginLeft: "-5px",
    },
    "@media only screen and (max-width:1300px)": {
      minHeight: "530px",
    },
  },
  otpBtn: {
    position: "absolute",
    bottom: "2rem",
    left: "50%",
    marginLeft: "-2.72rem",
    "@media only screen and (max-width:899px)": {
      position: "relative",
      marginBottom: "-1.8rem",
      bottom: "0 !important",
    },
    "@media only screen and (max-width:1300px)": {
      bottom: "1.5rem",
    },
  },
  pointer: {
    cursor: "pointer",
  },
  mobscroll: {
    overflowX: "hidden",
    "@media only screen and (max-width:899px)": {
      overflowX: "hidden",
    },
  },
};
const OtpPage = () => {
  const [otp, setOtp] = useState("");
  const { t } = useTranslation();
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const appContent = t("AppContent", { returnObjects: true });
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.profile);
  const [canResend, setCanResend] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { time: remainingTime, resetTimer } = useTimer(60);
  const isRemember = getCookie("isRemember");

  useEffect(() => {
    if (remainingTime === 0) {
      setCanResend(true);
    }
  }, [remainingTime]);
  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handlevarify = async () => {
    if (otp) {
      try {
        setLoading(true);
        let formBody = {
          email: user?.emailStore?.data,
          otp,
          oldEmail: "",
          updateEmail: false,
          signIn: true,
          remember: isRemember ? true : false,
        };

        const otpVarificationResponse = await dispatch(
          otpVarification(formBody)
        );
        if (!otpVarificationResponse?.status) {
          setShowError(true);
          setLoading(false);
        } else {
          setShowError(false);
          const allres = await dispatch(requestallmemberswithgroups({}));
          fetchToken(otpVarificationResponse?.data?.id);
          setLoading(false);
          const invitationData =
            JSON.parse(localStorage.getItem("invitationData")) || false;
          //
          if (
            otpVarificationResponse?.data?.isProfileJourneyCompleted &&
            invitationData
          ) {
            navigate("/invitations");
          } else if (
            otpVarificationResponse?.data?.isProfileJourneyCompleted &&
            !invitationData
          ) {
            if (otpVarificationResponse?.data?.accountOwner) {
              if (
                otpVarificationResponse?.data?.isFirstGroupCreated &&
                otpVarificationResponse?.data?.isFirstInviteSent
              ) {
                navigate("/chat");
              } else if (
                otpVarificationResponse?.data?.isFirstGroupCreated &&
                !otpVarificationResponse?.data?.isFirstInviteSent
              ) {
                navigate("/invite");
              } else {
                navigate("/createfirstgroup");
              }
            } else {
              navigate("/chat");
            }
          } else if (
            !otpVarificationResponse?.data?.isProfileJourneyCompleted &&
            invitationData &&
            +invitationData?.inviteType === 1
          ) {
            navigate("/individualprofile");
          } else {
            navigate("/profile");
          }
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const reSendEmail = () => {
    if (canResend) {
      const userEmail = { email: user?.emailStore?.data };
      dispatch(reSendOtp(userEmail)).then((res) => {
        if (res?.status) {
          dispatch(showToastMsg(res?.message));
          setCanResend(false);
          resetTimer();
        }
      });
    }
  };

  return (
    <Box m={2}>
      <Grid container justifyContent="space-between" sx={classes.mobscroll}>
        <Grid item xs={12} md={5}>
          <IntroVideo />
        </Grid>
        <Grid item xs={12} md={6} sx={classes.otpBoxWrapper}>
          <Box sx={classes.otpBox}>
            <Typography fontSize={"1.7rem"} fontWeight={600} variant="h5">
              {appContent?.twoFactorHeading}
            </Typography>
            <Typography mt={"0.6rem"} sx={classes.captions} variant="caption">
              {appContent?.twoFactorEmailSentDescription}
            </Typography>
            <Typography mt={"0.2rem"} sx={classes.captions}>
              {user?.emailStore?.data}
            </Typography>

            <Box mt={"2.5rem"}>
              <Box mb={"0.6rem"}>
                <Typography sx={classes.captions}>
                  {appContent?.verificationCode}
                </Typography>
              </Box>
              <Box width={"21.5rem"}>
                <MuiOtpInput
                  TextFieldsProps={{
                    type: "text",
                    inputMode: "numeric",
                    size: "small",
                  }}
                  length={6}
                  value={otp}
                  onChange={handleChange}
                />
              </Box>
              {showError ? (
                <FormHelperText sx={classes.verifyText}>
                  {appContent?.twoFactorEnterValidCode}
                </FormHelperText>
              ) : (
                <></>
              )}
              <Box mt={"1rem"}>
                <Typography color={"#183242"} variant="caption">
                  {appContent?.twoFactorDidntReceiveCode}{" "}
                  <Link
                    onClick={reSendEmail}
                    style={{
                      textDecoration: "none",
                      color: canResend ? "#59A0FF" : "#183242",
                      cursor: canResend ? "pointer" : "not-allowed",
                    }}
                  >
                    {" "}
                    {appContent?.resendEmail}
                  </Link>
                </Typography>
              </Box>
              {!canResend && (
                <Box>
                  <Typography
                    fontSize={"0.75rem"}
                    color={"#183242"}
                    variant="caption"
                  >
                    {appContent?.resendCodeTimerText} 00:
                    {`${String(remainingTime).padStart(2, "0")}`}
                  </Typography>
                </Box>
              )}
              <Box mt={"1rem"}>
                <Typography
                  fontSize={"0.75rem"}
                  color={"#183242"}
                  fontWeight={"600"}
                >
                  {appContent?.skipTwoFactorDescription}
                </Typography>
              </Box>

              <Box mt={"1.4rem"}>
                <Typography
                  fontWeight={400}
                  fontSize={"0.87rem"}
                  color={"#183242"}
                  variant="caption"
                >
                  {appContent?.checkEmailFolders}
                </Typography>
              </Box>

              <Box sx={classes.otpBtn}>
                <AuthActionButton
                  type="button"
                  variant="contained"
                  disabled={otp.length >= 6 ? false : true}
                  onClick={()=>!isLoading?handlevarify():""}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    appContent?.verify
                  )}
                </AuthActionButton>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OtpPage;
