import React, { useState } from "react";
import { Box, Typography, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import arrowForwardIosOutlined from "../../assets/images/chats/down_arrow.svg";
import { useSelector } from "react-redux";
import { accountType } from "../../utils/constant";
import AlertPopUp from "../../common/AlertPopUp";
const data = [
  // { title: "Change email", endpoint: "/changeemail" },
  { title: "Reset password", endpoint: "/resetpassword" },
  { title: "Delete account", endpoint: "/deleteaccount" },
];
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "1rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const Settings = ({
  setSettingDrawerOpen,
  setResetPasswordDrawerOpen,
  setDeleteAccountDrawerOpen,
}) => {
  const [isAlertModalOpen, setAlertModalOpen] = useState(false);
  const userData = useSelector((state) => state?.profile?.profileData);
  const handleClickItem = (type) => {
    switch (type) {
      case "/resetpassword":
        setResetPasswordDrawerOpen(true);
        return;
      case "/deleteaccount":
        const accounteOwned = userData?.data?.subscribedTo?.filter(
          (item) => item?.accountOwner === userData?.data?.id
        );
        const haveOrgAccount = accounteOwned?.filter(
          (item) => item?.subscriberPriority === accountType?.Organisation
        );
        if (
          userData?.data?.activeSubscriber?.subscriberPriority ===
          accountType?.Organisation
        ) {
          setDeleteAccountDrawerOpen(true);
        } else if (haveOrgAccount?.length === 0) {
          setDeleteAccountDrawerOpen(true);
        } else {
          setAlertModalOpen(true);
        }
        return;
      default:
        return;
    }
  };

  return (
    <Box sx={classes.drawerBody}>
      <AlertPopUp
        title={"Alert."}
        bodyContent={"Delete Your Orgaisation Account First"}
        open={isAlertModalOpen}
        closeModal={setAlertModalOpen}
      />
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => setSettingDrawerOpen(false)}
          />
        </Typography>
        <Typography color={"black"}>Settings</Typography>
      </Stack>

      <>
        <Box width={"85%"} margin={"auto"}>
          <Box
            overflow={"auto"}
            maxHeight={"280px"}
            width={"95%"}
            sx={{
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                width: "0px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "transparent",
              },
            }}
          >
            {data?.map((item) => (
              <>
                <Box
                  key={item?.title + "settings"}
                  onClick={() => handleClickItem(item?.endpoint)}
                  bgcolor={"#E1E1E1"}
                  sx={{
                    cursor: "pointer",
                    borderRadius: "2px",
                  }}
                  mb={"0.9rem"}
                  p={"0.5rem 1rem"}
                  position={"relative"}
                >
                  <Typography
                    color={"black"}
                    fontSize={"0.87rem"}
                    fontWeight={400}
                  >
                    {item?.title}
                  </Typography>
                  <Box position={"absolute"} right={"0.4rem"} top={"20%"}>
                    <img src={arrowForwardIosOutlined} alt="logo" />
                  </Box>
                </Box>
              </>
            ))}
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default Settings;
