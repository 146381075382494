import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Paper, Typography } from "@mui/material";
import delete_icon from "../../../assets/images/delete.svg";
import resend_icon from "../../../assets/images/chats/resend_icon.svg";
import { handleDeleteTempMsgData } from "../../../appStore/actions/ArkchatGroup/action";
const classes = {
  hoverStyle: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E1E1E1",
    },
  },
};
const TempMoreMenu = ({
  messageData,
  menuRef,
  setMoreMenu,
  handleSendTempMsg,
}) => {
  const selectedGroup = useSelector((state) => state?.appStateData?.selectedGroupData);
  const dispatch=useDispatch();
  const handleClickResend = (messageData) => {
    handleSendTempMsg(messageData);
    setMoreMenu(null);
  };
  const handleDeleteClick = (tempMsgData) => {
    dispatch(handleDeleteTempMsgData(tempMsgData?.temp_timetoken,selectedGroup?.pnId))
  };
  return (
    <Box
      position="absolute"
      top={"0"}
      right={"0.8rem"}
      width={"15rem"}
      zIndex={100}
      ref={menuRef}
    >
      <Paper
        sx={{
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          padding: "0.7rem 0rem",
        }}
      >
        <Box>
          <Box
            onClick={() => handleClickResend(messageData)}
            p={"0.4rem 1rem"}
            sx={classes?.hoverStyle}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              //   onClick={() => handlereply(messageData)}
            >
              <Box>
                <Typography>Resend</Typography>
              </Box>
              <Box>
                <img src={resend_icon} alt="resend_icon" />
              </Box>
            </Box>
          </Box>

          <Box onClick={() => handleDeleteClick(messageData)} p={"0.4rem 1rem"} sx={classes?.hoverStyle}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              //   onClick={() => handlereply(messageData)}
            >
              <Box>
                <Typography>Delete</Typography>
              </Box>
              <Box>
                <img src={delete_icon} alt="delete_icon" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default TempMoreMenu;
