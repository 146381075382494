import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import {
  getAllPlans,
  savePlanData,
} from "../../../appStore/actions/Subscription/action";
import AlertPopUp from "../../../common/AlertPopUp";
import { useTranslation } from "react-i18next";

const PricingPlans = ({
  setQuantityDarwerOpen,
  setBillingFormDrawerOpen,
  setManageActiveMemberDrawerOpen,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [isPermissionModalOpen, setPermissionModal] = useState(false);
  const allPlansList = useSelector((state) => state?.subscription?.allPlans);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPlans());
  }, []);

  const handleClickBuySubscription = (data) => {
    if (allPlansList?.canPay) {
      if (allPlansList?.plans?.length > 0) {
        dispatch(savePlanData(data));
        setManageActiveMemberDrawerOpen(true);
      }
    } else {
      setPermissionModal(true);
    }
  };

  return (
    <Box
      overflow={"scroll"}
      height={"calc(100vh - 7rem)"}
      sx={{
        "&::-webkit-scrollbar": {
          width: "1px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#F9F8F8",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "grey",
        },
      }}
      position={"relative"}
    >
      <AlertPopUp
        title={"Alert."}
        bodyContent={"Permission Deined"}
        open={isPermissionModalOpen}
        closeModal={setPermissionModal}
      />
      {allPlansList?.plans?.map((planData) => (
        <div
          style={{
            minHeight: "6rem",
            backgroundColor: "#F3F3F3",
            marginTop: "19px",
            fill: "#F3F3F3",
            filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                marginLeft: "16px",
                marginTop: "17px",
                color: "#183243",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "22px",
              }}
            >
              {planData?.name}
            </p>
            <Button
              variant="contained"
              disabled={planData?.purchased}
              onClick={() => handleClickBuySubscription(planData)}
              sx={{
                marginRight: "39px",
                backgroundColor: "#DB0011",
                border: "none",
                color: "white",
                marginTop: "17px",
                borderRadius: "2px",
                width: "63px",
                height: "30px",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "normal",
                cursor: "pointer",
                textTransform: "none",
                ":hover": {
                  backgroundColor: "#DB0011",
                  color: "#fff",
                },
              }}
            >
              {appContent?.buy}
            </Button>
          </div>
          <div
            style={{
              // marginTop: "9px",
              display: "flex",
              alignItems: "center",
              marginLeft: "17px",
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "18px",
            }}
          >
            <p>{planData?.description} </p>
          </div>
        </div>
      ))}
    </Box>
  );
};

export default PricingPlans;
