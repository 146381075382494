import { Tooltip } from "@mui/material";

// Reusable Tooltip Component
const LableToolTip = ({ title, children }) => (
  <Tooltip title={title} arrow>
    {children}
  </Tooltip>
);

export default LableToolTip;
