import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import searchIcon from "../../../assets/images/chats/gallery_active.svg";
import globeIcon from "../../../assets/images/post/globeIcon.svg";
import selectCheckIcon from "../../../assets/images/post/selectCheckIcon.svg";

import {
  ChatSearchButton,
  CustomInput,
  SearchBox,
  SmallIcon,
} from "../../../common/chatComponents/ScreenSearchSection";
import { useSelector } from "react-redux";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "0.5rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const CountryDrawer = ({
  setCountryDrawer,
  selectedCountries,
  handleCountryInputChange,
  isMultiSelectCountry = false,
  isAllCountry = false,
  handleClickAllCountry,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const countryData = useSelector((state) => state?.country);

  let filteredData = countryData?.countrylist?.data || [];

  filteredData = filteredData?.filter((el) =>
    el?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
        <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => {
              setCountryDrawer(false);
              setSearchQuery("");
            }}
          />
        </Typography>
        <Typography color={"black"}>Countries</Typography>
      </Stack>
         
          <Stack width={"90%"} margin={"auto"} mt={"1rem"}>
            <SearchBox>
              <CustomInput
                autoFocus
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
              />
              <ChatSearchButton size="small" variant="contained">
                <SmallIcon src={searchIcon} />
              </ChatSearchButton>
            </SearchBox>
          </Stack>
          <Box
            width={"90%"}
            margin={"auto"}
            mt={"1rem"}
            height={"72vh"}
            maxHeight={"72vh"}
            overflow={"auto"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "3px",
              },
            }}
          >
            {isMultiSelectCountry && (
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"4%"}
                mb={"0.6rem"}
                onClick={handleClickAllCountry}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  borderRadius={"2px"}
                  bgcolor={"#EDEDED"}
                  justifyContent={"center"}
                  width={"12%"}
                  height={"2.2rem"}
                >
                  {isAllCountry ? (
                    <img
                      style={{ width: "51%" }}
                      src={selectCheckIcon}
                      alt={"icon"}
                    />
                  ) : (
                    <img
                      style={{ width: "51%" }}
                      src={globeIcon}
                      alt={"icon"}
                    />
                  )}
                </Box>
                <Box>
                  <Typography
                    color={"#183243"}
                    fontSize={"0.85rem"}
                    fontWeight={400}
                    variant="h6"
                    sx={{ cursor: "pointer" }}
                  >
                    All countries
                  </Typography>
                </Box>
              </Box>
            )}

            <>
              {isMultiSelectCountry &&
                countryData?.countrylist &&
                filteredData?.map((el) => (
                  <Box
                    key={el?.iso2 + "CountryDrawer"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={"4%"}
                    onClick={() =>
                      handleCountryInputChange(
                        {
                          name: el?.name,
                          code: el?.iso2,
                        },
                        isMultiSelectCountry
                      )
                    }
                  >
                    <>
                      {selectedCountries.some(
                        (c) => c.code === el.iso2 || c.name === el.name
                      ) ? (
                        // Display selected icon
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          borderRadius={"2px"}
                          bgcolor={"#EDEDED"}
                          justifyContent={"center"}
                          width={"12%"}
                          height={"2.2rem"}
                          mb={"0.65rem"}
                        >
                          <img
                            style={{ width: "51%" }}
                            src={selectCheckIcon}
                            alt={"icon"}
                          />
                        </Box>
                      ) : (
                        <Box width={"12%"}>
                          <img
                            style={{ width: "100%" }}
                            src={el?.flag}
                            alt={el?.name}
                          />
                        </Box>
                      )}
                    </>

                    <Box>
                      <Typography
                        color={"#183243"}
                        fontSize={"0.85rem"}
                        fontWeight={400}
                        variant="h6"
                        sx={{ cursor: "pointer" }}
                      >
                        {el?.name}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </>

            <>
              {!isMultiSelectCountry &&
                countryData?.countrylist &&
                filteredData?.map((el) => (
                  <Box
                    key={el?.iso2 + "MultiselectCountryDrawer"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={"4%"}
                    onClick={() =>
                      handleCountryInputChange(
                        {
                          name: el?.name,
                          code: el?.iso2,
                        },
                        el
                      )
                    }
                  >
                    <>
                      <Box width={"12%"}>
                        <img
                          style={{ width: "100%" }}
                          src={el?.flag}
                          alt={el?.name}
                        />
                      </Box>
                    </>

                    <Box>
                      <Typography
                        color={"#183243"}
                        fontSize={"0.85rem"}
                        fontWeight={400}
                        variant="h6"
                        sx={{ cursor: "pointer" }}
                      >
                        {el?.name}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </>
          </Box>
          {isMultiSelectCountry && (
            <Box textAlign={"center"} mt={"1.5rem"}>
              <Button
                onClick={() => {
                  setCountryDrawer(false);
                  setSearchQuery("");
                }}
                style={{
                  background: "#DB0011",
                  color: "white",
                  borderRadius: "2px",
                }}
                variant="contained"
              >
                Done
              </Button>
            </Box>
          )}
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default CountryDrawer;
