import axios from "axios";
import * as appActionTypes from "./type";
import { getCookie } from "../../../config/cookie";
import { logWrapper } from "../../../utils/helper";
export const saveSelectedAppGroupData = (grpData) => (dispatch) => {
  dispatch({
    type: appActionTypes.SET_SELECTED_GROUP_DATA,
    payload: grpData,
  });
};

export const handleScrollToBottom = (payload) => (dispatch) => {
  dispatch({
    type: appActionTypes.SET_SCROLL_TO_BOTTOM,
    payload: payload,
  });
};

export const handleThreadScrollToBottom = (payload) => (dispatch) => {
  dispatch({
    type: appActionTypes.SET_THREAD_SCROLL_TO_BOTTOM,
    payload: payload,
  });
};

export const handleAppSelectedTopic = (topicData) => (dispatch) => {
  dispatch({
    type: appActionTypes.SET_SELECTED_TOPIC_DATA,
    payload: topicData,
  });
};

export const handleAppSelectedTopicToSaveMsg = (topicData) => (dispatch) => {
  dispatch({
    type: appActionTypes.TOPIC_TO_SAVE_MESSAGE,
    payload: topicData,
  });
};

export const handleChangeTopicOpen = (value) => (dispatch) => {
  dispatch({
    type: appActionTypes.CHANGE_TOPIC_INITIATED,
    payload: value,
  });
};

export const handleStarMsgScreen = (value) => (dispatch) => {
  dispatch({
    type: appActionTypes.SET_SHOW_STARMESSAGE_SCREEN,
    payload: value,
  });
};

export const handleHomeTabValue = (value) => (dispatch) => {
  dispatch({
    type: appActionTypes.SET_HOMETAB_VALUE,
    payload: value,
  });
};

export const handleShowAIScreen = (value) => (dispatch) => {
  dispatch({
    type: appActionTypes.SET_SHOW_AI_SCREEN,
    payload: value,
  });
};

export const handleShowAIHistoryScreen = (value) => (dispatch) => {
  dispatch({
    type: appActionTypes.SET_SHOW_AI_HISTOPRY_SCREEN,
    payload: value,
  });
};

export const handleShowAIStarHistoryScreen = (value) => (dispatch) => {
  dispatch({
    type: appActionTypes.SET_SHOW_STARHISTOPRY_SCREEN,
    payload: value,
  });
};

export const handleSwitchAccountRefresh = (value) => (dispatch) => {
  dispatch({
    type: appActionTypes.SWITCH_ACCOUNT_SHOULD_REFRESH,
    payload: value,
  });
};

export const handleOpenCloseThreadView = (value) => (dispatch) => {
  dispatch({
    type: appActionTypes.OPEN_CLOSE_THREAD_VIEW,
    payload: value,
  });
};

export const handleSavePreviewEditPostData = (postData) => (dispatch) => {
  dispatch({
    type: appActionTypes.POST_PREVIEW_OR_EDIT_DATA,
    payload: postData,
  });
};

export const handleTogglePunbunTokenUpdateState = (value) => (dispatch) => {
  dispatch({
    type: appActionTypes.TOGGLE_UPDATE_PUBNUB_TOKEN,
    payload: value,
  });
};

export const handleLoading = (value) => (dispatch) => {
  dispatch({
    type: appActionTypes.IS_LOADING,
    payload: value,
  });
};

export const handleAccountSwitchLoading = (value) => (dispatch) => {
  dispatch({
    type: appActionTypes.ACCOUNT_SWITCH_LOADING,
    payload: value,
  });
};

export const handleSetserviceTypePost=(type)=>(dispatch)=>{
  dispatch({
    type: appActionTypes.SET_SERVICETYPE_POST,
    payload: type,
  });
}

export const getUserProfileDetails = (user_id) => async () => {
  const responseData = await axios.get(
    `${process.env.REACT_APP_BASE_PATH}/onboarding/user/profile/${user_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    }
  );
  logWrapper(responseData);
  return responseData?.data?.data;
  
};

// https://apptest.arkchat.com/v2/onboarding/user/delete-user-account
// { for social
//   "email": "rajeev@sukritinfotech.com", only email for manual.
//   "social_id": "114988310860230957483",
//   "type": 1
// }

// https://apptest.arkchat.com/v2/onboarding/user/verify-account-delete

export const handleDeleteAccountFromOpenRoute = (reqBoby) => async () => {
  const responseData = await axios.post(
    `${process.env.REACT_APP_BASE_PATH}/onboarding/user/delete-user-account`,
    reqBoby,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return responseData?.data;
};

export const deleteAccountDetails = (value) => (dispatch) => {
  dispatch({
    type: appActionTypes.DELETE_ACCOUNT_API_RESPONSE_DATA,
    payload: value,
  });
};

export const handleOtpVerifyDeleteAccountFromOpenRoute =
  (reqBoby) => async () => {
    const responseData = await axios.post(
      `${process.env.REACT_APP_BASE_PATH}/onboarding/user/verify-account-delete`,
      reqBoby,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return responseData?.data;
  };
