import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import PostTabs from "./PostTabs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  AccountLevelArkchatPermission,
  sellerTypeEnum,
  serviceTypeName,
} from "../../utils/constant";
import { requestGetAllAcountLevelMember } from "../../appStore/actions/ArkchatGroup/action";
import useAppSelectors from "../../customHooks/useAppSelectors";
export const classes = {
  active: {
    "& .MuiStepIcon-root.Mui-active": {
      color: "rgba(24, 50, 67, 1)",
    },
    "& .MuiStepIcon-root.Mui-completed": {
      color: "rgba(24, 50, 67, 1)",
    },
  },
  checkedColor: {
    "&.Mui-checked": {
      color: "#092C4C",
    },
  },
};
const PostCreateIndex = () => {

  const [isLoading,setLoading] = useState(false);
  const { currentUserData,postServiceTypeValue } = useAppSelectors();
  const [activeStep, setActiveStep] = React.useState(() => {
    // +localStorage.getItem("serviceType")
    if ( +postServiceTypeValue!== serviceTypeName?.Product) {
      return currentUserData?.activeSubscriber?.profileBrief ? 1 : 0;
    } else {
      return !currentUserData?.activeSubscriber?.manufacturer &&
        currentUserData?.activeSubscriber?.sellerType === sellerTypeEnum?.Empty
        ? 0
        : 1;
    }
  });
  const [value, setValue] = useState(
    +postServiceTypeValue === serviceTypeName?.Photo
      ? 1
      : +postServiceTypeValue === serviceTypeName?.Videos
      ? 2
      : +localStorage.getItem("tabValue")
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      currentUserData?.activePermission === AccountLevelArkchatPermission?.USER
    ) {
      navigate("/post");
    }
  }, []);
  useEffect(() => {
    dispatch(requestGetAllAcountLevelMember());
  }, [dispatch]);

  return (
    <Box width={"100%"} margin={"auto"}>
      <PostTabs
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        setValue={setValue}
        value={value}
        postServiceTypeValue={+postServiceTypeValue}
        isLoading={isLoading}
        setLoading={setLoading}
      />
    </Box>
  );
};

export default PostCreateIndex;
