import { GET_FAQ_LIST_DATA } from "../../actions/masterData";

const initState = {
  faqData: [],
};

export const faqReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_FAQ_LIST_DATA:
      return {
        ...state,
        faqData: action?.payload,
      };
    default:
      return state;
  }
};
