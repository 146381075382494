import { BASE_API_URL } from "../../../config/url"

export const ALL_NOTIFICATION_DONE = 'ALL_NOTIFICATION_DONE'
export const CLEAR_ALL_NOTIFICATION_DONE = 'CLEAR_ALL_NOTIFICATION_DONE'
export const CLEAR_NOTIFICATION_BY_ID_DONE = 'CLEAR_NOTIFICATION_BY_ID_DONE'
export const STAR_NOTIFICATION_DONE = 'START_NOTIFICATION_DONE'
export const GET_ALL_USER_STARED_NOTIFIACTION = 'GET_ALL_USER_STARED_NOTIFIACTION'

export const ALL_NOTIFICATION_ERROR = 'ALL_NOTIFICATION_ERROR'
export const CLEAR_ALL_NOTIFICATION_ERROR = 'CLEAR_ALL_NOTIFICATION_ERROR'
export const CLEAR_NOTIFICATION_BY_ID_ERROR = 'CLEAR_NOTIFICATION_BY_ID_ERROR'
export const STAR_NOTIFICATION_ERROR = 'START_NOTIFICATION_ERROR'
export const GET_ALL_USER_STARED_NOTIFICATION_ERROR = 'GET_ALL_USER_STARED_NOTIFICATION_ERROR'

export const ALL_NOTIFICATION_URL = `${BASE_API_URL}/notifications`
export const CLEAR_ALL_NOTIFICATION_URL = `${BASE_API_URL}/notifications/clear`
export const STAR_NOTIFICATION_URL = `${BASE_API_URL}/notifications/starred` // it takes a notification id
export const GET_ALL_USER_STARED_NOTIFICATION_URL = `${BASE_API_URL}/notifications/starred` 

