// import constant file file types
import axios from "axios";
import { AUTH_API_URL, EMAIL_STORE, SET_CURRENT_USER_LOGEDIN ,LOGIN_FAIL, REMOVE_SET_CURRENT_USER_LOGEDIN} from ".";

import { SET_TOKEN_DONE } from "../../../reducers/auth";
import { REQUEST_FAILED } from "../../global/type";
import { getCookie, setCookie } from "../../../../config/cookie";

//  action for the sign in
export const userLogin = (formData) => {
  let API_URL = `${AUTH_API_URL}/signin?locale=en`;
  return (dispatch) => {
    return axios.post(API_URL, formData).then((res) => {
      if (res?.status == 200) {
        dispatch({
          type: EMAIL_STORE,
          payload: formData?.email,
        });
        return res?.data;
      } else {
        dispatch({
          type: LOGIN_FAIL,
          payload: res,
        });
        return res?.data;
      }
    }).catch((err)=>{
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    });
  };
};



export const handleSocialSignIn = (formData) =>{
  return (dispatch) => {
    return axios
      .post(`${AUTH_API_URL}/signin/social`, formData)
      .then((res) => {
        if (res?.status == 200) {
          if(res?.data?.data?.activeSubscriber?.id){
            setCookie("subsCriber_id",res?.data?.data?.activeSubscriber?.id,30)
          }
          dispatch({
            type: SET_CURRENT_USER_LOGEDIN,
            payload: res?.data,
          });
          dispatch({
            type: SET_TOKEN_DONE,
            payload: res?.data.data?.token,
          });
          return res?.data;
        } else {
          dispatch({
            type: SET_CURRENT_USER_LOGEDIN,
            payload: res,
          });
          return res?.data;
        }
      }).catch((err)=>{
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};


export const handleLogout = () =>{
  return (dispatch) => {
    const token = getCookie("token");
    return axios(`${AUTH_API_URL}/signout`,{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }})
      .then((res) => {
        if (res?.data?.status) {
            dispatch({
              type: REMOVE_SET_CURRENT_USER_LOGEDIN,
              payload: res?.data,
            });
          
          return res?.data;
        } else {
          // dispatch({
          //   type: REMOVE_SET_CURRENT_USER_LOGEDIN,
          //   payload: res,
          // });
          return res?.data;
        }
      }).catch((err)=>{
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};
