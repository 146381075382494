import React, { useState } from "react";
import {
  Box,
  Typography,
  Drawer,
  Stack,
  Button,
  ListItem,
  List,
} from "@mui/material";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { profileUpdate } from "../../appStore/actions/profile/action";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
  },
  header: {
    // backgroundColor: '#183243',
    backgroundColor: "#F9F8F8",
    color: "#000000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const HelperScreen = ({
  content,
  content2,
  heading1,
  heading2,
  isContact = false,
  updateKey,
}) => {
  const [isDrawerOpen, setDrawerOpen] = useState(true);
  const dispatch = useDispatch();
  const handleDone = () => {
    setDrawerOpen(false);
    dispatch(profileUpdate({ [updateKey]: true }, false));
  };
  return (
    <Drawer sx={{ zIndex: 1202 }} open={isDrawerOpen} anchor="right">
      <Box sx={classes.drawerBody}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={classes.header}
        >
          <Typography onClick={handleDone} sx={classes.iconDiv}>
            <CloseIcon sx={classes.iconn} />
          </Typography>
          <Typography>Tooltip</Typography>
        </Stack>
        {!isContact && (
          <Box width={"95%"} margin={"auto"} mt={"0.4rem"}>
            {content?.map((data) => (
              <Box width={"98%"} mb={"1rem"}>
                <Typography variant="subtitle2">{data}</Typography>
              </Box>
            ))}
          </Box>
        )}

        {isContact && (
          <Box width={"95%"} margin={"auto"} mt={"0.4rem"}>
            <Box>
              {/* <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>Hello</ListItem>
                <ListItem>Hello</ListItem>
                <ListItem>Hello</ListItem>
              </List> */}
              <Box>
                <Typography fontWeight={600}>{heading1}</Typography>
              </Box>
              {content?.map((data) => (
                <Box width={"98%"} mb={"1rem"} key={data}>
                  <Typography variant="subtitle2">{data}</Typography>
                </Box>
              ))}
            </Box>
            <Box>
              <Box>
                <Typography fontWeight={600}>{heading2}</Typography>
              </Box>
              {content2?.map((data) => (
                <Box width={"98%"} mb={"1rem"} key={data}>
                  <Typography variant="subtitle2">{data}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        <Box
          textAlign={"center"}
          width={"100%"}
          position={"absolute"}
          bottom={"1.5rem"}
        >
          <Button
            onClick={handleDone}
            style={{
              background: "#DB0011",
              color: "white",
              borderRadius: "3px",
              textTransform: "none",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
            variant="contained"
          >
            Got it
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default HelperScreen;
