import React from "react";
import { useSelector } from "react-redux";
import FilterCategory from "../../MarketPlace/FilterCategory";
import { serviceTypeName } from "../../../utils/constant";
const ExploreProductFilterTab = ({
  handleFilter,
  clearFilter,
  setChecked,
  checked,
}) => {
  const productServiceCategoryData = useSelector(
    (state) => state?.serviceCategory?.productServiceCategoryData
  );

  const handleFilterType = (categoryId) => {
    handleFilter(serviceTypeName?.Product, categoryId);
  };
  return (
    <>
      <FilterCategory
        data={productServiceCategoryData}
        handleFilterApply={handleFilterType}
        clearFilter={clearFilter}
        checked={checked}
        setChecked={setChecked}
      />
    </>
  );
};

export default ExploreProductFilterTab;
