import { BASE_API_URL } from "../../../config/url";

export const CREATE_SUBSCRIBER_DONE="CREATE_SUBSCRIBER_DONE";
export const CREATE_SUBSCRIBER_ERROR="CREATE_SUBSCRIBER_ERROR";

export const GET_INVITE_URL_DONE="GET_INVITE_URL_DONE";
export const GET_INVITE_URL_ERROR="GET_INVITE_URL_ERROR";

export const JOIN_USER_DONE="JOIN_USER_DONE";
export const JOIN_USER_ERROR="JOIN_USER_ERROR";
export const INVITATION_RETRIVED_DATA="INVITATION_RETRIVED_DATA";
export const ALL_PENDING_INVITATRION="ALL_PENDING_INVITATRION";

export const GET_USER_IN_ACCOUNT_DONE="GET_USER_IN_ACCOUNT_DONE";
export const GET_USER_IN_ACCOUNT_ERROR="GET_USER_IN_ACCOUNT_ERROR";

export const CREATE_SUBSCRIBER_API=`${BASE_API_URL}/onboarding/subscriber`;