import * as appActionTypes from "../../actions/AppState/type";

const initState = {
  currentHomeIndexTab:0,
  isThreadScreen: false,
  shouldCallPubnubGetToken: false,
  isLoading: false,
  isAccountSwitchLoading: false,
  openRouteDeleteAccountDetail:{}
};

export const localAppReducer = (state = initState, action) => {
  switch (action.type) {
    case appActionTypes.SET_HOMETAB_VALUE:
      return {
        ...state,
        currentHomeIndexTab: action?.payload,
      };
    case appActionTypes.OPEN_CLOSE_THREAD_VIEW:
      return {
        ...state,
        isThreadScreen: action?.payload,
      };
    case appActionTypes.SHOULD_CALL_PUBNUB_GET_TOKEN:
      return {
        ...state,
        shouldCallPubnubGetToken: action?.payload,
      };
    case appActionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: action?.payload,
      };
    case appActionTypes.ACCOUNT_SWITCH_LOADING:
      return {
        ...state,
        isAccountSwitchLoading: action?.payload,
      };
      case appActionTypes.DELETE_ACCOUNT_API_RESPONSE_DATA:
        return {
          ...state,
          openRouteDeleteAccountDetail: action?.payload,
        };
    default:
      return state;
  }
};
