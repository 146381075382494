import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack, Typography } from "@mui/material";
import { createStyledIndicatorButton } from "../../../../common/StyledComponents/SideBarComponents";
import { useSelector } from "react-redux";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "1rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};

const ViewLikeDrawer = ({ setViewLikeDarwerOpen }) => {
  const SmallActionButton = createStyledIndicatorButton("#E3E1E1");
  const likeUserList = useSelector(
    (state) => state?.arkchatgroup?.likeUserList
  );

  return (
    <Box sx={classes.drawerBody}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => setViewLikeDarwerOpen(false)}
          />
        </Typography>
        <Typography color={"black"}>
          {likeUserList?.length > 0
            ? `${likeUserList?.length} Likes`
            : "Like List"}
        </Typography>
      </Stack>

      <>
        <Box width={"95%"} margin={"auto"}>
          <Box
            overflow={"auto"}
            maxHeight={"480px"}
            width={"95%"}
            sx={{
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                width: "0px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "transparent",
              },
            }}
          >
            {likeUserList?.map((userData) => (
              <Box
                key={userData?.id + "likeuserList"}
                display="flex"
                alignItems="center"
                gap={"0.5rem"}
                mb={"0.8rem"}
              >
                <SmallActionButton
                  sx={{
                    color: "#183243",
                    padding: userData?.profileImgUrl && "0px",
                    overflow: "hidden",
                  }}
                  size="small"
                  variant="contained"
                  //   onClick={() => handleProfileBadgeClick(userData)}
                >
                  {false ? (
                    <img
                      src={userData?.profileImgUrl}
                      alt="profile"
                      height="100%"
                    />
                  ) : (
                    <Typography
                      variant="h6"
                      fontSize={"0.75rem"}
                      color={"#183243"}
                    >
                      {userData?.firstName && userData?.firstName[0]}
                    </Typography>
                  )}
                </SmallActionButton>

                <Box>
                  <Typography fontSize="0.9rem" fontWeight="400" variant="h6">
                    {`${userData?.firstName} ${userData?.lastName}`}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default ViewLikeDrawer;
