import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopTimePicker } from "@mui/x-date-pickers";

const Time = ({ defaultValue, handleTimeChange }) => {
  const classes = {
    timePbody: {
      fontSize:"0.75rem",
      "& input": {
        padding: 0,
        width: "62px",
        fontSize:"0.75rem",
        textAlign:"cetre"
      },
      "& fieldset": {
        border: "none",
      },
    },
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopTimePicker
         
          onChange={(newTime) => handleTimeChange(newTime)}
          sx={classes.timePbody}
          orientation={"landscape"}
          format={"hh:mm:A"}
          formatDencity="spacious"
          ampm={true}
          defaultValue={defaultValue}
        />
      </LocalizationProvider>
    </>
  );
};

export default Time;
