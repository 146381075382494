import React from "react";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import filesvg from "../../../assets/images/chats/file_svg.svg";
import googlesvg from "../../../assets/images/chats/google_svg.svg";
import youtubeLogo from "../../../assets/images/chats/youtubeLogo.svg";
import SelectImage from "./SelectImage";
import {
  sellerTypeEnum,
  serviceTypeName,
  serviceTypeValue,
} from "../../../utils/constant";
import { classes } from "../PostCreateIndex";
import SelectVideos from "./SelectVideos";
import useAppSelectors from "../../../customHooks/useAppSelectors";

const CreatePostComponent = ({
  setGoogleDrawerOpen,
  setYoutubeDrawerOpen,
  images,
  handleFileInputChange,
  cancleSelectedImage,
  cancleSelectedVideo,
  fileImageRef,
  setMultiSelectCountry,
  createPostData,
  handleCreatePostInputChange,
  handleTagKeyDown,
  handleRemoveTag,
  setCategoryModalOpen,
  selectedCategory,
  createPostSubmitBtnRef,
  handleCreatePostSubmit,
  setDrawerOpen,
  extraLable,
  brandArtistData,
  countryValue,
  authorized_seller,
  seller_of,
  descriptionHeading,
  descriptionCaption,
  visiblityCaption,
  titleCaption,
  videos,
}) => {
  const { currentUserData, postServiceTypeValue } = useAppSelectors();
  return (
    <Box
      position={"relative"}
      width={"100%"}
      mt={"0.5rem"}
      minHeight={"60vh"}
      display="flex"
      gap="4.5rem"
    >
      <Box width={"60%"}>
        <form onSubmit={handleCreatePostSubmit}>
          <>
            {(serviceTypeName?.Product === +postServiceTypeValue ||
              serviceTypeName?.Art === +postServiceTypeValue) && (
              <Box
                mb={"0.5rem"}
                sx={{
                  display: "flex",
                  gap: "6%",
                }}
              >
                <Box width={"48%"}>
                  <TextField
                    size="Large"
                    type="text"
                    label={extraLable || ""}
                    name="category"
                    variant="standard"
                    value={brandArtistData?.name || ""}
                    onClick={() => {
                      setDrawerOpen(true);
                    }}
                    required
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ArrowForwardIosOutlined />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: !!brandArtistData?.email ? true : undefined,
                    }}
                  />
                  <FormHelperText>Required</FormHelperText>
                </Box>

                <Box position={"relative"} width={"48%"}>
                  <Box width={"100%"} position={"absolute"} bottom={"0.4rem"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={classes?.checkedColor}
                          name="authorized_seller"
                          size="large"
                          onChange={handleCreatePostInputChange}
                          checked={authorized_seller}
                          required
                        />
                      }
                      label={`We are the authorised seller of this ${seller_of}`}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </>

          <Box
            mb={"0.5rem"}
            sx={{
              display: "flex",
              gap: "6%",
            }}
          >
            <Box width={"48%"}>
              <TextField
                size="Large"
                type="text"
                label={`${serviceTypeValue[+postServiceTypeValue]} Category`}
                name="category"
                variant="standard"
                value={selectedCategory?.name || ""}
                onClick={() => {
                  setCategoryModalOpen(true);
                }}
                required={true}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowForwardIosOutlined />
                    </InputAdornment>
                  ),
                }}
                sx={{ width: "100%" }}
              />
              <FormHelperText>Required</FormHelperText>
            </Box>

            {serviceTypeName?.Art !== +postServiceTypeValue && (
              <Box width={"48%"}>
                <TextField
                  size="Large"
                  type="text"
                  label={`${serviceTypeValue[+postServiceTypeValue]} title`}
                  name="title"
                  variant="standard"
                  value={createPostData?.post_data?.title}
                  required
                  fullWidth
                  sx={{ width: "100%" }}
                  onChange={handleCreatePostInputChange}
                />
                <FormHelperText>Required</FormHelperText>
                <FormHelperText>{titleCaption}</FormHelperText>
              </Box>
            )}
            {serviceTypeName?.Art === +postServiceTypeValue && (
              <Box width={"48%"}>
                <TextField
                  size="Large"
                  type="text"
                  label={"Visibility"}
                  variant="standard"
                  onClick={() => setMultiSelectCountry(true)}
                  name="countries"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ArrowForwardIosOutlined />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "100%" }}
                  value={countryValue}
                  required
                />

                <FormHelperText>{visiblityCaption}</FormHelperText>
              </Box>
            )}
          </Box>

          <Box
            mb={"0.5rem"}
            sx={{
              display: "flex",
              gap: "6%",
            }}
          >
            <Box width={"48%"}>
              <TextField
                size="Large"
                type="text"
                variant="outlined"
                value={createPostData?.tagsInput}
                name="tagsInput"
                fullWidth
                sx={{ width: "100%" }}
                placeholder="#tags"
                onChange={handleCreatePostInputChange}
                onKeyDown={handleTagKeyDown}
              />
              <FormHelperText>Optional</FormHelperText>
              <FormHelperText>eg: #iphone13pro,#iphone13pro.</FormHelperText>
              <FormHelperText>
                #tags will improve searchability of your service.
              </FormHelperText>
              <Box>
                {createPostData?.tags?.length > 0 &&
                  createPostData?.tags?.map((item, i) => (
                    <span key={item + "visibliKey"}>
                      <Chip
                        key={item + "createPostDataTags" + i}
                        label={item}
                        variant="outlined"
                        onDelete={() => handleRemoveTag(item)}
                        sx={{ marginRight: "4px", marginBottom: "4px" }}
                      />
                    </span>
                  ))}
              </Box>
            </Box>

            {serviceTypeName?.Art !== +postServiceTypeValue && (
              <Box width={"48%"}>
                <TextField
                  size="Large"
                  type="text"
                  label={"Visibility"}
                  variant="standard"
                  onClick={() =>
                    (currentUserData?.activeSubscriber?.sellerType ===
                      sellerTypeEnum?.Dealer ||
                      currentUserData?.activeSubscriber?.sellerType ===
                        sellerTypeEnum?.Distributor) &&
                    serviceTypeName?.Product === +postServiceTypeValue
                      ? null
                      : setMultiSelectCountry(true)
                  }
                  name="countries"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <ArrowForwardIosOutlined />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "100%" }}
                  value={countryValue}
                  required
                />
                <FormHelperText>{visiblityCaption}</FormHelperText>
              </Box>
            )}
          </Box>

          <Box>
            <Box mb={"0.6rem"} padding={"0rem 0rem 0rem 0.1rem"}>
              <Typography
                fontSize={"0.9rem"}
                color={"#4F4F4F"}
                lineHeight={"1rem"}
              >
                {descriptionHeading}
              </Typography>
            </Box>
            <TextareaAutosize
              sx={{
                "& fieldset": { border: "none" },
              }}
              style={{
                width: "100%",
                resize: "none",
                maxHeight: "5rem",
                height: "5rem",
                outline: "none",
                lineHeight: "1.25rem",
                padding: "5px 8px",
                fontFamily: "Poppins",
                fontSize: "0.87rem",
                borderColor: "rgb(118, 118, 118)",
                borderRadius: "4px",
              }}
              minRows={"1"}
              placeholder={descriptionCaption}
              name="description"
              value={createPostData?.post_data?.description}
              onChange={handleCreatePostInputChange}
            />
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box>
                {" "}
                <FormHelperText>
                  Optional, Maximum 2000 characters
                </FormHelperText>
              </Box>
              <Box>
                {" "}
                <FormHelperText>
                  {createPostData?.post_data?.description?.length}/2000
                </FormHelperText>
              </Box>
            </Box>
          </Box>
          <Button
            ref={createPostSubmitBtnRef}
            sx={{ display: "none" }}
            type="submit"
          >
            SubmitButton
          </Button>
        </form>
      </Box>

      <Box width={"35%"}>
        <Box display={"flex"} gap={"5px"}>
          {serviceTypeName?.Videos !== +postServiceTypeValue && (
            <Box
              border={"1px solid #E0E0E0"}
              boxShadow={"0px 2px 4px rgba(0, 0, 0, 0.10)"}
              width={"40%"}
              padding={"0.8rem 0.5rem"}
              bgcolor={"#E0E0E0"}
            >
              <Box textAlign={"center"}>
                <Typography
                  variant="h6"
                  fontSize={"0.9rem"}
                  fontWeight={400}
                  color={"#09090A"}
                >
                  Add Images
                </Typography>
              </Box>

              <Box textAlign={"center"}>
                <Typography
                  variant="h6"
                  fontSize={"0.65rem"}
                  fontWeight={400}
                  color={"#8A8A8A"}
                >
                  Minimum 1 Maximum 5
                </Typography>
              </Box>
            </Box>
          )}

          {serviceTypeName?.Videos === +postServiceTypeValue && (
            <Box
              border={"1px solid #E0E0E0"}
              boxShadow={"0px 2px 4px rgba(0, 0, 0, 0.10)"}
              width={"40%"}
              padding={"0.8rem 0.5rem"}
              bgcolor={"#E0E0E0"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                variant="h6"
                fontSize={"0.9rem"}
                fontWeight={400}
                color={"#09090A"}
              >
                Add Video
              </Typography>
            </Box>
          )}

          {serviceTypeName?.Videos !== +postServiceTypeValue && (
            <Box
              border={"1px solid #E0E0E0"}
              boxShadow={"0px 2px 4px rgba(0, 0, 0, 0.10)"}
              width={"29%"}
              padding={"0.8rem 0.5rem"}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                serviceTypeName?.Videos !== +postServiceTypeValue &&
                  fileImageRef.current.click();
              }}
            >
              <input
                multiple
                type="file"
                accept="image/*"
                ref={fileImageRef}
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
              <Box textAlign={"center"}>
                <img src={filesvg} alt="logo" />
              </Box>
              <Box textAlign={"center"}>
                <Typography
                  variant="h6"
                  fontSize={"0.9rem"}
                  fontWeight={400}
                  color={"#09090A"}
                >
                  From Device
                </Typography>
              </Box>
            </Box>
          )}

          {serviceTypeName?.Videos !== +postServiceTypeValue && (
            <Box
              border={"1px solid #E0E0E0"}
              boxShadow={"0px 2px 4px rgba(0, 0, 0, 0.10)"}
              width={"29%"}
              padding={"0.8rem 0.5rem"}
              sx={{ cursor: "pointer" }}
              onClick={() => setGoogleDrawerOpen(true)}
            >
              <Box textAlign={"center"}>
                <img src={googlesvg} alt="logo" />
              </Box>
              <Box textAlign={"center"}>
                <Typography
                  variant="h6"
                  fontSize={"0.9rem"}
                  fontWeight={400}
                  color={"#09090A"}
                >
                  From Google
                </Typography>
              </Box>
            </Box>
          )}

          {serviceTypeName?.Videos === +postServiceTypeValue && (
            <Box
              border={"1px solid #E0E0E0"}
              boxShadow={"0px 2px 4px rgba(0, 0, 0, 0.10)"}
              width={"29%"}
              padding={"0.8rem 0.5rem"}
              sx={{ cursor: "pointer" }}
              onClick={() => setYoutubeDrawerOpen(true)}
            >
              <Box textAlign={"center"}>
                <img src={youtubeLogo} alt="logo" />
              </Box>
              <Box textAlign={"center"}>
                <Typography
                  variant="h6"
                  fontSize={"0.9rem"}
                  fontWeight={400}
                  color={"#09090A"}
                >
                  From YouTube
                </Typography>
              </Box>
            </Box>
          )}
        </Box>

        <Box mt={"0.5rem"}>
          {serviceTypeName?.Videos !== +postServiceTypeValue && (
            <SelectImage
              images={images}
              cancleSelectedImage={cancleSelectedImage}
            />
          )}
          {serviceTypeName?.Videos === +postServiceTypeValue && (
            <SelectVideos
              videos={videos}
              cancleSelectedVideo={cancleSelectedVideo}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CreatePostComponent;
