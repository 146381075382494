import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Stack, Link, LinearProgress } from "@mui/material";
import { classes } from "./MessageList";
import three_dots from "../../../assets/images/chats/three_dots.svg";
import starred_icon from "../../../assets/images/chats/starred_icon.svg";
import unstar_icon from "../../../assets/images/chats/unstar_icon.svg";
import replaym from "../../../assets/images/chats/reply.svg";
import pendingMsg_icon from "../../../assets/images/chats/pending_icon.svg";
import moment from "moment";
import { convertToSimpleTime } from "../../../utils/datePicker";
import TempMoreMenu from "./TempMoreMenu";
import { getFileIconUrl } from "../../../utils/findextension";
import {
  MessageType,
  priorityValueColor,
  priorityValueLable,
} from "../../../utils/constant";
import TaskSvg from "../../../common/SvgImageView/TaskSvg";
import ApprovalSvg from "../../../common/SvgImageView/ApprovalSvg";
import ProgressBar from "./ProgressBar";
const TempMessageView = ({
  item,
  threeDotRef,
  userData,
  handleSendTempMsg,
  selectedGroup,
  progress,
}) => {
  const [isMoreMenuOpen, setMoreMenu] = useState(null);
  const tempMenuRef = useRef(null);
  const groupTopicData = useSelector((state) => state?.topic?.groupTopicData);
  const handleClickOutside = (event) => {
    if (tempMenuRef.current && !tempMenuRef.current.contains(event.target)) {
      setMoreMenu(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const toggleItemMenu = (item) => {
    if (isMoreMenuOpen?.temp_timetoken === item?.temp_timetoken) {
      setMoreMenu(null);
    } else {
      setMoreMenu(item);
    }
  };
  return (
    <>
      {(item?.type === 0 ||
        item?.type === 3 ||
        item?.type === 1 ||
        item?.type === 2) && (
        <>
          <Stack
            direction="row"
            spacing={2}
            width={"100%"}
            padding={"0.8rem 0rem"}
            justifyContent={"space-between"}
            key={item?.temp_timetoken}
          >
            <div style={{ width: "100%" }}>
              <Stack width={"100%"} direction="row" spacing={1}>
                {/* ------------profile section--------------- */}
                {item?.from?.pp ? (
                  <Box sx={classes.chatUserprofile}>
                    <img src={item?.from?.pp} alt="profile" height="100%" />
                  </Box>
                ) : (
                  <Box
                    bgcolor={"#E3E1E1"}
                    width={"30px"}
                    height={"30px"}
                    display={"flex"}
                    flex={"none"}
                    alignItems={"center"}
                    flex-wrap={"wrap"}
                    justifyContent={"center"}
                    borderRadius={"0.15rem"}
                  >
                    <Typography variant="h6" fontSize={"0.75rem"}>
                      {item?.from?.name && item?.from?.name[0]}
                    </Typography>
                  </Box>
                )}

                {/* ------------Details section--------------- */}
                <Box width={"88%"}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2" fontWeight={"450"}>
                      {item?.from?.name}
                    </Typography>
                    <Typography sx={classes.duetime}>
                      {/* Time */}
                      {moment(item?.createdAt).format(
                        userData?.profileData?.data?.timeFormat === "24"
                          ? userData?.profileData?.data?.userDateTimeFormat
                          : userData?.profileData?.data?.userDateTimeFormat
                      )}
                    </Typography>
                    <Box sx={classes.tick}>
                      <img src={pendingMsg_icon} alt="Chat Status" />
                    </Box>
                  </Stack>

                  {selectedGroup?.isGroup && (
                    <Typography
                      variant="subtitle2"
                      display={"inline-block"}
                      sx={classes.userlink}
                    >
                      {item?.is_all_group_member
                        ? "To all group members"
                        : `To ${item?.to[0]?.name}${
                            item?.to?.length > 1
                              ? `, and ${item?.to?.length - 1} more`
                              : ""
                          }`}
                    </Typography>
                  )}

                  {/* -------------reply---------- */}
                  {/* <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems={"center"}
                                            sx={classes.chatRep}
                                          >
                                            <img src={replaym} />
                                            <Typography>
                                              Please prepare living room
                                              drawing...
                                            </Typography>
                                          </Stack> */}
                  {/* -------------reply---------- */}
                  {item?.thread_original_message &&
                    item?.thread_original_message?.translations && (
                      <Stack
                        width={"100%"}
                        direction="row"
                        spacing={1}
                        sx={classes.chatRep}
                      >
                        <img src={replaym} alt="reply" />
                        {item?.thread_original_message?.translations[
                          item?.to?.find(
                            (data) =>
                              data?.id === userData?.profileData?.data?.id
                          )?.languageCode
                        ] ? (
                          <Typography sx={{ wordWrap: "break-word" }}>
                            {item?.to?.findIndex(
                              (idData) =>
                                idData?.id === userData?.profileData?.data?.id
                            ) !== -1 &&
                              item?.thread_original_message?.translations[
                                item?.to?.find(
                                  (data) =>
                                    data?.id === userData?.profileData?.data?.id
                                )?.languageCode
                              ]?.slice(0, 200) + " ..."}
                          </Typography>
                        ) : (
                          <Typography sx={{ wordWrap: "break-word" }}>
                            {item?.to?.findIndex(
                              (idData) =>
                                idData?.id === userData?.profileData?.data?.id
                            ) !== -1 &&
                              item?.thread_original_message?.message?.slice(
                                0,
                                200
                              ) + " ..."}
                          </Typography>
                        )}
                      </Stack>
                    )}

                  <Typography
                    sx={{ wordWrap: "break-word" }}
                    variant="subtitle2"
                    width={"100%"}
                  >
                    {item?.message?.split(/\n/).map((line, indx) => (
                      <span
                        key={line + indx}
                        style={{
                          marginBottom: "0.5rem",
                          display: "block",
                        }}
                      >
                        {line}
                      </span>
                    ))}
                  </Typography>
                  {/* -----------------subUser details------------------ */}

                  {selectedGroup?.isGroup &&
                    item?.type !== 0 &&
                    item?.type != 3 && (
                      <>
                        <Stack
                          mt={"0.8rem"}
                          direction="row"
                          spacing={1}
                          gap={"2.5rem"}
                          width={"90%"}
                        >
                          <Stack direction="row" spacing={1}>
                            {item?.assignee?.pp ? (
                              <Box sx={classes.chatUserprofile}>
                                <img
                                  src={item?.assignee?.pp}
                                  alt="profile"
                                  height="100%"
                                />
                              </Box>
                            ) : (
                              <Box
                                bgcolor={"#E3E1E1"}
                                width={"25px"}
                                height={"25px"}
                                display={"flex"}
                                flex={"none"}
                                alignItems={"center"}
                                flex-wrap={"wrap"}
                                justifyContent={"center"}
                                borderRadius={"0.15rem"}
                              >
                                <Typography variant="h6" fontSize={"0.75rem"}>
                                  {item?.assignee?.name &&
                                    item?.assignee?.name[0]}
                                </Typography>
                              </Box>
                            )}
                            <Box>
                              <Box sx={classes.userInfo}>
                                <Stack direction="row" spacing={1}>
                                  <Typography
                                    variant="subtitle2"
                                    fontWeight={"400"}
                                    fontSize={"0.75rem"}
                                  >
                                    {item?.type === MessageType?.Approval
                                      ? "Approval"
                                      : "Task"}{" "}
                                    : {item?.assignee?.name}
                                  </Typography>
                                </Stack>
                                <Typography
                                  variant="subtitle2"
                                  lineHeight={"15px"}
                                  fontSize={"0.75rem"}
                                >
                                  {" "}
                                  Due on :{" "}
                                  {`${item?.due_date}, ${convertToSimpleTime(
                                    item?.due_time
                                  )}`}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography fontSize={"0.75rem"}>
                                  {item?.type === MessageType?.Task
                                    ? "Task"
                                    : "Approval"}{" "}
                                  Priority :
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: "13px",
                                      height: "13px",
                                      verticalAlign: "middle",
                                      margin: "-1px 5px 0px 5px",
                                      backgroundColor:
                                        priorityValueColor[item?.priority],
                                    }}
                                  ></span>
                                  {priorityValueLable[item?.priority]}
                                </Typography>
                              </Box>
                            </Box>
                          </Stack>

                          <Stack direction="row" spacing={1}>
                            {item?.type === MessageType?.Approval && (
                              <>
                                <Box
                                  display={"flex"}
                                  sx={classes.pendingT}
                                  color={"#000000"}
                                  bgcolor={"#F5E120"}
                                >
                                  <Box display={"flex"} alignItems={"center"}>
                                    <ApprovalSvg />
                                  </Box>
                                  <Typography sx={classes.txt}>
                                    {" "}
                                    "Pending"
                                  </Typography>
                                </Box>
                              </>
                            )}
                            {item?.type === MessageType?.Task && (
                              <>
                                <Box
                                  display={"flex"}
                                  sx={classes.pendingT}
                                  color={"#000000"}
                                  bgcolor={"#F5E120"}
                                >
                                  <Box display={"flex"} alignItems={"center"}>
                                    <TaskSvg />
                                  </Box>
                                  <Typography sx={classes.txt}>
                                    {" "}
                                    Pending{" "}
                                  </Typography>
                                </Box>
                              </>
                            )}

                            <Stack
                              direction="row"
                              sx={classes.pendingT}
                              color={"#fff"}
                              bgcolor={"#DB0011"}
                            >
                              <Typography sx={classes.txt}> Update </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </>
                    )}
                  {/* -----------------Attachments------------------ */}
                  {item?.attachments?.length > 0 && (
                    <Box mt={"0.5rem"}>
                      {item?.attachments?.map((fileData) => (
                        <>
                          <Link
                            href={fileData?.location}
                            underline="none"
                            target="_blank"
                            rel="noopener"
                            color={"black"}
                            key={fileData?.location}
                          >
                            {" "}
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              gap={"0.2rem"}
                              // bgcolor={"#F3F3F3"}
                            >
                              <img
                                style={{ height: "25px", width: "25px" }}
                                src={getFileIconUrl(fileData?.location)}
                                alt={fileData?.name}
                              />
                              <Typography
                                variant="subtitle2"
                                fontWeight={"400"}
                                fontSize={"0.75rem"}
                              >
                                {fileData?.name}
                              </Typography>
                            </Box>
                          </Link>
                        </>
                      ))}
                    </Box>
                  )}
                  {item?.fileData?.length > 0 && (
                    <Box>
                      {item?.fileData?.map((fileData, i) => (
                        <Box
                          key={fileData?.name + isMoreMenuOpen}
                          display={"flex"}
                          alignItems={"center"}
                          gap={"0.2rem"}
                          // bgcolor={"#F3F3F3"}
                        >
                          <img
                            style={{ height: "25px", width: "25px" }}
                            src={getFileIconUrl(fileData?.name)}
                            alt={fileData?.name}
                          />
                          <Typography
                            variant="subtitle2"
                            fontWeight={"400"}
                            fontSize={"0.75rem"}
                          >
                            {fileData?.name}
                          </Typography>
                        </Box>
                      ))}
                      {progress > 0 && <ProgressBar value={progress} />}
                    </Box>
                  )}

                  {/* Which Topic */}
                  {selectedGroup?.isGroup && (
                    <Stack direction="row" spacing={2} mt={"0.8rem"}>
                      <Box
                        display={"flex"}
                        gap={"0.2rem"}
                        alignItems={"center"}
                      >
                        <Typography fontSize={"0.75rem"}>Topic : </Typography>
                        <Typography
                          title={
                            groupTopicData?.data?.length > 0 &&
                            groupTopicData?.data?.find(
                              (data) => data?.id === item?.topic_id
                            )?.name
                          }
                          sx={{
                            ...classes.userlink2,
                            maxWidth: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {groupTopicData?.data?.length > 0 &&
                            (() => {
                              const name = groupTopicData?.data?.find(
                                (data) => data?.id === item?.topic_id
                              )?.name;
                              return name?.length > 80
                                ? name.substring(0, 80) + "..."
                                : name;
                            })()}
                        </Typography>{" "}
                      </Box>
                    </Stack>
                  )}
                </Box>
                {/* ------------Right section--------------- */}
                <Box>
                  <Stack spacing={2} direction="row">
                    <Box position={"relative"}>
                      <Box
                        ref={threeDotRef}
                        onClick={() => toggleItemMenu(item)}
                        sx={{
                          cursor: "pointer",
                          pointerEvents: isMoreMenuOpen ? "none" : "",
                        }}
                      >
                        <img src={three_dots} alt="more" />
                      </Box>
                      {isMoreMenuOpen?.temp_timetoken ===
                        item?.temp_timetoken && (
                        <TempMoreMenu
                          handleSendTempMsg={handleSendTempMsg}
                          menuRef={tempMenuRef}
                          messageData={item}
                          setMoreMenu={setMoreMenu}
                        />
                      )}
                    </Box>

                    {selectedGroup?.isGroup && (
                      <Box
                        sx={{
                          cursor: "pointer",
                          width: "16px",
                          height: "16px",
                        }}
                      >
                        <img
                          width={"100%"}
                          height={"100%"}
                          src={item?.isStarred ? starred_icon : unstar_icon}
                          alt="more"
                        />
                      </Box>
                    )}
                  </Stack>
                </Box>
                {/* ------------------------------------------ */}
              </Stack>
            </div>
          </Stack>
          <Box width={"85%"} margin={"auto"} border={"1px solid #ECECEC"}></Box>
        </>
      )}
    </>
  );
};

export default TempMessageView;
