import {
  Button,
  CircularProgress,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { FormControl } from "@mui/base";
import { AuthActionButton } from "../../common/StyledComponents/AuthComponents";
import profileBanner from "../../assets/images/Network.svg";
import profileimg from "../../assets/images/auth/profileimg.svg";
import companyLogo from "../../assets/images/auth/companyLogo.svg";
import smallCloseIcon from "../../assets/images/post/closeIcon.svg";
import CustomDrawer from "./CustomDrawer";
import { ArrowBackIosNew } from "@mui/icons-material";
import editLogo from "../../assets/images/auth/editLogo.svg";
import cameraLogo from "../../assets/images/auth/cameraLogo.svg";
import { ActionButton } from "../../common/chatComponents/ScreenSearchSection";
import { useDispatch } from "react-redux";
import {
  profileUpdate,
  updateFirmPic,
  updateProfilePic,
} from "../../appStore/actions/profile/action";
import { createSubscriber } from "../../appStore/actions/subscriber/action";
import { useNavigate } from "react-router-dom";
import right_arrow from "../../assets/images/auth/right_arrow.svg";
import { setCookie } from "../../config/cookie";
import { characterLimit } from "../../utils/constant";
import { showToastMsg } from "../../appStore/actions/global/action";

const classes = {
  blockInputBox: {
    width: "100%",
  },
  formBox: {
    maxHeight: "60%",
    height: "60%",
    maxWidth: "88%",
    margin: "auto",
    marginTop: "8%",
  },
  captions: {
    fontSize: "0.75rem",
    display: "block",
  },
  signInBox: {
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.09)",
    height: "calc(100vh - 4rem)",
    maxHeight: "40.7rem",
    minHeight: "28rem",
    width: "100%",
    maxWidth: "43rem",
    position: "relative",
    "@media (min-width: 901px) and (max-width: 1024px)": {
      height: "100%",
    },
    "@media (min-width: 1025px) and (max-width: 1300px)": {
      minHeight: "33rem",
    },
  },
  buttonStyle: {
    position: "absolute",
    bottom: "2%",
    left: "50%",
    // transform: "translateX(-50%)",
    marginLeft: "-2.72rem",
    "@media only screen and (max-width:899px)": {
      position: "relative",
      marginBottom: "-1.8rem",
      bottom: "0 !important",
    },
    "@media only screen and (max-width:1300px)": {
      bottom: "1.5rem",
    },
  },
};

const ProfileForm = ({ profileData }) => {
  const endpoint = window.location.pathname;
  const [isLoading, setLoading] = useState(false);
  const [userProfileData, setUserProfileData] = useState({
    city: "",
    website: "",
    subscriber_firm_name: "",
    lastName: "",
    firstName: "",
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [coverImage, setCoverImage] = useState({
    src: profileBanner,
    isCoverSelected: false,
    files: "",
  });
  const [profileImage, setProfileImage] = useState({
    src: endpoint === "/individualprofile" ? profileimg : companyLogo,
    isProfileSelected: false,
    files: "",
  });
  const [masterListData, setMasterListData] = useState({}); // for storing selected Country & Industry
  const [type, setType] = useState("industry");
  const [isRequiredEmpty, setIsRequiredEmpty] = useState({
    fName: false,
    lName: false,
    industry: false,
    country: false,
    organization: false,
    city: false,
  });
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const coverPicRef = useRef(null);
  const profilePicRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const invitationData =
    JSON.parse(localStorage.getItem("invitationData")) || false;
  useEffect(() => {
    setUserProfileData(profileData?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserProfileData({
      ...userProfileData,
      [name]: value?.trim(),
    });
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (event.target.name === "cover") {
        setCoverImage({
          ...coverImage,
          src: URL.createObjectURL(file),
          isCoverSelected: true,
          files: file,
        });
      } else {
        setProfileImage({
          ...profileImage,
          src: URL.createObjectURL(file),
          isProfileSelected: true,
          files: file,
        });
      }
    }
  };

  const handleRemovePic = (type) => {
    if (type === "cover") {
      setCoverImage({
        src: profileBanner,
        isCoverSelected: false,
        files: "",
      });
    } else {
      setProfileImage({
        src: endpoint === "/individualprofile" ? profileimg : companyLogo,
        isProfileSelected: false,
        files: "",
      });
    }
  };

  const handleButtonClick = (type) => {
    if (type === "cover") {
      coverPicRef.current.click();
    } else {
      profilePicRef.current.click();
    }
  };
  const handleDrawerOpen = (type) => {
    setIsDrawerOpen(true);
    setType(type);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  const handleOtherApi = async (resData) => {
    try {
      if (endpoint === "/individualprofile") {
        if (coverImage?.isCoverSelected) {
          const formData = new FormData();
          formData.append("files", coverImage?.files);
          formData.append("data", JSON.stringify({ is_cover_image: true }));
          dispatch(updateProfilePic(formData));
        }

        if (profileImage?.isProfileSelected) {
          const formData = new FormData();
          formData.append("files", profileImage?.files);
          formData.append("data", JSON.stringify({ is_cover_image: false }));
          dispatch(updateProfilePic(formData));
        }
      }

      const formData = {
        subscriber_account_type: "",
        subscriber_account_industry: masterListData?.industryName,
        subscriber_account_expertise: "",
        subscriber_priority: endpoint === "/individualprofile" ? "1" : "2",
        subscriber_firm_name: userProfileData?.subscriber_firm_name || "",
        job_title: "",
        website: userProfileData?.website || "",
        city: userProfileData?.city,
        country: masterListData?.countryName || userProfileData?.country,
        country_code:
          masterListData?.countryCode || userProfileData?.countryCode,
      };
      if (!resData?.data?.activeSubscriber?.id) {
        const createSubscriberResponse = await dispatch(
          createSubscriber(formData)
        );
        setCookie("subsCriber_id", createSubscriberResponse?.data?.id, 30);

        if (endpoint !== "/individualprofile") {
          if (coverImage?.isCoverSelected) {
            const formData = new FormData();
            formData.append("files", coverImage?.files);
            formData.append("data", JSON.stringify({ is_cover_image: true }));
            dispatch(
              updateFirmPic(formData, createSubscriberResponse?.data?.id)
            );
          }

          if (profileImage?.isProfileSelected) {
            const formData = new FormData();
            formData.append("files", profileImage?.files);
            formData.append("data", JSON.stringify({ is_cover_image: false }));
            dispatch(
              updateFirmPic(formData, createSubscriberResponse?.data?.id)
            );
          }
        }
        const invitationData =
          JSON.parse(localStorage.getItem("invitationData")) || false;
        if (createSubscriberResponse?.status && invitationData) {
          localStorage.setItem(
            "newProfile_subscriber_id",
            createSubscriberResponse?.data?.id
          );
          navigate("/invitations");
        } else if (createSubscriberResponse?.status) {
          navigate("/createfirstgroup");
        }
      } else if (resData?.data?.activeSubscriber?.id) {
        navigate("/chat");
      }
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (profileData?.data?.signedUpBy === "apple") {
      if (
        userProfileData?.city &&
        masterListData?.countryName &&
        userProfileData?.firstName &&
        userProfileData?.lastName &&
        (endpoint === "/individualprofile" ||
          (userProfileData?.subscriber_firm_name &&
            masterListData?.industryName))
      ) {
        let formData;
        formData = {
          date_format: "DD-MM-YY",
          time_format: "24",
          language_code: "en",
          language: "english",
          country: masterListData?.countryName,
          country_code: masterListData?.countryCode,
          first_name: userProfileData?.firstName,
          last_name: userProfileData?.lastName,
          city: userProfileData?.city,
          is_profile_journey_completed: true,
          communication_email: userProfileData?.email,
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          referred_by: JSON.parse(localStorage.getItem("referred_by")) || "",
        };
        try {
          let res = await dispatch(profileUpdate(formData));
          setLoading(false);
          if (res?.status) {
            localStorage.removeItem("referred_by");
            handleOtherApi(res);
          } else {
            setLoading(false);
            dispatch(showToastMsg(appContent?.wrongError));
          }
        } catch (error) {
          setLoading(false);
          dispatch(showToastMsg(appContent?.wrongError));
          // Handle errors here.
        }
      } else {
        const isRequiredValue = {
          ...isRequiredEmpty,
          city: !userProfileData?.city ? true : false,
          country: !masterListData?.countryName ? true : false,
          industry: !masterListData?.industryName ? true : false,
          organization: !userProfileData?.subscriber_firm_name ? true : false,
          lName: !userProfileData?.lastName ? true : false,
          fName: !userProfileData?.firstName ? true : false,
        };
        setIsRequiredEmpty(isRequiredValue);
      }
    } else {
      if (
        userProfileData?.city &&
        masterListData?.countryName &&
        (endpoint === "/individualprofile" ||
          (userProfileData?.subscriber_firm_name &&
            masterListData?.industryName))
      ) {
        let formData;
        formData = {
          date_format: "DD-MM-YY",
          time_format: "24",
          language_code: "en",
          language: "english",
          country: masterListData?.countryName,
          country_code: masterListData?.countryCode,
          city: userProfileData?.city,
          is_profile_journey_completed: true,
          communication_email: userProfileData?.email,
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          referred_by: JSON.parse(localStorage.getItem("referred_by")) || "",
        };
        try {
          let res = await dispatch(profileUpdate(formData));
          setLoading(false);
          if (res?.status) {
            localStorage.removeItem("referred_by");
            handleOtherApi(res);
          } else {
            setLoading(false);
            dispatch(showToastMsg(appContent?.wrongError));
          }
        } catch (error) {
          setLoading(false);
          dispatch(showToastMsg(appContent?.wrongError));
          // Handle errors here.
        }
      } else {
        const isRequiredValue = {
          ...isRequiredEmpty,
          city: !userProfileData?.city ? true : false,
          country: !masterListData?.countryName ? true : false,
          industry: !masterListData?.industryName ? true : false,
          organization: !userProfileData?.subscriber_firm_name ? true : false,
          lName: !userProfileData?.lastName ? true : false,
          fName: !userProfileData?.firstName ? true : false,
        };
        setIsRequiredEmpty(isRequiredValue);
      }
    }
  };
  const handleBack = () => {
    navigate("/profile");
  };
  return (
    <Box>
      <Box sx={classes.signInBox}>
        <>
          <Box
            maxHeight={"21%"}
            height="21%"
            width={"100%"}
            position={"relative"}
          >
            <Box height={"100%"} overflow={"hidden"}>
              <img
                src={
                  coverImage?.isCoverSelected
                    ? coverImage?.src
                    : userProfileData?.coverImgUrl
                    ? userProfileData?.coverImgUrl
                    : coverImage?.src
                }
                alt="name"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              {coverImage?.isCoverSelected && (
                <Box
                  sx={{ cursor: "pointer" }}
                  zIndex={100}
                  position={"absolute"}
                  top={"0.2rem"}
                  right={"0.2rem"}
                  onClick={() => handleRemovePic("cover")}
                >
                  <img src={smallCloseIcon} alt="close" />
                </Box>
              )}
            </Box>

            <Box
              display={"flex"}
              position={"absolute"}
              alignItems={"center"}
              right={"1%"}
              bottom={"2%"}
              gap={"0.5rem"}
            >
              <input
                type="file"
                name="cover"
                accept="image/*"
                ref={coverPicRef}
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
              <Box>
                <Typography variant="h6" fontSize={"0.85rem"} color={"#fff"}>
                  Optional
                </Typography>
              </Box>
              <ActionButton
                variant="contained"
                size="small"
                onClick={() => handleButtonClick("cover")}
              >
                <img src={editLogo} alt="edit" />
              </ActionButton>
            </Box>

            <Box
              bgcolor={"#183242"}
              position={"absolute"}
              left="50%"
              marginLeft={"-50px"}
              transform="translateX(-40%)"
              bottom="-30%"
              borderRadius={"0.2rem"}
              overflow={"hidden"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                position={"relative"}
                height={"6.25rem"}
                width={"6.25rem"}
                minHeight={"100%"}
                minWidth={"100%"}
                padding={!profileImage?.isProfileSelected ? "0.8rem" : "0rem"}
                bgcolor={"#EAEAEA"}
              >
                {endpoint === "/individualprofile" ? (
                  <>
                    <img
                      src={
                        profileImage?.isProfileSelected
                          ? profileImage?.src
                          : userProfileData?.profileImgUrl
                          ? userProfileData?.profileImgUrl
                          : profileImage?.src
                      }
                      alt="name"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "fill",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={
                        profileImage?.isProfileSelected
                          ? profileImage?.src
                          : userProfileData?.profileImgUrl
                          ? userProfileData?.profileImgUrl
                          : profileImage?.src
                      }
                      alt="name"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "fill",
                      }}
                    />
                  </>
                )}

                {profileImage?.isProfileSelected && (
                  <Box
                    sx={{ cursor: "pointer" }}
                    zIndex={100}
                    position={"absolute"}
                    top={"0.2rem"}
                    right={"0.2rem"}
                    onClick={() => handleRemovePic("profilepic")}
                  >
                    <img src={smallCloseIcon} alt="close" />
                  </Box>
                )}

                <Box position={"absolute"} right={"1%"} bottom={"1.5%"}>
                  <input
                    type="file"
                    accept="image/*"
                    ref={profilePicRef}
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                  />
                  <ActionButton
                    variant="contained"
                    size="small"
                    onClick={() => handleButtonClick("profile")}
                  >
                    <img src={cameraLogo} alt="edit" />
                  </ActionButton>
                </Box>
              </Box>
            </Box>

            <Box>
              <Button
                sx={{ textTransform: "none", fontSize: "1rem" }}
                variant="ghost"
                startIcon={<ArrowBackIosNew fontSize="small" />}
                onClick={handleBack}
                disabled={invitationData && +invitationData.inviteType === 1}
              >
                Update Profile
              </Button>
            </Box>
          </Box>
        </>

        <Box sx={classes?.formBox}>
          <CustomDrawer
            isDrawerOpen={isDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            type={type}
            setMasterListData={setMasterListData}
          />
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
            }}
            noValidate
          >
            <Box
              height={"100%"}
              overflow={"auto"}
              display={"flex"}
              flexDirection={"column"}
              sx={{
                "&::-webkit-scrollbar": {
                  width: "0px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "3px",
                },
              }}
            >
              {profileData?.data?.signedUpBy === "apple" && (
                <Box display={"flex"} alignItems="center" gap={"2%"}>
                  <Box width={"49%"}>
                    <FormControl>
                      <TextField
                        error={!isRequiredEmpty?.fName ? false : true}
                        type="text"
                        label={"First Name"}
                        variant="standard"
                        name="firstName"
                        onChange={(e) => {
                          const inputValue = e.target.value.trim();
                          const { name } = e.target;
                          if (/^[a-zA-Z]*$/.test(inputValue)) {
                            setUserProfileData({
                              ...userProfileData,
                              [name]: inputValue?.trim(),
                            });
                            setIsRequiredEmpty({
                              ...isRequiredEmpty,
                              lName: inputValue.length === 0 ? true : false,
                            });
                          }
                        }}
                        value={userProfileData?.firstName}
                        sx={classes.blockInputBox}
                        inputProps={{
                          maxLength: characterLimit?.firstName,
                        }}
                        required
                      />
                      <FormHelperText
                        error={!isRequiredEmpty?.fName ? false : true}
                      >
                        Required
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  <Box width={"49%"}>
                    <FormControl>
                      <TextField
                        type="text"
                        error={!isRequiredEmpty?.lName ? false : true}
                        label={"Lirst Name"}
                        variant="standard"
                        name="lastName"
                        value={userProfileData?.lastName}
                        onChange={(e) => {
                          const inputValue = e.target.value.trim();
                          const { name } = e.target;
                          if (/^[a-zA-Z]*$/.test(inputValue)) {
                            setUserProfileData({
                              ...userProfileData,
                              [name]: inputValue?.trim(),
                            });
                            setIsRequiredEmpty({
                              ...isRequiredEmpty,
                              lName: inputValue.length === 0 ? true : false,
                            });
                          }
                        }}
                        required
                        inputProps={{
                          maxLength: characterLimit?.lastName,
                        }}
                        sx={classes.blockInputBox}
                      />
                      <FormHelperText
                        error={!isRequiredEmpty?.lName ? false : true}
                      >
                        Required
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
              )}

              {endpoint === "/individualprofile" ? (
                <></>
              ) : (
                <Box flex={1}>
                  <FormControl>
                    <TextField
                      sx={{ fontSize: "0.75rem" }}
                      size="small"
                      type="text"
                      label={appContent?.organizationName}
                      value={userProfileData?.subscriber_firm_name || ""}
                      error={isRequiredEmpty?.organization ? true : false}
                      variant="standard"
                      name="subscriber_firm_name"
                      required
                      onChange={(e) => {
                        const { name, value } = e.target;
                        // Regular expression to match allowed characters (alphanumeric, "&", and ".")
                        const allowedCharactersRegex =
                          /^(?:[a-zA-Z&. ]*|[a-zA-Z&.]+)$/;
                        if (allowedCharactersRegex.test(value)) {
                          const { name, value } = e.target;
                          setUserProfileData({
                            ...userProfileData,
                            [name]: value,
                          });
                          setIsRequiredEmpty({
                            ...isRequiredEmpty,
                            organization: value?.length > 0 ? false : true,
                          });
                        }
                      }}
                      fullWidth
                      inputProps={{
                        maxLength: characterLimit?.organisationName,
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "0.85rem",
                        },
                      }}
                    />
                    <FormHelperText
                      error={isRequiredEmpty?.organization ? true : false}
                      sx={classes?.captions}
                    >
                      Required
                    </FormHelperText>
                  </FormControl>
                </Box>
              )}
              {/* <Box flex={1}>
                <FormControl>
                  <TextField
                  sx={{fontSize:"0.75rem"}}
                    size="small"
                    value={userProfileData?.email || ""}
                    onChange={handleChange}
                    type="email"
                    label={appContent?.communationEmail}
                    InputLabelProps={{
                      shrink: !!userProfileData?.email ? true : undefined,
                      style: {
                        fontSize: "0.85rem",
                      },
                    }}
                    variant="standard"
                    name="email"
                    required
                    fullWidth
                  />
                  <FormHelperText sx={classes?.captions}>
                    Email id to get notifications, task & approval reminders.
                  </FormHelperText>
                </FormControl>
              </Box> */}

              {endpoint === "/individualprofile" ? (
                <></>
              ) : (
                <Box flex={1}>
                  <FormControl>
                    <TextField
                      sx={{ fontSize: "0.75rem" }}
                      size="small"
                      type="url"
                      label={appContent?.website}
                      onChange={handleChange}
                      value={userProfileData?.website?.trim() || ""}
                      variant="standard"
                      name="website"
                      InputLabelProps={{
                        style: {
                          fontSize: "0.85rem",
                        },
                      }}
                      fullWidth
                    />
                    <FormHelperText sx={classes?.captions}>
                      Optional(highly recommended)
                    </FormHelperText>
                  </FormControl>
                </Box>
              )}
              <Box flex={endpoint === "/individualprofile" ? 0.2 : 1}>
                <FormControl>
                  <TextField
                    sx={{ fontSize: "0.75rem" }}
                    size="small"
                    label="Industry"
                    onFocus={() => handleDrawerOpen("industry")}
                    error={
                      isRequiredEmpty?.industry && !masterListData?.industryName
                    }
                    variant="standard"
                    value={
                      masterListData?.industryName ||
                      userProfileData?.activeSubscriber
                        ?.subscriberAccountIndustry ||
                      ""
                    }
                    name="industry"
                    fullWidth
                    required
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src={right_arrow} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink:
                        !!masterListData?.industryName ||
                        userProfileData?.activeSubscriber
                          ?.subscriberAccountIndustry
                          ? true
                          : undefined,
                      style: {
                        fontSize: "0.85rem",
                      },
                    }}
                  />
                  <FormHelperText
                    error={
                      isRequiredEmpty?.industry && !masterListData?.industryName
                    }
                    sx={classes?.captions}
                  >
                    Required
                  </FormHelperText>
                </FormControl>
              </Box>

              <Box flex={endpoint === "/individualprofile" ? 0.2 : 1}>
                <FormControl>
                  <TextField
                    sx={{ fontSize: "0.75rem" }}
                    size="small"
                    type="text"
                    label={appContent?.city}
                    variant="standard"
                    value={userProfileData?.city || ""}
                    error={isRequiredEmpty?.city ? true : false}
                    name="city"
                    onChange={(e) => {
                      const inputValue = e.target.value.trim();
                      const { name, value } = e.target;
                      // Regular expression to match only alphabetic characters

                      if (/^(?:[a-zA-Z][a-zA-Z\s]*)?$/.test(inputValue)) {
                        setUserProfileData({
                          ...userProfileData,
                          [name]: value,
                        });
                        setIsRequiredEmpty({
                          ...isRequiredEmpty,
                          city: value?.length > 0 ? false : true,
                        });
                      }
                    }}
                    required
                    InputLabelProps={{
                      shrink: !!userProfileData?.city ? true : undefined,
                      style: {
                        fontSize: "0.85rem",
                      },
                    }}
                    inputProps={{
                      maxLength: characterLimit?.city,
                    }}
                    fullWidth
                  />
                  <FormHelperText
                    error={isRequiredEmpty?.city ? true : false}
                    sx={classes?.captions}
                  >
                    Required
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box flex={endpoint === "/individualprofile" ? 0.2 : 1}>
                <FormControl>
                  <TextField
                    sx={{ fontSize: "0.75rem" }}
                    size="small"
                    value={
                      masterListData?.countryName ||
                      userProfileData?.country ||
                      ""
                    }
                    type="text"
                    error={
                      isRequiredEmpty?.country && !masterListData?.countryName
                    }
                    label={appContent?.country}
                    variant="standard"
                    name="country"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src={right_arrow} alt="" />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: !!(
                        masterListData?.countryName || userProfileData?.country
                      )
                        ? true
                        : undefined,
                      style: {
                        fontSize: "0.85rem",
                      },
                    }}
                    onFocus={() => handleDrawerOpen("country")}
                    required
                    fullWidth
                  />
                  <FormHelperText
                    error={
                      isRequiredEmpty?.country && !masterListData?.countryName
                    }
                    sx={classes?.captions}
                  >
                    Required
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box sx={classes.buttonStyle}>
                <AuthActionButton type="submit" variant="contained">
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    appContent?.finish
                  )}
                </AuthActionButton>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileForm;
