import React from "react";
import { CircularProgress, Box } from "@mui/material";

const LoadingSpinner = ({minHeight="400px"}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight={minHeight}
    >
      <CircularProgress sx={{ color: "#DB0011" }} />
    </Box>
  );
};

export default LoadingSpinner;
