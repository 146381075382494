import React from "react";
import { Box } from "@mui/material";
import BouncingDotsLoader from "../../../common/BouncingDotsLoader";
import aIbadgeIcon from "../../../assets/images/chats/aIbadgeIcon.svg";
import { createStyledIndicatorButton } from "../../../common/StyledComponents/SideBarComponents";

const AILoder = () => {
  const BadgeButton = createStyledIndicatorButton("#F3F3F3");
  return (
    <Box padding="15px 0px">
      <Box display={"flex"} maxWidth={"90%"}>
        <Box mr={"0.5rem"}>
          <BadgeButton
            size="small"
            variant="contained"
            sx={{ background: "transparent" }}
          ></BadgeButton>
        </Box>

        <Box>
          <Box
            bgcolor={"#F3F3F3"}
            width={"5rem"}
            display={"flex"}
            alignItems={"center"}
            gap={"0.2rem"}
            borderRadius={"0.52rem"}
            padding={"0.5rem"}
          >
            <Box>
              <img src={aIbadgeIcon} alt="ai" />
            </Box>
            <BouncingDotsLoader />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AILoder;
