import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import archive_icon from "../../../assets/images/task/archive_icon.svg";
import unArchive_icon from "../../../assets/images/task/unArchive_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  AccountLevelArkchatPermission,
  ApprovalStatus,
  MessageType,
  TaskStatus,
  getTaskStatusLabels,
  priorityValueColor,
  priorityValueLable,
  taskStatusColor,
} from "../../../utils/constant";
import TaskSvg from "../../../common/SvgImageView/TaskSvg";
import ApprovalSvg from "../../../common/SvgImageView/ApprovalSvg";
import { handleArchiveTask } from "../../../appStore/actions/ArkchatGroup/action";
import moment from "moment";
import {
  getUserProfileDetails,
  handleThreadScrollToBottom,
} from "../../../appStore/actions/AppState/action";
import { findUser, setProfileData } from "../../../utils/helper";
import ProfileDetailModal from "../../../common/ProfileDetailModal";
import StartTask from "../MessageComponent/StartTask";
import { useTranslation } from "react-i18next";

const classes = {
  chatUserprofile: {
    bgcolor: "#E3E1E1",

    width: "30px",
    minWidth: "30px",
    height: "30px",
    display: "flex",
    display: "-webkit-box",
    display: "-ms-flexbox",
    display: "-webkit-flex,",

    alignContent: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    WebkitBoxPack: "center",
    msFlexPack: "center",
    borderRadius: "0.15rem",
    fontWeight: "500",

    img: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
      borderRadius: "3px",
    },
  },

  userlink: {
    color: "#2C86FF",
    lineHeight: "15px",
    fontSize: "13px",
    fontWeight: "450",
  },
  duetime: {
    color: "#000",
    opacity: "80%",
    fontSize: "13px",
    paddingTop: "1px",
  },
  userlink2: {
    color: "#2C86FF",
    display: "inline-block",
    fontSize: "14px",
  },
  userInfo: {
    marginTop: "-5px !important",
    width: "100%",
  },
  tick: {
    objectFit: "cover",
    maxWidth: "17px",
    maxHeight: "6px",
    img: {
      width: "100%",
    },
  },
  pendingT: {
    borderRadius: "4px",
    padding: "5px 7px",
    textAlign: "center",
    maxHeight: "1.5rem",
    // maxWidth: "90px",
    justifyContent: "center",
    alignItems: "centre",
    whiteSpace: "nowrap",
  },

  txt: {
    fontSize: "11px !important;",
    marginLeft: "2px !important;",
    whiteSpace: "nowrap",
  },
  chatpri: {
    background: "#E3E1E1",
    width: "fit-content",
    padding: "4px 10px",
    borderRadius: "3px",
    textAlign: "center",
    lineHeight: "14px",
    fontSize: "11px",
    whiteSpace: "nowrap",
  },
  archive: {
    border: "1px solid #000000",
    borderRadius: "3px",
    cursor: "pointer",
    img: {
      width: "1.4rem",
      height: "1.4rem",
      padding: "3px",
    },
  },
};

const TaskMessage = ({
  taskItem,
  startTaskOpen,
  setStartTaskOpen,
  handleStartTask,
  indx,
  handleClickTaskUpdate,
  handleViewThreadClick,
  tabValue,
  taskTabValue,
}) => {
  const { t } = useTranslation();
  const appConstant = t("AppConstant", { returnObjects: true });
  const taskStatusLabels = getTaskStatusLabels(appConstant);
  const [profileViewData, setProfileViewData] = useState("");
  const [isProfileViewOpen, setProfileViewModal] = useState(false);
  const groupmemberdetails = useSelector(
    (state) => state?.arkchatgroup?.groupmember
  );
  const groupList = useSelector((state) => state?.arkchatgroup?.groupList);
  const userData = useSelector((state) => state?.profile);
  const dispatch = useDispatch();

  const handleShowUserProfile = (userToFindData) => {
    let data = findUser(groupmemberdetails, userToFindData);

    if (!data) {
      setProfileViewModal(true);
      dispatch(getUserProfileDetails(userToFindData)).then((res) => {
        data = res;
        setProfileData(data, setProfileViewData, setProfileViewModal);
      });
    } else {
      setProfileData(data, setProfileViewData, setProfileViewModal);
    }
  };

  // const handleShowUserProfile = (userToFindData) => {
  //   const data = findUser(groupmemberdetails, userToFindData);
  //   const viewData = {
  //     companyName: data?.userData?.subscriberFirmName,
  //     userName: `${data?.userInfo?.firstName} ${data?.userInfo?.lastName}`,
  //     indusrtyName: data?.userData?.subscriberAccountIndustry,
  //     cityName: data?.userData?.city,
  //     countryName: data?.userData?.country,
  //     profileBrief: data?.userInfo?.profileBrief,
  //     profileImgUrl: data?.userInfo?.profileImgUrl,
  //     coverImgUrl: data?.userInfo?.coverImgUrl,
  //     isGroup: false,
  //     website: data?.userInfo?.website,
  //     linkedin: data?.userInfo?.linkedin,
  //   };
  //   setProfileViewData(viewData);
  //   setProfileViewModal(true);
  // };
  return (
    <>
      <ProfileDetailModal
        setProfileViewModal={setProfileViewModal}
        isProfileViewOpen={isProfileViewOpen}
        profileViewData={profileViewData}
        setProfileViewData={setProfileViewData}
      />
      <Box padding="0px 20px 35px" key={taskItem?.id + "TaskMessagecond"}>
        {/* -----------------subUser details------------------ */}
        <Stack
          direction="row"
          spacing={1}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
        >
          <Stack
            mt={"0.5rem"}
            width={"100%"}
            direction="row"
            spacing={1}
            gap={"0 5px"}
          >
            <Box sx={classes.userInfo}>
              <Box display={"flex"} alignItems={"center"} gap={"0.5rem"}>
                {taskItem?.assignee?.pp ? (
                  <Box
                    onClick={() =>
                      handleShowUserProfile(taskItem?.assignee?.id)
                    }
                    sx={{ ...classes.chatUserprofile, cursor: "pointer" }}
                  >
                    <img
                      src={taskItem?.assignee?.pp}
                      alt="profile"
                      height="100%"
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{ cursor: "pointer" }}
                    bgcolor={"#E3E1E1"}
                    width={"30px"}
                    height={"30px"}
                    display={"flex"}
                    flex={"none"}
                    alignItems={"center"}
                    flex-wrap={"wrap"}
                    justifyContent={"center"}
                    borderRadius={"0.15rem"}
                    onClick={() =>
                      handleShowUserProfile(taskItem?.assignee?.id)
                    }
                  >
                    <Typography variant="h6" fontSize={"0.75rem"}>
                      {taskItem?.assignee?.name && taskItem?.assignee?.name[0]}
                    </Typography>
                  </Box>
                )}
                <Stack alignItems={"center"} direction="row" spacing={1}>
                  <Typography
                    variant="subtitle2"
                    fontWeight={500}
                    fontSize={"0.87rem"}
                  >
                    {taskItem?.type === MessageType?.Task
                      ? "Assignee"
                      : "Approver"}{" "}
                    : {taskItem?.assignee?.name}
                  </Typography>
                  <Typography color={"#000"} fontSize={"0.8rem"}>
                    {/* Time */}
                    {moment(Math.floor(taskItem?.timetoken / 10000)).format(
                      userData?.profileData?.data?.userDateTimeFormat
                    )}
                  </Typography>
                </Stack>
              </Box>

              <Box
                width={"96.2%"}
                ml={"auto"}
                sx={{
                  "@media (max-width: 1194px)": {
                    width: "95.5%",
                  },
                }}
              >
                <Typography
                  variant="subtitle2"
                  fontWeight={400}
                  fontSize={"0.87rem"}
                >
                  {userData?.profileData?.data?.activePermission ===
                  AccountLevelArkchatPermission?.ADMIN ? (
                    <>{taskItem?.message}</>
                  ) : (
                    <>
                      {taskItem?.to?.findIndex(
                        (idData) =>
                          idData?.id === userData?.profileData?.data?.id
                      ) !== -1 &&
                        taskItem?.translations[
                          taskItem?.to?.find(
                            (data) =>
                              data?.id === userData?.profileData?.data?.id
                          )?.languageCode
                        ]}
                    </>
                  )}
                </Typography>

                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  mt={"0.5rem"}
                >
                  <Typography variant="subtitle2" lineHeight={"25px"}>
                    {" "}
                    Due on :{" "}
                    {moment(taskItem?.due_date).format(
                      userData?.profileData?.data?.userDateFormat
                    )}{" "}
                    {moment(taskItem?.due_time, "HH:mm").format(
                      userData?.profileData?.data?.userTimeFormat
                    )}
                  </Typography>

                  <Stack direction="row" spacing={1}>
                    <Typography
                      sx={classes.chatpri}
                      border={"1px solid transparent"}
                    >
                      Priority :
                      <span
                        style={{
                          display: "inline-block",
                          width: "13px",
                          height: "13px",
                          verticalAlign: "middle",
                          margin: "-1px 5px 0px 5px",
                          backgroundColor:
                            priorityValueColor[taskItem?.priority],
                        }}
                      ></span>
                      {priorityValueLable[taskItem?.priority]}
                    </Typography>

                    {taskItem?.type === MessageType?.Task && (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        sx={classes.pendingT}
                        color={
                          taskItem?.task_status === TaskStatus?.Pending
                            ? "#000000"
                            : "#fff"
                        }
                        bgcolor={taskStatusColor[taskItem?.task_status]}
                      >
                        <Box display={"flex"} alignItems={"center"}>
                          <TaskSvg
                            circleColor={
                              taskItem?.task_status === TaskStatus?.Pending
                                ? null
                                : "#fff"
                            }
                            tickColor={
                              taskItem?.task_status === TaskStatus?.Pending
                                ? null
                                : "#fff"
                            }
                          />
                        </Box>
                        <Typography sx={classes.txt}>
                          {" "}
                          {taskStatusLabels[taskItem?.task_status]}
                        </Typography>
                      </Box>
                    )}
                    {taskItem?.type === MessageType?.Approval && (
                      <>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          sx={classes.pendingT}
                          color={
                            taskItem?.approval_status ===
                            ApprovalStatus?.Pending
                              ? "#000000"
                              : "#fff"
                          }
                          bgcolor={
                            taskItem?.approval_status ===
                            ApprovalStatus?.Approved
                              ? "#007F8F"
                              : taskItem?.approval_status ===
                                ApprovalStatus?.Rejected
                              ? "#4F63AF"
                              : taskItem?.approval_status ===
                                ApprovalStatus?.Pending
                              ? "#F5E120"
                              : "#FF4450"
                          }
                        >
                          <Box display={"flex"} alignItems={"center"}>
                            <ApprovalSvg
                              circleColor={
                                taskItem?.approval_status ===
                                ApprovalStatus?.Pending
                                  ? null
                                  : "#fff"
                              }
                              tickColor={
                                taskItem?.approval_status ===
                                ApprovalStatus?.Pending
                                  ? null
                                  : "#fff"
                              }
                            />
                          </Box>
                          <Typography sx={classes.txt}>
                            {" "}
                            {taskItem?.approval_status ===
                            ApprovalStatus?.Approved
                              ? "Approved"
                              : taskItem?.approval_status ===
                                ApprovalStatus?.Rejected
                              ? "Rejected"
                              : taskItem?.approval_status ===
                                ApprovalStatus?.Pending
                              ? "Pending"
                              : "Delayed"}{" "}
                          </Typography>
                        </Box>
                      </>
                    )}
                    {taskItem?.type === MessageType?.Task &&
                      taskItem?.assignee?.id ===
                        userData?.profileData?.data?.id &&
                      taskItem?.task_status === TaskStatus?.Pending && (
                        <Stack
                          direction="row"
                          position={"relative"}
                          sx={{
                            ...classes.pendingT,
                            cursor: "pointer",
                          }}
                          color={"#fff"}
                          bgcolor={"#DB0011"}
                          onClick={() => handleStartTask(taskItem)}
                        >
                          {startTaskOpen?.timetoken === taskItem?.timetoken && (
                            <StartTask
                              open={
                                startTaskOpen?.timetoken === taskItem?.timetoken
                              }
                              taskData={taskItem}
                              setStartTaskOpen={setStartTaskOpen}
                            />
                          )}
                          <Box display={"flex"} alignItems={"center"}>
                            <TaskSvg circleColor={"#fff"} tickColor={"#fff"} />
                          </Box>
                          <Typography sx={classes.txt}> Start </Typography>
                        </Stack>
                      )}
                    {taskItem?.type === MessageType?.Task &&
                      ((taskItem?.from?.id ===
                        userData?.profileData?.data?.id &&
                        taskItem?.task_status !== TaskStatus?.Cancelled) ||
                        (taskItem?.assignee?.id ===
                          userData?.profileData?.data?.id &&
                          taskItem?.task_status === TaskStatus?.Started &&
                          taskItem?.task_status !== TaskStatus?.Done &&
                          taskItem?.task_status !== TaskStatus?.Cancelled)) && (
                        <Stack
                          direction="row"
                          alignItems={"center"}
                          sx={{ ...classes.pendingT, cursor: "pointer" }}
                          color={"#fff"}
                          bgcolor={"#DB0011"}
                          onClick={() => handleClickTaskUpdate(taskItem)}
                        >
                          <Typography sx={classes.txt}> Update </Typography>
                        </Stack>
                      )}
                    {/* for approval */}
                    {taskItem?.type === MessageType?.Approval &&
                      (taskItem?.from?.id === userData?.profileData?.data?.id ||
                        taskItem?.assignee?.id ===
                          userData?.profileData?.data?.id) &&
                      taskItem?.approval_status !== ApprovalStatus?.Approved &&
                      taskItem?.approval_status !== ApprovalStatus?.Rejected &&
                      taskItem?.approval_status !==
                        ApprovalStatus?.Cancelled && (
                        <Stack
                          direction="row"
                          alignItems={"center"}
                          sx={{ ...classes.pendingT, cursor: "pointer" }}
                          color={"#fff"}
                          bgcolor={"#DB0011"}
                          onClick={() => handleClickTaskUpdate(taskItem)}
                        >
                          <Typography sx={classes.txt}> Update </Typography>
                        </Stack>
                      )}

                    <Stack
                      direction="row"
                      alignItems={"center"}
                      sx={classes.archive}
                      onClick={() =>
                        dispatch(
                          handleArchiveTask(taskItem?.id, taskItem?.type)
                        )
                      }
                    >
                      <img
                        src={taskTabValue === 4 ? unArchive_icon : archive_icon}
                        alt="archive"
                      />
                    </Stack>
                  </Stack>
                </Box>
                <Box>
                  <Box display={"flex"} gap={"0.8rem"}>
                    <Typography fontSize={"0.75rem"}>
                      Assigned :{" "}
                      {moment(Math.floor(taskItem?.timetoken / 10000)).format(
                        userData?.profileData?.data?.userDateFormat
                      )}
                    </Typography>

                    {taskItem?.start_time > 0 && (
                      <Typography fontSize={"0.75rem"}>
                        Started :{" "}
                        {moment(taskItem?.start_time * 10000).format(
                          userData?.profileData?.data?.userDateFormat
                        )}
                      </Typography>
                    )}
                    {taskItem?.end_time > 0 && (
                      <Typography fontSize={"0.75rem"}>
                        Finished :{" "}
                        {moment(taskItem?.end_time * 10000).format(
                          userData?.profileData?.data?.userDateFormat
                        )}
                      </Typography>
                    )}
                  </Box>

                  {taskItem?.estimated_time?.timeValue && (
                    <Box display={"flex"} gap={"0.8rem"}>
                      <Typography fontSize={"0.75rem"}>
                        Estimated time : {taskItem?.estimated_time?.timeValue}{" "}
                        {taskItem?.estimated_time?.timeType}
                      </Typography>

                      {taskItem?.actual_time?.timeValue && (
                        <Typography fontSize={"0.75rem"}>
                          Actual Time : {taskItem?.actual_time?.timeValue}{" "}
                          {taskItem?.actual_time?.timeType}
                        </Typography>
                      )}
                      {taskItem?.diff_time?.timeValue && (
                        <Typography
                          color={
                            taskItem?.actual_time?.timeValue <
                            taskItem?.estimated_time?.timeValue
                              ? "#007F8F"
                              : "#DB0011"
                          }
                          fontSize={"0.75rem"}
                        >
                          {Math.abs(taskItem?.diff_time?.timeValue)}{" "}
                          {taskItem?.diff_time?.timeType}{" "}
                          {taskItem?.actual_time?.timeValue <
                          taskItem?.estimated_time?.timeValue
                            ? "Less"
                            : "More"}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>

                <Box
                  mt={"0.8rem"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"0.5rem"}
                >
                  {taskItem?.from?.pp ? (
                    <Box
                      onClick={() => handleShowUserProfile(taskItem?.from?.id)}
                      sx={{ ...classes.chatUserprofile, cursor: "pointer" }}
                    >
                      <img
                        src={taskItem?.from?.pp}
                        alt="profile"
                        height="100%"
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{ cursor: "pointer" }}
                      bgcolor={"#E3E1E1"}
                      width={"27px"}
                      height={"27px"}
                      display={"flex"}
                      flex={"none"}
                      alignItems={"center"}
                      flex-wrap={"wrap"}
                      justifyContent={"center"}
                      borderRadius={"0.15rem"}
                      onClick={() => handleShowUserProfile(taskItem?.from?.id)}
                    >
                      <Typography variant="h6" fontSize={"0.75rem"}>
                        {taskItem?.from?.name && taskItem?.from?.name[0]}
                      </Typography>
                    </Box>
                  )}
                  <Box display={"flex"} alignItems={"center"} gap={"0.2rem"}>
                    <Typography
                      fontSize={"0.87rem"}
                      color={"#474747"}
                      fontWeight={500}
                    >
                      {taskItem?.type === MessageType?.Task
                        ? "Assigner"
                        : "Approval Seeker"}{" "}
                      :{" "}
                    </Typography>
                    <Typography fontSize={"0.87rem"} fontWeight={400}>
                      {taskItem?.from?.name}
                    </Typography>
                  </Box>
                </Box>

                <Box width={"95.5%"} ml={"auto"}>
                  <Typography
                    // mt={"0.8rem"}
                    fontSize={"0.87rem"}
                    fontWeight={400}
                    variant="subtitle2"
                  >
                    Group :{" "}
                    {
                      groupList?.find((grp) => grp?.pnId === taskItem?.pnId)
                        ?.name
                    }
                  </Typography>
                  <Typography
                    fontSize={"0.75rem"}
                    fontWeight={400}
                    variant="subtitle2"
                    color={"#2C86FF"}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(handleThreadScrollToBottom(taskItem?.thread_id));
                      handleViewThreadClick(taskItem, tabValue);
                    }}
                  >
                    View message thread
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Stack>
        {/* -----------------subUser details------------------ */}
      </Box>
    </>
  );
};

export default TaskMessage;
