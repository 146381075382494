import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";
import { CloseButton, CloseIcon } from "../page/Profille/CustomDrawer";
import closeIcon from "../assets/images/chats/closeLogo.svg";
import { MessageType } from "../utils/constant";
const PopupModal = ({
  title,
  content,
  setPopupModalOpen,
  setMessageType,
  msgType,
  isChangeMsgType=false,
  isInvitationPop=false,
  handleDoneMsgTypeChange
}) => {
  const navigate=useNavigate()
  const handleClose = () => {
    setPopupModalOpen(false);
    if(isInvitationPop){
      navigate("/chat")
    }
  };
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      bgcolor="rgba(0, 0, 0, 0.7)"
      zIndex={1202}
    >
      <Box
        zIndex={10}
        position="absolute"
        bgcolor="#FFFFFF"
        width={"28.5rem"}
        p={"2rem 1.2rem"}
        top="27%"
        left="38%"
        transform={`translate(-50%, -50%)`}
        boxShadow={`0px 3.4402036666870117px 3.4402036666870117px 0px rgba(0, 0, 0, 0.25)`}
        borderRadius={"0.12rem"}
      >
        <Box display={"flex"} gap={"0.6rem"}>
          <Box>
            <CloseButton onClick={handleClose}>
              <CloseIcon src={closeIcon} alt="close" />
            </CloseButton>
          </Box>
          <Box>
            <Typography fontSize={"1rem"} variant="h6">
              {title}
            </Typography>
          </Box>
        </Box>

        <Box width={"81.8%"} margin={"auto"}>
          <Box mt={"0.7rem"}>
            <Typography
              fontWeight={"400"}
              fontSize={"0.87rem"}
              variant="h6"
              color={"#183242"}
            >
              {content}
            </Typography>
            {isChangeMsgType&& (
              <>
                <Box display={"flex"} justifyContent={"center"}>
                  <Button
                    onClick={() => setMessageType(MessageType?.Task)}
                    variant="outlined"
                    sx={{
                      fontSize: "14px",
                      borderRadius: "3px",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                      padding: "4px",
                      textTransform: "none",
                      backgroundColor:
                        msgType === MessageType?.Task ? "#183243" : "#D9D9D9",
                      color: msgType === MessageType?.Task ? "#fff" : "#183243",
                      border: "0px",
                      ":hover": {
                        border: "0px",
                        backgroundColor:
                          msgType === MessageType?.Task ? "#183243" : "#D9D9D9",
                        color:
                          msgType === MessageType?.Task ? "#fff" : "#183243",
                        borderRadius: "3px",
                        borderColor: "#183243",
                      },
                    }}
                  >
                    Task
                  </Button>
                  <Button
                    onClick={() => setMessageType(MessageType?.Approval)}
                    variant="outlined"
                    sx={{
                      fontSize: "14px",
                      borderRadius: "3px",
                      borderTopLeftRadius: "0px",
                      borderBottomLeftRadius: "0px",
                      padding: "4px 8px",
                      textTransform: "none",
                      backgroundColor:
                        msgType === MessageType?.Approval
                          ? "#183243"
                          : "#D9D9D9",
                      color:
                        msgType === MessageType?.Approval ? "#fff" : "#183243",
                      border: "0px",
                      ":hover": {
                        border: "0px",
                        backgroundColor:
                          msgType === MessageType?.Approval
                            ? "#183243"
                            : "#D9D9D9",
                        color:
                          msgType === MessageType?.Approval
                            ? "#fff"
                            : "#183243",
                        borderRadius: "3px",
                        borderColor: "#183243",
                      },
                    }}
                  >
                    Approval
                  </Button>
                </Box>

                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  gap={"0.2rem"}
                  mt={"1.4rem"}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      background: "#fff",
                      color: "#000000",
                      borderRadius: "2px",
                      cursor: "pointer",
                      borderColor: "#DB0011",
                    }}
                    onClick={() => {
                      setMessageType(MessageType?.Message);
                      setPopupModalOpen(false);
                    }}
                  >
                    Cancle
                  </Button>
                  <Button
                    sx={{
                      textTransform: "none",
                      background: "#DB0011",
                      color: "white",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    variant="contained"
                    onClick={handleDoneMsgTypeChange}
                  >
                    Done
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PopupModal;
