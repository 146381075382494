import axios from "axios";
import { RESETOLDPASSWORD_API_URL, RESETPASSWORD_API_URL, RESETPASSWORD_DONE } from ".";
import { REQUEST_FAILED } from "../../global/type";
import { showToastMsg } from "../../global/action";
import { getCookie } from "../../../../config/cookie";

const headerRequest = () => {
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    };
  };

//  action for the resetPassword
export const resetPassword = (formData)=>{
    let API_URL = RESETPASSWORD_API_URL;
    return (dispatch) => {
       return axios.post(API_URL,formData).then((res)=>{
            if(res?.status == 200){
                dispatch({
                    type: RESETPASSWORD_DONE,
                    payload: res?.data
                })
                return res?.data
            }else{
                dispatch({
                    type:RESETPASSWORD_DONE,
                    payload: res
                })
                return res?.data
            }
        }).catch((err)=>{
            dispatch({ type: REQUEST_FAILED, payload: err?.response });
          })
    }
}


//  I didn't thought too much about naming but yes this is resetpassword fun()
export const resetOldPassword = (formData)=>{
    let API_URL = RESETOLDPASSWORD_API_URL;
    return (dispatch) => {
       return axios.post(API_URL,formData,headerRequest()).then((res)=>{
            dispatch(showToastMsg(res?.data?.message||res?.message))
        }).catch((err)=>{
            dispatch({ type: REQUEST_FAILED, payload: err?.response });
          })
    }
}