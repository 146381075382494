/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CarouselComponent from "./page/auth/Carousel/Carousel";
import { createStyledIndicatorButton } from "./common/StyledComponents/SideBarComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  createRetriveInvite,
  handleAcceptIgnoreInvite,
} from "./appStore/actions/subscriber/action";
import { useNavigate } from "react-router-dom";
import { getProfile, profileUpdate } from "./appStore/actions/profile/action";
import { requestallmemberswithgroups } from "./appStore/actions/ArkchatGroup/action";
import { setCookie } from "./config/cookie";
import { InviteType, accountType } from "./utils/constant";
import PopupModal from "./common/PopupModal";

const classes = {
  signBoxWrapper: {
    display: "grid",
    alignItems: "center",
    "@media only screen and (max-width:899px)": {
      marginBottom: "25px",
    },
    "@media only screen and (min-width:1199px)": {
      paddingRight: "20px",
    },
  },
  signInBox: {
    padding: "1.5rem 1rem 2rem 2rem",
    border: "1px solid #C4C4C4",
    height: "calc(100vh - 32px)",
    maxHeight: "700px",
    minHeight: "590px",
    width: "100%",
    maxWidth: "690px",
    margin: "auto",
    position: "relative",
    "@media only screen and (max-width:900px)": {
      margin: "auto !important",
      padding: "1.5rem 2rem 2rem 2rem !important",
    },
    "@media only screen and (max-width:1024px)": {
      height: "100%",
      padding: "1rem",
      marginLeft: "-20px",
    },
    "@media only screen and (max-width:1300px)": {
      minHeight: "530px",
    },
  },
  captions: {
    fontSize: "0.87rem !important",
    display: "block",
  },
  ignorebtn: {
    color: "#183243",
    padding: "0.2rem 1rem",
    borderRadius: "0.2rem",
    bgcolor: "white",
    border: "1px solid #DB0011",
    boxShadow: "none",
    textTransform: "none",
    ":hover": {
      color: "#183243",
      padding: "0.2rem 1rem",
      borderRadius: "0.2rem",
      bgcolor: "white",
    },
  },
  acceptbtn: {
    color: "white",
    padding: "0.2rem 1rem",
    borderRadius: "0.2rem",
    bgcolor: "#DB0011",
    border: "1px solid #DB0011",
    boxShadow: "none",
    textTransform: "none",
    ":hover": {
      color: "white",
      padding: "0.2rem 1rem",
      borderRadius: "0.2rem",
      bgcolor: "#DB0011",
    },
  },
  radioStyle: {
    color: "#092C4C",
    "&.Mui-checked": {
      color: "#092C4C",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
    },
  },
  mobscroll: {
    overflowX: "hidden",
    "@media only screen and (max-width:899px)": {
      overflowX: "hidden",
    },
  },
};
const InvitationPage = () => {
  const [isLoading, setLoading] = useState(false);
  const [invitationData, setInvitationData] = useState(
    JSON.parse(localStorage.getItem("invitationData")) || false
  );
  const invitationRetrivedData = useSelector(
    (state) => state?.subscriber?.invitationRetrivedData
  );
  const [selectedAccount, setSelectedAccount] = useState();
  const [isPopupModalOpen, setPopupModalOpen] = useState(false);
  const NameBadge = createStyledIndicatorButton("#E3E1E1");
  const userData = useSelector((state) => state?.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const reqBody = {
      invite_token: invitationData?.token,
      invite_code: invitationData?.inviteCode,
      invite_name: invitationData?.inviteName,
      invite_type: invitationData?.inviteType,
      target_subscriber_id:
        localStorage.getItem("newProfile_subscriber_id") ||
        userData?.profileData?.data?.subscribedTo[0]?.id,
    };
    if (+invitationData?.inviteType === InviteType?.external) {
      localStorage.setItem(
        "inviteName",
        JSON.stringify(invitationData?.inviteName)
      );
    }
    dispatch(createRetriveInvite(reqBody)).then((res) => {      
      if (!res?.status) {
        setPopupModalOpen(true);
       }
       //else{
      //   dispatch(showToastMsg(res?.message))
      // }
    });
  }, []);

  useEffect(() => {
    if (invitationRetrivedData?.accountOwned?.length > 0) {
      setSelectedAccount(invitationRetrivedData?.accountOwned[0]?.id);
    }
  }, [invitationRetrivedData?.accountOwned?.length]);

  const handleSkip = async () => {
    const userData = await dispatch(getProfile(true));
    localStorage.removeItem("invitationData");
    localStorage.removeItem("newProfile_subscriber_id");
    if (userData?.data?.data?.accountOwner) {
      if (
        userData?.data?.data?.isFirstGroupCreated &&
        userData?.data?.data?.isFirstInviteSent
      ) {
        navigate("/chat");
      } else if (
        userData?.data?.data?.isFirstGroupCreated &&
        !userData?.data?.data?.isFirstInviteSent
      ) {
        navigate("/invite");
      } else {
        navigate("/createfirstgroup");
      }
    } else {
      navigate("/chat");
    }
  };

  const handleAction = (status, data) => {
    setLoading(true);
    // accountType?.Individual because for internal invite user can only join with there individual accoutn.
    const subsCriber_id = invitationRetrivedData?.accountOwned?.find(
      (item) => item?.subscriberPriority === accountType?.Individual
    );
    // 1=accept;
    // 2=ignore;
    if (data?.type === InviteType?.internal) {
      const reqBody = {
        status,
        target_subscriber_id: subsCriber_id?.id,
      };
      dispatch(handleAcceptIgnoreInvite(reqBody, data?.id)).then(
        async (res) => {
          setLoading(false);
          if (res?.status&& status === 1) {
            localStorage.removeItem("invitationData");
            localStorage.removeItem("newProfile_subscriber_id");
            setCookie("subsCriber_id", res?.data?.id, 7);
            dispatch(
              profileUpdate(
                {
                  tooltip_first_invite_screen_web: 1,
                },
                false
              )
            );
            // dispatch(getProfile(true));
            dispatch(requestallmemberswithgroups({ forceFetch: true })).then(
              (res) => {
                navigate("/chat");
              }
            );
          } else {
            handleSkip();
          }
        }
      );
    } else {
      if (selectedAccount) {
        const reqBody = {
          status,
          target_subscriber_id: selectedAccount,
        };
        dispatch(handleAcceptIgnoreInvite(reqBody, data?.id)).then(
          async (res) => {
            setLoading(false);
            if (res?.status && status === 1) {
              localStorage.removeItem("invitationData");
              localStorage.removeItem("newProfile_subscriber_id");
              const userData = await dispatch(getProfile(true));          
              dispatch(requestallmemberswithgroups({ forceFetch: true })).then(
                (res) => {
                  if (userData?.data?.isFirstGroupCreated) {
                    navigate("/chat");
                  } else {
                    if (data?.groupId !== 0) {
                      dispatch(
                        profileUpdate(
                          {
                            is_first_invite_sent: true,
                            tooltip_first_invite_screen_web: 2,
                          },
                          false
                        )
                      );
                      navigate("/createfirstgroup");
                    } else {
                      dispatch(
                        profileUpdate(
                          {
                            tooltip_first_invite_screen_web: 2,
                          },
                          false
                        )
                      );
                      navigate("/createfirstgroup");
                    }
                  }
                }
              );
            } else {
              handleSkip();
            }
          }
        );
      }
    }
  };
  const handleSelectAccount = (e, data) => {
    if (e.target.checked) {
      setSelectedAccount(data?.id);
    }
  };

  return (
    <Box m={2}>
      {isPopupModalOpen && (
        <PopupModal
          title="Alert"
          content="You have already Joined This Account ."
          setPopupModalOpen={setPopupModalOpen}
          isInvitationPop={true}
        />
      )}

      <Grid container justifyContent="space-between" sx={classes.mobscroll}>
        <Grid item xs={12} md={5}>
          <CarouselComponent />
        </Grid>
        <Grid item xs={12} md={6} sx={classes.signBoxWrapper}>
          <Box sx={classes.signInBox}>
            <Box>
              <Typography fontSize={"1.7rem"} fontWeight={600} variant="h5">
                Invitation
              </Typography>
              <Typography
                mt={"0.4em"}
                fontWeight={400}
                fontSize={"0.87rem"}
                variant="caption"
              >
                {invitationData && +invitationData?.inviteType === 1
                  ? `${invitationData?.inviteName} has invited you to join their account as a team member.`
                  : "The following people or organisations are interested in collaborating with you. If you accept their invitation, they will join your contact list, but  won’t get access to your data unless you add them to a group."}
              </Typography>
            </Box>

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              boxShadow={"0px 1px 4px rgba(0, 0, 0, 0.25)"}
              padding={"0.45rem"}
              mt={"0.5rem"}
            >
              {invitationData &&
                invitationRetrivedData?.invitations?.length > 0 &&
                invitationRetrivedData?.invitations?.map((data) => (
                  <>
                    {data?.type === 1 ? (
                      <>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <Box>
                            <NameBadge
                              sx={{ color: "#183243" }}
                              size="small"
                              variant="contained"
                            >
                              {data && data?.invitedBy?.subscriberFirmName[0]}
                            </NameBadge>
                          </Box>
                          <Box>
                            <Typography>
                              {data && data?.invitedBy?.subscriberFirmName}
                            </Typography>
                          </Box>
                        </Box>

                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <Button
                            onClick={() => handleAction(2, data)}
                            sx={classes?.ignorebtn}
                            size="small"
                            variant="contained"
                          >
                            Ignore
                          </Button>
                          <Button
                            onClick={() => handleAction(1, data)}
                            sx={classes?.acceptbtn}
                            size="small"
                            variant="contained"
                          >
                            Accept
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box>
                          <Box display={"flex"} alignItems={"center"} gap={1}>
                            <Box>
                              <NameBadge
                                sx={{ color: "#183243" }}
                                size="small"
                                variant="contained"
                              >
                                {data && data?.invitedBy?.subscriberFirmName[0]}
                              </NameBadge>
                            </Box>
                            <Box>
                              <Typography>
                                {data && data?.invitedBy?.subscriberFirmName}
                              </Typography>
                            </Box>
                          </Box>

                          <Box ml={"0.2rem"}>
                            <>
                              <RadioGroup row>
                                {invitationRetrivedData?.accountOwned?.map(
                                  (accountData) => (
                                    <FormControlLabel
                                      key={accountData?.id + "externalInvite"}
                                      value={accountData?.id}
                                      control={
                                        <Radio
                                          onChange={(e) =>
                                            handleSelectAccount(e, accountData)
                                          }
                                          sx={classes.radioStyle}
                                          checked={
                                            accountData?.id === selectedAccount
                                          }
                                        />
                                      }
                                      label={accountData?.subscriberFirmName}
                                      labelPlacement="end"
                                    />
                                  )
                                )}
                              </RadioGroup>
                            </>
                          </Box>
                        </Box>

                        <Box>
                          <Box mb={"0.4rem"}>
                            <Button
                              onClick={() => handleAction(2, data)}
                              sx={classes?.ignorebtn}
                              size="small"
                              variant="contained"
                            >
                              Ignore
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              onClick={() => handleAction(1, data)}
                              sx={classes?.acceptbtn}
                              size="small"
                              variant="contained"
                            >
                              {isLoading ? (
                                <CircularProgress size={24} color="inherit" />
                              ) : (
                                "Accept"
                              )}
                            </Button>
                          </Box>
                        </Box>
                      </>
                    )}
                  </>
                ))}
            </Box>
            <Box
              position={"absolute"}
              bottom={"1rem"}
              width={"90%"}
              textAlign={"center"}
            >
              <Button
                onClick={handleSkip}
                sx={classes?.acceptbtn}
                size="small"
                variant="contained"
              >
                Skip
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvitationPage;
