import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import { styled } from "@mui/system";
import {
  FilterIcon,
  StyledSideBarHeader,
  createStyledIndicatorButton,
} from "../../common/StyledComponents/SideBarComponents";
import Filtericon from "../../assets/images/task/Filter_icon.svg";
import ServiceFilter from "./ServiceFilter";
import ProductFilter from "./ProductFilter";
import ArtFilter from "./ArtFilter";
import { getServiceCategory } from "../../appStore/actions/masterData/masterDataAction";
import { serviceTypeName } from "../../utils/constant";
import {
  GET_ART_SERVICE_CATEGORY,
  GET_PRODUCT_SERVICE_CATEGORY,
  GET_SERVICE_CATEGORY,
} from "../../appStore/actions/masterData";
import { getSellerTabPost } from "../../appStore/actions/ArkchatGroup/action";
const StyledSubHeadingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  // backgroundColor: "#F3F3F3",
  marginBottom: "0.5rem",
});
const classes = {
  chatBox1: {
    minWidth: "280px",
    backgroundColor: "#F9F8F8",
    height: "calc(100vh - 64px)",
    width: "calc(100% - 71.7em)",
    maxWidth: "500px",
    "@media only screen and (max-width:900px)": {
      flex: "1",
    },
  },
  chatBox2: {
    backgroundColor: "#F9F8F8",
    height: "calc(100vh - 64px)",
    minWidth: "250px",
    maxWidth: "350px",

    "@media only screen and (max-width:900px)": {
      flex: "1",
    },
  },
  Tabs: {
    minHeight: "38px",
    width: "80%",
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
      //   paddingLeft: "14px",
      //   paddingRight: "14px",
    },
    "@media (max-width: 63em)": {
      "& .MuiTabs-flexContainer": {},
    },
  },
  tabs: {
    color: "black",
    padding: "0px 0px",
    textTransform: "none",
    minWidth: "unset",
    minHeight: "2.2rem",
    // marginRight: "13.5%",
    "&.Mui-selected": {
      color: "black",
    },
    "@media (max-width: 64em)": {
      // marginRight: "2.5rem",
    },
  },
  headingText: {
    color: "black",
    fontSize: "14px",
    fontWeight: "400",
    "@media (max-width: 63em)": {
      fontSize: "0.8rem",
    },
  },

  selectedItem: {
    // backgroundColor: "#183243",
    backgroundColor: "#E1E1E1",
    color: "#000",
  },
  nonselectedItem: {
    color: "#000",
  },
  taskmargin: {
    display: "flex",
    display: "-webkit-box",
    display: "-ms-flexbox",
    display: "-webkit-flex",
    "-webkit-flex-direction": "row",
    "-ms-flex-direction": "row",
    flexDirection: "row",
    "-webkit-box-align": "center",
    "-ms-flex-align": "center",
    alignItems: "center",

    "@media only screen and (max-width:63em)": {
      marginLeft: "0 0px",
    },
  },
  checklabel: {
    marginRight: "0",
  },

  sidebar: {
    padding: "0px 20px 9px 30px",
    height: "calc(100vh - 110px)",
    "@media (max-width: 85.5em)": {
      padding: "4px 8px 4px 8px",
    },
  },
  ckeckboxH: {
    height: "calc(100vh - 15.5rem)",
    overflow: "auto",
    "@media only screen and (min-width: 1600px)": {
      height: "calc(100vh - 18rem)",
    },
  },
  topindication: {
    padding: "0.5rem 0.5rem",
    marginTop: "3px",
    backgroundColor: "#F3F3F3",
    borderRadius: "0.1rem",
    fontSize: "13px !important",
  },
};
const MarketPlaceSideBar = ({ setSellerTabLoading,setSideBarOpen}) => {
  const SmallActionButton = createStyledIndicatorButton("#DB0011");
  const [taskApprovalViewValue, setTaskApprovalViewValue] = useState(0);
  const [checked, setChecked] = useState([]);
  const dispatch = useDispatch();
  const handleViewChange = (event, newValue) => {
    setChecked([]);
    setTaskApprovalViewValue(newValue);
  };
  const TabPanel = ({ children, value, index }) => (
    <div hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
  const handleFilter = (categoryId) => {
    setSellerTabLoading(true);
    dispatch(getSellerTabPost(`category=${categoryId}`)).then(() =>
      setSellerTabLoading(false)
    );
  };

  const clearFilter = () => {
    setSellerTabLoading(true);
    dispatch(getSellerTabPost()).then(() => setSellerTabLoading(false));
  };
  useEffect(() => {
    dispatch(
      getServiceCategory(serviceTypeName?.Art, GET_ART_SERVICE_CATEGORY)
    );
    dispatch(
      getServiceCategory(serviceTypeName?.Service, GET_SERVICE_CATEGORY)
    );
    dispatch(
      getServiceCategory(serviceTypeName?.Product, GET_PRODUCT_SERVICE_CATEGORY)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box sx={{ height: "100%" }}>
        {/* Sidebar */}
        <StyledSideBarHeader position={"relative"}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Box display="flex" alignItems="center" gap={"0.5rem"}>
              <SmallActionButton onClick={()=>setSideBarOpen(false)} size="small" variant="contained">
                <FilterIcon src={Filtericon} />
              </SmallActionButton>
              <Box>
                <Typography sx={classes.headingText} variant="h6">
                  Filter
                </Typography>
              </Box>
            </Box>
          </Box>
        </StyledSideBarHeader>
        <>
          <StyledSubHeadingBox>
            <Box width={"100%"} sx={classes.sidebar}>
              <Tabs
                value={taskApprovalViewValue}
                onChange={handleViewChange}
                TabIndicatorProps={{
                  style: { backgroundColor: "#183243" },
                }}
                sx={classes.Tabs}
              >
                <Tab disableRipple sx={classes.tabs} label="Services" />
                <Tab disableRipple sx={classes.tabs} label="Products" />
                <Tab disableRipple sx={classes.tabs} label="Art" />
              </Tabs>
              <TabPanel value={taskApprovalViewValue} index={0}>
                <ServiceFilter
                  handleFilter={handleFilter}
                  clearFilter={clearFilter}
                  checked={checked}
                  setChecked={setChecked}
                />
              </TabPanel>
              <TabPanel value={taskApprovalViewValue} index={1}>
                <ProductFilter
                  handleFilter={handleFilter}
                  clearFilter={clearFilter}
                  checked={checked}
                  setChecked={setChecked}
                />
              </TabPanel>
              <TabPanel value={taskApprovalViewValue} index={2}>
                <ArtFilter
                  handleFilter={handleFilter}
                  clearFilter={clearFilter}
                  checked={checked}
                  setChecked={setChecked}
                />
              </TabPanel>
            </Box>
          </StyledSubHeadingBox>
        </>
      </Box>
    </>
  );
};

// const SidebarList = () => {
//   return (
//     <>
//       <Typography sx={classes.topindication}>
//         Green color checkboxes have content
//       </Typography>
//       <Box position={"relative"}>
//         <Box sx={classes.ckeckboxH}>
//           <ServiceFilter />
//         </Box>
//       </Box>
//     </>
//   );
// };

export default MarketPlaceSideBar;
