import { ArrowForwardIosOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputAdornment,
  Link,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import PictureComponent from "./PictureComponent";
import {
  accountType,
  sellerTypeEnum,
  serviceTypeName,
} from "../../../utils/constant";
import AlertPopUp from "../../../common/AlertPopUp";

const classes = {
  formcontrol: {
    ".MuiFormControl-root": {
      width: "100%",
    },
  },
};

const FormComponent = ({
  type,
  accountTypeValue,
  coverImage,
  profileImage,
  handleInputChange,
  profileData,
  coverPicRef,
  profilePicRef,
  handleCameraButtonClick,
  handleProfileImgInputChange,
  countryDrawer,
  submitUpdateProfileBtnRef,
  handleSubmit,
  requiredInput,
  handleOrganisationType,
  isLinkedinPopUpOpen,
  setLinkedinPopUpOpen,
  manufactureOption,
  sellingOption,
  handleSellerTypeChange,
  sellerTypeValue,
  setCoverImage,
  setProfileImage,
  currentUserData,
  profileBanner,
  profileimg,
}) => {
  const handleRemovePic = (type) => {
    if (type === "cover") {
      setCoverImage({
        src: currentUserData?.coverImgUrl
          ? currentUserData?.coverImgUrl
          : profileBanner,
        isCoverSelected: false,
        files: "",
      });
    } else {
      setProfileImage({
        src: currentUserData?.profileImgUrl
          ? currentUserData?.profileImgUrl
          : profileimg,
        isProfileSelected: false,
        files: "",
      });
    }
  };

  return (
    <Box width={"100%"}>
      <AlertPopUp
        title={"LinkedIn profile url"}
        open={isLinkedinPopUpOpen}
        closeModal={setLinkedinPopUpOpen}
        isLinkedinPopUp={true}
      />
      <Box width={"70%"} margin={"auto"} mb={"2rem"}>
        <PictureComponent
          coverPicRef={coverPicRef}
          profilePicRef={profilePicRef}
          handleCameraButtonClick={handleCameraButtonClick}
          handleProfileImgInputChange={handleProfileImgInputChange}
          profileImage={profileImage}
          coverImage={coverImage}
          handleRemovePic={handleRemovePic}
        />
      </Box>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        {/* for post service Organisation */}
        <>
          {(type === serviceTypeName?.Service ||
            type === serviceTypeName?.Product ||
            type === serviceTypeName?.Art) &&
            accountTypeValue === accountType?.Organisation && (
              <>
                {type === serviceTypeName?.Product && (
                  <Box
                    mb={"0.5rem"}
                    sx={{
                      display: "flex",
                      gap: "4.5rem",
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      gap={"0.5rem"}
                      width={"35%"}
                    >
                      <Box>
                        <Radio
                          checked={manufactureOption}
                          name="manufacturer"
                          onChange={handleOrganisationType}
                          size="large"
                          sx={{
                            padding: "0",
                            color: "#183243",
                            "& .MuiSvgIcon-root": { color: "#183243" },
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          fontSize={"0.9rem"}
                          fontWeight={400}
                          color={"#183242"}
                        >
                          We manufacture products{" "}
                        </Typography>
                      </Box>
                    </Box>

                    <Box width={"55%"} display={"flex"} gap="4.5rem">
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        gap={"0.5rem"}
                      >
                        <Box>
                          <Radio
                            checked={sellingOption}
                            name="seller"
                            onChange={handleOrganisationType}
                            size="large"
                            sx={{
                              padding: "0",
                              color: "#183243",
                              "& .MuiSvgIcon-root": { color: "#183243" },
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            fontSize={"0.9rem"}
                            fontWeight={400}
                            color={"#183242"}
                          >
                            We sell products{" "}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        gap={"0.5rem"}
                      >
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": { color: "#183243" },
                                }}
                                checked={sellerTypeValue === sellerTypeEnum?.Agent}
                                onChange={handleSellerTypeChange}
                                value={sellerTypeEnum?.Agent}
                                disabled={!sellingOption}
                                size="medium"
                                required={
                                  sellingOption && sellerTypeValue === 0
                                }
                              />
                            }
                            label="Agent"
                          />
                        </Box>
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": { color: "#183243" },
                                }}
                                checked={
                                  sellerTypeValue === sellerTypeEnum?.Distributor
                                }
                                onChange={handleSellerTypeChange}
                                value={sellerTypeEnum?.Distributor}
                                disabled={!sellingOption}
                                size="medium"
                                required={
                                  sellingOption && sellerTypeValue === 0
                                }
                              />
                            }
                            label="Distributor"
                          />
                        </Box>
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": { color: "#183243" },
                                }}
                                checked={sellerTypeValue === sellerTypeEnum?.Dealer}
                                onChange={handleSellerTypeChange}
                                value={sellerTypeEnum?.Dealer}
                                disabled={!sellingOption}
                                size="medium"
                                required={
                                  sellingOption && sellerTypeValue === 0
                                }
                              />
                            }
                            label="Dealer"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}

                <Box
                  mb={"0.5rem"}
                  sx={{
                    display: "flex",
                    gap: "4.5rem",
                  }}
                >
                  <Box width={"35%"}>
                    <TextField
                      name="linkedin"
                      size="Large"
                      type="url"
                      label={"LinkedIn company page url"}
                      value={profileData?.linkedin}
                      onChange={handleInputChange}
                      InputProps={{
                        // readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Link
                              onClick={() => setLinkedinPopUpOpen(true)}
                              sx={{ cursor: "pointer" }}
                            >
                              Get linkedIn url
                            </Link>
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                      required={
                        (requiredInput === "linkedin" ||
                          requiredInput === "both") &&
                        !profileData?.website
                      }
                      fullWidth
                    />
                    <FormHelperText>
                      Optional, between website or LinkedIn profile at least one
                      is required.
                    </FormHelperText>
                  </Box>

                  <Box width={"55%"}>
                    <Box>
                      <FormLabel>Profile Brief</FormLabel>
                    </Box>
                    <TextField
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      name="profile_brief"
                      value={profileData?.profile_brief || ""}
                    />
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Box>
                        {" "}
                        <FormHelperText>
                          Required, Maximum 160 characters
                        </FormHelperText>
                      </Box>
                      <Box>
                        {" "}
                        <FormHelperText>
                          {profileData?.profile_brief?.length}/{160}
                        </FormHelperText>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  mb={"0.5rem"}
                  sx={{
                    display: "flex",
                    gap: "4.5rem",
                  }}
                >
                  <Box width={"35%"}>
                    <TextField
                      size="Large"
                      name={"subscriber_firm_name"}
                      type="text"
                      label={"Organisation Name"}
                      variant="standard"
                      fullWidth
                      value={profileData?.subscriberFirmName || ""}
                      InputProps={{
                        readOnly: true,
                      }}
                      onChange={handleInputChange}
                    />
                    <FormHelperText>Required</FormHelperText>
                  </Box>

                  <Box
                    width={"55%"}
                    sx={{
                      display: "flex",
                      gap: "4.5rem",
                    }}
                  >
                    <Box width={"45%"}>
                      <TextField
                        size="Large"
                        type="text"
                        label={"Website"}
                        variant="standard"
                        name="website"
                        required={
                          (requiredInput === "website" ||
                            requiredInput === "both") &&
                          !profileData?.linkedin
                        }
                        fullWidth
                        value={profileData?.website || ""}
                        onChange={handleInputChange}
                      />
                      <FormHelperText>
                        Optional, between website or LinkedIn profile at least
                        one is required.
                      </FormHelperText>
                    </Box>

                    <Box width={"45%"}>
                      <TextField
                        size="Large"
                        type="text"
                        label={"Email Id"}
                        variant="standard"
                        disabled
                        fullWidth
                        onChange={handleInputChange}
                        name="email"
                      />
                      <FormHelperText>
                        Email id to get notifications, task & approval reminders
                        and leads
                      </FormHelperText>
                    </Box>
                  </Box>
                </Box>

                <Box
                  mb={"0.5rem"}
                  sx={{
                    display: "flex",
                    gap: "4.5rem",
                  }}
                >
                  <Box width={"35%"}>
                    <TextField
                      size="small"
                      type="text"
                      label={"City"}
                      variant="standard"
                      name="city"
                      required
                      fullWidth
                      sx={{ width: "100%" }}
                      value={profileData?.city || ""}
                      onChange={handleInputChange}
                    />
                    <FormHelperText sx={classes?.captions}>
                      Required
                    </FormHelperText>
                  </Box>

                  <Box
                    width={"55%"}
                    sx={{
                      display: "flex",
                      gap: "4.5rem",

                      alignItems: "center",
                    }}
                  >
                    <Box width={"45%"}>
                      <TextField
                        size="small"
                        type="text"
                        label={"Country"}
                        variant="standard"
                        name="country"
                        onClick={() => countryDrawer(true)}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <ArrowForwardIosOutlined />
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          shrink: !!(
                            profileData?.countryName || profileData?.country
                          )
                            ? true
                            : undefined,
                          style: {
                            fontSize: "0.85rem",
                          },
                        }}
                        required
                        fullWidth
                        value={profileData?.country || ""}
                      />
                      <FormHelperText sx={classes?.captions}>
                        Required
                      </FormHelperText>
                    </Box>

                    <Box width={"45%"}>
                      <Typography
                        fontSize={"0.75rem"}
                        fontWeight={400}
                        color={"#183242"}
                      >
                        If your email id and website url matches, you will get{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
        </>

        {/* for post service individual */}
        {(type === serviceTypeName?.Service ||
          type === serviceTypeName?.Art ||
          type === serviceTypeName?.Photo ||
          type === serviceTypeName?.Videos) &&
          accountTypeValue === accountType?.Individual && (
            <>
              <>
                <Box
                  mb={"0.5rem"}
                  sx={{
                    display: "flex",
                    gap: "4.5rem",
                  }}
                >
                  <Box width={"35%"}>
                    <TextField
                      size="Large"
                      type="text"
                      label={"Email Id"}
                      value={profileData?.email || ""}
                      variant="standard"
                      fullWidth
                    />
                    <FormHelperText>
                      Email id to get notifications, task & approval reminders
                      and leads
                    </FormHelperText>
                  </Box>

                  <Box
                    width={"55%"}
                    sx={{
                      display: "flex",
                      gap: "4.5rem",
                    }}
                  >
                    <Box width={"45%"}>
                      <TextField
                        size="small"
                        type="text"
                        label={"City"}
                        variant="standard"
                        name="city"
                        required
                        fullWidth
                        sx={{ width: "100%" }}
                        value={profileData?.city || ""}
                      />
                      <FormHelperText sx={classes?.captions}>
                        Required
                      </FormHelperText>
                    </Box>
                    <Box width={"45%"}>
                      <TextField
                        size="small"
                        type="text"
                        label={"Country"}
                        variant="standard"
                        name="country"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <ArrowForwardIosOutlined />
                            </InputAdornment>
                          ),
                        }}
                        required
                        fullWidth
                        value={profileData?.country || ""}
                      />
                      <FormHelperText sx={classes?.captions}>
                        Required
                      </FormHelperText>
                    </Box>
                  </Box>
                </Box>

                <Box
                  mb={"0.5rem"}
                  sx={{
                    display: "flex",
                    gap: "4.5rem",
                  }}
                >
                  <Box width={"35%"}>
                    <TextField
                      size="Large"
                      type="url"
                      label={"LinkedIn company page url"}
                      value={profileData?.linkedin}
                      onChange={handleInputChange}
                      InputProps={{
                        // readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Link
                              onClick={() => setLinkedinPopUpOpen(true)}
                              sx={{ cursor: "pointer" }}
                            >
                              Get linkedIn url
                            </Link>
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                      name="linkedin"
                      required
                      fullWidth
                    />
                    <FormHelperText>Required</FormHelperText>
                  </Box>

                  <Box width={"55%"}>
                    <Box>
                      <FormLabel>Profile Brief</FormLabel>
                    </Box>
                    <TextField
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      name="profile_brief"
                      value={profileData?.profile_brief || ""}
                    />
                    <FormHelperText>This is a helper text.</FormHelperText>
                  </Box>
                </Box>
              </>
            </>
          )}

        <Button
          ref={submitUpdateProfileBtnRef}
          sx={{ display: "none" }}
          type="submit"
        >
          SubmitButton
        </Button>
      </form>
    </Box>
  );
};

export default FormComponent;
