import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack, Typography } from "@mui/material";
import useAppSelectors from "../../../customHooks/useAppSelectors";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "1rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const Support = ({ setSupportDrawerOpen }) => {
  const { currentUserData } = useAppSelectors();
  const handleEmailClick = () => {
    if (currentUserData?.id) {
      const subscriberId = currentUserData?.activeSubscriber?.id;
      const subscriberName = `${currentUserData?.activeSubscriber?.subscriberFirmName}`;
      const userName = `${currentUserData?.firstName} ${currentUserData?.lastName}`;
      const userId = currentUserData?.id;

      const mailto = `mailto:support@arkchat.com?cc=dev@arkchat.com&subject=Arkchat%20support%20request&body=Subscriber%20ID%3A%20${subscriberId}%0ASubscriber%20Name%3A%20${subscriberName}%0AUser%20Name%3A%20${userName}%0AUser%20ID%3A%20${userId}%0A%0APlease%20write%20your%20support%20request%20here.`;
      window.location.href = mailto;
    }
  };
  return (
    <Box sx={classes.drawerBody}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => setSupportDrawerOpen(false)}
          />
        </Typography>
        <Typography color={"black"}>Support</Typography>
      </Stack>

      <Box
        display={"flex"}
        m={"0.5rem"}
        maxHeight={"80vh"}
        width={"90%"}
        margin={"auto"}
      >
        <Box mt={"5rem"}>
          <Box>
            <Typography>Dear user,</Typography>
          </Box>
          <Box mt={"1rem"}>
            <Typography>
              If you have any Arkchat-related issues, please write to us at{" "}
              <span
                onClick={handleEmailClick}
                style={{ color: "#2C86FF", cursor: "pointer" }}
              >
                support@arkchat.com
              </span>
            </Typography>
          </Box>
          <Box mt={"1rem"}>
            <Typography>Team Arkchat</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Support;
