import React, { useState } from "react";
import { FormControl } from "@mui/base";
import {
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";
import { AuthActionButton } from "../../../common/StyledComponents/AuthComponents";
import { useDispatch } from "react-redux";
import { forgortPassword } from "../../../appStore/actions/auth/forgotpassword/forgotpasswordaction";
import validator from "validator";
import IntroVideo from "../../../common/IntroVideo";
import { showToastMsg } from "../../../appStore/actions/global/action";

const classes = {
  blockInputBox: {
    width: "100%",
    marginTop: "1.5rem",
  },
  forgotPasswordBox: {
    height: "calc(100vh - 4rem)",
    maxHeight: "40.7rem",
    minHeight: "28rem",
    width: "100%",
    maxWidth: "43rem",
    position: "relative",
    "@media only screen and (max-width:899px)": {
      height: "100%",
    },
    "@media only screen and (max-width: 1204px)": {
      minHeight: "38rem",
    },
  },
  captions: {
    fontSize: "0.87rem !important",
    display: "block",
  },
  signInLink: {
    fontSize: "0.87rem !important",
    display: "block",
  },
  sendBtn: {
    position: "absolute",
    bottom: "2rem",
    left: "50%",
    marginLeft: "-2.72rem",
    "@media only screen and (max-width:899px)": {
      position: "relative",
      marginBottom: "-1.8rem",
      bottom: "0 !important",
    },
    "@media only screen and (max-width:1300px)": {
      bottom: "1.5rem",
    },
  },
  mobscroll: {
    overflowX: "hidden",
    "@media only screen and (max-width:899px)": {
      overflowX: "hidden",
    },
  },
};

const ForgotPassword = () => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(true);
  const [isRequiredEmpty, setIsRequiredEmpty] = useState({
    email: false,
    password: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      setLoading(true);
      const formbody = {
        email,
      };
      dispatch(forgortPassword(formbody)).then((res) => {
        if (res?.status) {
          setLoading(false);
          localStorage.setItem("forgotpassword", true);
          navigate("/email-verify");
        } else {
          setLoading(false);
          dispatch(showToastMsg(res?.message));
        }
      });
    } else {
      const isRequiredValue = {
        ...isRequiredEmpty,
        email: !email ? true : false,
      };
      const isValidEmail = validator.isEmail(email);
      setIsValid(isValidEmail);
      setIsRequiredEmpty(isRequiredValue);
      setLoading(false);
    }
  };

  return (
    <Box m={2}>
      <Grid container justifyContent="space-between" sx={classes.mobscroll}>
        <Grid item xs={12} md={5}>
          <IntroVideo />
        </Grid>
        <Grid ml={"auto"} item xs={12} md={6} padding={"0.4rem 1rem"}>
          <Box
            boxShadow={"0px 4px 14px rgba(0, 0, 0, 0.09)"}
            padding={"1rem"}
            sx={classes.forgotPasswordBox}
          >
            <Typography fontSize={"1.7rem"} fontWeight={600} variant="h5">
              {appContent?.forgotPassword}
            </Typography>
            <Typography mt={"0.4em"} sx={classes.captions} variant="caption">
              {appContent?.emailForPasswordReset}
            </Typography>

            <form onSubmit={handleSubmit} noValidate>
              <FormControl>
                <TextField
                  type="email"
                  label="Email"
                  variant="standard"
                  error={!isValid || isRequiredEmpty?.email ? true : false}
                  value={email.trim()}
                  onChange={(e) => {
                    setEmail(e.target.value.trim());
                    setIsValid(validator.isEmail(e.target.value.trim()));
                  }}
                  required
                  sx={classes.blockInputBox}
                />
                <FormHelperText
                  error={!isValid || isRequiredEmpty?.email ? true : false}
                >
                  {appContent?.required}
                </FormHelperText>
              </FormControl>
              <Box>
                <Typography
                  mt={"1.5em"}
                  variant="caption"
                  sx={classes.captions}
                >
                  {appContent?.rememberPassword}{" "}
                  <Link
                    style={{ textDecoration: "none", color: "#59A0FF" }}
                    to="/signin"
                  >
                    {appContent?.signIn}
                  </Link>
                </Typography>
              </Box>

              <Box sx={classes.sendBtn}>
                <AuthActionButton disabled={isLoading} type="submit" variant="contained">
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    appContent?.send
                  )}
                </AuthActionButton>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;
