import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getProfile } from "../appStore/actions/profile/action";
import { requestallmemberswithgroups } from "../appStore/actions/ArkchatGroup/action";
import { useDispatch } from "react-redux";
import { eraseCookie, getCookie } from "../config/cookie";
import { clearAllDatabases } from "../config/db";

const useLoginCondition = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkLoginCondition = async () => {
    setLoading(true);
    const userdetails = await dispatch(getProfile());
    const allres = await dispatch(requestallmemberswithgroups({}));
    const invitationData =
      JSON.parse(localStorage.getItem("invitationData")) || false;
    const isProfileJourneyCompleted =
      userdetails?.isProfileJourneyCompleted ||
      userdetails?.data?.isProfileJourneyCompleted;
    const isFirstGroupCreated =
      userdetails?.isFirstGroupCreated ||
      userdetails?.data?.isFirstGroupCreated;
    const isFirstInviteSent =
      userdetails?.isFirstInviteSent || userdetails?.data?.isFirstInviteSent;
    const isAccountOwner =
      userdetails?.accountOwner || userdetails?.data?.accountOwner;
    if (isProfileJourneyCompleted && invitationData) {
      navigate("/invitations");
    } else if (isProfileJourneyCompleted && !invitationData) {
      if (isAccountOwner) {
        if (isFirstGroupCreated && isFirstInviteSent) {
          navigate("/chat");
        } else if (isFirstGroupCreated && !isFirstInviteSent) {
          navigate("/invite");
        } else {
          navigate("/createfirstgroup");
        }
      } else {
        navigate("/chat");
      }
    } else if (
      !isProfileJourneyCompleted &&
      invitationData &&
      +invitationData?.inviteType === 1
    ) {
      navigate("/individualprofile");
    } else {
      navigate("/profile");
    }
    setLoading(false);
  };

  useEffect(() => {
    const token = getCookie("token");
    const isRemember = getCookie("isRemember");
    if (token && isRemember) {
      localStorage.clear();
      checkLoginCondition();
    } else {
      clearAllDatabases();
      eraseCookie("token");
      eraseCookie("subsCriber_id");
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loading };
};

export default useLoginCondition;
