import * as actionTypes from "./type";
import axios from "axios";
import {
  requestShowLoader,
  requestHiderLoader,
  showToastMsg,
} from "../global/action";

import {
  HANDLE_TOAST_OPEN_CLOSE,
  REQUEST_FAILED,
  SHOW_TOAST_MESSAGE,
} from "../global/type";
import { getCookie } from "../../../config/cookie";
import {
  deleteGroupFrmGrpDb,
  deleteMsgFromDb,
  deleteTempMessageData,
  getAllAcountLevelMember,
  getAllmemberswithgroupsData,
  getLatestTimeToken,
  getMessageCount,
  getMessageFromDb,
  getTempStoreMessageData,
  sotreAllAcountLevelMember,
  sotreAllmemberswithgroupsData,
  storeMessageToDb,
  storeProfileData,
  storeTempMessageToDb,
  updateAllmemberswithgroupsData,
  updateAllmemberswithgroupsDataTimetoken,
  updateAllmemberswithgroupsMsgCount,
  updateMessageToDb,
} from "../../../config/db";
import { UPDATE_PROFILE_DONE, UPDATE_PROFILE_DONE_ERROR } from "../profile";
import {
  handleLoading,
  handleScrollToBottom,
  handleThreadScrollToBottom,
} from "../AppState/action";
import {
  handleDecryptSingleMessageData,
  msgEncrypter,
} from "../../../utils/messageEncrypt";
import { SHOULD_CALL_PUBNUB_GET_TOKEN } from "../AppState/type";
import { MessageType } from "../../../utils/constant";

const headerRequest = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getCookie("token")}`,
    },
  };
};

const attachmentheaderRequest = () => {
  return {
    headers: {
      // "Content-Type": "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getCookie("token")}`,
    },
  };
};

// Group and Dm List for sidebar Api's and functions

export const requestDeleteGroup = (groupId) => async (dispatch) => {
  try {
    dispatch(requestShowLoader());
    const responseData = await axios.delete(
      `${process.env.REACT_APP_BASE_PATH}/group/${groupId}`,
      headerRequest()
    );
    dispatch(
      showToastMsg(
        responseData?.data?.message
          ? responseData?.data?.message
          : responseData?.message
      )
    );
    return responseData;
  } catch (err) {
    dispatch(requestHiderLoader());
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const requestDeleteGroupFromDb = (groupId) => async (dispatch) => {
  try {
    await deleteGroupFrmGrpDb(groupId);
    const updateData = await getAllmemberswithgroupsData();
    dispatch({
      type: actionTypes.RECEIVED_ALL_GROUP_SUCCESS,
      payload: updateData,
    });
    return updateData;
  } catch (err) {
    dispatch(requestHiderLoader());
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const requestUpdateGroupDb = (data) => async (dispatch) => {
  try {
    const { timetoken, ...grpData } = data;
    await updateAllmemberswithgroupsData(data?.pnId, grpData);
    const updateData = await getAllmemberswithgroupsData();
    dispatch({
      type: actionTypes.RECEIVED_ALL_GROUP_SUCCESS,
      payload: updateData,
    });
  } catch (error) {}
};

export const requestEditGroupName = (groupId, reqbody) => async (dispatch) => {
  try {
    dispatch(requestShowLoader());
    const responsedata = await axios.put(
      `${process.env.REACT_APP_BASE_PATH}/group/edit/${groupId}`,
      reqbody,
      headerRequest()
    );
    dispatch(
      showToastMsg(responsedata?.data?.message || responsedata?.message)
    );
    return responsedata;
  } catch (err) {
    dispatch(requestHiderLoader());
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const requestCreatFirstGroup = (formData) => {
  let API_URL = `${process.env.REACT_APP_BASE_PATH}/group/gettingstarted`;
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .post(API_URL, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          dispatch({
            type: actionTypes.REQUEST_CREATE_FIRST_GROUP,
            payload: res?.data?.data,
          });
          return res?.data;
        } else {
          dispatch({
            type: actionTypes.CREATE_FIRST_GROUP_ERROR,
            payload: res?.data,
          });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};

const updateAllmemberswithgroupsDb = async () => {
  try {
    let API_URL = `${process.env.REACT_APP_BASE_PATH}/group/all-members-with-groups`;
    const token = getCookie("token");
    const subscriber_id = getCookie("subsCriber_id");
    const response = await axios.get(API_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      // Dispatch the profile data
      await sotreAllmemberswithgroupsData(subscriber_id, response.data.data);
      return response?.data?.data;
    }
  } catch (err) {
    // console.error("Background API call error:", err);
  }
};

export const requestallmemberswithgroups = ({
  forceFetch = false,
  isSignalData = false,
  createdData,
}) => {
  let API_URL = `${process.env.REACT_APP_BASE_PATH}/group/all-members-with-groups`;
  const token = getCookie("token");
  return async (dispatch) => {
    try {
      const subscriber_id = getCookie("subsCriber_id") || false;
      let allmemberswithgroupsData;
      if (subscriber_id) {
        allmemberswithgroupsData = await getAllmemberswithgroupsData(
          subscriber_id
        );
      }
      const paylodaData = {
        data: allmemberswithgroupsData,
      };
      let updatedGroupData;
      if (allmemberswithgroupsData.length > 0 && !forceFetch && subscriber_id) {
        if (isSignalData) {
          updatedGroupData = [createdData, ...paylodaData?.data];
          dispatch({
            type: actionTypes.RECEIVED_ALL_GROUP_SUCCESS,
            payload: updatedGroupData,
          });
          dispatch({
            type: SHOULD_CALL_PUBNUB_GET_TOKEN,
            payload: true,
          });
          await sotreAllmemberswithgroupsData(subscriber_id, updatedGroupData);
        }
        // If data is present, dispatch it
        else {
          dispatch({
            type: actionTypes.RECEIVED_ALL_GROUP_SUCCESS,
            payload: paylodaData?.data,
          });
          updateAllmemberswithgroupsDb().then((updatedData) => {
            if (updatedData) {
              dispatch({
                type: actionTypes.RECEIVED_ALL_GROUP_SUCCESS,
                payload: updatedData,
              });
              dispatch({
                type: SHOULD_CALL_PUBNUB_GET_TOKEN,
                payload: true,
              });
            }
          });
          return paylodaData?.data;
        }

        return updatedGroupData;
      } else {
        // If data is not present, make an API call
        const response = await axios.get(API_URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          // Dispatch the profile data
          await sotreAllmemberswithgroupsData(
            subscriber_id,
            response.data.data
          );
          dispatch({
            type: actionTypes.RECEIVED_ALL_GROUP_SUCCESS,
            payload: response?.data?.data,
          });
          dispatch({
            type: SHOULD_CALL_PUBNUB_GET_TOKEN,
            payload: true,
          });
          return response?.data?.data;
        } else {
          dispatch({
            type: actionTypes.REQUEST_ALL_MEMBERS_WITH_GROUP_ERROR,
            payload: response,
          });
        }
      }
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };
};

export const handleUpdateGroupSorting =
  (pnId, updatedTimeToken, actiVeGroupPnId) => async (dispatch) => {
    const isUpdateCount = actiVeGroupPnId === pnId;
    await updateAllmemberswithgroupsDataTimetoken(
      pnId,
      updatedTimeToken,
      isUpdateCount
    );
    const updateData = await getAllmemberswithgroupsData();
    dispatch({
      type: actionTypes.RECEIVED_ALL_GROUP_SUCCESS,
      payload: updateData,
    });
  };

export const requestCreatArkchatGroup = (reqBody) => async (dispatch) => {
  try {
    const responseData = await axios.post(
      `${process.env.REACT_APP_BASE_PATH}/group/create`,
      reqBody,
      headerRequest()
    );
    const { data } = responseData;
    // if(data?.code == 200) {
    //   await dispatch(requestallmemberswithgroups());
    // }
    return data;
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const requestCreateGroupWithmember = (reqBody) => async (dispatch) => {
  try {
    const responseData = await axios.post(
      `${process.env.REACT_APP_BASE_PATH}/group/`,
      reqBody,
      headerRequest()
    );
    const { data } = responseData;
    if (data?.code === 200) {
      dispatch(showToastMsg(data?.message || responseData?.message));
    }
    return data;
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

// export const requestSelectedSpecificperson = (userId) => async (dispatch) => {
//   try {
//     const responsedata = await axios.post(
//       `${process.env.REACT_APP_BASE_PATH}/chats/user/${userId}`,
//       { groupId: 0, topicId: 0 },
//       headerRequest()
//     );
//     const { data } = responsedata;
//     if (responsedata?.status) {
//       dispatch({
//         type: actionTypes.RECEIVED_GROUP_MESSAGE_LIST,
//         payload: data?.data,
//       });
//     }
//   } catch (err) {
//     dispatch({ type: REQUEST_FAILED, payload: err?.response });
//   }
// };

export const requestgroupMemberDetails =
  (reqBody, abortSignal) => async (dispatch) => {
    try {
      const requestOptions = {
        ...headerRequest(),
      };
      if (abortSignal) {
        requestOptions.signal = abortSignal;
      }

      const responseData = await axios.post(
        `${process.env.REACT_APP_BASE_PATH}/group/people`,
        reqBody,
        requestOptions
      );

      const { data } = responseData;

      if (data?.status) {
        const accounyLeveluser = data?.data;
        const external = [];
        const internal = [];

        accounyLeveluser.forEach((item) => {
          if (item?.inviteType == 0 || item?.inviteType == 1) {
            internal.push(item);
          }

          if (item?.inviteType == 2) {
            external.push(item);
          }
        });

        dispatch({
          type: actionTypes.RECEVED_GROUDP_MEMBER_SUCCESS,
          payload: {
            internalMember: internal,
            externalMember: external,
          },
        });
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        //
      } else {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      }
    }
  };

export const requestGetGroupRecipient =
  (group_Id, abortSignal) => async (dispatch) => {
    try {
      const requestOptions = {
        ...headerRequest(),
      };
      if (abortSignal) {
        requestOptions.signal = abortSignal;
      }

      const responseData = await axios.get(
        `${process.env.REACT_APP_BASE_PATH}/group/recipients/${group_Id}`,
        requestOptions
      );
      const { data } = responseData;
      if (data?.status) {
        dispatch({
          type: actionTypes.GROUDP_RECIPIENT_DETAILS,
          payload: data?.data,
        });
      }
      return data;
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };
export const requestUpdateFromSignalGroupRecipient =
  (Updated_data) => async (dispatch) => {
    try {
      if (Updated_data?.length > 0) {
        dispatch({
          type: actionTypes.GROUDP_RECIPIENT_DETAILS,
          payload: Updated_data,
        });
      }
      return Updated_data;
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };

export const requestAddmemberInGroup = (reqBody) => async (dispatch) => {
  try {
    const responseData = await axios.post(
      `${process.env.REACT_APP_BASE_PATH}/group/people/add`,
      reqBody,
      headerRequest()
    );

    const { data } = responseData;
    dispatch(showToastMsg(data?.message));
    return data;
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleExitFromGroup = (reqBody) => async (dispatch) => {
  try {
    const responseData = await axios.post(
      `${process.env.REACT_APP_BASE_PATH}/group/people/exit`,
      reqBody,
      headerRequest()
    );

    const { data } = responseData;

    return data;
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleDeleteGroupTopic = (topicId) => async (dispatch) => {
  try {
    const responseData = await axios.delete(
      `${process.env.REACT_APP_BASE_PATH}/group/topic/${topicId}`,
      headerRequest()
    );

    return responseData;
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleDeactivatePrivateChat =
  (reqBody, groupId) => async (dispatch) => {
    try {
      const responseData = await axios.post(
        `${process.env.REACT_APP_BASE_PATH}/group/managesettings/${groupId}`,
        reqBody,
        headerRequest()
      );

      dispatch(showToastMsg(responseData?.data?.message));

      return responseData;
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };

// export const getAccountMeberPermissionByGroup =
//   (reqObj) => async (dispatch) => {
//     try {
//       const responsedata = axios.get(
//         `${process.env.REACT_APP_BASE_PATH}/group/user/permission?group_id=${reqObj?.groupId}&user_id=${reqObj?.userId}`,
//         headerRequest()
//       );
//       const { data } = responsedata;
//       return data;
//     } catch (err) {
//       dispatch({ type: REQUEST_FAILED, payload: err?.response });
//     }
//   };

export const profileLanguageUpdate = (formData, groupId) => {
  let API_URL = `${process.env.REACT_APP_BASE_PATH}/group/chat/${groupId}`;
  const subscriber_id = getCookie("subsCriber_id") || false;
  const token = getCookie("token");
  return async (dispatch) => {
    return axios
      .put(API_URL, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        if (res.status === 200) {
          if (subscriber_id) {
            dispatch({
              type: UPDATE_PROFILE_DONE,
              payload: res?.data?.data,
            });
            await storeProfileData(subscriber_id, res?.data?.data);
          }
          return res?.data;
        } else {
          dispatch({
            type: UPDATE_PROFILE_DONE_ERROR,
            payload: res?.data?.data,
          });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
        //
      });
  };
};

const updateAllAcountLevelMemberDb = async () => {
  try {
    let API_URL = `${process.env.REACT_APP_BASE_PATH}/onboarding/subscriber/user/list`;
    const token = getCookie("token");
    const subscriber_id = getCookie("subsCriber_id");
    const response = await axios.get(API_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      const { data } = response;

      const accounyLeveluser = data?.data;
      const external = [];
      const internal = [];
      // eslint-disable-next-line array-callback-return
      accounyLeveluser.map((item) => {
        if (item?.inviteType == 0 || item?.inviteType == 1) {
          internal.push(item);
        }

        if (item?.inviteType == 2) {
          external.push(item);
        }
      });
      // Dispatch the profile data
      await sotreAllAcountLevelMember(subscriber_id, {
        internalMember: internal,
        externalMember: external,
      });
      return { internal, external };
    }
  } catch (err) {
    // console.error("Background API call error:", err);
  }
};

export const requestGetAllAcountLevelMember =
  (forceFetch = false) =>
  async (dispatch) => {
    try {
      const subscriber_id = getCookie("subsCriber_id");
      const allAcountLevelMemberData = await getAllAcountLevelMember(
        subscriber_id
      );
      if (allAcountLevelMemberData?.length > 0 && !forceFetch) {
        dispatch({
          type: actionTypes.RECEIVED_ONBOARDING_ACCOUNT_MEMBER,
          payload: allAcountLevelMemberData[0]?.data,
        });
        updateAllAcountLevelMemberDb().then((updatedData) => {
          dispatch({
            type: actionTypes.RECEIVED_ONBOARDING_ACCOUNT_MEMBER,
            payload: {
              internalMember: updatedData?.internal,
              externalMember: updatedData?.external,
            },
          });
        });
      } else {
        const responseData = await axios.get(
          `${process.env.REACT_APP_BASE_PATH}/onboarding/subscriber/user/list`,
          headerRequest()
        );

        const { data } = responseData;

        const accounyLeveluser = data?.data;
        const external = [];
        const internal = [];
        // eslint-disable-next-line array-callback-return
        accounyLeveluser.map((item) => {
          if (item?.inviteType == 0 || item?.inviteType == 1) {
            internal.push(item);
          }

          if (item?.inviteType == 2) {
            external.push(item);
          }
        });
        dispatch({
          type: actionTypes.RECEIVED_ONBOARDING_ACCOUNT_MEMBER,
          payload: {
            internalMember: internal,
            externalMember: external,
          },
        });
        await sotreAllAcountLevelMember(subscriber_id, {
          internalMember: internal,
          externalMember: external,
        });
      }
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };

// export const uploadAttachment = (files, pnId) => async () => {
//   try {
//     const responseData = await axios.post(
//       `${process.env.REACT_APP_BASE_PATH}/chats/attachment/${pnId}`,
//       files,
//       attachmentheaderRequest()
//     );
//     if (responseData?.data?.status) {
//       return responseData?.data?.data;
//     }
//   } catch (error) {}
// };

export const uploadAttachment =
  (files, pnId, config = {}) =>
  async (dispatch) => {
    try {
      const headersConfig = attachmentheaderRequest();

      const responseData = await axios.post(
        `${process.env.REACT_APP_BASE_PATH}/chats/attachment/${pnId}`,
        files,
        {
          ...headersConfig,
          ...config,
        }
      );

      if (responseData?.data?.status) {
        return responseData?.data?.data;
      }
    } catch (error) {
      // console.error("Error uploading file:", error);
      throw error;
    }
  };

// Message related fun()

export const handlePubnubToken = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_PATH}/group/get-token`,
      headerRequest()
    );
    return response?.data?.data;
  } catch (error) {
    // console.error("Error fetching Pubnub token:", error);
  }
};

export const handleDecryptMessageData = (messageDatas) => {
  try {
    let decryptedMsgData = [];
    decryptedMsgData = messageDatas?.map((item, i) => {
      if (
        item?.thread_original_message &&
        (item?.thread_original_message?.id ||
          item?.thread_original_message?.message)
      ) {
        return {
          ...item,
          message: msgEncrypter.decrypt(
            item?.message,
            process.env.REACT_APP_ENC_KEY,
            item?.kuncika
          ),
          translations: JSON.parse(
            msgEncrypter.decrypt(
              item?.translations,
              process.env.REACT_APP_ENC_KEY,
              item?.kuncika
            )
          ),
          thread_original_message: {
            ...item?.thread_original_message,
            message: msgEncrypter.decrypt(
              item?.thread_original_message?.message,
              process.env.REACT_APP_ENC_KEY,
              item?.kuncika
            ),
            translations: JSON.parse(
              msgEncrypter.decrypt(
                item?.thread_original_message?.translations,
                process.env.REACT_APP_ENC_KEY,
                item?.kuncika
              )
            ),
          },
        };
      } else {
        return {
          ...item,
          message: msgEncrypter.decrypt(
            item?.message,
            process.env.REACT_APP_ENC_KEY,
            item?.kuncika
          ),
          translations: JSON.parse(
            msgEncrypter.decrypt(
              item?.translations,
              process.env.REACT_APP_ENC_KEY,
              item?.kuncika
            )
          ),
        };
      }
    });
    return decryptedMsgData;
  } catch (error) {}
};

const getMessagesFromAPI = async ({
  pnId,
  payload = {},
  page = 1,
  page_size = 50,
  selectedGroup = true,
  lastTimeToken = null,
  abortSignal,
}) => {
  if (!selectedGroup) {
    payload = {
      type: 3,
    };
  }
  if (lastTimeToken) {
    payload = {
      ...payload,
      timetoken: lastTimeToken,
    };
  }
  try {
    let url = `${process.env.REACT_APP_BASE_PATH}/chats/messages/${pnId}?page=${page}&page_size=${page_size}`;

    // Prepare the options for the axios request
    const options = {
      ...headerRequest(),
      ...(abortSignal && { signal: abortSignal }), // Add signal to the options if present
    };

    const responseData = await axios.post(url, payload, options);
    if (responseData.status === 200) {
      const decryptedMessageData = handleDecryptMessageData(
        responseData?.data?.data?.messages
      );
      return { ...responseData?.data?.data, messages: decryptedMessageData };
    }
  } catch (err) {}
};

export const handleGetUpdatedMsgData = () => async (dispatch) => {
  try {
    const responseData = await axios.get(
      `${process.env.REACT_APP_BASE_PATH}/chats/activity`,
      headerRequest()
    );
    if (responseData?.data?.code === 200) {
      const { approval, task, delete_message } = responseData?.data?.data;
      let msgToUpdateToken = [];
      if (approval?.length > 0) {
        msgToUpdateToken = [...msgToUpdateToken, ...approval];
      }
      if (task?.length > 0) {
        msgToUpdateToken = [...msgToUpdateToken, ...task];
      }
      if (task?.length > 0 || approval?.length > 0) {
        dispatch(handleFetchConversation({ timetoken: msgToUpdateToken })).then(
          async (res) => {
            for (let i = 0; i < res?.length; i++) {
              if (res[i]?.message?.timetoken) {
                let decryptedData = handleDecryptSingleMessageData(
                  res[i]?.message
                );
                await updateMessageToDb(
                  res[i]?.pnId,
                  res[i]?.message?.temp_timetoken,
                  decryptedData
                );
              }
            }
          }
        );
      }
      if (delete_message?.length > 0) {
        for (let i = 0; i < delete_message?.length; i++) {
          await deleteMsgFromDb(delete_message[i]);
        }
      }
      const reqBody = {
        task: true,
        approval: true,
        delete_message: true,
      };
      dispatch(handleUpdatedMsgDataSyncedToServer(reqBody));
    }
    return;
  } catch (error) {}
};

export const handleUpdatedMsgDataSyncedToServer =
  (reqBoby) => async (dispatch) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_PATH}/chats/activity`,
        reqBoby,
        headerRequest()
      );
      return;
    } catch (error) {}
  };

export const fetchOldMessageFromApi =
  (selectedGroup, payload = {}, page) =>
  async (dispatch) => {
    const msgLength = await getMessageCount(selectedGroup?.pnId);
    const lastTimeToken = await getLatestTimeToken(selectedGroup?.pnId, false);
    getMessagesFromAPI({
      pnId: selectedGroup?.pnId,
      payload: payload,
      page: page,
      page_size: 50,
      selectedGroup: selectedGroup?.isGroup,
    }).then(async (res) => {
      const newMessages = res?.messages?.filter(
        (apiMessage) => apiMessage?.timetoken < lastTimeToken[0]?.timetoken
      );
      if (res?.count !== msgLength && newMessages?.length > 0) {
        dispatch({
          type: actionTypes.RECEIVED_OLD_MESSAGE_LIST,
          payload: newMessages,
        });
        await storeMessageToDb(newMessages);
      }
    });
  };

export const handleInternalPublisFun =
  (pnId, topic_id, callback) => async (dispatch) => {
    const messageData = await getMessageFromDb(pnId, topic_id);
    dispatch({
      type: actionTypes.RECEIVED_GROUP_MESSAGE_LIST,
      payload: messageData,
    });
    if (callback) {
      callback();
    }
  };

export const handleSaveTempMsgData =
  (messageData, pnId) => async (dispatch) => {
    const decryptedMsg = handleDecryptSingleMessageData(messageData, true);
    await storeTempMessageToDb(decryptedMsg);
    const tempMsgData = await getTempStoreMessageData(pnId);
    dispatch({
      type: actionTypes.RECEIVED_TEMP_MESSAGE_LIST,
      payload: tempMsgData,
    });
  };

export const handleGetTempMsgData = (pnId) => async (dispatch) => {
  const tempMsgData = await getTempStoreMessageData(pnId);
  dispatch({
    type: actionTypes.RECEIVED_TEMP_MESSAGE_LIST,
    payload: tempMsgData,
  });
};

export const handleDeleteTempMsgData =
  (temp_timetoken, pnId) => async (dispatch) => {
    await deleteTempMessageData(temp_timetoken);
    const tempMsgData = await getTempStoreMessageData(pnId);
    dispatch({
      type: actionTypes.RECEIVED_TEMP_MESSAGE_LIST,
      payload: tempMsgData,
    });
  };

export const handleUpdateExistingMessage =
  (
    pnId,
    data,
    shouldUpdateMessageList = false,
    messageCountToget,
    activeTopic,
    isThreadScreen = false
  ) =>
  async (dispatch) => {
    for (let i = 0; i < data?.length; i++) {
      let decryptedData = handleDecryptSingleMessageData(data[i]?.message);
      if (data[i]?.message?.temp_timetoken) {
        if (isThreadScreen) {
          dispatch({
            type: actionTypes.UPDATE_EXISTING_THREAD_MESSAGE,
            payload: decryptedData[0],
          });
        }
        await updateMessageToDb(
          pnId,
          data[i]?.message?.temp_timetoken,
          decryptedData
        );
      }
    }

    if (shouldUpdateMessageList) {
      let msgLimit =
        messageCountToget?.length <= 5 ? 10 : messageCountToget?.length;
      const messageData = await getMessageFromDb(
        pnId,
        activeTopic?.id,
        0,
        msgLimit
      );
      dispatch({
        type: actionTypes.RECEIVED_GROUP_MESSAGE_LIST,
        payload: messageData,
      });
    }
  };

export const handleFetchConversation = (reqBody) => async (dispatch) => {
  try {
    const responseData = await axios.post(
      `${process.env.REACT_APP_BASE_PATH}/chats/messages`,
      reqBody,
      headerRequest()
    );
    if (responseData.status === 200) {
      return responseData?.data?.data;
    }
  } catch (err) {
    // console.error("Background API call error:", err);
  }
};

export const handleInstantPubnubPublishMessage =
  (reqBody, activepnId, topic_id, temp_timetoken) => async (dispatch) => {
    await deleteTempMessageData(temp_timetoken);
    const tempMsgData = await getTempStoreMessageData(activepnId);
    dispatch({
      type: actionTypes.RECEIVED_TEMP_MESSAGE_LIST,
      payload: tempMsgData,
    });
    await storeMessageToDb(reqBody);
    if (reqBody[0]?.pnId === activepnId) {
      dispatch({
        type: actionTypes.RECEIVED_GROUP_INSTANT_MESSAGE_LIST,
        payload: reqBody[0],
      });
    }
  };

export const handleFetchOldMessage =
  (pnId, topic_id, startFrom) => async (dispatch) => {
    const messageData = await getMessageFromDb(pnId, topic_id, startFrom, 50);
    dispatch({
      type: actionTypes.RECEIVED_OLD_MESSAGE_LIST,
      payload: messageData,
    });
    return messageData;
  };

export const handleFetchMessages =
  (pnId, topic_id, payload = {}, callback, abortSignal) =>
  async (dispatch) => {
    try {
      let page = 1;
      const pageSize = 50;
      const messageData = await getMessageFromDb(pnId, topic_id);
      const lastTimeToken = await getLatestTimeToken(pnId);
      dispatch({
        type: actionTypes.RECEIVED_GROUP_MESSAGE_LIST,
        payload: messageData,
      });

      if (messageData?.length > 0) {
        if (callback) {
          callback();
        }

        while (true) {
          const res = await getMessagesFromAPI({
            pnId,
            payload,
            lastTimeToken: lastTimeToken[0]?.timetoken,
            page: page,
            page_size: pageSize,
            abortSignal,
          });

          const newMessages = res?.messages?.filter(
            (apiMessage) => apiMessage.timetoken > lastTimeToken[0]?.timetoken
          );

          if (newMessages?.length > 0 && messageData?.length > 0) {
            await storeMessageToDb(newMessages);
            dispatch(handleInternalPublisFun(pnId, topic_id, callback));
          } else if (
            messageData?.length === 0 &&
            newMessages?.length === 0 &&
            lastTimeToken?.length === 0
          ) {
            await storeMessageToDb(res?.messages);
            dispatch(handleInternalPublisFun(pnId, topic_id, callback));
          }

          if (res?.messages.length < pageSize) {
            break;
          }

          page++;
        }
      } else {
        const res = await getMessagesFromAPI({
          pnId,
          payload,
          lastTimeToken: 0,
          abortSignal,
        });

        let newMessages;
        newMessages = res?.messages?.filter(
          (apiMessage) => apiMessage.timetoken > lastTimeToken[0]?.timetoken
        );

        if (newMessages?.length > 0 && messageData?.length > 0) {
          await storeMessageToDb(newMessages);
          dispatch(handleInternalPublisFun(pnId, topic_id, callback));
        } else if (
          messageData?.length === 0 &&
          newMessages?.length === 0 &&
          lastTimeToken?.length === 0
        ) {
          await storeMessageToDb(res?.messages);
          dispatch(handleInternalPublisFun(pnId, topic_id, callback));
        }
      }

      if (callback) {
        callback();
      }
    } catch (err) {
      callback();
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };

export const handleTaskUpdate = (reqBoby, task_id) => async (dispatch) => {
  try {
    const responseData = await axios.put(
      `${process.env.REACT_APP_BASE_PATH}/chats/task/${task_id}`,
      reqBoby,
      headerRequest()
    );
    return responseData;
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const getAttachments = (queryParam) => async (dispatch) => {
  try {
    const responseData = await axios.get(
      `${process.env.REACT_APP_BASE_PATH}/chats/attachment/${queryParam}`,
      headerRequest()
    );
    dispatch({
      type: actionTypes.RECEIVED_ALL_ATTACHMENTS,
      payload: responseData?.data?.data,
    });
    return responseData?.data?.data;
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleUpdateMsgToDb =
  (reqBoby, shouldUpdateMessageList) => async (dispatch) => {
    try {
      const { pnId, temp_timetoken } = reqBoby;

      await updateMessageToDb(pnId, temp_timetoken, reqBoby, true);
    } catch (error) {}
  };

export const handleDeleteMsgFromDb =
  (
    msgPnId,
    temp_timetoken,
    sid,
    activePnId,
    activeTopicId,
    msglength,
    shouldUpdateMessageList,
    isThreadScreen = false
  ) =>
  async (dispatch) => {
    try {
      if (isThreadScreen) {
        dispatch({
          type: actionTypes.DELETE_EXISTING_THREAD_MESSAGE,
          payload: temp_timetoken,
        });
      }
      await deleteMsgFromDb(temp_timetoken);
      if (shouldUpdateMessageList) {
        const messageData = await getMessageFromDb(
          activePnId,
          activeTopicId,
          0,
          msglength
        );
        dispatch({
          type: actionTypes.RECEIVED_GROUP_MESSAGE_LIST,
          payload: messageData,
        });
      }
    } catch (error) {}
  };

export const handleSyncStarMsgData =
  (
    pnId,
    temp_timetoken,
    activeTopicId,
    msglength,
    updatedData,
    shouldUpdateMessageList = true,
    shouldUpdateThreadMessageList = false
  ) =>
  async (dispatch) => {
    try {
      const decryptedData = handleDecryptSingleMessageData(updatedData);
      if (shouldUpdateThreadMessageList) {
        dispatch({
          type: actionTypes.UPDATE_EXISTING_THREAD_MESSAGE,
          payload: decryptedData,
        });
      }
      await updateMessageToDb(pnId, temp_timetoken, decryptedData);
      if (shouldUpdateMessageList) {
        const messageData = await getMessageFromDb(
          pnId,
          activeTopicId,
          0,
          msglength
        );
        dispatch({
          type: actionTypes.RECEIVED_GROUP_MESSAGE_LIST,
          payload: messageData,
        });
      }
    } catch (error) {}
  };

export const handleStarMessage =
  (reqBoby, pnId, activeTopic, messageCountToget, isThreadScreen) =>
  async (dispatch) => {
    try {
      const responseData = await axios.put(
        `${process.env.REACT_APP_BASE_PATH}/chats/starred`,
        reqBoby,
        headerRequest()
      );
      if (responseData?.data?.status) {
        dispatch(handleScrollToBottom(false));
        const { pn_id, updatedAt, ...restData } = responseData?.data?.data;
        // pnId, timetoken, activeTopicId, msglength, updatedData
        dispatch(
          handleSyncStarMsgData(
            pnId,
            responseData?.data?.data?.timetoken,
            activeTopic?.id,
            messageCountToget?.length,
            restData,
            true,
            isThreadScreen
          )
        );
      }
      return responseData;
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };

export const handleGetStarMessages =
  (pnId, page = 1, page_size = 50) =>
  async (dispatch) => {
    try {
      const responseData = await axios.get(
        `${process.env.REACT_APP_BASE_PATH}/chats/starred/${pnId}?page=${page}&page_size=${page_size}`,
        headerRequest()
      );
      const starMsgData = handleDecryptMessageData(
        responseData?.data?.data?.messages
      );
      dispatch({
        type: actionTypes.REQUEST_GET_ALLSTAR_MESSAGE,
        payload: { ...responseData?.data?.data, messages: starMsgData },
      });
      return { ...responseData?.data?.data, messages: starMsgData };
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };

export const handleDeleteGroupMessage =
  (msgDetail, isAll) => async (dispatch) => {
    try {
      const responseData = await axios.delete(
        `${process.env.REACT_APP_BASE_PATH}/chats/${msgDetail?.pnId}/${msgDetail?.temp_timetoken}?all=${isAll}`,
        headerRequest()
      );
      return responseData;
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };

export const handleMoveChatToAnotherTopic =
  (pn_id, pubnub_timetoken, topic_id) => async (dispatch) => {
    try {
      const reqBody = {
        topic_id: topic_id,
      };
      const responseData = await axios.put(
        `${process.env.REACT_APP_BASE_PATH}/chats/conversation/${pn_id}/${pubnub_timetoken}`,
        reqBody,
        headerRequest()
      );
      return responseData;
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };

export const handleGetThreadMessage = (reqBody) => async (dispatch) => {
  try {
    const conversationid = reqBody?.thread_id
      ? reqBody?.thread_id
      : reqBody?.id;
    const responsedata = await axios.get(
      `${process.env.REACT_APP_BASE_PATH}/chats/conversation/${conversationid}`,
      headerRequest()
    );
    const { data } = responsedata;
    const decryptedMessageData = handleDecryptMessageData([
      data?.data?.parent,
      ...data?.data?.messages,
    ]);
    dispatch({
      type: actionTypes.RECEIVED_THREAD_MESSAGES,
      payload: decryptedMessageData,
    });
    return data;
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleInstantPubnubPublishThreadMessage =
  (
    reqBody,
    activepnId,
    topic_id,
    temp_timetoken,
    shouldStoreMsgDb = true,
    isThreadScreen,
    isThreadScrollToBottom
  ) =>
  async (dispatch) => {
    await deleteTempMessageData(temp_timetoken);
    const tempMsgData = await getTempStoreMessageData(activepnId);
    dispatch({
      type: actionTypes.RECEIVED_TEMP_MESSAGE_LIST,
      payload: tempMsgData,
    });
    shouldStoreMsgDb && (await storeMessageToDb(reqBody));
    if (reqBody[0]?.pnId === activepnId) {
      if (isThreadScreen && isThreadScrollToBottom === reqBody[0]?.thread_id) {
        dispatch(handleThreadScrollToBottom(reqBody[0]?.thread_id));
      }
      if (isThreadScrollToBottom !== reqBody[0]?.thread_id) {
        dispatch(handleThreadScrollToBottom(null));
      }
      dispatch({
        type: actionTypes.RECEIVED_GROUP_INSTANT_THREAD_MESSAGE_LIST,
        payload: reqBody[0],
      });
      dispatch({
        type: actionTypes.RECEIVED_GROUP_INSTANT_MESSAGE_LIST,
        payload: reqBody[0],
      });
      // if (isThreadScreen) {
      //   dispatch({
      //     type: actionTypes.RECEIVED_GROUP_INSTANT_MESSAGE_LIST,
      //     payload: reqBody[0],
      //   });
      // }
    }
  };

export const handleReadAllMsg = (reqBody, shouldUpdateGrpList = true) => {
  return async (dispatch) => {
    try {
      const responseData = await axios.post(
        `${process.env.REACT_APP_BASE_PATH}/chats/mark-as-read`,
        reqBody,
        headerRequest()
      );
      if (shouldUpdateGrpList) {
        await updateAllmemberswithgroupsMsgCount(reqBody?.ref_id);
        const updateData = await getAllmemberswithgroupsData();
        dispatch({
          type: actionTypes.RECEIVED_ALL_GROUP_SUCCESS,
          payload: updateData,
        });
      }
      return responseData;
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };
};

export const handleEditFileName = (reqBody, id) => async (dispatch) => {
  try {
    const responseData = await axios.post(
      `${process.env.REACT_APP_BASE_PATH}/chats/attachment-rename/${id}`,
      reqBody,
      headerRequest()
    );
    return responseData;
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleMediaDownload = (reqBody) => async (dispatch) => {
  const { location, filename } = reqBody;
  try {
    return `${process.env.REACT_APP_BASE_PATH}/chats/attachment-download?filename=${filename}&location=${location}`;
  } catch (error) {
    return error?.response;
  }
};

export const handleSearchGroupByName = (filterdgroupList) => (dispatch) => {
  dispatch({
    type: actionTypes?.RECEIVED_GROUP_FILTER,
    payload: filterdgroupList,
  });
};

//********************************* AI Related Fun ().  ********************************

export const handleGetAiHistory = (queryParam) => async (dispatch) => {
  try {
    var responsedata = null;
    if (queryParam) {
      responsedata = await axios.get(
        `${process.env.REACT_APP_BASE_PATH}/chats/ai?${queryParam}`,
        headerRequest()
      );
    } else {
      responsedata = await axios.get(
        `${process.env.REACT_APP_BASE_PATH}/chats/ai`,
        headerRequest()
      );
    }

    if (responsedata?.data?.status) {
      dispatch({
        type: actionTypes?.GET_AI_HISTORY_DONE,
        payload: responsedata?.data?.data,
      });
    }
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleDeleteAiHistory = (history_id) => async (dispatch) => {
  try {
    const responseData = await axios.delete(
      `${process.env.REACT_APP_BASE_PATH}/chats/ai/${history_id}`,
      headerRequest()
    );

    return responseData?.data;
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleStar_UnstarAiHistory = (query_id) => async (dispatch) => {
  try {
    const responseData = await axios.put(
      `${process.env.REACT_APP_BASE_PATH}/chats/ai/${query_id}`,
      {},
      headerRequest()
    );

    return responseData?.data;
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleQueryToAi = (reqBody) => async (dispatch) => {
  try {
    const responseData = await axios.post(
      `${process.env.REACT_APP_BASE_PATH}/chats/ai`,
      reqBody,
      headerRequest()
    );
    if (responseData?.data?.code == 200) {
      dispatch({
        type: actionTypes.GET_QUERY_ANSWER_DONE,
        payload: responseData?.data?.data,
      });
      return responseData?.data;
    }
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleUseHistoryQuery = (history_data) => async (dispatch) => {
  dispatch({
    type: actionTypes.GET_QUERY_ANSWER_DONE,
    payload: history_data,
  });
};

export const handleEditAnswer = (editdata) => async (dispatch) => {
  dispatch({
    type: actionTypes.GET_EDIT_ANSWER_DONE,
    payload: editdata,
  });
};

export const clearCurrentAiSessionData = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_CURRENT_AI_SESSION_DATA,
  });
};

//************************** Task Tab Func().**************************

export const handleTaskStart = (task_id,reqBoby) => async (dispatch) => {
  try {
    // https://apptest.arkchat.com/v2/chats/task/4 patch
    const responseData = await axios.patch(
      `${process.env.REACT_APP_BASE_PATH}/chats/task/${task_id}`,
      reqBoby,
      headerRequest()
    );
   
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleArchiveTask = (msg_id, type) => async (dispatch) => {
  try {
    const responseData = await axios.put(
      `${process.env.REACT_APP_BASE_PATH}/chats/archive/${msg_id}`,
      {},
      headerRequest()
    );
    if (responseData?.data?.status) {
      if (type === MessageType?.Task) {
        dispatch({
          type: actionTypes.UPDATE_EXISTING_TASK_LIST,
          payload: responseData?.data?.data,
        });
      } else if (type === MessageType?.Approval) {
        dispatch({
          type: actionTypes.UPDATE_EXISTING_APPROVALLIST_LIST,
          payload: responseData?.data?.data,
        });
      }
    }
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

const getTaskDataFromAPI = async (queryParam = "", page = 1, page_size = 50) => {
  try {
    let url = `${process.env.REACT_APP_BASE_PATH}/chats/tasks?page=${page}&page_size=${page_size}`;
    if (queryParam) {
      url = `${process.env.REACT_APP_BASE_PATH}/chats/tasks?&${queryParam}&page=${page}&page_size=${page_size}`;
    }

    const responseData = await axios.get(url, headerRequest());

    const decryptedData = handleDecryptMessageData(
      responseData?.data?.data?.messages
    );

    if (responseData.status === 200) {
      return { ...responseData?.data?.data, messages: decryptedData };
    }
  } catch (err) {
    // console.error("Background API call error:", err);
  }
};

export const fetchOldTaskTabDataFromApi =
  (queryParam, page, lastData) => async (dispatch) => {
    getTaskDataFromAPI(queryParam, page).then(async (res) => {
      const taskMsgData = res?.messages?.filter(
        (apiMessage) => apiMessage?.timetoken < lastData?.timetoken
      );
      if (taskMsgData?.length > 0) {
        dispatch({
          type: actionTypes.RECEIVED_OLD_TASKLIST_DATA,
          payload: taskMsgData,
        });
      }
    });
  };

export const handleGetAllTaskList =
  (queryParam, page, lastData) => async (dispatch) => {
    try {
      var responsedata = null;
      if (page && lastData) {
        dispatch(fetchOldTaskTabDataFromApi(queryParam, page, lastData));
      } else {
        if (queryParam) {
          responsedata = await axios.get(
            `${process.env.REACT_APP_BASE_PATH}/chats/tasks?${queryParam}`,
            headerRequest()
          );
          const decryptedData = handleDecryptMessageData(
            responsedata?.data?.data?.messages
          );

          dispatch(handleLoading(false));

          dispatch({
            type: actionTypes?.RECEIVED_ALL_TASKLIST,
            payload: decryptedData,
          });
        } else {
          getTaskDataFromAPI().then(async (res) => {
            dispatch(handleLoading(false));
            dispatch({
              type: actionTypes.RECEIVED_ALL_TASKLIST,
              payload: res?.messages,
            });
          });
        }
      }
    } catch (err) {
      dispatch(handleLoading(false));
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };

export const handleGetTaskAnalytics = (queryParam) => async (dispatch) => {
  try {
    var responsedata = null;
    if (queryParam) {
      responsedata = await axios.get(
        `${process.env.REACT_APP_BASE_PATH}/chats/tasks-analytics?${queryParam}`,
        headerRequest()
      );
    } else {
      responsedata = await axios.get(
        `${process.env.REACT_APP_BASE_PATH}/chats/tasks-analytics`,
        headerRequest()
      );
    }

    dispatch({
      type: actionTypes?.RECEIVED_ALL_TASKANALYTICS_DATA,
      payload: responsedata?.data?.data,
    });
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

//************************** Approval Tab Fun().**************************

const getApprovalDataFromAPI = async (
  queryParam = "",
  page = 1,
  page_size = 50
) => {
  try {
    let url = `${process.env.REACT_APP_BASE_PATH}/chats/approvals?page=${page}&page_size=${page_size}`;
    if (queryParam) {
      url = `${process.env.REACT_APP_BASE_PATH}/chats/approvals?&${queryParam}&page=${page}&page_size=${page_size}`;
    }
    const responseData = await axios.get(url, headerRequest());
    const decryptedData = handleDecryptMessageData(
      responseData?.data?.data?.messages
    );

    if (responseData.status === 200) {
      return { ...responseData?.data?.data, messages: decryptedData };
    }
  } catch (err) {
    // console.error("Background API call error:", err);
  }
};

export const fetchOldApprovalTabDataFromApi =
  (queryParam, page, lastData) => async (dispatch) => {
    getApprovalDataFromAPI(queryParam, page).then(async (res) => {
      const approvalMsgData = res?.messages?.filter(
        (apiMessage) => apiMessage?.timetoken < lastData?.timetoken
      );
      if (approvalMsgData?.length > 0) {
        dispatch({
          type: actionTypes.RECEIVED_OLD_APPROVALLIST_DATA,
          payload: approvalMsgData,
        });
      }
    });
  };

export const handleGetAllApprovalList =
  (queryParam, page, lastData) => async (dispatch) => {
    try {
      var responsedata = null;
      if (page && lastData) {
        dispatch(fetchOldApprovalTabDataFromApi(queryParam, page, lastData));
      } else {
        if (queryParam) {
          responsedata = await axios.get(
            `${process.env.REACT_APP_BASE_PATH}/chats/approvals?${queryParam}`,
            headerRequest()
          );
          const decryptedData = handleDecryptMessageData(
            responsedata?.data?.data?.messages
          );
          dispatch({
            type: actionTypes?.RECEIVED_ALL_APPROVALLIST,
            payload: decryptedData,
          });
        } else {
          getApprovalDataFromAPI().then((res) => {
            dispatch({
              type: actionTypes?.RECEIVED_ALL_APPROVALLIST,
              payload: res?.messages,
            });
          });
        }
      }
    } catch (err) {
      dispatch(handleLoading(false));
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };

export const handleGetApprovalAnalytics = (queryParam) => async (dispatch) => {
  try {
    var responsedata = null;
    if (queryParam) {
      responsedata = await axios.get(
        `${process.env.REACT_APP_BASE_PATH}/chats/approvals-analytics?${queryParam}`,
        headerRequest()
      );
    } else {
      responsedata = await axios.get(
        `${process.env.REACT_APP_BASE_PATH}/chats/approvals-analytics`,
        headerRequest()
      );
    }

    dispatch({
      type: actionTypes?.RECEIVED_ALL_APPROVALANALYTICS_DATA,
      payload: responsedata?.data?.data,
    });
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

// ************************** Post **************************

export const handleCreatePost = (reqBody) => async (dispatch) => {
  try {
    const responseData = await axios.post(
      `${process.env.REACT_APP_BASE_PATH}/post`,
      reqBody,
      attachmentheaderRequest()
    );

    const { data } = responseData?.data;
    if (responseData?.data?.status) {
      dispatch({
        type: actionTypes.CREATE_POST_DONE,
        payload: data,
      });
    }
    return responseData;
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleGetPostList = (subscriber_id) => async (dispatch) => {
  try {
    // https://dev-api01.arkchat.com/v1/chats/post/subscriber/22
    const responseData = await axios.get(
      `${process.env.REACT_APP_BASE_PATH}/post/subscriber/${subscriber_id}`,
      headerRequest()
    );

    const { data } = responseData;
    if (data?.status) {
      dispatch({
        type: actionTypes.GET_ALL_POST_LIST_DONE,
        payload: data?.data,
      });
    }
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleDeletePost = (post_id) => async (dispatch) => {
  try {
    const responseData = await axios.delete(
      `${process.env.REACT_APP_BASE_PATH}/post/${post_id}`,
      headerRequest()
    );

    return responseData?.data;
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleFetchPostList =
  ({
    page_size = 50,
    page = 1,
    draft,
    visibility,
    type,
    category,
    bookmark,
    search,
    fetchOld=false
  }) =>
  async (dispatch) => {
    try {
      let baseUrl = `${process.env.REACT_APP_BASE_PATH}/post/posts`;

      const queryParams = [];

      if (page_size) {
        queryParams.push(`page_size=${page_size}`);
      }
      if (page) {
        queryParams.push(`page=${page}`);
      }
      if (draft !== undefined) {
        queryParams.push(`draft=${draft}`);
      }
      if (visibility) {
        queryParams.push(`visibility=${visibility}`);
      }
      if (bookmark) {
        queryParams.push(`bookmark=${bookmark}`);
      }
      if (search) {
        queryParams.push(`search=${search}`);
      }
      if (type) {
        queryParams.push(`type=${type}`);
      }
      if (category) {
        queryParams.push(`category=${category}`);
      }

      // Construct the final URL with query parameters
      if (queryParams.length > 0) {
        baseUrl += `?${queryParams.join("&")}`;
      }

      const responseData = await axios.get(baseUrl, headerRequest());

      const { data } = responseData;
      if (data?.status&&!fetchOld) {
        dispatch({
          type: actionTypes.GET_ALL_POST_LIST_EXPLORE_DONE,
          payload: data?.data,
        });
      }else if(data?.status&&fetchOld){
        dispatch({
          type: actionTypes.GET_OLD_POST_DATA_EXPLORE_DONE,
          payload: data?.data,
        });
      }
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };

export const handleLikeUpdate = (type, id) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_POST_LIST_EXPLORE,
    payload: id,
  });
};

export const handleLikePost = (post_id) => {
  return async (dispatch) => {
    try {
      const responseData = await axios.put(
        `${process.env.REACT_APP_BASE_PATH}/post/like/${post_id}`,
        {},
        headerRequest()
      );
      return responseData;
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };
};

export const handleViewLikes = (post_id) => {
  return async (dispatch) => {
    try {
      // https://apptest.arkchat.com/v2/onboarding/user/profile/18
      // https://apptest.arkchat.com/v2/post/viewlike/3
      const responseData = await axios.get(
        `${process.env.REACT_APP_BASE_PATH}/post/viewlike/${post_id}`,
        headerRequest()
      );
      const { data } = responseData?.data;
      if (responseData?.data?.status) {
        dispatch({
          type: actionTypes.GET_LIKE_USER_LIST,
          payload: data,
        });
      }
      return responseData;
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };
};

export const handleBookmarkPost = (post_id) => {
  return async (dispatch) => {
    try {
      const responseData = await axios.put(
        `${process.env.REACT_APP_BASE_PATH}/post/bookmark/${post_id}`,
        {},
        headerRequest()
      );
      return responseData;
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };
};

export const getSellerTabPost = (queryParam) => async (dispatch) => {
  try {
    var responsedata = null;
    if (queryParam) {
      responsedata = await axios.get(
        `${process.env.REACT_APP_BASE_PATH}/post/sellers?${queryParam}`,
        headerRequest()
      );
    } else {
      responsedata = await axios.get(
        `${process.env.REACT_APP_BASE_PATH}/post/sellers`,
        headerRequest()
      );
    }
    const { data } = responsedata?.data;
    dispatch({
      type: actionTypes.GET_ALL_SELLER_TAB_POSTS,
      payload: data,
    });
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const sellerCollaborate = (post_id) => async (dispatch) => {
  try {
    let responsedata = await axios.get(
      `${process.env.REACT_APP_BASE_PATH}/post/collaborate/${post_id}`,
      headerRequest()
    );
    return responsedata?.data;
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const sellerConnect = (post_id) => async (dispatch) => {
  try {
    var responsedata = null;
    responsedata = await axios.get(
      `${process.env.REACT_APP_BASE_PATH}/post/connect/${post_id}`,
      headerRequest()
    );

    const { data } = responsedata?.data;
    dispatch(showToastMsg(responsedata?.data?.message));
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

// ****************** Brand *****************

export const handleGetBrandList = () => async (dispatch) => {
  try {
    const responseData = await axios.get(
      `${process.env.REACT_APP_BASE_PATH}/post/brands`,
      headerRequest()
    );

    const { data } = responseData;
    if (data?.status) {
      dispatch({
        type: actionTypes.GET_ALL_BRAND_LIST_DONE,
        payload: data?.data,
      });
    }
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleAddBrand = (reqBody) => {
  return async (dispatch) => {
    try {
      const responseData = await axios.post(
        `${process.env.REACT_APP_BASE_PATH}/post/brand`,
        reqBody,
        headerRequest()
      );

      const { data } = responseData?.data;

      if (responseData?.data?.status) {
      }
      return responseData;
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };
};

export const handleEditBrand = (reqBody, brand_id) => {
  return async (dispatch) => {
    try {
      const responseData = await axios.put(
        `${process.env.REACT_APP_BASE_PATH}/post/brand/${brand_id}`,
        reqBody,
        headerRequest()
      );

      const { data } = responseData?.data;

      if (responseData?.data?.status) {
      }
      return responseData;
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };
};

export const handleDeleteBrand = (brand_id) => async (dispatch) => {
  try {
    const responseData = await axios.delete(
      `${process.env.REACT_APP_BASE_PATH}/post/brand/${brand_id}`,
      headerRequest()
    );
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

//************************** Artists **************************

export const handleGetArtistList = () => async (dispatch) => {
  try {
    const responseData = await axios.get(
      `${process.env.REACT_APP_BASE_PATH}/post/artists`,
      headerRequest()
    );

    const { data } = responseData;
    if (data?.status) {
      dispatch({
        type: actionTypes.GET_ALL_ARTISTS_LIST_DONE,
        payload: data?.data,
      });
    }
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleAddArtist = (reqBody) => {
  return async (dispatch) => {
    try {
      const responseData = await axios.post(
        `${process.env.REACT_APP_BASE_PATH}/post/artist`,
        reqBody,
        headerRequest()
      );

      const { data } = responseData?.data;

      if (responseData?.data?.status) {
      }
      return responseData;
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };
};

export const handleEditArtist = (reqBody, artist_id) => {
  return async (dispatch) => {
    try {
      const responseData = await axios.put(
        `${process.env.REACT_APP_BASE_PATH}/post/artist/${artist_id}`,
        reqBody,
        headerRequest()
      );

      const { data } = responseData?.data;

      if (responseData?.data?.status) {
      }
      return responseData;
    } catch (err) {
      dispatch({ type: REQUEST_FAILED, payload: err?.response });
    }
  };
};

export const handleDeleteArtist = (artist_id) => async (dispatch) => {
  try {
    const responseData = await axios.delete(
      `${process.env.REACT_APP_BASE_PATH}/post/artist/${artist_id}`,
      headerRequest()
    );
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};

export const handleEditCreatePost = (reqBody, post_id) => async (dispatch) => {
  try {
    const responseData = await axios.post(
      `${process.env.REACT_APP_BASE_PATH}/post/${post_id}`,
      reqBody,
      attachmentheaderRequest()
    );

    const { data } = responseData?.data;
    if (responseData?.data?.status) {
      dispatch({
        type: actionTypes.CREATE_POST_DONE,
        payload: data,
      });
    }
    return responseData;
  } catch (err) {
    dispatch({ type: REQUEST_FAILED, payload: err?.response });
  }
};
