import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import searchIcon from "../../../assets/images/chats/gallery_active.svg";
import {
  ChatSearchButton,
  CustomInput,
  SearchBox,
  SmallIcon,
} from "../../../common/chatComponents/ScreenSearchSection";
import google_image from "../../../assets/images/chats/google_image.svg";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    padding: "4px",
    borderRadius: "2px",
    cursor: "pointer",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const GoogleAttachmentDrawer = ({
  handleCloseGoogleDrawer,
  handleSendGoogleAttachment,
  fileAttachment,
}) => {
  const [inputvalue, setInputValue] = useState("");
  const [googleLinkList, setGoogleLinkList] = useState([]);
  const [selectedgoogleLinkList, setSelectedGoogleLinkList] = useState([]);
  const [isCheckBoxDisabled, setIsCheckBoxDisabled] = useState(false);

  useEffect(() => {
    const combinedLength =
      fileAttachment?.length + selectedgoogleLinkList?.length;

    // Setting the disabled state of the checkbox not more than 5 overall attachment
    setIsCheckBoxDisabled(combinedLength === 5);
  }, [fileAttachment, selectedgoogleLinkList]);
  const handleClose = () => {
    setInputValue("");
    setSelectedGoogleLinkList([]);
    setGoogleLinkList([]);
    handleCloseGoogleDrawer();
  };

  const handleAttachedYouTube = () => {
    const selectedLink = googleLinkList
      ?.map((item) => {
        if (selectedgoogleLinkList?.includes(item?.link)) {
          return {
            ...item,
            videoLink: item?.link,
          };
        }
      })
      ?.filter(Boolean);

    handleSendGoogleAttachment(selectedLink);
  };

  const handleSearch = async () => {
    const response = await axios.get(
      "https://www.googleapis.com/customsearch/v1",
      {
        params: {
          key: process.env.REACT_APP_FIREBASE_API_KEY,
          q: inputvalue,
          cx: "831a5dbab64aa4a0a",
        },
      }
    );

    //

    const { items } = response?.data;

    // const filteredGoogleLink = items?.map((item, index) => {
    //   return {
    //     cacheId: item?.cacheId,
    //     link: item?.link,
    //     title: item?.title,
    //   };
    // });

    setGoogleLinkList(items);
  };

  const handleInputValue = (event) => {
    setInputValue(event.target.value);
  };

  const handleSelectVideo = (event, item) => {
    if (event.target.checked) {
      setSelectedGoogleLinkList([...selectedgoogleLinkList, item?.link]);
    } else {
      const filteredData = selectedgoogleLinkList?.filter(
        // eslint-disable-next-line eqeqeq
        (nesteditem) => nesteditem != item?.link
      );
      setSelectedGoogleLinkList(filteredData);
    }
  };
  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={classes.header}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography sx={classes.iconDiv}>
                <CloseIcon sx={classes.iconn} onClick={handleClose} />
              </Typography>
              <Typography color={"#000000"}>Google</Typography>
            </Stack>
          </Stack>

          <Stack width={"32%"} margin={"auto"} mt={"0.5rem"} mb={"0.5rem"}>
            <img src={google_image} alt="google" />
          </Stack>

          <Stack width={"90%"} margin={"auto"}>
            <SearchBox>
              <CustomInput
                autoFocus
                value={inputvalue}
                onChange={handleInputValue}
                placeholder="Search"
              />
              <ChatSearchButton
                onClick={handleSearch}
                size="small"
                variant="contained"
              >
                <SmallIcon src={searchIcon} />
              </ChatSearchButton>
            </SearchBox>
          </Stack>
          <>
            {googleLinkList?.length > 0 && (
              <Box
                mt={"0.6rem"}
                maxHeight={"24rem"}
                overflow={"auto"}
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888",
                    borderRadius: "3px",
                  },
                }}
              >
                {googleLinkList?.map((item) => (
                  <Stack
                    direction="row"
                    padding="12px 19px 12px 12px"
                    maxWidth={"92%"}
                    margin={"auto"}
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    key={item.link}
                  >
                    <Box maxWidth={"85%"}>
                      <Box>
                        <a
                          style={{
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            color: "grey",
                          }}
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.pagemap?.cse_image?.length > 0 &&
                            item.pagemap?.cse_image[0]?.src && (
                              <Box>
                                <img
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                    marginRight: "10px",
                                  }}
                                  src={item.pagemap?.cse_image[0]?.src}
                                  alt=""
                                />
                              </Box>
                            )}
                          <Box>
                            <Typography>{item.displayLink}</Typography>
                          </Box>
                        </a>
                      </Box>
                      <Box>
                        <a
                          style={{ textDecoration: "none" }}
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Typography
                            variant="h5"
                            fontSize={"1rem"}
                            sx={{
                              maxWidth: "90%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.title}
                          </Typography>
                        </a>
                      </Box>
                      <Box>
                        <Typography
                          fontSize={"0.85rem"}
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                            lineClamp: 3,
                          }}
                        >
                          {item.snippet}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <FormControlLabel
                        disabled={
                          isCheckBoxDisabled &&
                          !selectedgoogleLinkList?.includes(item?.cacheId)
                        }
                        control={
                          <Checkbox
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 30,color: "#183243" } }}
                          />
                        }
                        label={""}
                        name={item?.link}
                        checked={selectedgoogleLinkList?.includes(item?.link)}
                        onChange={(event) => handleSelectVideo(event, item)}
                      />
                    </Box>
                  </Stack>
                ))}
              </Box>
            )}
          </>
        </Stack>

        <Stack
          alignItems="center"
          spacing={1}
          padding="12px 0px"
          margin={"0 auto"}
          width={"360px"}
          position={"relative"}
        >
          <Button
            onClick={handleAttachedYouTube}
            style={{
              background: "#DB0011",
              color: "white",
              borderRadius: "2px",
            }}
            variant="contained"
          >
            Done
          </Button>
          <Box position={"absolute"} right={"1.5rem"}>
            <Typography fontSize={"0.85rem"}>
              {fileAttachment?.length + selectedgoogleLinkList?.length}/5
            </Typography>
          </Box>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default GoogleAttachmentDrawer;
