import React from "react";
import ProfileForm from "./ProfileForm";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import IntroVideo from "../../common/IntroVideo";
const classes = {
  Wrapper: {
    display: "flex",
    "@media only screen and (min-width: 1901px)": {
      width: "80%",
      margin: "auto",
    },
  },
};
const OrganisationProfile = () => {
  const profileData = useSelector((state) => state?.profile?.profileData);
  return (
    <Box m={2}>
      <Grid container sx={classes?.Wrapper}>
        <Grid item xs={12} md={5}>
        <IntroVideo />
        </Grid>
        <Grid ml={"auto"} padding={"0.4rem 1rem"} item xs={12} md={6}>
          <ProfileForm profileData={profileData} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrganisationProfile;
