import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createStyledIndicatorButton } from "../../../common/StyledComponents/SideBarComponents";
import {
  AccountLevelArkchatPermission,
  GroupLevelArkchatPermission,
  InviteType,
} from "../../../utils/constant";
import useAppSelectors from "../../../customHooks/useAppSelectors";
import { useTranslation } from "react-i18next";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  radiogp: {
    display: "block",
  },
};

const UserListDrawer = ({
  groupMemberData,
  internalMemebrData,
  externalMemberData,
  externalGroupRecipient,
  externalGroupMember,
  handleSelectCompanyAllRecipients,
  handleSelectExternalRecipients,
  handleCloseUserChatAttach,
  handleClostRecipientsDrawer,
  handleNextStep,
  handleSelectedRecipients,
  setMessageType,
  newMessages,
  handleOnChangeNewMessage,
  selectedRecipients,
  handleCLickTodo,
  setStepper,
}) => {
  const NameBadge = createStyledIndicatorButton("#E3E1E1");
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const { currentUserData, threadMsgList, isThreadScreen } = useAppSelectors();
  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={classes.header}
          >
            <Typography sx={classes.iconDiv}>
              <CloseIcon
                sx={classes.iconn}
                onClick={handleClostRecipientsDrawer}
              />
            </Typography>
            <Typography color={"black"}>
              Select recipients and assign To Do
            </Typography>
          </Stack>
          <Box
            mt={"0.5rem"}
            overflow={"scroll"}
            height={"calc(100vh - 14rem)"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "2px",
                padding: "0px 2px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#F9F8F8",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "grey",
              },
            }}
          >
            <Box padding="0px 19px 0px 12px">
              <Typography variant="caption" color={"black"}>
                {appContent?.msgSentInstruction}
              </Typography>
            </Box>
            {groupMemberData?.internalMember?.length > 0 &&
              groupMemberData?.internalMember?.map((memberData, i) => (
                <Box key={memberData.userId + "recipientList"}>
                  {i === 0 && (
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                        padding="0px 19px 0px 12px"
                      >
                        <FormControlLabel
                          sx={{
                            ".MuiFormControlLabel-label": {
                              fontWeight: 500,
                              color: "#000000",
                            },
                          }}
                          control={
                            <Checkbox
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 30,
                                  color: "#183243",
                                },
                              }}
                              // disabled={
                              //   isThreadScreen &&
                              //   selectedRecipients?.selectedInternalRecipients
                              //     ?.length +
                              //     selectedRecipients?.selectedExternalRecipients
                              //       ?.length ===
                              //     internalMemebrData?.length +
                              //       externalGroupRecipient?.length
                              // }
                              checked={
                                selectedRecipients?.selectedInternalRecipients
                                  ?.length +
                                  selectedRecipients?.selectedExternalRecipients
                                    ?.length ===
                                internalMemebrData?.length +
                                  externalGroupRecipient?.length
                              }
                              onChange={(event) =>
                                handleSelectedRecipients(
                                  event,
                                  null,
                                  "AllMember"
                                )
                              }
                              icon={
                                <NameBadge
                                  sx={{ color: "#183243" }}
                                  size="small"
                                  variant="contained"
                                >
                                  <Typography
                                    color={"#183243"}
                                    fontSize={"0.75rem"}
                                    fontWeight={600}
                                  >
                                    A
                                  </Typography>
                                </NameBadge>
                              }
                            />
                          }
                          label={
                            <Typography
                              maxWidth={"25rem"}
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                              fontSize={"0.88rem"}
                              fontWeight={600}
                            >
                              All Group Members
                            </Typography>
                          }
                        />
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                        padding="0px 19px 0px 12px"
                      >
                        <FormControlLabel
                          sx={{
                            ".MuiFormControlLabel-label": {
                              fontWeight: 600,
                              color: "#000000",
                            },
                          }}
                          control={
                            <Checkbox
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 30,
                                  color: "#183243",
                                },
                              }}
                              // disabled={
                              //   isThreadScreen &&
                              //   (selectedRecipients?.selectedInternalRecipients
                              //     ?.length +
                              //     selectedRecipients?.selectedExternalRecipients
                              //       ?.length ===
                              //     internalMemebrData?.length +
                              //       externalGroupRecipient?.length ||
                              //     selectedRecipients
                              //       ?.selectedInternalRecipients?.length ===
                              //       internalMemebrData?.length)
                              // }
                              checked={
                                selectedRecipients?.selectedInternalRecipients
                                  ?.length === internalMemebrData?.length
                              }
                              onChange={(e) =>
                                handleSelectedRecipients(e, null, "MyTeam")
                              }
                              icon={
                                <NameBadge
                                  sx={{ color: "#183243" }}
                                  size="small"
                                  variant="contained"
                                >
                                  <Typography
                                    color={"#183243"}
                                    fontSize={"0.75rem"}
                                    fontWeight={600}
                                  >
                                    {
                                      currentUserData?.activeSubscriber
                                        ?.subscriberFirmName[0]
                                    }
                                  </Typography>
                                </NameBadge>
                              }
                            />
                          }
                          label={
                            <Typography
                              title={
                                currentUserData?.activeSubscriber
                                  ?.subscriberFirmName
                              }
                              maxWidth={"25rem"}
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                              fontSize={"0.88rem"}
                              fontWeight={600}
                            >
                              My Team
                            </Typography>
                          }
                        />
                      </Stack>
                    </>
                  )}
                  {memberData?.permissionType !==
                    GroupLevelArkchatPermission?.REMOVE && (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      padding={"0rem 0.7rem 0rem 2rem"}
                    >
                      <FormControlLabel
                        sx={{
                          ".MuiFormControlLabel-label": {
                            fontSize: "0.78rem !important",
                            color: "#000000",
                          },
                        }}
                        control={
                          <Checkbox
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 25,
                                color: "#183243",
                              },
                            }}
                            disabled={
                              isThreadScreen &&
                              threadMsgList[0]?.participants?.includes(
                                memberData.userId
                              )
                            }
                            onChange={(e) =>
                              handleSelectedRecipients(e, memberData)
                            }
                            checked={selectedRecipients?.selectedInternalRecipients?.some(
                              (item) => item?.userId === memberData.userId
                            )}
                            icon={
                              <NameBadge
                                sx={{
                                  width: "1.55rem",
                                  height: "1.55rem",
                                  color: "#183243",
                                  padding: memberData?.userInfo?.profileImgUrl
                                    ? "0rem"
                                    : "0.25rem 0.62rem",
                                  overflow: "hidden",
                                }}
                                size="small"
                                variant="contained"
                              >
                                {memberData?.userInfo?.profileImgUrl ? (
                                  <img
                                    style={{ width: "100%", height: "100%" }}
                                    src={memberData?.userInfo?.profileImgUrl}
                                    alt={memberData?.userInfo?.firstName}
                                  />
                                ) : (
                                  <Typography
                                    color={"#183243"}
                                    fontSize={"0.75rem"}
                                  >
                                    {memberData?.userInfo?.firstName &&
                                      memberData?.userInfo?.firstName[0]}
                                  </Typography>
                                )}
                              </NameBadge>
                            }
                          />
                        }
                        label={
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={"0.15rem"}
                          >
                            <Typography
                              title={`${memberData?.userInfo?.firstName} ${memberData?.userInfo?.lastName}`}
                              maxWidth={"20rem"}
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                              fontSize={"0.78rem"}
                            >
                              {`${memberData?.userInfo?.firstName} ${memberData?.userInfo?.lastName}`}
                              <>
                                {memberData?.permission ===
                                  AccountLevelArkchatPermission?.ADMIN && ","}
                              </>
                            </Typography>
                            {memberData?.permission ===
                              AccountLevelArkchatPermission?.ADMIN &&
                              memberData?.inviteType ===
                                InviteType?.internalOwnerInviteType && (
                                <Typography fontSize={"0.7rem"}>
                                  Owner
                                </Typography>
                              )}
                            {memberData?.permission ===
                              AccountLevelArkchatPermission?.ADMIN &&
                              memberData?.inviteType ===
                                InviteType?.internal && (
                                <Typography fontSize={"0.7rem"}>
                                  Admin
                                </Typography>
                              )}
                          </Box>
                        }
                      />
                      <Button
                        onClick={() => handleCLickTodo(memberData)}
                        variant="outlined"
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "3px",
                          padding: "0px",
                          textTransform: "none",
                          color: "#183243",
                          borderColor: "#183243",
                          ":hover": {
                            color: "#183243",
                            borderRadius: "3px",
                            borderColor: "#183243",
                          },
                        }}
                      >
                        To Do
                      </Button>
                    </Stack>
                  )}
                </Box>
              ))}
            <Box>
              {externalMemberData.map((company, index) => (
                <Box key={index + "externalRecipientGroup"}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    padding="0px 19px 0px 12px"
                  >
                    <FormControlLabel
                      sx={{
                        ".MuiFormControlLabel-label": {
                          fontWeight: 600,
                          color: "#000000",
                        },
                      }}
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 30,
                              color: "#183243",
                            },
                          }}
                          // disabled={
                          //   isThreadScreen &&
                          //   (selectedRecipients?.selectedInternalRecipients
                          //     ?.length +
                          //     selectedRecipients?.selectedExternalRecipients
                          //       ?.length ===
                          //     internalMemebrData?.length +
                          //       externalGroupRecipient?.length ||
                          //     Object.values(company)[0]?.length ===
                          //       externalGroupMember[Object.keys(company)[0]]
                          //         ?.length)
                          // }
                          checked={
                            Object.values(company)[0]?.length ===
                            externalGroupMember[Object.keys(company)[0]]?.length
                          }
                          onChange={(event) =>
                            handleSelectCompanyAllRecipients(
                              event,
                              company,
                              Object.keys(company)[0]
                            )
                          }
                          icon={
                            <NameBadge
                              sx={{ color: "#183243" }}
                              size="small"
                              variant="contained"
                            >
                              <Typography
                                color={"#183243"}
                                fontSize={"0.75rem"}
                                fontWeight={600}
                              >
                                {Object.keys(company)[0] &&
                                  Object.keys(company)[0][0]}
                              </Typography>
                            </NameBadge>
                          }
                        />
                      }
                      label={
                        <Typography
                          title={Object.keys(company)[0]}
                          maxWidth={"25rem"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                          fontSize={"0.88rem"}
                          fontWeight={600}
                        >
                          {Object.keys(company)[0]}
                        </Typography>
                      }
                    />
                  </Stack>

                  {Object.values(company)[0]?.map((item, i) => (
                    <Stack
                      key={i + "externalRecipientGroupCompany"}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      padding={"0rem 0.7rem 0rem 2rem"}
                    >
                      <FormControlLabel
                        sx={{
                          ".MuiFormControlLabel-label": {
                            fontSize: "0.78rem !important",
                            color: "#000000",
                          },
                        }}
                        control={
                          <Checkbox
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 25,
                                color: "#183243",
                              },
                            }}
                            disabled={
                              isThreadScreen &&
                              threadMsgList[0]?.participants?.includes(
                                item.userId
                              )
                            }
                            onChange={(event) =>
                              handleSelectExternalRecipients(
                                event,
                                item,
                                Object.keys(company)[0],
                                Object.values(company)[0]
                              )
                            }
                            checked={selectedRecipients?.selectedExternalRecipients?.some(
                              (user) => user?.userId === item.userId
                            )}
                            icon={
                              <NameBadge
                                sx={{
                                  width: "1.55rem",
                                  height: "1.55rem",
                                  color: "#183243",
                                }}
                                size="small"
                                variant="contained"
                              >
                                <Typography
                                  color={"#183243"}
                                  fontSize={"0.75rem"}
                                >
                                  {item?.firstName && item?.firstName[0]}
                                </Typography>
                              </NameBadge>
                            }
                          />
                        }
                        label={
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={"0.15rem"}
                          >
                            <Typography
                              title={`${item?.firstName} ${item?.lastName}`}
                              maxWidth={"20rem"}
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                              fontSize={"0.78rem"}
                            >
                              {`${item?.firstName} ${item?.lastName}`}
                              <>
                                {item?.accountPermission ===
                                  AccountLevelArkchatPermission?.ADMIN && ","}
                              </>
                            </Typography>
                            {item?.accountPermission ===
                              AccountLevelArkchatPermission?.ADMIN &&
                              item?.inviteType === InviteType?.external && (
                                <Typography fontSize={"0.7rem"}>
                                  Admin
                                </Typography>
                              )}
                            {/* {item?.accountPermission ===
                              AccountLevelArkchatPermission?.ADMIN &&
                              item?.inviteType ===
                                InviteType?.internal && (
                                <Typography fontSize={"0.7rem"}>
                                  Admin
                                </Typography>
                              )} */}
                          </Box>
                        }
                      />
                      <Button
                        onClick={() => handleCLickTodo(item, false)}
                        variant="outlined"
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "3px",
                          padding: "0px",
                          textTransform: "none",
                          color: "#183243",
                          borderColor: "#183243",
                          ":hover": {
                            color: "#183243",
                            borderRadius: "3px",
                            borderColor: "#183243",
                          },
                        }}
                      >
                        To Do
                      </Button>
                    </Stack>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default UserListDrawer;
