import React from "react";
import { Box, Typography } from "@mui/material";
import { createStyledIndicatorButton } from "../../../common/StyledComponents/SideBarComponents";
import AILoder from "./AILoder";
const TempQuestionBox = ({ tempAiQuery }) => {
  const BadgeButton = createStyledIndicatorButton("#F3F3F3");

  return (
    <Box>
      <Box display={"flex"} maxWidth={"96%"} alignItems={"center"}>
        <Box mr={"0.5rem"}>
          <BadgeButton size="small" variant="contained">
            <Typography
              variant="subtitle2"
              fontSize={"0.87rem"}
              color={"#183242"}
              fontWeight={600}
            >
              Q
            </Typography>
          </BadgeButton>
        </Box>

        <Box maxWidth={"90%"}>
          <Typography
            variant="subtitle2"
            fontSize={"0.87rem"}
            color={"#000000"}
            fontWeight={600}
          >
            {tempAiQuery}
          </Typography>
        </Box>
      </Box>
      <AILoder />
    </Box>
  );
};

export default TempQuestionBox;
