import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import photosLogo from "../../assets/images/post/photos.svg";
import productLogo from "../../assets/images/post/Product.svg";
import artWorkLogo from "../../assets/images/post/Artwork.svg";
import serviceLogo from "../../assets/images/post/service.svg";
import videoLogo from "../../assets/images/post/videos.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AccountLevelArkchatPermission,
  accountType,
} from "../../utils/constant";
import AlertPopUp from "../../common/AlertPopUp";
import { getCountryList } from "../../appStore/actions/masterData/masterDataAction";
import { useTranslation } from "react-i18next";
import { handleSavePreviewEditPostData, handleSetserviceTypePost } from "../../appStore/actions/AppState/action";
import { StyledSideBarInviteButton } from "../../common/StyledComponents/SideBarComponents";
const organistationServiceTypeData = [
  { image: productLogo, title: "Products", serviceType: 2 },
  { image: artWorkLogo, title: "Art", serviceType: 3 },
  { image: serviceLogo, title: "Service", serviceType: 1 },
  { image: photosLogo, title: "Photos", serviceType: 4 },
  { image: videoLogo, title: "Videos", serviceType: 5 },
];

const individualServiceTypedata = [
  { image: artWorkLogo, title: "Art", serviceType: 3 },
  { image: serviceLogo, title: "Service", serviceType: 1 },
  { image: photosLogo, title: "Photos", serviceType: 4 },
  { image: videoLogo, title: "Videos", serviceType: 5 },
];

const PostLandingpage = () => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [isAccessDenied, setAccessDenied] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.profile?.profileData);

  const handleClickCard = (type) => {
    localStorage.clear();
    dispatch(handleSavePreviewEditPostData({}));
    localStorage.setItem("tabValue", 0);
    if (
      userData?.data?.activeSubscriber?.subscriberPriority ===
        accountType?.Organisation &&
      userData?.data?.activePermission !== AccountLevelArkchatPermission?.USER
    ) {
      dispatch(handleSetserviceTypePost(type));
      navigate("/post/post-create");
    } else if (
      userData?.data?.activeSubscriber?.subscriberPriority ===
        accountType?.Individual &&
      userData?.data?.activePermission !==
        AccountLevelArkchatPermission?.USER &&
      userData?.data?.activePermission !==
        AccountLevelArkchatPermission?.MANAGER
    ) {
      dispatch(handleSetserviceTypePost(type));
      navigate("/post/post-create");
    } else {
      setAccessDenied(true);
    }
  };

  useEffect(() => {
    dispatch(getCountryList());
  }, [dispatch]);
  return (
    <Box>
      <AlertPopUp
        title={"Access Denied ."}
        bodyContent={appContent?.postAlert}
        open={isAccessDenied}
        closeModal={setAccessDenied}
      />
      <Box
        bgcolor={"#F3F3F3"}
        height={"1.5rem"}
        position={"relative"}
        mb={"5rem"}
        textAlign={"end"}
      >
        <Box
          padding={"0rem 0.5rem 0rem 0rem"}
          onClick={() => navigate("/post/manage-mypost")}
        >
          <StyledSideBarInviteButton sx={{fontWeight:600}}>My Posts</StyledSideBarInviteButton>
        </Box>
      </Box>

      <Box width={"70%"} textAlign={"center"} margin={"auto"}>
        <Typography fontSize={"1rem"} variant="h6" fontWeight={600}>
          {appContent?.postLandingHeading}
        </Typography>
      </Box>

      <Box
        display={"flex"}
        justifyContent={"center"}
        gap={"0.8rem"}
        width={"70%"}
        margin={"auto"}
        mt={"1.5rem"}
      >
        <>
          {userData?.data?.activeSubscriber?.subscriberPriority ===
            accountType?.Organisation && (
            <>
              {userData?.data?.activeSubscriber?.accountServiceType === 0 ? (
                <>
                  {organistationServiceTypeData?.map((item) => (
                    <Box
                      key={item?.image + "organistationServiceTypeData"}
                      sx={{
                        border: "1px solid #DEDEDE",
                        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.13)",
                        borderRadius: "1rem",
                        width: "15%",
                        padding: "0.8rem 0rem",
                        cursor: "pointer",
                      }}
                      onClick={() => handleClickCard(item?.serviceType)}
                    >
                      <Box
                        textAlign={"center"}
                        width={"80%"}
                        margin={"auto"}
                        mt={"0.5rem"}
                      >
                        <img src={item?.image} alt={item?.title} />
                      </Box>
                      <Box
                        textAlign={"center"}
                        width={"80%"}
                        margin={"auto"}
                        mt={"0.5rem"}
                      >
                        <Typography
                          variant="h6"
                          fontSize={"1rem"}
                          fontWeight={400}
                        >
                          {item?.title}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </>
              ) : (
                <>
                  {organistationServiceTypeData?.map((item) => (
                    <Box
                      key={item?.image + "organistationServiceTypeData2"}
                      sx={{
                        border: "1px solid #DEDEDE",
                        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.13)",
                        borderRadius: "1rem",
                        width: "15%",
                        padding: "0.8rem 0rem",
                        cursor: "pointer",
                      }}
                      display={
                        userData?.data?.activeSubscriber?.accountServiceType ===
                          item?.serviceType ||
                        item?.serviceType === 4 ||
                        item?.serviceType === 5
                          ? ""
                          : "none"
                      }
                      onClick={() => handleClickCard(item?.serviceType)}
                    >
                      <Box
                        textAlign={"center"}
                        width={"80%"}
                        margin={"auto"}
                        mt={"0.5rem"}
                      >
                        <img src={item?.image} alt={item?.title} />
                      </Box>
                      <Box
                        textAlign={"center"}
                        width={"80%"}
                        margin={"auto"}
                        mt={"0.5rem"}
                      >
                        <Typography
                          variant="h6"
                          fontSize={"1rem"}
                          fontWeight={400}
                        >
                          {item?.title}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </>
              )}
            </>
          )}
        </>

        <>
          {userData?.data?.activeSubscriber?.subscriberPriority ===
            accountType?.Individual && (
            <>
              {userData?.data?.activeSubscriber?.accountServiceType === 0 ? (
                <>
                  {" "}
                  {individualServiceTypedata?.map((item) => (
                    <Box
                      key={item?.image + "individualServiceTypedata"}
                      sx={{
                        border: "1px solid #DEDEDE",
                        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.13)",
                        borderRadius: "1rem",
                        width: "15%",
                        padding: "0.8rem 0rem",
                        cursor: "pointer",
                      }}
                      onClick={() => handleClickCard(item?.serviceType)}
                    >
                      <Box
                        textAlign={"center"}
                        width={"80%"}
                        margin={"auto"}
                        mt={"0.5rem"}
                      >
                        <img src={item?.image} alt={item?.title} />
                      </Box>
                      <Box
                        textAlign={"center"}
                        width={"80%"}
                        margin={"auto"}
                        mt={"0.5rem"}
                      >
                        <Typography
                          variant="h6"
                          fontSize={"1rem"}
                          fontWeight={400}
                        >
                          {item?.title}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </>
              ) : (
                <>
                  {individualServiceTypedata?.map((item) => (
                    <Box
                      key={item?.image + "individualServiceTypedata2"}
                      sx={{
                        border: "1px solid #DEDEDE",
                        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.13)",
                        borderRadius: "1rem",
                        width: "15%",
                        padding: "0.8rem 0rem",
                        cursor: "pointer",
                      }}
                      display={
                        userData?.data?.activeSubscriber?.accountServiceType ===
                          item?.serviceType ||
                        item?.serviceType === 4 ||
                        item?.serviceType === 5
                          ? ""
                          : "none"
                      }
                      onClick={() => handleClickCard(item?.serviceType)}
                    >
                      <Box
                        textAlign={"center"}
                        width={"80%"}
                        margin={"auto"}
                        mt={"0.5rem"}
                      >
                        <img src={item?.image} alt={item?.title} />
                      </Box>
                      <Box
                        textAlign={"center"}
                        width={"80%"}
                        margin={"auto"}
                        mt={"0.5rem"}
                      >
                        <Typography
                          variant="h6"
                          fontSize={"1rem"}
                          fontWeight={400}
                        >
                          {item?.title}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </>
              )}
            </>
          )}
        </>
      </Box>

      <Box width={"25%"} margin={"auto"} textAlign={"center"} mt={"2.5rem"}>
        <Typography>
          {appContent?.postLandingPageContent}
        </Typography>
      </Box>
    </Box>
  );
};

export default PostLandingpage;
