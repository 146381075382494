import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Switchs from "./Switchs";
import { useSelector } from "react-redux";
import {
  AccountLevelArkchatPermission,
  GroupLevelArkchatPermission,
  InviteType,
} from "../../../utils/constant";
import { createStyledIndicatorButton } from "../../../common/StyledComponents/SideBarComponents";
import { groupExternalMember } from "../../../utils/externalMemberGroping";
import { useTranslation } from "react-i18next";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#000000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },

  internalExternalButton: {
    background: "white",
    border: "1px solid #183243",
    color: "#183243",
    borderRadius: "0px",
    fontSize: "0.75rem",
    textTransform: "none",
    "&:hover": {
      background: "white",
    },
  },

  activeButton: {
    "&:hover": {
      background: "#183243",
    },
    background: "#183243",
    borderRadius: "0px",
    color: "white",
    fontSize: "0.75rem",
    textTransform: "none",
    border: "1px solid #183243",
  },

  radiogp: {
    display: "block",
  },
  groupMember: {
    backgroundColor: "#F3F3F3",
    padding: "10px 10px",
  },
};

const CreateGroup = ({
  handleClosegroupdrawer,
  handlesavearkchatgroup,
  handleNameChange,
  newGroupName,
  isRequired,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const NameBadge = createStyledIndicatorButton("#E3E1E1");
  const [firsttimesnackvisible, setfirsttimesnackvisible] = useState(true);
  const [externalGroupMember, setExternalGroupMemebr] = useState({});
  const [externalMemberData, setExternalMemberData] = useState([]);
  const [activeButton, setActiveButton] = useState("internal");

  const accountLevelPeople = useSelector(
    (state) => state?.arkchatgroup?.accountLevelPeople
  );

  const userData = useSelector((state) => state?.profile);
  const [selectedGroupMember, setSelectedGroupMember] = useState({
    selectedInternalGroupMember: [],
    selectedExternalGroupMember: [],
  });
  const [selectedInternalMember, setSelectedInternalMember] = useState([]);
  const [memberPermission, setMemberPermission] = useState([]);

  useEffect(() => {
    if (accountLevelPeople?.externalMember?.length > 0) {
      const groupedExternalData = groupExternalMember(
        accountLevelPeople?.externalMember,
        false,
        appContent?.individual
      );
      setExternalMemberData(groupedExternalData);
    }
  }, [accountLevelPeople]);

  const handleclosesnacks = () => {
    setfirsttimesnackvisible(false);
  };

  const handlesave = () => {
    const reqbody = {
      group_name: newGroupName,
      subscriber_id: userData?.profileData?.data?.activeSubscriber?.id,
      description: "",
      members: [
        ...selectedGroupMember?.selectedInternalGroupMember,
        ...selectedGroupMember?.selectedExternalGroupMember,
      ],
    };
    handlesavearkchatgroup(reqbody);
  };

  const handleChangePermission = async (event, selectedPerson) => {
    if (event.target.checked) {
      setMemberPermission([...memberPermission, selectedPerson?.userId]);
      const reqBody = {
        user_id: selectedPerson?.userId,
        permission_type: GroupLevelArkchatPermission.MANAGER,
        target_subscriber_id: selectedPerson?.targetSubscriberId,
        is_external: false,
      };
      if (selectedInternalMember?.includes(selectedPerson?.userId)) {
        const updatedSelectedGroupMember =
          selectedGroupMember.selectedInternalGroupMember?.map((item) =>
            item?.user_id === selectedPerson?.userId
              ? {
                  ...item,
                  permission_type: GroupLevelArkchatPermission.MANAGER,
                }
              : item
          );
        setSelectedGroupMember({
          ...selectedGroupMember,
          selectedInternalGroupMember: updatedSelectedGroupMember,
        });
      } else {
        setSelectedGroupMember({
          ...selectedGroupMember,
          selectedInternalGroupMember: [
            ...selectedGroupMember.selectedInternalGroupMember,
            reqBody,
          ],
        });
        setSelectedInternalMember([
          ...selectedInternalMember,
          selectedPerson?.userId,
        ]);
      }
    } else {
      const updatedmemberPermission = memberPermission?.filter(
        (id) => id !== selectedPerson?.userId
      );
      setMemberPermission(updatedmemberPermission);
      const updatedSelectedGroupMember =
        selectedGroupMember.selectedInternalGroupMember?.map((item) =>
          item?.user_id === selectedPerson?.userId
            ? { ...item, permission_type: GroupLevelArkchatPermission.USER }
            : item
        );
      setSelectedGroupMember({
        ...selectedGroupMember,
        selectedInternalGroupMember: updatedSelectedGroupMember,
      });
    }
  };
  const handleSelectInternalMember = async (event, selectedPerson) => {
    if (event.target.checked) {
      setSelectedInternalMember([
        ...selectedInternalMember,
        selectedPerson?.userId,
      ]);
      const reqBody = {
        user_id: selectedPerson?.userId,
        permission_type: GroupLevelArkchatPermission.USER,
        target_subscriber_id: selectedPerson?.targetSubscriberId,
        is_external: false,
      };
      setSelectedGroupMember({
        ...selectedGroupMember,
        selectedInternalGroupMember: [
          ...selectedGroupMember.selectedInternalGroupMember,
          reqBody,
        ],
      });
    } else {
      const updatedSelectedMember = selectedInternalMember?.filter(
        (id) => id !== selectedPerson?.userId
      );
      const updatedmemberPermission = memberPermission?.filter(
        (id) => id !== selectedPerson?.userId
      );
      const updatedSelectedGroupMember =
        selectedGroupMember.selectedInternalGroupMember?.filter(
          (item) => item?.user_id !== selectedPerson?.userId
        );
      setSelectedGroupMember({
        ...selectedGroupMember,
        selectedInternalGroupMember: updatedSelectedGroupMember,
      });
      setSelectedInternalMember(updatedSelectedMember);
      setMemberPermission(updatedmemberPermission);
    }
  };
  const handleSelectExternalPerson = async (
    event,
    selectedPerson,
    companyName
  ) => {
    if (event.target.checked) {
      const reqBody = {
        user_id: selectedPerson?.userId,
        permission_type: GroupLevelArkchatPermission.USER,
        target_subscriber_id: selectedPerson?.targetSubscriberId,
        is_external: true,
      };
      setSelectedGroupMember({
        ...selectedGroupMember,
        selectedExternalGroupMember: [
          ...selectedGroupMember.selectedExternalGroupMember,
          reqBody,
        ],
      });
      if (externalGroupMember[companyName]?.lenght > 0) {
        const companyUserId = {
          [companyName]: [
            ...externalGroupMember[companyName],
            selectedPerson?.userId,
          ],
        };
        setExternalGroupMemebr({ ...externalGroupMember, ...companyUserId });
      } else {
        const companyUserId = { [companyName]: [selectedPerson?.userId] };
        setExternalGroupMemebr({ ...externalGroupMember, ...companyUserId });
      }
    } else {
      const updatedCompanyUserId = externalGroupMember[companyName]?.filter(
        (item) => item !== selectedPerson?.userId
      );
      const updatedData = { [companyName]: updatedCompanyUserId };
      const updatedSelectedGroupMember =
        selectedGroupMember.selectedExternalGroupMember?.filter(
          (item) => item?.user_id !== selectedPerson?.userId
        );
      setSelectedGroupMember({
        ...selectedGroupMember,
        selectedExternalGroupMember: updatedSelectedGroupMember,
      });
      setExternalGroupMemebr({ ...externalGroupMember, ...updatedData });
    }
  };

  const handleSelecteAllExternalCompanyMember = (
    event,
    selectedCompanyPerson,
    companyName
  ) => {
    if (event.target.checked) {
      const userIdData = selectedCompanyPerson[companyName]?.map(
        (user) => user?.userId
      );
      const updatedGroupMemberData = selectedCompanyPerson[companyName]?.map(
        (user) => {
          return {
            user_id: user?.userId,
            permission_type: GroupLevelArkchatPermission.USER,
            target_subscriber_id: user?.targetSubscriberId,
            is_external: true,
          };
        }
      );
      const companyUserId = { [companyName]: userIdData };
      setExternalGroupMemebr({ ...externalGroupMember, ...companyUserId });
      setSelectedGroupMember({
        ...selectedGroupMember,
        selectedExternalGroupMember: [
          ...selectedGroupMember?.selectedExternalGroupMember,
          ...updatedGroupMemberData,
        ],
      });
    } else {
      handleRemoveSelectedCompanyMembers(externalGroupMember[companyName]);
      const updatedExternalGroupMember = { ...externalGroupMember };
      delete updatedExternalGroupMember[companyName];
      setExternalGroupMemebr(updatedExternalGroupMember);
    }
  };
  const handleRemoveSelectedCompanyMembers = (idsToRemove) => {
    const updatedSelectedExternalGroupMember =
      selectedGroupMember.selectedExternalGroupMember?.filter(
        (member) => !idsToRemove.includes(member?.user_id)
      );
    setSelectedGroupMember({
      ...selectedGroupMember,
      selectedExternalGroupMember: updatedSelectedExternalGroupMember,
    });
  };

  const handleClickExternal = () => {
    setActiveButton("external");
  };

  const handleClickInternal = () => {
    setActiveButton("internal");
  };

  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={classes.header}
          >
            <Typography sx={classes.iconDiv}>
              <CloseIcon sx={classes.iconn} onClick={handleClosegroupdrawer} />
            </Typography>
            <Typography>{appContent?.createGroup}</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="end"
            spacing={2}
            justifyContent="start"
            sx={{ padding: "0.63rem 1.25rem 1.25rem" }}
          >
            <TextField
              type="text"
              label={appContent?.groupName}
              value={newGroupName}
              variant="standard"
              name="addTopic"
              sx={{ width: "20.62rem" }}
              required
              error={newGroupName ? false : isRequired}
              onChange={handleNameChange}
            />
          </Stack>
          <Stack
            position="static"
            direction="row"
            alignItems="center"
            spacing={2}
            sx={classes.header}
          >
            <Typography fontSize={14} paddingLeft={1}>
              {appContent?.addGroupMember}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="left"
            alignItems="center"
            spacing={1}
            padding="0.75rem 1.18rem 0.75rem 0.75rem"
          >
            <Button
              sx={
                activeButton === "internal"
                  ? classes.activeButton
                  : classes.internalExternalButton
              }
              variant="contained"
              onClick={handleClickInternal}
            >
              {appContent?.internal}
            </Button>
            <Button
              sx={
                activeButton === "external"
                  ? classes.activeButton
                  : classes.internalExternalButton
              }
              variant="contained"
              onClick={handleClickExternal}
            >
              {appContent?.external}
            </Button>
          </Stack>
          {firsttimesnackvisible && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={classes.groupMember}
            >
              <Typography sx={classes.iconDiv}>
                <CloseIcon sx={classes.iconn} onClick={handleclosesnacks} />
              </Typography>
              <Typography width={"19.37rem"} fontSize={"0.75rem"}>
                {appContent?.addMemberDescription}
              </Typography>
            </Stack>
          )}
          {/* list */}

          <Box
            overflow={"scroll"}
            height={
              firsttimesnackvisible
                ? "calc(100vh - 21rem)"
                : "calc(100vh - 18rem)"
            }
            sx={{
              "&::-webkit-scrollbar": {
                width: "1px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#F9F8F8",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "grey",
              },
            }}
          >
            {activeButton === "internal" && (
              <Box>
                {accountLevelPeople?.internalMember?.map((item) => (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    padding="0rem 1.18rem 0rem 0.75rem"
                    key={item?.userId + "internalCreategrp"}
                  >
                    <FormControlLabel
                      sx={classes.checked}
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 30,
                              color: "#183243",
                            },
                          }}
                          checked={selectedInternalMember?.includes(
                            item?.userId
                          )}
                          onChange={(event) =>
                            handleSelectInternalMember(event, item)
                          }
                          icon={
                            <NameBadge
                              sx={{ color: "#183243" }}
                              size="small"
                              variant="contained"
                            >
                              <Typography
                                color={"#183243"}
                                fontSize={"0.75rem"}
                              >
                                {item?.userInfo?.firstName &&
                                  item?.userInfo?.firstName[0]}
                              </Typography>
                            </NameBadge>
                          }
                        />
                      }
                      label={item?.userInfo?.firstName}
                      name={item?.userId}
                    />
                    {/* {
                  {item?.activePermission ===
                    AccountLevelArkchatPermission?.ADMIN &&
                    item?.inviteType === 0} */}
                    {/* <FormControlLabel
                      control={
                        <Switchs
                          switchLabel={appContent?.manager}
                          checked={memberPermission?.includes(item?.userId)}
                          handleChange={(event) =>
                            handleChangePermission(event, item)
                          }
                        />
                      }
                    /> */}
                    <>
                      {item?.inviteType ===
                        InviteType?.internalOwnerInviteType &&
                        (item?.permission ===
                          AccountLevelArkchatPermission?.ADMIN ||
                          userData?.profileData?.data?.accountOwner) && (
                          <Box>
                            <Typography>Owner</Typography>
                          </Box>
                        )}
                      {item?.inviteType === InviteType?.internal &&
                        item?.permission ===
                          AccountLevelArkchatPermission?.ADMIN && (
                          <Box>
                            <Typography>Admin</Typography>
                          </Box>
                        )}
                      {(item?.permission ===
                        AccountLevelArkchatPermission?.USER ||
                        item?.permission ===
                          AccountLevelArkchatPermission?.MANAGER) && (
                        // don't remove it, this can be enabled in future
                        // <FormControlLabel
                        //   control={
                        //     <Switchs
                        //       switchLabel="Manager"
                        //       checked={groupMemeberPermission?.includes(
                        //         item?.userId
                        //       )}
                        //       handleChange={(event) =>
                        //         handleChangeInternalMemberGrpPermission(
                        //           event,
                        //           item
                        //         )
                        //       }
                        //     />
                        //   }
                        // />
                        <Box>
                          <Typography>User</Typography>
                        </Box>
                      )}
                    </>
                  </Stack>
                ))}
              </Box>
            )}
            {activeButton == "external" && (
              <Box>
                {externalMemberData.map((company, index) => (
                  <Box padding="0px 19px 6px 12px">
                    <FormControlLabel
                      sx={{
                        ".MuiFormControlLabel-label": {
                          fontWeight: 500,
                          color: "#000000",
                        },
                      }}
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 30,
                              color: "#183243",
                            },
                          }}
                          checked={
                            Object.values(company)[0]?.length ===
                            externalGroupMember[Object.keys(company)[0]]?.length
                          }
                          onChange={(event) =>
                            handleSelecteAllExternalCompanyMember(
                              event,
                              company,
                              Object.keys(company)[0]
                            )
                          }
                          icon={
                            <NameBadge
                              sx={{ color: "#183243" }}
                              size="small"
                              variant="contained"
                            >
                              <Typography
                                color={"#183243"}
                                fontSize={"0.75rem"}
                                fontWeight={600}
                              >
                                {Object.keys(company)[0] &&
                                  Object.keys(company)[0][0]}
                              </Typography>
                            </NameBadge>
                          }
                        />
                      }
                      label={
                        <Typography fontWeight={600} fontSize={"0.88rem"}>
                          {Object.keys(company)[0]}
                        </Typography>
                      }
                    />
                    {Object.values(company)[0]?.map((item, i) => (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                        key={item?.userId + "externalInvitegrpmember"}
                      >
                        <Box width={"62%"} padding={"0rem 0rem 0rem 1.2rem"}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 25,
                                    color: "#183243",
                                  },
                                }}
                                checked={
                                  externalGroupMember[Object.keys(company)[0]]
                                    ?.length > 0 &&
                                  externalGroupMember[
                                    Object.keys(company)[0]
                                  ]?.includes(item?.userId)
                                }
                                onChange={(event) =>
                                  handleSelectExternalPerson(
                                    event,
                                    item,
                                    Object.keys(company)[0]
                                  )
                                }
                                icon={
                                  <NameBadge
                                    sx={{
                                      color: "#183243",
                                      width: "1.55rem",
                                      height: "1.55rem",
                                    }}
                                    size="small"
                                    variant="contained"
                                  >
                                    <Typography
                                      color={"#183243"}
                                      fontSize={"0.75rem"}
                                    >
                                      {item?.userInfo?.firstName &&
                                        item?.userInfo?.firstName[0]}
                                    </Typography>
                                  </NameBadge>
                                }
                              />
                            }
                            label={`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                          />
                        </Box>
                        {/* <FormControlLabel
                        control={
                          <Switchs
                            switchLabel="Owner"
                            name={item?.userId}
                            checked={groupMemeberPermission?.[item?.userId]}
                            handleChange={(event) =>
                              handleChangeInternalMemberGrpPermission(
                                event,
                                item
                              )
                            }
                          />
                        }
                      /> */}
                      </Stack>
                    ))}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          padding="0.75rem 1.18rem 0.75rem 0.75rem"
        >
          <Button
            style={{
              background: "#DB0011",
              color: "white",
              borderRadius: "3px",
            }}
            variant="contained"
            onClick={handlesave}
          >
            {appContent?.create}
          </Button>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default CreateGroup;
