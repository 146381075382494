import React from "react";
import { Box, Button, Typography} from "@mui/material";
import { useDispatch} from "react-redux";
import aigetstartedIcon from "../../../assets/images/chats/aigetstartedIcon.svg";
import { profileUpdate } from "../../../appStore/actions/profile/action";
import { useTranslation } from "react-i18next";

const AIgetStarted = () => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const dispatch = useDispatch();
  return (
    <Box width={"75%"} margin={"auto"}>
      <Box>
        <Box textAlign={"center"}>
          <img src={aigetstartedIcon} alt="aigetstartedIcon" />
        </Box>
        <Box textAlign={"center"} width={"70%"} margin={"auto"}>
          <Typography
            variant="h5"
            color={"rgba(24, 50, 66, 1)"}
            fontSize={"0.87rem"}
            fontWeight={"400"}
          >
            {appContent?.getStartedMsg}
          </Typography>
        </Box>
      </Box>
      <Box textAlign={"center"} mt={"1.5rem"}>
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            borderRadius: "0.15rem",
            backgroundColor: "#DB0011",
            padding: "0.4rem 0.5rem",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#DB0011",
              color: "#fff",
            },
          }}
          onClick={() =>
            dispatch(profileUpdate({ is_ai_journey_completed: true }))
          }
        >
          {appContent?.getStarted}
        </Button>
      </Box>
    </Box>
  );
};

export default AIgetStarted;
