import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCurrentAiSessionData,
  handleFetchMessages,
  handleGetAiHistory,
  handleGetAllApprovalList,
  handleGetAllTaskList,
  handleGetTempMsgData,
  handleGetUpdatedMsgData,
  handleReadAllMsg,
  requestCreateGroupWithmember,
  requestGetAllAcountLevelMember,
  requestGetGroupRecipient,
  requestallmemberswithgroups,
  requestgroupMemberDetails,
} from "../appStore/actions/ArkchatGroup/action";
import { getProfile } from "../appStore/actions/profile/action";
import { AccountLevelArkchatPermission, MessageType } from "../utils/constant";
import { getAlltopic } from "../appStore/actions/topics/action";
import {
  handleAccountSwitchLoading,
  handleAppSelectedTopic,
  handleAppSelectedTopicToSaveMsg,
  handleChangeTopicOpen,
  handleLoading,
  handleScrollToBottom,
  handleShowAIScreen,
  saveSelectedAppGroupData,
} from "../appStore/actions/AppState/action";
import useAppSelectors from "./useAppSelectors";

const useChatState = ({
  setisTopicOpen,
  isTopicOpen,
  groupname,
  setGroupname,
  selectedTaskGroup,
  setSelectedTaskGroup,
  selectedTaskmember,
  setSelectedTaskMember,
  taskTabValue,
  approvalTabValue,
}) => {
  const [isSyncing, setSyncing] = useState(false);
  const [isCreategroupOpen, setCreategroupopen] = useState(false);
  const [isRequired, setRequired] = useState(false);
  const [dmUserDeatils, setDMuserDetails] = useState(null);
  const dispatch = useDispatch();
  const [isMoveTopicTriggered, setMoveTopicTriggered] = useState(false);
  const [moveTopicMsgId, setMoveTopicMsgId] = useState(null);
  const [isPermissionModalOpen, setPermissionModal] = useState(false);
  const [newMessages, setNewMessages] = useState("");
  const [filterChecked, setFilterChecked] = useState([]);
  const [showSidebar, setShowSidebar] = useState(true);
  const [isAiQueryHistory, setAiQueryHistory] = useState(false);
  const [isShareAiText, setShareAiText] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [isOperationInProgress, setIsOperationInProgress] = useState(false);
  const ongoingRequestRef = useRef(null);
  const { currentUserData } = useAppSelectors();
  const selectedGroup = useSelector(
    (state) => state?.appStateData?.selectedGroupData
  );
  const switchAccountRefresh = useSelector(
    (state) => state?.appStateData?.isSwitchAccountRefresh
  );
  const accountLevelPeople = useSelector(
    (state) => state?.arkchatgroup?.accountLevelPeople
  );
  const groupList = useSelector((state) => state?.arkchatgroup?.groupList);
  const groupTopicData = useSelector((state) => state?.topic?.groupTopicData);
  const currentHomeIndexTab = useSelector(
    (state) => state?.localAppReducer?.currentHomeIndexTab
  );
  const syncCallBack = () => {
    setSyncing(false);
  };
  useEffect(() => {
    if (navigator.onLine) {
      dispatch(handleScrollToBottom(true));
      dispatch(getProfile());
      dispatch(requestGetAllAcountLevelMember());
      dispatch(handleGetAiHistory());
      dispatch(clearCurrentAiSessionData());
    }
  }, [dispatch, switchAccountRefresh]);

  // for by default first group selected
  useEffect(() => {
    if (navigator.onLine) {
      dispatch(handleAppSelectedTopic(null));
      dispatch(handleChangeTopicOpen(false));
      dispatch(handleShowAIScreen(false));
      dispatch(handleAppSelectedTopicToSaveMsg(null));
      // handleGetUpdatedMsgData is used for syncing if your application was closed and there is something changed in msg data
      setSyncing(true);
      dispatch(handleGetUpdatedMsgData()).then((res) => {
        dispatch(requestallmemberswithgroups({ forceFetch: true })).then(
          async (res) => {
            dispatch(handleAccountSwitchLoading(false));
            if (res?.length > 0) {
              const readAllmsgBody = {
                ref_id: res[0]?.pnId,
              };
              if (res[0]?.messageCount !== 0) {
                // eslint-disable-next-line no-unused-vars
                const res = await dispatch(handleReadAllMsg(readAllmsgBody));
              }
              if (res && res.length > 0 && res[0]?.isGroup) {
                dispatch(getAlltopic({ group_id: res[0]?.id }, true));
                dispatch(saveSelectedAppGroupData(res[0]));
                setisTopicOpen(true);
                const reqbody = {
                  group_id: res[0]?.id,
                  target_subscriber_id: currentUserData?.activeSubscriber?.id,
                };
                dispatch(requestgroupMemberDetails(reqbody));
                dispatch(requestGetGroupRecipient(res[0]?.id));
                dispatch(
                  handleFetchMessages(res[0]?.pnId, null, {}, syncCallBack)
                );

                dispatch(handleScrollToBottom(true));
              } else {
                if (res?.length > 0 && !res[0]?.isGroup) {
                  dispatch(saveSelectedAppGroupData(res[0]));
                  dispatch(
                    handleFetchMessages(
                      res[0]?.pnId,
                      0,
                      {
                        type: MessageType?.Direct_Mesage,
                      },
                      syncCallBack
                    )
                  );
                  dispatch(handleGetTempMsgData(res[0]?.pnId));
                  dispatch(handleScrollToBottom(true));
                  setDMuserDetails(res[0]);
                  setisTopicOpen(false);
                } else {
                  dispatch(saveSelectedAppGroupData(null));
                  setisTopicOpen(false);
                }
              }
            }
          }
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchAccountRefresh]);

  const handlegroupItemclick = async (selectedgroup) => {
    // Prevent new clicks if an operation is already in progress
    if (isOperationInProgress) {
      return;
    }
    setIsOperationInProgress(true);

    // Abort any ongoing requests to avoid conflicts
    if (ongoingRequestRef.current) {
      ongoingRequestRef.current.abort();
    }

    // Create a new AbortController for the current request
    const abortController = new AbortController();
    ongoingRequestRef.current = abortController;

    const readAllmsgBody = {
      ref_id: selectedgroup?.pnId,
    };
    setNewMessages("");
    dispatch(handleShowAIScreen(false));
    setAiQueryHistory(false);
    setShareAiText(false);

    if (selectedGroup?.pnId !== selectedgroup?.pnId) {
      setSyncing(true);
      if (selectedgroup?.isGroup) {
        const reqbody = {
          group_id: selectedgroup?.id,
          target_subscriber_id: currentUserData?.activeSubscriber?.id,
        };
        dispatch(
          handleFetchMessages(
            selectedgroup?.pnId,
            null,
            {},
            syncCallBack,
            abortController.signal
          )
        );
        dispatch(handleGetTempMsgData(selectedgroup?.pnId));
        if (selectedgroup?.messageCount !== 0) {
          await dispatch(handleReadAllMsg(readAllmsgBody));
        }
        dispatch(requestgroupMemberDetails(reqbody, abortController.signal));
        dispatch(
          requestGetGroupRecipient(selectedgroup?.id, abortController.signal)
        );
        dispatch(
          getAlltopic(
            { group_id: selectedgroup?.id },
            false,
            abortController.signal
          )
        );
        dispatch(handleAppSelectedTopic(null));
        dispatch(handleAppSelectedTopicToSaveMsg(null));
        setDMuserDetails(null);
        dispatch(handleScrollToBottom(true));
      } else {
        setDMuserDetails(selectedgroup);
        dispatch(
          handleFetchMessages(
            selectedgroup?.pnId,
            null,
            { type: MessageType?.Direct_Mesage },
            syncCallBack,
            abortController.signal
          )
        ).then(() => setSyncing(false));
        dispatch(handleScrollToBottom(true));
        dispatch(handleGetTempMsgData(selectedgroup?.pnId));
        setisTopicOpen(false);
      }
      dispatch(saveSelectedAppGroupData(selectedgroup));
      if (selectedgroup?.messageCount !== 0) {
        await dispatch(handleReadAllMsg(readAllmsgBody));
      }
    }

    setIsOperationInProgress(false);
  };

  // const handlegroupItemclick = async (selectedgroup) => {
  //   const readAllmsgBody = {
  //     ref_id: selectedgroup?.pnId,
  //   };
  //   setNewMessages("");
  //   dispatch(handleShowAIScreen(false));
  //   setAiQueryHistory(false);
  //   setShareAiText(false);
  //   if (selectedGroup?.pnId !== selectedgroup?.pnId) {
  //     setSyncing(true);
  //     if (selectedgroup?.isGroup) {
  //       const reqbody = {
  //         group_id: selectedgroup?.id,
  //         target_subscriber_id: userdetails?.data?.activeSubscriber?.id,
  //       };
  //       dispatch(
  //         handleFetchMessages(selectedgroup?.pnId, null, {}, syncCallBack)
  //       );
  //       dispatch(handleGetTempMsgData(selectedgroup?.pnId));
  //       if (selectedgroup?.messageCount !== 0) {
  //         // eslint-disable-next-line no-unused-vars
  //         const res = await dispatch(handleReadAllMsg(readAllmsgBody));
  //       }
  //       dispatch(requestgroupMemberDetails(reqbody));
  //       dispatch(requestGetGroupRecipient(selectedgroup?.id));
  //       dispatch(getAlltopic({ group_id: selectedgroup?.id }));
  //       dispatch(handleAppSelectedTopic(null));
  //       dispatch(handleAppSelectedTopicToSaveMsg(null));
  //       setDMuserDetails(null);
  //       dispatch(handleScrollToBottom(true));
  //     } else {
  //       setDMuserDetails(selectedgroup);
  //       dispatch(
  //         handleFetchMessages(
  //           selectedgroup?.pnId,
  //           null,
  //           {
  //             type: MessageType?.Direct_Mesage,
  //           },
  //           syncCallBack
  //         )
  //       ).then(() => setSyncing(false));
  //       dispatch(handleScrollToBottom(true));
  //       dispatch(handleGetTempMsgData(selectedgroup?.pnId));
  //       setisTopicOpen(false);
  //     }
  //     dispatch(saveSelectedAppGroupData(selectedgroup));
  //     if (selectedgroup?.messageCount !== 0) {
  //       // eslint-disable-next-line no-unused-vars
  //       const res = await dispatch(handleReadAllMsg(readAllmsgBody));
  //     }
  //   }
  // };

  const handlecreategroup = async () => {
    if (
      currentUserData?.activePermission === AccountLevelArkchatPermission?.USER
    ) {
      setPermissionModal(true);
    } else {
      // setActiveGroup(null);
      // setisTopicOpen(false);
      await dispatch(requestGetAllAcountLevelMember());
      setCreategroupopen(true);
    }
  };

  const handlecloseCreategroup = () => {
    setNewGroupName("");
    setCreategroupopen(false);
  };

  const handleSelectedTopic = (topicdetails) => {
    // Prevent new clicks if an operation is already in progress
    if (isOperationInProgress) {
      return;
    }
    setIsOperationInProgress(true);

    // Abort any ongoing requests to avoid conflicts
    if (ongoingRequestRef.current) {
      ongoingRequestRef.current.abort();
    }
    // Create a new AbortController for the current request
    const abortController = new AbortController();
    ongoingRequestRef.current = abortController;
    dispatch(handleAppSelectedTopic(topicdetails));
    dispatch(handleAppSelectedTopicToSaveMsg(topicdetails));
    dispatch(
      handleFetchMessages(
        selectedGroup?.pnId,
        topicdetails?.id,
        {},
        null,
        abortController.signal
      )
    );
    setIsOperationInProgress(false);
  };

  const handlesavearkchatgroup = async (reqbody) => {
    if (newGroupName) {
      const response = await dispatch(requestCreateGroupWithmember(reqbody));
      if (response?.code === 200) {
        handlecloseCreategroup();
      }
    } else {
      setRequired(true);
    }
  };

  const handleNameChange = (event) => {
    setNewGroupName(event.target.value);
  };

  const handleCheckedFilter = (event) => {
    const { name, value } = event.target;

    if (event.target.checked) {
      const selectedType = {
        type: name,
        value: value,
      };
      setFilterChecked([...filterChecked, selectedType]);
      // if (currentHomeIndexTab === 1) {
      //   handleApplyTaskFilter([...filterChecked, selectedType]);
      // } else {
      //   handleApplyApprovalFilter([...filterChecked, selectedType]);
      // }
    } else {
      const checkedData = filterChecked?.filter(
        // eslint-disable-next-line eqeqeq
        (item) => item?.type != event.target.name
      );
      setFilterChecked(checkedData);
      // if (currentHomeIndexTab === 1) {
      //   handleApplyTaskFilter(checkedData);
      // } else {
      //   handleApplyApprovalFilter(checkedData);
      // }
    }
  };
  const handleClearFilter = (page = null, lastData = null) => {
    if (currentHomeIndexTab === 1) {
      if (
        currentUserData?.activePermission ===
        AccountLevelArkchatPermission?.ADMIN
      ) {
        if (selectedTaskmember?.length > 0) {
          dispatch(
            handleGetAllTaskList(
              `userId=${selectedTaskmember[0]?.userId}`,
              page,
              lastData
            )
          );
        } else if (selectedTaskGroup?.length > 0) {
          dispatch(
            handleGetAllTaskList(
              `groupId=${selectedTaskGroup[0]?.id}`,
              page,
              lastData
            )
          );
        } else if (taskTabValue === 1) {
          dispatch(
            handleGetAllTaskList(
              `userId=${currentUserData?.id}`,
              page,
              lastData
            )
          );
        } else if (taskTabValue === 4) {
          dispatch(handleGetAllTaskList(`is_archived=${true}`, page, lastData));
        } else {
          if (!page) {
            dispatch(handleLoading(true));
          }
          dispatch(handleGetAllTaskList(null, page, lastData));
        }
      } else {
        if (taskTabValue === 0) {
          dispatch(
            handleGetAllTaskList(
              `userId=${currentUserData?.id}`,
              page,
              lastData
            )
          );
        } else if (taskTabValue === 1) {
          dispatch(handleGetAllTaskList(`is_archived=${true}`, page, lastData));
        }
      }
    }
    // for approval
    else {
      if (
        currentUserData?.activePermission ===
        AccountLevelArkchatPermission?.ADMIN
      ) {
        if (selectedTaskmember?.length > 0) {
          dispatch(
            handleGetAllApprovalList(`userId=${selectedTaskmember[0]?.userId}`)
          );
        } else if (selectedTaskGroup?.length > 0) {
          dispatch(
            handleGetAllApprovalList(`groupId=${selectedTaskGroup[0]?.id}`)
          );
        } else if (approvalTabValue === 1) {
          dispatch(
            handleGetAllApprovalList(
              `userId=${currentUserData?.id}`,
              page,
              lastData
            )
          );
        } else if (approvalTabValue === 4) {
          dispatch(
            handleGetAllApprovalList(`is_archived=${true}`, page, lastData)
          );
        } else {
          dispatch(handleGetAllApprovalList(null, page, lastData));
        }
      } else {
        if (approvalTabValue === 0) {
          dispatch(
            handleGetAllApprovalList(
              `userId=${currentUserData?.id}`,
              page,
              lastData
            )
          );
        } else if (approvalTabValue === 1) {
          dispatch(
            handleGetAllApprovalList(`is_archived=${true}`, page, lastData)
          );
        }
      }
    }
    setFilterChecked([]);
  };

  const handleApplyTaskFilter = (queryData, page = null, lastData = null) => {
    let task_status = "";
    let priority = "";
    let repeatable = false;
    queryData?.forEach((item) => {
      if (item.type.includes("Priority")) {
        priority += item.value + ",";
      } else if (item.type.includes("Repeat")) {
        repeatable = true;
      } else if (!item.type.includes("Repeat")) {
        task_status += item.value + ",";
      }
    });
    priority = priority.slice(0, -1);
    task_status = task_status.slice(0, -1);
    !page && dispatch(handleLoading(true));
    if (queryData?.length > 0) {
      let queryString = "";
      if (task_status) {
        queryString += `task_status=${task_status}`;
      }
      if (priority) {
        queryString += `${queryString ? "&" : ""}priority=${priority}`;
      }
      if (repeatable) {
        queryString += `${queryString ? "&" : ""}repeatable=${repeatable}`;
      }
      if (
        currentUserData?.activePermission ===
        AccountLevelArkchatPermission?.ADMIN
      ) {
        if (selectedTaskmember?.length > 0) {
          dispatch(
            handleGetAllTaskList(
              `userId=${selectedTaskmember[0]?.userId}&${queryString}`,
              page,
              lastData
            )
          );
        } else if (selectedTaskGroup?.length > 0) {
          dispatch(
            handleGetAllTaskList(
              `groupId=${selectedTaskGroup[0]?.id}&${queryString}`,
              page,
              lastData
            )
          );
        } else if (taskTabValue === 1) {
          dispatch(
            handleGetAllTaskList(
              `userId=${currentUserData?.id}&${queryString}`,
              page,
              lastData
            )
          );
        } else if (taskTabValue === 4) {
          dispatch(
            handleGetAllTaskList(
              `is_archived=${true}&${queryString}`,
              page,
              lastData
            )
          );
        } else {
          dispatch(handleGetAllTaskList(queryString, page, lastData));
        }
      } else {
        if (taskTabValue === 0) {
          dispatch(
            handleGetAllTaskList(
              `userId=${currentUserData?.id}&${queryString}`,
              page,
              lastData
            )
          );
        } else if (taskTabValue === 1) {
          dispatch(
            handleGetAllTaskList(
              `is_archived=${true}&${queryString}`,
              page,
              lastData
            )
          );
        }
      }
    } else {
      handleClearFilter(page, lastData);
    }
  };
  const handleApplyApprovalFilter = (
    queryData,
    page = null,
    lastData = null
  ) => {
    let approval_status = "";
    let priority = "";
    let repeatable = false;
    queryData?.forEach((item) => {
      if (item.type.includes("Priority")) {
        priority += item.value + ",";
      } else if (item.type.includes("Repeat")) {
        repeatable = true;
      } else if (!item.type.includes("Repeat")) {
        approval_status += item.value + ",";
      }
    });
    priority = priority.slice(0, -1);
    approval_status = approval_status.slice(0, -1);
    if (queryData?.length > 0) {
      let queryString = "";
      if (approval_status) {
        queryString += `approval_status=${approval_status}`;
      }
      if (priority) {
        queryString += `${queryString ? "&" : ""}priority=${priority}`;
      }
      if (repeatable) {
        queryString += `${queryString ? "&" : ""}repeatable=${repeatable}`;
      }
      if (
        currentUserData?.activePermission ===
        AccountLevelArkchatPermission?.ADMIN
      ) {
        if (selectedTaskmember?.length > 0) {
          dispatch(
            handleGetAllApprovalList(
              `userId=${selectedTaskmember[0]?.userId}&${queryString}`,
              page,
              lastData
            )
          );
        } else if (selectedTaskGroup?.length > 0) {
          dispatch(
            handleGetAllApprovalList(
              `groupId=${selectedTaskGroup[0]?.id}&${queryString}`,
              page,
              lastData
            )
          );
        } else if (approvalTabValue === 1) {
          dispatch(
            handleGetAllApprovalList(
              `userId=${currentUserData?.id}&${queryString}`,
              page,
              lastData
            )
          );
        } else if (approvalTabValue === 4) {
          dispatch(
            handleGetAllApprovalList(
              `is_archived=${true}&${queryString}`,
              page,
              lastData
            )
          );
        } else {
          dispatch(handleGetAllApprovalList(queryString, page, lastData));
        }
      }
    } else {
      handleClearFilter(page, lastData);
    }
  };

  return {
    isCreategroupOpen,
    setCreategroupopen,
    setSyncing,
    isSyncing,
    groupname,
    setGroupname,
    newGroupName,
    setNewGroupName,
    isRequired,
    setRequired,
    dmUserDeatils,
    setDMuserDetails,
    dispatch,
    isTopicOpen,
    setisTopicOpen,
    isMoveTopicTriggered,
    setMoveTopicTriggered,
    moveTopicMsgId,
    setMoveTopicMsgId,
    isPermissionModalOpen,
    setPermissionModal,
    newMessages,
    setNewMessages,
    filterChecked,
    setFilterChecked,
    currentHomeIndexTab,
    showSidebar,
    setShowSidebar,
    setAiQueryHistory,
    isShareAiText,
    setShareAiText,
    selectedTaskGroup,
    setSelectedTaskGroup,
    selectedTaskmember,
    setSelectedTaskMember,
    isAiQueryHistory,
    currentUserData,
    accountLevelPeople,
    groupList,
    groupTopicData,
    handlegroupItemclick,
    handlecreategroup,
    handlecloseCreategroup,
    handleApplyApprovalFilter,
    handleApplyTaskFilter,
    handleClearFilter,
    handleCheckedFilter,
    handleNameChange,
    handlesavearkchatgroup,
    handleSelectedTopic,
  };
};

export default useChatState;
