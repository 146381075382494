import React from "react";
import { Box, Button, Typography, Modal } from "@mui/material";
import closeLogo from "../../assets/images/chats/closeLogo.svg";
import { CloseButton, CloseIcon } from "../../page/Profille/CustomDrawer";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const ConfirmationPopUp = ({
  open,
  closeModal,
  title,
  handleDone,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", {
    returnObjects: true,
  });
  const handleClose = () => {
    closeModal(false);
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box display={"flex"} alignItems={"center"} gap={"0.4rem"}>
            <Box>
              <CloseButton
                onClick={handleClose}
                size="small"
                variant="contained"
              >
                <CloseIcon src={closeLogo} alt="close" />
              </CloseButton>
            </Box>

            <Box>
              <Typography fontSize={"1rem"} variant="h6" fontWeight={700}>
                {title}
              </Typography>
            </Box>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"center"}
            gap={"0.2rem"}
            mt={"1.4rem"}
          >
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                background: "#fff",
                color: "#000000",
                borderRadius: "2px",
                cursor: "pointer",
                borderColor: "#DB0011",
              }}
              onClick={() => closeModal(false)}
            >
              Cancle
            </Button>
            <Button
              sx={{
                textTransform: "none",
                background: "#DB0011",
                color: "white",
                borderRadius: "2px",
                cursor: "pointer",
              }}
              variant="contained"
              onClick={handleDone}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default ConfirmationPopUp;
