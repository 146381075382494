import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import searchIcon from "../../../assets/images/chats/gallery_active.svg";

import {
  ChatSearchButton,
  CustomInput,
  SearchBox,
  SmallIcon,
} from "../../../common/chatComponents/ScreenSearchSection";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "0.5rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const StateDrawer = ({
  stateData,
  setStateDrawerOpen,
  handleStateInputChange,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  let filteredData = stateData || [];

  filteredData = filteredData?.filter((el) =>
    el?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={classes.header}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              onClick={() => {
                setStateDrawerOpen(false);
                setSearchQuery("");
              }}
            >
              <Typography sx={classes.iconDiv}>
                <CloseIcon sx={classes.iconn} />
              </Typography>
              <Typography color={"black"}>States</Typography>
            </Stack>
          </Stack>

          <Stack width={"90%"} margin={"auto"} mt={"1rem"}>
            <SearchBox>
              <CustomInput
                autoFocus
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
              />
              <ChatSearchButton size="small" variant="contained">
                <SmallIcon src={searchIcon} />
              </ChatSearchButton>
            </SearchBox>
          </Stack>
          <Box
            width={"90%"}
            margin={"auto"}
            mt={"1rem"}
            height={"72vh"}
            maxHeight={"72vh"}
            overflow={"auto"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "3px",
              },
            }}
          >
            <>
              {stateData?.length > 0 &&
                filteredData?.map((el) => (
                  <Box
                    key={el?.name + "StateDrawer"}
                    mb={"1rem"}
                    display={"flex"}
                    alignItems={"center"}
                    onClick={() => handleStateInputChange(el)}
                  >
                    <Box>
                      <Typography
                        color={"#183243"}
                        fontSize={"0.85rem"}
                        fontWeight={400}
                        variant="h6"
                        sx={{ cursor: "pointer" }}
                      >
                        {el?.name}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </>
          </Box>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default StateDrawer;
