import React from "react";
import { useSelector } from "react-redux";
import FilterCategory from "./FilterCategory";
const ServiceFilter = ({ handleFilter, clearFilter, setChecked, checked }) => {
  const serviceCategoryData = useSelector(
    (state) => state?.serviceCategory?.serviceCategoryData
  );
  return (
    <>
      <FilterCategory
        data={serviceCategoryData}
        handleFilterApply={handleFilter}
        clearFilter={clearFilter}
        checked={checked}
        setChecked={setChecked}
      />
    </>
  );
};

export default ServiceFilter;
