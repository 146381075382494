import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const GoogleButton = styled(Button)({
    display: "flex",
    alignItems: "center",
    color: "black",
    border: "1px solid grey",
    // padding: "0.6em",
    borderRadius: "0.5rem",
    fontSize: "0.8rem",
    textTransform:"none",
    ":hover":{
      border: "1px solid grey",
     backgroundColor:"#fff"
    }
  });
  export const AppleButton = styled(Button)({
    display: "flex",
    alignItems: "center",
    color: "black",
    border: "1px solid grey",
    marginTop: "1.5em",
    marginBottom:"1.5em",
    // padding: "0.6em",
    borderRadius: "0.5em",
    fontSize: "0.8em",
    ":hover":{
      border: "1px solid grey",
     backgroundColor:"#fff"
    }
  });
  
  export const AuthActionButton = styled(Button)({
    backgroundColor: "#DB0011",
    color: "#fff",
    padding: "0.4em 1.5em",
    // borderRadius: "0",
    borderRadius: "0.15rem",
    marginTop: "2em",
    textTransform:"none",
    ":hover":{
      backgroundColor: "#DB0011",
    color: "#fff",
    }
  });

  export const GoogleImg=styled("img")({
        marginRight: "0.5em",
        width: "1.6em",
        height: "1.6em",
     
  })
  export const AppleImg=styled("img")({
    marginRight: "0.5em",
    width: "1.6em",
    height: "1.6em",
 
})