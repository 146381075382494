import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import {
  CustomInput,
  SearchBox,
  SearchButton,
  SmallIcon,
} from "../../common/chatComponents/ScreenSearchSection";
import searchIcon from "../../assets/images/chats/gallery_active.svg";
import { createStyledIndicatorButton } from "../../common/StyledComponents/SideBarComponents";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  removeExitUserAccount,
  updateAccountLevelPermission,
} from "../../appStore/actions/subscriber/action";
import { AccountLevelArkchatPermission } from "../../utils/constant";
import AlertPopUp from "../../common/AlertPopUp";
import ProfileViewModal from "../../common/ProfileViewModal";
import { requestGetAllAcountLevelMember } from "../../appStore/actions/ArkchatGroup/action";

const classes = {
  radioStyle: {
    color: "#092C4C",
    padding: "0.25rem",
    "&.Mui-checked": {
      color: "#092C4C",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
    },
  },
  labelStyle: {
    "&.MuiFormControlLabel-label": {
      fontSize: "4rem",
    },
  },
  radioContainer: {
    "@media (min-width: 67.5rem)": {
      gap: "10%",
    },
  },
};
export const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": !checked && {
    color: "#000000",
    fontSize: "0.75rem",
  },
  "@media (min-width: 67.5rem)": {
    marginLeft: "1.2rem",
  },
}));

export const StyledContactActionButton = styled(Button)({
  borderColor: "#DB0011",
  color: "#000000",
  padding: "0.15rem 0.5rem",
  textTransform: "none",
  minHeight: "unset",
  minWidth: "unset",
  borderRadius: "0.2rem",
  fontSize: "0.8rem",
  ":hover": {
    borderColor: "#DB0011",
    color: "#000000",
  },
});

const StyledContactInviteButton = styled(Button)({
  backgroundColor: "#DB0011",
  color: "#fff",
  padding: "0.5rem 3.5rem",
  textTransform: "none",
  borderRadius: "0.15rem",
  fontSize: "0.8rem",
  ":hover": {
    backgroundColor: "#DB0011",
    color: "#fff",
  },
});

const InternalContact = ({
  showPromt,
  handleCopyInvite,
  accountLevelPeople,
  currentUserData,
}) => {
  const SmallActionButton = createStyledIndicatorButton("#E3E1E1");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredInternalTeamData, setFilteredInternalTeamData] = useState([]);
  const [isPermissionModalOpen, setPermissionModal] = useState(false);
  const [isProfileViewOpen, setProfileViewModal] = useState(false);
  const [profileViewData, setProfileViewData] = useState("");
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const dispatch = useDispatch();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOpenMenu = (event) => {
    if (currentUserData?.activePermission !== 2) {
      setPermissionModal(true);
    } else {
      handleCopyInvite(1);
    }
  };

  const handleRemoveUser = (userData, isSelfExit = false) => {
    if (
      currentUserData?.activePermission !== 2 &&
      !isSelfExit
    ) {
      setPermissionModal(true);
    } else if (
      +currentUserData?.activePermission === 2 ||
      isSelfExit
    ) {
      const target_user_id = {
        remove_user_id: userData?.userId,
      };
      dispatch(removeExitUserAccount(target_user_id)).then((res) => {
        if (res?.status) {
          dispatch(requestGetAllAcountLevelMember());
        }
      });
    }
    //
  };

  useEffect(() => {
    // Filter the internal team data based on searchQuery
    const filteredData = accountLevelPeople?.internalMember?.filter(
      (userData) =>
        userData?.userInfo?.firstName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        userData?.userInfo?.lastName
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    setFilteredInternalTeamData(filteredData);
  }, [searchQuery, dispatch, accountLevelPeople]);

  const handlePermissionChange = (e, userData) => {
    if (currentUserData?.activePermission !== 2) {
      setPermissionModal(true);
    } else {
      const permissionValue = AccountLevelArkchatPermission[e?.target?.value];
      const updatedfilteredInternalTeamData = filteredInternalTeamData.map(
        (data) => {
          if (data?.userId === userData?.userId) {
            return {
              ...data,
              permission: permissionValue,
            };
          }
          return data;
        }
      );
      setFilteredInternalTeamData(updatedfilteredInternalTeamData);
      const reqBody = {
        update_user_id: userData?.userId,
        subscriber_id: userData?.subscriberId,
        permisson: permissionValue,
      };
      //
      //
      dispatch(updateAccountLevelPermission(reqBody));
    }
  };
  const handleProfileBadgeClick = (data) => {
    const viewData = {
      companyName: data?.userData?.subscriberFirmName,
      userName: `${data?.userInfo?.firstName} ${data?.userInfo?.lastName}`,
      indusrtyName: data?.userData?.subscriberAccountIndustry,
      cityName: data?.userData?.city,
      countryName: data?.userData?.country,
      profileBrief: data?.userData?.profileBrief,
      profileImgUrl: data?.userData?.profileImgUrl,
      coverImgUrl: data?.userData?.coverImgUrl,
      isGroup: false,
      website: data?.userInfo?.website,
      linkedin: data?.userInfo?.linkedin,
    };
    setProfileViewData(viewData);
    setProfileViewModal(true);
  };
  return (
    <Box
      padding={"1.5rem 0.8rem"}
      height={showPromt ? "64.2vh" : "82vh"}
      position="relative"
    >
      {/* Contact TOp Section */}
      <AlertPopUp
        title={"Access Denied ."}
        bodyContent={appContent?.addMember}
        open={isPermissionModalOpen}
        closeModal={setPermissionModal}
      />
      <ProfileViewModal
        setProfileViewModal={setProfileViewModal}
        isProfileViewOpen={isProfileViewOpen}
        profileViewData={profileViewData}
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box flex={1}>
          <Typography fontSize="0.8rem" fontWeight="600" variant="h6">
            {appContent?.internalContactsHeading}
          </Typography>
        </Box>
        <Box flex={1.2}>
          <SearchBox>
            <CustomInput
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <SearchButton
              sx={{
                background: "transparent",
                "&:hover": { background: "transparent" },
              }}
              size="small"
            >
              <SmallIcon src={searchIcon} />
            </SearchButton>
          </SearchBox>
        </Box>
      </Stack>

      {/* Contact List*/}
      <Box mt={"0.8rem"} height={showPromt ? "30vh" : "50vh"} overflow={"auto"}>
        {filteredInternalTeamData?.map((userData, indx) => (
          <Box
            key={
              userData?.userId +
              userData?.userInfo?.email +
              indx +
              "internalContactKey"
            }
          >
            {(userData?.inviteType === 1 || userData?.inviteType === 0) && (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={"0.6rem"}
              >
                <Box
                  flex={0.5}
                  display="flex"
                  alignItems="center"
                  gap={"0.5rem"}
                >
                  <SmallActionButton
                    sx={{
                      color: "#183243",
                      padding: userData?.userInfo.profileImgUrl && "0px",
                      overflow: "hidden",
                    }}
                    size="small"
                    variant="contained"
                    onClick={() => handleProfileBadgeClick(userData)}
                  >
                    {userData?.userInfo.profileImgUrl ? (
                      <img
                        src={userData?.userInfo?.profileImgUrl}
                        alt="profile"
                        height="100%"
                      />
                    ) : (
                      <Typography
                        variant="h6"
                        fontSize={"0.75rem"}
                        color={"#183243"}
                      >
                        {userData?.userInfo?.firstName &&
                          userData?.userInfo?.firstName[0]}
                      </Typography>
                    )}
                  </SmallActionButton>

                  <Box>
                    <Typography fontSize="0.9rem" fontWeight="400" variant="h6">
                      {`${userData?.userInfo?.firstName} ${userData?.userInfo?.lastName}`}
                    </Typography>
                  </Box>
                </Box>

                {/* Condition for checking user is owner of current active account. If he is owner show owner */}
                {((userData?.inviteType === 0 &&
                  userData?.userId ===
                  currentUserData?.id) ||
                  userData?.inviteType === 0) && (
                  <Box minWidth={"4.4rem"} textAlign={"center"}>
                    <Typography>Owner</Typography>
                  </Box>
                )}

                {/* Condition for checking user is Admin of current active account. If he is Admin show Admin */}
                {((userData?.inviteType === 1 &&
                  userData?.userId === currentUserData?.id &&
                  userData?.permission ===
                    AccountLevelArkchatPermission?.ADMIN) ||
                  (userData?.inviteType !== 0 &&
                    userData?.inviteType === 1 &&
                    !currentUserData?.accountOwner &&
                    userData?.permission ===
                      AccountLevelArkchatPermission?.ADMIN)) && (
                  <Box
                    flex={1}
                    display="inline-flex"
                    alignItems="center"
                    gap={"5%"}
                    justifyContent={"flex-end"}
                  >
                    {/* THis condition is for checking if logged in user is admin then only show exit button . if other user from list is admin show Admin in front of there name */}
                    {userData?.userId !==
                      currentUserData?.id && (
                      <Box minWidth={"4.4rem"} textAlign={"center"}>
                        <Typography>Admin</Typography>
                      </Box>
                    )}

                    {userData?.userId ===
                      currentUserData?.id &&
                      currentUserData?.activePermission ===
                        AccountLevelArkchatPermission?.ADMIN && (
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={"2rem"}
                        >
                          <StyledContactActionButton
                            variant="outlined"
                            sx={{ minWidth: "4.4rem" }}
                            onClick={() => handleRemoveUser(userData, true)}
                          >
                            Exit
                          </StyledContactActionButton>

                          <Typography
                            fontSize={"0.87rem"}
                            minWidth={"4.4rem"}
                            textAlign={"center"}
                          >
                            Admin
                          </Typography>
                        </Box>
                      )}
                  </Box>
                )}

                {/* This condition is to check if current logged/ user in list  is user or manager*/}
                {((userData?.inviteType === 1 &&
                  userData?.userId === currentUserData?.id &&
                  userData?.permission ===
                    AccountLevelArkchatPermission?.USER) ||
                  (userData?.inviteType !== 0 &&
                    userData?.inviteType === 1 &&
                    !currentUserData?.accountOwner &&
                    (userData?.permission ===
                      AccountLevelArkchatPermission?.USER ||
                      userData?.permission ===
                        AccountLevelArkchatPermission?.MANAGER))) && (
                  <Box
                    flex={1}
                    display="inline-flex"
                    alignItems="center"
                    gap={"5%"}
                    justifyContent={"flex-end"}
                  >
                    {/* This condition is checking if user is manager or user and if current logged in user is ADMIN then he'll see the option to change  user rights  */}
                    {userData?.userId !==
                      currentUserData?.id &&
                      currentUserData?.activePermission ===
                      AccountLevelArkchatPermission?.ADMIN ? (
                      <Box
                        flex={1}
                        display="inline-flex"
                        alignItems="center"
                        gap={"5%"}
                        justifyContent={"flex-end"}
                        sx={classes?.radioContainer}
                      >
                        <Box>
                          <FormControl>
                            <RadioGroup
                              onChange={(e) =>
                                handlePermissionChange(e, userData)
                              }
                              row
                            >
                              {currentUserData?.accountOwner && (
                                <StyledFormControlLabel
                                  value={"ADMIN"}
                                  control={
                                    <Radio
                                      sx={classes.radioStyle}
                                      checked={
                                        AccountLevelArkchatPermission.ADMIN ===
                                        userData.permission
                                      }
                                    />
                                  }
                                  label="Admin"
                                  labelPlacement="start"
                                />
                              )}
                              <StyledFormControlLabel
                                value={"MANAGER"}
                                control={
                                  <Radio
                                    sx={classes.radioStyle}
                                    checked={
                                      AccountLevelArkchatPermission.MANAGER ===
                                      userData.permission
                                    }
                                  />
                                }
                                label="Manager"
                                labelPlacement="start"
                              />
                              <StyledFormControlLabel
                                value={"USER"}
                                control={
                                  <Radio
                                    sx={classes.radioStyle}
                                    checked={
                                      AccountLevelArkchatPermission.USER ===
                                      userData.permission
                                    }
                                  />
                                }
                                label="User"
                                labelPlacement="start"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>

                        <Box>
                          <StyledContactActionButton
                            variant="outlined"
                            onClick={() => handleRemoveUser(userData)}
                          >
                            Remove
                          </StyledContactActionButton>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        {/* This condition is to check if logged in user is Not admin ,if he is not admin
                      and not current logged in user then he see user/manager , if he is logged in user then he'll see exit btn */}
                        {userData?.userId !==
                          currentUserData?.id && (
                          <Box minWidth={"4.4rem"} textAlign={"center"}>
                            {userData?.permission ===
                              AccountLevelArkchatPermission?.USER && (
                              <Typography>User</Typography>
                            )}
                            {userData?.permission ===
                              AccountLevelArkchatPermission?.MANAGER && (
                              <Typography>Manager</Typography>
                            )}
                          </Box>
                        )}
                      </>
                    )}

                    {userData?.userId ===
                      currentUserData?.id &&
                      (currentUserData?.activePermission ===
                        AccountLevelArkchatPermission?.USER ||
                        currentUserData?.activePermission ===
                          AccountLevelArkchatPermission?.MANAGER) && (
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={"2rem"}
                        >
                          <StyledContactActionButton
                            variant="outlined"
                            sx={{ minWidth: "4.4rem" }}
                            onClick={() => handleRemoveUser(userData, true)}
                          >
                            Exit
                          </StyledContactActionButton>

                          <Typography
                            fontSize={"0.87rem"}
                            minWidth={"4.4rem"}
                            textAlign={"center"}
                          >
                            {currentUserData?.activePermission ===
                            AccountLevelArkchatPermission?.USER
                              ? "User"
                              : "Manager"}
                          </Typography>
                        </Box>
                      )}
                  </Box>
                )}

                {/*This condition is if current logged in user is Admin  */}
                {currentUserData?.accountOwner && (
                  <>
                    {userData?.inviteType === 1 &&
                      userData?.inviteType !== 0 &&
                      userData?.userId !==
                      currentUserData?.id && (
                        <Box
                          flex={1}
                          display="inline-flex"
                          alignItems="center"
                          gap={"5%"}
                          justifyContent={"flex-end"}
                          sx={classes?.radioContainer}
                        >
                          <Box>
                            <FormControl>
                              <RadioGroup
                                onChange={(e) =>
                                  handlePermissionChange(e, userData)
                                }
                                row
                              >
                                {currentUserData?.accountOwner && (
                                  <StyledFormControlLabel
                                    value={"ADMIN"}
                                    control={
                                      <Radio
                                        sx={classes.radioStyle}
                                        checked={
                                          AccountLevelArkchatPermission.ADMIN ===
                                          userData.permission
                                        }
                                      />
                                    }
                                    label="Admin"
                                    labelPlacement="start"
                                  />
                                )}
                                <StyledFormControlLabel
                                  value={"MANAGER"}
                                  control={
                                    <Radio
                                      sx={classes.radioStyle}
                                      checked={
                                        AccountLevelArkchatPermission.MANAGER ===
                                        userData.permission
                                      }
                                    />
                                  }
                                  label="Manager"
                                  labelPlacement="start"
                                />
                                <StyledFormControlLabel
                                  value={"USER"}
                                  control={
                                    <Radio
                                      sx={classes.radioStyle}
                                      checked={
                                        AccountLevelArkchatPermission.USER ===
                                        userData.permission
                                      }
                                    />
                                  }
                                  label="User"
                                  labelPlacement="start"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Box>

                          <Box>
                            <StyledContactActionButton
                              variant="outlined"
                              onClick={() => handleRemoveUser(userData)}
                            >
                              Remove
                            </StyledContactActionButton>
                          </Box>
                        </Box>
                      )}
                  </>
                )}
              </Stack>
            )}
          </Box>
        ))}
      </Box>

      {/* Contact Bottom Section */}
      <Box width={"98%"} position="absolute" bottom="0.8rem">
        <Box textAlign={"center"} mb={"0.5rem"}>
          <StyledContactInviteButton onClick={handleOpenMenu}>
            {appContent?.invite}
          </StyledContactInviteButton>
        </Box>
        <Box width={"100%"} margin={"auto"} textAlign={"center"}>
          <Typography
            color="#DB0011"
            fontSize="0.75rem"
            fontWeight="400"
            variant="h6"
          >
            {appContent?.internalContactsHelperFirstPart}
          </Typography>
          <Typography
            color="#DB0011"
            fontSize="0.75rem"
            fontWeight="400"
            variant="h6"
          >
            {appContent?.internalContactsHelperSecondPart}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default InternalContact;
