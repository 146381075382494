import {
  ALL_PENDING_INVITATRION,
  CREATE_SUBSCRIBER_DONE,
  GET_INVITE_URL_DONE,
  GET_USER_IN_ACCOUNT_DONE,
  INVITATION_RETRIVED_DATA,
} from "../../actions/subscriber";

const initState = {
  data: [],
  error: null,
  status: null,
  currentlogedInuser: null,
  invitationRetrivedData: [],
  allPendingInvitationData: [],
};

export const subscriberReducer = (state = initState, action) => {
  switch (action.type) {
    case CREATE_SUBSCRIBER_DONE:
      return {
        ...state,
        subscriberData: {
          data: action?.payload,
          status: action?.payload?.data?.status,
          error: null,
        },
      };
    case GET_INVITE_URL_DONE:
      return {
        ...state,
        inviteUrlData: {
          data: action?.payload,
          status: action?.payload?.data?.status,
          error: null,
        },
      };
    case GET_USER_IN_ACCOUNT_DONE:
      return {
        ...state,
        usersInAccount: {
          data: action?.payload,
          status: action?.payload?.data?.status,
          error: null,
        },
      };
    case INVITATION_RETRIVED_DATA:
      return {
        ...state,
        invitationRetrivedData: action?.payload,
      };
    case ALL_PENDING_INVITATRION:
      return {
        ...state,
        allPendingInvitationData: action?.payload,
      };
    default:
      return state;
  }
};
