import moment from 'moment-timezone';
export function formatDate(format) {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = format
    .replace("DD", day)
    .replace("MM", month)
    .replace("YY", String(year).slice(-2))
    .replace("YYYY", year);

  return formattedDate;
}


export function formatTimeTokenDateByType(timetoken, formatType, timezone) {
  const date = moment(timetoken / 10000).tz(timezone);

  let formattedDate;

  switch (formatType) {
    case "YY-MM-DD":
      formattedDate = date.format("YY-MM-DD");
      break;
    case "MM-DD-YY":
      formattedDate = date.format("MM-DD-YY");
      break;
    case "DD-MM-YY":
      formattedDate = date.format("DD-MM-YY");
      break;
    default:
      throw new Error("Invalid format type");
  }

  return formattedDate;
}

export const convertToSimpleTime = (timeString) => {
  const [hours, minutes] = timeString.split(":");
  const meridiem = parseInt(hours) >= 12 ? "PM" : "AM";
  const formattedHours = parseInt(hours) % 12 || 12;
  const formattedTime = `${formattedHours}:${minutes} ${meridiem}`;
  return formattedTime;
};
