import {
  CREATE_TOPIC_DONE,
  CREATE_TOPIC_ERROR,
  DELETE_TOPIC_DONE,
  DELETE_TOPIC_ERROR,
  GET_TOPIC_DONE,
  GET_TOPIC_ERROR,
  UPDATE_TOPIC_DONE,
  UPDATE_TOPIC_ERROR,
} from "../../actions/topics";

const initState = {
  data: [],
  error: null,
  status: null,
  groupTopicData: [],
};

export const topicsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_TOPIC_DONE:
      return {
        ...state,
        groupTopicData: {
          ...state.groupTopicData,
          data: action?.payload,
          status: action?.payload?.data?.status,
          error: null,
        },
      };
    case GET_TOPIC_ERROR:
      return {
        ...state,
      };
    case CREATE_TOPIC_DONE:
      return {
        ...state,
      };
    case CREATE_TOPIC_ERROR:
      return {
        ...state,
        createNewTopics: {
          data: action?.payload?.data,
          status: action?.payload?.state,
          error: null,
        },
      };
    case UPDATE_TOPIC_DONE:
      return {
        ...state,
      };
    case UPDATE_TOPIC_ERROR:
      return {
        ...state,
      };
    case DELETE_TOPIC_DONE:
      return {
        ...state,
      };
    case DELETE_TOPIC_ERROR:
      return {
        ...state,
      };

    default:
      return state;
  }
};
