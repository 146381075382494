import React, { useState } from "react";
import {
  Box,
  Typography,
  Drawer,
  Stack,
  List,
  ListItem,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { profileUpdate } from "../../appStore/actions/profile/action";
import aiIcon from "../../assets/images/onBoarding/aiBtn_icon.svg";
import atTheRate from "../../assets/images/at-theRate.svg";
import chatSend from "../../assets/images/chat_send.svg";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
  },
  header: {
    // backgroundColor: '#183243',
    backgroundColor: "#F9F8F8",
    color: "#000000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const OnboardingScreen = () => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const dispatch = useDispatch();
  const [isDrawerOpen, setDrawerOpen] = useState(true);

  const handleDone = () => {
    setDrawerOpen(false);
    dispatch(
      profileUpdate(
        {
          tooltip_welcome_journey_completed_web: true,
        },
        false
      )
    );
  };

  return (
    <Drawer sx={{ zIndex: 1202 }} open={isDrawerOpen} anchor="right">
      <Box sx={classes.drawerBody}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={classes.header}
        >
          <Typography onClick={handleDone} sx={classes.iconDiv}>
            <CloseIcon sx={classes.iconn} />
          </Typography>
          <Typography>{appContent?.tooltip}</Typography>
        </Stack>
        <Box width={"95%"} margin={"auto"}>
          <Box>
            <Box display={"flex"} alignItems={"center"} gap={"0.2rem"}>
              <Typography variant="subtitle2">{appContent?.click}</Typography>
              <img
                style={{ width: "1.8rem", height: "1.8rem" }}
                src={aiIcon}
                alt="@"
              />
              <Typography variant="subtitle2">{appContent?.to} :</Typography>
            </Box>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                "& .MuiListItem-root": {
                  display: "list-item",
                  paddingTop: "0px",
                  paddingLeft: "0px",
                },
              }}
            >
              <ListItem>
                <Typography variant="subtitle2">{appContent?.KEY1}</Typography>
              </ListItem>
            </List>
          </Box>

          <Box mt={"0.4rem"}>
            <Box display={"flex"} alignItems={"center"} gap={"0.2rem"}>
              <Typography variant="subtitle2">{appContent?.click}</Typography>
              <img
                style={{ width: "1.8rem", height: "1.8rem" }}
                src={atTheRate}
                alt="@"
              />
              <Typography variant="subtitle2">{appContent?.to} :</Typography>
            </Box>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                "& .MuiListItem-root": {
                  display: "list-item",
                  paddingTop: "0px",
                  paddingLeft: "0px",
                },
              }}
            >
              <ListItem>
                <Typography variant="subtitle2">{appContent?.KEY2}</Typography>
              </ListItem>
              <ListItem>
                <Typography variant="subtitle2">{appContent?.KEY3}</Typography>
              </ListItem>
            </List>
          </Box>

          <Box mt={"0.4rem"}>
            <Box display={"flex"} alignItems={"center"} gap={"0.2rem"}>
              <Typography variant="subtitle2">{appContent?.click}</Typography>
              <img
                style={{ width: "1.8rem", height: "1.8rem" }}
                src={chatSend}
                alt="@"
              />
              <Typography variant="subtitle2">{appContent?.to} :</Typography>
            </Box>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                "& .MuiListItem-root": {
                  display: "list-item",
                  paddingTop: "0px",
                  paddingLeft: "0px",
                },
              }}
            >
              <ListItem>
                <Typography variant="subtitle2">{appContent?.KEY4}</Typography>
              </ListItem>
            </List>
          </Box>

          <Box mt={"0.4rem"}>
            <Typography variant="subtitle2">
              {appContent?.click} <b>'{appContent?.more}'</b> {appContent?.KEY5}
            </Typography>
          </Box>
          <Box mt={"0.4rem"}>
            <Typography variant="subtitle2">
              {appContent?.click} {appContent?.on} <b>'{appContent?.other}' </b>
              {appContent?.KEY6}
            </Typography>
          </Box>
        </Box>

        <Box
          textAlign={"center"}
          width={"100%"}
          position={"absolute"}
          bottom={"1.5rem"}
        >
          <Button
            onClick={handleDone}
            style={{
              background: "#DB0011",
              color: "white",
              borderRadius: "3px",
              textTransform: "none",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
            variant="contained"
            // onClick={handlesave}
          >
            {appContent?.gotIt}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default OnboardingScreen;
