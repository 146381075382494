import { getCookie } from "../../../config/cookie";
import * as actionTypes from "./type";
import axios from "axios";

export const getAllPlans = () => async (dispatch) => {
  try {
    let API_URL = `${process.env.REACT_APP_BASE_PATH}/billing/plans`;
    const token = getCookie("token");
    const response = await axios.get(API_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      dispatch({
        type: actionTypes.GET_ALL_PLANS,
        payload: response?.data?.data,
      });
      return response?.data?.data;
    }
  } catch (err) {
    //console.error("Background API call error:", err);
  }
};

export const getAllActiveMembers = () => async (dispatch) => {
  try {
    let API_URL = `${process.env.REACT_APP_BASE_PATH}/billing/active-members`;
    const token = getCookie("token");
    const response = await axios.get(API_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      // Dispatch the profile data
      dispatch({
        type: actionTypes.GET_ALL_ACTIVE_MEMBERS,
        payload: response?.data?.data,
      });
      return response?.data?.data;
    }
  } catch (err) {
    // console.error("Background API call error:", err);
  }
};

export const removeMemberFromAllGroup = (reqBody) => async (dispatch) => {
  try {
    let API_URL = `${process.env.REACT_APP_BASE_PATH}/group/exit-from-all-groups`;
    const token = getCookie("token");
    const response = await axios.post(API_URL, reqBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      // Dispatch the profile data
      dispatch(getAllActiveMembers());
      return response?.data?.data;
    }
  } catch (err) {
    // console.error("Background API call error:", err);
  }
};

export const savePlanData = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_PLAN_DATA,
    payload: data,
  });
};

export const confirmCouponCode = (couponCode) => async (dispatch) => {
  try {
    ///https://apptest.arkchat.com/v2/billing/plans/coupons/FPARK10
    ///https://apptest.arkchat.com/v2/billing/payment
    let API_URL = `${process.env.REACT_APP_BASE_PATH}/billing/plans/coupons/${couponCode}`;
    const token = getCookie("token");
    const response = await axios.get(API_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data?.data?.percentOff || response?.data?.data?.amountOff) {
      dispatch({
        type: actionTypes.CONFIRM_COUPON_VALIDITY,
        payload: response?.data?.data,
      });
    } else {
      dispatch({
        type: actionTypes.CONFIRM_COUPON_VALIDITY,
        payload: {},
      });
    }
    return response?.data?.data;
  } catch (err) {
    //console.error("Background API call error:", err);
  }
};

export const submitBillingInfo = (reqBody) => async (dispatch) => {
  try {
    /// https://apptest.arkchat.com/v2/billing/payment
    let API_URL = `${process.env.REACT_APP_BASE_PATH}/billing/payment`;
    const token = getCookie("token");
    const response = await axios.post(API_URL, reqBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      dispatch({
        type: actionTypes.BILLING_RESPONSE_DATA,
        payload: response?.data?.data,
      });
      return response?.data;
    }
  } catch (err) {
    // console.error("Background API call error:", err);
  }
};

export const updateSubscription = (reqBody, billing_id) => async (dispatch) => {
  try {
    let API_URL = `${process.env.REACT_APP_BASE_PATH}/billing/update-subscription/${billing_id}`;
    const token = getCookie("token");
    const response = await axios.post(API_URL, reqBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      // dispatch({
      //   type: actionTypes.BILLING_RESPONSE_DATA,
      //   payload: response?.data?.data,
      // });
      return response?.data;
    }
  } catch (err) {
    // console.error("Background API call error:", err);
  }
};

export const cancelSubscription = (reqBody, billing_id) => async (dispatch) => {
  try {
    let API_URL = `${process.env.REACT_APP_BASE_PATH}/cancel-subscription/${billing_id}`;
    const token = getCookie("token");
    const response = await axios.post(API_URL, reqBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      // dispatch({
      //   type: actionTypes.BILLING_RESPONSE_DATA,
      //   payload: response?.data?.data,
      // });
      return response?.data;
    }
  } catch (err) {
    // console.error("Background API call error:", err);
  }
};

export const updateBillingAddress = (reqBody) => async (dispatch) => {
  try {
    /// https://apptest.arkchat.com/v2/billing/payment
    let API_URL = `${process.env.REACT_APP_BASE_PATH}/billing/address`;
    const token = getCookie("token");
    const response = await axios.post(API_URL, reqBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      dispatch({
        type: actionTypes.BILLING_ADDRESS,
        payload: response?.data?.data,
      });
      return response?.data;
    }
  } catch (err) {
    // console.error("Background API call error:", err);
  }
};

export const getSubscriptionDetail = (subscriberId) => async (dispatch) => {
  try {
    let API_URL = `${process.env.REACT_APP_BASE_PATH}/billing/get-subscription/${subscriberId}`;
    const token = getCookie("token");
    const response = await axios.get(API_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      dispatch({
        type: actionTypes.SUBSCRIPTION_DETAILS,
        payload: response?.data?.data,
      });
      return response?.data?.data;
    }
  } catch (err) {
    // console.error("Background API call error:", err);
  }
};

export const getBillingAddress = () => async (dispatch) => {
  try {
    let API_URL = `${process.env.REACT_APP_BASE_PATH}/billing/address`;
    const token = getCookie("token");
    const response = await axios.get(API_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      dispatch({
        type: actionTypes.BILLING_ADDRESS,
        payload: response?.data?.data,
      });
      return response?.data?.data;
    }
  } catch (err) {
    // console.error("Background API call error:", err);
  }
};

export const initatePayment = (id) => async (dispatch) => {
  try {
    let API_URL = `${process.env.REACT_APP_BASE_PATH}/billing/payment/${id}`;
    const token = getCookie("token");
    const response = await axios.get(API_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response?.data?.data;
    }
  } catch (err) {
    // console.error("Background API call error:", err);
  }
};

export const billingFormData = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_BILLING_FORM_DATA,
    payload: data,
  });
};

export const handleShowLoading = (payload) => (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_LOADING,
    payload: payload,
  });
};
