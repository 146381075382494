import React from "react";
import { Box, Typography } from "@mui/material";
import emptyMsg_logo from "../../../assets/images/chats/emptyMsg_logo.svg";

const EmptyMsg = ({ content }) => {
  return (
    <Box textAlign={"center"} mt={"5rem"}>
      <Box>
        <img src={emptyMsg_logo} alt="" />
      </Box>
      <Box width={"90%"} margin={"auto"} mt={"2rem"}>
        <Typography>{content}</Typography>
      </Box>
    </Box>
  );
};

export default EmptyMsg;
