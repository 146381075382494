import React from "react";
import {Box,Button,Typography,Modal} from "@mui/material";
import closeLogo from "../assets/images/chats/closeLogo.svg";
import { CloseButton, CloseIcon } from "../page/Profille/CustomDrawer";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const AlertPopUp = ({
  open,
  closeModal,
  title,
  bodyContent,
  isLinkedinPopUp = false,
  isTaskUpdatePopup = false,
  handleDone
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", {
    returnObjects: true,
  });
  const handleClose = () => {
    closeModal(false);
  };
  const linkedinPoints = [
    appContent?.point1,
    appContent?.point2,
    appContent?.point3,
    appContent?.point4,
    appContent?.point5,
    appContent?.point6,
    appContent?.point7,
    appContent?.point8,
  ];
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box display={"flex"} alignItems={"center"} gap={"0.4rem"}>
            <Box>
              <CloseButton
                onClick={handleClose}
                size="small"
                variant="contained"
              >
                <CloseIcon src={closeLogo} alt="close" />
              </CloseButton>
            </Box>

            <Box>
              {!isLinkedinPopUp && (
                <Typography fontSize={"1rem"} variant="h6" fontWeight={700}>
                  {title}
                </Typography>
              )}
              {isLinkedinPopUp && (
                <Typography fontSize={"1rem"} variant="h6" fontWeight={700}>
                  {title}
                </Typography>
              )}
            </Box>
          </Box>

          {!isLinkedinPopUp && (
            <Box p={"0.8rem 0rem"} mt={"1rem"} width={"81.8%"} margin={"auto"}>
              <Typography variant="h6" fontSize={"0.9rem"}>
                {bodyContent}
              </Typography>
            </Box>
          )}

          {isLinkedinPopUp && (
            <Box p={"0.8rem 0rem"} mt={"1rem"} width={"81.8%"} margin={"auto"}>
              <ul style={{ listStyleType: "disc" }}>
                {linkedinPoints?.map((points, index) => (
                  <li key={index + points}>
                    <Typography
                      variant="h6"
                      fontSize={"0.9rem"}
                      color={"#183242"}
                    >
                      {points}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Box>
          )}

          {isTaskUpdatePopup && (
            <Box
              display={"flex"}
              justifyContent={"center"}
              gap={"0.2rem"}
              mt={"1.4rem"}
            >
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  background: "#fff",
                  color: "#000000",
                  borderRadius: "2px",
                  cursor: "pointer",
                  borderColor: "#DB0011",
                }}
                onClick={()=>closeModal(false)}
              >
                Cancle
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  background: "#DB0011",
                  color: "white",
                  borderRadius: "2px",
                  cursor: "pointer",
                }}
                variant="contained"
                onClick={handleDone}
              >
                Done
              </Button>
            </Box>
          )}
          
        </Box>
      </Modal>
    </div>
  );
};
export default AlertPopUp;
