import {
  getIndicatorData,
  removeIndicatorData,
  storeIndicatorData,
} from "../../../config/db";
import * as actionTypes from "./type";

export const requestShowLoader = () => (dispatch) => {
  dispatch({
    type: actionTypes.REQUEST_HIDE_LOADER,
    payload: true,
  });
};

export const requestHiderLoader = () => (dispatch) => {
  dispatch({
    type: actionTypes.REQUEST_HIDE_LOADER,
    payload: false,
  });
};

export const saveActivityIndicator = (user_Id, subsId) => async (dispatch) => {
  const indicatorData = await storeIndicatorData(user_Id, subsId);
  dispatch({
    type: actionTypes.SAVE_ACTIVITY_INDICATOR,
    payload: indicatorData?.indicatorData,
  });
};

export const getActivityIndicatorData = (user_Id) => async (dispatch) => {
  const indicatorData = await getIndicatorData(user_Id);
  dispatch({
    type: actionTypes.SAVE_ACTIVITY_INDICATOR,
    payload: indicatorData[0]?.indicatorData,
  });
};

export const removeActivityIndicator =
  (user_Id, subsId) => async (dispatch) => {
    const indicatorData = await removeIndicatorData(user_Id, subsId);
    dispatch({
      type: actionTypes.SAVE_ACTIVITY_INDICATOR,
      payload: indicatorData?.indicatorData,
    });
  };

export const handleToastOpenClose = (value) => (dispatch) => {
  dispatch({
    type: actionTypes.HANDLE_TOAST_OPEN_CLOSE,
    payload: value,
  });
};

export const showToastMsg = (msgData) => (dispatch) => {
  dispatch({
    type: actionTypes.HANDLE_TOAST_OPEN_CLOSE,
    payload: true,
  });
  dispatch({
    type: actionTypes.SHOW_TOAST_MESSAGE,
    payload: msgData,
  });
};
