/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  StyledTopicsHeader,
  StyledTopicSideBarInfoBox,
  StyledSidebarButton,
  createStyledIndicatorButton,
} from "../../../common/StyledComponents/SideBarComponents";
import {
  CustomInput,
  SearchBox,
  ChatSearchButton,
  SmallIcon,
} from "../../../common/chatComponents/ScreenSearchSection";
import addIcom from "../../../assets/images/chats/Add.svg";
import searchIcon from "../../../assets/images/chats/gallery_active.svg";
import editTopics from "../../../assets/images/editTopic.svg";
import InputDialogComponent from "../../../components/InputDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  createTopic,
  getAlltopic,
  updateTopic,
} from "../../../appStore/actions/topics/action";
import {
  handleDeleteGroupTopic,
  handleFetchMessages,
  handleMoveChatToAnotherTopic,
} from "../../../appStore/actions/ArkchatGroup/action";
import {
  handleAppSelectedTopic,
  handleAppSelectedTopicToSaveMsg,
  handleChangeTopicOpen,
} from "../../../appStore/actions/AppState/action";
import { showToastMsg } from "../../../appStore/actions/global/action";

const classes = {
  headingText: {
    color: "black",
    fontSize: "1rem",
    fontWeight: "400",
    "@media (max-width: 63rem)": {
      fontSize: "0.8rem",
    },
  },
  drawerBody: {
    minWidth: "393px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#183243",
    color: "#fff",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#E3E1E1",
    position: "relative",
    height: "30px",
    width: "30px",
    padding: "4px",
    borderRadius: "2px",
    cursor: "pointer",
  },
  iconDivRed: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    padding: "4px",
    borderRadius: "2px",
    cursor: "pointer",
  },
  iconn: {
    color: "black",
    height: "100%",
    width: "100%",
  },
  iconnWhite: {
    color: "white",
    height: "100%",
    width: "100%",
  },
  radiogp: {
    display: "block",
  },

  selectedItem: {
    // backgroundColor: "#183243",
    // color: "white",
    backgroundColor: "#E1E1E1",
    color: "#000",
    cursor: "pointer",
  },
  nonselectedItem: {
    color: "#000",
    cursor: "pointer",
  },
};
const Topics = ({
  setisTopicOpen,
  handleSelectedTopic,
  isMoveTopicTriggered,
  setMoveTopicTriggered,
  moveTopicMsgId,
  setMoveTopicMsgId,
}) => {
  const SmallActionButton = createStyledIndicatorButton("#E3E1E1");
  const [isAddTopicOpen, setIsAddTopicOpen] = useState(false);
  const [isEditTopicOpen, setIsEditTopicOpen] = useState(false);
  const [topicName, setTopicName] = useState("");
  const [searchToipcQuery, setSearchToipcQuery] = useState("");
  const [topicDetail, setTopicDetail] = useState({});
  const [selectedChangeTopic, setSelectedChangeTopic] = useState(null);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.profile?.profileData);
  const selectedGroup = useSelector(
    (state) => state?.appStateData?.selectedGroupData
  );
  const selectedTopic = useSelector(
    (state) => state?.appStateData?.selectedTopicData
  );
  const selectedTopicToSaveMsg = useSelector(
    (state) => state?.appStateData?.selectedTopicToSaveMsg
  );
  const groupTopicData = useSelector((state) => state?.topic?.groupTopicData);
  const isChangeTopicOpen = useSelector(
    (state) => state?.appStateData?.isChangeTopicOpen
  );

  const filteredTopicData =
    groupTopicData?.data?.length &&
    groupTopicData?.data?.filter((item) =>
      item?.name?.toLowerCase().includes(searchToipcQuery.toLowerCase())
    );
  const handleInputChange = (e) => {
    setTopicName(e.target.value);
  };

  const handleAddTopic = () => {
    const formData = {
      group_id: selectedGroup?.id,
      name: topicName,
      description: "",
      subscriber_id: userData?.data?.activeSubscriber?.id,
    };
    if (topicName) {
      setLoading(true);
      dispatch(createTopic(formData)).then((res) => {
        // eslint-disable-next-line eqeqeq
        setLoading(false);
        setIsAddTopicOpen(false);
      });
    }
  };

  const handleEditTopicOpen = (data) => {
    setTopicDetail(data);
    setIsEditTopicOpen(true);
  };
  const handleEditTopicInputChange = (e) => {
    setTopicDetail({ ...topicDetail, name: e.target.value });
  };
  const handleEditTopic = () => {
    if (topicDetail) {
      setLoading(true);
      const newTopicData = { name: topicDetail?.name };
      dispatch(updateTopic(newTopicData, topicDetail?.id)).then((res) => {
        if (res?.status) {
          dispatch(getAlltopic({ group_id: selectedGroup?.id }));
        }
        setLoading(false);
        setIsEditTopicOpen(false);
      });
    }
  };

  const handleChangeMoveTopic = (topicData) => {
    if (isChangeTopicOpen) {
      dispatch(handleAppSelectedTopicToSaveMsg(topicData));
      dispatch(handleChangeTopicOpen(false));
    } else {
      setSelectedChangeTopic(topicData);
    }
  };

  const handleSave = () => {
    if (selectedChangeTopic) {
      dispatch(
        handleMoveChatToAnotherTopic(
          moveTopicMsgId?.pnId,
          moveTopicMsgId?.timetoken,
          selectedChangeTopic?.id
        )
      ).then((res) => {
        setMoveTopicMsgId(null);
        setMoveTopicTriggered(false);
      });
    }
    //setMoveTopicTriggered(false)
  };

  const handleAlternateBtnClick = async () => {
    setDeleteLoading(true);
    const response = await dispatch(
      handleDeleteGroupTopic(topicDetail?.id)
    ).then((res) => {
      setDeleteLoading(false);
      if (res?.data?.status) {
        setIsEditTopicOpen(false);
        dispatch(showToastMsg(res?.data?.message));
      } else {
        dispatch(showToastMsg(res?.data?.message||"Something went wrong !"));
      }
    });

    if (response?.data?.status) {
      setIsEditTopicOpen(false);
      dispatch(getAlltopic({ group_id: selectedGroup?.id }));
    }
  };

  const handleClickAllMessages = () => {
    dispatch(handleAppSelectedTopic(null));
    dispatch(handleFetchMessages(selectedGroup?.pnId, null));
  };
  return (
    <React.Fragment>
      <Stack sx={{ paddingLeft: "0", bgcolor: "#F9F8F8" }}>
        <StyledTopicsHeader sx={{ bgcolor: "#F9F8F8" }}>
          <Box display="flex" alignItems="center" gap={"0.5rem"}>
            <Typography sx={classes.iconDiv}>
              <CloseIcon
                sx={classes.iconn}
                onClick={() => {
                  setisTopicOpen(false);
                  setMoveTopicMsgId(null);
                  setMoveTopicTriggered(false);
                  dispatch(handleChangeTopicOpen(false));
                }}
              />
            </Typography>
            <Box>
              <Typography sx={classes.headingText} variant="h6">
                Topics
              </Typography>
            </Box>
          </Box>
          <Box>
            <StyledSidebarButton
              onClick={() => setIsAddTopicOpen(true)}
              sx={{ display: "flex", alignItems: "center", gap: "4px" }}
              size="small"
            >
              <img src={addIcom} alt="group" />
              Topic
            </StyledSidebarButton>
          </Box>
        </StyledTopicsHeader>
        <StyledTopicSideBarInfoBox sx={{ bgcolor: "#F9F8F8" }}>
          <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Box width={"100%"}>
              <SearchBox>
                <CustomInput
                  onChange={(e) => setSearchToipcQuery(e.target.value)}
                  placeholder="Search"
                />
                <ChatSearchButton size="small" variant="contained">
                  <SmallIcon src={searchIcon} />
                </ChatSearchButton>
              </SearchBox>
            </Box>
            <Box>
              {isAddTopicOpen && (
                <>
                  {" "}
                  <InputDialogComponent
                    title="Add Topic"
                    open={isAddTopicOpen}
                    handleClose={() => setIsAddTopicOpen(false)}
                    onChange={handleInputChange}
                    btnLabel="Save"
                    isType="add"
                    inputLable="Topic Name"
                    onClick={handleAddTopic}
                    isLoading={isLoading}
                  />
                </>
              )}
            </Box>
          </Box>

          <Box
            height={"calc(100vh - 15.2rem)"}
            overflow="scroll"
            sx={{
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                width: "0px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "transparent",
              },
              "@media (max-width: 1194px)": {
                height: "calc(100vh - 18rem)",
              },
            }}
          >
            {isEditTopicOpen && (
              <>
                {" "}
                <InputDialogComponent
                  title="Edit/Delete Topic"
                  open={isEditTopicOpen}
                  handleClose={() => setIsEditTopicOpen(false)}
                  onChange={handleEditTopicInputChange}
                  btnLabel="Save"
                  isType="edit"
                  alternateBtnLabel="Delete"
                  value={topicDetail?.name}
                  inputLable="Topic Name"
                  onClick={handleEditTopic}
                  handleAlternateBtnClick={handleAlternateBtnClick}
                  isDeleteLoading={isDeleteLoading}
                  isLoading={isLoading}
                />
              </>
            )}

            {isChangeTopicOpen && (
              <Box
                display="flex"
                alignItems="center"
                gap={"0.6rem"}
                mt={"0.4rem"}
                bgcolor={"#183243"}
              >
                <Typography sx={classes.iconDivRed}>
                  <CloseIcon
                    sx={classes.iconnWhite}
                    onClick={() => dispatch(handleChangeTopicOpen(false))}
                  />
                </Typography>
                <Box>
                  <Typography color={"#fff"} fontSize={"0.84rem"}>
                    Change Topic
                  </Typography>
                </Box>
              </Box>
            )}
            {!isMoveTopicTriggered && !isChangeTopicOpen && (
              <Box
                display="flex"
                alignItems="center"
                mt={"0.5rem"}
                gap={"0.3rem"}
                width={"100%"}
                // key={ele?.id + "topicdata" + i}
              >
                <Box
                  position={"relative"}
                  width={"100%"}
                  padding={"0.2rem 0.5rem"}
                  backgroundColor="#F3F3F3"
                  borderRadius="0.1rem"
                  onClick={() =>
                    !isMoveTopicTriggered && handleClickAllMessages()
                  }
                  sx={
                    // eslint-disable-next-line eqeqeq
                    selectedTopic?.id == null &&
                    !isMoveTopicTriggered &&
                    !isChangeTopicOpen
                      ? classes.selectedItem
                      : classes.nonselectedItem
                  }
                >
                  <Typography
                    fontSize="0.84rem"
                    fontWeight={600}
                    color="Black"
                    variant="h6"
                    // title={ele?.name}
                  >
                    All Messages
                  </Typography>
                </Box>
              </Box>
            )}
            <Box
              position={"relative"}
              width={"100%"}
              padding={"0.2rem 0.5rem"}
              borderRadius="0.1rem"
              title={"Topic wise Messages"}
            >
              <Typography mt={"0.5rem"} fontSize={"0.84rem"} fontWeight={600}>
                Topic wise Messages
              </Typography>
            </Box>
            {groupTopicData?.data?.length > 0 &&
              filteredTopicData?.length > 0 &&
              filteredTopicData?.map((ele, i) => {
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={"0.3rem"}
                    width={"95%"}
                    mt={"0.5rem"}
                    marginLeft="auto"
                    key={ele?.id + "topicdata" + i}
                  >
                    <Box
                      position={"relative"}
                      width={"100%"}
                      padding={"0.2rem 0.5rem"}
                      backgroundColor="#F3F3F3"
                      borderRadius="0.1rem"
                      onClick={() =>
                        !isMoveTopicTriggered && handleSelectedTopic(ele)
                      }
                      sx={
                        // eslint-disable-next-line eqeqeq
                        selectedTopic?.id == ele?.id &&
                        !isMoveTopicTriggered &&
                        !isChangeTopicOpen
                          ? classes.selectedItem
                          : classes.nonselectedItem
                      }
                    >
                      <Typography
                        fontSize="0.82rem"
                        fontWeight="400"
                        color="Black"
                        variant="h6"
                        title={ele?.name}
                      >
                        {ele?.name?.length > 20
                          ? `${ele?.name.slice(0, 20)}...`
                          : ele?.name}
                      </Typography>
                      {isMoveTopicTriggered &&
                        selectedChangeTopic?.id === ele?.id && (
                          <Box position={"absolute"} top={"0.2rem"} right={"0"}>
                            <Button
                              onClick={handleSave}
                              sx={{
                                zIndex:"11",
                                padding: "0px",
                                fontSize: "0.7rem",
                                minWidth: "2.7rem",
                                border: "1px solid grey",
                                color: "black",
                                textTransform: "none",
                                bgcolor:"#E1E1E1",
                                "&:hover": {
                                  border: "1px solid grey",
                                  bgcolor:"#E1E1E1",
                                },
                              }}
                              variant="outlined"
                            >
                              Save
                            </Button>
                          </Box>
                        )}
                    </Box>

                    {i !== 0 && !isMoveTopicTriggered && !isChangeTopicOpen && (
                      <SmallActionButton
                        onClick={() => handleEditTopicOpen(ele)}
                        size="small"
                        variant="contained"
                      >
                        <SmallIcon sx={{ height: "0.8rem" }} src={editTopics} />
                      </SmallActionButton>
                    )}
                    <Box>
                      {(isMoveTopicTriggered || isChangeTopicOpen) && (
                        <FormControlLabel
                          sx={{ mr: "0px" }}
                          control={
                            <Checkbox
                              checked={
                                isChangeTopicOpen
                                  ? selectedTopicToSaveMsg?.id === ele?.id
                                  : selectedChangeTopic?.id === ele?.id
                              }
                              onChange={() => handleChangeMoveTopic(ele)}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  color: "grey"
                                },
                              }}
                            />
                          }
                        />
                      )}
                    </Box>
                  </Box>
                );
              })}
          </Box>
          {isMoveTopicTriggered && !isChangeTopicOpen && (
            <Box
              mt={"3rem"}
              textAlign={"center"}
              sx={{
                "@media (max-width: 1194px)": {
                  mt: "0rem",
                },
              }}
            >
              <Button
                onClick={() => setMoveTopicTriggered(false)}
                size="small"
                sx={{
                  backgroundColor: "#DB0011",
                  color: "#fff",
                  padding: "0.4em 1.5em",
                  borderRadius: "0.15rem",
                  textTransform: "none",
                  ":hover": {
                    backgroundColor: "#DB0011",
                    color: "#fff",
                  },
                }}
              >
                Cancel
              </Button>
            </Box>
          )}
        </StyledTopicSideBarInfoBox>
      </Stack>
    </React.Fragment>
  );
};

export default Topics;
