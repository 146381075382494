import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import closeIcon from "../../assets/images/chats/closeLogo.svg";
import {
  CustomInput,
  SearchBox,
  SmallIcon,
} from "../../common/chatComponents/ScreenSearchSection";
import searchIcon from "../../assets/images/chats/searchIcon.svg";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import {
  getCountryList,
  getIndustryList,
} from "../../appStore/actions/masterData/masterDataAction";

export const CloseButton = styled(Box)({
  display: "flex",
  /* align-items: center; */
  justifyContent: "center",
  textAlign: "center",
  cursor: "pointer",
  backgroundColor: "#DB0011",
  padding: "0.3rem",
  color: "#fff",
  width: "1.8rem",
  height: "1.8rem",
  minWidth: "unset",
  minHeight: "unset",
  borderRadius: "0.15rem",
  "&:hover": {
    backgroundColor: "#DB0011",
    color: "#fff",
  },
});
export const CloseIcon = styled("img")({
  width: "70%",
});

const CustomDrawer = ({
  isDrawerOpen,
  handleDrawerClose,
  type,
  setMasterListData,
  isFullWidth = false,
}) => {
  const dispatch = useDispatch();
  const countryData = useSelector((state) => state?.country);
  const industryData = useSelector((state) => state?.indusrty);
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  useEffect(() => {
    dispatch(getIndustryList());
    dispatch(getCountryList());
  }, [dispatch]);
  let filteredData = [];
  if (type === "industry") {
    filteredData = industryData?.industryvirtical?.data || [];
  } else {
    filteredData = countryData?.countrylist?.data || [];
  }
  // Apply the search query filter
  filteredData = filteredData?.filter((el) =>
    el.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleSelect = (data, il) => {
    if (type === "industry") {
      //
      setMasterListData((state) => {
        const newData = { ...state, industryName: data };
        return newData;
      });
      setSearchQuery("");
      handleDrawerClose();
    } else {
      setMasterListData((state) => {
        const newData = {
          ...state,
          countryName: data?.name,
          countryCode: data?.code,
        };
        return newData;
      });
      setSearchQuery("");
      handleDrawerClose();
    }
  };
  const handleClick = () => {
    setSearchQuery("");
    handleDrawerClose();
  };
  return (
    <>
      {/* Custom Drawer */}
      {isDrawerOpen && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: isFullWidth ? "100%" : "43%",
            height: "100%",
            backgroundColor: "#fff",
            transition: "transform 0.3s",
            transform: "translateX(0)",
            display: "flex",
            flexDirection: "column",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            zIndex: 1,
          }}
        >
          <Box
            bgcolor={"#EAEAEA"}
            display={"flex"}
            gap={"0.8rem"}
            padding={"0.5rem"}
            alignItems={"center"}
          >
            <Box>
              <CloseButton
                onClick={handleClick}
                size="small"
                variant="contained"
              >
                <CloseIcon src={closeIcon} alt="close" />
              </CloseButton>
            </Box>
            <Box>
              <Typography
                fontSize={"1rem"}
                fontWeight={400}
                variant="h6"
                color="#000000"
              >
                {type === "industry"
                  ? appContent?.industryHeading
                  : appContent?.countryHeading}
              </Typography>
            </Box>
          </Box>

          <Box flexGrow={1} padding={"0.5rem"} height={"70%"}>
            <Box display={"flex"} justifyContent={"flex-end"}>
              <Box width={"100%"}>
                <SearchBox>
                  <CustomInput
                    autoFocus
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search"
                  />
                  <SmallIcon src={searchIcon} />
                  {/* <SearchButton sx={{backgroundColor:"transparent"}} size="small" variant="contained">
                    
                  </SearchButton> */}
                </SearchBox>
              </Box>
            </Box>

            <Box
              mt={"1rem"}
              height={"calc(100% - 2.5rem)"}
              overflow={"auto"}
              sx={{
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "3px",
                },
              }}
            >
              {type === "industry" ? (
                <>
                  {" "}
                  {industryData?.industryvirtical &&
                    filteredData?.map((el, i) => (
                      <Box
                        key={el?.id + "industryvirtical"}
                        display={"flex"}
                        justifyContent={"centre"}
                        mt={"0.8rem"}
                        onClick={() => handleSelect(el?.name)}
                      >
                        <Box width={"81%"} margin={"auto"}>
                          <Typography
                            color={"#183243"}
                            fontSize={"0.75rem"}
                            fontWeight={400}
                            variant="h6"
                            sx={{ cursor: "pointer" }}
                          >
                            {el?.name}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                </>
              ) : (
                <>
                  {countryData?.countrylist &&
                    filteredData?.map((el) => (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        gap={"4%"}
                        onClick={() =>
                          handleSelect({ name: el?.name, code: el?.iso2 }, el)
                        }
                        key={el?.iso2 + "countryData?.countrylist"}
                      >
                        <Box width={isFullWidth?"11%":"15%"}>
                          <img
                            style={{ width: "100%" }}
                            src={el?.flag}
                            alt={el?.name}
                          />
                        </Box>
                        <Box>
                          <Typography
                            color={"#183243"}
                            fontSize={"0.875rem"}
                            fontWeight={400}
                            variant="h6"
                            sx={{ cursor: "pointer" }}
                          >
                            {el?.name}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CustomDrawer;
