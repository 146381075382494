import axios from "axios";
import { FORGOTPASSWORD_API_URL, FORGOTPASSWORD_DONE } from ".";
import { EMAIL_STORE } from "../login";
import { REQUEST_FAILED } from "../../global/type";

//  action for the forgortpassword
export const forgortPassword = (formData) => {
  let API_URL = FORGOTPASSWORD_API_URL;
  return (dispatch) => {
    return axios
      .post(API_URL, formData)
      .then((res) => {
        if (res?.status == 200) {
          dispatch({
            type: EMAIL_STORE,
            payload: formData?.email,
          });
          return res?.data;
        } else {
          dispatch({
            type: FORGOTPASSWORD_DONE,
            payload: res,
          });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
      });
  };
};
