import { GET_INDUSTRY_VIRTICAL, GET_INDUSTRY_VIRTICAL_ERROR } from "../../actions/masterData";


const initState = {
    data: [],
    error: null,
    status: null,
    currentlogedInuser: null,
};

export const industryVirticalReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_INDUSTRY_VIRTICAL:
            return {
                ...state,
                industryvirtical: {
                    data: action?.payload,
                    status: action?.payload?.data?.status,
                    error: null,
                },
            };
        case GET_INDUSTRY_VIRTICAL_ERROR:
            return {
                ...state,
                industryvirtical: {
                    data: action?.payload,
                    status: action?.payload?.state,
                    error: null,
                },
            };
        default:
            return state;
    }
};
