import React, { useState } from "react";
import { FormControl } from "@mui/base";
import {
  FormHelperText,
  Stack,
  TextField,
  Typography,
  Box,
  FormLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { AuthActionButton } from "../../common/StyledComponents/AuthComponents";
import { useDispatch, useSelector } from "react-redux";
import { deleteAccount } from "../../appStore/actions/profile/action";

const classes = {
  blockInputBox: {
    width: "100%",
    marginTop: "1.5rem",
  },
  captions: {
    fontSize: "0.87rem !important",
    display: "block",
  },
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "1rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const DeleteAccount = ({ setDeleteAccountDrawerOpen }) => {
  const dispatch = useDispatch();
  const currentuserdetails = useSelector(
    (state) => state?.profile?.profileData
  );
  const [deleteReason, setDeleteReason] = useState("");
  const [deleteKey, setDeleteKey] = useState("");
  const [isAggred, setAggred] = useState(false);
  const handleDeleteAccount = (e) => {
    e.preventDefault();
    const reqBody = {
      delete_reason: deleteReason,
    };
    dispatch(deleteAccount(reqBody));
  };

  return (
    <Box sx={classes.drawerBody}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => setDeleteAccountDrawerOpen(false)}
          />
        </Typography>
        <Typography color={"black"}>Delete account</Typography>
      </Stack>
      <>
        <Box width={"90%"} m={1} margin={"auto"}>
          <form>
            <Box width={"90%"}>
              <Box mb={"0.5rem"}>
                <FormLabel>Why do you want to delete this account?</FormLabel>
              </Box>
              <TextField
                onChange={(e) =>
                  e.target.value?.length <= 1000 &&
                  setDeleteReason(e.target.value)
                }
                variant="outlined"
                fullWidth
                name="profile_brief"
                value={deleteReason}
              />
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  {" "}
                  <FormHelperText>
                    Max 1000 characters , Min 50 characters (
                    {deleteReason?.length} characters)
                  </FormHelperText>
                </Box>
              </Box>
            </Box>

            <Box width={"90%"} mt={"1.5rem"}>
              <FormControl>
                <TextField
                  onChange={(e) => setDeleteKey(e.target.value)}
                  type={"text"}
                  placeholder="Write “DELETE”"
                  variant="standard"
                  name="reEnterPassword"
                  required
                  sx={classes.blockInputBox}
                />
                <FormHelperText>
                  Delete :{" "}
                  {
                    currentuserdetails?.data?.activeSubscriber
                      ?.subscriberFirmName
                  }
                </FormHelperText>
              </FormControl>
            </Box>

            <Box width={"90%"} mt={"3.5rem"}>
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "0.85rem",
                  },
                }}
                control={
                  <Checkbox
                    size="medium"
                    onChange={(e) => setAggred(e.target.checked)}
                    sx={{ "& .MuiSvgIcon-root": { color: "#183243" } }}
                  />
                }
                label="I understand that once this account is deleted it cannot be restored. All data associated with this account will also be permanently deleted."
                labelPlacement="end"
              />
            </Box>

            <Box
              width={"100%"}
              sx={{ position: "absolute", bottom: "2rem", textAlign: "center" }}
            >
              <AuthActionButton
                onClick={handleDeleteAccount}
                sx={{
                  color:
                    deleteReason?.length >= 50 &&
                    deleteKey === "DELETE" &&
                    isAggred
                      ? "#fff"
                      : "grey",
                  ":hover": {
                    color:
                      deleteReason?.length >= 50 &&
                      deleteKey === "DELETE" &&
                      isAggred
                        ? "#fff"
                        : "grey",
                  },
                  pointerEvents:
                    deleteReason?.length >= 50 &&
                    deleteKey === "DELETE" &&
                    isAggred
                      ? "auto"
                      : "none",
                }}
                type="submit"
                variant="contained"
              >
                Delete Account
              </AuthActionButton>
            </Box>
          </form>
        </Box>
      </>
    </Box>
  );
};

export default DeleteAccount;
  