import CryptoJS from "crypto-js";

export function generateRandomIV() {
  // kuncika
  const wordArray = CryptoJS.lib.WordArray.random(8);
  return wordArray.toString(CryptoJS.enc.Hex);
}

export const msgEncrypter = {
  encrypt: (text, key, iv) => {
    const encryptedText = CryptoJS.AES.encrypt(
      text,
      CryptoJS.enc.Utf8.parse(key),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return encryptedText.toString();
  },
  decrypt: (text, key, iv) => {
    const decryptedText = CryptoJS.AES.decrypt(
      text,
      CryptoJS.enc.Utf8.parse(key),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return decryptedText.toString(CryptoJS.enc.Utf8);
  },
};

export const handleDecryptSingleMessageData = (
  messageData,
  isTempMsg = false
) => {
  try {
    let decryptedMsgData = {};
    if (isTempMsg) {
      if (
        messageData?.thread_original_message &&
        (messageData?.thread_original_message?.id ||
          messageData?.thread_original_message?.message)
      ) {
        decryptedMsgData = {
          ...messageData,
          message: msgEncrypter.decrypt(
            messageData?.message,
            process.env.REACT_APP_ENC_KEY,
             messageData?.kuncika
          ),
          thread_original_message: {
            ...messageData?.thread_original_message,
            message: msgEncrypter.decrypt(
              messageData?.thread_original_message?.message,
              process.env.REACT_APP_ENC_KEY,
               messageData?.kuncika
            ),
            translations: JSON.parse(
              msgEncrypter.decrypt(
                messageData?.thread_original_message?.translations,
                process.env.REACT_APP_ENC_KEY,
                messageData?.kuncika
              )
            ),
          },
        };
      } else {
        decryptedMsgData = {
          ...messageData,
          message: msgEncrypter.decrypt(
            messageData?.message,
            process.env.REACT_APP_ENC_KEY,
            messageData?.kuncika
          ),
        };
      }
    } else {
      if (
        messageData?.thread_original_message &&
        (messageData?.thread_original_message?.id ||
          messageData?.thread_original_message?.message)
      ) {
        decryptedMsgData = {
          ...messageData,
          message: msgEncrypter.decrypt(
            messageData?.message,
            process.env.REACT_APP_ENC_KEY,
            messageData?.kuncika
          ),
          translations: JSON.parse(
            msgEncrypter.decrypt(
              messageData?.translations,
              process.env.REACT_APP_ENC_KEY,
              messageData?.kuncika
            )
          ),
          thread_original_message: {
            ...messageData?.thread_original_message,
            message: msgEncrypter.decrypt(
              messageData?.thread_original_message?.message,
              process.env.REACT_APP_ENC_KEY,
              messageData?.kuncika
            ),
            translations: JSON.parse(
              msgEncrypter.decrypt(
                messageData?.thread_original_message?.translations,
                process.env.REACT_APP_ENC_KEY,
                messageData?.kuncika
              )
            ),
          },
        };
      } else {
        decryptedMsgData = {
          ...messageData,
          message: msgEncrypter.decrypt(
            messageData?.message,
            process.env.REACT_APP_ENC_KEY,
            messageData?.kuncika
          ),
          translations: JSON.parse(
            msgEncrypter.decrypt(
              messageData?.translations,
              process.env.REACT_APP_ENC_KEY,
              messageData?.kuncika
            )
          ),
        };
      }
    }
    return decryptedMsgData;
  } catch (error) {
  }
};
