import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  handleDeletePost,
  handleGetPostList,
} from "../../../appStore/actions/ArkchatGroup/action";
import { Box, Typography } from "@mui/material";
import managePostEmpty from "../../../assets/images/post/managePostEmpty.svg";
import PostCard from "../commonComponent/PostCard/PostCard";
import { serviceTypeName, serviceTypeValue } from "../../../utils/constant";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { getServiceCategory } from "../../../appStore/actions/masterData/masterDataAction";
import { handleSavePreviewEditPostData, handleSetserviceTypePost } from "../../../appStore/actions/AppState/action";

const ManagePost = ({
  activeStep,
  setActiveStep,
}) => {
  const postDataList = useSelector(
    (state) => state?.arkchatgroup?.postDataList
  );
  const userData = useSelector((state) => state?.profile?.profileData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(handleGetPostList(userData?.data?.activeSubscriber?.id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const handleEditClick = (data) => {
    dispatch(handleSetserviceTypePost(data?.type));
    dispatch(getServiceCategory(data?.type));
    const createPostData = {
      category: data?.category,
      core_team: {},
      subscriber_id: data?.subscriber_id,
      tags: data?.tags || [],
      topic_id: 0,
      type: data?.type,
      countries: data?.countries || [],
      post_data: data?.postData,
      tagsInput: "",
    };
    const countriesArray = data?.countries?.map((name) => ({ name }));
   
    const localPreviewData={
      selectedCategory:data?.category,
      createPostData:createPostData,
      selectedCountries:countriesArray,
      selectedImageList:data?.attachments,
      selectedVideoList:data?.attachments,
      post_id:data?.post_id,
      authorized_seller:data?.authorizedSeller,
      editBrandData:data?.brandInfo,
      editArtistData:data?.artistInfo
    }
    dispatch(handleSavePreviewEditPostData(localPreviewData));
    localStorage.setItem("post_id", JSON.stringify(data?.id));
    let tabValue;
    if (
      data?.type === serviceTypeName?.Service ||
      data?.type === serviceTypeName?.Art ||
      data?.type === serviceTypeName?.Product
    ) {
      tabValue = 0;
    } else if (data?.type === serviceTypeName?.Photo) {
      tabValue = 1;
    } else if (data?.type === serviceTypeName?.Videos) {
      tabValue = 2;
    }
    localStorage?.setItem("tabValue", tabValue);
    navigate("/post/post-create");
  };

  const handleDeleteClick = (post_id) => {
    dispatch(handleDeletePost(post_id)).then((res) => {
      if (res?.status) {
        dispatch(handleGetPostList(userData?.data?.activeSubscriber?.id));
      }
    });
  };


  return (
    <>
    <Box bgcolor={"#F3F3F3"} height={"1.5rem"} position={"relative"}>
      </Box>
      <Box mt={"1rem"}>
        {postDataList?.data?.posts?.length === 0 && (
          <Box mt={"2.5rem"}>
            <Box textAlign={"center"}>
              <img src={managePostEmpty} alt="PostEmpty" />
            </Box>
            <Box textAlign={"center"}>
              <Typography>There is nothing to manage here.</Typography>
            </Box>
          </Box>
        )}
        <Masonry columnsCount={3} gutter="1rem">
          {postDataList?.data?.posts?.length > 0 &&
            postDataList?.data?.posts?.map((data,i) => (
              <Box width={"100%"} key={i+"managepostData"}>
                <PostCard
                  type={serviceTypeValue[data?.type]}
                  postPreviewData={data}
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  handleEditClick={handleEditClick}
                  handleDeleteClick={handleDeleteClick}
                  showBtn={true}
                />
                
              </Box>
            ))}
            {/* {postDataList?.data?.posts?.length > 0 &&
            postDataList?.data?.posts?.map((data,i) => (
              <Box width={"100%"}key={i+"managepostData"}>
                <PostCard
                  type={serviceTypeValue[data?.type]}
                  postPreviewData={data}
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  handleEditClick={handleEditClick}
                  handleDeleteClick={handleDeleteClick}
                  showBtn={true}
                />
                
              </Box>
            ))} */}
        </Masonry>
      </Box>
    </>
  );
};

export default ManagePost;
