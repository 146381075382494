import { useEffect, useState } from 'react';

const useTimer = (initialTime, interval = 1000) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    let timerId;

    if (time > 0) {
      timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, interval);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [time, interval]);

  const resetTimer = () => {
    setTime(initialTime);
  };

  return {
    time,
    resetTimer,
  };
};

export default useTimer;
