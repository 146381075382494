import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomInput,
  SearchBox,
  SearchButton,
  SmallIcon,
} from "../../common/chatComponents/ScreenSearchSection";
import searchIcon from "../../assets/images/chats/gallery_active.svg";
import { createStyledIndicatorButton } from "../../common/StyledComponents/SideBarComponents";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import contact_icon from "../../assets/images/contact_icon.svg";
import { useDispatch } from "react-redux";
import ProfileViewModal from "../../common/ProfileViewModal";
import AlertPopUp from "../../common/AlertPopUp";
import {
  removeExternalAccount,
  removeExitUserAccount,
} from "../../appStore/actions/subscriber/action";
import { requestGetAllAcountLevelMember } from "../../appStore/actions/ArkchatGroup/action";
import { groupExternalMember } from "../../utils/externalMemberGroping";
import { accountType } from "../../utils/constant";

export const StyledContactActionButton = styled(Button)({
  borderColor: "#DB0011",
  textTransform: "none",
  color: "#000000",
  padding: "0.15rem 0.5rem",
  minHeight: "unset",
  minWidth: "unset",
  borderRadius: "0.2rem",
  fontSize: "0.8rem",
  ":hover": {
    borderColor: "#DB0011",
    color: "#000000",
  },
});

const StyledContactInviteButton = styled(Button)({
  backgroundColor: "#DB0011",
  color: "#fff",
  padding: "0.5rem 3.5rem",
  borderRadius: "0.15rem",
  fontSize: "0.8rem",
  textTransform: "none",
  ":hover": {
    backgroundColor: "#DB0011",
    color: "#fff",
  },
});

const ExternalContact = ({
  showPromt,
  handleCopyInvite,
  currentUserData,
  accountLevelPeople,
}) => {
  const SmallActionButton = createStyledIndicatorButton("#E3E1E1");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isProfileViewOpen, setProfileViewModal] = useState(false);
  const [profileViewData, setProfileViewData] = useState("");
  const [isPermissionModalOpen, setPermissionModal] = useState(false);
  const [externalMemberData, setExternalMemberData] = useState([]);
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const externalTeamData = accountLevelPeople?.externalMember?.filter(
    (userData) => userData?.inviteType === 2
  );
  const dispatch = useDispatch();
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleOpenMenu = (event) => {
    handleCopyInvite(2);
  };

  const handleProfileBadgeClick = (data) => {
    const viewData = {
      companyName: data?.userData?.subscriberFirmName,
      userName: `${data?.userInfo?.firstName} ${data?.userInfo?.lastName}`,
      indusrtyName: data?.userData?.subscriberAccountIndustry,
      cityName: data?.userData?.city,
      countryName: data?.userData?.country,
      profileBrief: data?.userData?.profileBrief,
      profileImgUrl: data?.userData?.profileImgUrl,
      coverImgUrl: data?.userData?.coverImgUrl,
      isGroup: false,
      website: data?.userInfo?.website,
      linkedin: data?.userInfo?.linkedin,
    };
    setProfileViewData(viewData);
    setProfileViewModal(true);
  };
  const handleRemoveUser = (userData) => {
    if (currentUserData?.activePermission !== 2) {
      setPermissionModal(true);
    } else if (+currentUserData?.activePermission === 2) {
      userData?.userData?.subscriberPriority === 2 &&
        dispatch(removeExternalAccount(userData?.targetSubscriberId)).then((res) => {
          if (res?.status) {
            dispatch(requestGetAllAcountLevelMember());
          }
        });
    }
    //
  };
  const handleExitRemoveUser = (userData) => {
    const target_user_id = {
      remove_user_id: userData?.userId,
    };
    dispatch(removeExitUserAccount(target_user_id)).then((res) => {
      if (res?.status) {
        dispatch(requestGetAllAcountLevelMember());
      }
    });
  };
  useEffect(() => {
    if (accountLevelPeople) {
      if (accountLevelPeople?.externalMember?.length > 0) {
        const groupedExternalData = groupExternalMember(
          accountLevelPeople?.externalMember,
          false,
          appContent?.individual
        );

        setExternalMemberData(groupedExternalData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountLevelPeople]);

  useEffect(() => {
    const filtered = externalMemberData?.filter((company) => {
      const companyName = Object.keys(company)[0];
      const members = Object.values(company)[0];

      if (companyName.toLowerCase().includes(searchQuery.toLowerCase())) {
        return true;
      }

      return members.some((member) =>
        `${member?.userInfo?.firstName} ${member?.userInfo?.lastName}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    });
    setFilteredData(filtered);
  }, [searchQuery, externalMemberData]);
  return (
    <Box
      padding={"1.5rem 0.8rem"}
      height={showPromt ? "64.2vh" : "81vh"}
      position="relative"
    >
      <AlertPopUp
        title={"Access Denied ."}
        bodyContent={appContent?.addMember}
        open={isPermissionModalOpen}
        closeModal={setPermissionModal}
      />
      <ProfileViewModal
        setProfileViewModal={setProfileViewModal}
        isProfileViewOpen={isProfileViewOpen}
        profileViewData={profileViewData}
      />
      {/* Contact TOp Section */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box flex={1}>
          <Typography fontSize="0.8rem" fontWeight="600" variant="h6">
            {appContent?.externalContactsHeading}
          </Typography>
        </Box>
        <Box flex={1.2}>
          <SearchBox>
            <CustomInput
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <SearchButton
              sx={{
                background: "transparent",
                "&:hover": { background: "transparent" },
              }}
              size="small"
            >
              <SmallIcon src={searchIcon} />
            </SearchButton>
          </SearchBox>
        </Box>
      </Stack>

      {/* Display This Box When No extrnal Contact Available*/}
      {externalTeamData?.length === 0 && (
        <Box
          width={"100%"}
          position="absolute"
          top="50%"
          left="50%"
          sx={{ transform: "translate(-50%, -50%)" }}
        >
          <Box textAlign={"center"}>
            <img src={contact_icon} alt="contact" />
          </Box>
          <Box textAlign={"center"} width={"70%"} margin={"auto"}>
            <Typography fontSize="0.87rem" fontWeight="400" variant="h6">
              {appContent?.externalContactsEmptyText}
            </Typography>
          </Box>
        </Box>
      )}

      {/* Contact List */}
      {externalTeamData?.length > 0 && (
        <>
          <Box
            mt={"0.8rem"}
            height={showPromt ? "30vh" : "50vh"}
            overflow={"auto"}
          >
            {filteredData?.map((company, index) => (
              <Box
                key={index + "externalmembercontactPage"}
                mb={"1rem"}
                padding={"0px 4px 0px 2px"}
                width={"100%"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mb={"0.4rem"}
                >
                  <Box
                    flex={0.5}
                    display="flex"
                    alignItems="center"
                    gap={"0.5rem"}
                    width={"70%"}
                  >
                    <SmallActionButton
                      sx={{ color: "#183243" }}
                      size="small"
                      variant="contained"
                    >
                      <Typography
                        color={"#183243"}
                        fontSize={"0.75rem"}
                        fontWeight={600}
                      >
                        {Object.keys(company)[0] && Object.keys(company)[0][0]}
                      </Typography>
                    </SmallActionButton>
                    <Box>
                      <Typography
                        fontSize="0.9rem"
                        fontWeight="600"
                        variant="h6"
                      >
                        {Object.keys(company)[0]}
                      </Typography>
                    </Box>
                  </Box>

                  {currentUserData?.activePermission === 2 &&
                    Object.values(company)[0][0]?.userData
                      ?.subscriberPriority !== accountType?.Individual && (
                      <Box>
                        <StyledContactActionButton
                          onClick={() =>
                            handleRemoveUser(Object.values(company)[0][0])
                          }
                          variant="outlined"
                        >
                          Remove
                        </StyledContactActionButton>
                      </Box>
                    )}
                </Box>

                {Object.values(company)[0]?.map((item, i) => (
                  <Stack
                    mb={"0.4rem"}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    key={item?.userId + "externalcontactPage"}
                  >
                    <Box width={"65%"} padding={"0rem 0rem 0rem 1.2rem"}>
                      <Box
                        flex={0.5}
                        display="flex"
                        alignItems="center"
                        gap={"0.5rem"}
                      >
                        <SmallActionButton
                          onClick={() => handleProfileBadgeClick(item)}
                          sx={{
                            color: "#183243",
                            width: "1.55rem",
                            height: "1.55rem",
                          }}
                          size="small"
                          variant="contained"
                        >
                          <Typography color={"#183243"} fontSize={"0.75rem"}>
                            {item?.userInfo?.firstName &&
                              item?.userInfo?.firstName[0]}
                          </Typography>
                        </SmallActionButton>
                        <Box>
                          <Typography
                            title={`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                          >
                            {`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {item?.userData?.subscriberPriority ===
                      accountType?.Individual &&
                      (currentUserData?.id === item?.userId ||
                        currentUserData?.activePermission === 2) && (
                        <Box>
                          <StyledContactActionButton
                            sx={{ minWidth: "4.4rem" }}
                            onClick={() => handleExitRemoveUser(item)}
                            variant="outlined"
                          >
                            {currentUserData?.activePermission === 2
                              ? "Remove"
                              : "Exit"}
                          </StyledContactActionButton>
                        </Box>
                      )}
                  </Stack>
                ))}
              </Box>
            ))}
          </Box>
        </>
      )}

      {/* Contact Bottom Section */}
      <Box width={"98%"} position="absolute" bottom="0.8rem">
        <Box textAlign={"center"} mb={"0.5rem"}>
          <StyledContactInviteButton onClick={handleOpenMenu}>
            {appContent?.invite}
          </StyledContactInviteButton>
        </Box>
        <Box width={"100%"} margin={"auto"} textAlign={"center"}>
          <Typography
            color="#DB0011"
            fontSize="0.75rem"
            fontWeight="400"
            variant="h6"
          >
            {appContent?.externalContactsHelperFirstPart}
          </Typography>
          <Typography
            color="#DB0011"
            fontSize="0.75rem"
            fontWeight="400"
            variant="h6"
          >
            {appContent?.externalContactsHelperSecondPart}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ExternalContact;
