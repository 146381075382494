import React from "react";
import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";

const UpdateTaskSwitch = ({
  switchLabel = "Manager",
  name,
  handleChange,
  checked,
  disabled = false,
}) => {
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 50,
    height: 30,
    padding: "9px 0px",
    "& .MuiSwitch-switchBase": {
      padding: 1,
      transform: "translateX(0px)",
      "&.Mui-checked": {
        transform: "translateX(19px)",
        "& .MuiSwitch-thumb:before": {
          backgroundColor: "#183243",
          padding: "0 1px",
        },
        "& + .MuiSwitch-track": {
          opacity: 0.7,
          backgroundColor: "#A5A5A5",
          // backgroundColor: '#183243',
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: 29,
      height: 25,
      borderRadius: 0,
      fontSize: 9,
      "&:before ": {
        content: `"${switchLabel}"`,
        fontFamily: "Poppins",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundColor: "#F3F3F3",
        padding: "0px",
        lineHeight: "30px",
        textAlign: "center",
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#A5A5A5 ",
      // backgroundColor: '#A5A5A5 ',

      borderRadius: 0,
    },
  }));

  return (
    <>
      <MaterialUISwitch
        disabled={disabled}
        checked={checked}
        name={name}
        onChange={handleChange}
      />
    </>
  );
};
export default UpdateTaskSwitch;
