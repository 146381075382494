import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { cancelReason } from "../../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { cancelSubscription } from "../../../appStore/actions/Subscription/action";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "1rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  radioStyle: {
    color: "#092C4C",
    padding: "0.25rem",
    "&.Mui-checked": {
      color: "#092C4C",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
    },
  },
};
const CancelSubscription = ({ setCancleSubscriptionOpen }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const selectedManageSubscriptionData = useSelector(
    (state) => state?.subscription?.selectedManageSubscriptionData
  );
  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };

  const handleCancleSubscription = () => {
    let reqBody = {
      cancel_reason: selectedReason,
      cancel_comment: comment,
    };
    dispatch(cancelSubscription(reqBody, selectedManageSubscriptionData?.id));
  };
  return (
    <Box sx={classes.drawerBody}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => setCancleSubscriptionOpen(false)}
          />
        </Typography>
        <Typography color={"black"}>Cancel Subscription</Typography>
      </Stack>

      <>
        <Box width={"95%"} margin={"auto"}>
          <Box
            overflow={"auto"}
            maxHeight={"480px"}
            width={"95%"}
            sx={{
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                width: "0px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "transparent",
              },
            }}
          >
            <Box width={"96%"} margin={"auto"}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="cancel-reason"
                  name="cancel-reason"
                  value={selectedReason}
                  onChange={handleReasonChange}
                >
                  {Object.entries(cancelReason).map(([key, value]) => (
                    <FormControlLabel
                      key={key}
                      value={key}
                      control={<Radio sx={classes.radioStyle} />}
                      label={value}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <FormControl sx={{ width: "60%", ml: "0.8rem", mt: "0.5rem" }}>
                <TextField
                  type="text"
                  label={"Platform name"}
                  name=""
                  required
                  onChange={(e) => setComment(e.target.value)}
                />
              </FormControl>
            </Box>
          </Box>
          <Box
            position={"absolute"}
            bottom={"1rem"}
            width={"95%"}
            margin={"auto"}
            textAlign={"center"}
            mt={"2rem"}
          >
            <Button
              onClick={handleCancleSubscription}
              disabled={selectedReason && comment?false:true}
              sx={{
                backgroundColor: "#DB0011",
                color: "#fff",
                padding: "0.4rem 2.4rem",
                textTransform: "none",
                borderRadius: "0.15rem",
                ":hover": {
                  backgroundColor: "#DB0011",
                  color: "#fff",
                },
              }}
              variant="contained"
            >
              Cancel Subscription
            </Button>
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default CancelSubscription;
