import React from "react";
import { useSelector } from "react-redux";
import FilterCategory from "../../MarketPlace/FilterCategory";
import { serviceTypeName } from "../../../utils/constant";
const ExploreVideoFilter = ({
  handleFilter,
  clearFilter,
  setChecked,
  checked,
}) => {
  const videoServiceCategoryData = useSelector(
    (state) => state?.serviceCategory?.videoServiceCategoryData
  );
  const handleFilterType = () => {
    handleFilter(serviceTypeName?.Videos);
  };
  return (
    <>
      <FilterCategory
        data={videoServiceCategoryData}
        handleFilterApply={handleFilterType}
        clearFilter={clearFilter}
        checked={checked}
        setChecked={setChecked}
      />
    </>
  );
};

export default ExploreVideoFilter;
