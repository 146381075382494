import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CrudPopup from "../commonComponent/CrudPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAddArtist,
  handleDeleteArtist,
  handleEditArtist,
  handleGetArtistList,
} from "../../../appStore/actions/ArkchatGroup/action";
import editIcon from "../../../assets/images/editTopic.svg";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#EAEAEA",
    color: "#000000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "26px",
    width: "26px",
    padding: "4px",
    borderRadius: "2px",
    cursor: "pointer",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const ArtDrawer = ({
  setArtistDrawerOpen,
  editArtistData,
  setEditArtistData,
}) => {
  const artistList = useSelector(
    (state) => state?.arkchatgroup?.artistDataList?.data
  );
  const [isOpen, setOpen] = useState(false);
  const [isEditArtistOpen, setEditArtistOpen] = useState(false);
  const [addArtistData, setAddArtistData] = useState({ name: "" });
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddArtistData({
      ...addArtistData,
      [name]: value,
    });
  };
  const handleEditBtnClicked = (data) => {
    setEditArtistData(data);
    setAddArtistData(data);
    setEditArtistOpen(true);
  };
  useEffect(() => {
    dispatch(handleGetArtistList());
  }, [dispatch]);

  const handleSaveArtist = (e) => {
    e.preventDefault();
    dispatch(handleAddArtist(addArtistData)).then((res) => {
      dispatch(handleGetArtistList());
      setOpen(false);
    });
  };
  const handleUpdateArtist = (e) => {
    e.preventDefault();
    dispatch(handleEditArtist(addArtistData, editArtistData?.id)).then(
      (res) => {
        dispatch(handleGetArtistList());
        setEditArtistOpen(false);
      }
    );
  };
  const handleDelete = () => {
    dispatch(handleDeleteArtist(editArtistData?.id)).then((res) => {
      dispatch(handleGetArtistList());
      setEditArtistOpen(false);
    });
  };

  return (
    <React.Fragment>
      <CrudPopup
        inputLable1={"Artist Name"}
        open={isOpen}
        closePopup={setOpen}
        title={"Artist Name"}
        addbtnLable={"Save"}
        handleChange={handleChange}
        handleSave={handleSaveArtist}
        setAddData={setAddArtistData}
        value={addArtistData}
      />
      <CrudPopup
        inputLable1={"Artist name"}
        open={isEditArtistOpen}
        closePopup={setEditArtistOpen}
        title={"Artist Name"}
        addbtnLable={"Save"}
        isEdit={true}
        handleChange={handleChange}
        handleSave={handleUpdateArtist}
        value={addArtistData}
        handleDelete={handleDelete}
        setAddData={setAddArtistData}
      />
      <Box position={"relative"} sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={classes.header}
          >
            <Stack
              onClick={() => {
                setArtistDrawerOpen(false);
              }}
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Typography sx={classes.iconDiv}>
                <CloseIcon sx={classes.iconn} />
              </Typography>
              <Typography fontSize={"1rem"} variant="h6" fontWeight={400}>
                Artist name
              </Typography>
            </Stack>
          </Stack>
          <Box mt={"0.5rem"}>
            {artistList?.length > 0 &&
              artistList?.map((item) => (
                <Box
                  sx={{ cursor: "pointer" }}
                  padding={"0.2rem 0rem"}
                  width={"90%"}
                  margin={"auto"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  key={item?.id + "ArtDrawer"}
                >
                  <Box
                    onClick={() => {
                      setEditArtistData(item);
                      setArtistDrawerOpen(false);
                    }}
                  >
                    <Typography variant="h6" fontSize={"1rem"} fontWeight={400}>
                      {" "}
                      {item?.name}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => handleEditBtnClicked(item)}
                    sx={{
                      cursor: "pointer",
                      height: "1rem",
                      width: "1rem",
                    }}
                  >
                    <img
                      style={{ width: "1rem", height: "1rem" }}
                      src={editIcon}
                      alt="editIcon"
                    />
                  </Box>
                </Box>
              ))}
          </Box>
          <Box left={"20%"} width={"60%"} position={"absolute"} bottom={"1rem"}>
            <Button
              sx={{
                width: "100%",
                textTransform: "none",
                borderRadius: "2px",
                border: "1px solid #DB0011",
                backgroundColor: "#DB0011",
                color: "white",
                ":hover": {
                  border: "1px solid #DB0011",
                  backgroundColor: "#DB0011",
                  color: "white",
                },
              }}
              variant="outlined"
              onClick={() => setOpen(true)}
            >
              Add Artist
            </Button>
          </Box>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ArtDrawer;
