// db.js
import Dexie from "dexie";
import { getCookie } from "./cookie";

// Function to initialize Dexie database for an account
export const initializeDatabase = (subscriber_id) => {
  const db = new Dexie(`Subscriber_${subscriber_id}`);
  // Define object stores and version for the database
  db.version(4).stores({
    //- Never touch it as long as there are users out there with that version running!
    profileData: "++id, data",
    allmemberswithgroupsData: "pnId,timetoken",
    allAcountLevelMemberData: "++id, data",
    messageList:
      "timetoken,pnId,topic_id,temp_timetoken,[pnId+topic_id],[pnId+timetoken],[pnId+topic_id+timetoken],[pnId+temp_timetoken]",
    groupTopicData: "++id, groupId, topicData",
    accountLevelTeamData: "++id,data",
    tempMessageData:
      "temp_timetoken,pnId,topic_id,[pnId+topic_id+temp_timetoken]",
    taskTabData: "timetoken,temp_timetoken",
    approvalTabData: "timetoken,temp_timetoken",
  });

  // before upgrading i.e adding or removing keys except primary use this and also increase the version
  // .upgrade(async (trans) => {
  //   // Upgrade logic for allmemberswithgroupsData
  //   await trans.allmemberswithgroupsData.toCollection().modify((record) => {
  //     // Add new field with default value
  //     record.newField1 = record.newField1 || 'defaultValue1';
  //     // Remove old field
  //     delete record.oldField1;
  //   });

  //   // Upgrade logic for profileData
  //   await trans.profileData.toCollection().modify((record) => {
  //     // Add new field with default value
  //     record.newField2 = record.newField2 || 'defaultValue2';
  //     // Remove old field
  //     delete record.oldField2;
  //   });
  // });

  return db;
};

// add keys to schema
// .upgrade((trans) => {
//   return trans.allmemberswithgroupsData.toCollection().modify((record) => {
//     record.newField = record.newField || 'defaultValue'; // Add default value for new field
//   });
// });

// .upgrade((trans) => {
//   return trans.allmemberswithgroupsData.toCollection().modify((record) => {
//     delete record.oldField; // Remove the old field
//   });
// });

export const initializeMasterDatabase = () => {
  const db = new Dexie(`Arkchat_MasterData`);
  db.version(2).stores({
    //- Never touch it as long as there are users out there with that version running!
    industryMasterData: "++id, data",
    countryMasterData: "++id, data",
    categoryMasterData: "++id, categoryType, categoryData",
    languageMasterData: "++id, data",
    activityIndicator: "++id , userIndicator,indicatorData",
    faqMasterData: "id,order,section",
  });

  return db;
};

// MasterData related Function Db name:Arkchat_MasterData.

export async function storeIndicatorData(subscriber_id, indicatorId) {
  const db = initializeMasterDatabase();
  const existingIndicatorData = await db.activityIndicator
    .where("userIndicator")
    .equals(`userIndicator${subscriber_id}`)
    .toArray();

  if (
    existingIndicatorData?.length > 0 &&
    existingIndicatorData[0]?.indicatorData
  ) {
    const updatedData = { ...existingIndicatorData[0] };
    if (!updatedData["indicatorData"].includes(indicatorId)) {
      updatedData["indicatorData"].push(indicatorId);
    }
    // Update the channel in the messageList object store
    await db.activityIndicator.put(updatedData);
    return updatedData;
  } else {
    const newData = {
      userIndicator: `userIndicator${subscriber_id}`,
      indicatorData: [indicatorId],
    };
    await db.activityIndicator.put(newData);
    return newData;
  }
}

export async function removeIndicatorData(subscriber_id, indicatorId) {
  const db = initializeMasterDatabase();
  const existingIndicatorData = await db.activityIndicator
    .where("userIndicator")
    .equals(`userIndicator${subscriber_id}`)
    .toArray();
  if (
    existingIndicatorData?.length > 0 &&
    existingIndicatorData[0]?.indicatorData
  ) {
    const updatedData = { ...existingIndicatorData[0] };
    const newIndicatorData = updatedData["indicatorData"]?.filter(
      (item) => item !== indicatorId
    );
    updatedData["indicatorData"] = newIndicatorData;
    await db.activityIndicator.put(updatedData);
    return updatedData;
  }
}

export async function getIndicatorData(subscriber_id) {
  const db = initializeMasterDatabase();
  const existingIndicatorData = await db.activityIndicator
    .where("userIndicator")
    .equals(`userIndicator${subscriber_id}`)
    .toArray();
  return existingIndicatorData;
}

export async function storeFaqData(faqData) {
  const db = initializeMasterDatabase();
  return db.faqMasterData.bulkAdd(faqData).catch((error) => {
    // console.error("Error during allmemberswithgroupsData:", error);
  });
}

export async function getFaqDataDb(section) {
  const db = initializeMasterDatabase();
  const faqMasterData = await db.faqMasterData
    .where("section")
    .equals(`${section}`)
    .toArray();
  return faqMasterData;
}

export async function storeLanguageMasterData(languageData) {
  const db = initializeMasterDatabase();
  db.languageMasterData.clear();
  return db.languageMasterData.put({ languageData });
}

export async function getLanguageMasterData() {
  const db = initializeMasterDatabase();
  return db.languageMasterData.toArray();
}

export async function storeIndustryMasterData(industryData) {
  const db = initializeMasterDatabase();
  db.industryMasterData.clear();
  return db.industryMasterData.put({ industryData });
}
export async function getIndustryMasterData() {
  const db = initializeMasterDatabase();
  return db.industryMasterData.toArray();
}
export async function storeCountryMasterData(countryMasterData) {
  const db = initializeMasterDatabase();
  db.countryMasterData.clear();
  return db.countryMasterData.put({ countryMasterData });
}
export async function getCountryMasterData() {
  const db = initializeMasterDatabase();
  return db.countryMasterData.toArray();
}
export async function storeCategoryMasterData(categoryType, categoryData) {
  const db = initializeMasterDatabase();
  const newData = {
    categoryType: `categoryType_${categoryType}`,
    categoryData: categoryData,
  };

  return db.categoryMasterData.put(newData);
}

export async function getCategoryMasterData(categoryType) {
  const db = initializeMasterDatabase();
  const categoryMasterData = await db.categoryMasterData
    .where("categoryType")
    .equals(`categoryType_${categoryType}`)
    .toArray();
  return categoryMasterData;
}

// User related (Subscriber_${subscriber_id}) db function

// Profile related fun()
export const storeProfileData = (subscriber_id, data) => {
  const db = initializeDatabase(subscriber_id);
  db.profileData.clear();
  return db.profileData.put({ data });
};

export const getProfileData = (subscriber_id) => {
  const db = initializeDatabase(subscriber_id);
  return db.profileData.toArray();
};

export const sotreAccountMemberData = (subscriber_id, data) => {
  const db = initializeDatabase(subscriber_id);
  db.accountLevelTeamData.clear();
  return db.accountLevelTeamData.put({ data });
};

export const getAccountMemberData = (subscriber_id) => {
  const db = initializeDatabase(subscriber_id);
  return db.accountLevelTeamData.toArray();
};

// group and dm list related
export const sotreAllmemberswithgroupsData = (subscriber_id, data) => {
  const db = initializeDatabase(subscriber_id);
  db.allmemberswithgroupsData.clear();
  db.allmemberswithgroupsData.bulkAdd(data).catch((error) => {
    // console.error("Error during allmemberswithgroupsData:", error);
  });
  return;
};

export const updateAllmemberswithgroupsData = async (pnId, data) => {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  try {
    await db.allmemberswithgroupsData.update(pnId, data);
  } catch (error) {
  }
};

export const updateAllmemberswithgroupsMsgCount = async (pnId) => {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  await db.allmemberswithgroupsData
    .where("pnId")
    .equals(pnId)
    .first()
    .then((record) => {
      if (record) {
        record.messageCount = 0;

        return db.allmemberswithgroupsData.put(record);
      }
    })
    .catch((error) => {
      // console.error("Error updating record:", error);
    });
};

export const updateAllmemberswithgroupsDataTimetoken = async (
  pnId,
  updatedTimeToken,
  isUpdateCount = false
) => {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  await db.allmemberswithgroupsData
    .where("pnId")
    .equals(pnId)
    .first()
    .then((record) => {
      if (record) {
        record.timetoken = updatedTimeToken;
        if (!isUpdateCount) {
          record.messageCount = +record.messageCount + 1;
        }
        return db.allmemberswithgroupsData.put(record);
      }
    })
    .catch((error) => {
      // console.error("Error updating record:", error);
    });
};

export const deleteGroupFrmGrpDb = async (pnId) => {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  db.allmemberswithgroupsData
    .delete(pnId)
    .then(async () => {})
    .catch((error) => {
      //console.error("Error during delete:", error);
    });
  return;
};

export const getAllmemberswithgroupsData = () => {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  return db.allmemberswithgroupsData.orderBy("timetoken").reverse().toArray();
};

export const getSIngleAllmemberswithgroupsData = async (pnId) => {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  const singleData = await db.allmemberswithgroupsData
    .where("pnId")
    .equals(pnId)
    .first();
  return singleData;
};

// account level member data
export const sotreAllAcountLevelMember = (subscriber_id, data) => {
  const db = initializeDatabase(subscriber_id);
  db.allAcountLevelMemberData.clear();
  return db.allAcountLevelMemberData.put({ data });
};

export const getAllAcountLevelMember = (subscriber_id) => {
  const db = initializeDatabase(subscriber_id);
  return db.allAcountLevelMemberData.toArray();
};

// topic related func()

export async function storeGroupTopicData(subscriber_id, groupId, topicData) {
  const db = initializeDatabase(subscriber_id);
  const existingTopicData = await db.groupTopicData
    .where("groupId")
    .equals(`groupId_${groupId}`)
    .toArray();

  if (existingTopicData?.length > 0 && existingTopicData[0]?.topicData) {
    db.groupTopicData
      .where("groupId")
      .equals(`groupId_${groupId}`)
      .modify({ topicData });
  } else {
    const newData = {
      groupId: `groupId_${groupId}`,
      topicData: topicData,
    };

    return db.groupTopicData.put(newData);
  }
}
export async function deleteTopicData(groupId) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  db.groupTopicData.where("groupId").equals(`groupId_${groupId}`).delete();
  return;
}

export async function getGroupTopicData(subscriber_id, groupId) {
  const db = initializeDatabase(subscriber_id);
  const topicData = await db.groupTopicData
    .where("groupId")
    .equals(`groupId_${groupId}`)
    .toArray();
  return topicData;
}

// ****************Task/Approval Tab Related func()************

export async function storeTaskTabData(taskData) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  db.taskTabData.bulkAdd(taskData).catch((error) => {
    // console.error("Error during messageListData:", error);
  });
  return;
}

export async function getTaskTabData(startFrom = 0, pageSize = 4) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  let fetchedTaskData;
  if (startFrom === 0) {
    // Retrieve the latest messages without using a key range
    fetchedTaskData = await db.taskTabData.reverse().limit(pageSize).toArray();
  } else {
    fetchedTaskData = await db.taskTabData
      .where("timetoken")
      .between(Dexie.minKey, startFrom)
      .reverse()
      .limit(pageSize)
      .toArray();
  }

  return fetchedTaskData;
}

export async function updateTaskTabData(timetoken, newData) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  try {
    const existingRecord = await db.taskTabData.where({ timetoken }).toArray();
    if (existingRecord) {
      await db.taskTabData.update({ timetoken }, newData);
    }
    return;
  } catch (error) {
    // console.error("Error updating message data:", error);
  }
}

export async function storeApprovalTabData(taskData) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  db.approvalTabData.bulkAdd(taskData).catch((error) => {
    // console.error("Error during messageListData:", error);
  });
  return;
}

export async function getApprovalTabData(startFrom = 0, pageSize = 50) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  let fetchedTaskData;
  if (startFrom === 0) {
    // Retrieve the latest messages without using a key range
    fetchedTaskData = await db.approvalTabData
      .reverse()
      .limit(pageSize)
      .toArray();
  } else {
    fetchedTaskData = await db.approvalTabData
      .where("timetoken")
      .between(Dexie.minKey, startFrom)
      .reverse()
      .limit(pageSize)
      .toArray();
  }

  return fetchedTaskData;
}

export async function updateApprovalTabData(timetoken, newData) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  try {
    const existingRecord = await db.approvalTabData
      .where({ timetoken })
      .toArray();
    if (existingRecord) {
      await db.approvalTabData.update({ timetoken }, newData);
    }
    return;
  } catch (error) {
    // console.error("Error updating message data:", error);
  }
}

export async function getLatestTaskApprovalDataTimeToken(
  isTaskTimeToken = true,
  isLatestTimeToken = true
) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  let result;
  if (isTaskTimeToken) {
    if (isLatestTimeToken) {
      result = await db.taskTabData.reverse().limit(1).sortBy("timetoken");
    } else {
      result = await db.taskTabData.limit(1).sortBy("timetoken");
    }
  } else {
    if (isLatestTimeToken) {
      result = await db.approvalTabData.reverse().limit(1).sortBy("timetoken");
    } else {
      result = await db.approvalTabData.limit(1).sortBy("timetoken");
    }
  }
  return result;
}

export async function getTaskApprovalTabDataCount(isTaskTabData = true) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  let dataCount;

  if (isTaskTabData) {
    dataCount = await db.taskTabData.count();
  } else {
    dataCount = await db.approvalTabData.count();
  }
  return dataCount;
}

// ****************Message Related fun()****************

export async function storeTempMessageToDb(messageData) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  db.tempMessageData.bulkAdd([messageData]).catch((error) => {
    // console.error("Error during messageListData:", error);
  });
  return;
}

export async function getTempStoreMessageData(pnId, topic_id) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  let fetchedMessages;
  if (topic_id) {
    fetchedMessages = await db.tempMessageData
      .where({
        pnId: pnId,
        topic_id: topic_id,
      })
      .toArray();
  } else {
    fetchedMessages = await db.tempMessageData
      .where({
        pnId: pnId,
      })
      .toArray();
  }

  return fetchedMessages;
}

export async function deleteTempMessageData(temp_timetoken) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  if (temp_timetoken) {
    db.tempMessageData
      .where("temp_timetoken")
      .equals(temp_timetoken)
      .delete()
      .catch((error) => {
        // console.error("Error occurred while deleting entries:", error);
      });
  }
  return;
}

export async function storeMessageToDb(messageData) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  db.messageList.bulkAdd(messageData).catch((error) => {
    // console.error("Error during messageListData:", error, messageData);
  });
  return;
}

export async function getMessageFromDb(
  pnId,
  topic_id,
  startFrom = 0,
  pageSize = 50
) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  let fetchedMessages;

  if (startFrom === 0) {
    // Retrieve the latest messages without using a key range
    if (topic_id) {
      fetchedMessages = await db.messageList
        .where({
          pnId: pnId,
          topic_id: topic_id,
        })
        .reverse()
        .limit(pageSize)
        .toArray();
    } else {
      fetchedMessages = await db.messageList
        .where({ pnId: pnId })
        .reverse()
        .limit(pageSize)
        .toArray();
    }
  } else {
    if (topic_id) {
      fetchedMessages = await db.messageList
        .where("[pnId+topic_id+timetoken]")
        .between([pnId, topic_id, Dexie.minKey], [pnId, topic_id, startFrom])
        .reverse()
        .limit(pageSize)
        .toArray();
    } else {
      fetchedMessages = await db.messageList
        .where("[pnId+timetoken]")
        .between([pnId, Dexie.minKey], [pnId, startFrom])
        .reverse()
        .limit(pageSize)
        .toArray();
    }
  }

  return fetchedMessages.reverse();
}

// export async function updateMessageByTempTimetokenToDb(
//   pnId,
//   timetoken,
//   newData
// ) {
//   const subscriber_id = getCookie("subsCriber_id");
//   const db = initializeDatabase(subscriber_id);
//   try {
//     const existingRecord = await db.messageList
//       .where({ pnId, timetoken })
//       .toArray();
//     if (existingRecord?.length > 0) {
//       await db.messageList.update({ pnId, timetoken }, newData);
//     }
//     return;
//   } catch (error) {
//     console.error("Error updating message data:", error);
//   }
// }

export async function deleteMsgFromDb(temp_timetoken) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  try {
    db.messageList
      .where("temp_timetoken")
      .equals(temp_timetoken)
      .delete()
      .then(() => "")
      .catch((error) => {
        // console.error("Error occurred while deleting entries:", error);
      });
    return;
  } catch (error) {
    // console.error("Error updating message data:", error);
  }
}

export async function updateMessageToDb(pnId, temp_timetoken, newData,shouldAdd=false) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  try {
    const existingRecord = await db.messageList
      .where({ pnId, temp_timetoken })
      .toArray();
    if (existingRecord?.length > 0) {
      let primaryKey = existingRecord[0]?.timetoken;
      await db.messageList
        .update({ pnId, timetoken: primaryKey }, newData)
        .then((res) => "");
    }else if(shouldAdd){
      db.messageList.bulkAdd([newData]).catch((error) => {
        // console.error("Error during messageListData:", error, newData);
      });
    }
    return;
  } catch (error) {
    // console.error("Error updating message data:", error);
  }
}

export async function getMessageCount(pnId, topic_id) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  const messageCount = await db.messageList.where({ pnId: pnId }).count();
  return messageCount;
}

export async function getLatestTimeToken(pnId, isLastTimeToken = true) {
  const subscriber_id = getCookie("subsCriber_id");
  const db = initializeDatabase(subscriber_id);
  let result;
  if (isLastTimeToken) {
    result = await db.messageList
      .where({ pnId: pnId })
      .reverse()
      .limit(1)
      .sortBy("timetoken");
  } else {
    result = await db.messageList
      .where({ pnId: pnId })
      .limit(1)
      .sortBy("timetoken");
  }
  return result;
}

export const clearAllDatabases = async () => {
  // Get the names of all IndexedDB databases
  const dbNames = await Dexie.getDatabaseNames();

  for (const name of dbNames) {
    if (
      name === "firebaseLocalStorageDb" ||
      name === "firebase-messaging-database" ||
      name === "firebase-heartbeat-database" ||
      name === "Arkchat_MasterData"
    ) {
      continue;
    }

    const db = new Dexie(name);

    db.on("versionchange", (ev) => {
      if (ev.newVersion > 0) {
      } else {
      }
      db.close();
    });

    db.on("blocked", (ev) => {
      if (!ev.newVersion || ev.newVersion < ev.oldVersion) {
      } else {
      }
    });

    try {
      await db.open();

      db.close();

      await db.delete();
    } catch (error) {}
  }
};
