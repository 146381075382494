import axios from "axios";
import {
  ALL_NOTIFICATION_DONE,
  ALL_NOTIFICATION_ERROR,
  ALL_NOTIFICATION_URL,
  CLEAR_ALL_NOTIFICATION_DONE,
  CLEAR_ALL_NOTIFICATION_ERROR,
  CLEAR_ALL_NOTIFICATION_URL,
  CLEAR_NOTIFICATION_BY_ID_DONE,
  CLEAR_NOTIFICATION_BY_ID_ERROR,
  GET_ALL_USER_STARED_NOTIFIACTION,
  GET_ALL_USER_STARED_NOTIFICATION_ERROR,
  GET_ALL_USER_STARED_NOTIFICATION_URL,
  STAR_NOTIFICATION_DONE,
  STAR_NOTIFICATION_ERROR,
  STAR_NOTIFICATION_URL,
} from ".";

import { REQUEST_FAILED } from "../global/type";
import { getCookie } from "../../../config/cookie";

export const getAllNotification = () => {
  let API_URL = ALL_NOTIFICATION_URL;
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .get(API_URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: ALL_NOTIFICATION_DONE,
            payload: res?.data?.data,
          });
          return res?.data;
        } else {
          dispatch({
            type: ALL_NOTIFICATION_ERROR,
            payload: res?.data?.data,
          });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
       // 
      });
  };
};

export const starNotificationById = (notification_id) => {
  const API_URL = `${STAR_NOTIFICATION_URL}/${notification_id}`;
  const token = getCookie("token");
  return (dispatch) => {
    return axios
      .post(API_URL, notification_id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: STAR_NOTIFICATION_DONE,
            payload: res,
          });
          return res?.data;
        } else {
          dispatch({
            type: STAR_NOTIFICATION_ERROR,
            payload: res,
          });
          return res?.data;
        }
      })
      .catch((err) => {
        dispatch({ type: REQUEST_FAILED, payload: err?.response });
       // 
      });
  };
};

export const getAllStarredNotification = () => {
    let API_URL = GET_ALL_USER_STARED_NOTIFICATION_URL;
    const token = getCookie("token");
    return (dispatch) => {
      return axios
        .get(API_URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: GET_ALL_USER_STARED_NOTIFIACTION,
              payload: res,
            });
            return res?.data;
          } else {
            dispatch({
              type: GET_ALL_USER_STARED_NOTIFICATION_ERROR,
              payload: res,
            });
            return res?.data;
          }
        })
        .catch((err) => {
          dispatch({ type: REQUEST_FAILED, payload: err?.response });
         // 
        });
    };
  };
  


  export const clearNotificationById = (notification_id) => {
    const API_URL = `${CLEAR_ALL_NOTIFICATION_URL}/${notification_id}`;
    const token = getCookie("token");
    return (dispatch) => {
      return axios
        .get(API_URL,{
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: CLEAR_NOTIFICATION_BY_ID_DONE,
              payload: res,
            });
            return res?.data;
          } else {
            dispatch({
              type: CLEAR_NOTIFICATION_BY_ID_ERROR,
              payload: res,
            });
            return res?.data;
          }
        })
        .catch((err) => {
          dispatch({ type: REQUEST_FAILED, payload: err?.response });
          //
        });
    };
  };
  


  export const clearAllNotification = () => {
    const API_URL = CLEAR_ALL_NOTIFICATION_URL;
    const token = getCookie("token");
    return (dispatch) => {
      return axios
        .get(API_URL,{
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: CLEAR_ALL_NOTIFICATION_DONE,
              payload: res,
            });
            return res?.data;
          } else {
            dispatch({
              type: CLEAR_ALL_NOTIFICATION_ERROR,
              payload: res,
            });
            return res?.data;
          }
        })
        .catch((err) => {
          dispatch({ type: REQUEST_FAILED, payload: err?.response });
         // 
        });
    };
  };

