import React from "react";
import { useDispatch } from "react-redux";
import { TextareaAutosize, Box } from "@mui/material";
import {
  StyledBorderDiv,
  StyledFlex,
  StyledSpaceBetween,
} from "../../../Styled";
import {
  handleGetAiHistory,
  handleQueryToAi,
} from "../../../appStore/actions/ArkchatGroup/action";
import { useTranslation } from "react-i18next";
import AiQuerySend from "../../../common/SvgImageView/AiQuerySend";
const AIQueryInputBox = ({
  aiQueryValue,
  setAiQueryValue,
  setTempAiQuery,
  tempAiQuery,
  showTempQuery,
  setShowTempQuery,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const dispatch = useDispatch();
  const handleChnageQueryInput = (e) => {
    setAiQueryValue(e.target.value);
  };
  const handleSendQuery = () => {
    setTempAiQuery(aiQueryValue);
    setShowTempQuery(true);
    setAiQueryValue("");
    dispatch(handleQueryToAi({ query: aiQueryValue })).then((res) => {
      if (res?.status) {
        setShowTempQuery(false);
        dispatch(handleGetAiHistory());
      }
    });
  };
  return (
    <>
      <StyledBorderDiv style={{ position: "relative" }}>
        <Box width={"99%"} margin={"auto"}>
          <TextareaAutosize
            sx={{
              "& fieldset": { border: "none" },
            }}
            style={{
              width: "100%",
              resize: "none",
              border: "none",
              outline: "none",
              lineHeight: "1.25rem",
              padding: "5px 8px",
              fontFamily: "Poppins",
              fontSize: "0.87rem",
              maxHeight: "70vh",
              overflow: "auto",
            }}
            minRows={"1"}
            placeholder={appContent?.howCanihelpyou}
            value={aiQueryValue}
            onChange={handleChnageQueryInput}
          />
        </Box>

        <StyledSpaceBetween
          style={{
            backgroundColor: "#F3F3F3",
            display: "flex",
            justifyContent: "flex-end",
            width: "99%",
            margin: "auto",
            marginTop: "0.5rem",
          }}
        >
          <StyledFlex>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={aiQueryValue ? handleSendQuery : undefined}
            >
              <AiQuerySend color={aiQueryValue ? null : "#8C8C8C"} />
            </Box>
          </StyledFlex>
        </StyledSpaceBetween>
      </StyledBorderDiv>
    </>
  );
};

export default AIQueryInputBox;
