import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import aIbadgeIcon from "../../../assets/images/chats/aIbadgeIcon.svg";
import {
  IndicatorIcon,
  createStyledIndicatorButton,
} from "../../../common/StyledComponents/SideBarComponents";
import CloseIcon from "@mui/icons-material/Close";
import AIAnswerBox from "./AIAnswerBox";
import AIQuestionBox from "./AIQuestionBox";
import TempQuestionBox from "./TempQuestionBox";
import AiEditAnswer from "./AiEditAnswer";
import { handleEditAnswer } from "../../../appStore/actions/ArkchatGroup/action";
import { useTranslation } from "react-i18next";

const classes = {
  header: {
    backgroundColor: "#EAEAEA",
    color: "#000",
    padding: "4px 6px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "2px 4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const AIQueryPage = ({
  isAiQueryHistory,
  setAiQueryHistory,
  showTempQuery,
  tempAiQuery,
  setShareAiText,
  setNewMessages,
  showStaredAiHistory,
  setShowStaredAiHistory,
  setSelectedAnswer,
  selecteAnswer,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const BadgeButton = createStyledIndicatorButton("#F3F3F3");
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editAnswerData, setEditAnswerData] = useState({});
  const queryContainerRef = useRef(null);
  const aiHistoryData = useSelector(
    (state) => state?.arkchatgroup?.aiHistoryData
  );
  const aiQuerySession = useSelector(
    (state) => state?.arkchatgroup?.aiQuerySession
  );
  const selectedGroup = useSelector(
    (state) => state?.appStateData?.selectedGroupData
  );
  const dispatch = useDispatch();
  const scrollToBottom = () => {
    if (queryContainerRef.current) {
      queryContainerRef.current.scrollTop =
        queryContainerRef.current.scrollHeight;
    }
  };
  const scrollToTop = () => {
    if (queryContainerRef.current) {
      queryContainerRef.current.scrollTop = "0px";
    }
  };
  const handleOpenEditModal = (data) => {
    setEditAnswerData(data);
    setEditModalOpen(true);
  };

  const handleEditDone = () => {
    dispatch(handleEditAnswer(editAnswerData)).then(() => {
      setEditModalOpen(false);
    });
  };

  const handelAnswerChange = (event) => {
    setEditAnswerData({
      ...editAnswerData,
      answer: event.target.value,
    });
  };
  useEffect(() => {
    if (isAiQueryHistory) {
      scrollToTop();
    } else {
      scrollToBottom();
    }
  }, [aiQuerySession, showTempQuery, isAiQueryHistory, showStaredAiHistory]);
  return (
    <>
      <AiEditAnswer
        isEditModalOpen={isEditModalOpen}
        setEditModalOpen={setEditModalOpen}
        editAnswerData={editAnswerData}
        handelAnswerChange={handelAnswerChange}
        handleEditDone={handleEditDone}
      />
      <>
        <Stack
          mt={"0.5rem"}
          justifyContent={"center"}
          width={"100%"}
          direction={"row"}
          alignItems={"center"}
          sx={{
            padding: "2px 0px",
            backgroundColor: "#E1E1E1",
          }}
        >
          {" "}
          <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
            {selectedGroup?.name}
          </Typography>
        </Stack>
        {/* AI Type Heading Like is a history or stared */}
        {isAiQueryHistory && (
          <Box padding="15px 0px 0px 0px" mb={"0.9rem"}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={classes.header}
            >
              <Typography
                sx={classes.iconDiv}
                onClick={() =>
                  showStaredAiHistory
                    ? setShowStaredAiHistory(false)
                    : setAiQueryHistory(false)
                }
              >
                <CloseIcon sx={classes.iconn} />
              </Typography>
              <Typography variant="subtitle2" fontWeight={600} color={"#00000"}>
                {showStaredAiHistory ? "Starred queries" : "Query history"}
              </Typography>
            </Stack>
          </Box>
        )}
      </>
      <Box
        sx={{
          height: "100%",
          overflow: "auto",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "3px",
          },
        }}
        ref={queryContainerRef}
      >
        {!isAiQueryHistory && (
          <Box padding="15px 0px">
            <Box display={"flex"} maxWidth={"90%"}>
              <Box mr={"0.5rem"}>
                <BadgeButton size="small" variant="contained">
                  <IndicatorIcon src={aIbadgeIcon} />
                </BadgeButton>
              </Box>

              <Box>
                <Typography variant="subtitle2">
                  {appContent?.aiWelcomeMsg}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        {/* Histoy Data */}
        {isAiQueryHistory && (
          <>
            {aiHistoryData?.history?.map((data) => (
              <>
                {/* star is left */}
                {data?.messages
                  ?.filter((item) =>
                    showStaredAiHistory ? item?.is_starred === true : item
                  )
                  ?.map((historyData, indx) => (
                    <Box
                      mb={"0.9rem"}
                      key={historyData?.id + "historyData" + indx}
                    >
                      <AIQuestionBox
                        questionData={historyData?.query}
                        queried_by={historyData?.queried_by}
                        date={data?.date}
                        history_id={historyData?.id}
                        is_starred={historyData?.is_starred}
                        screenType={"aiHistory"}
                      />
                      <AIAnswerBox
                        answerData={historyData?.answer}
                        queried_by={historyData?.queried_by}
                        date={data?.date}
                        history_id={historyData?.id}
                        historyData={historyData}
                        screenType={"aiHistory"}
                        setAiQueryHistory={setAiQueryHistory}
                        setEditModalOpen={setEditModalOpen}
                        handleOpenEditModal={handleOpenEditModal}
                      />
                    </Box>
                  ))}
              </>
            ))}
          </>
        )}

        {/* AI Query SEssion */}
        {!isAiQueryHistory && (
          <>
            {aiQuerySession?.map((historyData, indx) => (
              <Box mb={"0.9rem"} key={historyData?.id + "sessionhistory" + indx}>
                <AIQuestionBox
                  questionData={historyData?.query}
                  queried_by={historyData?.queried_by}
                  history_id={historyData?.id}
                  is_starred={historyData?.is_starred}
                  screenType={"aiSession"}
                />
                <AIAnswerBox
                  answerData={historyData?.answer}
                  queried_by={historyData?.queried_by}
                  history_id={historyData?.id}
                  historyData={historyData}
                  screenType={"aiSession"}
                  setShareAiText={setShareAiText}
                  setNewMessages={setNewMessages}
                  selecteAnswer={selecteAnswer}
                  setSelectedAnswer={setSelectedAnswer}
                  setEditModalOpen={setEditModalOpen}
                  handleOpenEditModal={handleOpenEditModal}
                />
              </Box>
            ))}
          </>
        )}
        {/* AI Loder For Answer */}
        {showTempQuery && <TempQuestionBox tempAiQuery={tempAiQuery} />}
      </Box>
    </>
  );
};

export default AIQueryPage;
