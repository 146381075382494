import React from "react";
import { useDispatch } from "react-redux";
import { Slide, Snackbar, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  HANDLE_TOAST_OPEN_CLOSE,
  SHOW_TOAST_MESSAGE,
} from "../../appStore/actions/global/type";
const ToastMsg = React.memo(
  ({ message, showToastMsg = false }) => {
    const dispatch = useDispatch();

    const handleClose = (event, reason) => {
      dispatch({
        type: HANDLE_TOAST_OPEN_CLOSE,
        payload: false,
      });
      dispatch({
        type: SHOW_TOAST_MESSAGE,
        payload: "",
      });
      if (reason === "clickaway") {
        return;
      }
    };

    const handleCloseClicked = () => {
      dispatch({
        type: HANDLE_TOAST_OPEN_CLOSE,
        payload: false,
      });
      dispatch({
        type: SHOW_TOAST_MESSAGE,
        payload: "",
      });
    };

    function SlideTransition(props) {
      return <Slide {...props} direction="down" />;
    }

    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
        open={showToastMsg}
        message={message}
        TransitionComponent={SlideTransition}
        action={
          showToastMsg && (
            <Box display={"flex"} alignItems={"center"}>
              <CloseIcon
                sx={{ cursor: "pointer" }}
                color="white"
                onClick={handleCloseClicked}
              />
            </Box>
          )
        }
      />
    );
  },
  (prevProps, nextProps) => {
    // Only re-render if showToastMsg changes
    return prevProps.showToastMsg === nextProps.showToastMsg;
  }
);

export default ToastMsg;
